import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'

import { Button } from '../../../../global/button/Button'
import { FieldGroup } from '../../../../global/form/FieldGroup'
import { RawFormCheckBoxField } from '../../../../global/formField/FormCheckBoxField'
import { createFormField } from '../../../../global/formField/FormField'
import { RawSelectFormField } from '../../../../global/formField/FormSelectField'
import IconButton from '../../../../global/iconButton/IconButton'
import { CardPaymentProviderProceedModal } from '../../../../global/modal/CardPaymentProviderProceedModal'
import { InformationModal } from '../../../../global/modal/InformationModal'
import { Modal } from '../../../../global/modal/Modal'
import { PaymentProviderProceedModal } from '../../../../global/modal/PaymentProviderProceedModal'
import { TransactionFormActionButtonTemplate } from '../../../../global/templates/TransactionFormActionButtonTemplate'
import { useFormatNumber } from '../../../../hooks/useFormatNumber'
import { BlueInfoIcon } from '../../../../icons/BlueInfoIcon'
import { DropArrowDownIcon } from '../../../../icons/DropArrowDownIcon'
import { InfoIcon } from '../../../../icons/InfoIcon'
import {
  isPaymentProviderSCOrbitalCrypto,
  isPaymentProviderSCZotapayCrypto,
  isPaymentProviderSCZotapayCryptoMetablocks,
} from '../../../../model/PaymentProviderId'
import {
  isPaymentProviderBankType,
  isPaymentProviderCardProviderType,
  isPaymentProviderPSPType,
  isPaymentProviderPaymentAgentType,
} from '../../../../model/PaymentProviderType'
import { AvailableBonusCampaign } from '../../../../model/WalletDto'
import { WalletPaymentProviderCurrency } from '../../../../model/WalletPaymentProviderParameters'
import { Note } from '../../../../ui/Note/Note'
import { Tooltip } from '../../../../ui/Popups/Tooltip/Tooltip'
import { TextSmall } from '../../../../ui/Typography/Typography'
import { useWindowResize } from '../../../../utils/domUtils'
import { DepositFormWarningNote } from '../../../DepositFormWarningNote'
import { CurrencyAmountChip } from '../../../Trading-Accounts/CurrencyAmountChip'
import { WalletWithdrawFormValues } from '../../WalletWithdraw/WalletWithdrawForm/WalletWithdrawForm'
import { WalletDepositCurrencyModal } from '../WalletDepositCurrencyModal'
import { CurrencyAmountField } from './CurrencyAmountField'
import { FormTermsConditionsPSP } from './FormTermsConditionsFactory'
import { WalletDepositFormValues } from './WalletDepositForm'
import { WalletFormFieldsFactory } from './WalletFormFieldsFactory'

import styles from './WalletDepositForm.module.scss'

const FormField = createFormField<WalletDepositFormValues>()

interface WalletDepositPSPFieldsProps {
  showAmountPresets?: boolean
  onCancel(): void
  minAmount?: number
  isBonusLoading?: boolean
  onClickTermsConditions(): void
  availableBonusCampaigns?: AvailableBonusCampaign[] | null
  isBonusSelected?: boolean
  setIsBonusSelected?: (value: boolean) => void
  selectedBonusCampaign?: AvailableBonusCampaign | null
  setSelectedBonusCampaign?: (campaign: AvailableBonusCampaign | null) => void
  bonusDepositAmount?: number
}

export const WalletDepositPSPFields: React.FC<WalletDepositPSPFieldsProps> = (props) => {
  const {
    onCancel,
    minAmount,
    showAmountPresets,
    onClickTermsConditions,
    availableBonusCampaigns,
    isBonusLoading,
    isBonusSelected,
    setIsBonusSelected,
    selectedBonusCampaign,
    setSelectedBonusCampaign,
    bonusDepositAmount,
  } = props
  const { t } = useTranslation()

  // Required by WCA-2693 - New Proceed modal should only open for "Credit or Debit Card" method
  const creditDebitCardMethodId = '2d5792d8-4986-11ea-9e5d-000c29df7a17'

  const { values, setValues, setFieldValue, setFieldTouched, isValid, handleSubmit, validateForm } =
    useFormikContext<WalletDepositFormValues>()
  const { formatMoney } = useFormatNumber()

  const [isCurrencyModalOpen, setCurrencyModalOpen] = useState(false)
  const [isPaymentProviderProceedModalOpen, setPaymentProviderProceedModalOpen] = useState(false)
  const [isCardPaymentProviderProceedModalOpen, setCardPaymentProviderProceedModalOpen] =
    useState(false)
  const [isRulesAccepted, setIsRulesAccepted] = useState(false)
  const [bonusDepositModalVisible, setBonusDepositModalVisible] = useState(false)

  const { values: walletValues } = useFormikContext<WalletWithdrawFormValues>()

  const handleExitPageConfirmationModalOpen = () => {
    onCancel()
  }

  const isMobile = useWindowResize()

  useEffect(() => {
    validateForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBonusSelected])

  const handleCurrency = (walletPaymentProviderCurrency: WalletPaymentProviderCurrency) => {
    setValues({
      ...values,
      paymentProvider: {
        ...values.paymentProvider,
        currency: walletPaymentProviderCurrency,
      },
    })
    setCurrencyModalOpen(false)
  }

  const handleCurrencyModalOpen = () => {
    setCurrencyModalOpen(true)
  }

  const handleCurrencyModalClose = () => {
    setCurrencyModalOpen(false)
  }

  const handleSubmitForm = () => {
    handleSubmit()
  }

  const handlePaymentProviderProceed = () => {
    if (isPaymentProviderPSPType(values.paymentProvider.providerCategory.id)) {
      setPaymentProviderProceedModalOpen(true)
    }

    if (isPaymentProviderCardProviderType(values.paymentProvider.providerCategory.id)) {
      if (values.paymentProvider?.id === creditDebitCardMethodId) {
        setCardPaymentProviderProceedModalOpen(true)
      } else {
        setPaymentProviderProceedModalOpen(true)
      }
    }

    if (isPaymentProviderBankType(values.paymentProvider.providerCategory.id)) {
      handleSubmit()
    }

    if (isPaymentProviderPaymentAgentType(values.paymentProvider.providerCategory.id)) {
      handleSubmit()
    }
  }

  const handlePaymentProviderProceedModalClose = () => {
    setPaymentProviderProceedModalOpen(false)
  }

  const handleCardPaymentProviderProceedModalClose = () => {
    setCardPaymentProviderProceedModalOpen(false)
  }

  const disabled =
    values?.paymentProvider?.parameters &&
    !(values.paymentProvider.parameters.currencies.length > 1)

  const isCryptoNoteInfoVisible =
    isPaymentProviderSCOrbitalCrypto(values?.paymentProvider?.id) ||
    isPaymentProviderSCZotapayCrypto(values?.paymentProvider?.id) ||
    isPaymentProviderSCZotapayCryptoMetablocks(values?.paymentProvider?.id)

  const isAdditionalCryptoTextVisible =
    isPaymentProviderSCOrbitalCrypto(values?.paymentProvider?.id) ||
    isPaymentProviderSCZotapayCrypto(values?.paymentProvider?.id)

  const isBonusDepositSectionVisible = availableBonusCampaigns && availableBonusCampaigns.length > 0

  const handleBonusCheckboxChange = (checked: boolean) => {
    if (checked) {
      setFieldValue('bonusCampaignId', selectedBonusCampaign?.bonusCampaign.id)
      setFieldTouched('bonusAmount', true)
    } else {
      setFieldValue('bonusCampaignId', undefined)
      setFieldValue('bonusAmount', undefined)
      setFieldTouched('bonusAmount', false)
    }
    setIsBonusSelected?.(checked)
    validateForm()
  }

  const handleBonusCampaignChange = (campaignId: string) => {
    const selectedCampaign = availableBonusCampaigns?.find(
      (campaign) => campaign.bonusCampaign.id === campaignId
    )
    setSelectedBonusCampaign?.(selectedCampaign || null)
    setFieldValue('bonusCampaignId', campaignId)
  }

  useEffect(() => {
    if (availableBonusCampaigns?.length === 1) {
      setSelectedBonusCampaign?.(availableBonusCampaigns[0])
      setFieldValue('bonusCampaignId', availableBonusCampaigns[0].bonusCampaign.id)
    }
  }, [availableBonusCampaigns])

  const getBonusCampaignField = (availableBonusCampaigns?: AvailableBonusCampaign[]) => {
    if (!availableBonusCampaigns?.length) {
      return null
    }

    return availableBonusCampaigns.length === 1 ? (
      <FormField
        name='bonusCampaignId'
        label={t('Wallet.Bonus Type')}
        placeholder={t('Wallet.Bonus Type')}
        value={selectedBonusCampaign?.bonusCampaign.name}
        readOnly
      />
    ) : (
      <RawSelectFormField
        name='bonusCampaignId'
        label={t('Wallet.Bonus Type')}
        placeholder={t('Wallet.Bonus Type')}
        value={selectedBonusCampaign?.bonusCampaign.id}
        onChange={(e) => handleBonusCampaignChange(e.target.value)}
      >
        {availableBonusCampaigns.map(({ bonusCampaign }) => (
          <option value={bonusCampaign.id} key={bonusCampaign.id}>
            {bonusCampaign.name}
          </option>
        ))}
      </RawSelectFormField>
    )
  }

  const isBonusAmountOutOfRange = (): boolean => {
    if (!selectedBonusCampaign) {
      return false
    }

    const { depositLessThanMinDeposit, bonusExceedsMaxBonus } = selectedBonusCampaign

    return depositLessThanMinDeposit || bonusExceedsMaxBonus
  }

  const getBonusDepositModalTitle = (): string | undefined => {
    if (!selectedBonusCampaign || bonusDepositAmount == null) {
      return undefined
    }

    const { minDepositAmount, maxBonusAmount } = selectedBonusCampaign

    if (bonusDepositAmount < minDepositAmount) {
      return t('Wallet.Minimum Deposit Amount')
    }

    if (bonusDepositAmount > maxBonusAmount) {
      return t('Wallet.Maximum Bonus Amount')
    }

    return undefined
  }

  const getBonusDepositModalBody = (): string | undefined => {
    if (!selectedBonusCampaign || bonusDepositAmount == null) {
      return undefined
    }

    const { minDepositAmount, maxBonusAmount } = selectedBonusCampaign

    if (bonusDepositAmount < minDepositAmount) {
      return t('Wallet.Increase your amount to at least', {
        amount: formatMoney(minDepositAmount, values.wallet.currency.id),
      })
    }

    if (bonusDepositAmount > maxBonusAmount) {
      return t('Wallet.The maximum amount of bonus you can get according', {
        amount: formatMoney(maxBonusAmount, values.wallet.currency.id),
      })
    }

    return undefined
  }

  const BonusInfoIcon = () => {
    const handleIconClick = () => {
      if (isMobile) {
        setBonusDepositModalVisible(true)
      }
    }

    return (
      <div>
        {isMobile ? (
          <IconButton onClick={handleIconClick}>
            <InfoIcon
              size={18}
              color={selectedBonusCampaign?.depositLessThanMinDeposit ? 'error' : 'warning'}
            />
          </IconButton>
        ) : (
          <Tooltip content={getBonusDepositModalBody()} direction='top'>
            <IconButton>
              <InfoIcon
                size={18}
                color={selectedBonusCampaign?.depositLessThanMinDeposit ? 'error' : 'warning'}
              />
            </IconButton>
          </Tooltip>
        )}
      </div>
    )
  }

  useEffect(() => {
    if (isBonusSelected && !!values.amount && (availableBonusCampaigns?.length ?? 0) > 1) {
      const currentBonusCampaign = availableBonusCampaigns?.find(
        (campaign) => campaign.bonusCampaign.id === values.bonusCampaignId
      )
      const [singleBonus] = availableBonusCampaigns || []
      const campaignToSet = currentBonusCampaign ?? singleBonus
      setSelectedBonusCampaign?.(campaignToSet)
      if (campaignToSet.bonusCampaign.id !== values.bonusCampaignId) {
        setFieldValue('bonusCampaignId', campaignToSet.bonusCampaign.id)
      }
      setFieldValue('bonusAmount', campaignToSet.bonusAmount)
    }
  }, [
    availableBonusCampaigns,
    isBonusSelected,
    setFieldValue,
    values.amount,
    values.bonusCampaignId,
  ])

  const isBonusCampaignValid = (): boolean => {
    if (!availableBonusCampaigns?.length) {
      return true
    }
    if (!isBonusSelected) {
      return true
    }
    if (!selectedBonusCampaign) {
      return false
    }
    if (!selectedBonusCampaign.bonusAmount) {
      return false
    }
    if (!isRulesAccepted) {
      return false
    }

    return true
  }

  return (
    <React.Fragment>
      {bonusDepositModalVisible && (
        <Modal
          closeModal={() => setBonusDepositModalVisible(false)}
          render={() => (
            <InformationModal
              title={getBonusDepositModalTitle()}
              onCancelText={t('Got It')}
              onCancel={() => setBonusDepositModalVisible(false)}
            >
              <span>{getBonusDepositModalBody()}</span>
            </InformationModal>
          )}
        />
      )}
      {values.paymentProvider.id && (
        <React.Fragment>
          {isCurrencyModalOpen && (
            <WalletDepositCurrencyModal
              onSelectOption={handleCurrency}
              onClose={handleCurrencyModalClose}
            />
          )}
          {isPaymentProviderProceedModalOpen && (
            <PaymentProviderProceedModal
              onProceed={handleSubmitForm}
              onClose={handlePaymentProviderProceedModalClose}
            />
          )}
          {isCardPaymentProviderProceedModalOpen && (
            <CardPaymentProviderProceedModal
              onProceed={handleSubmitForm}
              onClose={handleCardPaymentProviderProceedModalClose}
            />
          )}
          <WalletFormFieldsFactory />
          <FieldGroup>
            <CurrencyAmountField />
            <div className={styles.currencyFieldWrapper}>
              <FormField
                name='paymentProvider.currency.id'
                label={t('Wallet.Currency')}
                disabled={disabled}
                rightIcon={!disabled && <DropArrowDownIcon />}
                onClick={handleCurrencyModalOpen}
              />
            </div>
          </FieldGroup>
          {showAmountPresets && (
            <FieldGroup>
              <CurrencyAmountChip minValue={minAmount} />
            </FieldGroup>
          )}
          {values.amount && isBonusDepositSectionVisible && (
            <div className='my-2'>
              <RawFormCheckBoxField
                name='isBonusSelected'
                onChange={(e) => handleBonusCheckboxChange(e.target.checked)}
                value={isBonusSelected}
              >
                {t('Wallet.Get a bonus on deposit')}
              </RawFormCheckBoxField>
            </div>
          )}
          {isBonusSelected && availableBonusCampaigns && isBonusDepositSectionVisible && (
            <>
              {getBonusCampaignField(availableBonusCampaigns)}
              <FormField
                name='bonusAmount'
                label={t('Wallet.Bonus size')}
                placeholder={t('Wallet.Bonus size')}
                value={`${selectedBonusCampaign?.bonusAmount.toFixed(2)} ${
                  values.wallet?.currency?.id
                }`}
                disabled
                loading={isBonusLoading}
                rightIcon={isBonusAmountOutOfRange() && <BonusInfoIcon />}
              />
              <RawFormCheckBoxField
                name='isRulesAccepted'
                onChange={(e) => setIsRulesAccepted(e.target.checked)}
                value={isRulesAccepted}
              >
                <TextSmall>{t('Wallet.acceptDepositBonusRules.1')} </TextSmall>
                <a
                  className={styles.link}
                  target='_blank'
                  href={selectedBonusCampaign?.termsAndConditionsUrl}
                  rel='noreferrer'
                >
                  <TextSmall>{t('Wallet.acceptDepositBonusRules.2')}</TextSmall>
                </a>
              </RawFormCheckBoxField>
            </>
          )}
          {isCryptoNoteInfoVisible && (
            <div className='mb-4 mt-2'>
              <Note
                renderLeft={<BlueInfoIcon />}
                text={t('Wallet.Deposit the exact amount initially selected')}
              />
            </div>
          )}
          <FormField
            name='comment'
            label={t('Wallet.Additional Comment')}
            placeholder={t('Wallet.Additional Comment')}
          />
          <DepositFormWarningNote paymentProvider={walletValues?.paymentProvider} />
          {isAdditionalCryptoTextVisible && (
            <div className='mt-5'>
              <TextSmall>{t('Crypto info')}</TextSmall>
            </div>
          )}
          <div className='mt-4 mb-5'>
            <FormTermsConditionsPSP onClickTermsConditions={onClickTermsConditions} />
          </div>
          <TransactionFormActionButtonTemplate>
            <Button
              appearance='secondary'
              size='L'
              type='button'
              onClick={handleExitPageConfirmationModalOpen}
            >
              {t('Cancel')}
            </Button>
            <Button
              appearance='primary'
              size='L'
              type='button'
              onClick={handlePaymentProviderProceed}
              disabled={!isValid || !isBonusCampaignValid() || isBonusLoading}
            >
              {t('Confirm')}
            </Button>
          </TransactionFormActionButtonTemplate>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

import { useCallback, useEffect, useRef, useState } from 'react'

import { useArabicSessionLanguage } from '../../../global/context/SessionSettingsContext'
import { debounce } from '../../../utils/debounce'
import { toSentenceCase } from '../../../utils/format.utils'
import { Direction } from '../../common/types'

interface UseTooltipMove {
  boxRef: React.RefObject<HTMLDivElement>
  newDirection: Direction
  handleChange: () => void
}

const getHorizontal = (direction?: Direction) => {
  if (direction?.toLowerCase().includes('left')) {
    return 'left'
  }
  if (direction?.toLowerCase().includes('right')) {
    return 'right'
  }
  return ''
}

const getVertical = (direction?: Direction) => {
  if (direction?.includes('top')) {
    return 'top'
  }
  if (direction?.includes('bottom')) {
    return 'bottom'
  }
  return ''
}

export const useTooltipMove = (direction?: Direction): UseTooltipMove => {
  const boxRef = useRef<HTMLDivElement>(null)
  const [newDirection, setNewDirection] = useState<Direction>(direction ?? 'top')
  const isArabic = useArabicSessionLanguage()

  const handleChange = useCallback(() => {
    const screenWidth = window.innerWidth
    const screenHeight = window.innerHeight
    const currentHorizontal = getHorizontal(newDirection)
    const currentVertical = getVertical(newDirection)

    const positionToSet = { horizontal: currentHorizontal, vertical: currentVertical }

    const rightPosition = screenWidth - (boxRef.current?.getBoundingClientRect().right ?? 0)
    const leftPosition = boxRef.current?.getBoundingClientRect().left ?? 0

    const newPositions = {
      right: isArabic ? leftPosition : rightPosition,
      left: isArabic ? rightPosition : leftPosition,
      top: boxRef.current?.getBoundingClientRect().top ?? 0,
      bottom: screenHeight - (boxRef.current?.getBoundingClientRect().bottom ?? 0),
      width: boxRef.current?.getBoundingClientRect().width ?? 0,
      height: boxRef.current?.getBoundingClientRect().height ?? 0,
    }

    if (newPositions.top < 0) {
      positionToSet.vertical = 'bottom'
    }

    if (newPositions.bottom < 0) {
      positionToSet.vertical = 'top'
    }

    if (newPositions.left < 0) {
      positionToSet.horizontal = 'right'
    }

    if (newPositions.right < 0) {
      positionToSet.horizontal = 'left'
    }

    setNewDirection(
      `${positionToSet.vertical}${
        positionToSet.vertical ? toSentenceCase(positionToSet.horizontal) : positionToSet.horizontal
      }` as Direction
    )
  }, [boxRef, newDirection, isArabic])

  useEffect(() => {
    const debouncedHandleResizeAndScroll = debounce(() => handleChange(), 100)
    debouncedHandleResizeAndScroll()
    window.addEventListener('resize', debouncedHandleResizeAndScroll)
    window.addEventListener('scroll', debouncedHandleResizeAndScroll)

    return () => {
      window.removeEventListener('resize', debouncedHandleResizeAndScroll)
      window.removeEventListener('scroll', debouncedHandleResizeAndScroll)
    }
  }, [handleChange])

  return {
    boxRef,
    newDirection,
    handleChange,
  }
}

import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { differenceInMilliseconds } from 'date-fns'
import { FormikErrors, FormikProvider, useFormik } from 'formik'

import { FilterOptionsDto } from '../../model/FilterOptionsDto'
import { NameDto } from '../../model/NameDto'
import { PlatformTypeEnum } from '../../model/PlatformTypeEnum'
import {
  SubscriptionClassificationTypes,
  SubscriptionLevels,
  SubscriptionRequestTypes,
  SubscriptionStatuses,
  SubscriptionTypes,
} from '../../model/SubscriptionsDto'
import { Operator } from '../../utils/ApiClient'
import { useDateFilterReadContext, useDateFilterWriteContext } from '../../utils/DateFilterContext'
import { AvailableEntities, TickmillCompany } from '../../utils/companyName.utils'
import { DateFilterPresets, IDateFilter } from '../../utils/filter.utils'
import { Loading } from '../Loading/Loading'
import { FilterModalChipFilter } from './filterParts/chip/FilterModalChipFilter'
import { FilterModalCustomDateFilter } from './filterParts/customDate/FilterModalCustomDateFilter'
import { FilterModalFromToFilter } from './filterParts/fromTo/FilterModalFromToFilter'
import { FilterModalTextFieldFilter } from './filterParts/textField/FilterModalTextFieldFilter'
import { FilterModalToggleFilter } from './filterParts/toggle/FilterModalToggleFilter'

import styles from './FilterQueryModal.module.scss'

export type FilterQueryPropsValue =
  | { value: string | number; operator: Operator }
  | string
  | number
  | undefined
export interface FilterQueryProps {
  [key: string]: { value: string | number; operator: Operator } | string | number | undefined
}

export enum SearchField {
  ClientAmountFrom = 'ca_search_AmountFrom',
  ClientAmountTo = 'ca_search_AmountTo',
  ClientClientAmountFrom = 'ca_search_ClientAmountFrom',
  ClientClientAmountTo = 'ca_search_ClientAmountTo',
  QueryAmountFrom = 'query_search_AmountFrom',
  QueryAmountTo = 'query_search_AmountTo',
  QueryVolumeFrom = 'query_search_VolumeFrom',
  QueryVolumeTo = 'query_search_VolumeTo',
  ClientVolumeFrom = 'ca_search_VolumeFrom',
  ClientVolumeTo = 'ca_search_VolumeTo',
  ClientPriceFrom = 'ca_search_PriceFrom',
  ClientPriceTo = 'ca_search_PriceTo',
  ClientDateFrom = 'ca_search_DateFrom',
  ClientDateTo = 'ca_search_DateTo',
  ClientRegistrationDateFrom = 'ca_search_RegistrationDateFrom',
  ClientRegistrationDateTo = 'ca_search_RegistrationDateTo',
  DateFrom = 'DateFrom',
  DateTo = 'DateTo',
}

export enum FilterOptions {
  Commission,
  ClientVolume,
  Volume,
  ClientIdAmount,
  Amount,
  Price,
  RegistrationDate,
  Date,
  Status = 'ca_search_Status',
  Type = 'ca_search_Type',
  Wallet = 'ca_search_WalletId',
  TransactionType = 'ca_search_TransactionTypeId',
  TransactionStatus = 'ca_search_TransactionStateId',
  AccountType = 'ca_search_AccountType',
  Currency = 'ca_search_Currency',
  Country = 'ca_search_Country',
  Entity = 'ca_search_TickmillCompany',
  SubscriptionType = 'ca_search_SubscriptionTypeId',
  SubscriptionLevel = 'ca_search_SubscriptionLevelId',
  SubscriptionClassificationType = 'ca_search_SubscriptionClassificationTypeId',
  SubscriptionStatus = 'ca_search_StateId',
  SubscriptionRequestType = 'ca_search_ClientAreaRequestTypeId',
  Name = 'ca_search_Name',
  TradingAccountPlatformType = 'ca_search_TradingAccountPlatformTypeId',
  TradingAccount = 'ca_search_TaId',
}

export type FilterQueryModalProps = {
  searchFilters?: FilterQueryProps
  currentFilterOptions: FilterOptions[]
  allFilterOptions?: FilterOptionsDto
  transactionTypes?: NameDto[]
  wallets?: NameDto<string>[]
  tradingAccounts?: NameDto<string>[]
  status?: NameDto[]
  transactionStates?: NameDto[]
  currencyOptions?: NameDto<string>[]
  types?: NameDto[]
  tradingAccountTypes?: NameDto[]
  countries?: NameDto<string>[]
  entities?: TickmillCompany[]
  customLabels?: { [key in FilterOptions]?: string }
  dateLimits?: { from: Date; to: Date }
  allowFutureDate?: boolean
  datePresets?: DateFilterPresets[]

  isLoading?: boolean
  setSearchFilters(value: React.SetStateAction<FilterQueryProps | undefined>): void
  onConfirm({
    searchFilters,
    currentFilter,
  }: {
    searchFilters?: FilterQueryProps
    currentFilter?: IDateFilter
  }): void
}

export const FilterQueryModal: React.FC<FilterQueryModalProps> = (props) => {
  const {
    searchFilters,
    currentFilterOptions,
    allFilterOptions,
    setSearchFilters,
    onConfirm,
    isLoading = false,
  } = props
  const { t } = useTranslation()

  const { clearFilter } = useDateFilterWriteContext()
  const { currentFilter } = useDateFilterReadContext()

  const handleConfirm = () => {
    onConfirm({ searchFilters, currentFilter })
  }

  const handleClear = () => {
    setSearchFilters(undefined)
    clearFilter()
    onConfirm({})
  }

  const formik = useFormik<FilterQueryProps>({
    initialValues: searchFilters || {},
    onSubmit: handleConfirm,
    validate: (values) => {
      const errors: FormikErrors<FilterQueryProps> = {}
      validateAmountRange(
        values,
        SearchField.ClientClientAmountFrom,
        SearchField.ClientClientAmountTo,
        errors
      )
      validateAmountRange(values, SearchField.ClientAmountFrom, SearchField.ClientAmountTo, errors)
      validateAmountRange(
        values,
        SearchField.ClientClientAmountFrom,
        SearchField.ClientClientAmountTo,
        errors
      )
      validateAmountRange(values, SearchField.QueryAmountFrom, SearchField.QueryAmountTo, errors)
      validateAmountRange(values, SearchField.QueryAmountFrom, SearchField.QueryAmountTo, errors)
      validateAmountRange(values, SearchField.QueryVolumeFrom, SearchField.QueryVolumeTo, errors)
      validateAmountRange(values, SearchField.ClientVolumeFrom, SearchField.ClientVolumeTo, errors)
      validateAmountRange(values, SearchField.ClientPriceFrom, SearchField.ClientPriceTo, errors)
      validateDateRange(values, SearchField.ClientDateFrom, SearchField.ClientDateTo, errors)
      validateDateRange(
        values,
        SearchField.ClientRegistrationDateFrom,
        SearchField.ClientRegistrationDateTo,
        errors
      )

      return errors
    },
  })

  const validateDateRange = (
    values: FilterQueryProps,
    fieldFrom: keyof FilterQueryProps,
    fieldTo: keyof FilterQueryProps,
    errors: FormikErrors<FilterQueryProps>
  ) => {
    const fromValue = values[fieldFrom]
    const toValue = values[fieldTo]
    if (
      typeof fromValue === 'string' &&
      typeof toValue === 'string' &&
      differenceInMilliseconds(new Date(fromValue), new Date(toValue)) > 0
    ) {
      errors[fieldTo] = t('Validation.The start date cannot be after the end date')
    }
  }

  const validateAmountRange = (
    values: FilterQueryProps,
    fieldFrom: keyof FilterQueryProps,
    fieldTo: keyof FilterQueryProps,
    errors: FormikErrors<FilterQueryProps>
  ) => {
    if (
      values[fieldFrom] &&
      values[fieldTo] &&
      Number(values[fieldFrom]) > Number(values[fieldTo])
    ) {
      errors[fieldTo] = t('Validation.From amount cannot be greater than To amount')
    }
  }

  return (
    <FormikProvider value={formik}>
      <Fragment>
        <header className='modal-card-head'>
          <p className='modal-card-title'>{t('Filters')}</p>
        </header>
        <section className={classNames('modal-card-body', styles.body)}>
          <Loading isLoading={isLoading} showLoadingIcon>
            {currentFilterOptions.map((filterOption, idx) => (
              <FilterModalChipFilterFactory
                key={idx}
                {...props}
                filterOption={filterOption}
                allFilterOptions={allFilterOptions}
              />
            ))}
          </Loading>
        </section>
        <footer className={classNames('modal-card-foot', styles.footer)}>
          <button className='button' onClick={handleClear} type='button'>
            {t('Clear All')}
          </button>

          <button
            className='button'
            onClick={formik.submitForm}
            type='button'
            disabled={!formik.isValid}
          >
            {t('Confirm')}
          </button>
        </footer>
      </Fragment>
    </FormikProvider>
  )
}

const FilterModalChipFilterFactory: React.FC<
  FilterQueryModalProps & { filterOption: FilterOptions; allFilterOptions?: FilterOptionsDto }
> = (props) => {
  const {
    status,
    types,
    countries,
    wallets,
    tradingAccountTypes,
    tradingAccounts,
    transactionTypes,
    currencyOptions,
    transactionStates,
    setSearchFilters,
    searchFilters,
    filterOption,
    entities,
    customLabels,
    allowFutureDate,
    dateLimits,
    datePresets,
    allFilterOptions,
  } = props

  const { t } = useTranslation()

  const isMetaTraderTAFilterAvailable = allFilterOptions?.platformTypes.some(
    (platformType) =>
      platformType.id === PlatformTypeEnum.MT4 || platformType.id === PlatformTypeEnum.MT5
  )
  const isTmtTAFilterAvailable = allFilterOptions?.platformTypes.some(
    (platformType) => platformType.id === PlatformTypeEnum.TickmillTrader
  )
  const isTAPlatformTypeFilterVisible =
    tradingAccounts?.length && (isMetaTraderTAFilterAvailable || isTmtTAFilterAvailable)

  switch (filterOption) {
    case FilterOptions.Country:
      return countries?.length ? (
        <FilterModalChipFilter
          name={customLabels?.[FilterOptions.Country] || t('Country')}
          filterType={FilterOptions.Country}
          options={countries}
          setState={setSearchFilters}
          state={searchFilters}
        />
      ) : null
    case FilterOptions.TradingAccountPlatformType:
      return isTAPlatformTypeFilterVisible ? (
        <FilterModalToggleFilter
          name={
            customLabels?.[FilterOptions.TradingAccountPlatformType] ||
            t('Trading Account.Trading Account')
          }
          filterType={FilterOptions.TradingAccountPlatformType}
          options={[
            ...(isMetaTraderTAFilterAvailable ? [{ name: 'MT4/MT5', id: '1,2' }] : []),
            ...(isTmtTAFilterAvailable ? [{ name: 'Tickmill Trader', id: '4' }] : []),
          ]}
          setState={setSearchFilters}
          state={searchFilters}
        />
      ) : null
    case FilterOptions.TradingAccount:
      return tradingAccounts?.length ? (
        <FilterModalChipFilter
          name={
            customLabels?.[FilterOptions.TradingAccount] !== undefined
              ? customLabels?.[FilterOptions.TradingAccount]
              : t('Trading Account.Trading Account')
          }
          filterType={FilterOptions.TradingAccount}
          options={tradingAccounts}
          setState={setSearchFilters}
          state={searchFilters}
        />
      ) : null
    case FilterOptions.SubscriptionType:
      return (
        <FilterModalChipFilter
          name={customLabels?.[FilterOptions.SubscriptionType] || t('Type')}
          filterType={FilterOptions.SubscriptionType}
          options={SubscriptionTypes}
          setState={setSearchFilters}
          state={searchFilters}
        />
      )
    case FilterOptions.Type:
      return types?.length ? (
        <FilterModalChipFilter
          name={customLabels?.[FilterOptions.Type] || t('Type')}
          filterType={FilterOptions.Type}
          options={types}
          setState={setSearchFilters}
          state={searchFilters}
        />
      ) : null
    case FilterOptions.SubscriptionLevel:
      return (
        <FilterModalChipFilter
          name={customLabels?.[FilterOptions.SubscriptionLevel] || t('Subscriptions.Level')}
          filterType={FilterOptions.SubscriptionLevel}
          options={SubscriptionLevels}
          setState={setSearchFilters}
          state={searchFilters}
        />
      )
    case FilterOptions.SubscriptionClassificationType:
      return (
        <FilterModalChipFilter
          name={
            customLabels?.[FilterOptions.SubscriptionClassificationType] ||
            t('Subscriptions.Classification Type')
          }
          filterType={FilterOptions.SubscriptionClassificationType}
          options={SubscriptionClassificationTypes}
          setState={setSearchFilters}
          state={searchFilters}
        />
      )
    case FilterOptions.SubscriptionStatus:
      return (
        <FilterModalChipFilter
          name={customLabels?.[FilterOptions.SubscriptionStatus] || t('Status')}
          filterType={FilterOptions.SubscriptionStatus}
          options={SubscriptionStatuses}
          setState={setSearchFilters}
          state={searchFilters}
        />
      )
    case FilterOptions.SubscriptionRequestType:
      return (
        <FilterModalChipFilter
          name={
            customLabels?.[FilterOptions.SubscriptionRequestType] || t('Subscriptions.Request Type')
          }
          filterType={FilterOptions.SubscriptionRequestType}
          options={SubscriptionRequestTypes}
          setState={setSearchFilters}
          state={searchFilters}
        />
      )
    case FilterOptions.Wallet:
      return wallets?.length ? (
        <FilterModalChipFilter
          name={customLabels?.[FilterOptions.Wallet] || t('Wallet.Wallet')}
          filterType={FilterOptions.Wallet}
          currencies={currencyOptions}
          options={wallets}
          setState={setSearchFilters}
          state={searchFilters}
        />
      ) : null
    case FilterOptions.AccountType:
      return tradingAccountTypes?.length ? (
        <FilterModalChipFilter
          name={customLabels?.[FilterOptions.AccountType] || t('Trading Account.Account type')}
          filterType={FilterOptions.AccountType}
          options={tradingAccountTypes}
          setState={setSearchFilters}
          state={searchFilters}
        />
      ) : null
    case FilterOptions.TransactionType:
      return transactionTypes?.length ? (
        <FilterModalChipFilter
          name={customLabels?.[FilterOptions.TransactionType] || t('Type')}
          filterType={FilterOptions.TransactionType}
          options={transactionTypes}
          setState={setSearchFilters}
          state={searchFilters}
        />
      ) : null
    case FilterOptions.TransactionStatus:
      return transactionStates?.length ? (
        <FilterModalChipFilter
          name={customLabels?.[FilterOptions.TransactionStatus] || t('Status')}
          filterType={FilterOptions.TransactionStatus}
          options={transactionStates}
          setState={setSearchFilters}
          state={searchFilters}
        />
      ) : null
    case FilterOptions.Commission:
      return (
        <FilterModalFromToFilter
          name={customLabels?.[FilterOptions.Commission] || t('IB.Commission')}
          setState={setSearchFilters}
          keys={{
            from: 'query_search_AmountFrom',
            to: 'query_search_AmountTo',
          }}
          state={searchFilters}
          isQueryParameter
        />
      )
    case FilterOptions.ClientVolume:
      return (
        <FilterModalFromToFilter
          name={customLabels?.[FilterOptions.ClientVolume] || t('IB.Volume')}
          setState={setSearchFilters}
          keys={{
            from: 'query_search_VolumeFrom',
            to: 'query_search_VolumeTo',
          }}
          state={searchFilters}
          isQueryParameter
        />
      )
    case FilterOptions.Volume:
      return (
        <FilterModalFromToFilter
          name={customLabels?.[FilterOptions.Volume] || t('IB.Volume')}
          setState={setSearchFilters}
          keys={{
            from: 'ca_search_VolumeFrom',
            to: 'ca_search_VolumeTo',
          }}
          state={searchFilters}
          isQueryParameter
        />
      )
    case FilterOptions.ClientIdAmount:
      return (
        <FilterModalFromToFilter
          name={customLabels?.[FilterOptions.ClientIdAmount] || t('Amount')}
          presets={[100, 1000, 10000]}
          setState={setSearchFilters}
          keys={{
            from: 'ca_search_ClientAmountFrom',
            to: 'ca_search_ClientAmountTo',
          }}
          state={searchFilters}
          isQueryParameter
        />
      )
    case FilterOptions.Price:
      return (
        <FilterModalFromToFilter
          name={customLabels?.[FilterOptions.Price] || t('Subscriptions.Price')}
          presets={[100, 1000, 10000]}
          setState={setSearchFilters}
          keys={{
            from: 'ca_search_PriceFrom',
            to: 'ca_search_PriceTo',
          }}
          state={searchFilters}
          isQueryParameter
        />
      )
    case FilterOptions.Amount:
      return (
        <FilterModalFromToFilter
          name={customLabels?.[FilterOptions.Amount] || t('Commission')}
          setState={setSearchFilters}
          keys={{
            from: 'ca_search_AmountFrom',
            to: 'ca_search_AmountTo',
          }}
          state={searchFilters}
          isQueryParameter
        />
      )
    case FilterOptions.Entity:
      return (
        <FilterModalChipFilter
          name={customLabels?.[FilterOptions.Entity] || t('IB.Entity')}
          filterType={FilterOptions.Entity}
          options={entities || AvailableEntities}
          setState={setSearchFilters}
          state={searchFilters}
        />
      )
    case FilterOptions.Currency:
      return currencyOptions?.length ? (
        <FilterModalChipFilter
          name={customLabels?.[FilterOptions.Currency] || t('Currency')}
          filterType={FilterOptions.Currency}
          options={currencyOptions}
          currencies={currencyOptions}
          setState={setSearchFilters}
          state={searchFilters}
        />
      ) : null
    case FilterOptions.RegistrationDate:
      return (
        <FilterModalCustomDateFilter
          keys={{
            from: 'ca_search_RegistrationDateFrom',
            to: 'ca_search_RegistrationDateTo',
          }}
          limits={dateLimits}
          allowFuture={allowFutureDate}
          name={customLabels?.[FilterOptions.RegistrationDate] || t('IB.Registration Date')}
          setState={setSearchFilters}
          state={searchFilters}
        />
      )
    case FilterOptions.Date:
      return (
        <FilterModalCustomDateFilter
          keys={{
            from: 'ca_search_DateFrom',
            to: 'ca_search_DateTo',
          }}
          limits={dateLimits}
          allowFuture={allowFutureDate}
          name={customLabels?.[FilterOptions.Date] || t('Date')}
          setState={setSearchFilters}
          state={searchFilters}
          presets={datePresets}
        />
      )
    case FilterOptions.Status:
      return status?.length ? (
        <FilterModalChipFilter
          name={customLabels?.[FilterOptions.Status] || t('Status')}
          filterType={FilterOptions.Status}
          options={status}
          setState={setSearchFilters}
          state={searchFilters}
        />
      ) : null
    case FilterOptions.Name:
      return (
        <FilterModalTextFieldFilter
          name={customLabels?.[FilterOptions.Name] || t('Stock Dividends.Name')}
          filterType={FilterOptions.Name}
          setState={setSearchFilters}
          state={searchFilters}
        />
      )
    default:
      return null
  }
}

import { useMemo } from 'react'

import { isSumsubNeeded } from '../utils/AccountAccess/accountKYCStatuses'
import { useAccountReadContext } from '../utils/AccountContextContext'

export const useDocumentVerificationPath = (): string => {
  const { account } = useAccountReadContext()

  return useMemo(() => {
    if (!account) {
      return '/profile/document-management'
    }
    if (isSumsubNeeded(account)) {
      return '/profile/verification'
    } else {
      return '/profile/document-management'
    }
  }, [account])
}

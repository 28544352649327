import React, { useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { Loading } from '../global/Loading/Loading'
import { useSessionEntity } from '../global/context/EntityContext'
import { ToastContext, errorToast } from '../global/toast/Toast'
import { CreateLead, LeadMembersEnum } from '../model/CreateLead'
import { TickmillProductType } from '../model/TickmillProductType'
import { useNavigate } from '../navigation/custom-react-router-dom'
import { errorOptions, resolveApiErrorContent } from '../routing/RouterToaster'
import { ResponseError, useApiClient } from '../utils/ApiClient'
import { ClientApiClient } from '../utils/clientApi'
import { TickmillCompaniesEnum, getTickmillPublicDomain } from '../utils/companyName.utils'
import { useFetchOne } from '../utils/useFetch'

export default function MarketingPage() {
  const { t } = useTranslation()

  const setToast = useContext(ToastContext)

  const apiClient = useApiClient(ClientApiClient)
  const [searchParams] = useSearchParams()

  const entity = useSessionEntity()
  const navigate = useNavigate()
  const [lead, setLead] = useState<CreateLead>()

  const prepareLead = () => {
    const lead = {
      source: searchParams.get(LeadMembersEnum.SOURCE) || '',
      typeId: searchParams.get(LeadMembersEnum.TYPE_ID) || '1',
      genderId: searchParams.get(LeadMembersEnum.GENDER_ID) || '0',
      firstName: searchParams.get(LeadMembersEnum.FIRST_NAME) || '',
      nativeName: searchParams.get(LeadMembersEnum.NATIVE_NAME) || '',
      lastName: searchParams.get(LeadMembersEnum.LAST_NAME) || '',
      middleName: searchParams.get(LeadMembersEnum.MIDDLE_NAME) || '',
      birthDay: searchParams.get(LeadMembersEnum.BIRTHDAY) || '',
      email: searchParams.get(LeadMembersEnum.EMAIL) || '',
      phoneNumber: searchParams.get(LeadMembersEnum.PHONE_NUMBER) || '',
      countryCode: searchParams.get(LeadMembersEnum.COUNTRY_CODE) || '',
      countryId: searchParams.get(LeadMembersEnum.COUNTRY_ID) || '',
      languageId: searchParams.get(LeadMembersEnum.LANGUAGE_ID) || 'en',
      optIn: false,
      campaign: searchParams.get(LeadMembersEnum.CAMPAIGN) || '',
      campaignSource: searchParams.get(LeadMembersEnum.CAMPAIGN_SOURCE) || '',
      campaignMedium: searchParams.get(LeadMembersEnum.CAMPAIGN_MEDIUM) || '',
      campaignTerm: searchParams.get(LeadMembersEnum.CAMPAIGN_TERM) || '',
      campaignContent: searchParams.get(LeadMembersEnum.CAMPAIGN_CONTENT) || '',
      referrer: searchParams.get(LeadMembersEnum.REFERRER) || '',
      referralCode: searchParams.get(LeadMembersEnum.REFERRER_CODE) || '',
      tickmillCompanyId: Number(searchParams.get(LeadMembersEnum.TICKMILL_COMPANY_ID)) || entity,
      tickmillPublicDomain: getTickmillPublicDomain(),
      productIds:
        entity === TickmillCompaniesEnum.TICKMILL_UK
          ? [TickmillProductType.CFD, TickmillProductType.ETD]
          : [TickmillProductType.CFD],
      agreedLegalDocuments: [],
    }
    setLead(lead)

    return lead
  }

  const leadCallback = useCallback(async () => {
    try {
      const res = await apiClient.createLead(prepareLead())
      return res
    } catch (err: unknown) {
      const error = err as ResponseError
      const code = error?.response?.response?.data?.code

      const errorDisplayContent = resolveApiErrorContent(code as errorOptions, t)

      if (!errorDisplayContent.title) {
        setToast(errorToast(t('Something went wrong! Please try again later')))
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { data, isLoading } = useFetchOne(leadCallback)

  if (data) {
    const params = new URLSearchParams()
    lead && Object.entries(lead).map(([k, v]) => params.set(k.toString(), v.toString()))
    navigate(`/sign-up?${params.toString()}`)
  }

  return <Loading isLoading={isLoading} showLoadingIcon text={t('Creating lead')} />
}

import { ElementType, FC } from 'react'
import classNames from 'classnames'

import { IconProps } from '../../icons/IconProps'

import styles from './Badge.module.scss'

export type BadgeVariantType =
  | 'active'
  | 'pending'
  | 'completed'
  | 'ended'
  | 'approved'
  | 'cancelled'
  | 'failed'
  | 'declined'
  | 'rejected'
  | 'reversed'
  | 'new'
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'success'
  | 'successNew'
  | 'surfaceSuccess'
  | 'warning'
  | 'danger'
  | 'light'
  | 'demo'
  | 'live'
  | 'inactive'
  | 'expired'

export type BadgeColor = 'green' | 'blue' | 'red' | 'yellow' | 'black'

type BaseBadgeProps = {
  text: string
  onClick?(): void
}

type LegacyBadgeProps = BaseBadgeProps & {
  isNew?: false
  appearance?: BadgeVariantType
  color?: never
  startIcon?: never
  endIcon?: never
}

type NewBadgeProps = BaseBadgeProps & {
  isNew: true
  appearance?: never
  color?: BadgeColor
  startIcon?: ElementType<IconProps>
  endIcon?: ElementType<IconProps>
}

export type BadgeProps = LegacyBadgeProps | NewBadgeProps

export const Badge: FC<BadgeProps> & {
  New: FC<Omit<BadgeProps, 'isNew'>>
} = (props) => {
  const { text, appearance, onClick, color, isNew, startIcon: StartIcon, endIcon: EndIcon } = props

  return (
    <span
      className={classNames(styles.badge, {
        [styles[appearance as BadgeVariantType]]: !!appearance,
        [styles.isNew]: isNew,
        [styles[color as BadgeColor]]: !!color,
        [styles.hasStartIcon]: !!StartIcon,
        [styles.hasEndIcon]: !!EndIcon,
        [styles.clickable]: !!onClick,
      })}
      onClick={onClick}
    >
      {StartIcon && <StartIcon size={24} color='alwaysWhite' />}
      {text}
      {EndIcon && <EndIcon size={24} color='alwaysWhite' />}
    </span>
  )
}

Badge.New = (props) => {
  return (
    <Badge
      isNew
      color={props.color || 'green'}
      onClick={props.onClick}
      text={props.text}
      startIcon={props.startIcon}
      endIcon={props.endIcon}
    />
  )
}

import { useCallback, useMemo } from 'react'
import ReactGA from 'react-ga4'

import { useSessionLanguage } from '../global/context/SessionSettingsContext'
import { GAEventPaths, GA_EVENTS } from '../model/GAEvents'
import { useAccountReadContext } from './AccountContextContext'

interface GAEventBaseProperties {
  ca_client_id?: string
  ca_timestamp?: string
  ca_language?: string
  [key: string]: string | number | boolean | undefined
}

const buildEventWithProperties = (eventName: string, properties?: GAEventBaseProperties) => {
  return () => {
    ReactGA.event(eventName, {
      ...properties,
      ca_timestamp: properties?.ca_timestamp || new Date().toISOString(),
    })
  }
}

const buildEventStructure = (
  events: Record<string, any>,
  properties?: GAEventBaseProperties
): Record<string, any> => {
  return Object.entries(events).reduce(
    (result, [key, value]) => ({
      ...result,
      [key]:
        typeof value === 'string'
          ? buildEventWithProperties(value, properties)
          : buildEventStructure(value, properties),
    }),
    {}
  )
}

const callGAEvents = (properties?: GAEventBaseProperties): GAEventPaths =>
  buildEventStructure(GA_EVENTS, properties) as GAEventPaths

export const useGAEvents = (): {
  callGAEvents: (properties?: GAEventBaseProperties) => GAEventPaths
} => {
  const { account } = useAccountReadContext()
  const locale = useSessionLanguage()

  const baseProperties = useMemo(
    () => ({
      ca_client_id: account?.id,
      ca_language: locale,
    }),
    [account?.id, locale]
  )

  const memoizedCallGAEvents = useCallback(
    (props?: GAEventBaseProperties) => callGAEvents({ ...baseProperties, ...props }),
    [baseProperties]
  )

  return { callGAEvents: memoizedCallGAEvents }
}

import { useContext } from 'react'

import {
  CorporateFormValues,
  SignupFormValues,
  useSignup,
} from '../../../global/context/SignupContext'
import { AccountType } from '../../../model/AccountType'
import { LeadDto } from '../../../model/LeadDto'
import { AuthUser } from '../../../model/User'
import { useNavigate } from '../../../navigation/custom-react-router-dom'
import { useApiClient } from '../../../utils/ApiClient'
import { AuthSessionContext } from '../../../utils/AuthContext'
import { ClientApiClient } from '../../../utils/clientApi'
import { getTickmillPublicDomain } from '../../../utils/companyName.utils'
import { loginToAuth } from '../../../utils/loginToAuth'
import { TokenStorage } from '../../../utils/tokenStorage'
import { isZero } from '../../../utils/validations'
import { wait } from '../../../utils/wait'
import { ClientRegisterValues } from '../types'

interface CreateClient {
  signInAndMoveToFinancialInfo: (values: SignupFormValues) => Promise<void>
  denormalize: (
    lead: LeadDto,
    data: SignupFormValues,
    corporateData?: CorporateFormValues
  ) => ClientRegisterValues
}

export const useCreateClient = (): CreateClient => {
  const apiClient = useApiClient(ClientApiClient)
  const navigate = useNavigate()
  const [, setAuth] = useContext(AuthSessionContext)
  const { signupData, resetLead } = useSignup()

  const signInAndMoveToFinancialInfo = async (values: SignupFormValues) => {
    await wait(1000)
    await doLogin(values, async (auth) => {
      resetLead()
      navigate('/sign-up/financial-info/' + auth.id)
    })
  }

  const doLogin = async (values: SignupFormValues, callback: (auth: AuthUser) => Promise<void>) => {
    if (!signupData.lead) {
      return
    }
    const login = await apiClient.login({
      email: signupData.lead.email,
      password: values?.password,
    })
    const auth = loginToAuth(login)
    if (!auth) {
      return
    }
    setAuth(auth)
    localStorage.setItem('auth', JSON.stringify(auth))
    TokenStorage.storeToken('access', auth.accessToken)
    TokenStorage.storeToken('refresh', auth.refreshToken)
    TokenStorage.storeSession(auth.sessionId)

    await callback?.(auth)
  }

  const denormalize = (
    lead: LeadDto,
    data: SignupFormValues,
    corporateData?: CorporateFormValues
  ): ClientRegisterValues => {
    const formVales = {
      // step 4
      firstname: lead.firstName,
      middleName: lead.middleName,
      lastname: lead.lastName,
      nativeName: lead.nativeName,
      genderId: lead.gender.id,
      primaryPhoneNumberCountryCode: lead.countryCode,
      primaryPhoneNumber: lead.phoneNumber,
      primaryEmail: lead.email,
      birthday: lead.birthDay,
      tickmillCompanyId: lead.tickmillCompany.id,
      type: lead.type.id,
      communicationLanguageId: lead.language.id,
      primaryAddressCountryId: lead.country.id,
      primaryAddressStreet: data.address,
      primaryAddressState: data.primaryAddressState,
      primaryAddressCity: data.primaryAddressCity,
      primaryAddressStreetHouseNo: data.streetHouseNumber,
      primaryAddressStreetHouseNoNotAvailable: data.streetHouseNumberNotAvailable,
      primaryAddressPostalCode: data.primaryAddressPostalCode,
      nationalities: data.nationalities.map((n, i) => ({
        nationalityId: n.id,
        isPrimary: isZero(i),
      })),
      personalId: data.personalId,
      personalIdTypeId: data.personalIdTypeId,
      taxId: !data.taxIdNotAvailable ? data.taxId : '',
      taxIdAvailable: !data.taxIdNotAvailable && !!data.taxId,
      isPoliticallyExposedPerson: data.isPoliticallyExposedPerson,
      mainIbCode: data.mainIbCode,
      affToken: data.affiliateToken,
      password: data.password || '',
      taxIdUnavailableReasonId: data.taxIdUnavailableReasonId || null,
      tickmillPublicDomain: getTickmillPublicDomain(),

      // step 6
      shouldReceiveNewsletter: lead.optIn || false,
      agreedLegalDocuments: data?.agreedLegalDocuments || [],
      marketDataClassificationId: data?.marketDataClassificationId || undefined,
    }

    if (lead.type.id === AccountType.Corporate) {
      const companyDetailsValues = {
        // step 7
        companyName: corporateData?.companyName,
        companyRegistrationDate: corporateData?.companyRegistrationDate,
        companyRegistrationNumber: corporateData?.companyRegistrationNumber,
        companyTypeId: corporateData?.companyTypeId,

        companyAddressCountry: corporateData?.companyAddressCountry,
        companyAddressCity: corporateData?.registrationAddress?.companyAddressCity,
        companyAddressPostalCode: corporateData?.registrationAddress?.companyAddressPostalCode,
        companyAddressState: corporateData?.registrationAddress?.companyAddressState,
        companyAddressStreet: corporateData?.registrationAddress?.companyAddressStreet,

        companyPhysicalAddressCountry: corporateData?.companyAddressCountry,
        companyPhysicalAddressCity: corporateData?.physicalAddress?.companyAddressCity,
        companyPhysicalAddressPostalCode: corporateData?.physicalAddress?.companyAddressPostalCode,
        companyPhysicalAddressState: corporateData?.physicalAddress?.companyAddressState,
        companyPhysicalAddressStreet: corporateData?.physicalAddress?.companyAddressStreet,

        companyPhoneNumberCountryCode: corporateData?.companyPhoneNumberCountryCode,
        companyPhoneNumber: corporateData?.companyPhoneNumber,
        companyEmail: corporateData?.companyEmail,
        clientTests: corporateData?.clientTests,

        // step 8
        legalRepresentationBasisId: corporateData?.legalRepresentationBasis?.id?.toString(),
        ownershipStructureId: corporateData?.ownershipStructure?.id?.toString(),
        companyOwners: corporateData?.companyOwners,

        // step 9
        companyDirectors: corporateData?.directors || [],
        companyAuthorisedSignatories: corporateData?.signatories || [],
        companyAuthorisedTraders: corporateData?.traders || [],
      }
      return { ...formVales, ...companyDetailsValues }
    }

    return formVales
  }

  return {
    signInAndMoveToFinancialInfo,
    denormalize,
  }
}

import React, { useCallback, useMemo } from 'react'
import { SnsWebSdkBaseConfig } from '@sumsub/websdk'
import SumsubWebSdk from '@sumsub/websdk-react'
import { AnyEventName, AnyEventPayload, SnsWebSdkOptions } from '@sumsub/websdk/types/types'

import { SumsubMessage, SumsubStatusAnswer } from '../../model/Sumsub'
import { useNavigate } from '../../navigation/custom-react-router-dom'
import { isKycVerified } from '../../utils/AccountAccess/accountKYCStatuses'
import { useAccountReadContext } from '../../utils/AccountContextContext'
import { useApiClient } from '../../utils/ApiClient'
import { ClientApiClient } from '../../utils/clientApi'
import { CommonPaths } from '../../utils/path'
import { useFetchOne } from '../../utils/useFetch'
import { Loading } from '../Loading/Loading'
import { useProductReadContext } from '../context/ProductContext'
import { useSessionLanguage } from '../context/SessionSettingsContext'
import { detectThemeClientSide } from '../theme/Theme'

import styles from './Sumsub.module.scss'

export const Sumsub: React.FC = () => {
  const locale = useSessionLanguage()
  const apiClient = useApiClient(ClientApiClient)
  const theme = detectThemeClientSide()
  const navigate = useNavigate()
  const { account } = useAccountReadContext()
  const { product } = useProductReadContext()

  const getSumsubAccessToken = useCallback(async (): Promise<string> => {
    const response = await apiClient.getSumsubAccessToken()
    return response.token
  }, [apiClient])

  const { data: token, isLoading } = useFetchOne<string>(getSumsubAccessToken)

  const sumsubConfig: SnsWebSdkBaseConfig = useMemo(() => {
    return {
      lang: locale,
      theme,
    }
  }, [locale, theme])

  const sumsubOptions: SnsWebSdkOptions = {
    enableScrollIntoView: false,
  }

  const checkOCRFailed = (payload: AnyEventPayload) => {
    const OCR_CATCH_STRING = 'as it is on your identity document'
    if ((payload as any).reviewResult?.reviewAnswer === SumsubStatusAnswer.Red) {
      const comment = (payload as any).moderationComment
      if (comment?.includes(OCR_CATCH_STRING)) {
        return true
      }
    }
    return false
  }

  const handleMessage = (message: AnyEventName, payload: AnyEventPayload) => {
    // TODO: remove this console.log, after testing is finalized
    console.log({ message, payload })
    if (
      !isKycVerified(account, product) &&
      message === SumsubMessage.StatusChanged &&
      ((payload as any)?.reviewResult?.reviewAnswer === SumsubStatusAnswer.Green ||
        checkOCRFailed(payload))
    ) {
      navigate(CommonPaths.AccountLoading)
    } else if (message === SumsubMessage.Error) {
      console.log('sumsub error: ', message)
    }
  }

  return (
    <Loading isLoading={isLoading} showLoadingIcon>
      <div className={styles.wrapper}>
        {token && (
          <SumsubWebSdk
            options={sumsubOptions}
            className={styles.sumsub}
            accessToken={token ?? ''}
            expirationHandler={getSumsubAccessToken}
            config={sumsubConfig}
            onMessage={handleMessage}
            onError={(error) => console.log('sumsub error:', { error })}
          />
        )}
      </div>
    </Loading>
  )
}

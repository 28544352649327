import React from 'react'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

import { Badge, BadgeVariantType } from '../../global/badge/Badge'
import { NameDto } from '../../model/NameDto'
import { SubscriptionStatus } from '../../model/SubscriptionsDto'

interface DocumentBadgeStatusProps extends NameDto {
  type?: SubscriptionStatus
}

type SubscriptionStatusBadgeVariantMapType = Record<
  SubscriptionStatus,
  'active' | 'cancelled' | 'completed' | 'failed' | 'pending' | 'rejected' | 'expired'
>

const SubscriptionStatusBadgeVariantAppearanceMap: SubscriptionStatusBadgeVariantMapType = {
  [SubscriptionStatus.ACTIVE]: 'active',
  [SubscriptionStatus.CANCELLED]: 'cancelled',
  [SubscriptionStatus.COMPLETED]: 'completed',
  [SubscriptionStatus.DEDUCTING_FUNDS]: 'pending',
  [SubscriptionStatus.FAILED]: 'failed',
  [SubscriptionStatus.IN_PROCESS]: 'pending',
  [SubscriptionStatus.IN_PROCESS_CANCEL]: 'pending',
  [SubscriptionStatus.PENDING]: 'pending',
}

export const getSubscriptionStatusBadgeLabel = (
  status: SubscriptionStatus,
  t: TFunction<'translation', undefined, 'translation'>
): string => {
  if (status === SubscriptionStatus.ACTIVE) {
    return t('Active')
  } else if (status === SubscriptionStatus.CANCELLED) {
    return t('Cancelled')
  } else if (status === SubscriptionStatus.COMPLETED) {
    return t('Completed')
  } else if (status === SubscriptionStatus.DEDUCTING_FUNDS) {
    return t('Deducting Funds')
  } else if (status === SubscriptionStatus.FAILED) {
    return t('Failed')
  } else if (status === SubscriptionStatus.IN_PROCESS) {
    return t('In Process')
  } else if (status === SubscriptionStatus.IN_PROCESS_CANCEL) {
    return t('Pending')
  } else if (status === SubscriptionStatus.PENDING) {
    return t('Pending')
  } else {
    return t('Pending')
  }
}

export const SubscriptionBadgeStatus: React.FC<DocumentBadgeStatusProps> = (props) => {
  const { id } = props

  const { t } = useTranslation()

  return (
    <Badge
      text={getSubscriptionStatusBadgeLabel(id, t)}
      appearance={
        SubscriptionStatusBadgeVariantAppearanceMap[id as SubscriptionStatus] as BadgeVariantType
      }
    />
  )
}

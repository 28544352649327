export interface UpdateSubscriptionRequest {
  subscriptions?: {
    subscriptionDetailId: string | undefined
    subscriptionRequestTypeId?: SubscriptionRequestType
  }[]
  subscriptionTimeInForceId: SubscriptionTimeInForce
  overrideOpenPositionsForCancel?: boolean
}

export enum SubscriptionRequestType {
  Add = 1,
  Cancel = 2,
  Update = 3,
  Resubscribe = 4,
}

// Enumerates the time in force for a subscription
export enum SubscriptionTimeInForce {
  Immediate = 1,
  BeginningOfNextMonth = 2,
}

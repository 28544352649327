import React, { CSSProperties, PropsWithChildren } from 'react'

import { Link } from '../navigation/custom-react-router-dom'

interface Props {
  url?: string
  title?: string
  target?: string

  className?: string
  style?: CSSProperties
}

export const ExternalLink: React.FC<PropsWithChildren<Props>> = (props) => {
  const { url, title, className, style, children, target = '_blank' } = props

  if (!url) {
    if (className) {
      return (
        <span className={className} style={style} title={title}>
          {children}
        </span>
      )
    }

    return <React.Fragment>{children}</React.Fragment>
  }

  if (url.startsWith(origin + '/')) {
    return (
      <Link {...props} to={url.substring(origin.length)}>
        {children}
      </Link>
    )
  }
  return (
    <a
      href={url}
      className={className}
      style={style}
      title={title}
      target={target}
      rel='noopener noreferrer'
    >
      {children}
    </a>
  )
}

import { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import classNames from 'classnames'

import { Button } from '../global/button/Button'
import { TwoFAProviderEnum } from '../model/TwoFactorAuthentication'
import { TwoFactor } from '../model/User'
import { Text } from '../ui/Typography/Typography'
import { secondsToMMSS } from '../utils/date.utils'
import { Resend2FAError } from './TwoFactorAuthPage'
import { TwoFactorAuthLocationState } from './useTwoFactorAuth'

import styles from './TwoFactorAuthPage.module.scss'

interface UseTwoFactorAuthTextProps {
  resendCode: () => Promise<void>
  disableResend: boolean
  initialCountdown: number
  resendError: Resend2FAError
  twoFactor?: TwoFactor
}

interface UseTwoFactorAuthTextReturn {
  resendText: JSX.Element
  noteText: JSX.Element
  errorText: JSX.Element
}

export const useTwoFactorAuthText = (
  props: UseTwoFactorAuthTextProps
): UseTwoFactorAuthTextReturn => {
  const { twoFactor, resendCode, resendError, disableResend, initialCountdown } = props

  const location = useLocation()
  const locationState = location.state as TwoFactorAuthLocationState

  const { t } = useTranslation()

  const errorText = useMemo(() => {
    if (resendError.tooManyInputs && !resendError.hideResend) {
      return (
        <div className={styles.notice}>
          <span className={styles.important}>{t('Wallet.Important!').toUpperCase()} </span>
          {t(
            'TwoFactorAuth.You’ve reached the maximum attempts. Please repeat your request, resend the code or contact our support team'
          )}
        </div>
      )
    }

    return <></>
  }, [resendError.tooManyInputs, resendError.hideResend, t])

  const resendText = useMemo(() => {
    if (twoFactor?.provider?.id === TwoFAProviderEnum.GoogleAuthenticator) {
      return <></>
    } else if (resendError.hideResend) {
      return (
        <div className={styles.notice}>
          <span className={styles.important}>{t('Wallet.Important!').toUpperCase()} </span>
          {t('errors.Too many resend attempts', {
            minutes: secondsToMMSS(resendError.errorTimer ?? 1800),
          })}
        </div>
      )
    } else if (initialCountdown > 0) {
      const providerId = twoFactor?.provider?.id || twoFactor?.typeId
      if (providerId === TwoFAProviderEnum.Email) {
        return (
          <div className={styles.notice}>
            {t('TwoFactorAuth.Please allow at least 30 seconds for the code to reach your inbox', {
              seconds: initialCountdown,
            })}
          </div>
        )
      }
      if (providerId === TwoFAProviderEnum.SMS) {
        return (
          <div className={styles.notice}>
            {t('TwoFactorAuth.Please allow at least 30 seconds for the code to reach your phone', {
              seconds: initialCountdown,
            })}
          </div>
        )
      }
      return <></>
    } else {
      return (
        <div className='is-flex text-align-center mt-4'>
          <span className={classNames(styles.text, 'is-flex is-align-items-center')}>
            {t('Sign up.Not get code')}
            <Button
              className='pl-1'
              type='button'
              disabled={disableResend}
              onClick={resendCode}
              appearance='plain'
              size='XS'
            >
              <Text>{' ' + t('Sign up.Resend')}</Text>
            </Button>
          </span>
        </div>
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialCountdown, resendError, t, twoFactor?.provider?.id])

  const noteText = useMemo(() => {
    if (twoFactor?.provider?.id === TwoFAProviderEnum.GoogleAuthenticator) {
      return (
        <Trans
          i18nKey={'Sign up.According to your security setting Google Authenticator App'}
          components={{ 1: <span className={styles.email} /> }}
        />
      )
    } else {
      return (
        <span>
          {t('Sign up.According to your security settings, a verification code is sent to')}{' '}
          {(locationState?.twoFactorAuthData?.twoFactor?.length || 0 > 0) &&
            locationState.twoFactorAuthData?.twoFactor?.map((method, idx, array) => (
              <span key={idx}>
                {idx > 0 && idx === array.length - 1 ? (
                  <span> {t('and')} </span>
                ) : idx > 0 ? (
                  ', '
                ) : (
                  ' '
                )}
                <span className={styles.email}>{method.authMedium}</span>
              </span>
            ))}
          <span>.</span>
          <br />
          {t('Sign up.Please enter the code to login to your Client Area')}
        </span>
      )
    }
  }, [locationState?.twoFactorAuthData?.twoFactor, t, twoFactor?.provider?.id])

  return { resendText, noteText, errorText }
}

import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

import { Loading } from '../../global/Loading/Loading'
import { ScrollToIds, useScrollToElementIds } from '../../hooks/useScrollToElementIds'
import { GiftIcon } from '../../icons/GiftIcon'
import {
  BronzeIcon,
  DiamondIcon,
  EliteIcon,
  ElitePlusIcon,
  GoldIcon,
  LegendIcon,
  PlatinumIcon,
  SilverIcon,
  UltimateIcon,
} from '../../icons/TierIcons'
import {
  IntroducingBrokerDetailsDto,
  LoyaltyTierDto,
  loyaltyBonusStatus,
} from '../../model/IntroducingBrokerDetailsDto'
import { TextSmall } from '../../ui/Typography/Typography'
import { isMobile } from '../../utils/domUtils'
import { sleep } from '../../utils/transaction.utils'
import { useScrollToTop } from '../../utils/useScrollToTop'
import { isZero } from '../../utils/validations'
import { SectionHeader } from '../Dashboard/components/SectionHeader/SectionHeader'
import { LoyaltyDetailsCard } from './LoyaltyDetailsCard'
import { useLoyaltyTermsUrl } from './useLoyaltyTermsUrl'

import styles from './LoyaltyPage.module.scss'

export interface Props {
  loyaltyTiers: LoyaltyTierDto[] | undefined
  introducingBroker: IntroducingBrokerDetailsDto | null
  onSubmitReward(rewardId: string): Promise<void>
}

export interface LoyaltyMappedType {
  id: number
  name: string | null
  label: string | null
  minLots: string
  lots: number
  icon: React.ElementType
  amount: number
  groupId: number
  isCreated: boolean
  isRedeemed: boolean
  isApproved: boolean
  isPendingTransaction: boolean
  isDeactivated: boolean
  isCurrentTier: boolean
  htmlPrizeText: string | null
  subgroup: LoyaltyMappedType[]
  isNextTier: boolean
}

export const Loyalty: React.FC<Props> = ({ loyaltyTiers, introducingBroker, onSubmitReward }) => {
  useScrollToTop()
  const [expandedCardIndex, setExpandedCardIndex] = useState(-1)
  const [isLoading, setIsLoading] = useState(false)
  const termsUrl = useLoyaltyTermsUrl()
  const { scrollIntoView } = useScrollToElementIds()
  const handleCardExpand = (index: number) => {
    if (index === expandedCardIndex) {
      setExpandedCardIndex(-1)
    } else {
      setExpandedCardIndex(index)
      scrollIntoView([`loyalty-tier-details-card-${index + 1}`])
    }
  }
  const { t } = useTranslation()

  const getLoyaltyTiersData = useMemo(() => {
    return (loyaltyTiers || [])
      .map<LoyaltyMappedType>((tier) => {
        const currentLoyaltyReward = introducingBroker?.loyaltyDetails.loyaltyRewards.find(
          (x) => x.tierId === tier.id
        )
        return {
          id: tier.id,
          name: tier.name,
          label: tier.label,
          minLots: formatNumberToLots(tier.minLots, t),
          lots: tier.minLots,
          icon: tierIconByType[tier.id],
          amount: tier.amount,
          groupId: tier.groupId,
          isCreated: currentLoyaltyReward?.loyaltyBonusStatus.id === loyaltyBonusStatus.Created,
          isRedeemed: currentLoyaltyReward?.loyaltyBonusStatus.id === loyaltyBonusStatus.Redeemed,
          isApproved:
            (currentLoyaltyReward?.loyaltyBonusStatus.id === loyaltyBonusStatus.Approved &&
              !currentLoyaltyReward.transactionId) ??
            false,
          isPendingTransaction:
            (currentLoyaltyReward?.loyaltyBonusStatus.id === loyaltyBonusStatus.Approved &&
              !!currentLoyaltyReward.transactionId) ??
            false,
          isDeactivated:
            (currentLoyaltyReward?.loyaltyBonusStatus.id !== loyaltyBonusStatus.Approved &&
              currentLoyaltyReward?.loyaltyBonusStatus.id !== loyaltyBonusStatus.Redeemed) ??
            false,
          htmlPrizeText: tier.htmlPrizeText,
          subgroup: [],
          isCurrentTier: tier.id === introducingBroker?.loyaltyDetails?.currentTier?.id,
          isNextTier: tier.id === introducingBroker?.loyaltyDetails?.nextTier?.id,
        }
      })
      .filter((t) => t.icon)
      .reduce<LoyaltyMappedType[]>((unique, o) => {
        if (!unique.some((obj) => obj.groupId === o.groupId)) {
          unique.push(o)
        } else {
          const index = unique.findIndex((obj) => obj.groupId === o.groupId)
          unique[index].subgroup.push(o)
        }
        return unique
      }, [])
      .map((t) => {
        const approvedOne = t?.subgroup.find((x) => x.isApproved)
        const isAnyNotRedeemed = t?.subgroup.find((x) => !x.isRedeemed)
        const isCurrentOne = t?.subgroup.find((x) => x.isCurrentTier)
        if (!t.subgroup.length) {
          return { ...t }
        }
        return {
          ...t,
          isApproved: !!approvedOne || t.isApproved,
          isRedeemed: isAnyNotRedeemed ? false : t.isRedeemed,
          id: t.isApproved ? t.id : approvedOne ? approvedOne.id : t.id,
          isCurrentTier: isCurrentOne?.isCurrentTier ?? t.isCurrentTier,
        }
      })
  }, [loyaltyTiers, introducingBroker, t])

  const submitReward = useCallback(
    async (tier: LoyaltyMappedType) => {
      const reward = introducingBroker?.loyaltyDetails.redeemableRewards.find(
        (x) => x.tierId === tier.id
      )
      if (reward) {
        if (reward.loyaltyBonusStatus.id === loyaltyBonusStatus.Approved) {
          setIsLoading(true)
          await onSubmitReward(reward.id)
        } else {
          console.error('Reward not approved')
        }
      } else {
        console.error('Reward not found')
      }
      await sleep(3000)
      setIsLoading(false)
    },
    [introducingBroker?.loyaltyDetails.redeemableRewards, onSubmitReward]
  )

  if (!introducingBroker) {
    return null
  }
  return (
    <>
      <div className={styles.wrapper}>
        <SectionHeader
          title={t('Loyalty Program.Loyalty Program')}
          icon={<GiftIcon />}
          id={ScrollToIds.LoyaltyProgramHeader}
        />
        <div className={styles.subHeader}>
          <h5>
            {introducingBroker.loyaltyProgramEligible && introducingBroker.loyaltyProgramEnabled ? (
              <>
                <div className={styles.currentTier}>
                  {introducingBroker.loyaltyDetails.currentTier &&
                    (isMobile() ? (
                      <>
                        {t('Loyalty Program.Your current IB tier')}:
                        <b>
                          {introducingBroker.loyaltyDetails.currentTier.label?.toLocaleUpperCase()}
                        </b>
                      </>
                    ) : (
                      <>
                        {t('Loyalty Program.Your current IB tier')}{' '}
                        {React.createElement(
                          tierIconByType[introducingBroker.loyaltyDetails.currentTier.id]
                        )}{' '}
                        <b>
                          {introducingBroker.loyaltyDetails.currentTier.label?.toLocaleUpperCase()}
                        </b>
                      </>
                    ))}
                </div>
                <TextSmall>
                  {t('Loyalty Program.Your current volume is of')}{' '}
                  <b>
                    {formatNumberToLots(
                      introducingBroker?.loyaltyDetails.loyaltyLots as number,
                      t,
                      true
                    )}
                  </b>
                </TextSmall>
                {introducingBroker?.loyaltyDetails.nextTier && (
                  <TextSmall
                    dangerouslySetInnerHTML={{
                      __html: t('Loyalty Program.lots to unlock the next', {
                        lots: introducingBroker?.loyaltyDetails.loyaltyLotsToNextTier,
                        cash: introducingBroker?.loyaltyDetails.nextTier?.amount.toLocaleString(),
                      }),
                    }}
                  />
                )}
              </>
            ) : (
              <div className={styles.rightTrack}>
                <TextSmall
                  dangerouslySetInnerHTML={{
                    __html: t('Loyalty Program.You are on the right track'),
                  }}
                />
                <>
                  <br />
                  <TextSmall>
                    {t('Loyalty Program.Your current volume is of')}{' '}
                    <b>
                      {formatNumberToLots(
                        introducingBroker?.loyaltyDetails.loyaltyLots as number,
                        t,
                        true
                      )}
                    </b>
                  </TextSmall>
                </>
              </div>
            )}
            <a className='is-link' target='_blank' href={termsUrl} rel='noreferrer'>
              {t('Loyalty Program.Find out more info')}
            </a>
          </h5>
        </div>
        <Loading isLoading={isLoading} showLoadingIcon>
          <div className={styles.container}>
            {getLoyaltyTiersData.map((tier, index) => (
              <LoyaltyDetailsCard
                cardId={`loyalty-tier-details-card-${index + 1}`}
                key={`loyalty-tier-details-card-${index}`}
                isFirstOne={isZero(index)}
                isLastOne={index === getLoyaltyTiersData.length - 1}
                data={tier}
                introducingBroker={introducingBroker}
                isExpanded={index === expandedCardIndex}
                onExpand={() => handleCardExpand(index)}
                onSubmitReward={submitReward}
                isLoading={isLoading}
              />
            ))}
          </div>
        </Loading>
      </div>
    </>
  )
}

export const tierIconByType: Record<number, React.ElementType> = {
  1: BronzeIcon,
  2: SilverIcon,
  3: GoldIcon,
  4: PlatinumIcon,
  5: DiamondIcon,
  6: EliteIcon,
  7: ElitePlusIcon,
  8: ElitePlusIcon,
  9: ElitePlusIcon,
  10: ElitePlusIcon,
  11: ElitePlusIcon,
  12: ElitePlusIcon,
  13: ElitePlusIcon,
  14: ElitePlusIcon,
  15: ElitePlusIcon,
  16: ElitePlusIcon,
  17: ElitePlusIcon,
  18: ElitePlusIcon,
  19: ElitePlusIcon,
  20: ElitePlusIcon,
  21: ElitePlusIcon,
  22: ElitePlusIcon,
  23: ElitePlusIcon,
  24: ElitePlusIcon,
  25: LegendIcon,
  26: UltimateIcon,
}

export function formatNumberToLots(num: number, t: TFunction, upperCase?: boolean): string {
  let formattedNum: string

  if (num < 50000) {
    formattedNum = new Intl.NumberFormat().format(num)
    return `${formattedNum} ${upperCase ? t('Loyalty Program.LOTS') : t('Loyalty Program.lots')}`
  } else if (num >= 50000 && num < 1000000) {
    formattedNum = new Intl.NumberFormat().format(num)
    return `${formattedNum} ${upperCase ? t('Loyalty Program.LOTS') : t('Loyalty Program.lots')}`
  } else {
    if (isZero(num % 1000000)) {
      // whole million number
      formattedNum = `${Math.floor(num / 1000000)} ${
        upperCase ? t('Loyalty Program.MILLION') : t('Loyalty Program.million')
      }`
      return `${formattedNum} ${upperCase ? t('Loyalty Program.LOTS') : t('Loyalty Program.lots')}`
    } else {
      // decimals
      formattedNum = new Intl.NumberFormat().format(num)
    }
    return `${formattedNum} ${upperCase ? t('Loyalty Program.LOTS') : t('Loyalty Program.lots')}`
  }
}

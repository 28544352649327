import React, { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { RadioBoxProps, RadioButton } from '../radioButton/RadioButton'
import { SelectField, SelectFieldProps } from './SelectField'

import styles from './SelectModal.module.scss'

interface SelectableModalProps {
  title: string
  onCancel(): void
  onConfirm?(): void
  renderOptions(): React.ReactNode
  renderFooter?(): React.ReactNode
}

export const SelectModal: React.FC<SelectableModalProps> = (props) => {
  const { onCancel, title, renderOptions, renderFooter, onConfirm } = props

  return (
    <React.Fragment>
      <header className='modal-card-head'>
        <p className='modal-card-title'>{title}</p>
      </header>
      <div className={classNames('modal-card-body', 'compact', styles.body)}>{renderOptions()}</div>
      <SelectModalFooter renderFooter={renderFooter} onCancel={onCancel} onConfirm={onConfirm} />
    </React.Fragment>
  )
}

const SelectModalFooter: React.FC<Omit<SelectableModalProps, 'title' | 'renderOptions'>> = (
  props
) => {
  const { renderFooter, onCancel, onConfirm } = props

  const { t } = useTranslation()

  if (renderFooter) {
    return <footer className={classNames('modal-card-foot', styles.foot)}>{renderFooter()}</footer>
  }

  return (
    <footer className={classNames('modal-card-foot', styles.foot)}>
      <button className='button' onClick={onCancel} type='button'>
        {t('Cancel')}
      </button>
      {!!onConfirm && (
        <button className='button' onClick={onConfirm} type='button'>
          {t('Confirm')}
        </button>
      )}
    </footer>
  )
}

export interface SelectModalOptionProps extends Omit<RadioBoxProps, 'value'> {
  value: boolean
  onClick<T>(value: T): void
  subModal?: SelectFieldProps
  spacing?: 'compact' | 'normal'
}

export const SelectModalOption: React.FC<PropsWithChildren<SelectModalOptionProps>> = (props) => {
  const {
    label,
    hint,
    value,
    disabled,
    onClick,
    subModal,
    children,
    spacing = 'normal',
    ...restProps
  } = props

  return (
    <div
      className={classNames(styles.option, {
        [styles.compact]: spacing === 'compact',
      })}
    >
      <label>
        <RadioButton
          {...restProps}
          checked={value}
          disabled={disabled}
          onClick={() => onClick(value)}
        >
          <div className={styles.label}>{label}</div>
          <div className={styles.hint}>{hint}</div>
          <div className={styles.content}>{children}</div>
        </RadioButton>
      </label>
      {subModal && <SelectField className={styles.subSelect} {...subModal} />}
    </div>
  )
}

import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useSessionEntity } from '../../../../global/context/EntityContext'
import {
  CorporateFormValues,
  HasEmirStep,
  useSignup,
} from '../../../../global/context/SignupContext'
import { useNavigate } from '../../../../navigation/custom-react-router-dom'
import { useApiClient } from '../../../../utils/ApiClient'
import { ClientApiClient } from '../../../../utils/clientApi'
import { isTickmillEUType, isTickmillUKType } from '../../../../utils/companyName.utils'
import { isNumber } from '../../../../utils/validations'
import { SignupWarningMessages } from '../../Components/SignupWarningMessages'
import { useCreateClient } from '../../hooks/useCreateClient'
import { CompanyDetailsStep1Page } from './CompanyDetailsStep1/CompanyDetailsStep1Page'
import { CompanyDetailsStep2Page } from './CompanyDetailsStep2/CompanyDetailsStep2Page'
import { CompanyDetailsStep3FormValues } from './CompanyDetailsStep3/CompanyDetailsStep3Form'
import { CompanyDetailsStep3Page } from './CompanyDetailsStep3/CompanyDetailsStep3Page'
import { CompanyDetailsStep4FormValues } from './CompanyDetailsStep4/CompanyDetailsStep4Form'
import { CompanyDetailsStep4Page } from './CompanyDetailsStep4/CompanyDetailsStep4Page'
import { CompanyDetailsTemplate } from './CompanyDetailsTemplate'

export const CompanyDetailsOrchestrator: React.FC = () => {
  const { step } = useParams<{ step: string }>()
  const [subStep, setSubStep] = useState(Number(step ?? 1) ?? 1)
  const apiClient = useApiClient(ClientApiClient)
  const navigate = useNavigate()
  const { denormalize, signInAndMoveToFinancialInfo } = useCreateClient()
  const entity = useSessionEntity()

  const { signupData, updateCorpValues, updateTotalSteps } = useSignup()
  const hasEmirStep =
    (isTickmillEUType(
      entity ?? signupData.lead?.tickmillCompany.id ?? signupData.values.tickmillCompanyId
    ) ||
      isTickmillUKType(
        entity ?? signupData.lead?.tickmillCompany.id ?? signupData.values.tickmillCompanyId
      )) ??
    false
  const totalSteps = signupData.totalSteps.companyDetails

  useEffect(() => {
    updateTotalSteps({ companyDetails: hasEmirStep ? HasEmirStep.Yes : HasEmirStep.No })
  }, [entity, hasEmirStep, signupData.lead, signupData.values.tickmillCompanyId, updateTotalSteps])

  useEffect(() => {
    if (Object.keys(signupData.values).length < 2) {
      navigate('/sign-up/')
    }
    if (step && !isNumber(step)) {
      setSubStep(1)
    }
    if (step && Number(step) > totalSteps) {
      setSubStep(1)
    }
    if (step) {
      navigate('/sign-up/company-details/')
    }
  }, [navigate, signupData.values, step, totalSteps])

  const nextStep = (values?: Partial<CorporateFormValues>, skipSubStep?: boolean) => {
    updateCorpValues(values)
    if (skipSubStep) {
      return handleSubmit(values as CompanyDetailsStep3FormValues)
    }
    setSubStep(subStep + 1)
  }

  const handleSubmit = async (
    values: Partial<CompanyDetailsStep3FormValues | CompanyDetailsStep4FormValues>
  ) => {
    try {
      const corporateData = {
        ...signupData.corporateValues,
        ...values,
      } as CorporateFormValues
      updateCorpValues(corporateData)
      if (!signupData.lead) {
        return
      }
      await apiClient.createClient(denormalize(signupData.lead, signupData.values, corporateData))
      await signInAndMoveToFinancialInfo(signupData.values)
    } catch (e: unknown) {
      console.error(e)
    }
  }

  const getPage = () => {
    if (subStep === 1) {
      return <CompanyDetailsStep1Page onSubmit={(v) => nextStep(v)} />
    }
    if (subStep === 2) {
      return <CompanyDetailsStep2Page onSubmit={(v) => nextStep(v)} />
    }
    if (subStep === 3) {
      return <CompanyDetailsStep3Page onSubmit={(v) => nextStep(v, !hasEmirStep)} />
    }
    if (subStep === 4) {
      return <CompanyDetailsStep4Page onSubmit={handleSubmit} />
    }
  }

  return (
    <CompanyDetailsTemplate
      lead={signupData.lead}
      step={subStep}
      goBack={subStep > 1 ? () => setSubStep(subStep - 1) : undefined}
      totalSteps={totalSteps}
    >
      <SignupWarningMessages leadTypeId={signupData.lead?.type?.id} />
      {getPage()}
    </CompanyDetailsTemplate>
  )
}

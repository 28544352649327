import { useCallback, useState } from 'react'
import UAParser from 'ua-parser-js'

import { isTMTPlatformType, isTradingViewPlatformType } from '../model/PlatformTypeEnum'
import { DemoTradingAccount, TradingAccount } from '../model/TradingAccount'
import { ResponseError, instanceOfAxiosError, useApiClient } from '../utils/ApiClient'
import { ClientApiClient } from '../utils/clientApi'
import { useTraderUrlByPlatform } from './useTraderUrlByPlatform'
import { useTradingViewTradeUrl } from './useTradingViewTradeUrl'

export const usePlatformTradeUrl = (): {
  getPlatformTradeUrl: (
    tradingAccount: TradingAccount | DemoTradingAccount | undefined
  ) => Promise<string | undefined>
  isLoading: boolean
} => {
  const [isLoading, setLoading] = useState(false)
  const apiClient = useApiClient(ClientApiClient)

  const mobileTMTTradeUrl = getMobileTMTTradeUrl()
  const tradingViewTradeUrl = useTradingViewTradeUrl()
  const getTraderUrlByPlatform = useTraderUrlByPlatform()

  const getPlatformTradeUrl = useCallback(
    async (tradingAccount: TradingAccount | DemoTradingAccount | undefined) => {
      setLoading(true)
      const platformId = tradingAccount?.platformType.id

      const platformTradeUrlFallback =
        process.env.REACT_APP_ENVIRONMENT === 'development' ||
        process.env.NODE_ENV === 'development'
          ? 'https://tmdev.prosp.devexperts.com'
          : 'https://tmt.tickmill.com'

      try {
        if (isTradingViewPlatformType(platformId)) {
          return tradingViewTradeUrl
        } else if (isTMTPlatformType(platformId)) {
          if (mobileTMTTradeUrl) {
            return mobileTMTTradeUrl
          }

          if (tradingAccount?.id) {
            const tmtSingleSignOnUrl = await apiClient
              .getTATradeSSOToken(tradingAccount.id)
              .then((response) => response.url)

            if (tmtSingleSignOnUrl) {
              return tmtSingleSignOnUrl
            }

            return platformTradeUrlFallback
          }
        }

        return getTraderUrlByPlatform(tradingAccount)
      } catch (error: unknown) {
        if (instanceOfAxiosError((error as ResponseError)?.response)) {
          return platformTradeUrlFallback
        }
      } finally {
        setLoading(false)
      }
    },
    [tradingViewTradeUrl, mobileTMTTradeUrl, getTraderUrlByPlatform, apiClient]
  )

  return { getPlatformTradeUrl, isLoading }
}

const getMobileTMTTradeUrl = () => {
  const osName = new UAParser().getResult().os.name?.toLowerCase()

  if (osName === 'ios') {
    return 'https://apps.apple.com/app/tickmill-trader/id6463237970'
  } else if (osName === 'android') {
    return 'https://play.google.com/store/apps/details?id=com.tickmilltrader&pli=1'
  }
}

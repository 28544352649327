import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { handleBaseTwoFactorErrors } from '../Profile/AccountSettings/handleBaseTwoFactorErrors'
import { TwoFactorAuthEvents } from '../TwoFactorAuth/useTwoFactorAuth'
import { useSnackbar } from '../global/context/SnackbarContext'
import { TwoFAProviderEnum } from '../model/TwoFactorAuthentication'
import { useNavigate } from '../navigation/custom-react-router-dom'
import { useApiClient } from '../utils/ApiClient'
import { ClientApiClient } from '../utils/clientApi'
import { ResetPasswordFormComplete } from './ResetPasswordFormComplete'

import styles from './ForgotPasswordPage.module.scss'

export interface ResetPasswordCompleteFormValues {
  email: string
  confirmPassword: string
  password: string
  token: string
}

export interface ResetPasswordFormValues {
  confirmPassword: string
  password: string
}

export const ResetPasswordPage: React.FC = () => {
  const { email, token } = useParams<{ email: string; token: string }>()
  const navigate = useNavigate()
  const apiClient = useApiClient(ClientApiClient)
  const { t } = useTranslation()
  const { addSnackbar } = useSnackbar()

  if (!email || !token) {
    navigate('/login')
    return null
  }

  const onSubmit = async (values: ResetPasswordFormValues) => {
    if (email && token) {
      const decodedEmail = decodeURIComponent(email)

      try {
        const response = await apiClient.resetPasswordComplete({
          password: values.password,
          email: decodedEmail,
          token,
        })

        const hasTwoFactor = (response?.twoFactor?.length ?? 0) > 0

        if (hasTwoFactor) {
          const googleAuthProvider = response.twoFactor?.find(
            (twoFactor) => twoFactor.provider?.id === TwoFAProviderEnum.GoogleAuthenticator
          )

          const authMedium = googleAuthProvider
            ? t('Profile.Google Authenticator app')
            : response.twoFactor?.[0]?.authMedium

          navigate('/2fa/confirm', {
            state: {
              twoFactorAuthData: {
                twoFactorAuthEvent: TwoFactorAuthEvents.ResetPassword,
                email: decodedEmail,
                password: values.password,
                urlToken: token,
                twoFactor: googleAuthProvider
                  ? [{ ...response.twoFactor?.[0], authMedium }]
                  : response.twoFactor,
              },
            },
          })
        } else {
          addSnackbar.success({ message: t('Password has been reset successfully!') })
          navigate('/login')
        }
      } catch (error: unknown) {
        const isErrorCaught = handleBaseTwoFactorErrors(error, navigate)
        if (!isErrorCaught) {
          throw error
        }
      }
    }
  }
  return (
    <React.Fragment>
      <div className={styles.topWrapper}>
        <div className={styles.wrapper}>
          <div className={styles.inner}>
            <h2 className={styles.passwordChangeTitle}>{t('Profile.Password Change')}</h2>
            <ResetPasswordFormComplete email={email} token={token} onSubmit={onSubmit} />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

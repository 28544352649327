import React, { SyntheticEvent, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { Form, FormikErrors, FormikProps, useFormikContext, withFormik } from 'formik'
import { t } from 'i18next'
import { UAParser } from 'ua-parser-js'

import { ExternalLink } from '../../global/ExternalLink'
import { Loading } from '../../global/Loading/Loading'
import { Button } from '../../global/button/Button'
import { CopyButton } from '../../global/button/CopyButton'
import { useSessionLanguage } from '../../global/context/SessionSettingsContext'
import { TextField } from '../../global/field/TextField'
import { createFormField } from '../../global/formField/FormField'
import { Modal } from '../../global/modal/Modal'
import { AppStoreIcon } from '../../icons/AppStoreIcon'
import { GooglePlayIcon } from '../../icons/GooglePlayIcon'
import { MicrosoftStoreIcon } from '../../icons/MicrosoftStoreIcon'
import { StepIcon } from '../../icons/StepIcon'
import { OperatingSystemNameEnum } from '../../model/PlatformTypeEnum'
import { GoogleAuthenticator2FAProviderDataDto } from '../../model/TwoFactorAuthentication'
import { FormSubmitValues } from '../../utils/formValidation'

import styles from './AccountSettingsPage.module.scss'

interface OuterGoogleAuthenticatorModalProps {
  onSubmit(values: FormSubmitValues<GoogleAuthenticatorModalFormValues>): Promise<void>
  close: () => void
  isOpen: boolean
  data: GoogleAuthenticator2FAProviderDataDto | null
  isError: boolean
  isLoading: boolean
}

export interface GoogleAuthenticatorModalFormValues {
  verificationCode: string
}

interface TwoFactorGoogleAuthenticatorModalProps {
  close: () => void
  isOpen?: boolean
  data: GoogleAuthenticator2FAProviderDataDto | null
  isLoading: boolean
}

const FormVerificationCodeField = createFormField<GoogleAuthenticatorModalFormValues>()

const GoogleAuthenticatorModalFormUI: React.FC<
  FormikProps<GoogleAuthenticatorModalFormValues> & OuterGoogleAuthenticatorModalProps
> = (props) => {
  const { handleSubmit, setSubmitting, close, isOpen, data, isLoading } = props

  const locale = useSessionLanguage()

  const handleSubmitForm = (event: SyntheticEvent) => {
    event.preventDefault()
    setSubmitting(true)
    handleSubmit()
  }

  useEffect(() => {
    props.validateForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, isLoading])

  return (
    <>
      <Form onSubmit={handleSubmitForm}>
        <GoogleAuthenticatorModal close={close} isOpen={isOpen} data={data} isLoading={isLoading} />
      </Form>
    </>
  )
}

export const GoogleAuthenticatorFormModal = withFormik<
  OuterGoogleAuthenticatorModalProps,
  GoogleAuthenticatorModalFormValues
>({
  mapPropsToValues: () => {
    return {
      verificationCode: '',
    }
  },
  mapPropsToTouched: ({ isError }) => {
    return {
      verificationCode: isError,
    }
  },
  handleSubmit: async (values, { props, setSubmitting, resetForm }) => {
    try {
      await props.onSubmit(values)
    } finally {
      setSubmitting(false)
      resetForm()
    }
  },
  validate: (values, { isError }) => {
    const errors: FormikErrors<GoogleAuthenticatorModalFormValues> = {}
    if (!values.verificationCode) {
      errors.verificationCode = t('Validation.Required')
    }

    if (isError && !values.verificationCode) {
      errors.verificationCode = t('errors.Code is not valid')
    }

    return errors
  },
  enableReinitialize: true,
  isInitialValid: false,
})(GoogleAuthenticatorModalFormUI)

export const GoogleAuthenticatorModal: React.FC<TwoFactorGoogleAuthenticatorModalProps> = ({
  close,
  isOpen,
  data,
  isLoading,
}) => {
  const { t } = useTranslation()

  const { handleSubmit, isValid, dirty, resetForm } =
    useFormikContext<GoogleAuthenticatorModalFormValues>()
  const parser = new UAParser()
  const { device, os } = parser.getResult()

  if (!isOpen) {
    return null
  }

  const handleModalClose = () => {
    resetForm()
    close()
  }

  return (
    <Modal
      closeModal={handleModalClose}
      cardClassName={classNames('modalCard googleAuthenticatorBox', styles.googleAuthenticatorBox)}
      render={() => (
        <Loading isLoading={isLoading} showLoadingIcon>
          <p className={styles.title}>{t('Profile.Set up 2FA via Authenticator App')}</p>
          <div className={styles.divider} />
          <section className={styles.content}>
            <div className='is-flex is-align-items-start'>
              <StepIcon stepNumber={1} />
              <p className={styles.stepTitle}>
                {t('Trading Account.Download')}{' '}
                <span className='has-text-weight-semibold'>
                  {t('Profile.Google Authenticator app')}
                </span>
              </p>
            </div>
            <div className={styles.iconsBox}>
              {(os.name === OperatingSystemNameEnum.iOS || !device.type) && (
                <ExternalLink
                  className='is-clickable'
                  url='https://apps.apple.com/us/app/google-authenticator/id388497605'
                >
                  <AppStoreIcon />
                </ExternalLink>
              )}
              {(os.name === OperatingSystemNameEnum.Android || !device.type) && (
                <ExternalLink
                  className='is-clickable'
                  url='https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&pcampaignid=web_share'
                >
                  <GooglePlayIcon />
                </ExternalLink>
              )}
              {(os.name === OperatingSystemNameEnum.Windows_Phone || !device.type) && (
                <ExternalLink
                  className='is-clickable'
                  url='https://www.microsoft.com/store/productId/9NBLGGH5K7JN'
                >
                  <MicrosoftStoreIcon />
                </ExternalLink>
              )}
            </div>
          </section>
          <div className={styles.divider} />
          <section className={styles.content}>
            <div className='is-flex is-align-items-start'>
              <StepIcon stepNumber={2} />
              {!device.type ? (
                <p className={styles.stepTitle}>
                  {t('Profile.Scan the QR-code in the app using your device camera')}
                </p>
              ) : (
                <p className={styles.stepTitle}>
                  {t('Profile.Enter the password provided')}{' '}
                  <span className='has-text-weight-semibold'>{t('Profile.Below in the app')}</span>
                </p>
              )}
            </div>
            <div className={styles.qrCodeWrapper}>
              {!device.type && (
                <img alt={t('QR code')} className={styles.qrCodeImg} src={data?.data.qrCode} />
              )}
              <div className={styles.qrCodeMainContent}>
                <p className={styles.qrCodeTitle}>
                  {!device.type
                    ? t('Profile.Or enter the code manually')
                    : t('Profile.Manual Entry')}
                </p>
                <div className={styles.qrCodeControl}>
                  <TextField
                    type={'text'}
                    className={styles.qrCode}
                    readOnly
                    value={data?.data.manualToken}
                  />
                  <CopyButton value={data?.data.manualToken!} />
                </div>
              </div>
            </div>
          </section>
          <div className={styles.divider} />
          <section className={styles.content}>
            <div className={styles.verificationTitleBox}>
              <StepIcon stepNumber={3} />
              <p className={styles.stepTitle}>
                {t('Profile.The app will start generating verification codes')}
              </p>
            </div>
            <p className={styles.verificationSubtitle}>
              {t('Profile.Please enter one of the codes below')}
            </p>
            <FormVerificationCodeField
              wrapperClassname={styles.verificationFieldWrapper}
              className={styles.verificationField}
              placeholder={t('Profile.Enter the verification code')}
              name='verificationCode'
              type='number'
            />
          </section>
          <div className={styles.divider} />
          <section className={styles.controlBox}>
            <Button appearance='secondary' onClick={close} type='button' size='S'>
              {t('Cancel')}
            </Button>
            <Button
              onClick={() => handleSubmit()}
              type='submit'
              appearance='primary'
              size='S'
              disabled={!(isValid && dirty)}
            >
              {t('Continue')}
            </Button>
          </section>
        </Loading>
      )}
    />
  )
}

import { ReactNode, createContext, useEffect, useMemo, useState } from 'react'

import { useGuardedContext } from '../global/useGuardedContext'
import { WalletDto } from '../model/WalletDto'
import { useApiClient } from './ApiClient'
import { ClientApiClient } from './clientApi'

interface WalletsReadContextImpl {
  wallets: WalletDto[] | undefined
}

interface WalletsWriteContextImpl {
  refreshUserWallets: () => Promise<WalletDto[] | undefined>
}

const WalletsReadContext = createContext<WalletsReadContextImpl | undefined>(undefined)
WalletsReadContext.displayName = 'WalletsReadContext'

const WalletsWriteContext = createContext<WalletsWriteContextImpl | undefined>(undefined)
WalletsWriteContext.displayName = 'WalletsWriteContext'

export function useWalletsReadContext(): WalletsReadContextImpl {
  return useGuardedContext(WalletsReadContext)
}

export function useWalletsWriteContext(): WalletsWriteContextImpl {
  return useGuardedContext(WalletsWriteContext)
}

interface Props {
  wallets: WalletDto[] | undefined
  children: ReactNode
}

export function WalletsContextProvider({ wallets, children }: Props): React.ReactElement {
  const [walletState, setWalletState] = useState<WalletDto[] | undefined>(wallets)

  useEffect(() => {
    setWalletState(wallets)
  }, [wallets])

  const apiClient = useApiClient(ClientApiClient)

  const readContext = useMemo<WalletsReadContextImpl>(
    () => ({
      wallets: walletState,
    }),
    [walletState]
  )

  const writeContext = useMemo<WalletsWriteContextImpl>(() => {
    return {
      async refreshUserWallets() {
        try {
          const fetchedWallets = await apiClient.getWallets()
          setWalletState(fetchedWallets)
          return fetchedWallets
        } catch (e: unknown) {
          console.error('Error during refreshWallets', e)
        }
      },
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletState])

  return (
    <WalletsReadContext.Provider value={readContext}>
      <WalletsWriteContext.Provider value={writeContext}>{children}</WalletsWriteContext.Provider>
    </WalletsReadContext.Provider>
  )
}

import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { format } from 'date-fns'

import { Loading } from '../../global/Loading/Loading'
import { Button } from '../../global/button/Button'
import { useSessionEntity } from '../../global/context/EntityContext'
import {
  useArabicSessionLanguage,
  useSessionLanguage,
} from '../../global/context/SessionSettingsContext'
import { InformationModal } from '../../global/modal/InformationModal'
import { Modal } from '../../global/modal/Modal'
import { DownloadIcon } from '../../icons/DownloadIcon'
import { HistoryIcon } from '../../icons/HistoryIcon'
import { AccountDetailedDto } from '../../model/AccountDetailedDto'
import { TestAnswersDto, TestStatusEnum } from '../../model/ClientTestAnswerDto'
import { ClientTestDto } from '../../model/ClientTestDto'
import { ClientTestSubcategoryEnum } from '../../model/ClientTestSubcategoryEnum'
import { RegulatorOptionDto } from '../../model/RegulatorOptionDto'
import { TaxHistoryDto } from '../../model/TaxHistoryDto'
import { useNavigate } from '../../navigation/custom-react-router-dom'
import { PageHeader } from '../../ui/Table/Header/PageHeader'
import { Text, TextH1 } from '../../ui/Typography/Typography'
import { useAccountReadContext } from '../../utils/AccountContextContext'
import { useApiClient } from '../../utils/ApiClient'
import { ClientApiClient } from '../../utils/clientApi'
import { useFetchOne } from '../../utils/useFetch'
import { useScrollToTop } from '../../utils/useScrollToTop'
import { W8BeneficialForm, W8BeneficialFormValues } from './W8BeneficialForm'
import { useDownloadW8BForm } from './useDownloadW8BForm'

import styles from './W8BeneficialPage.module.scss'

export const W8BeneficialPage: React.FC = () => {
  useScrollToTop()
  const apiClient = useApiClient(ClientApiClient)
  const { account } = useAccountReadContext()
  const entity = useSessionEntity()
  const locale = useSessionLanguage()

  const testHistoryCallback = useCallback(
    async () => await apiClient.getTaxHistory(locale),
    [apiClient, locale]
  )

  const { data: testHistory = [], isLoading: isLoadingTaxHistory } =
    useFetchOne(testHistoryCallback)

  const regulatorsCallback = useCallback(
    async () => await apiClient.getRegulators(entity, locale),
    [apiClient, entity, locale]
  )

  const { data: regulators = [], isLoading } = useFetchOne(regulatorsCallback)

  const taxFormQuestionsCallback = useCallback(
    async () =>
      await apiClient.getTaxFormQuestions({
        subcategoryId: ClientTestSubcategoryEnum.W8BENTaxForm,
        languageId: locale,
      }),
    [apiClient, locale]
  )

  const { data: taxFormQuestions, isLoading: isLoadingTaxFormQuestions } =
    useFetchOne(taxFormQuestionsCallback)

  const taxFormAnswersCallback = useCallback(
    async () =>
      await apiClient.getTaxFormAnswers({
        subcategoryId: 5,
        languageId: locale,
      }),
    [apiClient, locale]
  )

  const { data: taxFormAnswers, isLoading: isLoadingTaxFormAnswers } =
    useFetchOne(taxFormAnswersCallback)

  return (
    <Loading
      showLoadingIcon
      isLoading={
        isLoadingTaxFormAnswers && isLoadingTaxFormQuestions && isLoading && isLoadingTaxHistory
      }
    >
      <W8Beneficial
        account={account}
        taxFormAnswers={taxFormAnswers}
        taxFormQuestions={taxFormQuestions}
        testHistory={testHistory}
        regulators={regulators}
      />
    </Loading>
  )
}

interface Props {
  taxFormAnswers: TestAnswersDto | undefined
  taxFormQuestions: ClientTestDto | undefined
  regulators: RegulatorOptionDto[]
  testHistory: TaxHistoryDto[]
  account: AccountDetailedDto | undefined
}

export const W8Beneficial: React.FC<Props> = ({
  taxFormAnswers,
  taxFormQuestions,
  regulators,
  testHistory,
  account,
}) => {
  const { t } = useTranslation()
  const apiClient = useApiClient(ClientApiClient)
  const navigate = useNavigate()
  const isArabic = useArabicSessionLanguage()

  const downloadForm = useDownloadW8BForm({
    taxFormAnswers,
    isArabic,
    regulators,
    taxFormQuestions,
  })

  const [submitted, setSubmitted] = useState(false)

  const handleFormSubmit = async (values: W8BeneficialFormValues) => {
    const clientId = account?.id || ''
    try {
      await apiClient.createClientTests({
        testId: taxFormQuestions?.id || '',
        category: taxFormQuestions?.category || '',
        clientId,
        dateTaken: new Date().toISOString(),
        freeAnswers: values.freeAnswers.filter((item) => item.answer),
        selectedAnswers: values.selectedAnswers.map((x) => x.answer),
      })
      setSubmitted(true)
    } catch (error: unknown) {
      console.error(error)
    }
  }
  const [actionModal, setActionModal] = useState(false)
  const [historyModal, setHistoryModal] = useState(false)

  const handleCloseActionModal = () => setActionModal(false)
  const handleOpenActionModal = () => setActionModal(true)

  const handleOpenHistoryModal = () => {
    handleCloseActionModal()
    setHistoryModal(true)
  }
  const handleCloseHistoryModal = () => setHistoryModal(false)

  const handleClickBack = () => {
    handleOpenActionModal()
    handleCloseHistoryModal()
  }

  return (
    <>
      <PageHeader
        title={t('W-8BEN FORM')}
        subtitle={t('W8.To be able to view and trade the CFD Stocks that are available')}
        optionsToggle={taxFormAnswers ? handleOpenActionModal : undefined}
      />
      {submitted && (
        <div className={styles.card}>
          <TextH1>{t('W8.Thank You!')}</TextH1>
          <Text isParagraph>{t('W8.W-8BEN Form successfully submitted')}</Text>
          <Button
            appearance='secondary'
            size='S'
            onClick={() => navigate('/dashboard/traders-room/wallets')}
          >
            {t('W8.Go To Dashboard')}
          </Button>
        </div>
      )}
      {!submitted && historyModal && (
        <Modal
          closeModal={handleCloseHistoryModal}
          render={() => (
            <InformationModal
              title={t('W-8BEN Form History')}
              onCancel={handleCloseHistoryModal}
              onBack={handleClickBack}
              onCancelText={t('Cancel')}
              className={'p-0'}
            >
              <ul className={styles.modalContent}>
                {testHistory.map((x) => (
                  <li className={styles.hasBorderBottom}>
                    <div className='py-5 px-5'>
                      <div className='is-flex mb-2'>
                        <div className={styles.name}>{t('Date')}</div>
                        <div>{format(new Date(x.dateTaken), 'dd.MM.yyyy HH:mm')}</div>
                      </div>
                      <div className='is-flex'>
                        <div className={styles.name}>{t('Status')}</div>
                        <div
                          className={classNames({
                            'has-text-danger-dark': x.testStatus.id === TestStatusEnum.Declined,
                            'has-text-warning': x.testStatus.id === TestStatusEnum.Pending,
                          })}
                        >
                          {t(x.testStatus.name)}
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </InformationModal>
          )}
        />
      )}

      {!submitted && actionModal && (
        <Modal
          closeModal={handleCloseActionModal}
          render={() => (
            <InformationModal
              title={t('W-8BEN Form')}
              onCancel={handleCloseActionModal}
              onCancelText={t('Cancel')}
              className={'p-0'}
            >
              <ul>
                <li className='is-flex is-clickable' onClick={downloadForm}>
                  <div className='p-5 is-flex is-align-items-center'>
                    <DownloadIcon />
                    <span className='ml-3'>{t('Download form')}</span>
                  </div>
                </li>
                <li
                  className={classNames('is-flex is-clickable', styles.hasBorderBottom)}
                  onClick={handleOpenHistoryModal}
                >
                  <div className='p-5 is-flex is-align-items-center'>
                    <HistoryIcon />
                    <span className='ml-3'>{t('History')}</span>
                  </div>
                </li>
              </ul>
            </InformationModal>
          )}
        />
      )}
      {!submitted && taxFormQuestions && account && (
        <W8BeneficialForm
          regulators={regulators}
          taxFormQuestions={taxFormQuestions}
          taxFormAnswers={taxFormAnswers}
          account={account}
          handelFormSubmit={handleFormSubmit}
          isFormEnabled={!taxFormAnswers || taxFormAnswers?.allowRetake}
        />
      )}
    </>
  )
}

import React, { ForwardedRef, HTMLAttributes, forwardRef } from 'react'
import classNames from 'classnames'

import styles from './Typography.module.scss'

export type HTMLTag = 'h1' | 'h2' | 'h3' | 'h4' | 'p' | 'span' | 'div' | 'label' | 'aside'
type AlignType = 'left' | 'center' | 'right'

interface TypoProps extends Omit<HTMLAttributes<HTMLElement>, 'className'> {
  id?: string
  children?: React.ReactNode
  className?: string
  as?: HTMLTag
  align?: AlignType
}

enum TextSize {
  textHero = 'textHero',
  textH1 = 'textH1',
  textH2 = 'textH2',
  textH3 = 'textH3',
  textH4 = 'textH4',
  textLarge = 'textLarge',
  textLargeStrong = 'textLargeStrong',
  text = 'text',
  textStrong = 'textStrong',
  textSmall = 'textSmall',
  textSmallStrong = 'textSmallStrong',
  textTiny = 'textTiny',
  textTinyStrong = 'textTinyStrong',
  textMicro = 'textMicro',
}

const typographyFactory = (kind: TextSize, defaultTag: HTMLTag = 'span') => {
  return forwardRef(
    (props: TypoProps & { isParagraph?: boolean }, ref?: ForwardedRef<HTMLElement>) => {
      const { isParagraph, as = defaultTag, align, ...restProps } = props
      const Tag = as as keyof Pick<JSX.IntrinsicElements, HTMLTag>
      const className = classNames(
        styles[kind],
        {
          [styles.alignLeft]: align === 'left',
          [styles.alignCenter]: align === 'center',
          [styles.alignRight]: align === 'right',
        },
        props.className
      )

      if (isParagraph) {
        return (
          <p {...restProps} ref={ref as React.ForwardedRef<any>} className={className}>
            {props.children}
          </p>
        )
      }

      return (
        <Tag {...restProps} ref={ref as React.ForwardedRef<any>} className={className}>
          {props.children}
        </Tag>
      )
    }
  )
}

export const TextHero = typographyFactory(TextSize.textHero)

export const TextH1 = typographyFactory(TextSize.textH1, 'h1')
export const TextH2 = typographyFactory(TextSize.textH2, 'h2')
export const TextH3 = typographyFactory(TextSize.textH3, 'h3')
export const TextH4 = typographyFactory(TextSize.textH4, 'h4')

export const TextLarge = typographyFactory(TextSize.textLarge)
export const TextLargeStrong = typographyFactory(TextSize.textLargeStrong)
export const Text = typographyFactory(TextSize.text)
export const TextStrong = typographyFactory(TextSize.textStrong)
export const TextSmall = typographyFactory(TextSize.textSmall)
export const TextSmallStrong = typographyFactory(TextSize.textSmallStrong)
export const TextTiny = typographyFactory(TextSize.textTiny)
export const TextTinyStrong = typographyFactory(TextSize.textTinyStrong)
export const TextMicro = typographyFactory(TextSize.textMicro)

import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../../../global/button/Button'
import { useSnackbar } from '../../../global/context/SnackbarContext'
import IconButton from '../../../global/iconButton/IconButton'
import { useFormatNumber } from '../../../hooks/useFormatNumber'
import { DotsIcon } from '../../../icons/DotsIcon'
import { DummyIcon } from '../../../icons/DummyIcon'
import { WarningIcon } from '../../../icons/WarningIcon'
import { NameDto } from '../../../model/NameDto'
import { CurrencyBalance } from '../../../model/TradingAccount'
import { AlertIconWrapper } from '../../../ui/AlertIconWrapper/AlertIconWrapper'
import { TextSmall } from '../../../ui/Typography/Typography'

import styles from './TradingAccountBalanceCard.module.scss'

interface TradingAccountBalanceCardProps {
  balance: CurrencyBalance
  restrictions?: NameDto[]
  handleSetSelectedTradingAccount(): void
  initiateTransfer: () => void
  isTransferFromRestricted: boolean
  hasAllRestrictions: boolean
  onSetModalVisible(v: boolean): void
}

export const TradingAccountBalanceCard: React.FC<TradingAccountBalanceCardProps> = ({
  balance,
  handleSetSelectedTradingAccount,
  initiateTransfer,
  onSetModalVisible,
  hasAllRestrictions,
  isTransferFromRestricted,
}) => {
  const { t } = useTranslation()
  const { formatMoney } = useFormatNumber()
  const { addSnackbar } = useSnackbar()

  const showSnackbarError = () =>
    isTransferFromRestricted && addSnackbar.error({ message: t('This action is not available') })

  return (
    <div className={styles.card}>
      <div className={styles.titleWrapper}>
        {hasAllRestrictions ? (
          <IconButton onClick={() => onSetModalVisible(true)}>
            <WarningIcon size={20} color='error' />
          </IconButton>
        ) : (
          <DummyIcon />
        )}
        <TextSmall> {balance.currency}</TextSmall>

        <IconButton onClick={handleSetSelectedTradingAccount}>
          <DotsIcon />
        </IconButton>
      </div>
      <h2 className='mb-4 mt-4 has-text-weight-semibold'>
        {formatMoney(balance.balance, balance.currency)}
      </h2>

      <div className='mb-4'>
        {isTransferFromRestricted && (
          <AlertIconWrapper
            fullWidth
            spaced
            isActive={isTransferFromRestricted && !hasAllRestrictions}
          >
            <Button
              onClick={showSnackbarError}
              size='M'
              className={styles.button}
              appearance='secondary'
              state='disabled'
            >
              {t('Transfer')}
            </Button>
          </AlertIconWrapper>
        )}
        {!isTransferFromRestricted && (
          <Button
            onClick={initiateTransfer}
            size='M'
            className={styles.button}
            appearance='secondary'
          >
            {t('Transfer')}
          </Button>
        )}
      </div>
    </div>
  )
}

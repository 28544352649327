import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { AppMarketQRCode } from '../global/appMarketLink/desktop/AppMarketQRCode'
import { AppMarketLink } from '../global/appMarketLink/mobile/AppMarketLink'
import IconButton from '../global/iconButton/IconButton'
import { ArrowLeftIcon } from '../icons/ArrowLeftIcon'
import { GoodIcon } from '../icons/GoodIcon'
import { LoginResponse } from '../model/User'
import { useNavigate } from '../navigation/custom-react-router-dom'
import { Text, TextH3, TextStrong } from '../ui/Typography/Typography'
import { useWindowResize } from '../utils/domUtils'
import { TwoFactorAuthForm } from './TwoFactorAuthForm'
import { TwoFactorAuthLocationState, useTwoFactorAuth } from './useTwoFactorAuth'
import { useTwoFactorAuthText } from './useTwoFactorAuthText'

import styles from './TwoFactorAuthPage.module.scss'

export interface TwoFactorAuthLoginLocationState {
  twoFactorAuthData: LoginResponse
}

export interface Resend2FAError {
  hideResend: boolean
  errorTimer: number
  tooManyInputs: boolean
}

export const TwoFactorAuthPage: React.FC = () => {
  const navigate = useNavigate()
  const {
    twoFactor,
    resendCode,
    handleVerifyCode,
    error,
    status,
    disableResend,
    renderTrustModal,
    setRecoveryCodeModal,
    initialCountdown,
    resendError,
    renderRecoveryModal,
    handleTimer,
    stateChecker,
  } = useTwoFactorAuth()
  const { noteText, resendText, errorText } = useTwoFactorAuthText({
    twoFactor,
    initialCountdown,
    resendCode,
    disableResend,
    resendError,
  })

  const { t } = useTranslation()
  const isMobile = useWindowResize()
  const location = useLocation()
  const locationState = location.state as TwoFactorAuthLocationState

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => handleTimer().startResetTimer(), [resendError.hideResend])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => handleTimer().startInitialTimer(), [initialCountdown])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => stateChecker().effect(), stateChecker().dependants)

  useEffect(() => {
    if (!locationState?.twoFactorAuthData?.twoFactor) {
      navigate('/login')
    }
  }, [locationState, navigate])

  return (
    <div className={styles.topWrapper}>
      <div className={styles.wrapper}>
        {renderTrustModal()}
        {renderRecoveryModal()}
        {!isMobile && (
          <div className={styles.qrCode}>
            <AppMarketQRCode />
          </div>
        )}
        <div className={styles.inner}>
          <IconButton onClick={() => navigate('/login')}>
            <div className={styles.backToLogin}>
              <ArrowLeftIcon color='text' />
              <Text>{t('Sign up.Back to Log In')}</Text>
            </div>
          </IconButton>
          <div className={styles.title}>
            <TextH3>{t('Sign up.Two-Factor Authentication')}</TextH3>
            {status === 'success' && <GoodIcon size={24} />}
          </div>
          <TextStrong>{t('Sign up.Enter 6-digit code')}</TextStrong>
          <Text className={styles.notice}>{noteText}</Text>
          <TwoFactorAuthForm
            resendText={resendText}
            errorText={errorText}
            openRecoveryModal={() => setRecoveryCodeModal(true)}
            statusType={status}
            onSubmit={handleVerifyCode}
            validationError={error}
          />
        </div>
        <AppMarketLink />
      </div>
    </div>
  )
}

import {
  PropsWithChildren,
  ReactElement,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'

import { EntityConfigurationDto } from '../model/EntityConfigurationDto'
import { useApiClient } from '../utils/ApiClient'
import { AuthSessionContext } from '../utils/AuthContext'
import { ClientApiClient } from '../utils/clientApi'

const TradingAccountEntityConfigurationContext = createContext<EntityConfigurationDto | null>(null)
TradingAccountEntityConfigurationContext.displayName = 'TradingAccountEntityConfigurationContext'

export function useEntityConfig(): EntityConfigurationDto | null {
  return useContext(TradingAccountEntityConfigurationContext)
}

export function TradingAccountEntityConfigurationProvider({
  children,
}: PropsWithChildren): ReactElement {
  const [auth] = useContext(AuthSessionContext)
  const clientApiClient = useApiClient(ClientApiClient)
  const [entityConfiguration, setEntityConfiguration] = useState<EntityConfigurationDto | null>(
    null
  )

  useEffect(() => {
    const fetchClientEntityConfiguration = async () => {
      try {
        const entityConfig = await clientApiClient.getClientEntityConfiguration()
        setEntityConfiguration(entityConfig)
      } catch (e: unknown) {
        console.error('error fetching entity configuration:', e)
      }
    }

    if (auth && !entityConfiguration) {
      fetchClientEntityConfiguration()
    }
  }, [auth, entityConfiguration, clientApiClient])

  return (
    <TradingAccountEntityConfigurationContext.Provider value={entityConfiguration}>
      {children}
    </TradingAccountEntityConfigurationContext.Provider>
  )
}

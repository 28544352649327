import React from 'react'
import { useTranslation } from 'react-i18next'

import { WalletWithdrawTransactionFailedPage } from '../../Traders-Room/Wallets/WalletWithdraw/WalletWithdrawTransactionPage/WalletWithdrawTransactionFailedPage'
import { useNavigate } from '../../navigation/custom-react-router-dom'

export const PaymentProviderWalletDepositTransactionFailedPage: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleCancel = () => {
    navigate('/dashboard/payment-agent/wallets')
  }

  return (
    <WalletWithdrawTransactionFailedPage
      title={t('Transactions.Funds not deposited')}
      onCancel={handleCancel}
    />
  )
}

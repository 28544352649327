import { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { NavigateOptions } from 'react-router-dom'

import { getTransactionBadgeLabel } from '../../../Traders-Room/Transaction-History/TransactionBadgeStatus'
import { TwoFactorAuthWithdrawalWarningModal } from '../../../TwoFactorAuth/TwoFactorAuthWithdrawalWarningModal'
import { HorizontalScroll } from '../../../global/HorizontalScroll/HorizontalScroll'
import { Loading } from '../../../global/Loading/Loading'
import { Button } from '../../../global/button/Button'
import { useArabicSessionLanguage } from '../../../global/context/SessionSettingsContext'
import { useSnackbar } from '../../../global/context/SnackbarContext'
import { useFormatNumber } from '../../../hooks/useFormatNumber'
import { useWithdrawalPageNavigation } from '../../../hooks/useWithdrawalPageNavigation'
import { Link } from '../../../navigation/custom-react-router-dom'
import { InfoCard } from '../../../ui/Popups/InfoCard/InfoCard'
import { Text, TextH3, TextSmall, TextStrong } from '../../../ui/Typography/Typography'
import { AuthSessionContext } from '../../../utils/AuthContext'
import { useGAEvents } from '../../../utils/callGAEvents'
import { dispatchOpenChat } from '../../../utils/cookie.utils'
import { getIBTransactionFieldsByTransactionType } from '../../../utils/transaction.utils'
import { WalletRestrictions, isRestricted, isWalletSuspended } from '../../../utils/wallet.utils'
import { isTransactionPositive } from '../TransactionHistory/TransactionHistory'
import { CurrencyCodeFlagIcon } from '../components/CurrencyCodeFlagIcon/CurrencyCodeFlagIcon'
import { SectionHeader } from '../components/SectionHeader/SectionHeader'
import { WalletWithLatestTransactions } from '../hooks/useWalletsWithLatestTransactions'

import styles from './Wallets.module.scss'

interface IBWalletProps {
  walletsWithLatestTransactions: WalletWithLatestTransactions[]
  isLoading: boolean
}

const MAX_WALLETS = 2
const NAVIGATE_OPTIONS: NavigateOptions = { state: { from: 'dashboard' } }

export const Wallets: FC<IBWalletProps> = (props) => {
  const { walletsWithLatestTransactions, isLoading } = props

  const slicedWalletsWithLatestTransactions = walletsWithLatestTransactions.slice(0, MAX_WALLETS)

  const { t } = useTranslation()
  const { formatMoney } = useFormatNumber()
  const isArabic = useArabicSessionLanguage()
  const [auth] = useContext(AuthSessionContext)
  const { addSnackbar } = useSnackbar()
  const { callGAEvents } = useGAEvents()

  const {
    handleWithdrawalNavigation,
    twoFactorWarningModalState,
    onDismissModal,
    getWithdrawalPath,
    walletWithdrawLoadingId,
  } = useWithdrawalPageNavigation()

  const infoCardTitle = t('Temporarily unavailable')
  const infoCardText = t('Please contact customer support for assistance')

  if (!slicedWalletsWithLatestTransactions.length && !isLoading) {
    return null
  }

  const handleWithdrawal = (isWithdrawRestricted: boolean, walletId: string): void => {
    if (isWithdrawRestricted) {
      addSnackbar.error({ message: t('This action is not available') })
      return
    }
    callGAEvents().IBDashboard.Wallet.withdraw()
    handleWithdrawalNavigation({
      walletId,
      navigateOptions: NAVIGATE_OPTIONS,
    })
  }

  return (
    <>
      {twoFactorWarningModalState.visible && (
        <TwoFactorAuthWithdrawalWarningModal
          closeModal={onDismissModal}
          walletId={twoFactorWarningModalState.walletId}
          getWithdrawalPath={getWithdrawalPath}
          withdrawalNavigateOptions={NAVIGATE_OPTIONS}
        />
      )}
      <div className={styles.iBWallet}>
        <SectionHeader
          title={t('IB.Dashboard.IB Wallet')}
          seeMoreLinkProps={{
            onClick: () => callGAEvents().IBDashboard.Wallet.seeMore(),
            to: '/dashboard/introducing-broker/wallets',
          }}
        />

        <Loading isLoading={isLoading} showLoadingIcon>
          <HorizontalScroll
            containerClassName={styles.walletsContainer}
            innerClassName={styles.walletsContainerScroll}
          >
            {slicedWalletsWithLatestTransactions.map((walletWithLatestTransaction, index) => {
              const { wallet, latestTransactions } = walletWithLatestTransaction

              const latestTransaction = latestTransactions.length
                ? latestTransactions[0]
                : undefined
              const isLastItem = index === slicedWalletsWithLatestTransactions.length - 1

              const fields = latestTransaction
                ? getIBTransactionFieldsByTransactionType(
                    latestTransaction.type.id,
                    latestTransaction,
                    isArabic,
                    auth?.id
                  )
                : undefined

              const isWithdrawRestricted = isRestricted(
                WalletRestrictions.WITHDRAW,
                wallet.restrictions
              )

              const isTransferFromRestricted = isRestricted(
                WalletRestrictions.TRANSFER_FROM,
                wallet.restrictions
              )

              const hasAllRestrictions = isWalletSuspended(wallet.restrictions)

              const isWithdrawDisabled = !hasAllRestrictions && isWithdrawRestricted
              const isTransferDisabled = !hasAllRestrictions && isTransferFromRestricted

              return (
                <div key={wallet.id} className={styles.wallet}>
                  <div className={styles.header}>
                    <div className={styles.currency}>
                      <CurrencyCodeFlagIcon currencyType={wallet.currency.id} />

                      <TextStrong>{wallet.currency.id}</TextStrong>
                    </div>

                    {latestTransaction && (
                      <div className={styles.status}>
                        <TextSmall className={styles.statusTitle}>
                          {getTransactionBadgeLabel(latestTransaction.state.id, t)}
                        </TextSmall>

                        {fields && (
                          <TextStrong>
                            <span>
                              {isTransactionPositive(
                                latestTransaction.walletTransactions[0].directionType.id
                              )
                                ? '+'
                                : '-'}
                            </span>
                            {formatMoney(fields.amount, fields.currency)}
                          </TextStrong>
                        )}
                      </div>
                    )}
                  </div>

                  <div className={styles.info}>
                    <TextH3>{formatMoney(wallet.balance, wallet.currency.id)}</TextH3>
                    <Text className={styles.walletName}>{wallet.name}</Text>
                  </div>

                  <div className={styles.buttons}>
                    <InfoCard
                      className={styles.infoCard}
                      direction='top'
                      disabled={!isWithdrawDisabled}
                      title={infoCardTitle}
                      text={infoCardText}
                      render={
                        <Button onClick={dispatchOpenChat} appearance='link'>
                          {t('Contact Support')}
                        </Button>
                      }
                    >
                      <Button
                        size='L'
                        appearance='primary'
                        state={isWithdrawRestricted ? 'disabled' : 'normal'}
                        fullWidth
                        onClick={() => handleWithdrawal(isWithdrawRestricted, wallet.id)}
                        loading={walletWithdrawLoadingId === wallet.id}
                      >
                        <Text>{t('Withdraw')}</Text>
                      </Button>
                    </InfoCard>

                    <Link
                      className={styles.button}
                      to={
                        isTransferFromRestricted
                          ? '#'
                          : `/dashboard/introducing-broker/wallets/${wallet.id}/transfer`
                      }
                    >
                      <InfoCard
                        className={styles.infoCard}
                        direction={isLastItem ? 'topLeft' : 'top'}
                        disabled={!isTransferDisabled}
                        title={infoCardTitle}
                        text={infoCardText}
                        render={
                          <Button onClick={dispatchOpenChat} appearance='link'>
                            {t('Contact Support')}
                          </Button>
                        }
                      >
                        <Button
                          size='L'
                          appearance='primary'
                          onClick={() => callGAEvents().IBDashboard.Wallet.transfer()}
                          state={isTransferFromRestricted ? 'disabled' : 'normal'}
                          fullWidth
                        >
                          <Text>{t('Transfer')}</Text>
                        </Button>
                      </InfoCard>
                    </Link>
                  </div>
                </div>
              )
            })}
          </HorizontalScroll>
        </Loading>
      </div>
    </>
  )
}

import { useTranslation } from 'react-i18next'

import { useArabicSessionLanguage } from '../../global/context/SessionSettingsContext'
import { CancelActionModalBody } from '../../global/modal/CancleActionModal'
import { Modal } from '../../global/modal/Modal'
import { CloseIcon } from '../../icons/CloseIcon'
import { Link } from '../../navigation/custom-react-router-dom'
import { TextSmall } from '../../ui/Typography/Typography'

import styles from './PlatformTypeInfoModal.module.scss'
import './PlatformTypeInfoModal.module.scss'

export interface PlatformTypeInfoModalProps {
  descriptionText: string
  ctaLink: string
  onModalClose(): void
}

export const PlatformTypeInfoModal = ({
  descriptionText,
  ctaLink,
  onModalClose,
}: PlatformTypeInfoModalProps): JSX.Element => {
  const { t } = useTranslation()
  const isArabic = useArabicSessionLanguage()

  return (
    <div className={styles.container}>
      <Modal
        pullDown
        closeModal={onModalClose}
        render={({ closeModal }) => (
          <CancelActionModalBody className={styles.modalBody}>
            <div className={styles.iconWrapper} onClick={closeModal} dir={isArabic ? 'rtl' : ''}>
              <CloseIcon />
            </div>
            <TextSmall className={styles.infoDescription} isParagraph>
              {descriptionText}
            </TextSmall>
            {ctaLink && (
              <Link to={ctaLink} className={styles.ctaLink}>
                <TextSmall>{t('Trading Account.Read more')}</TextSmall>
              </Link>
            )}
          </CancelActionModalBody>
        )}
      />
    </div>
  )
}

import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from 'react'
import { v4 as uuid } from 'uuid'

import { SnackbarData } from '../../ui/Popups/Snackbar/Snackbar'

const charLongerDuration = 50
const longDuration = 16000
const shortDuration = 5000
const hoverDuration = 8000
const maxSnackbars = 3

enum SnackbarActionKind {
  ADD = 'ADD',
  REMOVE = 'REMOVE',
}

interface SnackbarAction {
  type: SnackbarActionKind
  snackbar?: SnackbarData
  removeId?: string
}

type AddSnackbarAction = Omit<SnackbarData, 'id'>

interface SnackbarContextValue {
  snackbars: SnackbarData[]
  add: (snackbar: AddSnackbarAction) => string
  remove: (id: string) => void
}

const SnackbarContext = createContext<SnackbarContextValue | undefined>(undefined)

export const SnackbarContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [snackbars, dispatch] = useReducer(reducer, [])

  const add = (snackbar: AddSnackbarAction): string => {
    const id = uuid()
    const newSnackBar = { ...snackbar, id }
    dispatch({ type: SnackbarActionKind.ADD, snackbar: newSnackBar })
    return id
  }

  const remove = useCallback((id: string): void => {
    dispatch({
      type: SnackbarActionKind.REMOVE,
      removeId: id,
    })
  }, [])

  const setupAutoClose = useCallback(
    (snackbar: SnackbarData) => {
      if (snackbar.noTimeout) {
        return
      }

      const duration =
        snackbar.message.length > charLongerDuration && !snackbar.ignoreMessageLength
          ? longDuration
          : shortDuration
      const snackbarId = snackbar.id

      const timeout = setTimeout(() => remove(snackbarId), duration)

      const onHover = () => {
        clearTimeout(timeout)
        setTimeout(() => remove(snackbarId), hoverDuration)
      }

      const checkElement = () => {
        const snackbarElement = document.getElementById(snackbarId)
        if (snackbarElement) {
          snackbarElement.addEventListener('mouseenter', onHover, { once: true })
        } else {
          setTimeout(checkElement, 50)
        }
      }

      checkElement()
    },
    [remove]
  )

  useEffect(() => {
    if (snackbars.length > 0) {
      const latestSnackbar = snackbars[snackbars.length - 1]
      setupAutoClose(latestSnackbar)
    }
  }, [setupAutoClose, snackbars])

  return (
    <SnackbarContext.Provider value={{ snackbars, add, remove }}>
      {children}
    </SnackbarContext.Provider>
  )
}

const reducer = (state: SnackbarData[], action: SnackbarAction): SnackbarData[] => {
  switch (action.type) {
    case SnackbarActionKind.ADD:
      if (action.snackbar) {
        const newSnackbars = [...state, action.snackbar]
        if (newSnackbars.length > maxSnackbars) {
          newSnackbars.shift()
        }

        return [...newSnackbars]
      }
      return state
    case SnackbarActionKind.REMOVE:
      return [...state.filter((snackbar) => snackbar.id !== action.removeId)]
    default:
      return state
  }
}

export interface UseSnackbar {
  addSnackbar: {
    error: (snackbar: AddSnackbarAction) => string
    success: (snackbar: AddSnackbarAction) => string
    info: (snackbar: AddSnackbarAction) => string
    warning: (snackbar: AddSnackbarAction) => string
    generic: (snackbar: AddSnackbarAction) => string
  }
  removeSnackbar: SnackbarContextValue['remove']
  snackbars: SnackbarContextValue['snackbars']
}

export const useSnackbar = (): UseSnackbar => {
  const context = useContext(SnackbarContext)
  if (context === undefined) {
    throw new Error('useSnackbar must be used within a SnackbarContextProvider')
  }

  const error = (x: AddSnackbarAction) => context.add({ ...x, type: 'error' })
  const success = (x: AddSnackbarAction) => context.add({ ...x, type: 'success' })
  const info = (x: AddSnackbarAction) => context.add({ ...x, type: 'info' })
  const warning = (x: AddSnackbarAction) => context.add({ ...x, type: 'warning' })
  const generic = (x: AddSnackbarAction) => context.add(x)

  return {
    addSnackbar: {
      error,
      success,
      info,
      warning,
      generic,
    },
    snackbars: context.snackbars,
    removeSnackbar: context.remove,
  }
}

import {
  Dispatch,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react'

import { AuthSessionContext, MaybeAuthSession } from '../../utils/AuthContext'
import { TickmillCompaniesEnum, getTickmillCompanyByHostname } from '../../utils/companyName.utils'
import { useGuardedContext } from '../useGuardedContext'

export interface EntitySettings {
  entity: TickmillCompaniesEnum
}

export function restoreSession(auth: MaybeAuthSession): EntitySettings {
  return {
    entity: auth?.tickmillCompany?.id || getTickmillCompanyByHostname(),
  }
}

type EntitySettingsContextType = [EntitySettings, Dispatch<Partial<EntitySettings>>] | undefined

const EntitySettingsContext = createContext<EntitySettingsContextType>(undefined)
EntitySettingsContext.displayName = 'EntitySettingsContextType'

export function useEntitySettings(): [EntitySettings, Dispatch<Partial<EntitySettings>>] {
  return useGuardedContext(EntitySettingsContext)
}

export function useSessionEntity(): TickmillCompaniesEnum {
  const [entity] = useEntitySettings()
  return entity.entity
}

export function EntitySettingsContextProvider({ children }: PropsWithChildren): JSX.Element {
  const [auth] = useContext(AuthSessionContext)
  const [entitySettings, _setEntitySettings] = useState(restoreSession(auth))

  const setSettings = useCallback(
    (entity: Partial<EntitySettings>) => {
      _setEntitySettings({ ...entitySettings, ...entity })
    },
    [entitySettings]
  )

  return (
    <EntitySettingsContext.Provider value={[entitySettings, setSettings]}>
      {children}
    </EntitySettingsContext.Provider>
  )
}

import { useEffect } from 'react'

import { useDocumentVerificationPath } from '../../hooks/useDocumentVerificationPath'
import { AccountDetailedDto } from '../../model/AccountDetailedDto'
import { useNavigate } from '../../navigation/custom-react-router-dom'
import { useAccountReadContext } from '../AccountContextContext'

type AccessCallBack = () => void

interface AccountActionAccessCheck {
  checkAccountTradersRoomAccess: (callback: AccessCallBack) => void
  checkAccountOpenAccountAccess: (callback: AccessCallBack) => void
  checkAccountIBAccess: (callback: AccessCallBack) => void
  isAccountTradersRoomActionAccessLocked: boolean
  isAccountOpenAccountActionAccessLocked: boolean
  isAccountIntroducingBrokerActionAccessLocked: boolean
}

export const useAccountActionAccessCheck = (): AccountActionAccessCheck => {
  const navigate = useNavigate()
  const { account } = useAccountReadContext()
  const docVerificationPath = useDocumentVerificationPath()

  const accountActionAccessLocks = getAccountActionAccessLocks(account)

  const checkAccountTradersRoomActionAccess = (callback: AccessCallBack) => {
    checkActionAccess(callback)(accountActionAccessLocks.isAccountTradersRoomActionAccessLocked)
  }

  const checkAccountOpenAccountActionAccess = (callback: AccessCallBack) => {
    checkActionAccess(callback)(accountActionAccessLocks.isAccountOpenAccountActionAccessLocked)
  }

  const checkAccountIBActionAccess = (callback: AccessCallBack) => {
    checkActionAccess(callback)(
      accountActionAccessLocks.isAccountIntroducingBrokerActionAccessLocked
    )
  }

  const checkActionAccess = (callback: AccessCallBack) => (locked: boolean) => {
    locked ? navigate(docVerificationPath) : callback()
  }

  return {
    ...accountActionAccessLocks,
    checkAccountTradersRoomAccess: checkAccountTradersRoomActionAccess,
    checkAccountOpenAccountAccess: checkAccountOpenAccountActionAccess,
    checkAccountIBAccess: checkAccountIBActionAccess,
  }
}

interface AccountActionAccessLocks {
  isAccountTradersRoomActionAccessLocked: boolean
  isAccountOpenAccountActionAccessLocked: boolean
  isAccountIntroducingBrokerActionAccessLocked: boolean
}

export const getAccountActionAccessLocks = (
  account?: AccountDetailedDto
): AccountActionAccessLocks => {
  return {
    isAccountTradersRoomActionAccessLocked: isAccountTradersRoomActionAccessLocked(account),
    isAccountOpenAccountActionAccessLocked: isAccountOpenAccountActionAccessLocked(account),
    isAccountIntroducingBrokerActionAccessLocked:
      isAccountIntroducingBrokerActionAccessLocked(account),
  }
}

export const useAccountTradersRoomAccessCheck = (): boolean => {
  const { isAccountTradersRoomActionAccessLocked } = useAccountActionAccessCheck()
  return useAccessCheck(isAccountTradersRoomActionAccessLocked)
}

export const isAccountTradersRoomActionAccessLocked = (
  account: AccountDetailedDto | undefined
): boolean => {
  return !!account?.actionRestrictions.tradersRoomRestricted
}

export const useAccountOpenAccountAccessCheck = (): boolean => {
  const { isAccountOpenAccountActionAccessLocked } = useAccountActionAccessCheck()
  return useAccessCheck(isAccountOpenAccountActionAccessLocked)
}

export const isAccountOpenAccountActionAccessLocked = (
  account: AccountDetailedDto | undefined
): boolean => {
  return !!account?.actionRestrictions.openAccountRestricted
}

export const useAccountIBAccessCheck = (): boolean => {
  const { isAccountTradersRoomActionAccessLocked } = useAccountActionAccessCheck()
  return useAccessCheck(isAccountTradersRoomActionAccessLocked)
}

export const isAccountIntroducingBrokerActionAccessLocked = (
  account: AccountDetailedDto | undefined
): boolean => {
  return !!account?.actionRestrictions.introducingBrokerRestricted
}

const useAccessCheck = (isLocked: boolean) => {
  const navigate = useNavigate()
  const docVerificationPath = useDocumentVerificationPath()

  useEffect(() => {
    if (isLocked) {
      navigate(docVerificationPath)
    }
  }, [docVerificationPath, isLocked, navigate])

  return isLocked
}

import { ReactNode, useMemo, useRef, useState } from 'react'
import classNames from 'classnames'

import { Locale, localeFlagMap } from '../locale/Locale'

import 'react-dropdown/style.css'
import ReactCountryFlag from 'react-country-flag'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { useProfileTabs } from '../../Profile/ProfileTabs'
import { CloseIcon } from '../../icons/CloseIcon'
import { DummyIcon } from '../../icons/DummyIcon'
import { useNavigate } from '../../navigation/custom-react-router-dom'
import { AlertIconWrapper } from '../../ui/AlertIconWrapper/AlertIconWrapper'
import { Popover } from '../../ui/Popover/Popover'
import { TextSmall, TextStrong } from '../../ui/Typography/Typography'
import { useWindowResize } from '../../utils/domUtils'
import { useArabicSessionLanguage } from '../context/SessionSettingsContext'
import IconButton from '../iconButton/IconButton'

import styles from './NavAccountMenu.module.scss'

const LocaleFlag: Record<Locale, ReactNode> = {} as Record<Locale, ReactNode>

for (const [locale, countryCode] of Object.entries(localeFlagMap)) {
  LocaleFlag[locale as Locale] = (
    <ReactCountryFlag className={styles.countryFlag} countryCode={countryCode} svg />
  )
}

interface NavAccountMenuProps {
  isOpen: boolean
  close(): void
}

export const NavAccountMenu: React.FC<NavAccountMenuProps> = ({ isOpen, close }) => {
  const isArabic = useArabicSessionLanguage()
  const isMobile = useWindowResize()
  const profileTabs = useProfileTabs()
  const menuItems = useMemo(
    () =>
      profileTabs.map((tab) => ({
        ...tab,
        to: `/profile/${tab.id}`,
        label: tab.menuName ?? tab.name,
      })),
    [profileTabs]
  )
  const { t } = useTranslation()
  const myRef = useRef<HTMLDivElement>(null)
  const [showShadow, setShowShadow] = useState(false)
  const navigate = useNavigate()

  const location = useLocation()
  const path = location.pathname

  const checkScrollPosition = () => {
    if (myRef.current) {
      const scrollPosition = myRef.current.scrollTop
      if (scrollPosition > 1) {
        if (!showShadow) {
          setShowShadow(true)
        }
      } else {
        if (showShadow) {
          setShowShadow(false)
        }
      }
    }
  }

  interface handleClickProps {
    path?: string
  }

  const handleClick = ({ path }: handleClickProps) => {
    if (path) {
      navigate(path)
    }
    close()
  }

  return (
    <Popover isOpen={isOpen} onClick={close}>
      {isMobile && (
        <div
          className={classNames(styles.closeBox, {
            [styles.showShadow]: !!showShadow,
          })}
        >
          <DummyIcon />
          <TextStrong>{t('My Account')}</TextStrong>
          <IconButton onClick={close} className={styles.closeIcon}>
            <CloseIcon size={16} color={'text'} />
          </IconButton>
        </div>
      )}
      <div
        ref={myRef}
        onScroll={checkScrollPosition}
        className={classNames(styles.variantsScroller, { [styles.isArabic]: isArabic })}
      >
        {menuItems
          .filter((item) => !item.hidden)
          .map((item) => (
            <div key={item.id} className={styles.optionWrapper}>
              <AlertIconWrapper isActive={item.required} spaced>
                <TextSmall
                  key={item.id}
                  onClick={() => handleClick({ path: item.to })}
                  className={classNames(styles.option, {
                    [styles.active]: item.to && path.includes(item.to || ''),
                  })}
                >
                  {item.label}
                </TextSmall>
              </AlertIconWrapper>
            </div>
          ))}
      </div>
    </Popover>
  )
}

import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Loading } from '../../../global/Loading/Loading'
import { Button } from '../../../global/button/Button'
import { useSessionEntity } from '../../../global/context/EntityContext'
import { useSnackbar } from '../../../global/context/SnackbarContext'
import { Dropdown } from '../../../global/dropdown/Dropdown'
import IconButton from '../../../global/iconButton/IconButton'
import { Table } from '../../../global/table/Table'
import { useFormatNumber } from '../../../hooks/useFormatNumber'
import { DotsIcon } from '../../../icons/DotsIcon'
import { WarningIcon } from '../../../icons/WarningIcon'
import { NameDto } from '../../../model/NameDto'
import { CurrencyBalance } from '../../../model/TradingAccount'
import { CurrencyType } from '../../../model/WalletDto'
import { AlertIconWrapper } from '../../../ui/AlertIconWrapper/AlertIconWrapper'
import { isTickmillEUType } from '../../../utils/companyName.utils'
import { AppENV, enableByEnv } from '../../../utils/config'
import { InitiateConversion, TradingAccountOption } from './TradingAccountBalances'
import { TradingAccountBalancesOptions } from './TradingAccountBalancesOptions'

import styles from './TradingAccountTable.module.scss'

interface TradingAccountsTableProps {
  balances?: CurrencyBalance[]
  restrictions?: NameDto[]
  isLoading?: boolean
  handleSetSelectedTradingAccount(currency: CurrencyType): void
  initiateTransfer: (currency: CurrencyType) => void
  isTransferFromRestricted: boolean
  TAOptionsData?: TradingAccountOption
  onSetModalVisible: (v: boolean) => void
  setTAOptionsData: (tradingAccountOption?: TradingAccountOption) => void
  intiateConversion: ({ currency, balance }: InitiateConversion) => void
  hasAllRestrictions: boolean
}

const getColumnSpan = (columns: number, isEUEntity: boolean) => {
  let fixedColumnCount = columns
  if (isEUEntity) {
    fixedColumnCount--
  }
  if (!enableByEnv([AppENV.development])) {
    fixedColumnCount--
  }
  return fixedColumnCount
}

export const TradingAccountsTable: React.FC<TradingAccountsTableProps> = (props) => {
  const {
    balances = [],
    handleSetSelectedTradingAccount,
    isLoading,
    initiateTransfer,
    isTransferFromRestricted,
    hasAllRestrictions,
    TAOptionsData,
    onSetModalVisible,
    setTAOptionsData,
    intiateConversion,
  } = props
  const { t } = useTranslation()
  const entity = useSessionEntity()
  const { formatMoney } = useFormatNumber()

  const [showMore, setShoreMore] = useState(false)
  const { addSnackbar } = useSnackbar()

  const getBalances = useMemo(() => {
    if (!balances?.length) {
      return []
    }
    if (showMore) {
      return balances
    }
    return balances.filter((x, index) => index <= 2)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [balances.length, showMore])

  const showSnackbarError = () =>
    isTransferFromRestricted && addSnackbar.error({ message: t('This action is not available') })

  return (
    <React.Fragment>
      <Table wrapperClassName={styles.tableWrapper} size='small'>
        <Loading showLoadingIcon isLoading={!!isLoading}>
          <thead>
            <tr>
              <th>{t('Trading Account.Currency')}</th>
              <th>{t('Trading Account.Balance')}</th>
              <th />
              <th />
            </tr>
          </thead>
          <tbody>
            {!getBalances ? (
              <td align='center' colSpan={getColumnSpan(11, isTickmillEUType(entity))}>
                {t('No results')}
              </td>
            ) : (
              getBalances.map((tradingAccount, idx) => (
                <tr key={tradingAccount.currency}>
                  <td>
                    <div className={styles.tradingAccountTitleWrapper}>
                      {hasAllRestrictions && (
                        <IconButton
                          className={styles.icon}
                          onClick={() => hasAllRestrictions && onSetModalVisible(true)}
                        >
                          <WarningIcon color={'error'} size={20} />
                        </IconButton>
                      )}
                      {tradingAccount.currency}{' '}
                    </div>
                  </td>
                  <td>{formatMoney(tradingAccount.balance, tradingAccount.currency)}</td>
                  <td>
                    {isTransferFromRestricted && (
                      <AlertIconWrapper
                        spaced
                        isActive={isTransferFromRestricted && !hasAllRestrictions}
                      >
                        <Button
                          state='disabled'
                          size='XS'
                          appearance='secondary'
                          onClick={showSnackbarError}
                        >
                          {t('Transfer')}
                        </Button>
                      </AlertIconWrapper>
                    )}
                    {!isTransferFromRestricted && (
                      <Button
                        size='XS'
                        appearance='secondary'
                        onClick={() => initiateTransfer(tradingAccount.currency)}
                      >
                        {t('Transfer')}
                      </Button>
                    )}
                  </td>
                  <td width={50}>
                    <div className={styles.dropdownIconContainer}>
                      <IconButton
                        appearance='hoverable'
                        onClick={() => handleSetSelectedTradingAccount(tradingAccount.currency)}
                      >
                        <DotsIcon />
                      </IconButton>
                      {TAOptionsData?.currency === tradingAccount?.currency && (
                        <Dropdown
                          position={idx >= 1 ? 'top' : 'bottom'}
                          onClose={() => setTAOptionsData(undefined)}
                        >
                          <TradingAccountBalancesOptions
                            tradingAccount={TAOptionsData.tradingAccount}
                            currency={TAOptionsData.currency}
                            isTransferFromRestricted={isTransferFromRestricted}
                            intiateConversion={intiateConversion}
                          />
                        </Dropdown>
                      )}
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Loading>
      </Table>
      {balances.length > 3 && (
        <div className='text-align-center pt-5'>
          <Button appearance='link' onClick={() => setShoreMore((prevState) => !prevState)}>
            {showMore ? t('Show less Currencies') : t('Show more Currencies')}
          </Button>
        </div>
      )}
    </React.Fragment>
  )
}

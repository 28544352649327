import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  FilterOptions,
  FilterQueryModal,
  FilterQueryProps,
} from '../../global/filter/FilterQueryModal'
import { FilterOptionsDto } from '../../model/FilterOptionsDto'
import { IDateFilter } from '../../utils/filter.utils'
import { IBClientsQuery } from './IBClientsPage'

interface Props {
  currentQuery?: IBClientsQuery
  filterOptions?: FilterOptionsDto
  onConfirm({
    searchFilters,
    currentFilter,
  }: {
    searchFilters?: FilterQueryProps
    currentFilter?: IDateFilter
  }): void
}

export const IBClientsFilterModal: React.FC<Props> = ({
  onConfirm,
  currentQuery,
  filterOptions,
}) => {
  const [searchFilters, setSearchFilters] = useState(currentQuery)
  const { t } = useTranslation()

  return (
    <FilterQueryModal
      searchFilters={searchFilters}
      setSearchFilters={setSearchFilters}
      countries={filterOptions?.countries}
      tradingAccountTypes={filterOptions?.accountTypes}
      currencyOptions={filterOptions?.walletCurrencies}
      entities={filterOptions?.entities}
      customLabels={{
        [FilterOptions.Date]: t('IB.Trade Period'),
      }}
      currentFilterOptions={[
        FilterOptions.ClientVolume,
        FilterOptions.Commission,
        FilterOptions.Country,
        FilterOptions.AccountType,
        FilterOptions.Currency,
        FilterOptions.Entity,
        FilterOptions.RegistrationDate,
        FilterOptions.Date,
      ]}
      onConfirm={onConfirm}
    />
  )
}

import { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { To } from 'react-router-dom'
import classNames from 'classnames'

import { InfoIcon } from '../../../../icons/InfoIcon'
import { Link } from '../../../../navigation/custom-react-router-dom'
import { InfoCard } from '../../../../ui/Popups/InfoCard/InfoCard'
import { TextH3 } from '../../../../ui/Typography/Typography'

import styles from './SectionHeader.module.scss'

export interface SeeMoreLinkProps {
  to: To
  onClick?: () => void
  target?: string
  icon?: ReactNode
}

interface SectionHeaderProps {
  title: string
  seeMoreLinkProps?: SeeMoreLinkProps
  tooltipContent?: string
  icon?: ReactNode
  iconMobileOnly?: boolean
  id?: string
}

export const SectionHeader: FC<SectionHeaderProps> = (props) => {
  const { title, seeMoreLinkProps, tooltipContent, icon, iconMobileOnly, id } = props

  const { t } = useTranslation()

  return (
    <div className={styles.sectionHeader} id={id}>
      <div className={styles.title}>
        {icon && (
          <span
            className={classNames(styles.iconWrapper, {
              [styles.mobileOnly]: !!iconMobileOnly,
            })}
          >
            {icon}
          </span>
        )}
        <TextH3>{title}</TextH3>
        {!!tooltipContent?.length && (
          <InfoCard text={tooltipContent}>
            <InfoIcon color='iconTertiary' size={20} />
          </InfoCard>
        )}
      </div>

      {seeMoreLinkProps && (
        <Link className={styles.seeMoreButton} {...seeMoreLinkProps}>
          {t('IB.Dashboard.See more')}
        </Link>
      )}
    </div>
  )
}

import { IconProps, iconColor } from '../../../../icons/IconProps'

export function WebTraderLogo(props: IconProps): JSX.Element {
  const width = props.width ?? 24
  const height = props.height ?? 24

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20.75 3.75H7.25C6.85218 3.75 6.47064 3.90804 6.18934 4.18934C5.90804 4.47064 5.75 4.85218 5.75 5.25V6.75H4.25C3.85218 6.75 3.47064 6.90804 3.18934 7.18934C2.90804 7.47064 2.75 7.85218 2.75 8.25V18.75C2.75 19.1478 2.90804 19.5294 3.18934 19.8107C3.47064 20.092 3.85218 20.25 4.25 20.25H17.75C18.1478 20.25 18.5294 20.092 18.8107 19.8107C19.092 19.5294 19.25 19.1478 19.25 18.75V17.25H20.75C21.1478 17.25 21.5294 17.092 21.8107 16.8107C22.092 16.5294 22.25 16.1478 22.25 15.75V5.25C22.25 4.85218 22.092 4.47064 21.8107 4.18934C21.5294 3.90804 21.1478 3.75 20.75 3.75ZM17.75 8.25V9.75H4.25V8.25H17.75ZM17.75 18.75H4.25V11.25H17.75V18.75ZM20.75 15.75H19.25V8.25C19.25 7.85218 19.092 7.47064 18.8107 7.18934C18.5294 6.90804 18.1478 6.75 17.75 6.75H7.25V5.25H20.75V15.75Z'
        fill={iconColor(props)}
      />
    </svg>
  )
}

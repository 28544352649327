import React from 'react'
import { useTranslation } from 'react-i18next'

import IconButton from '../../../global/iconButton/IconButton'
import { useFormatNumber } from '../../../hooks/useFormatNumber'
import { InfoIcon } from '../../../icons/InfoIcon'
import { TradingAccount } from '../../../model/TradingAccount'
import { TextH3, TextSmall } from '../../../ui/Typography/Typography'

import styles from './TradingAccountPageHeaderParts.module.scss'

interface MobileHeaderProps {
  setAvailableBalanceModal: (value: boolean) => void
  setBalanceModalOpen: (value: boolean) => void
  tradingAccounts: TradingAccount[]
}

export const MobileHeader: React.FC<MobileHeaderProps> = (props) => {
  const { setAvailableBalanceModal, setBalanceModalOpen, tradingAccounts } = props
  const tradingAccount = tradingAccounts.length > 0 ? tradingAccounts[0] : null

  const { formatMoney } = useFormatNumber()

  const { t } = useTranslation()
  const tradingAccountId = tradingAccount?.platformOverview?.login || null

  return (
    <div className={styles.totalBalance}>
      <div className={styles.header}>
        <TextSmall isParagraph className={styles.title}>
          {t('Trading Account.Trading Account')}, {tradingAccountId}
        </TextSmall>
        <IconButton className={styles.infoIcon} onClick={() => setBalanceModalOpen(true)}>
          <InfoIcon size={14} />
        </IconButton>
      </div>
      <TextH3>{`≈${formatMoney(
        tradingAccount?.platformOverview.balance,
        tradingAccount?.platformOverview.reportingCurrency
      )}`}</TextH3>
      <div className={styles.header}>
        <TextSmall isParagraph className={styles.title}>
          {t('Wallet.Available to withdraw')}:{' '}
          <TextSmall className={styles.currencyText}>{`≈${formatMoney(
            tradingAccount?.platformOverview.availableToWithdraw,
            tradingAccount?.platformOverview.reportingCurrency
          )}`}</TextSmall>
        </TextSmall>
        <IconButton onClick={() => setAvailableBalanceModal(true)} className={styles.infoIcon}>
          <InfoIcon size={14} />
        </IconButton>
      </div>
    </div>
  )
}

interface TitleHeaderProps {
  setBalanceModalOpen: (value: boolean) => void
  tradingAccounts: TradingAccount[]
}

export const TitleHeader: React.FC<TitleHeaderProps> = (props) => {
  const { setBalanceModalOpen, tradingAccounts } = props

  const { t } = useTranslation()
  const { formatMoney } = useFormatNumber()

  const tradingAccount = tradingAccounts.length > 0 ? tradingAccounts[0] : null

  return (
    <div className={styles.totalBalance}>
      <div className={styles.header}>
        <TextSmall isParagraph className={styles.title}>
          <span>
            {t('Trading Account.Trading Account')}, {tradingAccount?.platformOverview.login || null}
          </span>{' '}
        </TextSmall>
        <IconButton onClick={() => setBalanceModalOpen(true)} className={styles.infoIcon}>
          <InfoIcon size={14} />
        </IconButton>
      </div>
      <TextH3>{`≈${formatMoney(
        tradingAccount?.platformOverview.balance,
        tradingAccount?.platformOverview.reportingCurrency
      )}`}</TextH3>
    </div>
  )
}

interface RightHeaderProps {
  setAvailableBalanceModal: (value: boolean) => void
  tradingAccounts: TradingAccount[]
}

export const RightHeader: React.FC<RightHeaderProps> = (props) => {
  const { setAvailableBalanceModal, tradingAccounts } = props

  const tradingAccount = tradingAccounts.length > 0 ? tradingAccounts[0] : null

  const { t } = useTranslation()
  const { formatMoney } = useFormatNumber()

  return (
    <div className={styles.totalBalance}>
      <div className={styles.header}>
        <TextSmall isParagraph className={styles.title}>
          {t('Wallet.Available to withdraw')}
        </TextSmall>
        <IconButton onClick={() => setAvailableBalanceModal(true)} className={styles.infoIcon}>
          <InfoIcon size={14} />
        </IconButton>
      </div>
      <TextH3 className={styles.textRight}>{`≈${formatMoney(
        tradingAccount?.platformOverview.availableToWithdraw,
        tradingAccount?.platformOverview.reportingCurrency
      )}`}</TextH3>
    </div>
  )
}

import React from 'react'
import { useTranslation } from 'react-i18next'

import { TwoFactorAuthWithdrawalWarningModal } from '../../TwoFactorAuth/TwoFactorAuthWithdrawalWarningModal'
import { Loading } from '../../global/Loading/Loading'
import { useWithdrawalPageNavigation } from '../../hooks/useWithdrawalPageNavigation'
import { NameDto } from '../../model/NameDto'
import { WalletCurrency, WalletTypeEnum } from '../../model/WalletDto'
import { PageHeader } from '../../ui/Table/Header/PageHeader'
import { useApiClient } from '../../utils/ApiClient'
import { ClientApiClient } from '../../utils/clientApi'
import { useWindowResize } from '../../utils/domUtils'
import { useCallbackWithForceRefresh } from '../../utils/useCallbackWithForceRefresh'
import { useFetchOne } from '../../utils/useFetch'
import { PaymentAgentWalletsCard } from './PaymentAgentWalletsCard'
import { PaymentAgentWalletsTable } from './PaymentAgentWalletsTable'

export interface PaymentAgentWalletMappedDto {
  currency: WalletCurrency
  id: string
  name: string
  balance: number
  reserved: number
  restrictions: NameDto[]
}

export const PaymentAgentWalletsPage: React.FC = () => {
  const apiClient = useApiClient(ClientApiClient)
  const isMobile = useWindowResize()
  const { t } = useTranslation()
  const {
    handleWithdrawalNavigation,
    twoFactorWarningModalState,
    onDismissModal,
    getWithdrawalPath,
    walletWithdrawLoadingId,
  } = useWithdrawalPageNavigation()

  const { callback: walletsCallback } = useCallbackWithForceRefresh(async () => {
    const response = await apiClient.getWallets({
      walletTypeId: 100,
    })
    return response
      .filter((x) => x.walletType.id === WalletTypeEnum.PAYMENT_AGENT)
      .map((w) => ({
        currency: w.currency,
        id: w.id,
        name: w.name,
        balance: w.balance,
        reserved: w.reserved,
        restrictions: w.restrictions,
      }))
  }, [])

  const { data: wallets = [], isLoading } = useFetchOne(walletsCallback)

  return (
    <React.Fragment>
      {twoFactorWarningModalState.visible && (
        <TwoFactorAuthWithdrawalWarningModal
          closeModal={onDismissModal}
          getWithdrawalPath={getWithdrawalPath}
          walletId={twoFactorWarningModalState.walletId}
        />
      )}
      <PageHeader title={t('Payment Agent.Wallet')} />
      <Loading showLoadingIcon isLoading={isLoading}>
        {isMobile &&
          wallets.map((wallet) => (
            <PaymentAgentWalletsCard
              wallet={wallet}
              isLoading={walletWithdrawLoadingId === wallet.id}
              handleWithdrawalNavigation={handleWithdrawalNavigation}
            />
          ))}
        <PaymentAgentWalletsTable
          wallets={wallets}
          walletWithdrawLoadingId={walletWithdrawLoadingId}
          handleWithdrawalNavigation={handleWithdrawalNavigation}
        />
      </Loading>
    </React.Fragment>
  )
}

import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { NoData } from '../../IntroducingBroker/Clients/IBClientsTable'
import { useArabicSessionLanguage } from '../../global/context/SessionSettingsContext'
import { FilterQueryProps } from '../../global/filter/FilterQueryModal'
import { Modal } from '../../global/modal/Modal'
import { SortHeader } from '../../global/sortHeader/SortHeader'
import { Table, TableBody, TableCell } from '../../global/table/Table'
import { useFormatNumber } from '../../hooks/useFormatNumber'
import { useScrollToElementIds } from '../../hooks/useScrollToElementIds'
import { NameDto } from '../../model/NameDto'
import {
  SubscriptionHistoryItem,
  getSubscriptionRequestTypeLabel,
  showSubLevel,
} from '../../model/SubscriptionsDto'
import { SearchTermState } from '../../ui/Table/Header/PageHeaderParts'
import { TextSmall } from '../../ui/Typography/Typography'
import { PageQuery } from '../../utils/ApiClient'
import { AuthSessionContext } from '../../utils/AuthContext'
import { formatDate } from '../../utils/date.utils'
import { SubscriptionBadgeStatus } from './SubscriptionBadgeStatus'
import { SubscriptionStatusHistoryModal } from './SubscriptionStatusHistoryModal'

import styles from './SubscriptionsHistoryTable.module.scss'

interface SubscriptionsHistoryTableProps {
  data: SubscriptionHistoryItem[]
  search: SearchResponseProps
  sortOptions?: NameDto<string>[]
}

interface SearchResponseProps {
  search: FilterQueryProps
  searchTerm: SearchTermState
  pageQuery: PageQuery | undefined
  setPageQuery(value: PageQuery): void
}

export const SubscriptionsHistoryTable: React.FC<SubscriptionsHistoryTableProps> = (props) => {
  const { data, search, sortOptions = [] } = props
  const { pageQuery, setPageQuery } = search

  const { formatMoney } = useFormatNumber()
  const isArabic = useArabicSessionLanguage()
  const { t } = useTranslation()
  const { scrollIntoView } = useScrollToElementIds()

  const [expandedRow, setExpandedRow] = useState('')
  const [historyStatusModal, setHistoryStatusModal] = useState<boolean>(false)
  const [selectedSubscription, setSelectedSubscription] = useState('')
  const [auth] = useContext(AuthSessionContext)
  const dateFormat = auth?.dateFormatType?.name

  const handleSetExpandedRow = (id: string, rowIndex: number) => {
    if (id === expandedRow) {
      id = ''
    }
    id && scrollIntoView([`subscription-history-etd-item-${rowIndex + 1}`])
    setExpandedRow(id)
  }

  if (data.length <= 0) {
    return <NoData search={search} />
  }

  return (
    <React.Fragment>
      {historyStatusModal && (
        <Modal
          closeModal={() => setHistoryStatusModal(false)}
          render={({ closeModal }) => (
            <SubscriptionStatusHistoryModal id={selectedSubscription} onCancel={closeModal} />
          )}
        />
      )}
      <Table spacing='none'>
        <thead>
          <tr>
            {sortOptions?.map((x) => (
              <SortHeader
                id={x.id}
                key={x.id}
                sort={pageQuery?.sort || undefined}
                sortOrder={pageQuery?.sortOrder}
                setSort={(sort, sortOrder) => {
                  setPageQuery!({
                    ...pageQuery,
                    sort,
                    sortOrder,
                  })
                }}
              >
                {x.name}
              </SortHeader>
            ))}
          </tr>
        </thead>
        {data.map((x, index) => (
          <TableBody
            margined
            spacing='none'
            key={x.id}
            id={`subscription-history-etd-item-${index + 1}`}
          >
            <tr
              className={classNames({
                [styles.expandableParent]: expandedRow === x.id,
              })}
            >
              <td>{formatDate(x.createdDate, { formatType: dateFormat })}</td>
              <td className='has-cursor-pointer' onClick={() => handleSetExpandedRow(x.id, index)}>
                <strong>{x.subscription.name}</strong>
              </td>
              <td>{getSubscriptionRequestTypeLabel(x.type.id, t)}</td>
              <td>{formatMoney(x.amount)}</td>
              <td className='has-cursor-pointer' onClick={() => setSelectedSubscription(x.id)}>
                <span onClick={() => setHistoryStatusModal(true)}>
                  <SubscriptionBadgeStatus {...x.state} />
                </span>
              </td>
            </tr>
            {expandedRow === x.id && (
              <tr className={styles.expandable}>
                <TableCell spacing='none' expanded colSpan={5}>
                  <div
                    dir={isArabic ? 'rtl' : ''}
                    className={classNames('px-4 py-4', styles.container)}
                  >
                    <div className={styles.leftContainer}>
                      <div>
                        <TextSmall
                          dir={isArabic ? 'rtl' : ''}
                          className={styles.secondaryTextSmall}
                        >
                          {t('Subscriptions.Product Group')}:
                        </TextSmall>
                        <TextSmall dir={isArabic ? 'rtl' : ''}>
                          {' '}
                          {x.subscriptionGroupType.name}
                        </TextSmall>
                      </div>
                    </div>
                    {showSubLevel(x.subscriptionGroupType.id, x.subscriptionDetailType.id) && (
                      <div>
                        <TextSmall className={styles.secondaryTextSmall}>
                          {t('Subscriptions.Level')}:{' '}
                        </TextSmall>
                        <TextSmall>{x.subscriptionDetailType.id}</TextSmall>
                      </div>
                    )}
                  </div>
                </TableCell>
              </tr>
            )}
          </TableBody>
        ))}
      </Table>
    </React.Fragment>
  )
}

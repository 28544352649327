import React from 'react'
import { useTranslation } from 'react-i18next'

import { WalletWithdrawFormValues } from '../../Traders-Room/Wallets/WalletWithdraw/WalletWithdrawForm/WalletWithdrawForm'
import {
  WalletWithdrawPage,
  useWalletWithdrawFetch,
} from '../../Traders-Room/Wallets/WalletWithdraw/WalletWithdrawPage/WalletWithdrawPage'
import { Loading } from '../../global/Loading/Loading'
import { usePreviousLocation } from '../../hooks/usePreviousLocation'
import { MasterTransaction } from '../../model/MasterTransaction'
import {
  isTransactionStatusSuccessful,
  isTransactionStatusUnSuccessful,
} from '../../model/TransactionStatus'
import { WalletTypeEnum } from '../../model/WalletDto'
import { useNavigate } from '../../navigation/custom-react-router-dom'
import { useAccountReadContext } from '../../utils/AccountContextContext'
import { isTickmillPartner } from '../../utils/companyName.utils'

export const IBWalletWithdrawPage: React.FC = () => {
  const navigate = useNavigate()
  const from = usePreviousLocation()

  const handlePageExitConfirmation = () => {
    const path =
      from === 'dashboard'
        ? '/dashboard/introducing-broker/dashboard'
        : '/dashboard/introducing-broker/wallets'
    navigate(path)
  }

  const { data, isLoading } = useWalletWithdrawFetch({
    onCancel: handlePageExitConfirmation,
  })

  const handleSubmit = async (data: MasterTransaction, values: WalletWithdrawFormValues) => {
    if (data && !data?.twoFactor) {
      if (isTransactionStatusSuccessful(data.state.id)) {
        navigate(
          `/dashboard/introducing-broker/wallets/${values.wallet.id}/withdrawal/transaction/succeed`,
          {
            state: {
              data,
              providerCategoryId: values.paymentProvider.providerCategory.id,
            },
          }
        )
      }

      if (isTransactionStatusUnSuccessful(data.state.id)) {
        navigate(
          `/dashboard/introducing-broker/wallets/${values.wallet.id}/withdrawal/transaction/failed`,
          {
            state: {
              data,
              providerCategoryId: values.paymentProvider.providerCategory.id,
            },
          }
        )
      }
    }
  }
  const { t } = useTranslation()
  const { account } = useAccountReadContext()

  const ibOrPartnerTitle = isTickmillPartner(account)
    ? t('Wallet.Partner')
    : t('Wallet.Introducing Broker')

  return (
    <Loading isLoading={isLoading}>
      {data && (
        <WalletWithdrawPage
          wallet={data}
          walletType={WalletTypeEnum.IB}
          onSubmit={handleSubmit}
          onCancel={handlePageExitConfirmation}
          title={ibOrPartnerTitle}
        />
      )}
    </Loading>
  )
}

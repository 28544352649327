import { useTranslation } from 'react-i18next'
import { NavigateOptions } from 'react-router-dom'

import { Button } from '../global/button/Button'
import { InfoModal } from '../global/modal/InfoModal'
import { Modal } from '../global/modal/Modal'
import { useNavigate } from '../navigation/custom-react-router-dom'
import { Text, TextSmallStrong } from '../ui/Typography/Typography'

interface Props {
  walletId?: string | null
  closeModal: () => void
  getWithdrawalPath: (walletId?: string | null) => string
  withdrawalNavigateOptions?: NavigateOptions
}

export const TwoFactorAuthWithdrawalWarningModal: React.FC<Props> = ({
  closeModal,
  walletId,
  getWithdrawalPath,
  withdrawalNavigateOptions,
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const withdrawalPath = getWithdrawalPath(walletId)

  return (
    <Modal
      closeModal={closeModal}
      render={() => (
        <InfoModal
          title={t('Warning')}
          renderFooter={() => {
            return (
              <>
                <Button
                  onClick={() => navigate(withdrawalPath, withdrawalNavigateOptions)}
                  type='button'
                >
                  <TextSmallStrong>{t('Profile.Accept the risk')}</TextSmallStrong>
                </Button>
                <Button onClick={() => navigate('/profile/security-settings')} type='button'>
                  <TextSmallStrong>{t('TwoFactorAuth.Enable 2FA')}</TextSmallStrong>
                </Button>
              </>
            )
          }}
          renderBody={() => {
            return (
              <section className='modal-card-body'>
                <Text
                  isParagraph
                  dangerouslySetInnerHTML={{
                    __html: t(
                      'TwoFactorAuth.Two-Factor Authentication (2FA) is not enabled for Withdrawals'
                    ),
                  }}
                />
              </section>
            )
          }}
        />
      )}
    />
  )
}

export const GA_EVENTS = {
  IBDashboard: {
    Wallet: {
      seeMore: 'ib_dashboard_wallet_see_more',
      withdraw: 'ib_dashboard_wallet_withdraw',
      transfer: 'ib_dashboard_wallet_transfer',
    },
    TransactionHistory: {
      seeMore: 'ib_dashboard_transaction_history_see_more',
    },
    QuickPerformance: {
      seeMore: 'ib_dashboard_quick_performance_see_more',
      commission: 'ib_dashboard_quick_performance_commission',
      clients: 'ib_dashboard_quick_performance_clients',
      lots: 'ib_dashboard_quick_performance_lots',
      sevenDays: 'ib_dashboard_quick_performance_seven_days',
      fourteenDays: 'ib_dashboard_quick_performance_fourteen_days',
      oneMonth: 'ib_dashboard_quick_performance_one_month',
      oneYear: 'ib_dashboard_quick_performance_one_year',
      calendar: 'ib_dashboard_quick_performance_calendar',
    },
    MultiLevelCommission: {
      seeMore: 'ib_dashboard_multi_level_commission_see_more',
    },
    LoyaltyProgram: {
      seeMore: 'ib_dashboard_loyalty_program_see_more',
      claimRewards: 'ib_dashboard_loyalty_program_claim_rewards',
    },
    ReferralLink: {
      seeMore: 'ib_dashboard_referral_link_see_more',
      copyLink: 'ib_dashboard_referral_link_copy_link',
      copyIBCode: 'ib_dashboard_referral_link_copy_ib_code',
      copyMTIBCode: 'ib_dashboard_referral_link_copy_mt_ib_code',
    },
  },
  IBQuestionnaire: {
    InfoLinks: {
      whyBecomeIB: 'ib_questionnaire_why_become_ib_click',
      loyaltyProgram: 'ib_questionnaire_loyalty_program_click',
    },
    ProgramType: {
      dropdownClick: 'ib_questionnaire_program_type_dropdown_click',
      introducingBroker: 'ib_questionnaire_program_type_ib_select',
      multiTier: 'ib_questionnaire_program_type_multitier_select',
    },
    Actions: {
      submit: 'ib_questionnaire_submit_click',
      becomeIB: 'ib_questionnaire_become_ib_click',
    },
    Questions: {
      standardSelect: 'ib_questionnaire_standard_question_select',
      multiTierSelect: 'ib_questionnaire_multitier_question_select',
      questionSelect: 'ib_questionnaire_question_select',
    },
  },
} as const

// Type-safe event structure for function calls
export type GAEventPaths = {
  [K1 in keyof typeof GA_EVENTS]: {
    [K2 in keyof (typeof GA_EVENTS)[K1]]: {
      [K3 in keyof (typeof GA_EVENTS)[K1][K2]]: () => void
    }
  }
}

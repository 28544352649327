import React, { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../button/Button'
import { CancelActionModal } from './CancleActionModal'

interface InformationModalProps {
  title?: string
  onCancel(): void
  onBack?(): void
  onClick?(): void
  spacing?: 'regular' | 'compact'
  onCancelText?: string
  onClickText?: string
  className?: string
  isLoading?: boolean
}

export const InformationModal: React.FC<PropsWithChildren<InformationModalProps>> = (props) => {
  const { t } = useTranslation()
  const {
    onCancel,
    children,
    title,
    spacing = 'regular',
    onCancelText = t('Okay') + '!',
    onClickText,
    isLoading = false,

    onBack,
    onClick,
    className,
  } = props

  return (
    <CancelActionModal
      title={title}
      spacing={spacing}
      footer={
        <>
          <Button size='S' appearance='plain' onClick={onCancel}>
            {onCancelText}
          </Button>
          {onClickText && (
            <Button size='S' appearance='plain' disabled={isLoading} onClick={onClick}>
              {onClickText}
            </Button>
          )}
        </>
      }
      onBack={onBack}
      className={className}
    >
      {children}
    </CancelActionModal>
  )
}

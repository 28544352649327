import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'

import { handleBaseTwoFactorErrors } from '../../../../Profile/AccountSettings/handleBaseTwoFactorErrors'
import { isDocError } from '../../../../Profile/DocumentManagementUpload/documentManagementUploadStatuses'
import { TwoFactorAuthEvents } from '../../../../TwoFactorAuth/useTwoFactorAuth'
import { Loading } from '../../../../global/Loading/Loading'
import { useSessionLanguage } from '../../../../global/context/SessionSettingsContext'
import { ToastContext, errorToast } from '../../../../global/toast/Toast'
import { useFormatNumber } from '../../../../hooks/useFormatNumber'
import { BankAccountCreate } from '../../../../model/BankAccount'
import {
  CreateWalletToPspWithdrawTransaction,
  denormalizeCreateWalletToPspWithdrawTransaction,
} from '../../../../model/CreateWalletToPspWithdrawTransaction'
import { InitWalletToPspWithdrawTransactionCommand } from '../../../../model/InitWalletToPspWithdrawTransactionCommand'
import { MasterTransaction } from '../../../../model/MasterTransaction'
import {
  PaymentProviderType,
  isPaymentProviderBankType,
} from '../../../../model/PaymentProviderType'
import { TwoFAProviderEnum } from '../../../../model/TwoFactorAuthentication'
import { WalletDto, WalletTypeEnum } from '../../../../model/WalletDto'
import { useNavigate } from '../../../../navigation/custom-react-router-dom'
import { useApiClient } from '../../../../utils/ApiClient'
import { santizeBase64 } from '../../../../utils/base64Helper'
import { ClientApiClient } from '../../../../utils/clientApi'
import { sleep } from '../../../../utils/transaction.utils'
import { useCallbackWithForceRefresh } from '../../../../utils/useCallbackWithForceRefresh'
import { useFetchOne } from '../../../../utils/useFetch'
import { useMutation } from '../../../../utils/useMutation'
import { useScrollToTop } from '../../../../utils/useScrollToTop'
import { WalletRestrictions, isRestricted } from '../../../../utils/wallet.utils'
import {
  WalletWithdrawForm,
  WalletWithdrawFormValues,
} from '../WalletWithdrawForm/WalletWithdrawForm'

interface WalletWithdrawFetchProps {
  onCancel(): void
}

interface UseWalletWithdrawFetch {
  data: WalletDto | undefined
  isLoading: boolean
}

export const useWalletWithdrawFetch = (props: WalletWithdrawFetchProps): UseWalletWithdrawFetch => {
  const { walletId } = useParams<{ walletId?: string }>()

  const apiClient = useApiClient(ClientApiClient)

  const { callback } = useCallbackWithForceRefresh(async () => {
    if (walletId) {
      return apiClient.getWalletById(walletId)
    }
  }, [walletId])

  const { data, isLoading } = useFetchOne(callback)

  useEffect(() => {
    if (isRestricted(WalletRestrictions.WITHDRAW, data?.restrictions)) {
      props.onCancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.restrictions])

  return { data, isLoading }
}

interface WalletWithdrawMutateProps {
  onSubmit(data: MasterTransaction, values: WalletWithdrawFormValues): void
  setDocumentError(errorCode: string): void
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useWalletWithdrawMutate = (props: WalletWithdrawMutateProps) => {
  const { onSubmit, setDocumentError } = props
  const locale = useSessionLanguage()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const location = useLocation()
  const setToast = useContext(ToastContext)
  const apiClient = useApiClient(ClientApiClient)

  const handleSuccess = async (data: MasterTransaction, values: WalletWithdrawFormValues) => {
    onSubmit(data, values)
  }

  const handleError = (error: unknown): Promise<void> | void => {
    const isErrorCaught = handleBaseTwoFactorErrors(error, navigate)
    if (!isErrorCaught) {
      throw error
    }
  }

  const handle2FAWithdrawal = ({
    response,
    payload,
    paymentProviderCategoryId,
    isWithdrawFromIBOrPartners,
  }: {
    response: MasterTransaction
    payload: Omit<InitWalletToPspWithdrawTransactionCommand, 'caller' | 'clientIpAddress'>
    paymentProviderCategoryId?: PaymentProviderType
    isWithdrawFromIBOrPartners: boolean
  }) => {
    const googleAuthProvider =
      response.twoFactor?.find(
        (twoFactor) => twoFactor?.provider?.id === TwoFAProviderEnum.GoogleAuthenticator
      ) ?? !response?.twoFactor?.[0]?.authMedium

    const authMedium = googleAuthProvider
      ? t('Profile.Google Authenticator app')
      : response.twoFactor?.[0]?.authMedium

    navigate('/2fa/confirm', {
      state: {
        twoFactorAuthData: {
          twoFactorAuthEvent: TwoFactorAuthEvents.Withdrawal,
          twoFactor: googleAuthProvider
            ? [{ ...response.twoFactor?.[0], authMedium }]
            : response.twoFactor,
          withdrawalData: {
            withdrawalPayload: payload,
            withdrawalProviderCategoryId: paymentProviderCategoryId,
          },
          isWithdrawFromIBOrPartners,
        },
      },
    })
  }

  const handleBankAccountCreation = async (values: WalletWithdrawFormValues) => {
    const { saveClientAccount, bankCodeSort, externalAccount, ...restFieldValues } = values.fields
    const { bankDepositProof } = values
    const payload = {
      ...restFieldValues,
      bankAccount: externalAccount,
      codeSort: bankCodeSort,
      document: bankDepositProof
        ? {
            file: santizeBase64(values.bankDepositProof?.base64Content),
            fileName: values.bankDepositProof?.fileName || '',
          }
        : undefined,
    } as BankAccountCreate

    try {
      return apiClient.addBankAccount(payload)
    } catch (error: unknown) {
      const errorCode = (error as any).response?.response?.data?.code

      if (isDocError(errorCode)) {
        setDocumentError(errorCode)
      }
    }
  }

  return useMutation(
    async (values: WalletWithdrawFormValues) => {
      const isBankType = isPaymentProviderBankType(
        Number(values.paymentProvider.providerCategory.id)
      )

      const createBankAccount = async () => {
        const bankAccounts = await apiClient.getBankAccounts()

        if (bankAccounts.length === 0) {
          const bankAccountCreationResponse = await handleBankAccountCreation(values)

          if (!bankAccountCreationResponse?.id) {
            setToast(errorToast({ title: t('errors.Something went wrong!') }))
            return
          }

          return bankAccountCreationResponse.id
        }
      }

      const bankAccountId = isBankType ? await createBankAccount() : ''

      const payload = denormalizeCreateWalletToPspWithdrawTransaction(
        createPspToWalletWithdrawTransaction({
          ...values,
          fields: { ...values.fields, documentId: bankAccountId ?? '' },
        }),
        locale
      )

      await sleep(1000)

      const response = await apiClient.transactionWithdraw(payload)
      const hasTwoFactor = (response?.twoFactor?.length ?? 0) > 0
      const isWithdrawFromIBOrPartners = location.pathname.includes(
        '/dashboard/introducing-broker/'
      )

      if (hasTwoFactor) {
        handle2FAWithdrawal({
          response,
          payload,
          paymentProviderCategoryId: values.paymentProvider.providerCategory.id,
          isWithdrawFromIBOrPartners,
        })
      }

      return response
    },
    {
      onSuccess: handleSuccess,
      onError: handleError,
    }
  )
}

interface WalletWithdrawPageProps {
  wallet?: WalletDto
  walletType: WalletTypeEnum

  onSubmit(data: MasterTransaction, values: WalletWithdrawFormValues): void

  onCancel(): void
  title?: string
}

export const WalletWithdrawPage: React.FC<WalletWithdrawPageProps> = (props) => {
  const { wallet, walletType, onSubmit, onCancel, title } = props

  useScrollToTop()

  const { t } = useTranslation()
  const { formatMoney } = useFormatNumber()
  const [documentError, setDocumentError] = useState<string>()
  const mutation = useWalletWithdrawMutate({ onSubmit, setDocumentError })

  const handleSubmit = async (values: WalletWithdrawFormValues) => {
    await mutation.mutate(values)
  }

  const handlePageExitConfirmation = () => {
    onCancel()
  }

  return (
    <React.Fragment>
      {!mutation.isLoading && (
        <WalletWithdrawForm
          formatMoney={formatMoney}
          wallet={wallet}
          walletType={walletType}
          onSubmit={handleSubmit}
          onCancel={handlePageExitConfirmation}
          title={title}
        />
      )}
      {mutation.isLoading && (
        <Loading isLoading={mutation.isLoading} showLoadingIcon text={t('Withdrawing')} />
      )}
    </React.Fragment>
  )
}

const createPspToWalletWithdrawTransaction = (
  values: WalletWithdrawFormValues
): CreateWalletToPspWithdrawTransaction => {
  return {
    fromWallet: {
      id: values.wallet.id,
      currency: {
        id: values.wallet.currency?.id,
      },
    },
    toPaymentProvider: {
      id: values.paymentProvider.id,
      currency: {
        id: values.paymentProvider.currency.id,
      },
    },
    amount: values.amount,
    comment: values.comment,
    terms: values.terms.paymentOperations,
    form: values.paymentProvider.parameters.form,
    fields: values.fields,
  }
}

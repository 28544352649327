import { useState } from 'react'
import ReactDOM from 'react-dom'
import { LinkProps } from 'react-router-dom'
import classNames from 'classnames'

import IconButton from '../../../global/iconButton/IconButton'
import { Modal } from '../../../global/modal/Modal'
import { CloseIcon } from '../../../icons/CloseIcon'
import { Link } from '../../../navigation/custom-react-router-dom'
import { useWindowResize } from '../../../utils/domUtils'
import { TextLargeStrong, TextSmall } from '../../Typography/Typography'
import { Bubble, BubbleProps } from '../Bubble/Bubble'
import { useInfoCardMove } from './useInfoCardMove'

import styles from './InfoCard.module.scss'

export interface InfoCardLinkProps {
  to: LinkProps['to']
  state?: LinkProps['state']
  text: string
  target?: LinkProps['target']
}

export interface InfoCardProps extends BubbleProps {
  title?: string
  text?: React.ReactNode
  render?: React.ReactNode
  className?: string
  isBackgroundLess?: boolean
  modalCardClassName?: string
  cardStyle?: React.CSSProperties
  link?: InfoCardLinkProps
  lockDirection?: boolean

  usePortal?: boolean
}

export const InfoCard: React.FC<InfoCardProps> = (props) => {
  const {
    title,
    text,
    render,
    link,
    disabled,
    children,
    direction,
    lockDirection,
    isBackgroundLess,
    className,
    modalCardClassName,
    fullWidth,
    cardStyle,
    usePortal,
  } = props
  const [modalOpen, setModalOpen] = useState(false)
  const { boxRef, newDirection } = useInfoCardMove(direction)

  const isMobile = useWindowResize()

  if (usePortal) {
    const rootElement = document.getElementById('root') as HTMLElement
    return ReactDOM.createPortal(
      <div className={className} style={cardStyle}>
        <TextSmall isParagraph>{text}</TextSmall>
        {link && (
          <TextSmall>
            <Link to={link.to} target={link.target} state={link.state}>
              {link.text}
            </Link>
          </TextSmall>
        )}
      </div>,
      rootElement
    )
  }

  if (isMobile) {
    return (
      <div
        onPointerDown={(e) => {
          e.stopPropagation()
          e.preventDefault()
          setModalOpen(true)
        }}
        className={classNames(styles.mobileWrapper, className, {
          [styles.fullWidth]: fullWidth,
        })}
      >
        {modalOpen && !disabled && (
          <Modal
            pullDown
            isBackgroundLess={isBackgroundLess}
            cardClassName={modalCardClassName}
            closeModal={() => setModalOpen(false)}
            render={() => (
              <div
                className={styles.infoCard}
                style={cardStyle}
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                }}
              >
                {title && (
                  <div className={styles.mobileTitle}>
                    <TextLargeStrong className={styles.title}>{title}</TextLargeStrong>
                    <IconButton onClick={() => setModalOpen(false)}>
                      <CloseIcon />
                    </IconButton>
                  </div>
                )}
                <div className={styles.mobileText}>
                  <TextSmall className={styles.text}>{text}</TextSmall>
                  {!title && (
                    <IconButton
                      className={styles.close}
                      onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        setModalOpen(false)
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                </div>
                {render && <div className={styles.render}>{render}</div>}
                {link && (
                  <Link
                    to={link.to}
                    target={link.target || '_blank'}
                    state={link.state}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {link.text}
                  </Link>
                )}
              </div>
            )}
          />
        )}
        {children}
      </div>
    )
  }

  return (
    <Bubble
      {...{ ...props, direction: lockDirection ? direction : newDirection }}
      component={children}
    >
      <div
        className={styles.infoCard}
        style={cardStyle}
        ref={boxRef}
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
        }}
      >
        {title && <TextLargeStrong className={styles.title}>{title}</TextLargeStrong>}
        <TextSmall className={styles.text}>{text}</TextSmall>
        {render && <div className={styles.render}>{render}</div>}
        {link && (
          <Link to={link.to} target={link.target} state={link.state}>
            {link.text}
          </Link>
        )}
      </div>
    </Bubble>
  )
}

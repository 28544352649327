import { IconProps } from './IconProps'

export const Step3Icon = (props: IconProps): JSX.Element => {
  const width = props.width || '40'
  const height = props.height || '40'
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='40' height='40' rx='6' fill='#CC0000' />
      <path
        d='M23.96 17.02C23.96 17.66 23.8333 18.22 23.58 18.7C23.34 19.1667 22.9933 19.5467 22.54 19.84C22.0867 20.1333 21.5667 20.3467 20.98 20.48V20.56C22.1267 20.6933 22.98 21.0533 23.54 21.64C24.1133 22.2133 24.4 22.9733 24.4 23.92C24.4 24.7467 24.2 25.4867 23.8 26.14C23.4133 26.78 22.8067 27.2867 21.98 27.66C21.1667 28.02 20.12 28.2 18.84 28.2C18.08 28.2 17.3733 28.14 16.72 28.02C16.0667 27.9 15.4467 27.7 14.86 27.42V25.5C15.46 25.8067 16.1 26.04 16.78 26.2C17.4733 26.36 18.1333 26.44 18.76 26.44C19.9733 26.44 20.8467 26.2133 21.38 25.76C21.9133 25.2933 22.18 24.6467 22.18 23.82C22.18 23.2867 22.04 22.8533 21.76 22.52C21.48 22.1733 21.0533 21.92 20.48 21.76C19.9067 21.5867 19.1933 21.5 18.34 21.5H17.04V19.76H18.36C19.1733 19.76 19.8333 19.66 20.34 19.46C20.8467 19.2467 21.22 18.9533 21.46 18.58C21.7 18.2067 21.82 17.78 21.82 17.3C21.82 16.66 21.6067 16.1667 21.18 15.82C20.7667 15.46 20.1667 15.28 19.38 15.28C18.9 15.28 18.4533 15.3333 18.04 15.44C17.64 15.5467 17.2733 15.6867 16.94 15.86C16.6067 16.0333 16.28 16.2267 15.96 16.44L14.92 14.98C15.28 14.7133 15.68 14.4667 16.12 14.24C16.5733 14.0133 17.0733 13.84 17.62 13.72C18.18 13.5867 18.7867 13.52 19.44 13.52C20.92 13.52 22.04 13.8467 22.8 14.5C23.5733 15.14 23.96 15.98 23.96 17.02Z'
        fill='white'
      />
    </svg>
  )
}

import React from 'react'
import { useTranslation } from 'react-i18next'

import { useProductReadContext } from '../../global/context/ProductContext'
import { Sumsub } from '../../global/sumsub/Sumsub'
import { SumsubThankYouPage } from '../../global/sumsub/SumsubThankYouPage'
import { PageHeader } from '../../ui/Table/Header/PageHeader'
import { isAccountPendingDocumentVerificationStatus } from '../../utils/AccountAccess/accountDocumentStatuses'
import { isKycVerified } from '../../utils/AccountAccess/accountKYCStatuses'
import { useAccountReadContext } from '../../utils/AccountContextContext'
import { useScrollToTop } from '../../utils/useScrollToTop'

import styles from './SumsubProfilePage.module.scss'

export const SumsubProfilePage: React.FC = () => {
  useScrollToTop()
  const { t } = useTranslation()
  const { account } = useAccountReadContext()
  const { product } = useProductReadContext()
  const showPending =
    isAccountPendingDocumentVerificationStatus(account) && isKycVerified(account, product)

  return (
    <div className={styles.sumsubProfilePage}>
      <PageHeader title={t('Verification')} />
      {!showPending && <Sumsub />}
      {showPending && <SumsubThankYouPage compact />}
    </div>
  )
}

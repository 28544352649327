import React from 'react'
import { useTranslation } from 'react-i18next'

import { dispatchOpenChat } from '../../utils/cookie.utils'
import { CancelActionModal } from './CancleActionModal'

interface Props {
  title?: string
  description?: string
  onConfirm: () => void
}

export const RestrictionActionModalBody: React.FC<Props> = ({ description }) => (
  <p dangerouslySetInnerHTML={{ __html: description as string }} />
)

export const RestrictionActionModal: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  const {
    onConfirm,

    title = t('Temporarily unavailable'),
    description = t('Please contact Support for assistance.'),
  } = props

  const onContactSupport = () => {
    dispatchOpenChat()
    onConfirm()
  }

  return (
    <CancelActionModal
      title={title}
      footer={[
        <button className='button' onClick={onContactSupport} type='button'>
          {t('Contact Support')}
        </button>,
        <button className='button' onClick={onConfirm} type='button'>
          {t('Got It')}
        </button>,
      ]}
    >
      <RestrictionActionModalBody description={description} onConfirm={onConfirm} />
    </CancelActionModal>
  )
}

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { AxiosError } from 'axios'

import { Loading } from '../../../global/Loading/Loading'
import { useProductReadContext } from '../../../global/context/ProductContext'
import { useSessionLanguage } from '../../../global/context/SessionSettingsContext'
import { useNavigate } from '../../../navigation/custom-react-router-dom'
import { useApiClient } from '../../../utils/ApiClient'
import { ClientApiClient } from '../../../utils/clientApi'
import { useMutation } from '../../../utils/useMutation'
import { PaymentAgentSuccessModal } from './PaymentAgentSuccessModal'
import {
  PaymentAgentTransferForm,
  PaymentAgentTransferFormValues,
} from './PaymentAgentTransferForm/PaymentAgentTransferForm'

const usePaymentAgentWalletTransferMutate = () => {
  const { walletId } = useParams<{ walletId?: string }>()

  const apiClient = useApiClient(ClientApiClient)
  const locale = useSessionLanguage()

  const handleError = (error: AxiosError<{ message: string }>) => {
    // TODO: add error notification
    alert(error?.response?.data?.message)
  }

  return useMutation(
    async (values: PaymentAgentTransferFormValues) => {
      if (!values?.campaignName) {
        return await apiClient.transactionDepositFromPaymentAgent({
          languageId: locale,
          clientWalletId: values.clientWallet.id,
          paymentAgentWalletId: walletId || '',
          amount: values?.amount || 0,
          system: values.system,
          account: values.account,
          comment: values.comment,
          fileName: values.fileName,
          base64Content: values.base64Content,
        })
      }

      if (values?.campaignName) {
        const tradingAccountCampaign = await apiClient.tradingAccountValidateCampaignAccount(
          values.clientWallet.id,
          values.campaignName
        )
        const tradingAccountId = tradingAccountCampaign.tradingAccountId
        const [tradingAccountCampaignId] = tradingAccountCampaign.campaigns

        return await apiClient.transactionDepositFromPaymentAgent({
          languageId: locale,
          clientWalletId: values.clientWallet.id,
          paymentAgentWalletId: walletId || '',
          amount: values?.amount || 0,
          system: values.system,
          account: values.account,
          comment: values.comment,
          fileName: values.fileName,
          base64Content: values.base64Content,
          details: [
            { name: 'TradingAccountId', value: tradingAccountId },
            { name: 'CampaignId', value: tradingAccountCampaignId },
          ],
        })
      }
    },
    {
      onError: handleError,
    }
  )
}

export const PaymentAgentWalletTransferPage: React.FC = () => {
  const navigate = useNavigate()

  const [values, setValues] = useState<PaymentAgentTransferFormValues | undefined>()
  const mutation = usePaymentAgentWalletTransferMutate()
  const { isDefaultCFDProductType } = useProductReadContext()

  const handleSubmit = async (values: PaymentAgentTransferFormValues) => {
    await mutation.mutate(values)
    setValues(values)
  }

  const handleSuccess = async () => {
    navigate('/dashboard/payment-agent/transaction-history')
  }

  const handlePageExitConfirmation = () => {
    navigate(
      isDefaultCFDProductType()
        ? '/dashboard/payment-agent/wallets'
        : '/dashboard/traders-room/balances'
    )
  }
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <Loading isLoading={mutation.isLoading} showLoadingIcon text={t('Wallet.Transferring ...')}>
        <PaymentAgentTransferForm
          data={values}
          onSubmit={handleSubmit}
          onCancel={handlePageExitConfirmation}
        />
      </Loading>
      {mutation.data && values && (
        <PaymentAgentSuccessModal data={values} onConfirm={handleSuccess} />
      )}
    </React.Fragment>
  )
}

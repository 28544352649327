import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Loading } from '../../global/Loading/Loading'
import { Button } from '../../global/button/Button'
import { CopyLink } from '../../global/copyLink/CopyLink'
import IconButton from '../../global/iconButton/IconButton'
import { InfoModal } from '../../global/modal/InfoModal'
import { Modal } from '../../global/modal/Modal'
import { usePersistentWindow } from '../../hooks/usePersistentWindow'
import { CopyIcon } from '../../icons/CopyIcon'
import { DownloadIcon } from '../../icons/DownloadIcon'
import { TwoCircularArrowsIcon } from '../../icons/TwoCircularArrowsIcon'
import { AccountDetailedDto, ClientPhoneNumberDto } from '../../model/AccountDetailedDto'
import { NameDto } from '../../model/NameDto'
import {
  TwoFAProviderItem,
  TwoFactorAuthRecoveryCodeDto,
} from '../../model/TwoFactorAuthentication'
import { InfoCard } from '../../ui/Popups/InfoCard/InfoCard'
import { Text, TextH4, TextStrong, TextTiny } from '../../ui/Typography/Typography'
import { useApiClient } from '../../utils/ApiClient'
import { ClientApiClient } from '../../utils/clientApi'
import { useWindowResize } from '../../utils/domUtils'
import { TwoFactorAuthMethodsList } from './TwoFactorAuthMethodsList'

import styles from './AccountSettingsPage.module.scss'

export interface TwoFactorAuthenticationProps {
  activeProviders: TwoFAProviderItem[]
  account?: AccountDetailedDto
  providers: NameDto[]
  twoFAProvidersActionTypes: NameDto[]
  isTwoFAProvidersLoading: boolean
  onProviderToggle: (provider: NameDto, isActive: boolean) => void
  onAuthActionsProviderToggle: (provider: NameDto, isActive: boolean) => void
  onPhoneNumberChange: (phone: string, provider: NameDto) => void
  onGenerateRecoveryCodes: () => void
  setInfoModalData: (infoModalData: { text: string; isVisible: boolean }) => void
}

export const TwoFactorAuthentication: React.FC<TwoFactorAuthenticationProps> = (props) => {
  const { t } = useTranslation()

  return (
    <>
      <Text isParagraph className={styles.twoFASubtitle}>
        {t(
          'Profile.We highly recommend enabling two-factor authentication as an essential measure to enhance the security of your account'
        )}
      </Text>

      <div className={styles.shortMarginDivider} />
      <TwoFactorAuthMethodsList title={t('Profile.Choose method')} hasRecoveryCodes {...props} />
      <div className={styles.largeMarginDivider} />
      <Loading isLoading={props.isTwoFAProvidersLoading} showLoadingIcon>
        <TwoFactorAuthMethodsList
          title={t('Profile.Advanced settings')}
          isAdvancedSettingsList
          {...props}
        />
      </Loading>
    </>
  )
}

interface InfoModalProps {
  isOpen?: boolean
  close(): void
}

export const TwoFactorAuthenticationInfoModal: React.FC<InfoModalProps> = ({ close, isOpen }) => {
  const { t } = useTranslation()
  if (!isOpen) {
    return null
  }
  return (
    <Modal
      closeModal={close}
      render={() => (
        <InfoModal
          title={t('TwoFactorAuth.Two-factor authentication')}
          renderFooter={() => {
            return (
              <button className='button' onClick={close} type='button'>
                {t('Got It')}
              </button>
            )
          }}
          renderBody={() => {
            return (
              <>
                <section className='modal-card-body'>
                  <TextStrong
                    isParagraph
                    dangerouslySetInnerHTML={{
                      __html: t('Profile.What is Two-Factor Authentication?'),
                    }}
                  />
                  <Text
                    isParagraph
                    dangerouslySetInnerHTML={{
                      __html: t(
                        'Profile.A feature that helps you get access to your Client Area with improved security and minimising the security breach threats'
                      ),
                    }}
                  />
                </section>
                <div className={styles.twoFAModalDivider} />
                <section className='modal-card-body'>
                  <TextStrong
                    isParagraph
                    dangerouslySetInnerHTML={{
                      __html: t('Profile.How does it work?'),
                    }}
                  />
                  <Text
                    isParagraph
                    dangerouslySetInnerHTML={{
                      __html: t(
                        'Profile.Once enabled, a verification code will be sent to you via your selected method every time you log in'
                      ),
                    }}
                  />
                </section>
              </>
            )
          }}
        />
      )}
    />
  )
}

export interface TwoFactorAuthenticationSelectPhoneModalProps {
  close: () => void
  isOpen: boolean
  phoneNumbers: ClientPhoneNumberDto[]
  selectPhone: (phoneNumber: string) => void
  selectedPhone?: string
  onConfirm: (phoneNumber: string) => void
}

export const TwoFactorAuthenticationSelectPhoneModal: React.FC<
  TwoFactorAuthenticationSelectPhoneModalProps
> = ({ close, isOpen, phoneNumbers, onConfirm, selectedPhone, selectPhone }) => {
  const { t } = useTranslation()

  if (!isOpen) {
    return null
  }
  return (
    <Modal
      closeModal={close}
      render={() => (
        <>
          <header className='modal-card-head'>
            <p className='modal-card-title'>{t('Profile.2FA for Log in')}</p>
          </header>
          <section className='modal-card-body p-0'>
            {phoneNumbers.map((phone) => {
              return (
                <li className={styles.twoFAModalRadioListItem} key={phone.id}>
                  <input
                    type='radio'
                    value={phone.id}
                    defaultChecked={selectedPhone === phone.countryCode + phone.number}
                    onClick={() => selectPhone(phone.countryCode + phone.number)}
                    name='sessionTheme'
                  />
                  <label className='radio ml-4'>
                    {phone.countryCode} {phone.number}
                  </label>
                </li>
              )
            })}
          </section>
          <footer className={'modal-card-foot'}>
            <button className='button' onClick={close} type='button'>
              {t('Cancel')}
            </button>
            <button
              className='button'
              onClick={() => selectedPhone && onConfirm(selectedPhone)}
              disabled={!selectedPhone}
              type='submit'
            >
              {t('Confirm')}
            </button>
          </footer>
        </>
      )}
    />
  )
}

interface ConfirmTurnOffTwoFactorAuthenticationModalProps {
  onConfirm: () => void
  close: () => void
  isOpen?: boolean
}

export const ConfirmTurnOffTwoFactorAuthenticationModal: React.FC<
  ConfirmTurnOffTwoFactorAuthenticationModalProps
> = ({ onConfirm, close, isOpen }) => {
  const { t } = useTranslation()
  if (!isOpen) {
    return null
  }

  return (
    <Modal
      pullDown
      closeModal={close}
      cardClassName='modalCard twoFATurnOffModal'
      render={() => {
        return (
          <section className={classNames('modal-card-body', styles.twoFATurnOffModalWrapper)}>
            <h3>{t('Warning')}</h3>
            <Text
              isParagraph
              dangerouslySetInnerHTML={{
                __html: t(
                  'Profile.We advise you not to turn off your Two-Factor authentication (2FA) or any of the enabled channels, as that will remove the extra layer of security the 2FA adds to your Client Area'
                ),
              }}
            />

            <div className={styles.twoFATurnOffModalButtons}>
              <Button appearance='secondary' size='S' onClick={close}>
                {t('Cancel')}
              </Button>
              <Button appearance='primary' size='S' onClick={onConfirm}>
                Accept the risk
              </Button>
            </div>
          </section>
        )
      }}
    />
  )
}

const useTwoFactorPersistentWindow = (condition: boolean): void => {
  const persistentWindowCondition = useCallback(() => condition, [condition])

  usePersistentWindow(persistentWindowCondition)
}

export interface TwoFactorAuthenticationGenerateRecoveryCodesModalProps {
  close: () => void
  isOpen: boolean
}

export const TwoFactorAuthenticationGenerateRecoveryCodesModal: React.FC<
  TwoFactorAuthenticationGenerateRecoveryCodesModalProps
> = ({ close, isOpen }) => {
  const modalInnerRef = useRef<HTMLDivElement>(null)

  const { t } = useTranslation()
  const apiClient = useApiClient(ClientApiClient)
  const isMobile = useWindowResize()

  const [isRecoveryCodesLoading, setIsRecoveryCodesLoading] = useState(false)
  const [hasUserSavedRecoveryCodes, setHasUserSavedRecoveryCodes] = useState(false)
  const [recoveryCodes, setRecoveryCodes] = useState<TwoFactorAuthRecoveryCodeDto[]>([])
  const [popupTopPosition, setPopupTopPosition] = useState<number | undefined>(undefined)

  const recoveryCodesCol1 = recoveryCodes.slice(0, Math.ceil(recoveryCodes.length / 2))
  const recoveryCodesCol2 = recoveryCodes.slice(Math.ceil(recoveryCodes.length / 2))

  const recoveryCodesCols = [recoveryCodesCol1, recoveryCodesCol2]

  const isCloseDisabled = !hasUserSavedRecoveryCodes || isRecoveryCodesLoading

  const generateRecoveryCodes = useCallback(async () => {
    try {
      setIsRecoveryCodesLoading(true)
      const response = await apiClient.get2FARecoveryCodesGenerate()
      setRecoveryCodes(response.recoveryCodesDto)
      setIsRecoveryCodesLoading(false)
    } catch (error: unknown) {
      console.error(error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const reGenerateRecoveryCodes = useCallback(async () => {
    try {
      setIsRecoveryCodesLoading(true)
      const response = await apiClient.get2FARecoveryCodesRegenerate()
      setRecoveryCodes(response.recoveryCodesDto)
      setIsRecoveryCodesLoading(false)
      setHasUserSavedRecoveryCodes(false)
    } catch (error: unknown) {
      console.error(error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getRecoveryCodesAsText = () => {
    return recoveryCodes.map((code) => code.recoveryCode).join(' ')
  }

  const downloadRecoveryCodesTxtFile = () => {
    const recoveryCodesAsText = recoveryCodes.map((code) => code.recoveryCode).join('\n')

    const blob = new Blob([recoveryCodesAsText], { type: 'text/plain' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = 'recoveryCodes.txt'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(url)

    setHasUserSavedRecoveryCodes(true)
  }

  const handleClose = () => {
    if (isCloseDisabled) {
      setPopupTopPosition(modalInnerRef.current?.getBoundingClientRect().top)
      return
    }
    setPopupTopPosition(undefined)
    close()
  }

  useTwoFactorPersistentWindow(isCloseDisabled && isOpen)

  useEffect(() => {
    if (isOpen) {
      setHasUserSavedRecoveryCodes(false)
      generateRecoveryCodes()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  if (!isOpen) {
    return null
  }

  return (
    <Modal
      closeModal={handleClose}
      cardClassName={styles.recoveryCodesModalCard}
      pullDown
      render={() => (
        <div className={styles.recoveryCodesModalInner} ref={modalInnerRef}>
          <header className={styles.recoveryCodesModalHeader}>
            <TextH4>{t('Profile.Save your recovery codes')}</TextH4>
          </header>

          <section className={styles.recoveryCodesModalSection}>
            <Text
              isParagraph
              dangerouslySetInnerHTML={{
                __html: t(
                  `Profile.If you lose access to your device or email, you’ll need to use one of the following recovery codes to log into your Client Area`,
                  {
                    interpolation: { escapeValue: false },
                  }
                ),
              }}
            ></Text>
          </section>

          <section
            className={classNames(
              styles.recoveryCodesModalSection,
              styles.recoveryCodesModalSectionCodes
            )}
          >
            <Loading isLoading={isRecoveryCodesLoading} showLoadingIcon>
              <div className={styles.recoveryCodesModalColumns}>
                {recoveryCodesCols.map((recoveryCodesCol, index) => {
                  return (
                    <ul key={index} className={styles.recoveryCodesModalCodesColumn}>
                      {recoveryCodesCol.map((recoveryCode, codeIndex) => {
                        return (
                          <li
                            key={recoveryCode.recoveryCode}
                            className={styles.recoveryCodesModalCode}
                          >
                            <TextTiny>
                              {index * recoveryCodesCol1.length + codeIndex + 1}.{' '}
                              {recoveryCode.recoveryCode}
                            </TextTiny>
                          </li>
                        )
                      })}
                    </ul>
                  )
                })}
              </div>
            </Loading>
          </section>

          <section
            className={classNames(
              styles.recoveryCodesModalSection,
              styles.recoveryCodesModalSectionButtons
            )}
          >
            {!isMobile && (
              <div className='is-flex is-align-center'>
                <CopyLink
                  label={`${t('Copied')}!`}
                  value={getRecoveryCodesAsText()}
                  onCopy={() => setHasUserSavedRecoveryCodes(true)}
                >
                  <Button
                    appearance='selectable'
                    renderLeftIcon={() => <CopyIcon />}
                    className={styles.recoveryCodesModalButton}
                  >
                    {t('Copy')}
                  </Button>
                </CopyLink>
                <Button
                  className={styles.recoveryCodesModalButton}
                  appearance='selectable'
                  renderLeftIcon={() => <DownloadIcon />}
                  onClick={() => downloadRecoveryCodesTxtFile()}
                >
                  {t('Download')}
                </Button>
              </div>
            )}
            {isMobile && (
              <div className='is-flex is-align-center'>
                <CopyLink
                  label={`${t('Copied')}!`}
                  value={getRecoveryCodesAsText()}
                  onCopy={() => setHasUserSavedRecoveryCodes(true)}
                >
                  <IconButton
                    appearance='selectable'
                    className={styles.recoveryCodesModalButtonMobile}
                  >
                    <CopyIcon />
                  </IconButton>
                </CopyLink>
                <IconButton
                  className={styles.recoveryCodesModalButtonMobile}
                  onClick={() => downloadRecoveryCodesTxtFile()}
                  appearance='selectable'
                >
                  <DownloadIcon />
                </IconButton>
              </div>
            )}
            <Button
              className={styles.recoveryCodesModalButton}
              appearance='selectable'
              renderLeftIcon={() => <TwoCircularArrowsIcon />}
              onClick={() => reGenerateRecoveryCodes()}
            >
              {t(`Profile.Get new codes`)}
            </Button>
          </section>

          <footer className={styles.recoveryCodesModalFooter}>
            <InfoCard
              className={styles.recoveryCodesModalCancelTooltip}
              modalCardClassName={styles.recoveryCodesModalCancelTooltipCard}
              direction='top'
              disabled={!isCloseDisabled}
              title={t('Profile.Recovery codes')}
              text={t(
                'Profile.Please copy or download your recovery codes before you close this window'
              )}
              cardStyle={
                isMobile && popupTopPosition
                  ? {
                      top: popupTopPosition - 16,
                      position: 'fixed',
                      transform: 'translateY(-100%)',
                      margin: '0 1rem',
                    }
                  : undefined
              }
              isBackgroundLess
            >
              <Button
                className={styles.recoveryCodesModalCancelButton}
                appearance='secondary'
                state={isCloseDisabled ? 'disabled' : undefined}
                size='S'
                onClick={() => handleClose()}
                type='button'
              >
                {t('Close')}
              </Button>
            </InfoCard>
          </footer>
        </div>
      )}
    />
  )
}

import { ElementType, FC } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { NavigationRightIcon } from '../../../../icons/NavigationRightIcon'
import { Tooltip } from '../../../../ui/Popups/Tooltip/Tooltip'
import { TextH4, TextSmall } from '../../../../ui/Typography/Typography'

import styles from './Steps.module.scss'

export interface StepItem {
  title?: string
  icon: ElementType
  label?: string
}

interface StepsProps {
  firstStep?: StepItem
  nextStep?: StepItem
  finalStep?: StepItem
}

export const Steps: FC<StepsProps> = (props) => {
  const { firstStep, nextStep, finalStep } = props

  const { t } = useTranslation()

  const hasAllSteps = firstStep && nextStep && finalStep

  const getTitleByIndex = (index: number) => {
    switch (index) {
      case 0:
        return t('IB.Dashboard.Current level')
      case 1:
        return t('IB.Dashboard.Next level')
      case 2:
        return t('IB.Dashboard.Final level')
      default:
        return ''
    }
  }

  return (
    <div
      className={classNames(styles.steps, {
        [styles.center]: !hasAllSteps,
      })}
    >
      {[firstStep, nextStep, finalStep].map((step, index) => {
        if (!step) {
          return null
        }

        return (
          <>
            <Step
              key={step.label}
              title={step.title ?? getTitleByIndex(index)}
              icon={step.icon}
              label={step.label}
            />
            {index < 2 && (
              <NavigationRightIcon
                color={index === 0 ? 'iconSecondary' : 'iconDisabled'}
                className={styles.arrowIcon}
              />
            )}
          </>
        )
      })}
    </div>
  )
}

const Step: FC<StepItem> = (props) => {
  const { title, icon: Icon, label } = props

  return (
    <div className={styles.step}>
      {!!title?.length && (
        <Tooltip.New content={title}>
          <TextSmall title={title} className={styles.stepTitle}>
            {title}
          </TextSmall>
        </Tooltip.New>
      )}
      <Icon />
      {!!label?.length && (
        <TextH4 title={label} className={styles.stepLabel}>
          {label}
        </TextH4>
      )}
    </div>
  )
}

import { ReactNode } from 'react'

import { AppStoreIcon } from '../../icons/AppStoreIcon'
import { GooglePlayIcon } from '../../icons/GooglePlayIcon'
import { MacOSIcon } from '../../icons/MacOSIcon'
import { WebAppIcon } from '../../icons/WebAppIcon'
import { WindowsIcon } from '../../icons/WindowsIcon'
import { PlatformInfoDto } from '../../model/PlatformInfoDto'
import {
  OperatingSystemEnum,
  PlatformTypeEnum,
  detectAndroidOperatingSystem,
  detectDesktopOperatingSystem,
  detectIOSOperatingSystem,
  detectMacOSOperatingSystem,
  detectWindowsOperatingSystem,
} from '../../model/PlatformTypeEnum'
import { useWindowResize } from '../../utils/domUtils'

type PlatformDownloadLink = {
  icon: ReactNode
  link: string
}

type PlatformTradingViewDownloadLinksData = {
  platformDownloadLinksData: PlatformDownloadLink[]
}

interface LinkData {
  os: OperatingSystemEnum
  icon: ReactNode
  condition?: () => boolean
  desktopCondition?: () => boolean
  link: string
  enabled: boolean
}

const platformDownloadLinksData: Omit<LinkData, 'link'>[] = [
  {
    os: OperatingSystemEnum.WINDOWS,
    icon: <WindowsIcon width={153} height={40} />,
    condition: detectWindowsOperatingSystem,
    desktopCondition: detectWindowsOperatingSystem,
    enabled: true,
  },
  {
    os: OperatingSystemEnum.DESKTOP,
    icon: <WindowsIcon width={153} height={40} />,
    condition: detectDesktopOperatingSystem,
    desktopCondition: detectDesktopOperatingSystem,
    enabled: true,
  },
  {
    os: OperatingSystemEnum.OSX,
    icon: <MacOSIcon width={153} height={40} />,
    condition: detectMacOSOperatingSystem,
    desktopCondition: detectMacOSOperatingSystem,
    enabled: true,
  },
  {
    os: OperatingSystemEnum.IOS,
    icon: <AppStoreIcon width={119} height={40} />,
    condition: detectIOSOperatingSystem,
    enabled: false,
  },
  {
    os: OperatingSystemEnum.ANDROID,
    icon: <GooglePlayIcon width={135} height={40} />,
    condition: detectAndroidOperatingSystem,
    enabled: false,
  },

  {
    os: OperatingSystemEnum.WEB_TRADER,
    icon: <WebAppIcon />,
    condition: () => detectAndroidOperatingSystem() || detectIOSOperatingSystem(),
    enabled: true,
  },
]

export const usePlatformTradingViewDownloadLinksData = (
  platformInfo: PlatformInfoDto[]
): PlatformTradingViewDownloadLinksData => {
  const isMobile = useWindowResize()
  const platformData = platformInfo.find(
    ({ platfromType }) => platfromType.id === PlatformTypeEnum.TradingView
  )

  const parsedData: LinkData[] =
    platformData?.platformLinks?.map((x) => {
      const data = platformDownloadLinksData
        .filter((x) => x.enabled)
        .find((y) => y.os === x.operatingSystem.id)
      return {
        condition: data?.condition,
        desktopCondition: data?.desktopCondition,
        icon: data?.icon,
        link: x.productLink,
        os: x.operatingSystem.id,
        enabled: !!data?.enabled,
      }
    }) || []

  const platformDownloadLinksMobileData = parsedData
    .filter((x) => x.condition?.())
    .map((x) => ({
      icon: x.icon,
      link: x.link,
    }))

  const platformDownloadLinksDesktopData = parsedData
    .filter((x) => x.desktopCondition?.() ?? true)
    .map((x) => ({
      icon: x.icon,
      link: x.link,
    }))

  return {
    platformDownloadLinksData: isMobile
      ? platformDownloadLinksMobileData
      : platformDownloadLinksDesktopData,
  }
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { WalletDepositFormValues } from '../../Traders-Room/Wallets/WalletDeposit/WalletDepositForm/WalletDepositForm'
import {
  WalletDepositPage,
  useWalletDepositFetch,
} from '../../Traders-Room/Wallets/WalletDeposit/WalletDepositPage/WalletDepositPage'
import { Loading } from '../../global/Loading/Loading'
import {
  MasterTransaction,
  isTransactionFormRedirect,
  isTransactionHistory,
  isTransactionSuccessful,
  isTransactionUnSuccessful,
  isTransactionUrlRedirect,
} from '../../model/MasterTransaction'
import { isPaymentProviderBankType } from '../../model/PaymentProviderType'
import { WalletTypeEnum } from '../../model/WalletDto'
import { useNavigate } from '../../navigation/custom-react-router-dom'

export const PaymentAgentWalletDepositPage: React.FC = () => {
  const { walletId } = useParams<{ walletId?: string }>()

  const navigate = useNavigate()

  const handlePageExitConfirmation = () => {
    navigate(`/dashboard/payment-agent/wallets`)
  }

  const { data, isLoading } = useWalletDepositFetch({
    onCancel: handlePageExitConfirmation,
  })

  const handleSubmit = (data: MasterTransaction, values: WalletDepositFormValues) => {
    if (isTransactionHistory(data)) {
      navigate(`/dashboard/payment-agent/transaction-history`)
    }

    if (isPaymentProviderBankType(values.paymentProvider.providerCategory.id)) {
      navigate(`/dashboard/payment-agent/wallets/${values.wallet.id}/deposit/transaction/succeed`, {
        state: {
          data,
          providerCategoryId: values.paymentProvider.providerCategory.id,
        },
      })
    }

    if (isTransactionUrlRedirect(data)) {
      navigate(`/dashboard/payment-agent/wallets/${walletId}/deposit/redirect`, {
        state: {
          transaction: data,
          values,
        },
      })
    }

    if (isTransactionFormRedirect(data)) {
      navigate(`/dashboard/payment-agent/wallets/${walletId}/deposit/redirect`, {
        state: {
          transaction: data,
          values,
        },
      })
    }

    if (isTransactionSuccessful(data)) {
      navigate(`/dashboard/payment-agent/wallets/${values.wallet.id}/deposit/transaction/succeed`, {
        state: {
          data,
          providerCategoryId: values.paymentProvider.providerCategory.id,
        },
      })
    }

    if (isTransactionUnSuccessful(data)) {
      navigate(`/dashboard/payment-agent/wallets/${values.wallet.id}/deposit/transaction/failed`, {
        state: {
          data,
          providerCategoryId: values.paymentProvider.providerCategory.id,
        },
      })
    }
  }

  const { t } = useTranslation()

  return (
    <Loading isLoading={isLoading}>
      {data && (
        <WalletDepositPage
          wallet={data}
          walletType={WalletTypeEnum.PAYMENT_AGENT}
          onSubmit={handleSubmit}
          onCancel={handlePageExitConfirmation}
          title={t('Payment Agent.Payment Agent')}
        />
      )}
    </Loading>
  )
}

import { RefObject, useCallback, useEffect, useRef, useState } from 'react'

import { useResizeEffect } from './useResizeEffect'

export interface HorizontalScroll<T = HTMLElement> {
  scrollContainerRef: RefObject<T>
  scrollContainerInnerRef: RefObject<T>
  hasScrolledToEnd: boolean
  hasScrolledToStart: boolean
  isScrollable: boolean
}

export const useHorizontalScroll = <T extends HTMLElement>(): HorizontalScroll<T> => {
  const scrollContainerRef = useRef<T>(null)
  const scrollContainerInnerRef = useRef<T>(null)

  const [hasScrolledToEnd, setHasScrolledToEnd] = useState(false)
  const [hasScrolledToStart, setHasScrolledToStart] = useState(true)
  const [isScrollable, setIsScrollable] = useState(false)

  const checkIfScrolledToAnyEnd = useCallback(() => {
    if (scrollContainerRef.current && scrollContainerInnerRef.current) {
      const { scrollLeft, clientWidth, scrollWidth } = scrollContainerRef.current
      const diff = Math.abs(scrollLeft + clientWidth - scrollWidth)

      setHasScrolledToEnd(diff < 1)
      setHasScrolledToStart(scrollLeft === 0)
    }
  }, [])

  const updateScrollableState = useCallback(() => {
    if (scrollContainerRef.current && scrollContainerInnerRef.current) {
      const containerWidth = scrollContainerRef.current.clientWidth
      const innerWidth = scrollContainerInnerRef.current.clientWidth

      setIsScrollable(innerWidth > containerWidth)

      checkIfScrolledToAnyEnd()
    }
  }, [checkIfScrolledToAnyEnd])

  const handleChange = useCallback(() => {
    checkIfScrolledToAnyEnd()
    updateScrollableState()
  }, [checkIfScrolledToAnyEnd, updateScrollableState])

  useResizeEffect(handleChange)

  useEffect(() => {
    if (scrollContainerRef.current && scrollContainerInnerRef.current) {
      const scrollContainer = scrollContainerRef.current

      const handleScroll = () => {
        checkIfScrolledToAnyEnd()
      }

      scrollContainer.addEventListener('scroll', handleScroll)

      return () => {
        scrollContainer.removeEventListener('scroll', handleScroll)
      }
    }
  }, [checkIfScrolledToAnyEnd])

  useEffect(() => {
    const observer = new ResizeObserver(handleChange)
    const container = scrollContainerRef.current
    const inner = scrollContainerInnerRef.current

    if (inner) {
      observer.observe(inner)
    }

    if (container) {
      observer.observe(container)
    }

    handleChange()

    return () => {
      observer.disconnect()
    }
  }, [handleChange])

  return {
    scrollContainerRef,
    scrollContainerInnerRef,
    hasScrolledToEnd,
    hasScrolledToStart,
    isScrollable,
  }
}

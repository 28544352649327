import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Button } from '../../global/button/Button'
import { Modal } from '../../global/modal/Modal'

import styles from './BalancesOptionsModal.module.scss'

interface BalancesOptionsModalProps {
  closeModal: () => void
  renderOptions: () => React.ReactNode
}

export const BalancesOptionsModal: React.FC<BalancesOptionsModalProps> = ({
  closeModal,
  renderOptions,
}) => {
  const { t } = useTranslation()

  return (
    <Modal
      pullDown
      cardClassName={styles.modal}
      closeModal={closeModal}
      render={({ closeModal }) => (
        <section className={classNames(styles.body)}>
          {renderOptions()}

          <footer className={classNames(styles.footer)}>
            <Button onClick={closeModal} type='button' appearance='secondary' fullWidth size='M'>
              {t('Cancel')}
            </Button>
          </footer>
        </section>
      )}
    />
  )
}

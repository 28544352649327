import { Loading } from '../../../global/Loading/Loading'
import { TextH1 } from '../../../ui/Typography/Typography'

import styles from './NoteWrapper.module.scss'

interface NotWrapperProps {
  title: string
  id?: string
  text?: React.ReactNode
  button?: React.ReactNode
  isLoading?: boolean
}

export const NoteWrapper: React.FC<NotWrapperProps> = (props) => {
  const { title, text, button, isLoading = false, id } = props

  return (
    <div id={id} className={styles.noteWrapper}>
      <Loading isLoading={isLoading} showLoadingIcon>
        <div className={styles.textWrapper}>
          <TextH1>{title}</TextH1>
          {!!text && <div className={styles.text}>{text}</div>}
        </div>
        {button}
      </Loading>
    </div>
  )
}

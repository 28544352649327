import React, { FC, MouseEventHandler, PropsWithChildren, ReactNode } from 'react'
import classNames from 'classnames'

import styles from './Button.module.scss'

export enum ButtonAppearance {
  Primary = 'primary',
  PrimaryNew = 'primaryNew',
  Secondary = 'secondary',
  SecondaryNew = 'secondaryNew',
  Plain = 'plain',
  Link = 'link',
  Tertiary = 'tertiary',
  Selectable = 'selectable',
  Ghost = 'ghost',
}

export const isButtonAppearanceGhost = (appearance?: string): boolean => {
  return ButtonAppearance.Ghost === appearance
}

export const isButtonAppearanceSecondary = (appearance?: string): boolean => {
  return ButtonAppearance.Secondary === appearance
}

enum ButtonState {
  Normal = 'normal',
  Focus = 'focus',
  Disabled = 'disabled',
  Static = 'static',
}

export interface ButtonProps {
  type?: 'submit' | 'reset' | 'button' | undefined
  appearance?: AppearanceType
  state?: 'normal' | 'focus' | 'disabled' | 'static' | undefined
  size?: 'XS' | 'S' | 'M' | 'L'
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  disabled?: boolean
  fullWidth?: boolean
  className?: string
  renderLeftIcon?: () => ReactNode
  loading?: boolean
  renderRightIcon?: () => ReactNode
  onMouseOver?: MouseEventHandler<HTMLButtonElement>
  onMouseOut?: MouseEventHandler<HTMLButtonElement>
}

type AppearanceType =
  | 'primary'
  | 'primaryNew'
  | 'secondary'
  | 'secondaryNew'
  | 'plain'
  | 'link'
  | 'tertiary'
  | 'selectable'
  | 'ghost'

export const Button: FC<PropsWithChildren<ButtonProps>> & {
  New: FC<PropsWithChildren<ButtonProps>>
} = ({
  type,
  className,
  appearance,
  state,
  size,
  onClick,
  disabled,
  loading,
  fullWidth,
  renderLeftIcon,
  renderRightIcon,
  onMouseOut,
  onMouseOver,
  children,
}) => {
  return (
    <button
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      className={classNames('button', className, styles.btn, {
        [styles.tertiary]: appearance === ButtonAppearance.Tertiary,
        [styles.isPlain]: appearance === ButtonAppearance.Plain,
        [styles.primary]: appearance === ButtonAppearance.Primary,
        [styles.primaryNew]: appearance === ButtonAppearance.PrimaryNew,
        [styles.ghost]: appearance === ButtonAppearance.Ghost,
        [styles.secondary]: appearance === ButtonAppearance.Secondary,
        [styles.secondaryNew]: appearance === ButtonAppearance.SecondaryNew,
        [styles.ghost]: appearance === ButtonAppearance.Ghost,
        [styles.link]: appearance === ButtonAppearance.Link,
        [styles.selectable]: appearance === ButtonAppearance.Selectable,
        [styles.focus]: state === ButtonState.Focus,
        [styles.disabled]: state === ButtonState.Disabled || disabled || loading,
        [styles.static]: state === ButtonState.Static,
        [styles.fullWidth]: fullWidth,
        [styles[`size-${size}`]]: !!size,
      })}
      type={type}
      disabled={loading || disabled}
    >
      <span className={classNames(styles.contentWrapper)}>
        {loading && (
          <div className={styles.loadingWrapper}>
            <div className={styles.loadingSpinner} />
          </div>
        )}
        {renderLeftIcon && <span className={styles.sideIcon}>{renderLeftIcon()}</span>}
        <span>{children}</span>
        {renderRightIcon && <span className={styles.sideIcon}>{renderRightIcon()}</span>}
      </span>
    </button>
  )
}

Button.New = (props) => {
  return <Button {...props} appearance={getNewAppearance(props)} />
}

const getNewAppearance = (props: ButtonProps) => {
  const { appearance } = props

  if (appearance === ButtonAppearance.Primary) {
    return ButtonAppearance.PrimaryNew
  }

  if (appearance === ButtonAppearance.Secondary) {
    return ButtonAppearance.SecondaryNew
  }

  return undefined
}

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAccountInfo } from '../global/context/AccountInfoContext/AccountInfoContext.Provider'
import { useModalsContext } from '../global/context/ModalsContext'
import { ConfirmationModal as ConfirmationModalView } from '../global/modal/ConfirmationModal'
import { LuckyDrawModal as LuckyDrawModalView } from '../global/modal/LuckyDrawModal'
import { Modal } from '../global/modal/Modal'
import { LuckyDrawCampaign } from '../model/LuckyDrawDto'
import { useNavigate } from '../navigation/custom-react-router-dom'

export enum TradersRoomModalsType {
  LuckyDraw = 'TradersRoomLuckyDrawModal',
  Confirmation = 'TradersRoomConfirmationModal',
}

type ModalType = TradersRoomModalsType | number | string

export const isTradersRoomLuckyDrawModalOpen = (value: ModalType): boolean => {
  return value === TradersRoomModalsType.LuckyDraw
}

export const isTradersRoomConfirmationModalOpen = (value: ModalType): boolean => {
  return value === TradersRoomModalsType.Confirmation
}

export const TradersRoomModals: React.FC = () => {
  const { modalData, activeModal, closeModal } = useModalsContext()

  switch (activeModal as TradersRoomModalsType) {
    case TradersRoomModalsType.LuckyDraw:
      return <LuckyDrawModal {...modalData} closeModal={closeModal} />
    case TradersRoomModalsType.Confirmation:
      return <ConfirmationModal closeModal={closeModal} />
    default:
      return null
  }
}

interface ModalProps {
  closeModal(): void
}

interface LuckyDrawModalProps extends ModalProps {
  campaign: LuckyDrawCampaign
}

const LuckyDrawModal: React.FC<LuckyDrawModalProps> = (props) => {
  const { campaign, closeModal } = props

  const [isLuckyDrawPlaying, setLuckyDrawPlaying] = useState<boolean>(false)

  if (!campaign) {
    return null
  }

  return (
    <Modal
      closeModal={() => {
        if (!isLuckyDrawPlaying) {
          localStorage.setItem('luckyDrawViewed', 'true')
          closeModal()
        }
      }}
      render={({ closeModal }) => (
        <LuckyDrawModalView
          passSetPlaying={setLuckyDrawPlaying}
          luckyCampaign={campaign}
          onClose={closeModal}
        />
      )}
    />
  )
}

export const ConfirmationModal: React.FC<ModalProps> = (props) => {
  const { closeModal } = props

  const { t } = useTranslation()
  const navigate = useNavigate()

  const { accountInfoLimits } = useAccountInfo()

  return (
    <Modal
      closeModal={closeModal}
      render={() => (
        <ConfirmationModalView
          onConfirm={() => navigate(`/dashboard/traders-room/trading-accounts/new`)}
          title={t('Campaign Account')}
          renderFooter={() => (
            <React.Fragment>
              <button className='button' onClick={closeModal} type='button'>
                {t('Not now')}
              </button>
              <button
                disabled={accountInfoLimits?.hasReachedMaxTradingAccounts}
                className='button'
                onClick={() => {
                  closeModal()
                  navigate(`/dashboard/traders-room/trading-accounts/new?campaign=true`)
                }}
                type='button'
              >
                <b>{t('Create Campaign Account')}</b>
              </button>
            </React.Fragment>
          )}
          onCancel={closeModal}
        >
          <p>
            {t('Campaigns.A Campaign Account is a Trading Account linked to a specific campaign')}{' '}
            {t('Campaigns.Do you want to create a Campaign Account now')}
          </p>
        </ConfirmationModalView>
      )}
    />
  )
}

import { FC, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Button } from '../global/button/Button'
import { useHomePageUrl } from '../hooks/useHomePageUrl'
import { Link } from '../navigation/custom-react-router-dom'
import { TextH3, TextLargeStrong } from '../ui/Typography/Typography'

import styles from './WarningAccountPage.module.scss'

export type WarningPageStatus = 'Closed' | 'Inactive'

interface WarningAccountPageProps {
  status: WarningPageStatus
}

export const WarningAccountPage: FC<WarningAccountPageProps> = ({ status }) => {
  const { t } = useTranslation()

  const homePageUrl = useHomePageUrl()

  const isStillMounted = useRef(true)

  useEffect(() => {
    isStillMounted.current = false

    const timer = setTimeout(() => {
      isStillMounted.current = true
    }, 100)

    return () => {
      if (isStillMounted.current) {
        localStorage.setItem(`hasVisited${status}AccountPage`, 'true')
      }
      clearTimeout(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const Content: Record<WarningPageStatus, { title: string; body: string }> = {
    Closed: {
      title: t('Your account is currently closed'),
      body: t('To reactivate your account, please read and accept the terms'),
    },
    Inactive: {
      title: t('Your account is currently inactive'),
      body: `${t('Due to inactivity your account became inactive')} ${t(
        'To reactivate your account, please read and accept the terms'
      )}`,
    },
  }

  return (
    <section className={styles.wrapper}>
      <TextLargeStrong className={classNames(styles.title, 'is-uppercase')}>
        {t('My Account')}
      </TextLargeStrong>
      <div className='my-6 has-text-centered'>
        <TextH3 className='is-uppercase mb-5'>{t('Warning')}!</TextH3>
        <div className={classNames(styles.box, 'has-text-centered')}>
          <div className={styles.content}>
            <h1 className='mb-5'>{Content[status].title}</h1>
            <p className='text'>{Content[status].body}</p>
          </div>
          <div className={classNames(styles.reverseColumns, 'columns is-centered mt-3')}>
            <div className='column is-4'>
              <Link to={'/dashboard/learning/trainings'}>
                <Button size='M' appearance='secondary' className='is-fullwidth'>
                  <span>{t('Training Materials')}</span>
                </Button>
              </Link>
            </div>
            <div className='column is-4'>
              <Button appearance='primary' size='M' className='is-fullwidth'>
                <span>{t('Reactivate Account')}</span>
              </Button>
            </div>
          </div>
          <div>
            <a
              href={`${homePageUrl}/trading/demo-account`}
              target='_blank'
              rel='noreferrer'
              className='is-link text'
            >
              {t('Get Your Free Demo Account')}
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

import React, { useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { throttle } from 'lodash'

import { Button } from '../../../../../global/button/Button'
import { useNavigate } from '../../../../../navigation/custom-react-router-dom'
import { isCannotCreateLeadError, isInvalidLeadError } from '../../../../../routing/RouterToaster'
import { Text } from '../../../../../ui/Typography/Typography'
import { ResponseError, instanceOfAxiosError, useApiClient } from '../../../../../utils/ApiClient'
import { useSupportContacts } from '../../../../../utils/SupportContactsContext'
import { ClientApiClient } from '../../../../../utils/clientApi'
import { NoteWrapper } from '../../../Components/NoteWrapper'
import { SignupErrorStep } from '../../../Components/SignupErrorStep'

export enum SignupError {
  Unavailable = 'verifiable_action_unavailable',
  TokenExpired = 'lead_token_expired',
  BackToLogin = 'lead_verification_request_error',
  LeadVerificationErrorException = 'lead_verification_error',
  LeadVerificationRequestErrorException = 'lead_verification_request_error',
}

export const SignupErrorPage: React.FC = () => {
  const [searchParams] = useSearchParams()

  const apiClient = useApiClient(ClientApiClient)
  const navigate = useNavigate()

  const leadId = searchParams.get('leadId') ?? ''
  const leadEmail = searchParams.get('leadEmail') ?? ''
  const errorParam = searchParams.get('error')
  const [error, setError] = useState(errorParam ?? '')

  const [loading, setLoading] = useState(false)

  const { t } = useTranslation()

  const handleResend = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()

    try {
      setLoading(true)
      await apiClient.resendCreateLeadEmail(leadId)
      const searchParams = new URLSearchParams()
      if (leadEmail) {
        searchParams.set('leadEmail', leadEmail)
      }
      searchParams.set('leadId', leadId)
      navigate(`/sign-up/personal-details/verify-email/resend?${searchParams.toString()}`)
    } catch (e: unknown) {
      if (instanceOfAxiosError((e as ResponseError)?.response)) {
        const errorResponse = (e as ResponseError).response.response?.data
        if (errorResponse?.code) {
          setError(errorResponse.code)
        }
      }
    } finally {
      setLoading(false)
    }
  }

  const throttledHandleResend = useRef(
    throttle(handleResend, 1000, { leading: true, trailing: false })
  )

  const getErrorBody = () => {
    switch (error) {
      case SignupError.Unavailable:
        return [
          t(
            'Sign up.Your link has expired and is not valid anymore. You can start a new registration right away.'
          ),
        ]
      case SignupError.TokenExpired:
        return [t('Sign up.The link you clicked has expired')]

      default:
        return []
    }
  }

  const getErrorHeader = () => {
    switch (error) {
      case SignupError.Unavailable:
        return t('Sign up.Looks like your link has expired')
      case SignupError.TokenExpired:
        return t('Sign up.Your verification link has expired')
      default:
        return t('Sign up.Something went wrong')
    }
  }

  const getErrorButton = () => {
    switch (error) {
      case SignupError.Unavailable:
        return {
          link: '/sign-up',
          onClick: () => navigate('/sign-up'),
          text: t('Sign up.Start Registration'),
        }
      case SignupError.TokenExpired:
        return {
          onClick: throttledHandleResend.current,
          text: t('Sign up.Resend Verification Email'),
        }
      default:
        return { link: '/support', text: t('Contact support') }
    }
  }

  const goBack = () => navigate('/sign-up/personal-details/3/')

  if (isInvalidLeadError(error)) {
    return <InvalidLeadError error={error} goBack={goBack} />
  }

  if (isCannotCreateLeadError(error)) {
    return (
      <CannotCreateLeadError
        error={error}
        onResend={throttledHandleResend.current}
        goBack={goBack}
      />
    )
  }

  return (
    <NoteWrapper
      isLoading={loading}
      title={getErrorHeader()}
      text={getErrorBody().map((text) => (
        <Text isParagraph>{text}</Text>
      ))}
      button={
        <Button appearance='primary' size='L' type='button' onClick={getErrorButton().onClick}>
          {getErrorButton().text}
        </Button>
      }
    />
  )
}

interface InvalidLeadErrorProps {
  error: string
  goBack(): void
}

const InvalidLeadError: React.FC<InvalidLeadErrorProps> = (props) => {
  const { error, goBack } = props

  const { t } = useTranslation()
  const navigate = useNavigate()
  const { supportEmail } = useSupportContacts()

  const errorContent = () => {
    switch (error) {
      case 'missing_gender_id':
        return t('Sign up.Gender was not specified.')
      case 'invalid_gender':
        return t('Sign up.Invalid gender.')
      case 'name_contains_illegal_characters':
        return t('Sign up.Please use latin characters only for name.')
      case 'invalid_first_name':
        return t('Sign up.First name contains unsupported characters.')
      case 'invalid_last_name':
        return t('Sign up.Last name contains unsupported characters.')
      case 'invalid_birth_day':
        return t('Sign up.Selected birthday is invalid.')
      case 'invalid_email':
        return t('Sign up.Email provided is invalid.')
      case 'invalid_lead_type':
        return t('Sign up.Account type selected is invalid.')
      case 'invalid_phone_number_length':
        return t('Sign up.Phone number needs to be 5 or more digits.')
      case 'invalid_country':
        return t('Sign up.This country is not available for this Tickmill entity.')
      case 'invalid_language':
        return t('Sign up.Language selected is invalid.')
      case 'invalid_user_status_aptest_limit':
        return t(
          'Sign up.Maximum attempts reached. You will be able to re-take the appropriateness test in 1 year. Please contact {{email}} for further assistance.',
          {
            email: supportEmail,
          }
        )
      case 'invalid_user_status_vulnerable_client':
        return t(
          'Sign up.From the information you have provided, we do not think this product is appropriate for you at this time. If you would like to discuss further, please contact {{email}}.',
          {
            email: supportEmail,
          }
        )
      case 'address_street_client_contains_illegal_characters':
        return t('Sign up.Address street contains unsupported characters')
      case 'address_city_client_contains_illegal_characters':
        return t('Sign up.Address city contains unsupported characters')
      case 'address_county_client_contains_illegal_characters':
        return t('Sign up.Address state contains unsupported characters')
      case 'address_postcode_client_contains_illegal_characters':
        return t('Sign up.Address postcode contains unsupported characters')
      case 'address_street_company_contains_illegal_characters':
        return t('Sign up.Company address street contains unsupported characters')
      case 'address_city_company_contains_illegal_characters':
        return t('Sign up.Company address city contains unsupported characters')
      case 'address_county_company_contains_illegal_characters':
        return t('Sign up.Company address state contains unsupported characters')
      case 'address_postcode_company_contains_illegal_characters':
        return t('Sign up.Company address postcode contains unsupported characters')
      default:
        return null
    }
  }

  return (
    <React.Fragment>
      <SignupErrorStep goBack={goBack} />
      <NoteWrapper
        title={t('Sign up.Error!')}
        text={
          <>
            <Text>{errorContent()}</Text>
            <Text>
              {t('Sign up.Go back to')}{' '}
              <Button appearance='link' onClick={() => navigate('/login')}>
                {t('Sign up.Login Page')}
              </Button>
            </Text>
          </>
        }
      />
    </React.Fragment>
  )
}

interface CannotCreateLeadErrorProps {
  error: string
  onResend(event: React.MouseEvent<HTMLButtonElement>): void
  goBack(): void
}

const CannotCreateLeadError: React.FC<CannotCreateLeadErrorProps> = (props) => {
  const { error, onResend, goBack } = props

  const { t } = useTranslation()
  const navigate = useNavigate()

  const errorContent = () => {
    switch (error) {
      case 'already_awaiting_lead_verification':
        return t(
          'Sign up.Verification email has already been sent to you, please verify your email before proceeding.'
        )
      case 'already_started_another_lead_verification_process':
        return (
          <Trans
            i18nkey={t(
              'Sign up.You have started the registration process earlier. To continue registration please press'
            )}
          >
            <Text>
              {t(
                'Sign up.You have started the registration process earlier. To continue registration please press'
              )}
            </Text>
            <Button appearance='link' onClick={onResend}>
              {t('Sign up.Resend')} {t('Sign up.email')}
            </Button>
            .
          </Trans>
        )
      case 'lead_already_converted':
        return t('Sign up.We already have a record with this e-mail account.')
      case 'lead_already_exists':
        return t(
          'Sign up.We already have a record with this e-mail account. Please verify your e-mail.'
        )
      default:
        return null
    }
  }

  return (
    <React.Fragment>
      <SignupErrorStep goBack={goBack} />
      <NoteWrapper
        title={t('Sign up.Error!')}
        text={
          <>
            <Text>{errorContent()}</Text>
            <Text>
              {t('Sign up.Go back to')}{' '}
              <Button appearance='link' onClick={() => navigate('/login')}>
                {t('Sign up.Login Page')}
              </Button>
            </Text>
          </>
        }
      />
    </React.Fragment>
  )
}

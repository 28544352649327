import { useMemo } from 'react'

import {
  IntroducingBrokerReferralDetailsDto,
  IntroducingBrokerReferralTypes,
} from '../model/IntroducingBrokerDetailsDto'
import { useAccountReadContext } from './AccountContextContext'
import { useApiClient } from './ApiClient'
import { ClientApiClient } from './clientApi'
import { useCallbackWithForceRefresh } from './useCallbackWithForceRefresh'
import { useFetchOne } from './useFetch'

export interface IBReferralCodes {
  ibCode?: string
  mtCode?: string
  referralCodes: IntroducingBrokerReferralDetailsDto[]
  isLoading: boolean
  hideSelectors?: boolean
  refreshReferralCodes: () => void
  legalDocumentsSchemes: string
}

export const useIBReferralCodes = (): IBReferralCodes => {
  const { account } = useAccountReadContext()
  const introducingBrokerId = account?.clientIntroducingBroker?.id
  const apiClient = useApiClient(ClientApiClient)

  const { callback: referralCodeCallback, forceRefresh } = useCallbackWithForceRefresh(
    async () => {
      const referralCodes = introducingBrokerId
        ? await apiClient.getIBReferralCodes(introducingBrokerId)
        : []

      const ibCode =
        (await referralCodes.find(
          ({ mainIbScheme }) => mainIbScheme.id === IntroducingBrokerReferralTypes.Default
        )?.code) || undefined

      const mtCode =
        (await referralCodes.find(
          ({ mainIbScheme }) => mainIbScheme.id === IntroducingBrokerReferralTypes.MultiTier
        )?.code) || undefined

      return {
        ibCode,
        mtCode,
        referralCodes: referralCodes || [],
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [introducingBrokerId]
  )
  const { data, isLoading } = useFetchOne(referralCodeCallback)

  const legalDocumentsSchemes = useMemo(
    () =>
      data?.referralCodes
        .map((referralCode) => {
          return `&IbSchemes=${referralCode.mainIbScheme.id}`
        })
        .join('') || '',
    [data?.referralCodes]
  )
  const hideSelectors = useMemo(() => {
    if (!data) {
      return true
    }
    const { ibCode, mtCode } = data
    if (!ibCode && !mtCode) {
      return true
    }
    return false
  }, [data])

  return {
    ibCode: data?.ibCode,
    mtCode: data?.mtCode,
    referralCodes: data?.referralCodes || [],
    isLoading: isLoading,
    hideSelectors,
    refreshReferralCodes: forceRefresh,
    legalDocumentsSchemes,
  }
}

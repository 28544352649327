import { IconProps } from './IconProps'

export const Step4Icon = (props: IconProps): JSX.Element => {
  const width = props.width || '40'
  const height = props.height || '40'
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='40' height='40' rx='6' fill='#CC0000' />
      <path
        d='M25.06 24.84H23.08V28H21.02V24.84H14.4V23.16L20.98 13.66H23.08V23.04H25.06V24.84ZM21.02 19.16C21.02 18.84 21.02 18.5333 21.02 18.24C21.0333 17.9467 21.0467 17.6667 21.06 17.4C21.0733 17.12 21.08 16.8667 21.08 16.64C21.0933 16.4 21.1067 16.1867 21.12 16H21.04C20.9333 16.2667 20.8 16.54 20.64 16.82C20.4933 17.1 20.34 17.3467 20.18 17.56L16.4 23.04H21.02V19.16Z'
        fill='white'
      />
    </svg>
  )
}

import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { useSessionLanguage } from '../../global/context/SessionSettingsContext'
import { mapAcuityLocale } from '../../global/locale/Locale'
import { TextSmall } from '../../ui/Typography/Typography'
import { AcquityHeader } from '../AcquityHeader'

import styles from './ResearchTerminal.module.scss'

declare const window: Window & { AcuityWidgets: any }

export const ResearchTerminal: React.FC = () => {
  const { t } = useTranslation()
  const locale = useSessionLanguage()

  const researchTerminalWidgetRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    window.AcuityWidgets.globals({
      apikey: 'f3eb0d37-bce0-40e2-bf70-1ce9fbfb8d90',
      locale: mapAcuityLocale(locale),
    })

    const widget = window.AcuityWidgets.CreateWidget(
      'acuityresearchterminal',
      researchTerminalWidgetRef.current,
      { settingId: 6991 }
    )

    widget.mount()

    return () => {
      widget.unmount && widget.unmount()
    }
  }, [locale])

  return (
    <>
      <AcquityHeader
        title={t('Research Terminal')}
        description={t('Elevate your trading experience with Research Terminal')}
      />
      <div id='acuity-research-terminal' ref={researchTerminalWidgetRef}></div>
      <div className={styles.disclaimer}>
        <TextSmall isParagraph className={styles.disclaimerText}>
          {t(
            'Any information or comments provided by third parties do not reflect the views of Tickmill'
          )}
        </TextSmall>
        <TextSmall isParagraph className={styles.disclaimerText}>
          {t(
            'All content is for information purposes only and should not be considered as investment advice or research'
          )}
        </TextSmall>
      </div>
    </>
  )
}

import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Button } from '../../../../global/button/Button'
import { CopyButton } from '../../../../global/button/CopyButton'
import { useProductReadContext } from '../../../../global/context/ProductContext'
import { FormTemplate } from '../../../../global/templates/FormTemplate'
import { MasterTransaction } from '../../../../model/MasterTransaction'
import { useNavigate } from '../../../../navigation/custom-react-router-dom'
import { TextSmall, TextStrong, TextTiny } from '../../../../ui/Typography/Typography'
import { useWindowResize } from '../../../../utils/domUtils'

import styles from './WalletDepositTransactionSuccessBankPage.module.scss'

interface WalletDepositTransactionSuccessProps {
  transaction: MasterTransaction
  onCancel(): void
}

export const WalletDepositTransactionSuccessBankPage: React.FC<
  WalletDepositTransactionSuccessProps
> = ({ transaction, onCancel }) => {
  const [walletTransaction] = transaction.walletTransactions
  const data = walletTransaction.paymentProviderTransaction.response.redirectData.data
  const reference = transaction.referenceId
  const navigate = useNavigate()
  const { isDefaultCFDProductType } = useProductReadContext()

  const handleClose = () => {
    navigate(
      isDefaultCFDProductType()
        ? '/dashboard/traders-room/wallets'
        : '/dashboard/traders-room/balances'
    )
  }

  const { t } = useTranslation()

  return (
    <FormTemplate title={t('Wallet.Deposit')} goBack={handleClose}>
      <div>
        <TextSmall isParagraph className={styles.additionalNote}>
          {t(
            'Wallet.Please use the information below to make a bank transfer and fund your wallet'
          )}
        </TextSmall>

        <TransactionInformationField
          label={t('Wallet.Deposit To Wallet')}
          value={`${walletTransaction.wallet.currency.id} ${t('Wallet.Wallet')}`}
        />

        <TransactionInformationField
          label={t('Wallet.Amount')}
          value={walletTransaction.amount.toString()}
          additionalText={t('Wallet.Please note that the amount funded to your account may differ')}
        />

        {Object.entries(data).map(([key, value = '']) => (
          <TransactionInformationField label={key} value={value} />
        ))}
        {reference && (
          <TransactionInformationField
            label={t('Wallet.Reference number')}
            value={reference}
            additionalText={t(
              'Wallet.Please note that this reference number is unique only for this deposit'
            )}
          />
        )}
        <div className='columns'>
          <div className='column'>
            <div className={classNames('buttons is-centered', styles.marginTop)}>
              <Button type='submit' appearance='secondary' onClick={onCancel} size='L'>
                {t('Wallet.Back to Dashboard')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </FormTemplate>
  )
}

interface TransactionInformationFieldProps {
  label: string
  value: string
  additionalText?: string
}

export const TransactionInformationField: React.FC<TransactionInformationFieldProps> = (props) => {
  const { label, value, additionalText } = props

  return (
    <Fragment key={label}>
      <div className={classNames('field', { [styles.resetMargin]: additionalText })}>
        <div className={styles.fieldWrapper}>
          <div className='control'>
            <div className={styles.field}>
              <div className={styles.fieldText}>
                <label className={styles.label}>{label}</label>
                <TextStrong>{value}</TextStrong>
              </div>
              <CopyButton value={value} borderVariant='borderless' />
            </div>
          </div>
        </div>
      </div>
      {additionalText && (
        <TextTiny isParagraph className={styles.additionalText}>
          {additionalText}
        </TextTiny>
      )}
    </Fragment>
  )
}

import { useTranslation } from 'react-i18next'

import { TabProps, Tabs } from '../global/tabs/Tabs'
import { useCurrentTab } from '../hooks/useCurrentTab'
import { useNavigate } from '../navigation/custom-react-router-dom'

import styles from './SubscriptionMobileTabs.module.scss'

export const SubscriptionMobileTabs: React.FC = () => {
  const currentTab = useCurrentTab('subscriptions')
  const { t } = useTranslation()
  const navigate = useNavigate()

  const tabs: TabProps[] = [
    {
      id: 'trading-platforms',
      name: t('Subscriptions.Trading Platforms'),
    },
    {
      id: 'market-data',
      name: t('Market Data.title'),
    },
  ]

  const handleTabChange = (tab: string) => {
    navigate(`/dashboard/subscriptions/${tab}`)
  }

  return (
    <Tabs
      wrapperClassName={styles.tabsWrapper}
      tabs={tabs}
      currentTab={currentTab}
      onTabChanged={handleTabChange}
    />
  )
}

import { ElementType } from 'react'

import { LevelFiveIcon } from '../../../icons/LevelFiveIcon'
import { LevelFourIcon } from '../../../icons/LevelFourIcon'
import { LevelOneIcon } from '../../../icons/LevelOneIcon'
import { LevelSixIcon } from '../../../icons/LevelSixIcon'
import { LevelThreeIcon } from '../../../icons/LevelThreeIcon'
import { LevelTwoIcon } from '../../../icons/LevelTwoIcon'

export const iconByLevel: Record<number, ElementType> = {
  1: LevelOneIcon,
  2: LevelTwoIcon,
  3: LevelThreeIcon,
  4: LevelFourIcon,
  5: LevelFiveIcon,
  6: LevelSixIcon,
}

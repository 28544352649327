import React from 'react'
import { useParams } from 'react-router-dom'

import { useProductReadContext } from '../../../../global/context/ProductContext'
import {
  MasterTransaction,
  isTransactionFormRedirect,
  isTransactionHistory,
  isTransactionSuccessful,
  isTransactionUnSuccessful,
  isTransactionUrlRedirect,
} from '../../../../model/MasterTransaction'
import { isPaymentProviderBankType } from '../../../../model/PaymentProviderType'
import { WalletTypeEnum } from '../../../../model/WalletDto'
import { useNavigate } from '../../../../navigation/custom-react-router-dom'
import { WalletDepositFormValues } from '../WalletDepositForm/WalletDepositForm'
import { WalletDepositPage } from './WalletDepositPage'

interface Props {
  walletType: WalletTypeEnum
}
export const TradersRoomWalletStartDepositPage: React.FC<Props> = (props) => {
  const { walletType } = props

  const { walletId } = useParams<{ walletId?: string }>()

  const navigate = useNavigate()

  const { isDefaultCFDProductType } = useProductReadContext()

  const handleSubmit = (data: MasterTransaction, values: WalletDepositFormValues) => {
    if (isTransactionHistory(data)) {
      navigate(`/dashboard/traders-room/transaction-history`)
    }

    if (isPaymentProviderBankType(values.paymentProvider.providerCategory.id)) {
      navigate(`/dashboard/traders-room/wallets/${values.wallet.id}/deposit/transaction/succeed`, {
        state: {
          data,
          providerCategoryId: values.paymentProvider.providerCategory.id,
        },
      })
    }

    if (isTransactionUrlRedirect(data)) {
      navigate(`/dashboard/traders-room/wallets/${walletId}/deposit/redirect`, {
        state: {
          transaction: data,
          values,
        },
      })
    }

    if (isTransactionFormRedirect(data)) {
      navigate(`/dashboard/traders-room/wallets/${walletId}/deposit/redirect`, {
        state: {
          transaction: data,
          values,
        },
      })
    }

    if (isTransactionSuccessful(data)) {
      navigate(`/dashboard/traders-room/wallets/${values.wallet.id}/deposit/transaction/succeed`, {
        state: {
          data,
          providerCategoryId: values.paymentProvider.providerCategory.id,
        },
      })
    }

    if (isTransactionUnSuccessful(data)) {
      navigate(`/dashboard/traders-room/wallets/${values.wallet.id}/deposit/transaction/failed`, {
        state: {
          data,
          providerCategoryId: values.paymentProvider.providerCategory.id,
        },
      })
    }
  }

  const handlePageExitConfirmation = () => {
    navigate(
      isDefaultCFDProductType()
        ? '/dashboard/traders-room/wallets'
        : '/dashboard/traders-room/balances'
    )
  }

  return (
    <WalletDepositPage
      walletType={walletType}
      onSubmit={handleSubmit}
      onCancel={handlePageExitConfirmation}
    />
  )
}

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation, useOutletContext } from 'react-router-dom'
import classNames from 'classnames'

import { TabProps, Tabs } from '../global/tabs/Tabs'
import { useCurrentTab } from '../hooks/useCurrentTab'
import { ScrollToIds } from '../hooks/useScrollToElementIds'
import { LoyaltyDashboardDetailsDto } from '../model/IntroducingBrokerLoyalty'
import { useNavigate } from '../navigation/custom-react-router-dom'
import { PageHeader } from '../ui/Table/Header/PageHeader'
import { useAccountReadContext } from '../utils/AccountContextContext'
import { useIBCampaignsResultsReadContext } from '../utils/IBCampaignsResultsContext'
import { isNoTabsPath } from '../utils/path'
import { useIBLoyalty } from './Dashboard/hooks/useIBLoyalty'
import { useIBRoutesConditions } from './IntroducingBrokerRoutes'
import { IBSignUpPage } from './SignUp/IBSignUpPage'

import styles from './IntroducingBroker.module.scss'

type ContextType = {
  ibLoyaltyData?: LoyaltyDashboardDetailsDto | null
  isLoyaltyDataLoading?: boolean
}

export const IntroducingBroker: React.FC = () => {
  const location = useLocation()
  const currentTab = useCurrentTab('introducing-broker')
  const navigate = useNavigate()
  const visible = useIBRoutesConditions()
  const { account } = useAccountReadContext()
  const { data: ibLoyaltyData, isLoading: isLoyaltyDataLoading } = useIBLoyalty()
  const [showThankYouPage, setShowThankYouPage] = useState(false)

  const { t } = useTranslation()
  const { ibCampaignsResults } = useIBCampaignsResultsReadContext()

  const ibSignupFormSubmitted = localStorage.getItem('ibSignupFormSubmitted') === 'true'

  const TabsDefinition: TabProps[] = [
    { name: t('Tabs.Dashboard'), id: 'dashboard' },
    // { name: t('Tabs.Rebates'), id: 'rebates' },
    { name: t('Tabs.Wallets'), id: 'wallets' },
    { name: t('Tabs.Clients'), id: 'clients' },
    { name: t('Tabs.Income'), id: 'income' },
    {
      name: t('Tabs.Reports'),
      id: 'reports',
    },
    {
      name: t('Tabs.Transaction History'),
      id: 'transaction-history',
    },
    {
      name: t('Tabs.Legal Documents'),
      id: 'legal-documents',
    },
  ]

  if (visible.isMultiTierVisible) {
    TabsDefinition.push({
      name: t('Tabs.Multi-Tier'),
      id: 'multi-tier',
    })
  }

  if (visible.isReferralMaterialsVisible) {
    TabsDefinition.push({
      name: t('Tabs.Referral Materials'),
      id: 'referral-materials',
    })
  }

  if (ibCampaignsResults.length) {
    TabsDefinition.push({
      name: t('Tabs.IB Contest'),
      id: 'ib-contest',
    })
  }

  if (ibLoyaltyData?.loyaltyProgramEnabled) {
    TabsDefinition.push({
      name: t('Tabs.Loyalty Program'),
      id: 'loyalty-program',
    })
  }

  // const getPath = (tabId: string): string => {
  //   if (tabId === 'rebates') {
  //     return 'rebates/groups'
  //   }
  //   return tabId
  // }

  if (!account?.clientIntroducingBroker?.id || showThankYouPage || ibSignupFormSubmitted) {
    return (
      <div className='pt-5'>
        <PageHeader title={t('IB.Introducing Broker Program')} id={ScrollToIds.IBSignupHeader} />
        <IBSignUpPage
          ibSignupFormSubmitted={ibSignupFormSubmitted}
          hideHeader
          // onSetShowThanYouPage={setShowThankYouPage}
        />
      </div>
    )
  }

  return (
    <React.Fragment>
      {!isNoTabsPath(location.pathname) && (
        <div className={classNames('section', styles.adminRoutes)}>
          <Tabs
            tabs={TabsDefinition}
            currentTab={currentTab}
            appearance='light'
            onTabChanged={(tab) => navigate(`/dashboard/introducing-broker/${tab}`)}
          />
        </div>
      )}
      <Outlet context={{ ibLoyaltyData, isLoyaltyDataLoading } satisfies ContextType} />
    </React.Fragment>
  )
}

export const useLoyaltyData = (): ContextType => {
  return useOutletContext<ContextType>()
}

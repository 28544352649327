import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Loading } from '../../../global/Loading/Loading'
import { Paging, PagingEventType } from '../../../global/Paging/Paging'
import { useSnackbar } from '../../../global/context/SnackbarContext'
import { InformationModal } from '../../../global/modal/InformationModal'
import { Modal } from '../../../global/modal/Modal'
import { RestrictionActionModal } from '../../../global/modal/RestrictionActionModal'
import {
  ScrollToIds,
  useScrollAfterLoad,
  useScrollIntoViewOnPagingEntriesChange,
} from '../../../hooks/useScrollToElementIds'
import { AccountGroupType } from '../../../model/AccountGroupType'
import { PlatformTypeEnum } from '../../../model/PlatformTypeEnum'
import { TradingAccount } from '../../../model/TradingAccount'
import { CurrencyType } from '../../../model/WalletDto'
import { Link, useNavigate } from '../../../navigation/custom-react-router-dom'
import { PageHeader } from '../../../ui/Table/Header/PageHeader'
import { Operator, PageQuery, useApiClient } from '../../../utils/ApiClient'
import { ClientApiClient } from '../../../utils/clientApi'
import { useWindowResize } from '../../../utils/domUtils'
import {
  TradingAccountRestrictions,
  isRestricted,
  isTradingAccountSuspended,
} from '../../../utils/trading-account.utils'
import { useCallbackWithForceRefresh } from '../../../utils/useCallbackWithForceRefresh'
import { useFetchAppendablePage } from '../../../utils/useFetch'
import { TradingAccountBalanceCard } from './TradingAccountBalanceCard'
import { TradingAccountOptionsModal } from './TradingAccountOptionsModal'
import { MobileHeader, RightHeader, TitleHeader } from './TradingAccountPageHeaderParts'
import { TradingAccountsTable } from './TradingAccountsTable'

export interface InitiateTransfer {
  tradingAccountId: string
  currency?: CurrencyType
  balance?: number
}

export interface InitiateConversion {
  currency?: CurrencyType
  balance?: number
}

export interface TradingAccountOption {
  tradingAccount: TradingAccount
  currency: CurrencyType
}

export const TradingAccountBalances: React.FC = () => {
  const { t } = useTranslation()
  const { addSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const isMobile = useWindowResize()
  const apiClient = useApiClient(ClientApiClient)

  const [TAOptionsData, setTAOptionsData] = useState<TradingAccountOption>()
  const [isBalanceModalOpen, setBalanceModalOpen] = useState(false)
  const [isAvailableBalanceModalOpen, setAvailableBalanceModalOpen] = useState(false)
  const [isRestrictionModalOpen, setRestrictionModalOpen] = useState(false)

  const { callback } = useCallbackWithForceRefresh(
    async (query?: PageQuery) =>
      apiClient.getTradingAccounts({
        ...query,
        search: {
          AccountGroupType: {
            value: AccountGroupType.Live,
            operator: Operator.EQUAL,
          },
          Platform: {
            value: PlatformTypeEnum.CQGCAST, // it's for ETD
            operator: Operator.EQUAL,
          },
          Status: {
            value: 1,
            operator: Operator.EQUAL,
          },
        },
      }),
    []
  )
  const {
    data: tradingAccounts,
    isLoading,
    setPageQuery,
    meta: metaActiveTAs,
  } = useFetchAppendablePage(callback)

  const tradingAccount = tradingAccounts.length > 0 ? tradingAccounts[0] : null
  const hasAllRestrictions = isTradingAccountSuspended(tradingAccount?.restrictions)

  const isTransferFromRestricted = isRestricted(
    TradingAccountRestrictions.TRANSFER_FROM,
    tradingAccount?.restrictions
  )

  const intiateTransfer = ({ tradingAccountId, currency, balance }: InitiateTransfer) => {
    if (Number(balance) >= 0) {
      navigate(`/dashboard/traders-room/balances/${tradingAccountId}/transfer`, {
        state: {
          currency,
        },
      })
    } else {
      navigate('/dashboard/traders-room/wallets/transfer')
    }
  }

  const intiateConversion = ({ currency, balance }: InitiateConversion) => {
    if (isTransferFromRestricted) {
      addSnackbar.error({ message: t('This action is not available') })
      return
    }
    if (Number(balance) >= 0) {
      navigate(`/dashboard/traders-room/transactions/conversion`, {
        state: {
          tradingAccountId: TAOptionsData?.tradingAccount.id,
          currency,
        },
      })
    } else {
      navigate('/dashboard/traders-room/transactions/conversion')
    }
  }

  const [isPaginationEntrySelected, setIsPaginationEntrySelected] = useState(false)
  useScrollIntoViewOnPagingEntriesChange(
    ScrollToIds.TradingAccountBalancesHeader,
    isPaginationEntrySelected,
    isLoading,
    setIsPaginationEntrySelected
  )
  useScrollAfterLoad(ScrollToIds.TradingAccountBalancesHeader, isLoading, metaActiveTAs?.pageSize)

  return (
    <div>
      {isBalanceModalOpen && (
        <Modal
          closeModal={() => setBalanceModalOpen(false)}
          render={({ closeModal }) => (
            <InformationModal
              title={t('Trading Account.Trading Accounts Balance')}
              onCancel={closeModal}
              onCancelText={t('Got It')}
            >
              <Trans
                i18nKey='Wallet.Your current balance is the total of your {{ value }} balance(s)'
                components={{
                  1: <Link title={t('Currency you selected')} to={`/profile/account-settings`} />,
                }}
                values={{ value: 'trading account' }}
              />
            </InformationModal>
          )}
        />
      )}
      {isRestrictionModalOpen && (
        <Modal
          closeModal={() => setRestrictionModalOpen(false)}
          render={({ closeModal }) => <RestrictionActionModal onConfirm={closeModal} />}
        />
      )}
      {isAvailableBalanceModalOpen && (
        <Modal
          closeModal={() => setAvailableBalanceModalOpen(false)}
          render={({ closeModal }) => (
            <InformationModal
              title={t('Wallet.Withdrawable Balance')}
              onCancel={closeModal}
              onCancelText={t('Got It')}
            >
              <Trans
                i18nKey='Wallet.Your withdrawable balance is the total amount you may withdraw, including your current open-position obligations'
                components={{
                  1: <Link title={t('Currency you selected')} to={`/profile/account-settings`} />,
                }}
              />
            </InformationModal>
          )}
        />
      )}

      <PageHeader
        id={ScrollToIds.TradingAccountBalancesHeader}
        renderSubtitle={() =>
          isMobile ? (
            <MobileHeader
              setBalanceModalOpen={setBalanceModalOpen}
              setAvailableBalanceModal={setAvailableBalanceModalOpen}
              tradingAccounts={tradingAccounts}
            />
          ) : (
            <TitleHeader
              setBalanceModalOpen={setBalanceModalOpen}
              tradingAccounts={tradingAccounts}
            />
          )
        }
        rightRender={
          !isMobile && (
            <RightHeader
              setAvailableBalanceModal={setAvailableBalanceModalOpen}
              tradingAccounts={tradingAccounts}
            />
          )
        }
      />

      {isMobile && (
        <>
          {!!TAOptionsData && (
            <TradingAccountOptionsModal
              TAOptionsData={TAOptionsData}
              onCancel={() => setTAOptionsData(undefined)}
              isTransferFromRestricted={isTransferFromRestricted}
              intiateConversion={intiateConversion}
            />
          )}
          <div className='is-flex is-flex-direction-column'>
            {tradingAccount?.platformOverview.balances.map((balance, id) => (
              <TradingAccountBalanceCard
                key={id}
                hasAllRestrictions={hasAllRestrictions}
                onSetModalVisible={setRestrictionModalOpen}
                initiateTransfer={() =>
                  intiateTransfer({
                    tradingAccountId: tradingAccount.id,
                    currency: balance.currency,
                    balance: balance.balance,
                  })
                }
                handleSetSelectedTradingAccount={() =>
                  setTAOptionsData({
                    tradingAccount,
                    currency: balance.currency,
                  })
                }
                balance={balance}
                isTransferFromRestricted={isTransferFromRestricted}
              />
            ))}
            {metaActiveTAs && (
              <Paging
                scrollToHeaderId={ScrollToIds.TradingAccountBalancesHeader}
                pageData={metaActiveTAs}
                isLoading={isLoading}
                onPageChanged={(pageIndex, pageSize, pagingEventType) => {
                  if (pagingEventType === PagingEventType.ENTRIES_CHANGED) {
                    setIsPaginationEntrySelected(true)
                  }
                  setPageQuery!({
                    pageIndex,
                    pageSize,
                  })
                }}
              />
            )}
          </div>
        </>
      )}

      <Loading showLoadingIcon isLoading={isLoading}>
        {tradingAccount && (
          <TradingAccountsTable
            initiateTransfer={(currency) =>
              intiateTransfer({
                tradingAccountId: tradingAccount.id,
                currency,
                balance: tradingAccount.platformOverview.balances.find(
                  (b) => b.currency === currency
                )?.balance,
              })
            }
            hasAllRestrictions={hasAllRestrictions}
            onSetModalVisible={setRestrictionModalOpen}
            intiateConversion={intiateConversion}
            balances={tradingAccount?.platformOverview.balances}
            isTransferFromRestricted={isTransferFromRestricted}
            TAOptionsData={TAOptionsData}
            setTAOptionsData={setTAOptionsData}
            handleSetSelectedTradingAccount={(currency) =>
              setTAOptionsData({ tradingAccount, currency })
            }
          />
        )}
      </Loading>
    </div>
  )
}

export interface SumsubAccessTokenData {
  userId: string
  token: string
}

export enum SumsubMessage {
  Submitted = 'idCheck.onApplicantSubmitted',
  StepCompleted = 'idCheck.onStepCompleted',
  StatusChanged = 'idCheck.onApplicantStatusChanged',
  Error = 'idCheck.onError',
}

export enum SumsubStatusAnswer {
  Red = 'RED',
  Green = 'GREEN',
}

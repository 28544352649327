import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as XLSX from 'xlsx'

import { Loading } from '../../global/Loading/Loading'
import { Paging, PagingEventType } from '../../global/Paging/Paging'
import { useSessionLanguage } from '../../global/context/SessionSettingsContext'
import { ExportModal } from '../../global/modal/ExportModal'
import { Modal } from '../../global/modal/Modal'
import {
  ScrollToIds,
  useScrollAfterLoad,
  useScrollIntoViewOnPagingEntriesChange,
} from '../../hooks/useScrollToElementIds'
import { ClientRebateCampaignDto, isCampaignClientContest } from '../../model/CampaignResultDto'
import { PageHeader } from '../../ui/Table/Header/PageHeader'
import { useAccountReadContext } from '../../utils/AccountContextContext'
import { PageQuery, useApiClient } from '../../utils/ApiClient'
import { AuthSessionContext } from '../../utils/AuthContext'
import { ClientApiClient, emptyPaginationResponse } from '../../utils/clientApi'
import { formatDate } from '../../utils/date.utils'
import { useWindowResize } from '../../utils/domUtils'
import { getScrollToCardId } from '../../utils/getItemId'
import { generatePDFTable } from '../../utils/prepare.pdf.utils'
import { useCallbackWithForceRefresh } from '../../utils/useCallbackWithForceRefresh'
import { IAppendableFetchResult, useFetchAppendablePage } from '../../utils/useFetch'
import { useScrollToTop } from '../../utils/useScrollToTop'
import { CampaignContestPage } from './CampaignContestPage'
import { CampaignRebatePage } from './CampaignRebatePage'
import { CampaignResultCard } from './CampaignResultCard'
import { CampaignResultsTable } from './CampaignResultsTable'

const useCampaignFetch = () => {
  const locale = useSessionLanguage()
  const apiClient = useApiClient(ClientApiClient)
  const { account } = useAccountReadContext()

  const { callback, forceRefresh } = useCallbackWithForceRefresh(
    async (query?: PageQuery) => {
      if (account?.id) {
        return apiClient.getCampaigns({
          ...query,
          caller: 'ca',
          languageId: locale,
        })
      }
      return Promise.resolve(emptyPaginationResponse)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locale]
  )
  const query = useFetchAppendablePage(callback)

  return { query, forceRefresh }
}

const useSelectCampaign = () => {
  const [selectedCampaign, setSelectedCampaign] = useState<ClientRebateCampaignDto | undefined>()

  const handleSelectCampaign = (campaign: ClientRebateCampaignDto) => {
    setSelectedCampaign(campaign)
  }

  const handleGoBack = () => {
    setSelectedCampaign(undefined)
  }

  return { selectedCampaign, handleSelectCampaign, handleGoBack }
}

interface GenerateDocumentFileProps {
  query: IAppendableFetchResult<ClientRebateCampaignDto[]>
}

const useGenerateDocumentFile = (props: GenerateDocumentFileProps) => {
  const { query } = props

  const { t } = useTranslation()

  const exportFilename = 'campaigns_report'

  const [auth] = useContext(AuthSessionContext)
  const dateFormat = auth?.dateFormatType?.name

  const [isOptionsModalOpen, setOptionsModalOpen] = useState<boolean>(false)

  const handleGenerateExcel = () => {
    const table = tableBody()
    const wb = XLSX.utils.book_new()
    const ws = XLSX.utils.aoa_to_sheet(table)

    XLSX.utils.book_append_sheet(wb, ws, exportFilename)
    XLSX.writeFile(wb, `${exportFilename}.xlsx`)

    setOptionsModalOpen(false)
  }

  const handleGeneratePDF = () => {
    const data = tableBody()
    generatePDFTable({
      data,
      fileName: exportFilename,
    })

    setOptionsModalOpen(false)
  }

  const tableBody = () => {
    const headerCsvData = [
      [
        t('Campaigns.Campaign name'),
        t('Campaigns.Campaign Account No'),
        t('Campaigns.Start Date'),
        t('Campaigns.End Date'),
        t('Campaigns.Currency'),
        t('Campaigns.Status'),
      ],
    ]
    return query.data.reduce((previousValue, currentValue) => {
      return previousValue.concat([
        [
          currentValue.name || '',
          `${currentValue.currency.id} ${currentValue.account}`,
          formatDate(currentValue.from, { formatType: dateFormat }),
          formatDate(currentValue.to, { formatType: dateFormat }),
          currentValue.currency.id,
          currentValue.status.name,
        ],
      ])
    }, headerCsvData)
  }

  return {
    handleGenerateExcel,
    handleGeneratePDF,
    tableBody,
    isOptionsModalOpen,
    setOptionsModalOpen,
    exportFilename,
  }
}

export const CampaignResultsPage: React.FC = () => {
  useScrollToTop()

  const isMobile = useWindowResize()
  const campaign = useCampaignFetch()
  const { selectedCampaign, handleSelectCampaign, handleGoBack } = useSelectCampaign()

  const [isPaginationEntrySelected, setIsPaginationEntrySelected] = useState(false)
  useScrollIntoViewOnPagingEntriesChange(
    ScrollToIds.CampaignResultsHeader,
    isPaginationEntrySelected,
    campaign.query.isLoading,
    setIsPaginationEntrySelected
  )
  useScrollAfterLoad(
    ScrollToIds.CampaignResultsHeader,
    campaign.query.isLoading,
    campaign.query.meta?.pageSize
  )

  const handlePageChange = (
    pageIndex: number,
    pageSize: number,
    pagingEventType: PagingEventType
  ) => {
    if (pagingEventType === PagingEventType.ENTRIES_CHANGED) {
      setIsPaginationEntrySelected(true)
    }
    campaign.query.setPageQuery!({
      ...campaign.query.pageQuery,
      pageIndex,
      pageSize,
    })
  }

  if (selectedCampaign) {
    if (isCampaignClientContest(selectedCampaign)) {
      return <CampaignContestPage campaign={selectedCampaign!} onGoBack={handleGoBack} />
    } else {
      return <CampaignRebatePage campaign={selectedCampaign!} onGoBack={handleGoBack} />
    }
  }

  return (
    <>
      <Header query={campaign.query} />
      <Loading showLoadingIcon isLoading={campaign.query.isLoading}>
        {!isMobile && (
          <CampaignResultsTable
            campaignResults={campaign.query.data}
            setSelectedCampaign={handleSelectCampaign}
          />
        )}
        {isMobile &&
          campaign.query.data.map((x, id) => (
            <CampaignResultCard
              key={id}
              cardId={getScrollToCardId(id, campaign.query.data.length, 'campaign-results')}
              campaignResult={x}
              setSelectedCampaign={handleSelectCampaign}
            />
          ))}
      </Loading>
      {campaign.query.meta && (
        <Paging
          scrollToHeaderId={ScrollToIds.CampaignResultsHeader}
          pageData={campaign.query.meta}
          isLoading={campaign.query.isLoading}
          onPageChanged={handlePageChange}
        />
      )}
    </>
  )
}

interface HeaderProps {
  query: IAppendableFetchResult<ClientRebateCampaignDto[]>
}

const Header: React.FC<HeaderProps> = (props) => {
  const { query } = props

  const { t } = useTranslation()
  const generateDocumentFile = useGenerateDocumentFile({ query })

  if (query.hasInitialResults) {
    return (
      <>
        {generateDocumentFile.isOptionsModalOpen && (
          <Modal
            render={({ closeModal }) => (
              <ExportModal
                fileName={generateDocumentFile.exportFilename}
                onCloseModal={closeModal}
                csvData={generateDocumentFile.tableBody()}
                onExportToCSV={closeModal}
                onExportToPdf={generateDocumentFile.handleGeneratePDF}
                onExportToExcel={generateDocumentFile.handleGenerateExcel}
              />
            )}
            closeModal={() => generateDocumentFile.setOptionsModalOpen(false)}
          />
        )}
        <PageHeader
          id={ScrollToIds.CampaignResultsHeader}
          title={t('Campaigns.Campaigns')}
          optionsToggle={() => generateDocumentFile.setOptionsModalOpen(true)}
        />
      </>
    )
  }

  return <PageHeader title={t('Campaigns.Campaigns')} />
}

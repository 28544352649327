import React from 'react'
import { Outlet } from 'react-router-dom'
import classNames from 'classnames'

import { DocumentManagementNotification } from '../Profile/DocumentManagement/DocumentManagementPage'
import {
  YearlyKycUpdateDetailsNotification,
  isKycUpdateDetailsNotificationNeeded,
} from '../Profile/YearlyKycUpdateDetails/YearlyKycUpdateDetailsPage'
import { ScrollToIds } from '../hooks/useScrollToElementIds'
import { isDocumentManagementNotificationNeeded } from '../utils/AccountAccess/accountDocumentStatuses'
import { useAccountReadContext } from '../utils/AccountContextContext'
import { DashboardTabsCFD } from './DashboardTabsCFD'
import { DashboardTabsETD } from './DashboardTabsETD'

import styles from './Dashboard.module.scss'

export const Dashboard: React.FC = () => {
  const { account } = useAccountReadContext()

  return (
    <>
      {isKycUpdateDetailsNotificationNeeded(account) && <YearlyKycUpdateDetailsNotification />}
      {isDocumentManagementNotificationNeeded(account) && <DocumentManagementNotification />}
      <div className={classNames('section', styles.adminRoutes)} id={ScrollToIds.DashboardTabs}>
        <DashboardTabsCFD />
        <DashboardTabsETD />
      </div>
      <Outlet />
    </>
  )
}

import { useCallback, useState } from 'react'

import { PaymentProvider } from '../../../../../model/PaymentProviderDto'
import { WalletDepositBonusCampaignDto } from '../../../../../model/WalletDto'
import { useApiClient } from '../../../../../utils/ApiClient'
import { ClientApiClient } from '../../../../../utils/clientApi'
import { useDebounceEffect } from '../../../../../utils/debounce'
import { Wallet } from '../WalletDepositForm'

export interface GetAvailableBonusCampaignsValue {
  availableBonusCampaigns: WalletDepositBonusCampaignDto['availableBonusCampaigns']
}

export type GetAvailableBonusCampaigns = (
  wallet: Wallet,
  paymentProvider: PaymentProvider,
  depositAmount: number
) => Promise<GetAvailableBonusCampaignsValue>

interface UseGetAvailableBonusCampaignsValue {
  getAvailableBonusCampaigns: GetAvailableBonusCampaigns
  availableBonusCampaigns: WalletDepositBonusCampaignDto['availableBonusCampaigns'] | null
  isBonusLoading: boolean
}

interface UseGetAvailableBonusCampaignsProps {
  wallet?: Wallet
  paymentProvider?: PaymentProvider
  depositAmount?: number
}

export const useGetAvailableBonusCampaigns = (
  props: UseGetAvailableBonusCampaignsProps = {}
): UseGetAvailableBonusCampaignsValue => {
  const { wallet, paymentProvider, depositAmount } = props

  const apiClient = useApiClient(ClientApiClient)
  const [isBonusLoading, setIsBonusLoading] = useState(false)
  const [availableBonusCampaigns, setAvailableBonusCampaigns] = useState<
    WalletDepositBonusCampaignDto['availableBonusCampaigns'] | null
  >(null)

  const getAvailableBonusCampaigns: GetAvailableBonusCampaigns = useCallback(
    async (
      wallet: Wallet,
      paymentProvider: PaymentProvider,
      depositAmount: number
    ): Promise<GetAvailableBonusCampaignsValue> => {
      const response = await apiClient.getWalletDepositBonusCampaigns(
        wallet.id!,
        paymentProvider.id!,
        depositAmount
      )
      return {
        availableBonusCampaigns: response.availableBonusCampaigns ?? null,
      }
    },
    [apiClient]
  )

  useDebounceEffect(
    () => {
      if (!wallet?.id || !paymentProvider?.id || depositAmount == null) {
        return
      }

      const fetchAvailableBonusCampaigns = async () => {
        try {
          setIsBonusLoading(true)
          const { availableBonusCampaigns } = await getAvailableBonusCampaigns(
            wallet,
            paymentProvider,
            depositAmount
          )
          setAvailableBonusCampaigns(availableBonusCampaigns)
        } catch (error: unknown) {
          console.error('Failed to fetch available bonus campaigns:', error)
          setAvailableBonusCampaigns(null)
        } finally {
          setIsBonusLoading(false)
        }
      }

      fetchAvailableBonusCampaigns()
    },
    [wallet, paymentProvider, depositAmount, getAvailableBonusCampaigns],
    500
  )

  return { getAvailableBonusCampaigns, availableBonusCampaigns, isBonusLoading }
}

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { matchPath, useLocation } from 'react-router-dom'
import classNames from 'classnames'
import { UAParser } from 'ua-parser-js'

import { CloseIcon } from '../../icons/CloseIcon'
import { TickmillAppIcon } from '../../icons/TickmillAppIcon'
import { TickmillAppRatingIcon } from '../../icons/TickmillAppRatingIcon'
import { isAccountActivatedStatus } from '../../model/AccountDetailedStatus'
import { OperatingSystemNameEnum } from '../../model/PlatformTypeEnum'
import { Text, TextSmallStrong } from '../../ui/Typography/Typography'
import { useAccountReadContext } from '../../utils/AccountContextContext'
import { useWindowResize } from '../../utils/domUtils'
import { useLocallyPersistedState } from '../../utils/useStorage'
import { useProductReadContext } from '../context/ProductContext'
import { useArabicSessionLanguage, useSessionLanguage } from '../context/SessionSettingsContext'
import IconButton from '../iconButton/IconButton'

import styles from './TickmillAppBanner.module.scss'

const excludedPaths = [
  ':locale/sign-up/*',
  ':locale/users',
  ':locale/dashboard/traders-room/wallets/:id/deposit',
  ':locale/dashboard/traders-room/wallets/deposit',
  ':locale/dashboard/traders-room/wallets/:id/transfer',
  ':locale/dashboard/traders-room/wallets/transfer',
  ':locale/dashboard/introducing-broker/wallets/:id/transfer',
  ':locale/dashboard/payment-agent/wallets/:id/transfer',
  ':locale/dashboard/traders-room/trading-accounts/:id/transfer',
  ':locale/dashboard/traders-room/wallets/:id/withdrawal',
  ':locale/dashboard/traders-room/wallets/withdrawal',
  ':locale/dashboard/introducing-broker/wallets/:id/withdrawal',
  ':locale/dashboard/introducing-broker/wallets/withdrawal',
  ':locale/dashboard/traders-room/trading-accounts/:id/demo/add-balance',
  ':locale/dashboard/traders-room/trading-accounts/new',
  ':locale/dashboard/traders-room/trading-accounts/new/demo',
  ':locale/dashboard/traders-room/trading-accounts/:id/change-password',
  ':locale/dashboard/traders-room/transactions/conversion',
  ':locale/dashboard/first-deposit',
  ':locale/dashboard/product-registration/appropriateness-test',
  ':locale/dashboard/product-registration/kyc-personal-details',
  ':locale/dashboard/product-registration/legal-documents',
  ':locale/dashboard/take-app-test',
  ':locale/dashboard/update-kyc',
  ':locale/profile/kyc-update-details',
  ':locale/profile/appropriateness-test',
  ':locale/profile/first-time-deposit',
  ':locale/first-time-deposit',
]

export const TickmillAppBannerDownloadedKey = 'tickmillAppBannerDownloaded'
export const TickmillAppBannerClosedKey = 'tickmillAppBannerClosed'

export const TickmillAppBanner: React.FC = () => {
  const { account } = useAccountReadContext()
  const locale = useSessionLanguage()
  const location = useLocation()
  const isMobile = useWindowResize()
  const { isDefaultCFDProductType } = useProductReadContext()
  const isProductTypeCFD = isDefaultCFDProductType()

  const parser = new UAParser()
  const { os } = parser.getResult()

  const [hasDownloadedApp, setHasDownloadedApp] = useLocallyPersistedState<boolean>(
    TickmillAppBannerDownloadedKey,
    false
  )

  const [hasClosedBanner, setHasClosedBanner] = useLocallyPersistedState<boolean>(
    TickmillAppBannerClosedKey,
    false
  )

  const [isBannerClosedOnLogin, setIsBannerClosedOnLogin] = useState<boolean>(false)

  const handleClose = () => {
    if (location.pathname === '/login') {
      setIsBannerClosedOnLogin(true)
    }
    setHasClosedBanner(true)
  }

  const handleDownload = () => {
    if (location.pathname === '/login') {
      setIsBannerClosedOnLogin(true)
    }
    setHasDownloadedApp(true)

    const language = locale || 'en'
    let appLink = ''

    if (os.name === OperatingSystemNameEnum.iOS) {
      appLink = `https://apps.apple.com/app/id1548644102?l=${language}`
    } else if (os.name === OperatingSystemNameEnum.Android) {
      appLink = `https://play.google.com/store/apps/details?id=com.tickmill&hl=${language}`
    }

    if (appLink) {
      window.location.href = appLink
    }
  }

  useEffect(() => {
    if (location.pathname === '/login') {
      setIsBannerClosedOnLogin(false)
    }
  }, [location.pathname])

  const shouldShowBanner = () => {
    const isLoginPage = location.pathname === '/login'

    const isExcludedPath = excludedPaths.some((excludedPath) =>
      matchPath({ path: excludedPath, end: false }, location.pathname)
    )

    const hasChinaResidency = account?.addresses.some((x) => x.country.id === 'CHN')
    const isMobileDevice =
      os.name === OperatingSystemNameEnum.Android || os.name === OperatingSystemNameEnum.iOS

    if (hasChinaResidency || !isMobileDevice || isExcludedPath) {
      return false
    }

    if (isLoginPage) {
      return !isBannerClosedOnLogin
    }

    if (hasDownloadedApp || hasClosedBanner) {
      return false
    }

    if (account && !isAccountActivatedStatus(account)) {
      return false
    }

    if (isProductTypeCFD) {
      return true
    }

    return false
  }

  if (!shouldShowBanner() || !isMobile) {
    return null
  }

  return <Banner onClose={handleClose} onDownload={handleDownload} />
}

interface TickmillAppProps {
  onClose: () => void
  onDownload: () => void
}

const Banner: React.FC<TickmillAppProps> = ({ onClose, onDownload }) => {
  const { t } = useTranslation()
  const isArabic = useArabicSessionLanguage()
  return (
    <div className={styles.wrapper}>
      <div className={classNames('container', styles.innerWrapper)} dir={isArabic ? 'rtl' : ''}>
        <div className={styles.leftContent}>
          <IconButton onClick={onClose} className={styles.closeButton} dir={isArabic ? 'rtl' : ''}>
            <CloseIcon size={10} />
          </IconButton>
          <div className={styles.tickmillAppIcon}>
            <TickmillAppIcon />
          </div>
          <div className={styles.appInfo} dir={isArabic ? 'rtl' : ''}>
            <TextSmallStrong>{t('Tickmill App')}</TextSmallStrong>
            <div className={styles.iconContainer} dir={isArabic ? 'rtl' : ''}>
              <TickmillAppRatingIcon />
            </div>
          </div>
        </div>
        <div className={styles.rightContent}>
          <Text className={styles.link} onClick={onDownload}>
            {t('Download')}
          </Text>
        </div>
      </div>
    </div>
  )
}

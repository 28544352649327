import { useTranslation } from 'react-i18next'

import { ScrollToIds } from '../../hooks/useScrollToElementIds'
import { useNavigate } from '../../navigation/custom-react-router-dom'
import { PageHeader } from '../../ui/Table/Header/PageHeader'

import styles from './ReferralParts.module.scss'

interface HeaderProps {
  name: string
}

export const ReferralHeader: React.FC<HeaderProps> = ({ name }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <PageHeader
      id={ScrollToIds.ReferralMaterialsHeader}
      backButton={() => navigate('/dashboard/introducing-broker/referral-materials')}
      darkTitle={t('Referral Materials.Referral Materials') + ' / '}
      title={name}
      className={styles.header}
    />
  )
}

import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../../global/button/Button'
import { MarketDataClassificationStatus } from '../../model/AccountDetailedDto'
import { PageHeader } from '../../ui/Table/Header/PageHeader'
import { Text, TextH3, TextLarge, TextLargeStrong } from '../../ui/Typography/Typography'
import { useAccountReadContext } from '../../utils/AccountContextContext'
import { dispatchOpenChat } from '../../utils/cookie.utils'

import styles from './MarketDataClassification.module.scss'

export const MarketDataClassificationPage: React.FC = () => {
  const { t } = useTranslation()
  const { account } = useAccountReadContext()
  const marketDataClassification =
    account?.marketDataClassification?.id === MarketDataClassificationStatus.Professional
      ? t('Profile.Market-Data Professional')
      : t('Profile.Market-Data Private')

  return (
    <Fragment>
      <PageHeader title={t('Profile.Market Data Classification')} />
      <div className={styles.marketDataClassificattion}>
        <TextH3>{t('Account Type')}</TextH3>
        <TextLarge className={styles.status}>
          {t('Profile.Current status')}{' '}
          <TextLargeStrong className={styles.statusValue}>
            {marketDataClassification}
          </TextLargeStrong>
        </TextLarge>
        <Text>
          {t('Profile.If you have any questions regarding your Market Data Classification')}{' '}
          <Button onClick={dispatchOpenChat} appearance='link'>
            {t('Contact Support')}
          </Button>
          .
        </Text>
      </div>
    </Fragment>
  )
}

import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Loading } from '../../../global/Loading/Loading'
import { Button } from '../../../global/button/Button'
import { CopyLink } from '../../../global/copyLink/CopyLink'
import { Text, TextSmall } from '../../../ui/Typography/Typography'
import { useGAEvents } from '../../../utils/callGAEvents'
import { SectionHeader } from '../components/SectionHeader/SectionHeader'

import styles from './ReferralLink.module.scss'

interface IBReferralLinkProps {
  ibCode?: string
  mtCode?: string
  ibLink?: string
  isLoading: boolean
}

interface CopyDataList {
  name: string
  value: string
  onClick?: () => void
}

export const ReferralLink: FC<IBReferralLinkProps> = (props) => {
  const { ibCode, mtCode, ibLink, isLoading } = props
  const { callGAEvents } = useGAEvents()

  const { t } = useTranslation()

  const list = useMemo<CopyDataList[]>(() => {
    const list: CopyDataList[] = []

    if (ibLink?.length) {
      list.push({
        name: t('IB.Dashboard.Referral Link for registration'),
        value: ibLink,
        onClick: () => callGAEvents().IBDashboard.ReferralLink.copyLink(),
      })
    }

    if (ibCode?.length) {
      list.push({
        name: t('IB.Dashboard.IB code'),
        value: ibCode,
        onClick: () => callGAEvents().IBDashboard.ReferralLink.copyIBCode(),
      })
    }

    if (mtCode?.length) {
      list.push({
        name: t('IB.Dashboard.Multi-tier IB code'),
        value: mtCode,
        onClick: () => callGAEvents().IBDashboard.ReferralLink.copyMTIBCode(),
      })
    }

    return list
  }, [ibLink, ibCode, mtCode, t, callGAEvents])

  return (
    <div className={styles.iBReferralLink}>
      <SectionHeader
        title={t('IB.Dashboard.Referral Link')}
        seeMoreLinkProps={{
          onClick: () => callGAEvents().IBDashboard.ReferralLink.seeMore(),
          to: '/dashboard/introducing-broker/referral-materials',
        }}
      />

      <Loading isLoading={isLoading} showLoadingIcon>
        <ul className={styles.iBReferralLinkList}>
          {list.map((item) => {
            return (
              <li key={item.value} className={styles.iBReferralLinkItem}>
                <TextSmall>{item.name}</TextSmall>
                <div className={styles.iBReferralLinkCopyField}>
                  <Text className={styles.iBReferralLinkCopyValue}>{item.value}</Text>
                  <CopyLink label={`${t('Copied')}!`} value={item.value}>
                    <Button size='S' appearance='primary' onClick={item.onClick}>
                      {t('Copy')}
                    </Button>
                  </CopyLink>
                </div>
              </li>
            )
          })}
        </ul>
      </Loading>
    </div>
  )
}

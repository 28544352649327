import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { Badge } from '../../global/badge/Badge'
import { Button } from '../../global/button/Button'
import { SubscriptionRequest } from '../../model/TradingPlatformsDto'
import { useWindowResize } from '../../utils/domUtils'
import { isSubscriptionTimeInForceImmediate } from './subscriptionUtils'

export const CancelOrProcessingButton = memo(
  ({
    subscriptionRequest,
    scrollToCart,
    onCancel,
  }: {
    onCancel(): void
    subscriptionRequest?: SubscriptionRequest
    scrollToCart?: () => void
  }) => {
    const isMobile = useWindowResize()
    const { t } = useTranslation()
    const isSubscriptionRequestImmediate = isSubscriptionTimeInForceImmediate(subscriptionRequest)

    return isSubscriptionRequestImmediate ? (
      <Badge appearance='warning' text={t('Subscriptions.Processing')} />
    ) : (
      <div className='is-flex is-justify-content-center'>
        <Button appearance='secondary' onClick={onCancel} size={isMobile ? 'S' : 'XS'}>
          {t('Cancel')}
        </Button>
        <Button
          appearance='primary'
          className='ml-2'
          onClick={() => scrollToCart?.()}
          size={isMobile ? 'S' : 'XS'}
        >
          {t('Subscriptions.Go to cart')}
        </Button>
      </div>
    )
  }
)

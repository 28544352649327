import { RefObject, useEffect, useRef } from 'react'

export function isContainedWithinNode(element: Element, node: Node): boolean {
  if (element.isSameNode(node)) {
    return true
  }
  if (!element.parentElement) {
    return false
  }

  return isContainedWithinNode(element.parentElement, node)
}

export function useOnClickedOutside<E extends Node>(
  onClicked: (() => void) | false,
  excludedElements?: Node[]
): RefObject<E> {
  const ref = useRef<E>(null)
  const openingRef = useRef(false)

  useEffect(() => {
    if (onClicked && ref.current) {
      const onClickedOutside = (event: MouseEvent) => {
        if (openingRef.current) {
          openingRef.current = false
          return
        }

        const eventTarget = event.target as Element

        const isExcluded = excludedElements?.some(
          (excludedElement) =>
            excludedElement && isContainedWithinNode(eventTarget, excludedElement)
        )

        if (ref.current && !isContainedWithinNode(eventTarget, ref.current) && !isExcluded) {
          onClicked()
        }
      }

      document.documentElement.addEventListener('click', onClickedOutside, true)

      return () => {
        document.documentElement.removeEventListener('click', onClickedOutside, true)
      }
    }
  }, [onClicked, excludedElements])

  useEffect(() => {
    openingRef.current = true
  }, [])

  return ref
}

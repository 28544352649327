import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Badge } from '../../global/badge/Badge'
import { Button } from '../../global/button/Button'
import { InfoIcon } from '../../icons/InfoIcon'
import { TwoCircularArrowsIcon } from '../../icons/TwoCircularArrowsIcon'
import {
  ClientEmailDto,
  ClientPhoneNumberDto,
  getVerifiedPhoneNumbers,
} from '../../model/AccountDetailedDto'
import { NameDto } from '../../model/NameDto'
import { TwoFAProviderEnum } from '../../model/TwoFactorAuthentication'
import { CheckboxSwitch } from '../../ui/CheckboxSwitch/CheckboxSwitch'
import { Tooltip } from '../../ui/Popups/Tooltip/Tooltip'
import { Text, TextH3, TextSmallStrong, TextStrong } from '../../ui/Typography/Typography'
import { useWindowResize } from '../../utils/domUtils'
import { TwoFactorAuthenticationProps } from './TwoFactorAuthentication'

import styles from './AccountSettingsPage.module.scss'

interface TwoFactorAuthMethodsListProps extends TwoFactorAuthenticationProps {
  title: string
  availableActionTypes?: any
  hasRecoveryCodes?: boolean
  isAdvancedSettingsList?: boolean
}

interface TwoFactorAuthMethodsProviderTitle {
  provider: NameDto
  checkBoxSwitchValue: boolean
  onPhoneNumberChange: (phone: string, provider: NameDto) => void
  isPhoneDisabled: boolean
  email?: ClientEmailDto
  phone?: string
  isAdvancedSettingsList?: boolean
  verifiedPhones?: ClientPhoneNumberDto[]
}

interface TwoFactorCheckboxSwitchProps {
  areActionProvidersDisabled: boolean
  isAdvancedSettingsList?: boolean
  isPhoneDisabled: boolean
  setInfoModalData: (infoModalData: { text: string; isVisible: boolean }) => void
  checkBoxSwitchProps: {
    disabled: boolean
    name: string
    value: boolean
    onChange: (value: boolean) => void
  }
}

export const TwoFactorAuthProviderTitle: React.FC<TwoFactorAuthMethodsProviderTitle> = ({
  provider,
  checkBoxSwitchValue,
  onPhoneNumberChange,
  isPhoneDisabled,
  email,
  phone,
  isAdvancedSettingsList,
  verifiedPhones,
}) => {
  const { t } = useTranslation()
  return (
    <Text
      isParagraph
      className={classNames(styles.twoFALabel, styles.textContrastMediumLow, {
        [styles.hasBadge]:
          !isAdvancedSettingsList && provider.id === TwoFAProviderEnum.GoogleAuthenticator,
        [styles.has2FAEmailSet]:
          !isAdvancedSettingsList && provider.id === TwoFAProviderEnum.Email && checkBoxSwitchValue,
      })}
    >
      <span>{t(`TwoFactorAuth.${provider.name}`, { defaultValue: provider.name })}</span>
      {!isAdvancedSettingsList && provider.id === TwoFAProviderEnum.GoogleAuthenticator && (
        <Badge text={t('Recommended')} appearance='surfaceSuccess' />
      )}
      {!isAdvancedSettingsList && provider.id === TwoFAProviderEnum.SMS && (
        <span className={styles.tooltipContainer}>
          <Tooltip
            autoHide={2000}
            content={t(
              'Profile.SMS can be enabled together with Email to increase the deliverability of the verification code'
            )}
            direction={'topRight'}
            className={styles.tooltipWrapper}
          >
            <InfoIcon size={12} />
          </Tooltip>
        </span>
      )}
      {!isAdvancedSettingsList &&
        provider.id === TwoFAProviderEnum.Email &&
        checkBoxSwitchValue && (
          <TextStrong className={styles.textContrastMediumLow}>{email?.address}</TextStrong>
        )}
      {!isAdvancedSettingsList && provider.id === TwoFAProviderEnum.SMS && !isPhoneDisabled && (
        <span className='is-flex is-align-items-center'>
          {checkBoxSwitchValue && (
            <TextStrong className={styles.textContrastMediumLow}>{phone}</TextStrong>
          )}
          {checkBoxSwitchValue && verifiedPhones && verifiedPhones?.length > 1 && (
            <button
              onClick={() => phone && onPhoneNumberChange(phone, provider)}
              className={classNames('is-link', 'ml-1')}
            >
              <Text>{t('Profile.Change')}</Text>
            </button>
          )}
        </span>
      )}
    </Text>
  )
}

const TwoFactorCheckboxSwitch = ({
  areActionProvidersDisabled,
  isAdvancedSettingsList,
  isPhoneDisabled,
  setInfoModalData,
  checkBoxSwitchProps,
}: TwoFactorCheckboxSwitchProps) => {
  const isMobile = useWindowResize()
  const { t } = useTranslation()

  if (isMobile) {
    return (
      <span
        onClick={() => {
          if (areActionProvidersDisabled && isAdvancedSettingsList) {
            setInfoModalData({
              text: t('Profile.Please choose the method first'),
              isVisible: true,
            })
          } else if (isPhoneDisabled) {
            setInfoModalData({
              text: t(`Profile.Verify your phone number at 'My Phone Numbers'`),
              isVisible: true,
            })
          }
        }}
      >
        <CheckboxSwitch {...checkBoxSwitchProps} />
      </span>
    )
  }

  const tooltipContent =
    areActionProvidersDisabled && isAdvancedSettingsList
      ? t('Profile.Please choose the method first')
      : isPhoneDisabled
      ? t(`Profile.Verify your phone number at 'My Phone Numbers'`)
      : null

  return (
    <span>
      <Tooltip
        autoHide={2000}
        direction='topLeft'
        disabled={!tooltipContent}
        content={tooltipContent || ''}
      >
        <CheckboxSwitch {...checkBoxSwitchProps} />
      </Tooltip>
    </span>
  )
}

export const TwoFactorAuthMethodsList: React.FC<TwoFactorAuthMethodsListProps> = ({
  account,
  providers,
  activeProviders,
  twoFAProvidersActionTypes,
  title,
  onPhoneNumberChange,
  onProviderToggle,
  onAuthActionsProviderToggle,
  onGenerateRecoveryCodes,
  hasRecoveryCodes,
  isAdvancedSettingsList,
  setInfoModalData,
}) => {
  const { t } = useTranslation()

  const email = account?.emails.find((x) => x.isPrimary)
  const phone = activeProviders.find(
    (activeProvider) => activeProvider.type.id === TwoFAProviderEnum.SMS
  )?.authMedium
  const verifiedPhones = getVerifiedPhoneNumbers(account)

  return (
    <>
      <TextH3>{title}</TextH3>
      <div className={styles.twoFAMethodContainer}>
        {[...(isAdvancedSettingsList ? twoFAProvidersActionTypes : providers)].map(
          (provider: NameDto) => {
            const areActionProvidersDisabled = activeProviders.length === 0
            const checkBoxSwitchValue = isAdvancedSettingsList
              ? activeProviders.length > 0 &&
                activeProviders[0].authActions.some((authAction) => authAction.id === provider.id)
              : activeProviders.some((activeProvider) => activeProvider.type.id === provider.id)
            const isPhoneDisabled =
              !verifiedPhones?.length &&
              provider.id === TwoFAProviderEnum.SMS &&
              provider.name === 'SMS'

            const checkBoxSwitchProps = {
              disabled: (areActionProvidersDisabled && isAdvancedSettingsList) || isPhoneDisabled,
              name: provider.name,
              value: checkBoxSwitchValue,
              onChange: (value: boolean) =>
                isAdvancedSettingsList
                  ? onAuthActionsProviderToggle(provider, value)
                  : onProviderToggle(provider, value),
            }

            return (
              <div key={provider.name} className={styles.twoFAMethodWrapper}>
                <TwoFactorAuthProviderTitle
                  provider={provider}
                  checkBoxSwitchValue={checkBoxSwitchValue}
                  onPhoneNumberChange={onPhoneNumberChange}
                  isPhoneDisabled={isPhoneDisabled}
                  email={email}
                  phone={phone}
                  isAdvancedSettingsList={isAdvancedSettingsList}
                  verifiedPhones={verifiedPhones}
                />
                <TwoFactorCheckboxSwitch
                  areActionProvidersDisabled={areActionProvidersDisabled}
                  isAdvancedSettingsList={isAdvancedSettingsList}
                  isPhoneDisabled={isPhoneDisabled}
                  setInfoModalData={setInfoModalData}
                  checkBoxSwitchProps={checkBoxSwitchProps}
                />
              </div>
            )
          }
        )}
      </div>
      {activeProviders.length > 0 && hasRecoveryCodes && (
        <div className='is-flex is-justify-content-center'>
          <Button
            appearance='secondary'
            size='M'
            renderLeftIcon={() => <TwoCircularArrowsIcon themable />}
            onClick={() => onGenerateRecoveryCodes()}
            className={styles.viewRecoveryCodes}
          >
            <TextSmallStrong>{t('Profile.View Recovery Codes')}</TextSmallStrong>
          </Button>
        </div>
      )}
    </>
  )
}

import React from 'react'
import { useTranslation } from 'react-i18next'

import { Loading } from '../../global/Loading/Loading'
import { ScrollToIds } from '../../hooks/useScrollToElementIds'
import { FileIcon } from '../../icons/FileIcon'
import { NavigationArrowRightDiagonalIcon } from '../../icons/NavigationArrowRightDiagonalIcon'
import { TextStrong } from '../../ui/Typography/Typography'
import { useIBReferralCodes } from '../../utils/useIBReferralCodes'
import { useScrollToTop } from '../../utils/useScrollToTop'
import { SectionHeader } from '../Dashboard/components/SectionHeader/SectionHeader'
import { useIntroducingBrokerLegalDocuments } from '../hooks/useIntroducingBrokerLegalDocuments'

import styles from './LegalDocuments.module.scss'

export const LegalDocumentsPage: React.FC = () => {
  useScrollToTop()
  const { legalDocumentsSchemes, isLoading: referralCodesLoading } = useIBReferralCodes()
  const { t } = useTranslation()

  const { data: legalDocuments, isLoading } = useIntroducingBrokerLegalDocuments({
    legalDocumentsSchemes,
  })

  return (
    <div className={styles.wrapper}>
      <SectionHeader
        title={t('Legal Documents')}
        icon={<FileIcon />}
        id={ScrollToIds.IBLegalDocumentsHeader}
      />
      <Loading showLoadingIcon isLoading={isLoading || referralCodesLoading}>
        <div className={styles.cards}>
          {legalDocuments?.map(({ code, title, url }) => (
            <a target='_blank' href={url} rel='noreferrer' className={styles.card} key={code}>
              <div className={styles.cardTitle}>
                <TextStrong isParagraph>{title}</TextStrong>
              </div>
              <div className={styles.cardIconWrapper}>
                <NavigationArrowRightDiagonalIcon />
              </div>
            </a>
          ))}
        </div>
      </Loading>
    </div>
  )
}

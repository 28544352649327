import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useAccountReadContext } from '../utils/AccountContextContext'
import { TwoFactorAuthEvents } from './useTwoFactorAuth'

interface GoBackProps {
  text: string
  path: string
  state?: { [key: string]: string | boolean | undefined }
}

export const useTwoFactorAuthGoBackProps = (
  twoFactorAuthEvent: TwoFactorAuthEvents | string,
  walletId?: string
): GoBackProps => {
  const { t } = useTranslation()
  const { account } = useAccountReadContext()

  return useMemo(() => {
    const goBackPropsFallback = account
      ? {
          text: t('Profile.Back to Security Settings'),
          path: '/profile/security-settings',
          state: {},
        }
      : { text: t('Sign up.Back to Log In'), path: '/login', state: {} }

    if (
      twoFactorAuthEvent === TwoFactorAuthEvents.ResetPassword ||
      twoFactorAuthEvent === TwoFactorAuthEvents.LogIn
    ) {
      return {
        text: t('Profile.Back to Log In'),
        path: '/login',
      }
    } else if (
      twoFactorAuthEvent === TwoFactorAuthEvents.ChangePassword ||
      twoFactorAuthEvent === TwoFactorAuthEvents.EnableProvider ||
      twoFactorAuthEvent === TwoFactorAuthEvents.DisableProvider ||
      twoFactorAuthEvent === TwoFactorAuthEvents.DisableAuthAction
    ) {
      return {
        text: t('Profile.Back to Security Settings'),
        path: '/profile/security-settings',
        state:
          twoFactorAuthEvent === TwoFactorAuthEvents.ChangePassword
            ? { isPasswordTabSelected: true }
            : {},
      }
    } else if (twoFactorAuthEvent === TwoFactorAuthEvents.Withdrawal) {
      return {
        text: t('Profile.Back to Withdraw'),
        path: walletId
          ? `/dashboard/traders-room/wallets/${walletId}/withdrawal`
          : '/dashboard/traders-room/wallets/',
      }
    } else {
      return goBackPropsFallback
    }
  }, [twoFactorAuthEvent, account, walletId, t])
}

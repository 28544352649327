import { useMemo } from 'react'

import { PlatformTypeShort } from '../../model/PlatformTypeEnum'
import { RouteConfig } from '../../routing/RenderRoutes'
import { Platforms } from './Platforms'
import { PlatformsDownloadMT4Page, PlatformsDownloadMT5Page } from './PlatformsDownloadPage'
import { PlatformsLearnMorePage, TMTVideos } from './PlatformsLearnMorePage'
import { PlatformsTradingViewPage } from './PlatformsTradingViewPage'

export const usePlatformRoutes = (): RouteConfig[] => {
  return useMemo(
    (): RouteConfig[] => [
      {
        path: 'platforms',
        outlet: <Platforms />,
        children: [
          {
            path: PlatformTypeShort.TickmillTrader,
            outlet: <PlatformsLearnMorePage />,
            children: [
              {
                path: ':deviceType',
                element: <TMTVideos />,
              },
            ],
          },
          {
            path: PlatformTypeShort.TradingView,
            element: <PlatformsTradingViewPage />,
          },
          {
            path: PlatformTypeShort.MT4,
            element: <PlatformsDownloadMT4Page />,
          },
          {
            path: PlatformTypeShort.MT5,
            element: <PlatformsDownloadMT5Page />,
          },
        ],
      },
    ],
    []
  )
}

import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Button } from '../../global/button/Button'
import { HexagonCheckIcon } from '../../icons/HexagonCheckIcon'
import { StepArrowIcon } from '../../icons/StepArrowIcon'
import { Link } from '../../navigation/custom-react-router-dom'
import { Text, TextH2, TextLargeStrong, TextStrong, TextTiny } from '../../ui/Typography/Typography'
import { useWindowResize } from '../../utils/domUtils'

import styles from './CreateTradingAccountCampaignSuccessPage.module.scss'

interface Step {
  name: string
  description: string

  isInteractive?: boolean
  isDisabled?: boolean
}

export const CreateTradingAccountCampaignSuccessPage: FC = () => {
  const { t } = useTranslation()

  const steps: Step[] = [
    {
      name: t('Trading Account.Step 1'),
      description: t('Trading Account.Check your email'),
      isDisabled: true,
    },
    {
      name: t('Trading Account.Step 2'),
      description: t('Trading Account.Get TradingView'),
      isInteractive: true,
      isDisabled: true,
    },
    {
      name: t('Trading Account.Step 3'),
      description: t('Trading Account.Log in'),
      isDisabled: true,
    },
    {
      name: t('Trading Account.Step 4'),
      description: t('Trading Account.Start trading'),
      isDisabled: true,
    },
  ]
  const tradingViewToolPath = '/dashboard/tools/platforms/tradingview'

  const isMobile = useWindowResize()

  return (
    <div className={styles.tradingView}>
      <HexagonCheckIcon color='alwaysWhite' />
      <TextH2 isParagraph>{t('Trading Account.Trading account created!')}</TextH2>

      <div className={styles.container}>
        <span className={styles.textCentered}>
          <TextStrong>
            {t('Trading Account.Your login credentials have been sent to your email.')}
          </TextStrong>{' '}
          <Text className={styles.textSecondary}>
            {t(
              'Trading Account.We created demo and live trading accounts, and you can use your credentials to access both.'
            )}
          </Text>
        </span>

        <span className={styles.textCentered}>
          <Text className={styles.textSecondary}>
            {t('Trading Account.Use these credentials to log in to')}
          </Text>
          <TextStrong>{t('TradingView')}</TextStrong>
          <Text className={styles.textSecondary}> {t('Trading Account.or')} </Text>
          <TextStrong>{t('Trading Account.Tickmill Trader platform')}</TextStrong>
          <Text className={styles.textSecondary}>, </Text>
          <Text className={styles.textSecondary}>
            {t('Trading Account.giving you the flexibility to choose the platform')}
          </Text>
        </span>

        <span className={styles.textCentered}>
          <Text className={styles.textSecondary}>
            {t(
              'Trading Account.Need help connecting to TradingView? Check out our step-by-step guide'
            )}
          </Text>{' '}
          <Link to={tradingViewToolPath}>
            <TextStrong className={styles.link}>{`${t('Trading Account.here')}.`}</TextStrong>
          </Link>
        </span>
        <div className={styles.nextSection}>
          <TextLargeStrong isParagraph>{t('Trading Account.What’s next?')}</TextLargeStrong>
          <div className={styles.steps}>
            {steps.map((step, i) => {
              const isArrowVisible = i !== steps.length - 1 && !isMobile

              return (
                <>
                  <div className={styles.step}>
                    <TextTiny
                      isParagraph
                      className={classNames(styles.textSecondary, {
                        [styles.isDisabled]: step.isDisabled,
                      })}
                    >
                      {step.name}:
                    </TextTiny>
                    {step?.isInteractive ? (
                      <Link to={tradingViewToolPath}>
                        <TextStrong className={styles.textInteractive}>
                          <div> {step.description}</div>
                        </TextStrong>
                      </Link>
                    ) : (
                      <TextStrong>
                        <div> {step.description}</div>
                      </TextStrong>
                    )}
                  </div>
                  {isArrowVisible && <StepArrowIcon size={25} color='iconDisabled' />}
                </>
              )
            })}
          </div>
        </div>
      </div>
      <Link to='/dashboard/traders-room/wallets' className={styles.buttonContainer}>
        <Button appearance='primary' size='L' className={styles.button}>
          {t('Trading Account.Return to Wallets')}
        </Button>
      </Link>
    </div>
  )
}

import { NameDto } from './NameDto'
import { PlatformTypeEnum } from './PlatformTypeEnum'

export interface PlatformType extends NameDto<PlatformTypeEnum> {
  enabled: boolean
}

export const isPlatformTypeDisabled = (
  platformTypes: PlatformType[],
  platformId: number
): boolean => {
  const platform = platformTypes.find(
    (platformType: PlatformType) => platformType.id === platformId
  )
  return !platform?.enabled
}

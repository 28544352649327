import React, { useContext } from 'react'

import { useFormatNumber } from '../../hooks/useFormatNumber'
import { CorporateActionDto } from '../../model/CorporateActionDto'
import { CurrencyType } from '../../model/WalletDto'
import { AuthSessionContext } from '../../utils/AuthContext'
import { formatDate } from '../../utils/date.utils'

import styles from './StockDividendsPage.module.scss'

interface Props {
  data: CorporateActionDto
  cardId?: string
}

export const StockDividendsCard: React.FC<Props> = ({ data, cardId }) => {
  const { formatMoney } = useFormatNumber()
  const [auth] = useContext(AuthSessionContext)
  const dateFormat = auth?.dateFormatType?.name

  return (
    <div className={styles.card} id={cardId}>
      <div className={styles.row}>
        <span className={styles.firstItem}>{data.instrument.name}</span>
        <span className={styles.secondItem}>
          {formatMoney(
            data.corporateActionDividend?.rate,
            data.corporateActionDividend.currencyId as CurrencyType
          )}
        </span>
      </div>
      <div className={styles.row}>
        <span className={styles.firstItem}>{data.instrument.symbol}</span>
        <span className={styles.secondItem}>
          {' '}
          {data.date && formatDate(data.date, { formatType: dateFormat })}
        </span>
      </div>
    </div>
  )
}

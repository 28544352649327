import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { TFunction } from 'i18next'

import { BackButton } from '../../global/backButton/BackButton'
import { Button } from '../../global/button/Button'
import { SelectModalCardOption } from '../../global/field/SelectModelCardOption'
import { MT4Logo } from '../../icons/MT4Logo'
import { MT5Logo } from '../../icons/MT5Logo'
import { TickmillTraderLogo } from '../../icons/TickmillTraderLogo'
import { TradingViewLogo } from '../../icons/TradingViewLogo'
import { NameDto } from '../../model/NameDto'
import { PlatformTypeEnum } from '../../model/PlatformTypeEnum'
import { TradingAccountInfo, TradingAccountInfoDetail } from '../../model/TradingAccountInfo'
import { useNavigate } from '../../navigation/custom-react-router-dom'
import { TextH3 } from '../../ui/Typography/Typography'

import styles from './PlatformTypePage.module.scss'

interface PlatformTypePageState {
  tradingAccountsAccountInfo: TradingAccountInfo[]
  demo?: boolean
  tmpPlatformType?: NameDto | undefined
}

export const PlatformTypePage = (): JSX.Element => {
  const location = useLocation()
  const navigate = useNavigate()

  const { tradingAccountsAccountInfo, demo, tmpPlatformType } =
    (location.state as PlatformTypePageState) || {}

  const [selectedPlatformType, setSelectedPlatformType] = useState<NameDto | undefined>(
    tmpPlatformType
  )

  const { t } = useTranslation()

  const allowedPlatforms = useMemo(
    () =>
      demo
        ? tradingAccountsAccountInfo.filter((x) => x.allowToOpenDemoAccount)
        : tradingAccountsAccountInfo,
    [tradingAccountsAccountInfo, demo]
  )

  const getPlatformTypeName = (platformTypeId: PlatformTypeEnum) => {
    switch (platformTypeId) {
      case PlatformTypeEnum.MT4:
        return t('Trading Account.mt4.name')
      case PlatformTypeEnum.MT5:
        return t('Trading Account.mt5.name')
      case PlatformTypeEnum.TradingView:
        return t('Trading Account.tradingView.name')
      default:
        return t('Trading Account.tickmillTrader.name')
    }
  }

  const addPlatformDetails = (
    accountInfo: TradingAccountInfo,
    t: TFunction
  ): TradingAccountInfoDetail => {
    switch (accountInfo.platform.id) {
      case PlatformTypeEnum.MT4:
        return {
          ...accountInfo,
          hint: t('mt4.hint'),
          description: t('mt4.description'),
          logo: <MT4Logo size={40} />,
        }
      case PlatformTypeEnum.MT5:
        return {
          ...accountInfo,
          hint: t('mt5.hint'),
          description: t('mt5.description'),
          logo: <MT5Logo size={40} />,
        }
      case PlatformTypeEnum.TradingView:
        return {
          ...accountInfo,
          hint: t('tradingView.hint'),
          description: t('tradingView.description'),
          logo: <TradingViewLogo size={40} />,
        }
      default:
        return {
          ...accountInfo,
          hint: t('tickmillTrader.hint'),
          description: t('tickmillTrader.description'),
          logo: <TickmillTraderLogo size={40} />,
        }
    }
  }

  const backUrl = demo
    ? `/dashboard/traders-room/trading-accounts/new/demo`
    : `/dashboard/traders-room/trading-accounts/new`

  const handleCancel = () => {
    navigate(backUrl, {
      state: {
        selectedPlatformType: location.state?.tmpPlatformType,
        previousValues: location.state?.previousValues,
      },
    })
  }

  const handleConfirm = () => {
    navigate(backUrl, {
      state: {
        selectedPlatformType,
        previousValues: location.state?.previousValues,
      },
    })
  }

  return (
    <>
      <div className={styles.backButton}>
        <BackButton onClick={handleCancel} />
        <TextH3>{t('Trading Account.Platform Type')}</TextH3>
      </div>

      <div className={styles.platformTypes}>
        {allowedPlatforms.map((allowedPlatform) => {
          const { platform, hint, description, logo } = addPlatformDetails(allowedPlatform, t)
          return (
            <SelectModalCardOption
              label={getPlatformTypeName(platform.id)}
              value={platform.id === selectedPlatformType?.id}
              onClick={() => {
                setSelectedPlatformType(platform)
              }}
              key={platform.id}
              logo={logo}
              hint={hint}
              description={description}
              isOnFire={platform.id === PlatformTypeEnum.TradingView}
            />
          )
        })}
      </div>
      <footer className={styles.footer}>
        <Button
          appearance='primary'
          size='L'
          onClick={handleConfirm}
          disabled={!selectedPlatformType}
        >
          {t('Confirm')}
        </Button>
        <Button appearance='secondary' size='L' onClick={handleCancel}>
          {t('Cancel')}
        </Button>
      </footer>
    </>
  )
}

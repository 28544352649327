import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Color } from '../../icons'
import { InfoCard } from '../../ui/Popups/InfoCard/InfoCard'
import { useWindowResize } from '../../utils/domUtils'
import { InfoIconWrapper } from '../InfoIconWrapper/InfoIconWrapper'

import styles from './InteractiveInfoButton.module.scss'

export interface InteractiveInfoButtonProps {
  color?: Color
  size?: number
  text: string
  moreInfoPath: string
}

export const InteractiveInfoButton: React.FC<InteractiveInfoButtonProps> = ({
  color,
  size,
  text,
  moreInfoPath,
}) => {
  const { t } = useTranslation()
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const isMobile = useWindowResize()
  const containerRef = useRef<HTMLDivElement>(null)
  const [position, setPosition] = useState({ x: 0, y: 0 })

  const openPopupOnHover = () => {
    if (!isMobile) {
      setIsPopupOpen(true)
    }
  }

  const closePopupOnHover = () => {
    if (!isMobile) {
      setIsPopupOpen(false)
    }
  }

  const updatePosition = () => {
    const { left, top, height } = containerRef.current?.getBoundingClientRect() as DOMRect
    setPosition({ x: left + window.scrollX, y: top + window.scrollY + height })
  }

  useEffect(() => {
    if (isPopupOpen) {
      updatePosition()
    }
  }, [isPopupOpen])

  return (
    <div ref={containerRef} onMouseEnter={openPopupOnHover} onMouseLeave={closePopupOnHover}>
      {isPopupOpen && (
        <InfoCard
          text={text}
          link={{ to: moreInfoPath, text: t('Trading Account.Read more') }}
          usePortal
          cardStyle={{ top: position.y, left: position.x }}
          className={styles.infoContent}
        />
      )}
      <InfoIconWrapper onClick={() => {}} className='info-button' color={color} size={size} />
    </div>
  )
}

import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useProductReadContext } from '../global/context/ProductContext'
import { RouteConfig } from '../routing/RenderRoutes'
import { useAccountReadContext } from '../utils/AccountContextContext'
import { DateFilterContextProvider } from '../utils/DateFilterContext'
import { MarketDataHelpPage } from './MarketData/MarketDataHelpPage'
import { MarketDataPage } from './MarketData/MarketDataPage'
import { SubscriptionsETD } from './SubscriptionsETD'
import { SubscriptionsHistoryPageETD } from './SubscriptionsHistory/SubscriptionsHistoryPageETD'
import { SubscriptionsTradingPlatformsETDPage } from './SubscriptionsTradingPlatformsETD/SubscriptionsTradingPlatformsETDPage'
import { SubscriptionsTradingPlatformsHelpETDPage } from './SubscriptionsTradingPlatformsETD/SubscriptionsTradingPlatformsHelpETDPage'

export const useSubscriptionRoutes = (): RouteConfig[] => {
  const { t } = useTranslation()
  const visible = useSubscriptionRoutesConditions()

  return useMemo(
    (): RouteConfig[] => [
      {
        path: 'subscriptions',
        outlet: <SubscriptionsETD />,
        condition: visible.isSubscriptionsVisible,
        children: [
          {
            path: 'market-data',
            element: <MarketDataPage />,
            children: [
              {
                path: 'why-market-data',
                element: <MarketDataHelpPage />,
              },
            ],
          },
          {
            path: 'trading-platforms',
            element: <SubscriptionsTradingPlatformsETDPage />,
            children: [
              {
                path: 'why-trading-platforms',
                element: (
                  <SubscriptionsTradingPlatformsHelpETDPage
                    title={t('Subscriptions.Trading Platforms')}
                    description={t(
                      'Subscriptions.A Trading Platform is the software that enables investors and traders'
                    )}
                  />
                ),
              },
              {
                path: 'why-third-party-trading-platforms',
                element: (
                  <SubscriptionsTradingPlatformsHelpETDPage
                    title={t('Subscriptions.What is a Third Party Trading Platform')}
                    description={t(
                      'Subscriptions.Tickmill gives you the possibility to connect your trading account'
                    )}
                  />
                ),
              },
            ],
          },
          {
            path: 'history',
            element: (
              <DateFilterContextProvider>
                <SubscriptionsHistoryPageETD />
              </DateFilterContextProvider>
            ),
          },
        ],
      },
    ],
    [t, visible.isSubscriptionsVisible]
  )
}

interface RoutesConditions {
  isSubscriptionsVisible: boolean
}

export const useSubscriptionRoutesConditions = (): RoutesConditions => {
  const { isDefaultCFDProductType } = useProductReadContext()
  const { account } = useAccountReadContext()

  const isSubscriptionsVisible = useMemo(() => {
    if (isDefaultCFDProductType()) {
      return false
    }

    return !!account?.visibilityConfiguration.hasEtdTraderRoom
  }, [account?.visibilityConfiguration.hasEtdTraderRoom, isDefaultCFDProductType])

  return { isSubscriptionsVisible }
}

import { PropsWithChildren, ReactNode } from 'react'
import classNames from 'classnames'

import { RadioCard } from '../radioButton/RadioButton'
import { SelectModalOptionProps } from './SelectModal'

import styles from './SelectModelCardOption.module.scss'

export interface SelectModalCardOptionProps extends SelectModalOptionProps {
  logo: ReactNode
  description?: string

  isOnFire?: boolean
}

export const SelectModalCardOption: React.FC<PropsWithChildren<SelectModalCardOptionProps>> = (
  props
) => {
  const {
    label,
    hint,
    value,
    disabled,
    onClick,
    subModal,
    children,
    spacing = 'normal',
    logo,
    description,
    isOnFire,
    ...restProps
  } = props

  return (
    <div
      className={classNames(styles.option, {
        [styles.compact]: spacing === 'compact',
      })}
    >
      <RadioCard
        {...restProps}
        checked={value}
        disabled={disabled}
        onClick={() => onClick(value)}
        logo={logo}
        label={label}
        hint={hint}
        description={description}
        isOnFire={isOnFire}
        isUnselectedGray
      />
    </div>
  )
}

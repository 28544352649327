import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Button } from '../../../global/button/Button'
import { AccountDetailedDto } from '../../../model/AccountDetailedDto'
import { isAccountActivatedStatus } from '../../../model/AccountDetailedStatus'
import { CountryPreferenceStrippedDto } from '../../../model/CountryDto'
import { PhoneNumberAddForm, PhoneNumberAddFormValues } from './PhoneNumberAddForm'

import styles from './PhoneNumberModal.module.scss'

interface PhoneNumberAddModalProps {
  account: AccountDetailedDto
  onCancel: () => void
  onConfirm: (values: PhoneNumberAddFormValues) => void
  countries: CountryPreferenceStrippedDto[]
}

export const PhoneNumberAddModal: React.FC<PhoneNumberAddModalProps> = ({
  onCancel,
  onConfirm,
  account,
  countries,
}) => {
  const { t } = useTranslation()
  const [values, setValues] = useState<PhoneNumberAddFormValues>()
  const [isValid, setIsValid] = useState<boolean>()

  return (
    <>
      <header className='modal-card-head'>
        <p className='modal-card-title'>{t('PhoneNumbers.Add New Phone Number')}</p>
      </header>
      <PhoneNumberAddForm
        passValues={setValues}
        countries={countries}
        account={account}
        setIsValid={setIsValid}
      />
      <footer className={classNames('modal-card-foot', styles.footer)}>
        <Button className={styles.button} appearance='plain' onClick={onCancel} size='S'>
          {t('Cancel')}
        </Button>
        <Button
          className={styles.button}
          appearance='plain'
          size='S'
          onClick={() => !!values && onConfirm(values)}
          disabled={!isValid}
        >
          {isAccountActivatedStatus(account) ? t('PhoneNumbers.Confirm & Verify') : t('Confirm')}
        </Button>
      </footer>
    </>
  )
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavigateOptions } from 'react-router-dom'

import { Button } from '../../global/button/Button'
import { useSnackbar } from '../../global/context/SnackbarContext'
import { Table } from '../../global/table/Table'
import { useFormatNumber } from '../../hooks/useFormatNumber'
import { WarningIcon } from '../../icons/WarningIcon'
import { Link } from '../../navigation/custom-react-router-dom'
import { AlertIconWrapper } from '../../ui/AlertIconWrapper/AlertIconWrapper'
import { TextTinyStrong } from '../../ui/Typography/Typography'
import { WalletRestrictions, isRestricted, isWalletSuspended } from '../../utils/wallet.utils'
import { IntroducingBrokerMappedDto } from './IBWalletsPage'

interface IBWalletsTableProps {
  data: IntroducingBrokerMappedDto[]
  walletWithdrawLoadingId: string

  handleWithdrawalNavigation: (withdrawalOptions?: {
    walletId?: string
    navigateOptions?: NavigateOptions
  }) => Promise<void>
}

export const IBWalletsTable: React.FC<IBWalletsTableProps> = ({
  data,
  handleWithdrawalNavigation,
  walletWithdrawLoadingId,
}) => {
  const { t } = useTranslation()
  const { formatMoney } = useFormatNumber()
  const { addSnackbar } = useSnackbar()

  return (
    <Table>
      <thead>
        <tr>
          <th>{t('Wallet.Wallet')}</th>
          <th>{t('Trading Account.Balance')}</th>
          <th>{t('IB.Incoming Reserved')}</th>
          <th>{t('IB.Outgoing Reserved')}</th>
          <th>{t('IB.All Time Reward')}</th>
          <th align='center'>{t('Wallet.Transaction History')}</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {!data.length ? (
          <>
            <td align='center' colSpan={8}>
              {t('No results')}
            </td>
          </>
        ) : (
          data.map((wallet) => {
            const hasAllRestrictions = isWalletSuspended(wallet.restrictions)
            const isTransferFromRestricted = isRestricted(
              WalletRestrictions.TRANSFER_FROM,
              wallet.restrictions
            )
            const isWithdrawRestricted = isRestricted(
              WalletRestrictions.WITHDRAW,
              wallet.restrictions
            )

            return (
              <tr key={wallet.id}>
                <td>
                  <div className='is-flex is-flex-direction-column'>
                    <span>
                      {hasAllRestrictions && <WarningIcon size={20} color={'error'} />}
                      {wallet.currency.id} {t('Wallet.IB Wallet Name')}
                    </span>
                    <span className='text-secondary'>{wallet.name}</span>
                  </div>
                </td>
                <td>
                  <strong>{formatMoney(wallet.balance, wallet.currency.id)}</strong>
                </td>
                <td>{formatMoney(wallet.incomingReserved, wallet.currency.id)}</td>
                <td>{formatMoney(wallet.reserved, wallet.currency.id)}</td>
                <td>{formatMoney(wallet.allTimeReward, wallet.currency.id)}</td>

                <td align='center'>
                  <Link
                    className='is-link is-right'
                    to='/dashboard/introducing-broker/transaction-history'
                    state={{ walletId: wallet.id }}
                  >
                    {t('View')}
                  </Link>
                </td>
                <td>
                  <AlertIconWrapper spaced isActive={!hasAllRestrictions && isWithdrawRestricted}>
                    <Button.New
                      onClick={() => {
                        if (!hasAllRestrictions && isWithdrawRestricted) {
                          addSnackbar.error({ message: t('This action is not available') })
                        } else {
                          handleWithdrawalNavigation({ walletId: wallet.id })
                        }
                      }}
                      size='XS'
                      appearance='secondary'
                      state={isWithdrawRestricted ? 'disabled' : 'normal'}
                      loading={walletWithdrawLoadingId === wallet.id}
                    >
                      <TextTinyStrong>{t('Withdrawal')}</TextTinyStrong>
                    </Button.New>
                  </AlertIconWrapper>
                </td>
                <td>
                  <Link
                    to={
                      isTransferFromRestricted
                        ? '#'
                        : `/dashboard/introducing-broker/wallets/${wallet.id}/transfer`
                    }
                  >
                    <AlertIconWrapper
                      spaced
                      isActive={!hasAllRestrictions && isTransferFromRestricted}
                    >
                      <Button.New
                        onClick={() => {
                          if (!hasAllRestrictions && isTransferFromRestricted) {
                            addSnackbar.error({ message: t('This action is not available') })
                          }
                        }}
                        size='XS'
                        appearance='secondary'
                        state={isTransferFromRestricted ? 'disabled' : 'normal'}
                      >
                        <TextTinyStrong>{t('Transfer')}</TextTinyStrong>
                      </Button.New>
                    </AlertIconWrapper>
                  </Link>
                </td>
              </tr>
            )
          })
        )}
      </tbody>
    </Table>
  )
}

import { useEffect } from 'react'
import ReactGA from 'react-ga4'

import { AppENV, enableByEnv } from '../utils/config'

const GOOGLE_ANALYTICS_ID_DEV = 'G-CY9T0DVEM7'
const GOOGLE_ANALYTICS_ID_PROD = 'G-V5QSJ1FRBW'

export const useGoogleAnalytics = (): void => {
  useEffect(() => {
    ReactGA.initialize(
      enableByEnv([AppENV.production]) ? GOOGLE_ANALYTICS_ID_PROD : GOOGLE_ANALYTICS_ID_DEV
    )
  }, [])
}

import React from 'react'

import { MasterTransaction } from '../../../../model/MasterTransaction'
import {
  isTransactionStatusSuccessful,
  isTransactionStatusUnSuccessful,
} from '../../../../model/TransactionStatus'
import { WalletTypeEnum } from '../../../../model/WalletDto'
import { useNavigate } from '../../../../navigation/custom-react-router-dom'
import { WalletWithdrawFormValues } from '../WalletWithdrawForm/WalletWithdrawForm'
import { WalletWithdrawPage } from './WalletWithdrawPage'

interface TradersRoomWalletStartWithdrawPageProps {
  walletType: WalletTypeEnum
}

export const TradersRoomWalletStartWithdrawPage: React.FC<
  TradersRoomWalletStartWithdrawPageProps
> = (props) => {
  const { walletType } = props

  const navigate = useNavigate()

  const handleSubmit = async (data: MasterTransaction, values: WalletWithdrawFormValues) => {
    if (data && !data?.twoFactor) {
      if (isTransactionStatusSuccessful(data.state.id)) {
        navigate(
          `/dashboard/traders-room/wallets/${values.wallet.id}/withdrawal/transaction/succeed`,
          {
            state: {
              data,
              providerCategoryId: values.paymentProvider.providerCategory.id,
            },
          }
        )
      }

      if (isTransactionStatusUnSuccessful(data.state.id)) {
        navigate(
          `/dashboard/traders-room/wallets/${values.wallet.id}/withdrawal/transaction/failed`,
          {
            state: {
              data,
              providerCategoryId: values.paymentProvider.providerCategory.id,
            },
          }
        )
      }
    }
  }

  const handlePageExitConfirmation = () => {
    navigate(`/dashboard/traders-room/wallets`)
  }

  return (
    <WalletWithdrawPage
      walletType={walletType}
      onSubmit={handleSubmit}
      onCancel={handlePageExitConfirmation}
    />
  )
}

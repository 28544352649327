import React, { PropsWithChildren, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { useNavigate } from '../navigation/custom-react-router-dom'
import { useApiClient } from '../utils/ApiClient'
import { ClientApiClient } from '../utils/clientApi'
import { UseReferralToken, useReferralToken } from './ReferralToken'
import {
  ReferralParams,
  getReferralParams,
  getReferralParamsOrCookie,
  getReferralParamsOrCookieForRedirect,
  hasIBCodeParameter,
  isAffTokenAndReferralCode,
  isAffTokenAndReferralCodeEmpty,
  isAffiliatesParams,
  isLandingPage,
  isReferralCode,
  isReferralCodeIB,
  isReferralDefault,
  isReferralLandingPage,
  isReferralLink,
  isSGLLink,
  setAffiliatesParamsOrCookie,
  setReferralCode,
  setReferralParams,
  setReferralParamsOrCookie,
  setSGLParamsOrCookie,
} from './helpers'

interface UseReferral extends UseReferralToken {
  params: ReferralParams
  isReferralLink: () => boolean
  isLandingPage: () => boolean
  isReferralLandingPage: () => boolean
  isReferralDefault: () => boolean
  isAffiliatesParams: () => boolean
  isSGLLink: () => boolean
  isReferralCode: () => boolean
  isReferralCodeIB: () => boolean
  isAffTokenAndReferralCode: () => boolean
  isAffTokenAndReferralCodeEmpty: () => boolean
  hasIBCodeParameter: () => boolean
}

export const useReferral = (): UseReferral => {
  const location = useLocation()
  const referralToken = useReferralToken()

  const search = location.search
  const referralParams = getReferralParamsOrCookie(search)

  return {
    params: referralParams,
    ...referralToken,
    isReferralLink: () => isReferralLink(referralParams),
    isLandingPage: () => isLandingPage(referralParams),
    isReferralLandingPage: () => isReferralLandingPage(referralParams),
    isReferralDefault: () => isReferralDefault(referralParams),
    isAffiliatesParams: () => isAffiliatesParams(referralParams),
    isSGLLink: () => isSGLLink(referralParams),
    isReferralCode: () => isReferralCode(referralParams),
    isReferralCodeIB: () => isReferralCodeIB(referralParams),
    isAffTokenAndReferralCode: () => isAffTokenAndReferralCode(referralParams),
    isAffTokenAndReferralCodeEmpty: () => isAffTokenAndReferralCodeEmpty(referralParams),
    hasIBCodeParameter: () => hasIBCodeParameter(search),
  }
}

export const Referral: React.FunctionComponent<PropsWithChildren<{}>> = ({ children }) => {
  const location = useLocation()

  const navigate = useNavigate()

  const clientApiClient = useApiClient(ClientApiClient)

  const cookieHandler = async () => {
    const params = getReferralParams(location.search)
    if (isLandingPage(params)) {
      await setCookies(params)
      if (isReferralLandingPage(params)) {
        window.location.href = getRedirectUrl(params)
      }

      if (!isReferralLandingPage(params)) {
        navigate('/404')
      }
    } else {
      await setCookies(params)
    }
  }

  useEffect(() => {
    cookieHandler()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setCookies = async (params: ReferralParams) => {
    if (isReferralLink(params)) {
      await clientApiClient.validateIntroducingBrokerByCode(params.utm_content).then(() => {
        setReferralParams(params)
        setReferralCode(params)
      })
    } else if (isAffiliatesParams(params)) {
      setAffiliatesParamsOrCookie(params)
    } else if (isSGLLink(params)) {
      setSGLParamsOrCookie(params)
    } else if (isReferralDefault(params)) {
      setReferralParamsOrCookie(params)
    }
  }

  const getRedirectUrl = (params: ReferralParams): string => {
    const url = new URL(decodeURIComponent(params.landingPageUrl))
    const urlSearchParams = new URLSearchParams(url.search)

    const urlParams = Object.entries(Object.fromEntries(urlSearchParams.entries()))
      .filter(([key, value]) => !!key && !!value)
      .reduce((previousValue, [key, value]) => {
        return {
          ...previousValue,
          [key]: value,
        }
      }, {})

    const referralParams = getReferralParamsOrCookieForRedirect(location.search)

    const redirectUrl = `${url.origin}${url.pathname}?${new URLSearchParams({
      ...urlParams,
      ...referralParams,
    })}`

    return redirectUrl
  }

  return <React.Fragment>{children}</React.Fragment>
}

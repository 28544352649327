import { BonusTransactionDto } from './BonusTransactionDto'
import { NameDto } from './NameDto'
import { TransactionLinksDto } from './TransactionLinksDto'
import { TwoFactor } from './User'
import { CurrencyType, WalletDto } from './WalletDto'

export interface MasterTransactionDto {
  id: string
  referenceId: string | null
  type: NameDto
  state: NameDto
  comment: string | null
  date: string
  settlementDate: string
  createdDate: string
  updatedDate: string
  reverseTransaction: NameDto
  reversedTransactions: NameDto[]
  walletTransactions: WalletTransactionDto[]
  groupReference?: TransactionGroupReferenceDto
  tradingAccountTransactions: TradingAccountTransactionDto[]
  linkedFrom: TransactionLinksDto[]
  linkedTo: TransactionLinksDto[]
  twoFactor?: TwoFactor[]
}

interface GroupReferenceTransaction {
  id: string
  name: string
  referenceId: string
  type: NameDto
  state: NameDto
  debitAmount: number
  creditAmount: number
}

interface TransactionGroupReferenceDto {
  id: string
  name: number
  type: NameDto
  totalWalletsAmount: number
  totalPaymentsAmount: number
  walletCurrencyId: string
  paymentsCurrencyId: string
  transactions: GroupReferenceTransaction[]
  dateCreated: string
  dateUpdated: string
}

interface TradingAccountTransactionDto {
  id: string
  directionType: NameDto
  amount: number
  tradingAccount: NameDto
  wallet: NameDto
  client: NameDto
  tickmillCompany: NameDto
  currency: NameDto
}

export interface WalletTransactionDto {
  id: string
  directionType: NameDto
  amount: number
  currencyRate: number
  otherAmount: number
  otherCurrency: {
    id: CurrencyType
    name: string
  }
  wallet: WalletDto
  paymentProviderTransaction: PaymentProviderTransactionDto
  paymentAgentTransaction: PaymentAgentTransactionDto
  ibCommisionTransaction: IbCommisionTransactionDto
  bonusTransaction: BonusTransactionDto
}

export interface PaymentProviderTransactionDto {
  id: string
  providerReference: string | null
  paymentProvider: NameDto
  currency: NameDto
  request: PaymentProviderTransactionRequestDto
  response: PaymentProviderTransactionResponseDto
  isConnectedToService: boolean
}

interface PaymentProviderTransactionRequestDto {
  [key: string]: string
}

export interface PaymentProviderTransactionResponseDto {
  redirect: {
    type: string
    method: null
    url: null
    data: {
      [key: string]: string | undefined
    } | null
    prepared: null
  }
}

interface PaymentAgentTransactionDto {
  id: string
  documentId: string | null
  paymentAgent: NameDto
  system: string | null
  account: string | null
}

interface IbCommisionTransactionDto {
  id: string
  introducingBroker: NameDto
  lotCount: number
}

export const isTransactionRedirectStaticType = (data?: MasterTransactionDto): boolean => {
  const [walletTransaction] = data?.walletTransactions || []

  return walletTransaction?.paymentProviderTransaction?.response?.redirect?.type === 'static'
}

import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5'

import { Loading } from '../Loading/Loading'

import styles from './PDFDocument.module.scss'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import classNames from 'classnames'

import { debounce } from '../../utils/debounce'
import { useLocallyPersistedState } from '../../utils/useStorage'
import { CookieStorageKey } from '../CookieConsent/CookieConsent'

interface PDFDocumentProps {
  file?: string | null
  onLoadSuccess?(): void
  setScrolledToBottom: React.Dispatch<React.SetStateAction<boolean>>
}

export const PDFDocument: React.FC<PDFDocumentProps> = (props) => {
  const { file, onLoadSuccess, setScrolledToBottom } = props

  const ref = useRef<HTMLDivElement>(null)
  const [numPages, setNumPages] = useState(0)
  const [isLoading, setLoading] = useState(true)
  const [cookiesHidden] = useLocallyPersistedState(CookieStorageKey, false)

  const [width, setWidth] = useState(0)

  const onResize = useCallback((timeout: NodeJS.Timeout | undefined) => {
    return () => {
      clearTimeout(timeout)

      timeout = setTimeout(() => {
        ref.current && setWidth(ref.current.clientWidth)
      }, 1000)

      return timeout
    }
  }, [])

  useEffect(() => {
    const timeout: NodeJS.Timeout | undefined = undefined

    window.addEventListener('resize', onResize(timeout))

    return () => {
      window.removeEventListener('resize', onResize(timeout))
    }
  }, [onResize])

  const handleDocumentLoadSuccess = (document: { numPages: number }) => {
    ref.current && setWidth(ref.current.clientWidth)
    setNumPages(document.numPages)
    setLoading(false)
    onLoadSuccess?.()
  }

  useEffect(() => {
    const chatContainer = document.getElementById('chat-widget-container')
    const mainContainer = document.getElementById('mainContainer')
    const appFooter = document.getElementById('appFooter')
    if (chatContainer) {
      chatContainer.style.display = 'none'
    }
    if (mainContainer) {
      mainContainer.style.paddingBottom = '0'
    }
    if (appFooter) {
      appFooter.style.display = 'none'
    }
    const listInnerElement = ref.current

    const onScroll = debounce(() => {
      if (listInnerElement) {
        const { scrollTop, scrollHeight, clientHeight } = listInnerElement
        const errorAllowance = 10
        const isNearBottom = scrollTop + clientHeight + errorAllowance >= scrollHeight
        if (isNearBottom) {
          setScrolledToBottom(true)
        }
      }
    }, 100)

    if (listInnerElement) {
      listInnerElement.addEventListener('scroll', onScroll)
      return () => {
        listInnerElement.removeEventListener('scroll', onScroll)
        if (chatContainer) {
          chatContainer.style.display = ''
        }
        if (mainContainer) {
          mainContainer.style.paddingBottom = ''
        }
        if (appFooter) {
          appFooter.style.display = ''
        }
      }
    }
  }, [setScrolledToBottom, ref])

  return (
    <div
      className={classNames(styles.wrapper, { [styles.cookiesHidden]: cookiesHidden })}
      ref={ref}
    >
      <Document
        file={file}
        externalLinkTarget='_blank'
        loading={<Loading showLoadingIcon isLoading={isLoading} />}
        onLoadSuccess={handleDocumentLoadSuccess}
      >
        {Array.from({ length: numPages }).map((_, index) => (
          <Page
            pageNumber={index + 1}
            renderTextLayer
            renderAnnotationLayer
            width={width}
            key={index}
            loading={''}
          />
        ))}
      </Document>
    </div>
  )
}

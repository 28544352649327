import React from 'react'
import { useTranslation } from 'react-i18next'
import { getIn, useFormikContext } from 'formik'
import { FieldConfig as FormikFieldConfig } from 'formik/dist/Field'

import { useFormatNumber } from '../../hooks/useFormatNumber'
import { useCurrencyAmountRate } from '../currency/CurrencyAmountRate'
import { DeepNonNullable } from '../types/DeepNonNullable'
import { DeepRequired } from '../types/DeepRequired'
import { TypedFieldProps } from '../types/TypedFieldProps'
import { ValidateField } from '../types/ValidateField'
import {
  createFormNumericField,
  isNumericFieldMinValid,
  isNumericFieldRangeValid,
} from './FormNumericField'

const FormNumericField = createFormNumericField()

type FieldConfig = Omit<FormikFieldConfig, 'name'>
interface FormFieldProps extends FieldConfig {
  namePrefix?: string
  fromCurrency: string
  toCurrency?: string
  min?: number
  max?: number
  onChange?(value: React.ChangeEvent<HTMLInputElement>): void
  onClick?(): void
  hint?: string
}
export function RawCurrencyAmountRateField<FormValues>(
  props: FormFieldProps & TypedFieldProps<FormValues>
): JSX.Element {
  const { name, label, placeholder, fromCurrency, toCurrency, hint, ...restProps } = props
  const { formatMoney } = useFormatNumber()
  const { values, errors } = useFormikContext()

  const value = getIn(values, name)
  const error = getIn(errors, name)

  const { amount = 0 } = useCurrencyAmountRate({
    fromCurrency,
    toCurrency,
    amount: value,
  })
  const { t } = useTranslation()

  const getNumericFieldHint = (): string | undefined => {
    const { min = 0, max, toCurrency } = props

    if (hint) {
      return hint
    }

    if (!(amount > 0)) {
      return undefined
    }

    if (error) {
      return undefined
    }

    if ((max && isNumericFieldRangeValid(value, min, max)) || !isNumericFieldMinValid(value, min)) {
      return formatMoney(amount, toCurrency)
    }
  }

  return (
    <FormNumericField
      {...restProps}
      name={name}
      label={label || t('Amount')}
      placeholder={placeholder || t('Amount')}
      min={0}
      hint={getNumericFieldHint()}
    />
  )
}

export function createFormCurrencyAmountField<FormValues>() {
  return function TypedField<Name extends ValidateField<DeepNonNullable<DeepRequired<FormValues>>>>(
    props: FormFieldProps & TypedFieldProps<DeepNonNullable<DeepRequired<FormValues>>, Name>
  ): JSX.Element {
    return <RawCurrencyAmountRateField {...props} />
  }
}

import { useEffect, useRef } from 'react'

export const useExternalScripts = (source: string, id: string): void => {
  const hasRun = useRef<boolean>(true)

  useEffect(() => {
    if (hasRun.current) {
      const existingScript = document.getElementById(id)
      const existingScriptSrc = existingScript?.getAttribute('src')

      if (existingScriptSrc !== source) {
        const script = document.createElement('script')

        script.src = source
        script.id = id
        script.async = true
        script.defer = true
        document.body.appendChild(script)
      }

      hasRun.current = false
    }
  }, [source, id])
}

import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useOutletContext } from 'react-router-dom'
import classNames from 'classnames'

import { Loading } from '../global/Loading/Loading'
import { Tabs, defineTab } from '../global/tabs/Tabs'
import { useCurrentTab } from '../hooks/useCurrentTab'
import { ScrollToIds } from '../hooks/useScrollToElementIds'
import { AccountGroupType } from '../model/AccountGroupType'
import { PlatformTypeEnum } from '../model/PlatformTypeEnum'
import { useNavigate } from '../navigation/custom-react-router-dom'
import { PageHeader } from '../ui/Table/Header/PageHeader'
import { Operator, useApiClient } from '../utils/ApiClient'
import { ClientApiClient } from '../utils/clientApi'
import { useWindowResize } from '../utils/domUtils'
import { useFetchOne } from '../utils/useFetch'

import styles from './StatementsETD.module.scss'

type ContextType = { tradingAccountId?: string }

export const StatementsETD: React.FC = () => {
  const { t } = useTranslation()
  const currentTab = useCurrentTab('statements')
  const navigate = useNavigate()
  const isMobile = useWindowResize()
  const apiClient = useApiClient(ClientApiClient)

  const isDaily = currentTab === 'daily'

  const tradingAccountCallback = useCallback(async () => {
    const [tradingAccount] = (
      await apiClient.getTradingAccounts({
        search: {
          AccountGroupType: {
            value: AccountGroupType.Live,
            operator: Operator.EQUAL,
          },
          Platform: {
            value: PlatformTypeEnum.CQGCAST, // it's for ETD
            operator: Operator.EQUAL,
          },
          Status: {
            value: 1,
            operator: Operator.EQUAL,
          },
        },
      })
    ).items

    return tradingAccount?.id
  }, [apiClient])

  const { data: tradingAccountId, isLoading } = useFetchOne(tradingAccountCallback)

  function* getTabs() {
    yield {
      ...defineTab('daily', t('Statements.Daily')),
    }
    yield {
      ...defineTab('monthly', t('Statements.Monthly')),
    }
  }

  const TabsDefinition = [...getTabs()]

  return (
    <div>
      <div className={classNames('section', styles.adminRoutes)}>
        <Tabs
          className={styles.tabs}
          tabs={TabsDefinition}
          currentTab={currentTab}
          appearance={isMobile ? undefined : 'light'}
          onTabChanged={(tab) => navigate(`/dashboard/statements/${tab}`)}
        />
      </div>

      <PageHeader
        title={isDaily ? t('Statements.Daily Statements') : t('Statements.Monthly Statements')}
        id={ScrollToIds.StatementsETDHeader}
      />
      <Loading showLoadingIcon isLoading={isLoading}>
        <Outlet context={{ tradingAccountId } satisfies ContextType} />
      </Loading>
    </div>
  )
}

export const useStatementsTAId = (): ContextType => {
  return useOutletContext<ContextType>()
}

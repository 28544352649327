import React from 'react'
import { useTranslation } from 'react-i18next'
import { useOutletContext } from 'react-router-dom'

import { PlatformInfoPlatformLinkDto } from '../../model/PlatformInfoDto'
import { getVersionByPlatformType } from '../../model/PlatformTypeEnum'
import { Text } from '../../ui/Typography/Typography'
import { PlatformDownloadButton, PlatformIconFactory } from './PlatformIcon'
import { usePlatformInfo } from './Platforms'
import { PlatformsToolKitsSection } from './PlatformsToolKitsSection'

import styles from './Platforms.module.scss'

export const PlatformsDownloadMT4Page: React.FC = () => {
  const { t } = useTranslation()

  return <PlatformsDownloadPage text={t('MetaTrader.MT4')} noteText={t('MetaTrader.Get access')} />
}

export const PlatformsDownloadMT5Page: React.FC = () => {
  const { t } = useTranslation()

  return (
    <PlatformsDownloadPage text={t('MetaTrader.MT5')} noteText={t('MetaTrader.Explore more')} />
  )
}

interface PlatformsDownloadProps {
  text: string
  noteText: string
}

const PlatformsDownloadPage: React.FC<PlatformsDownloadProps> = (props) => {
  const { text, noteText } = props

  const { t } = useTranslation()

  const platformInfoProps = useOutletContext<ReturnType<typeof usePlatformInfo>>()
  const { selectedPlatform, platformId } = platformInfoProps

  return (
    <>
      <Text isParagraph className={styles.text}>
        {text}
      </Text>
      <div className='has-text-centered'>
        <h2 className={styles.title}>
          {t('MetaTrader.Download Your MetaTrader')} {getVersionByPlatformType(platformId)}
        </h2>
        <Text isParagraph className={styles.note}>
          <span className={styles.textGray}>{noteText}</span>
        </Text>
        <div className={styles.icons}>
          <DownloadSectionBody platformLinks={selectedPlatform?.platformLinks} />
        </div>
      </div>

      <PlatformsToolKitsSection {...platformInfoProps} />
    </>
  )
}

interface DownloadSectionBodyProps {
  platformLinks: PlatformInfoPlatformLinkDto[] | undefined
}

const DownloadSectionBody: React.FC<DownloadSectionBodyProps> = (props) => {
  const { platformLinks = [] } = props

  return (
    <>
      {platformLinks
        .sort((a, b) => a.order - b.order)
        .map((link, linkIndex) => (
          <div className={styles.icon} key={linkIndex}>
            <PlatformDownloadButton to={link.productLink}>
              <PlatformIconFactory os={link.operatingSystem.id} />
            </PlatformDownloadButton>
          </div>
        ))}
    </>
  )
}

import { FC } from 'react'
import classNames from 'classnames'

import { InfoIcon } from '../../../../icons/InfoIcon'
import { InfoCard } from '../../../../ui/Popups/InfoCard/InfoCard'
import { Tooltip } from '../../../../ui/Popups/Tooltip/Tooltip'
import { Text, TextSmall } from '../../../../ui/Typography/Typography'
import { Color, ProgressBar } from '../../../../ui/v2/ProgressBar/ProgressBar'

import styles from './ProgressBarsBlock.module.scss'

export interface ProgressBarsSectionItem {
  percent: number
  label?: string
}

export interface ProgressBarsBlockItem {
  title: string
  label?: string
  tooltip?: string
  sections: ProgressBarsSectionItem[]
}

interface ProgressBarsBlockProps {
  items: ProgressBarsBlockItem[]
  className?: string
  color?: Color
}

export const ProgressBarsBlock: FC<ProgressBarsBlockProps> = (props) => {
  const { items, className, color = 'red' } = props

  return (
    <div className={classNames(styles.progressBarsBlock, className)}>
      {items.map((item, index) => {
        return <ProgressBarBlock key={index} color={color} {...item} />
      })}
    </div>
  )
}

interface ProgressBarBlockProps extends ProgressBarsBlockItem {
  color: Color
}

const ProgressBarBlock: FC<ProgressBarBlockProps> = (props) => {
  const { title, label, sections, color, tooltip } = props

  return (
    <div className={styles.progressBarBlock}>
      <div className={styles.progressBarBlockHead}>
        <div className={styles.progressBarBlockTitle}>
          <Text>{title}</Text>
          {!!tooltip?.length && (
            <InfoCard text={tooltip}>
              <InfoIcon color='iconTertiary' size={20} />
            </InfoCard>
          )}
        </div>
        <TextSmall>{label}</TextSmall>
      </div>
      <div className={styles.progressBarBlockProgress}>
        {sections.map((section, index) => {
          return <ProgressBarSection key={index} {...section} color={color} />
        })}
      </div>
    </div>
  )
}

interface ProgressBarsSectionProps extends ProgressBarsSectionItem {
  color: Color
}

const ProgressBarSection: FC<ProgressBarsSectionProps> = (props) => {
  const { percent, label, color } = props

  return (
    <div className={styles.progressBarSection}>
      <ProgressBar color={color} percent={percent} />
      {!!label?.length && (
        <Tooltip.New content={label} wrapperClassName={styles.progressBarLabelWrapper}>
          <TextSmall className={styles.progressBarLabel}>{label}</TextSmall>
        </Tooltip.New>
      )}
    </div>
  )
}

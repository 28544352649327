import { useEffect, useMemo, useRef } from 'react'

export const debounce = (
  func: (...args: any[]) => void,
  delay: number
): ((...args: any[]) => void) => {
  let timerId: NodeJS.Timeout
  return (...args: any[]) => {
    clearTimeout(timerId)
    timerId = setTimeout(() => {
      func(...args)
    }, delay)
  }
}

type EffectCallback = () => void

export const useDebounceEffect = (effect: EffectCallback, deps: any[], delay: number): void => {
  const effectRef = useRef(effect)
  effectRef.current = effect

  const debouncedEffect = useMemo(() => {
    return debounce(() => {
      effectRef.current()
    }, delay)
  }, [delay])

  useEffect(() => {
    debouncedEffect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps, debouncedEffect])
}

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'

import { DepositFormWarningBox } from '../../../../../Traders-Room/DepositFormWarningBox'
import { CurrencyAmountChip } from '../../../../../Traders-Room/Trading-Accounts/CurrencyAmountChip'
import { WalletDepositCurrencyModal } from '../../../../../Traders-Room/Wallets/WalletDeposit/WalletDepositCurrencyModal'
import { CurrencyAmountField } from '../../../../../Traders-Room/Wallets/WalletDeposit/WalletDepositForm/CurrencyAmountField'
import { FormTermsConditionsPSP } from '../../../../../Traders-Room/Wallets/WalletDeposit/WalletDepositForm/FormTermsConditionsFactory'
import { WalletDepositFormValues } from '../../../../../Traders-Room/Wallets/WalletDeposit/WalletDepositForm/WalletDepositForm'
import { WalletFormFieldsFactory } from '../../../../../Traders-Room/Wallets/WalletDeposit/WalletDepositForm/WalletFormFieldsFactory'
import { Button } from '../../../../../global/button/Button'
import { FieldGroup } from '../../../../../global/form/FieldGroup'
import { createFormField } from '../../../../../global/formField/FormField'
import { CardPaymentProviderProceedModal } from '../../../../../global/modal/CardPaymentProviderProceedModal'
import { PaymentProviderProceedModal } from '../../../../../global/modal/PaymentProviderProceedModal'
import { TransactionFormActionButtonTemplate } from '../../../../../global/templates/TransactionFormActionButtonTemplate'
import { DropArrowDownIcon } from '../../../../../icons/DropArrowDownIcon'
import {
  isPaymentProviderBankType,
  isPaymentProviderCardProviderType,
  isPaymentProviderPSPType,
  isPaymentProviderPaymentAgentType,
} from '../../../../../model/PaymentProviderType'
import { WalletPaymentProviderCurrency } from '../../../../../model/WalletPaymentProviderParameters'
import { Link } from '../../../../../navigation/custom-react-router-dom'

import styles from './FirstTimeDepositForm.module.scss'

const FormField = createFormField<WalletDepositFormValues>()

interface FirstTimeDepositPSPFieldsProps {
  showAmountPresets?: boolean
  minAmount?: number
  skipButtonLink: string
  onClickTermsConditions(): void
}

export const FirstTimeDepositPSPFields: React.FC<FirstTimeDepositPSPFieldsProps> = (props) => {
  const { minAmount, showAmountPresets, skipButtonLink, onClickTermsConditions } = props
  const { t } = useTranslation()

  const { values, setValues, isValid, handleSubmit } = useFormikContext<WalletDepositFormValues>()

  const [isCurrencyModalOpen, setCurrencyModalOpen] = useState(false)
  const [isPaymentProviderProceedModalOpen, setPaymentProviderProceedModalOpen] = useState(false)
  const [isCardPaymentProviderProceedModalOpen, setCardPaymentProviderProceedModalOpen] =
    useState(false)

  const handleCurrency = (walletPaymentProviderCurrency: WalletPaymentProviderCurrency) => {
    if (!values.paymentProvider) {
      return
    }
    setValues({
      ...values,
      paymentProvider: {
        ...values.paymentProvider,
        currency: walletPaymentProviderCurrency,
      },
    })
    setCurrencyModalOpen(false)
  }

  const handleCurrencyModalOpen = () => {
    setCurrencyModalOpen(true)
  }

  const handleCurrencyModalClose = () => {
    setCurrencyModalOpen(false)
  }

  const handleSubmitForm = () => {
    handleSubmit()
  }

  const handlePaymentProviderProceed = () => {
    if (isPaymentProviderPSPType(values.paymentProvider?.providerCategory.id)) {
      setPaymentProviderProceedModalOpen(true)
    }

    if (isPaymentProviderCardProviderType(values.paymentProvider?.providerCategory.id)) {
      setCardPaymentProviderProceedModalOpen(true)
    }

    if (isPaymentProviderBankType(values.paymentProvider?.providerCategory.id)) {
      handleSubmit()
    }

    if (isPaymentProviderPaymentAgentType(values.paymentProvider?.providerCategory.id)) {
      handleSubmit()
    }
  }

  const handlePaymentProviderProceedModalClose = () => {
    setPaymentProviderProceedModalOpen(false)
  }

  const handleCardPaymentProviderProceedModalClose = () => {
    setCardPaymentProviderProceedModalOpen(false)
  }

  const disabled =
    values?.paymentProvider?.parameters &&
    !(values.paymentProvider.parameters.currencies.length > 1)

  return (
    <React.Fragment>
      {isCurrencyModalOpen && (
        <WalletDepositCurrencyModal
          onSelectOption={handleCurrency}
          onClose={handleCurrencyModalClose}
        />
      )}
      {isPaymentProviderProceedModalOpen && (
        <PaymentProviderProceedModal
          onProceed={handleSubmitForm}
          onClose={handlePaymentProviderProceedModalClose}
        />
      )}
      {isCardPaymentProviderProceedModalOpen && (
        <CardPaymentProviderProceedModal
          onProceed={handleSubmitForm}
          onClose={handleCardPaymentProviderProceedModalClose}
        />
      )}
      <WalletFormFieldsFactory />
      <FieldGroup>
        <CurrencyAmountField />
        <div className={styles.currencyFieldWrapper}>
          <FormField
            name='paymentProvider.currency.id'
            label={t('Wallet.Currency')}
            disabled={disabled}
            rightIcon={!disabled && <DropArrowDownIcon />}
            onClick={handleCurrencyModalOpen}
          />
        </div>
      </FieldGroup>
      {showAmountPresets && (
        <FieldGroup>
          <CurrencyAmountChip minValue={minAmount} />
        </FieldGroup>
      )}
      {values.paymentProvider.name === 'Crypto Currency' && (
        <DepositFormWarningBox className={styles.formItem}>
          {t('Crypto info')}
        </DepositFormWarningBox>
      )}
      <FormField
        name='comment'
        label={t('Wallet.Additional Comment')}
        placeholder={t('Wallet.Additional Comment')}
      />
      <DepositFormWarningBox className={styles.formItem}>
        <ul>
          <li>{t('Sign up.1. Start Now: Deposit now and start exploring your Client Area')}</li>
          <li>{t('Sign up.2. Note: Trade on your demo account until your account is verified')}</li>
          <li>
            {t(
              `Sign up.3. Full Access: Once your account is approved, you'll be able to trade with real funds and withdraw profits`
            )}
          </li>
        </ul>
      </DepositFormWarningBox>
      <div className='my-5'>
        <FormTermsConditionsPSP onClickTermsConditions={onClickTermsConditions} />
      </div>
      <TransactionFormActionButtonTemplate>
        <Link to={skipButtonLink}>
          <Button appearance='secondary' size='L' type='button' fullWidth>
            {t('Skip')}
          </Button>
        </Link>
        <Button
          appearance='primary'
          size='L'
          type='button'
          onClick={handlePaymentProviderProceed}
          disabled={!isValid}
        >
          {t('Confirm')}
        </Button>
      </TransactionFormActionButtonTemplate>
    </React.Fragment>
  )
}

import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import * as XLSX from 'xlsx'

import { Loading } from '../../global/Loading/Loading'
import { Paging, PagingEventType } from '../../global/Paging/Paging'
import { useSessionLanguage } from '../../global/context/SessionSettingsContext'
import { FilterQueryProps } from '../../global/filter/FilterQueryModal'
import { ExportModal } from '../../global/modal/ExportModal'
import { InformationModal } from '../../global/modal/InformationModal'
import { Modal } from '../../global/modal/Modal'
import { SortByModal } from '../../global/modal/SortByModal'
import { SortHeaderAlignType } from '../../global/sortHeader/SortHeader'
import { useFormatNumber } from '../../hooks/useFormatNumber'
import {
  ScrollToIds,
  useScrollAfterLoad,
  useScrollToElementIds,
} from '../../hooks/useScrollToElementIds'
import { WarningIcon } from '../../icons/WarningIcon'
import {
  isCampaignCalculationAccountType,
  isCampaignCalculationTiersType,
} from '../../model/CampaignCalculationType'
import { ClientRebateCampaignDto, isCampaignClientRebate } from '../../model/CampaignResultDto'
import { isCampaignClientRebateType } from '../../model/CampaignType'
import { ClientCampaignRebateResultDto } from '../../model/ClientCampaignRebateResultDto'
import { ClientRebateCampaignDetailDto } from '../../model/ClientRebateCampaignDetailDto'
import { PageHeader } from '../../ui/Table/Header/PageHeader'
import { TextLarge } from '../../ui/Typography/Typography'
import { PageQuery, SortOrder, initialPageData, useApiClient } from '../../utils/ApiClient'
import { AuthSessionContext } from '../../utils/AuthContext'
import { useDateFilterWriteContext } from '../../utils/DateFilterContext'
import { ClientApiClient } from '../../utils/clientApi'
import { formatDate } from '../../utils/date.utils'
import { useWindowResize } from '../../utils/domUtils'
import { normalizeSortLabel } from '../../utils/format.utils'
import { generatePDFTable } from '../../utils/prepare.pdf.utils'
import { useCallbackWithForceRefresh } from '../../utils/useCallbackWithForceRefresh'
import {
  IAppendableFetchResult,
  IFetchResult,
  useFetchAppendablePage,
  useFetchOne,
} from '../../utils/useFetch'
import { useScrollToTop } from '../../utils/useScrollToTop'
import { CampaignRebateCards } from './CampaignRebateCard'
import { CampaignRebateTable } from './CampaignRebateTable'

const initialQuery = {}

interface Props {
  campaign: ClientRebateCampaignDto
  onGoBack(): void
}

interface GenerateDocumentFileProps {
  query: IAppendableFetchResult<ClientCampaignRebateResultDto[]>
  campaign: ClientRebateCampaignDto
}

const useGenerateDocumentFile = (props: GenerateDocumentFileProps) => {
  const { query, campaign } = props

  const { t } = useTranslation()

  const exportFilename = 'campaigns_rebate_report'

  const [auth] = useContext(AuthSessionContext)
  const dateFormat = auth?.dateFormatType?.name
  const { formatMoney } = useFormatNumber()

  const [isOptionsModalOpen, setOptionsModalOpen] = useState<boolean>(false)

  const handleGenerateExcel = () => {
    const table = tableBody()
    const wb = XLSX.utils.book_new()
    const ws = XLSX.utils.aoa_to_sheet(table)

    XLSX.utils.book_append_sheet(wb, ws, exportFilename)
    XLSX.writeFile(wb, `${exportFilename}.xlsx`)

    setOptionsModalOpen(false)
  }

  const handleGeneratePDF = () => {
    const data = tableBody()
    generatePDFTable({
      data,
      title: `Campaigns - ${campaign.name}`,
      fileName: exportFilename,
    })

    setOptionsModalOpen(false)
  }

  const getCampaignRebateValue = () => {
    if (isCampaignCalculationTiersType(campaign.calculationType.id)) {
      return t('Campaigns.Tier Reached')
    }

    if (isCampaignCalculationAccountType(campaign.calculationType.id)) {
      return t('Account Type')
    }

    return campaign?.calculationType?.name || ''
  }

  const tableBody = () => {
    if (isCampaignClientRebate(campaign)) {
      const headerCsvData = [
        [
          t('Campaigns.Round Date'),
          t('Campaigns.Total Volume Traded'),
          getCampaignRebateValue(),
          t('Campaigns.Accumulated Rebate'),
          t('Campaigns.Currency'),
        ],
      ]

      return query.data.reduce((previousValue, currentValue) => {
        return previousValue.concat([
          [
            formatDate(currentValue.round, { formatType: dateFormat }),
            currentValue.lots.toString(),
            currentValue?.rebateValue?.toString() || '',
            `${formatMoney(currentValue.rebate, currentValue.currency.id)}`,
            currentValue.currency.id,
          ],
        ])
      }, headerCsvData)
    }

    const headerCsvData = [
      [
        t('Campaigns.Round Date'),
        t('Campaigns.Total Volume Traded'),
        t('Campaigns.Accumulated Rebate'),
        t('Campaigns.Currency'),
      ],
    ]
    return query.data.reduce((previousValue, currentValue) => {
      return previousValue.concat([
        [
          formatDate(currentValue.round, { formatType: dateFormat }),
          currentValue.lots.toString(),
          formatMoney(currentValue.rebate, currentValue.currency.id),
          currentValue.currency.id,
        ],
      ])
    }, headerCsvData)
  }

  return {
    handleGenerateExcel,
    handleGeneratePDF,
    tableBody,
    isOptionsModalOpen,
    setOptionsModalOpen,
    exportFilename,
  }
}

interface CampaignResultsFetchProps {
  search: SearchResponseProps
  campaign: ClientRebateCampaignDto
}

interface CampaignResultsResponseProps {
  query: IAppendableFetchResult<ClientCampaignRebateResultDto[]>
  forceRefresh(): void
}

const useCampaignResultsFetch = (
  props: CampaignResultsFetchProps
): CampaignResultsResponseProps => {
  const { campaign, search } = props

  const locale = useSessionLanguage()
  const apiClient = useApiClient(ClientApiClient)

  const { callback, forceRefresh } = useCallbackWithForceRefresh(
    async (query?: PageQuery) => {
      if (campaign?.account) {
        return apiClient.getRebateCampaignResults(campaign.account, campaign.id, {
          ...query,
          ...search.pageQuery,
          search: search.search,
          caller: 'ca',
          languageId: locale,
        })
      }

      return Promise.resolve(initialPageData)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [search.search, search.pageQuery, campaign?.account, locale]
  )
  const query = useFetchAppendablePage(callback)

  return { query, forceRefresh }
}

const useCampaignFetch = (props: CampaignResultsFetchProps) => {
  const { campaign } = props

  const locale = useSessionLanguage()
  const apiClient = useApiClient(ClientApiClient)

  const { callback, forceRefresh } = useCallbackWithForceRefresh(
    async () => {
      if (campaign?.account) {
        return apiClient.getTradingAccountRebateCampaignById(campaign.account, campaign.id, locale)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locale, campaign?.account]
  )
  const query = useFetchOne(callback)

  return { query, forceRefresh }
}

interface SearchResponseProps {
  clearSearch(callback: () => void): void
  search: FilterQueryProps
  setSearch(value: FilterQueryProps): void
  pageQuery: PageQuery | undefined
  setPageQuery(value: PageQuery): void
}

const useSearch = (props: SortResponseProps): SearchResponseProps => {
  const { sort, sortOrder } = props

  const location = useLocation()
  const { clearFilter } = useDateFilterWriteContext()

  const [search, setSearch] = useState<FilterQueryProps>(initialQuery)
  const [pageQuery, setPageQuery] = useState<PageQuery>({ sort, sortOrder })

  useEffect(() => {
    setSearch(initialQuery)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  const clearSearch = (callback: () => void) => {
    callback()
    setSearch(initialQuery)
    clearFilter()
  }

  return {
    clearSearch,
    search,
    setSearch,
    pageQuery,
    setPageQuery,
  }
}

interface SortResponseProps {
  closeSortModal(): void
  isSortModalOpen: boolean
  setSort(value: string): void
  sort: string | undefined
  sortOrder: SortOrder | undefined
  setSortOrder(value: SortOrder): void
  setSortModalOpen(value: boolean): void
}

type SortOptionsTypes = SortOptionsType[]
type SortOptionsType = { id: string; name: string; align: SortHeaderAlignType }

interface SortProps {
  campaign: ClientRebateCampaignDto
}

const useSort = (): SortResponseProps => {
  const [sort, setSort] = useState<string>()
  const [sortOrder, setSortOrder] = useState<SortOrder>()
  const [isSortModalOpen, setSortModalOpen] = useState(false)

  const closeSortModal = () => {
    setSortModalOpen(false)
  }

  return {
    closeSortModal,
    isSortModalOpen,
    setSort,
    sort,
    sortOrder,
    setSortOrder,
    setSortModalOpen,
  }
}

interface SortSettingsResponseProps {
  getCampaignRebateValue(): string
  getSortName(): string | undefined
  sortOptions: SortOptionsTypes
}

const useSortSettings = (
  props: SortProps,
  sortResponseProps: SortResponseProps
): SortSettingsResponseProps => {
  const { campaign } = props

  const { t } = useTranslation()

  const getCampaignRebateValue = () => {
    if (isCampaignCalculationTiersType(campaign.calculationType.id)) {
      return t('Campaigns.Tier Reached')
    }

    if (isCampaignCalculationAccountType(campaign.calculationType.id)) {
      return t('Account Type')
    }

    return campaign?.calculationType?.name || ''
  }

  const sortOptions: SortOptionsTypes = isCampaignClientRebate(campaign)
    ? [
        {
          id: 'CampaignRebatePeriod.From',
          name: t('Campaigns.Round Date'),
          align: 'left',
        },
        { id: 'Volume', name: t('Campaigns.Total Volume Traded'), align: 'center' },
        hasRebateValueColumn(campaign)
          ? {
              id: 'RebateValue',
              name: getCampaignRebateValue(),
              align: 'center',
            }
          : undefined,
        {
          id: 'AccumulatedRebate',
          name: t('Campaigns.Accumulated Rebate'),
          align: 'center',
        },
      ].filter((x): x is SortOptionsType => !!x?.id)
    : [
        {
          id: 'CampaignRebatePeriod.From',
          name: t('Campaigns.Round Date'),
          align: 'left',
        },
        { id: 'Volume', name: t('Campaigns.Total Volume Traded'), align: 'center' },
        {
          id: 'AccumulatedRebate',
          name: t('Campaigns.Accumulated Rebate'),
          align: 'center',
        },
      ]

  const getSortName = () => {
    return sortOptions.find((x) => x.id === sortResponseProps.sort)?.name
  }

  return {
    getCampaignRebateValue,
    getSortName,
    sortOptions: sortOptions || [],
  }
}

interface CurrentRoundProps {
  campaign: ClientRebateCampaignDto
  campaignResults: IFetchResult<ClientCampaignRebateResultDto[]>
}

const useCurrentRound = (props: CurrentRoundProps) => {
  const { campaign, campaignResults } = props

  const [currentRound, setCurrentRound] = useState<Date | null>()

  useEffect(() => {
    const data = campaignResults?.data || []
    const campaignLength = data?.length || 0
    const campaignActive = new Date(campaign.to) >= new Date()

    const maxDate = new Date(
      Math.max(
        ...data.map((x) => {
          return new Date(x.round).getTime()
        })
      )
    )

    if (!currentRound?.getTime()) {
      setCurrentRound(campaignActive && campaignLength > 0 ? maxDate : null)
    }
  }, [campaign.to, campaignResults?.data, currentRound])

  return { currentRound }
}

export const CampaignRebatePage: React.FC<Props> = (props) => {
  useScrollToTop()
  const { campaign, onGoBack } = props

  const { t } = useTranslation()
  const isMobile = useWindowResize()

  const sort = useSort()
  const search = useSearch(sort)
  const campaignDetails = useCampaignFetch({ search, campaign })
  const campaignResults = useCampaignResultsFetch({ search, campaign })
  const sortSettings = useSortSettings(props, sort)

  const query = campaignResults.query
  const { meta, pageQuery, setPageQuery } = query

  const generateDocumentFile = useGenerateDocumentFile({ query, campaign })
  const { currentRound } = useCurrentRound({ campaign, campaignResults: campaignResults.query })
  const { formatMoney } = useFormatNumber()
  const { scrollIntoView } = useScrollToElementIds()

  const [isInfoModal, setInfoModal] = useState(false)

  const [isPaginationEntrySelected, setIsPaginationEntrySelected] = useState(false)
  useEffect(() => {
    if (
      isPaginationEntrySelected &&
      !campaignDetails.query.isLoading &&
      !campaignResults.query.isLoading
    ) {
      scrollIntoView([ScrollToIds.CampaignTotalRebateHeader])
      setIsPaginationEntrySelected(false)
    }
  }, [campaignDetails.query.isLoading, campaignResults.query.isLoading, scrollIntoView])

  useScrollAfterLoad(
    ScrollToIds.CampaignTotalRebateHeader,
    campaignResults.query.isLoading,
    meta?.pageSize
  )

  return (
    <Loading
      showLoadingIcon
      isLoading={campaignDetails.query.isLoading || campaignResults.query.isLoading}
    >
      {generateDocumentFile.isOptionsModalOpen && (
        <Modal
          render={({ closeModal }) => (
            <ExportModal
              fileName={generateDocumentFile.exportFilename}
              onCloseModal={closeModal}
              csvData={generateDocumentFile.tableBody()}
              onExportToCSV={closeModal}
              onExportToPdf={generateDocumentFile.handleGeneratePDF}
              onExportToExcel={generateDocumentFile.handleGenerateExcel}
            />
          )}
          closeModal={() => generateDocumentFile.setOptionsModalOpen(false)}
        />
      )}
      {isInfoModal && (
        <Modal
          closeModal={() => setInfoModal(false)}
          render={() => (
            <InformationModal
              onCancel={() => setInfoModal(false)}
              title={t('Campaigns.Paid Out Rebate')}
              onCancelText={t('Got It')}
            >
              <p>
                {t('Campaigns.amounts under', {
                  amount: formatMoney(1, campaign.currency.id),
                })}
              </p>
            </InformationModal>
          )}
        />
      )}
      {isMobile && sort.isSortModalOpen && (
        <Modal
          closeModal={() => sort.setSortModalOpen(false)}
          render={({ closeModal }) => (
            <SortByModal
              onCancel={closeModal}
              options={sortSettings.sortOptions}
              onConfirm={(option, sortBy) => {
                search.setPageQuery?.({
                  ...search.pageQuery,
                  sort: option,
                  sortOrder: sortBy,
                })
                closeModal()
              }}
            />
          )}
        />
      )}
      <div className='mb-4'>
        <Header
          campaign={campaign}
          query={campaignResults.query}
          pageQuery={search.pageQuery}
          sort={sort}
          sortSettings={sortSettings}
          campaignDetails={campaignDetails.query.data}
          onGoBack={onGoBack}
          setOptionsModalOpen={() => generateDocumentFile.setOptionsModalOpen(true)}
        />
      </div>
      {campaignDetails.query?.data && campaignDetails.query?.data?.disqualified && (
        <CampaignsWarningMessage />
      )}
      {isMobile && (
        <CampaignRebateCards
          data={campaignResults.query.data}
          campaign={campaign}
          campaignDetails={campaignDetails.query.data}
          currentRound={currentRound}
          campaignRebateValue={sortSettings.getCampaignRebateValue()}
          setInfoModal={setInfoModal}
        />
      )}
      {!isMobile && (
        <CampaignRebateTable
          sortOptions={sortSettings.sortOptions}
          data={campaignResults.query.data}
          campaign={campaign}
          campaignDetails={campaignDetails.query.data}
          currentRound={currentRound}
          pageQuery={search.pageQuery}
          setPageQuery={search.setPageQuery}
          setInfoModal={setInfoModal}
        />
      )}
      {meta && (
        <Paging
          scrollToHeaderId={ScrollToIds.CampaignTotalRebateHeader}
          pageData={meta}
          isLoading={campaignDetails.query.isLoading || campaignResults.query.isLoading}
          onPageChanged={(pageIndex, pageSize, pagingEventType) => {
            if (pagingEventType === PagingEventType.ENTRIES_CHANGED) {
              setIsPaginationEntrySelected(true)
            }
            return setPageQuery!({
              ...pageQuery,
              pageIndex,
              pageSize,
            })
          }}
        />
      )}
    </Loading>
  )
}

interface HeaderProps {
  campaign: ClientRebateCampaignDto
  query: IAppendableFetchResult<ClientCampaignRebateResultDto[]>
  pageQuery: PageQuery | undefined
  sort: SortResponseProps
  sortSettings: SortSettingsResponseProps
  campaignDetails?: ClientRebateCampaignDetailDto
  onGoBack(): void
  setOptionsModalOpen(value: boolean): void
}

const Header: React.FC<HeaderProps> = (props) => {
  const { campaign, campaignDetails, query, sort, sortSettings, pageQuery } = props
  const { onGoBack, setOptionsModalOpen } = props

  const { t } = useTranslation()
  const isMobile = useWindowResize()
  const { formatMoney } = useFormatNumber()

  if (query.hasInitialResults) {
    return (
      <>
        <PageHeader
          id={ScrollToIds.CampaignTotalRebateHeader}
          title={campaign.name}
          subtitle={`${t('Campaigns.Total Rebate')}: ${formatMoney(
            campaignDetails?.totalRebate,
            campaign.currency.id
          )}${createTitle(campaignDetails)}`}
          darkTitle={!isMobile ? `${t('Tabs.Campaign Results')} / ` : undefined}
          backButton={onGoBack}
          optionsToggle={() => setOptionsModalOpen(true)}
          filterToggles={{
            openSortModal: () => sort.setSortModalOpen(true),
            sortLabel: normalizeSortLabel(t, sortSettings.sortOptions || [], pageQuery?.sort),
          }}
        />
      </>
    )
  }

  return (
    <PageHeader
      id={ScrollToIds.CampaignTotalRebateHeader}
      title={campaign.name}
      darkTitle={!isMobile ? `${t('Tabs.Campaign Results')} / ` : undefined}
      backButton={onGoBack}
    />
  )
}

const CampaignsWarningMessage = () => {
  const { t } = useTranslation()

  return (
    <div className='is-flex is-align-items-center mb-2'>
      <span className='mr-2'>
        <WarningIcon size={18} color='error' />
      </span>
      <TextLarge isParagraph className='has-error'>
        {t("Campaigns.You've been disqualified from the competition")}
      </TextLarge>
    </div>
  )
}

const createTitle = (campaignDetails?: ClientRebateCampaignDetailDto) => {
  const platformTypeName = campaignDetails?.tradingAccount?.platformType?.name
  const platformTypeNameText = platformTypeName ? ` | ${platformTypeName}` : ''
  return platformTypeNameText
}

const hasRebateValueColumn = (campaignDetails?: ClientRebateCampaignDto) => {
  return (
    isCampaignClientRebateType(campaignDetails?.type?.id) &&
    !isCampaignCalculationAccountType(campaignDetails?.calculationType.id)
  )
}

import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import classNames from 'classnames'

import { useAccountInfo } from '../global/context/AccountInfoContext/AccountInfoContext.Provider'
import { useModalsContext } from '../global/context/ModalsContext'
import { useProductReadContext } from '../global/context/ProductContext'
import { useLuckyDraw } from '../global/fortuneWheel/useLuckyDraw'
import IconButton from '../global/iconButton/IconButton'
import { TabProps, Tabs } from '../global/tabs/Tabs'
import { useCurrentTab } from '../hooks/useCurrentTab'
import { InfoIcon } from '../icons/InfoIcon'
import { ClientCampaignDto } from '../model/ClientCampaignDto'
import { useNavigate } from '../navigation/custom-react-router-dom'
import { TextStrong } from '../ui/Typography/Typography'
import { useAccountActionAccessCheck } from '../utils/AccountAccess/accountAccessCheck'
import { isNoTabsPath } from '../utils/path'
import { TradersRoomModalsType, isTradersRoomConfirmationModalOpen } from './TradersRoomModals'

import styles from './TradersRoom.module.scss'

interface TradersRoomTabsProps {
  clientCampaigns?: ClientCampaignDto[]
  clientCampaignsList?: ClientCampaignDto[]
}

export const TradersRoomTabs: React.FC<TradersRoomTabsProps> = (props) => {
  const currentTab = useCurrentTab('traders-room')

  const navigate = useNavigate()
  const location = useLocation()
  const CFDTabs = useCFDTabs(props.clientCampaignsList, props.clientCampaigns)
  const ETDTabs = useETDTabs()

  const { isDefaultCFDProductType } = useProductReadContext()

  const { setActiveModal } = useModalsContext()

  const handleTabChange = (tab: string) => {
    navigate(`/dashboard/traders-room/${tab}`)
  }

  if (isNoTabsPath(location.pathname)) {
    return null
  }

  return (
    <div className={classNames('section', styles.adminRoutes)}>
      <Tabs
        tabs={isDefaultCFDProductType() ? CFDTabs : ETDTabs}
        currentTab={currentTab}
        appearance='light'
        renderEndIcon={(id) => (
          <TradersRoomIcon
            id={id}
            onClick={() => setActiveModal(TradersRoomModalsType.Confirmation)}
          />
        )}
        renderTab={(name, highlight) => <TradersRoomTab name={name} highlight={highlight} />}
        onTabChanged={handleTabChange}
      />
    </div>
  )
}

interface TradersRoomTabProps {
  name: string
  highlight?: boolean
}

const TradersRoomTab: React.FC<TradersRoomTabProps> = (props) => {
  const { name, highlight } = props

  if (highlight) {
    return (
      <TextStrong isParagraph className={styles.highlight}>
        {name}
      </TextStrong>
    )
  }
  return <span>{name}</span>
}

interface TradersRoomIconProps {
  id: string
  onClick: () => void
}

const TradersRoomIcon: React.FC<TradersRoomIconProps> = (props) => {
  const { id, onClick } = props

  if (id === 'add-campaign-account') {
    return (
      <IconButton
        onClick={(event) => {
          event.stopPropagation()
          onClick()
        }}
      >
        <InfoIcon />
      </IconButton>
    )
  }

  return null
}

const useCFDTabs = (
  campaignsList?: ClientCampaignDto[],
  campaigns?: ClientCampaignDto[]
): TabProps[] => {
  const { t } = useTranslation()
  const { luckyDrawCampaign, setShowLuckyDraw } = useLuckyDraw()

  const { accountInfoLimits } = useAccountInfo()
  const { checkAccountTradersRoomAccess } = useAccountActionAccessCheck()
  const navigate = useNavigate()
  const { activeModal } = useModalsContext()

  return [
    {
      name: t('Tabs.Wallets'),
      id: 'wallets',
    },
    {
      name: t('Tabs.Trading Accounts'),
      id: 'trading-accounts',
    },
    {
      name: t('Tabs.Transactions'),
      id: 'transactions',
    },
    {
      name: t('Tabs.Transaction History'),
      id: 'transaction-history',
    },
    {
      name: t('Tabs.Stock Dividends'),
      id: 'stock-dividends',
    },
    {
      name: t('Tabs.Campaign Results'),
      id: 'campaign-results',
      hidden: !campaignsList?.length,
    },
    {
      name: t('Tabs.Lucky Draw'),
      id: 'lucky-draw',
      highlight: true,
      onTabClicked: () => setShowLuckyDraw(true),
      hidden: !luckyDrawCampaign,
    },
    {
      name: t('Tabs.Add Campaign Account'),
      id: 'add-campaign-account',
      disabled: accountInfoLimits?.hasReachedMaxTradingAccounts,
      highlight: true,
      hidden: !campaigns?.length,
      onTabClicked: () => {
        checkAccountTradersRoomAccess(() => {
          if (!isTradersRoomConfirmationModalOpen(activeModal)) {
            navigate(`/dashboard/traders-room/trading-accounts/new?campaign=true`)
          }
        })
      },
    },
  ]
}

const useETDTabs = (): TabProps[] => {
  const { t } = useTranslation()

  return [
    {
      name: t('Tabs.Balances'),
      id: 'balances',
    },
    {
      id: 'transactions',
      name: t('Tabs.Transactions'),
      disabled: false,
    },
    {
      id: 'transaction-history',
      name: t('Tabs.Transaction History'),
      disabled: false,
    },
  ]
}

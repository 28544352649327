import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { ScrollToIds, useScrollToElementIds } from '../../hooks/useScrollToElementIds'
import { useNavigate } from '../../navigation/custom-react-router-dom'
import { PageHeader } from '../../ui/Table/Header/PageHeader'
import { TextH3 } from '../../ui/Typography/Typography'

import styles from './MarketDataPage.module.scss'

export const MarketDataHelpPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { scrollIntoView } = useScrollToElementIds()
  scrollIntoView([ScrollToIds.SubscriptionsMarketDataHeader], {
    skipElementsViewportVisibility: true,
  })

  const handleGoBack = () => {
    navigate('/dashboard/subscriptions/market-data')
  }

  return (
    <>
      <PageHeader
        title={t('Market Data.title')}
        backButton={handleGoBack}
        id={ScrollToIds.SubscriptionsMarketDataHeader}
      />
      <div className={classNames('mt-3', styles.card)}>
        <TextH3>{t('Market Data.Why do I need Market Data')}</TextH3>
        <p className='text mt-4'>
          {t('Market Data.Market Data is what provides you with real-time')}
        </p>
      </div>
    </>
  )
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { CircleWarningIcon } from '../../icons/CircleWarningIcon'
import { TextH3, TextSmall } from '../../ui/Typography/Typography'
import { useScrollToTop } from '../../utils/useScrollToTop'

import styles from './Sumsub.module.scss'

interface Props {
  compact?: boolean
}

export const SumsubThankYouPage: React.FC<Props> = (props) => {
  useScrollToTop()
  const { compact } = props
  const { t } = useTranslation()

  return (
    <div className={styles.thankYouPage}>
      <div className={styles.innerWrapper}>
        <div
          className={classNames(styles.notifyCard, {
            [styles.compact]: compact,
          })}
        >
          <div className={styles.notifyCardTitleBox}>
            <CircleWarningIcon />
            <TextH3>{t('Thank you for registering with Tickmill')}</TextH3>
          </div>
          <TextSmall className={styles.text}>
            {t('The verification process is in progress')}
          </TextSmall>
        </div>
      </div>
    </div>
  )
}

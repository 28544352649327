import { IconProps, iconColor } from './IconProps'

export function HexagonCheckIcon(props: IconProps): JSX.Element {
  return (
    <svg width='57' height='64' viewBox='0 0 57 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0 18.9501C0 16.831 1.15213 14.8795 3.00755 13.8558L25.3106 1.55069C27.06 0.585479 29.1824 0.585478 30.9318 1.55069L53.2349 13.8558C55.0903 14.8795 56.2424 16.831 56.2424 18.9501V45.0499C56.2424 47.169 55.0903 49.1205 53.2349 50.1442L30.9318 62.4493C29.1824 63.4145 27.06 63.4145 25.3106 62.4493L3.00755 50.1442C1.15213 49.1205 0 47.169 0 45.0499V18.9501Z'
        fill='#00B728'
      />
      <path
        d='M40.7081 25.7076L24.7081 41.7076C24.6152 41.8005 24.5049 41.8743 24.3835 41.9246C24.2621 41.975 24.132 42.0009 24.0006 42.0009C23.8691 42.0009 23.739 41.975 23.6176 41.9246C23.4962 41.8743 23.3859 41.8005 23.2931 41.7076L16.2931 34.7076C16.1054 34.5199 16 34.2654 16 34.0001C16 33.7347 16.1054 33.4802 16.2931 33.2926C16.4807 33.1049 16.7352 32.9995 17.0006 32.9995C17.2659 32.9995 17.5204 33.1049 17.7081 33.2926L24.0006 39.5863L39.2931 24.2926C39.4807 24.1049 39.7352 23.9995 40.0006 23.9995C40.2659 23.9995 40.5204 24.1049 40.7081 24.2926C40.8957 24.4802 41.0011 24.7347 41.0011 25.0001C41.0011 25.2654 40.8957 25.5199 40.7081 25.7076Z'
        fill={iconColor(props)}
      />
    </svg>
  )
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { CloseIcon } from '../../icons/CloseIcon'
import { WarningIcon } from '../../icons/WarningIcon'
import { TextSmall, TextSmallStrong } from '../../ui/Typography/Typography'
import IconButton from '../iconButton/IconButton'

import styles from './SystemNotification.module.scss'

interface SystemNotificationProps {
  title: string
  close?(): void
  readMore?: () => void
}

export const SystemNotification = (props: SystemNotificationProps) => {
  return (
    <div className={styles.wrapper}>
      <div className={classNames('container', styles.innerWrapper)}>
        <WarningIcon color='textOnPrimaryColor' />
        <NotificationMessage {...props} />
      </div>
    </div>
  )
}

const NotificationMessage = (props: SystemNotificationProps) => {
  const { title, close, readMore } = props

  const { t } = useTranslation()

  return (
    <div className={styles.message}>
      <TextSmallStrong isParagraph className={styles.title}>
        {title}
        {readMore && (
          <TextSmall className={styles.readMore} onClick={readMore}>
            {t('Notifications.Read more')}
          </TextSmall>
        )}
      </TextSmallStrong>
      {!!close && (
        <IconButton onClick={close}>
          <CloseIcon color='textOnPrimaryColor' />
        </IconButton>
      )}
    </div>
  )
}

import { useNavigate } from '../../navigation/custom-react-router-dom'
import { errorOptions } from '../../routing/RouterToaster'
import { ResponseError, instanceOfAxiosError } from '../../utils/ApiClient'

export const handleBaseTwoFactorErrors = (
  error: unknown,
  navigate: ReturnType<typeof useNavigate>
): void | boolean => {
  if (instanceOfAxiosError((error as ResponseError)?.response)) {
    const errorResponse = (error as ResponseError).response.response?.data
    const cooldownInMinutes = errorResponse?.properties?.cooldownInMinutes

    switch (errorResponse?.code) {
      case errorOptions.two_factor_auth_resend_code_cannot_be_executed:
        const cooldownInSeconds = errorResponse?.properties?.remainingSeconds
        navigate('/profile/security-settings', {
          state: { newCodeCantBeSent: true, cooldownInSeconds },
        })
        return true
      case errorOptions.two_factor_auth_too_many_resend_code_attempts:
        navigate('/profile/security-settings', {
          state: { isTooManyResendAttempts: true, cooldownInMinutes },
        })
        return true
      case errorOptions.two_factor_auth_too_many_insert_resend_code_attempts:
        navigate('/profile/security-settings', {
          state: { isTooManyCodeInsertAttempts: true, cooldownInMinutes },
        })
        break
      case errorOptions.two_factor_authentication_invalid_token:
        navigate('/profile/security-settings')
        break
      case errorOptions.two_factor_authentication_expired_token:
        navigate('/profile/security-settings')
        break
      default:
        return
    }
  }
}

import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'

import '../../layout/site.scss'
import { Referral } from '../../Referral/Referral'
import { LocaleModule } from '../../global/LocaleModule'
import { AccountInfoLimitsContextProvider } from '../../global/context/AccountInfoContext/AccountInfoContext.Provider'
import { EntitySettingsContextProvider } from '../../global/context/EntityContext'
import { LegalLinksProvider } from '../../global/context/LegalLinksContext'
import { LossPercentagesContextProvider } from '../../global/context/LossPercentagesContext'
import { ModalsProvider } from '../../global/context/ModalsContext'
import { ProductContextProvider, useProductWriteContext } from '../../global/context/ProductContext'
import { PublicDomainsContextProvider } from '../../global/context/PublicDomainsContext'
import { SessionSettingsContextProvider } from '../../global/context/SessionSettingsContext'
// import { SessionTimeoutProvider } from '../../global/context/SessionTimeoutContext'
import { SignupContextProvider } from '../../global/context/SignupContext'
import { SnackbarContextProvider } from '../../global/context/SnackbarContext'
import { AllLocales, Locale, detectLocaleClientSide } from '../../global/locale/Locale'
import { detectThemeClientSide } from '../../global/theme/Theme'
import { Toast, ToastContext, isToastTypeDanger } from '../../global/toast/Toast'
import { useGoogleAnalytics } from '../../hooks/useGoogleAnalytics'
import { useLogout } from '../../hooks/useLogout'
import i18n from '../../i18n'
import { AccountDetailedDto } from '../../model/AccountDetailedDto'
import { NotificationType } from '../../model/Notification'
import { AuthUser } from '../../model/User'
import { WalletDto } from '../../model/WalletDto'
import { useAccountReadContext, useAccountWriteContext } from '../../utils/AccountContextContext'
import { ApiClientContext, useApiClient } from '../../utils/ApiClient'
import { AuthSessionContext, MaybeAuthSession, initiateAuthSession } from '../../utils/AuthContext'
import { FirstDepositContextProvider } from '../../utils/FirstDepositContext'
import {
  useIBCampaignsResultsReadContext,
  useIBCampaignsResultsWriteContext,
} from '../../utils/IBCampaignsResultsContext'
import { useMaintenance } from '../../utils/MaintenanceModeContext'
import { PathHistoryProvider } from '../../utils/PathHistoryContext'
import { SupportContactsProvider } from '../../utils/SupportContactsContext'
import { TradingAccountEntityConfigurationProvider } from '../../utils/TradingAccountEntityConfigurationContext'
import { WalletsContextProvider } from '../../utils/UserWalletContext'
import { ClientApiClient } from '../../utils/clientApi'
import {
  entityPerTickmillCompany,
  getRedirectDomain,
  getTickmillCompanyByHostname,
} from '../../utils/companyName.utils'
import { dispatchEntitySelected } from '../../utils/cookie.utils'
import { useHubSpot } from '../../utils/hubSpot'
import { isLoginPage as checkIfLoginPage } from '../../utils/isLoginPage'
import { useHideNotifications } from '../../utils/notifications'
import { isSignUpPath } from '../../utils/path'
import { sleep } from '../../utils/transaction.utils'
// import { sleep } from '../../utils/transaction.utils'
import { useScrollbarWidth } from '../../utils/useScrollbarWidth'
import { useLocallyPersistedState } from '../../utils/useStorage'
import { MainContainer } from '../MainContainer'
import { useErrorHandler } from './ErrorHandler'

interface RoutingProps {
  toast?: Toast
  auth: MaybeAuthSession
  account?: AccountDetailedDto
  urlLocale: Locale
  setCurrentPath: (path: string) => void
}

const Routing = (props: RoutingProps) => {
  const { toast, auth, account, urlLocale, setCurrentPath } = props

  const apiClient = useContext(ApiClientContext)
  const clientApiClient = useApiClient(ClientApiClient)
  const { setMaintenance } = useMaintenance()

  const { changeProductContext } = useProductWriteContext()
  const { errorHandler } = useErrorHandler()
  const location = useLocation()
  const { logout } = useLogout()
  const isLoginPage = checkIfLoginPage(location.pathname)
  const [productHandled, setProductHandled] = useLocallyPersistedState<boolean>(
    'productHandled',
    false
  )
  const [wallets, setWallets] = useState<WalletDto[] | undefined>()
  const removeSession = (a: AuthUser) => {
    logout()
    window.location.replace('https://' + getRedirectDomain(a.tickmillCompany.id))
  }

  useEffect(() => {
    setTimeout(() => {
      if (!auth) {
        return
      }
      if (isLoginPage) {
        return
      }
      if (window.env.APP_ENTITY.includes(entityPerTickmillCompany[auth.tickmillCompany.id])) {
        return
      }
      if (process.env.NODE_ENV === 'development') {
        return
      }

      console.debug('Wrong Entity. Logout!')
      clientApiClient.logOut(auth.id).finally(() => removeSession(auth))
    }, 3000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, isLoginPage])

  useEffect(() => {
    if (!apiClient) {
      return
    }
    apiClient.updateMaintenanceMode = () => setMaintenance()

    apiClient.errorHandler = errorHandler
  }, [apiClient, errorHandler, setMaintenance])

  useHubSpot()
  useGoogleAnalytics()

  useEffect(() => {
    if (!auth || !account) {
      setProductHandled(false)
    }
  }, [auth, account, setProductHandled])

  useEffect(() => {
    const productId = account?.preferredLandingPage?.tickmillProductId
    if (!productHandled && productId) {
      changeProductContext(productId)
      setProductHandled(true)
    }
    return
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, location.pathname])

  const fetchWallets = useCallback(async () => {
    try {
      const fetchedWallets = await clientApiClient.getWallets()
      setWallets(fetchedWallets)
    } catch (e: unknown) {
      console.error('error from wallets endpoint')
    }
  }, [clientApiClient])

  useEffect(() => {
    if (!auth) {
      setWallets([])
    } else if (!wallets?.length && auth) {
      fetchWallets()
    }
  }, [wallets?.length, auth, fetchWallets])

  return (
    <Routes>
      {AllLocales.map((locale) => (
        <Route
          key={locale}
          path={`/${locale}/*`}
          element={
            <WalletsContextProvider wallets={wallets}>
              <MainContainer
                urlLocale={urlLocale}
                toast={toast}
                auth={auth}
                account={account}
                setCurrentPath={setCurrentPath}
              />
            </WalletsContextProvider>
          }
        />
      ))}
    </Routes>
  )
}

export const Session: React.FC = () => {
  useScrollbarWidth()
  const location = useLocation()
  const [applyTheme, setApplyTheme] = useState(false)
  const [currentPath, setCurrentPath] = useState(location.pathname)
  const [auth, setAuth] = useState<MaybeAuthSession>(initiateAuthSession(location))

  const clientApiClient = useApiClient(ClientApiClient)
  const urlParams = new URLSearchParams(location.search)
  const preferredLanguage = urlParams.get('lang')
  const { refreshAccount } = useAccountWriteContext()
  const { refreshIBCampaigns } = useIBCampaignsResultsWriteContext()
  const { ibCampaignsResults } = useIBCampaignsResultsReadContext()
  const { account } = useAccountReadContext()
  const { toast, setToast } = useToast()
  const [pathHistory, setPathHistory] = useState<string[]>([])

  const rootDiv = document.getElementById('root')
  if (rootDiv) {
    rootDiv.setAttribute('dir', i18n.dir())
  }

  // this should be moved to the context above to avoid this way of getting language
  const locale =
    (preferredLanguage
      ? AllLocales.includes(preferredLanguage as Locale)
        ? preferredLanguage
        : null
      : null) ||
    detectLocaleClientSide() ||
    ('en' as Locale)

  useEffect(() => {
    if (urlParams.get('phrase')) {
      i18n.changeLanguage('cimode')
    } else {
      i18n.changeLanguage(locale)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (auth) {
      refreshAccount(locale as Locale)
      // setLiveChatEntity(auth).then(() => {})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth])

  useEffect(() => {
    if (!ibCampaignsResults.length && account?.clientIntroducingBroker?.id) {
      clientApiClient.getIBCampaignsResults().then((campaigns) => {
        refreshIBCampaigns(campaigns.items)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ibCampaignsResults.length, account?.clientIntroducingBroker?.id])

  useEffect(() => {
    setApplyTheme(!isSignUpPath(currentPath))
  }, [currentPath])

  const getUrlLocale = useMemo(() => {
    const urlLocale = window.location.pathname.substring(1, 3) as Locale
    if (AllLocales.includes(urlLocale)) {
      return urlLocale as Locale
    } else {
      return 'en' as Locale
    }
  }, [])

  const trackLastPathsVisited = () => {
    if (pathHistory.length === 0 || pathHistory[pathHistory.length - 1] !== location.pathname) {
      const newPathHistory = [...pathHistory, location.pathname].slice(-2)
      setPathHistory(newPathHistory)
    }
  }

  useEffect(() => {
    trackLastPathsVisited()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  return (
    <SessionSettingsContextProvider
      initialSettings={{
        locale: locale as Locale,
        theme: detectThemeClientSide(),
      }}
      applyTheme={applyTheme}
    >
      <PathHistoryProvider currentPathHistory={pathHistory}>
        <Referral>
          <ToastContext.Provider value={setToast}>
            <AuthSessionContext.Provider value={[auth, setAuth]}>
              <EntitySettingsContextProvider>
                <SupportContactsProvider>
                  <PublicDomainsContextProvider>
                    <ProductContextProvider>
                      <TradingAccountEntityConfigurationProvider>
                        <LossPercentagesContextProvider>
                          <FirstDepositContextProvider auth={auth}>
                            <LegalLinksProvider>
                              <ModalsProvider>
                                <AccountInfoLimitsContextProvider>
                                  <SnackbarContextProvider>
                                    <SignupContextProvider>
                                      {/*<SessionTimeoutProvider>*/}
                                      <LocaleModule />
                                      <Routing
                                        setCurrentPath={setCurrentPath}
                                        urlLocale={getUrlLocale}
                                        toast={toast}
                                        auth={auth}
                                        account={account}
                                      />
                                      {/*</SessionTimeoutProvider>*/}
                                    </SignupContextProvider>
                                  </SnackbarContextProvider>
                                </AccountInfoLimitsContextProvider>
                              </ModalsProvider>
                            </LegalLinksProvider>
                          </FirstDepositContextProvider>
                        </LossPercentagesContextProvider>
                      </TradingAccountEntityConfigurationProvider>
                    </ProductContextProvider>
                  </PublicDomainsContextProvider>
                </SupportContactsProvider>
              </EntitySettingsContextProvider>
            </AuthSessionContext.Provider>
          </ToastContext.Provider>
        </Referral>
      </PathHistoryProvider>
    </SessionSettingsContextProvider>
  )
}

const useToast = () => {
  const location = useLocation()
  const ToastErrorTypeVisibilityInSeconds = 15
  const ToastOtherTypeVisibilityInSeconds = 5

  const [toast, setToast] = useState<Toast>()
  const { hideNotifications } = useHideNotifications()

  useEffect(() => {
    if (toast && !toast.requireInteraction) {
      // clear toast after 'n' seconds
      hideNotifications([NotificationType.Promotional, NotificationType.General])

      const timeOutSeconds = isToastTypeDanger(toast.type)
        ? ToastErrorTypeVisibilityInSeconds * 1000
        : ToastOtherTypeVisibilityInSeconds * 1000

      const timeout = setTimeout(() => setToast(undefined), timeOutSeconds)
      return () => clearTimeout(timeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toast, setToast])

  useEffect(() => {
    if (toast && isToastTypeDanger(toast.type)) {
      setToast(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  return { toast, setToast }
}

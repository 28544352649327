import React from 'react'
import classNames from 'classnames'

import { InfoIconWrapper } from '../../global/InfoIconWrapper/InfoIconWrapper'
import { TextH3 } from '../../ui/Typography/Typography'
import { isFunction } from '../../utils/isFunction'

import styles from './AccountSettingsPage.module.scss'

type RenderTitleType = React.ReactNode | (() => React.ReactNode)

interface Props {
  children: React.ReactNode
  title?: string
  icon?: JSX.Element
  infoToggle?: () => void
  isList?: boolean
  className?: string
  renderTitle?: RenderTitleType
}

const Title = ({ renderTitle }: { renderTitle: RenderTitleType }) => {
  if (renderTitle && isFunction(renderTitle)) {
    return <>{renderTitle()}</>
  }

  if (renderTitle) {
    return <>{renderTitle}</>
  }
}

export const AccountSettingsBox = (props: Props): JSX.Element => {
  const {
    children,
    title,
    icon,
    infoToggle,
    isList,
    className,
    renderTitle = <TextH3>{title}</TextH3>,
  } = props

  return (
    <div className={classNames(styles.box, className)}>
      <div className={styles.boxTitle}>
        {icon}
        <Title renderTitle={renderTitle} />
        {infoToggle && <InfoIconWrapper onClick={infoToggle} className={styles.infoIconWrapper} />}
      </div>
      {isList ? <ul className={styles.boxList}>{children}</ul> : children}
    </div>
  )
}

import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useSessionLanguage } from '../global/context/SessionSettingsContext'
import { PromoOptionCategoryEnum } from '../model/PromoOptionCategoryEnum'
import { RouteConfig } from '../routing/RenderRoutes'
import { useAccountReadContext } from '../utils/AccountContextContext'
import { DateFilterContextProvider } from '../utils/DateFilterContext'
import { isTickmillPartner } from '../utils/companyName.utils'
import { useIBPrograms } from '../utils/useIBPrograms'
import { IBClientsPage } from './Clients/IBClientsPage'
import { IBContestPage } from './Contest/IBContestPage'
import { IBDashboardPage } from './Dashboard/IBDashboardPage'
import { IBIncomePage } from './Income/IBIncomePage'
import { IntroducingBroker } from './IntroducingBroker'
import { LegalDocumentsPage } from './LegalDocuments/LegalDocumentsPage'
import { LoyaltyPage } from './Loyalty/LoyaltyPage'
import { MultiTierPage } from './MultiTier/MultiTierPage'
import { LinksPage } from './Referrals/Links/LinksPage'
import { PromoMaterialsPage } from './Referrals/PromoMaterials/PromoMaterialsPage'
import { QRCodePage } from './Referrals/QRCode/QRCodePage'
import { ReferralMaterialsPage } from './Referrals/ReferralMaterialsPage'
import { ReportsPage } from './Reports/ReportsPage'
import { IntroducingBrokerTransactionHistoryPage } from './TransactionHistory/IntroducingBrokerTransactionHistoryPage'
import { IBWalletTransferPage } from './Wallets/IBWalletTransferPage'
import { IBWalletWithdrawPage } from './Wallets/IBWalletWithdrawPage'
import { IBWalletWithdrawTransactionCompletedPage } from './Wallets/IBWalletWithdrawTransactionCompletedPage'
import { IBWalletWithdrawTransactionFailedPage } from './Wallets/IBWalletWithdrawTransactionFailedPage'
import { IBWalletWithdrawTransactionSuccessPage } from './Wallets/IBWalletWithdrawTransactionSuccessPage'
import { IBWalletsPage } from './Wallets/IBWalletsPage'

export const useIBRoutes = (): RouteConfig[] => {
  const visible = useIBRoutesConditions()
  const { t } = useTranslation()
  const locale = useSessionLanguage()
  return useMemo(
    (): RouteConfig[] => [
      {
        path: 'introducing-broker',
        outlet: <IntroducingBroker />,
        condition: visible.isIBVisible,
        children: [
          {
            path: 'dashboard',
            element: <IBDashboardPage />,
          },
          {
            path: 'wallets',
            element: <IBWalletsPage />,
            children: [
              {
                path: ':walletId',
                children: [
                  {
                    path: 'withdrawal',
                    element: <IBWalletWithdrawPage />,
                    children: [
                      {
                        path: 'transaction',
                        children: [
                          {
                            path: 'succeed',
                            element: <IBWalletWithdrawTransactionSuccessPage />,
                          },
                          {
                            path: 'completed',
                            element: <IBWalletWithdrawTransactionCompletedPage />,
                          },
                          {
                            path: 'failed',
                            element: <IBWalletWithdrawTransactionFailedPage />,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: 'transfer',
                    element: <IBWalletTransferPage />,
                  },
                ],
              },
            ],
          },
          {
            path: 'clients',
            element: (
              <DateFilterContextProvider>
                <IBClientsPage />
              </DateFilterContextProvider>
            ),
          },
          {
            path: 'legal-documents',
            element: <LegalDocumentsPage />,
          },
          {
            path: 'income',
            element: (
              <DateFilterContextProvider>
                <IBIncomePage />
              </DateFilterContextProvider>
            ),
          },
          {
            path: 'transaction-history',
            element: (
              <DateFilterContextProvider>
                <IntroducingBrokerTransactionHistoryPage />
              </DateFilterContextProvider>
            ),
          },
          {
            path: 'ib-contest',
            element: (
              <DateFilterContextProvider>
                <IBContestPage />
              </DateFilterContextProvider>
            ),
          },
          {
            path: 'loyalty-program',
            element: <LoyaltyPage />,
          },
          {
            path: 'reports',
            element: <ReportsPage />,
          },
          {
            path: 'referral-materials',
            element: <ReferralMaterialsPage />,
            condition: visible.isReferralMaterialsVisible,
            children: [
              {
                path: 'links',
                element: <LinksPage />,
              },
              {
                path: 'logos',
                element: (
                  <PromoMaterialsPage
                    promoCategoryType={PromoOptionCategoryEnum.LOGO}
                    name={t('Referral Materials.Logo')}
                  />
                ),
              },
              {
                path: 'banners',
                element: (
                  <PromoMaterialsPage
                    promoCategoryType={PromoOptionCategoryEnum.BANNER}
                    name={t('Referral Materials.Banners')}
                  />
                ),
              },
              {
                path: 'qr-code',
                element: <QRCodePage />,
              },
            ],
          },
          {
            path: 'multi-tier',
            element: <MultiTierPage />,
            condition: visible.isMultiTierVisible,
          },
        ],
      },
    ],
    [visible.isIBVisible, visible.isReferralMaterialsVisible, visible.isMultiTierVisible, locale]
  )
}

interface RoutesConditions {
  isIBVisible: boolean
  isMultiTierVisible: boolean
  isReferralMaterialsVisible: boolean
}

export const useIBRoutesConditions = (): RoutesConditions => {
  const { account } = useAccountReadContext()

  const { multiTierProgram } = useIBPrograms()

  const isIBVisible = useMemo(() => !!account?.visibilityConfiguration.hasIBRoom, [account])

  const isMultiTierVisible = useMemo(
    () => !!account?.visibilityConfiguration.hasMultiTier && multiTierProgram,
    [account?.visibilityConfiguration.hasMultiTier, multiTierProgram]
  )

  const isReferralMaterialsVisible = useMemo(() => !isTickmillPartner(account), [account])

  return {
    isIBVisible,
    isMultiTierVisible,
    isReferralMaterialsVisible,
  }
}

import { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { addMilliseconds, differenceInMilliseconds, formatISO, isAfter, parseISO } from 'date-fns'

import { MultiLevelCommissionDetailDto } from '../../../model/MultiLevelCommission'
import { formatDate } from '../../../utils/date.utils'
import {
  ProgressBarsBlock,
  ProgressBarsBlockItem,
  ProgressBarsSectionItem,
} from '../components/ProgressBarsBlock/ProgressBarsBlock'

interface ProgressBarsProps {
  multiLevelData?: MultiLevelCommissionDetailDto
}

interface TimeRange {
  start: string
  end: string
}

const DATE_FORMAT = 'dd.MM.yy'

export const ProgressBars: FC<ProgressBarsProps> = (props) => {
  const { multiLevelData } = props

  const { t } = useTranslation()

  const getClientLabel = useCallback(
    (amount?: number): string => {
      if (!amount) {
        return ''
      }

      return amount === 1
        ? `${amount} ${t('IB.Dashboard.Client')}`
        : `${amount} ${t('IB.Dashboard.Clients')}`
    },
    [t]
  )

  const getFormattedTimeRange = useCallback(
    (startDate?: string, endDate?: string): string | undefined => {
      if (!startDate || !endDate) {
        return undefined
      }

      return `${formatDate(startDate, { formatType: DATE_FORMAT, withTime: false })} — ${formatDate(
        endDate,
        {
          formatType: DATE_FORMAT,
          withTime: false,
        }
      )}`
    },
    []
  )

  const equalTimeRanges = useMemo<TimeRange[]>(() => {
    if (!multiLevelData) {
      return []
    }

    const startDate = parseISO(multiLevelData.periodStartDate)
    const endDate = parseISO(multiLevelData.periodEndDate)

    const totalDuration = differenceInMilliseconds(endDate, startDate)

    const segmentDuration = totalDuration / 3

    return [
      {
        start: formatISO(startDate),
        end: formatISO(addMilliseconds(startDate, segmentDuration)),
      },
      {
        start: formatISO(addMilliseconds(startDate, segmentDuration)),
        end: formatISO(addMilliseconds(startDate, 2 * segmentDuration)),
      },
      {
        start: formatISO(addMilliseconds(startDate, 2 * segmentDuration)),
        end: formatISO(endDate),
      },
    ]
  }, [multiLevelData])

  const items = useMemo<ProgressBarsBlockItem[]>(() => {
    const clientsPercent =
      ((multiLevelData?.clients.current || 0) / (multiLevelData?.clients.required || 0)) * 100
    const volumeInMillionUsdPercent =
      ((multiLevelData?.volumeInMillionUsd.current || 0) /
        (multiLevelData?.volumeInMillionUsd.required || 0)) *
      100

    return [
      {
        title: t('IB.Dashboard.Clients'),
        label: getClientLabel(multiLevelData?.clients.required),
        tooltip: getClientLabel(multiLevelData?.clients.current),
        sections: [
          {
            percent: clientsPercent,
          },
        ],
      },
      {
        title: t('IB.Dashboard.Volume'),
        label: multiLevelData?.volumeInMillionUsd.required
          ? `${multiLevelData.volumeInMillionUsd.required} M`
          : undefined,
        tooltip: multiLevelData?.volumeInMillionUsd.current
          ? `${multiLevelData.volumeInMillionUsd.current} M`
          : undefined,
        sections: [
          {
            percent: volumeInMillionUsdPercent,
          },
        ],
      },
      {
        title: t('IB.Dashboard.Time'),
        tooltip: getFormattedTimeRange(
          multiLevelData?.periodStartDate,
          multiLevelData?.periodEndDate
        ),
        sections: equalTimeRanges.map<ProgressBarsSectionItem>((timeRange) => {
          const totalPeriod = differenceInMilliseconds(
            parseISO(timeRange.end),
            parseISO(timeRange.start)
          )
          const percent =
            (differenceInMilliseconds(new Date(), parseISO(timeRange.start)) / totalPeriod) * 100
          return {
            label: getFormattedTimeRange(timeRange.start, timeRange.end),
            percent: percent,
          }
        }),
      },
    ]
  }, [t, multiLevelData, getClientLabel, getFormattedTimeRange, equalTimeRanges])

  return <ProgressBarsBlock items={items} color='blue' />
}

import React from 'react'

import { useNavigate } from '../../../../navigation/custom-react-router-dom'
import { WalletDepositTransactionCompletedPage } from '../../../Wallets/WalletDeposit/WalletDepositTransactionPage/WalletDepositTransactionCompletedPage'

export const TradersRoomTradingAccountDepositTransactionCompletedPage: React.FC = () => {
  const navigate = useNavigate()

  const handleCancel = () => {
    navigate('/dashboard/traders-room/transaction-history')
  }

  return <WalletDepositTransactionCompletedPage onCancel={handleCancel} />
}

import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { ConfettiIcon } from '../../../icons/ConfettiIcon'
import { LevelFireIcon } from '../../../icons/LevelFireIcon'
import { MultiLevelCommissionDetailDto } from '../../../model/MultiLevelCommission'
import { Text, TextH4, TextSmallStrong, TextStrong } from '../../../ui/Typography/Typography'
import { iconByLevel } from '../constants/multiLevelCommission'

import styles from './NewLevelBanner.module.scss'

interface NewLevelBannerProps {
  multiLevelData: MultiLevelCommissionDetailDto
  onHideClick: () => void
}

export const NewLevelBanner: FC<NewLevelBannerProps> = (props) => {
  const { multiLevelData, onHideClick } = props
  const { levels } = multiLevelData
  const currentLevel = levels.current.id
  const hasReachedMaxLevel = currentLevel === levels.final.id

  const Icon = iconByLevel[currentLevel]

  const { t } = useTranslation()

  return (
    <div
      className={classNames(styles.newLevelBanner, {
        [styles[`level${currentLevel}`]]: currentLevel,
        [styles.maxLevel]: hasReachedMaxLevel,
      })}
    >
      <ConfettiIcon className={styles.confetti} width={145} />
      <Icon size={hasReachedMaxLevel ? 66 : 75} />
      <div className={styles.inner}>
        <div className={styles.textWrapper}>
          <TextH4 className={styles.title}>{t('IB.Dashboard.Congratulations! 🎉')}</TextH4>
          <div>
            {hasReachedMaxLevel ? (
              <Text className={styles.text}>{t('IB.Dashboard.You reached the maximum level')}</Text>
            ) : (
              <>
                <TextStrong className={styles.text}>
                  {t('IB.Dashboard.New level unlocked!')}
                </TextStrong>{' '}
                <Text className={styles.text}>
                  {t('IB.Dashboard.Keep pushing — the best is yet to come! 💪 🚀')}
                </Text>
              </>
            )}
          </div>
        </div>
        {hasReachedMaxLevel ? (
          <LevelFireIcon size={55} className={styles.maxLevelIcon} />
        ) : (
          <button
            className={styles.hideButton}
            onClick={() => multiLevelData.canHideNotification && onHideClick()}
          >
            <TextSmallStrong>{t('IB.Dashboard.HIDE')}</TextSmallStrong>
          </button>
        )}
      </div>
    </div>
  )
}

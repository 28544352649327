import { useTranslation } from 'react-i18next'

import { getDocUploadFailMessage } from '../../Profile/DocumentManagementUpload/documentManagementUploadStatuses'
import { Text } from '../../ui/Typography/Typography'
import { InformationModal } from './InformationModal'
import { Modal } from './Modal'

interface DocumentErrorModalProps {
  isOpen?: boolean
  onClose: () => void
  message?: string
}

export const DocumentErrorModal: React.FC<DocumentErrorModalProps> = (props) => {
  const { onClose, message, isOpen } = props

  const { t } = useTranslation()

  if (!isOpen) {
    return null
  }

  return (
    <Modal
      closeModal={onClose}
      render={() => (
        <InformationModal onCancel={onClose} onCancelText={t('Got It')} title={t('Upload failed')}>
          <Text isParagraph>{t(getDocUploadFailMessage(message))}</Text>
        </InformationModal>
      )}
    />
  )
}

import { ReactNode, createContext, useContext, useEffect, useMemo, useRef } from 'react'
import { useLocation } from 'react-router-dom'

import {
  TickmillProductType,
  getTickmillProductTypeName,
  isTickmillProductTypeCFD,
} from '../../model/TickmillProductType'
import { useAccountReadContext } from '../../utils/AccountContextContext'
import { AuthSessionContext } from '../../utils/AuthContext'
import { TickmillCompaniesEnum } from '../../utils/companyName.utils'
import { useLocallyPersistedState } from '../../utils/useStorage'
import { useGuardedContext } from '../useGuardedContext'
import { useSessionEntity } from './EntityContext'

interface ChangeProductOptions {
  skipClientSideDataProductUpdate?: boolean
}

interface ProductReadContextImpl {
  product: TickmillProductType
  isDefaultCFDProductType(): boolean
}

interface ProductWriteContextImpl {
  changeProductContext: (
    product?: TickmillProductType,
    options?: ChangeProductOptions
  ) => TickmillProductType
}

const ProductReadContext = createContext<ProductReadContextImpl | undefined>(undefined)
ProductReadContext.displayName = 'ProductReadContext'

const ProductWriteContext = createContext<ProductWriteContextImpl | undefined>(undefined)
ProductWriteContext.displayName = 'ProductWriteContext'

export function useProductReadContext(): ProductReadContextImpl {
  return useGuardedContext(ProductReadContext)
}

export function useProductWriteContext(): ProductWriteContextImpl {
  return useGuardedContext(ProductWriteContext)
}

interface Props {
  children: ReactNode
}

export const ProductKey = 'product'

export function ProductContextProvider({ children }: Props): JSX.Element {
  const entity = useSessionEntity()
  const { account } = useAccountReadContext()
  const [auth] = useContext(AuthSessionContext)
  const [productState, setProductState] = useLocallyPersistedState(
    ProductKey,
    TickmillProductType.CFD
  )
  const hasInitializedProductState = useRef(false)
  const location = useLocation()
  const inSignUpPath = location.pathname.includes('sign-up')

  const readContext = useMemo<ProductReadContextImpl>(
    () => ({
      product: productState,
      isDefaultCFDProductType: () => productState === TickmillProductType.CFD,
    }),
    [productState]
  )

  useEffect(() => {
    if (!auth) {
      removeProductClientSide()
      hasInitializedProductState.current = false
      return
    }

    if (inSignUpPath) {
      removeProductClientSide()
      hasInitializedProductState.current = false
      return
    }

    if (!hasInitializedProductState.current) {
      hasInitializedProductState.current = true
      applyProductClientSide(productState)
      return
    }

    applyProductClientSide(productState)
  }, [auth, productState, inSignUpPath])

  const writeContext = useMemo<ProductWriteContextImpl>(() => {
    return {
      changeProductContext(p) {
        if (entity === TickmillCompaniesEnum.TICKMILL_UK) {
          let newProductState = productState

          if (p) {
            newProductState = p
          } else if (productState === TickmillProductType.CFD) {
            newProductState = TickmillProductType.ETD
          } else {
            newProductState = TickmillProductType.CFD
          }
          setProductState(newProductState)
          applyProductClientSide(newProductState)
          return newProductState
        }
        return productState
      },
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productState, entity])

  return (
    <ProductReadContext.Provider value={readContext}>
      <ProductWriteContext.Provider value={writeContext}>{children}</ProductWriteContext.Provider>
    </ProductReadContext.Provider>
  )
}

const applyProductClientSide = (product: TickmillProductType): void => {
  if (isTickmillProductTypeCFD(product)) {
    document.body.setAttribute(
      'data-product',
      getTickmillProductTypeName(TickmillProductType.CFD).toLowerCase()
    )
  } else {
    document.body.setAttribute(
      'data-product',
      getTickmillProductTypeName(TickmillProductType.ETD).toLowerCase()
    )
  }
}

const removeProductClientSide = (): void => {
  if (document.body.hasAttribute('data-product')) {
    document.body.removeAttribute('data-product')
  }
}

import { FC } from 'react'

import { ProgressLevelDto } from '../../../model/MultiLevelCommission'
import { StepItem, Steps } from '../components/Steps/Steps'
import { iconByLevel } from '../constants/multiLevelCommission'

export interface LevelsProps {
  levels: ProgressLevelDto
}

export const Levels: FC<LevelsProps> = (props) => {
  const { levels } = props

  const isBeforeLastLevel = levels.next?.id === levels.final?.id

  const firstStep: StepItem | undefined = levels.current
    ? {
        icon: iconByLevel[levels.current.id],
      }
    : undefined

  const nextStep: StepItem | undefined =
    levels.next && !isBeforeLastLevel
      ? {
          icon: iconByLevel[levels.next.id],
        }
      : undefined

  const finalStep: StepItem | undefined = levels.final
    ? {
        icon: iconByLevel[levels.final.id],
      }
    : undefined

  return <Steps firstStep={firstStep} nextStep={nextStep} finalStep={finalStep} />
}

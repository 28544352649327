import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { NotificationWarning } from '../../global/notification/notification'
import { ScrollToIds, useScrollToElementIds } from '../../hooks/useScrollToElementIds'
import { useNavigate } from '../../navigation/custom-react-router-dom'
import { PageHeader } from '../../ui/Table/Header/PageHeader'
import { useAccountActionAccessCheck } from '../../utils/AccountAccess/accountAccessCheck'
import { useApiClient } from '../../utils/ApiClient'
import { ClientApiClient } from '../../utils/clientApi'
import { useWindowResize } from '../../utils/domUtils'
import { useFetchOne } from '../../utils/useFetch'
import { useScrollToTop } from '../../utils/useScrollToTop'
import { BankAccountsCard } from './BankAccountsCard'
import { BankAccountsTable } from './BankAccountsTable'

import styles from './BankAccountsPage.module.scss'

const useBankAccounts = () => {
  const apiClient = useApiClient(ClientApiClient)

  const callback = useCallback(() => {
    return apiClient.getBankAccounts()
  }, [apiClient])

  const { data } = useFetchOne(callback)

  return { data }
}

export const BankAccountsPage: React.FC = () => {
  useScrollToTop()
  const isMobile = useWindowResize()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { scrollIntoView } = useScrollToElementIds()
  scrollIntoView([ScrollToIds.ProfileBankAccountsHeader])
  const { data } = useBankAccounts()

  const { checkAccountTradersRoomAccess } = useAccountActionAccessCheck()

  return (
    <React.Fragment>
      <NotificationWarning
        wrapperClassName={styles.notificationWrapper}
        title={t('Notifications.Note')}
        subtitle={
          <p className={styles.notificationSubtitle}>
            {t(
              'Profile.Only upload your bank details for International Bank Deposits and Withdrawals'
            )}
          </p>
        }
      />
      <PageHeader
        id={ScrollToIds.ProfileBankAccountsHeader}
        title={t('Bank Accounts')}
        actions={[
          {
            onClick: () =>
              checkAccountTradersRoomAccess(() => navigate('/profile/bank-accounts/new')),
            label: `+ ${t('Add New')}`,
            size: 'S',
          },
        ]}
      />
      {isMobile ? (
        <BankAccountsCard bankAccounts={data} />
      ) : (
        <BankAccountsTable bankAccounts={data} />
      )}
    </React.Fragment>
  )
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

import { Button } from '../../../global/button/Button'
import { useFormatNumber } from '../../../hooks/useFormatNumber'
import { NameDto } from '../../../model/NameDto'
import { CurrencyType } from '../../../model/WalletDto'
import { Text } from '../../../ui/Typography/Typography'
import { dispatchOpenChat } from '../../../utils/cookie.utils'

import styles from './TransferFromModal.module.scss'

export interface BalanceProps {
  platformType?: NameDto
  accountType?: NameDto
  balance: number
  currencyId: CurrencyType
  isDisabled: boolean
  freeMargin?: number
  isWallet?: boolean
  isTransferFrom?: boolean
  isTransferTo?: boolean
}

export const Balance: React.FC<BalanceProps> = (props) => {
  const { balance, platformType, accountType, currencyId, freeMargin } = props
  const { isDisabled, isWallet, isTransferFrom, isTransferTo } = props

  const { t } = useTranslation()
  const { formatMoney } = useFormatNumber()

  return (
    <div className={styles.balance}>
      {platformType && accountType && (
        <Text isParagraph>
          {platformType?.name} / {accountType?.name}
        </Text>
      )}
      <Text isParagraph>
        {t('Wallet.Balance')}: {formatMoney(balance, currencyId)}
      </Text>
      {freeMargin !== undefined && (
        <Text isParagraph>
          {t('Wallet.Available')}: {formatMoney(freeMargin, currencyId)}
        </Text>
      )}
      {isDisabled && (
        <>
          <Text isParagraph className={styles.primaryText}>
            {getRestrictionMessage(isTransferFrom, isTransferTo, isWallet, t)}
          </Text>
          <div>
            {t('Please')}{' '}
            <Button onClick={dispatchOpenChat} appearance='link'>
              {t('Contact Support').toLowerCase()}
            </Button>{' '}
            {t('for more info.')}
          </div>
        </>
      )}
    </div>
  )
}

export const getRestrictionMessage = (
  isTransferFrom = false,
  isTransferTo = false,
  isWallet = false,
  t: TFunction
): string | undefined => {
  if (isWallet) {
    if (isTransferFrom) {
      return t('Transfer restricted from.TM Wallet')
    } else if (isTransferTo) {
      return t('Transfer restricted to.TM Wallet')
    }
  } else {
    if (isTransferFrom) {
      return t('Transfer restricted from.TM Trading Account')
    } else if (isTransferTo) {
      return t('Transfer restricted to.TM Trading Account')
    }
  }
}

import { IconProps } from './IconProps'

export function MT4Logo(props: IconProps): JSX.Element {
  const { size = 24 } = props

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_2400_45235)'>
        <path
          d='M20.0063 32C25.3082 32 29.6063 27.7019 29.6063 22.4C29.6063 17.0981 25.3082 12.8 20.0063 12.8C14.7043 12.8 10.4062 17.0981 10.4062 22.4C10.4062 27.7019 14.7043 32 20.0063 32Z'
          fill='white'
        />
        <path
          d='M16.0059 25.1819V23.841L19.9559 17.6001H21.074V19.5092H20.3922L17.7331 23.7228V23.7956H23.2468V25.1819H16.0059ZM20.4468 26.9092V24.7728L20.465 24.1728V17.6001H22.0559V26.9092H20.4468Z'
          fill='#E2A508'
        />
        <path
          d='M20.0302 9.696C22.1907 9.696 23.9422 7.52548 23.9422 4.848C23.9422 2.17052 22.1907 0 20.0302 0C17.8696 0 16.1182 2.17052 16.1182 4.848C16.1182 7.52548 17.8696 9.696 20.0302 9.696Z'
          fill='#3F9029'
        />
        <path
          d='M26.2535 13.6733L26.254 13.6707C26.4908 13.0362 26.681 12.3856 26.8233 11.724C26.8793 11.4631 27.1421 11.3633 27.269 11.5981C27.6801 12.3583 27.9609 13.955 27.9469 14.9738C27.9454 15.083 27.9842 15.1886 28.0578 15.2692C29.4883 16.8336 30.4249 18.7762 30.7641 20.8533C30.8266 21.236 31.2749 21.3307 31.41 20.9672C32.8123 17.1928 34.5466 6.59355 25.1753 3.38193C24.9078 3.29023 24.6332 3.50679 24.6454 3.78936C24.8009 7.36719 23.234 9.09821 21.9077 9.91859C20.8322 10.5839 19.4726 10.6088 18.3525 10.0214C16.9007 9.25998 15.1257 7.56864 15.309 3.86917C15.3225 3.5982 15.0755 3.37982 14.8132 3.44901C8.40232 5.13999 7.41567 10.8887 7.46981 13.6829C7.47547 13.9745 7.79126 14.1403 8.05173 14.009C9.43134 13.3133 10.929 12.879 12.4687 12.7292C12.6446 12.7121 12.7894 12.5849 12.842 12.4162C12.8811 12.2912 12.927 12.1686 12.9796 12.0491C13.1175 11.7358 13.5279 11.8763 13.5441 12.2182C13.5545 12.4377 13.7485 12.5914 13.967 12.5692C14.4378 12.5214 15.1735 12.4621 16.0597 12.4265C16.1031 12.4248 16.1462 12.416 16.1869 12.4008C17.8325 11.7858 19.6029 11.578 21.3468 11.7957C23.1075 12.0154 24.7878 12.6622 26.2415 13.6795C26.2465 13.6831 26.2535 13.6795 26.2535 13.6733Z'
          fill='#3F9029'
        />
        <path
          d='M39.573 33.6311C40.6536 31.7602 39.65 29.1579 37.3315 27.8188C35.013 26.4796 32.2575 26.9107 31.1769 28.7816C30.0963 30.6525 31.0998 33.2547 33.4183 34.5939C35.7369 35.933 38.4924 35.502 39.573 33.6311Z'
          fill='#076BA0'
        />
        <path
          d='M30.0154 30.2716C29.9797 29.0096 30.6363 27.822 31.7036 27.1476C33.0874 26.2733 35.437 25.5868 38.5435 27.6087C38.7708 27.7566 39.0837 27.6532 39.1562 27.3919C40.9294 20.9946 36.4606 17.2886 34.0086 15.9169C33.7512 15.7729 33.4453 15.9664 33.431 16.261C33.3559 17.8054 32.9907 19.3229 32.3534 20.7339C32.2815 20.8931 32.3179 21.0796 32.4351 21.2091C32.5249 21.3083 32.609 21.4124 32.687 21.5207C32.893 21.8071 32.5803 22.099 32.2684 21.9341C32.0672 21.8277 31.8176 21.9171 31.7244 22.1247C31.5382 22.5397 31.2419 23.1676 30.8547 23.9121C30.8328 23.9542 30.8185 24 30.8122 24.047C30.5754 25.812 29.9102 27.4927 28.8742 28.9421C27.9253 30.2695 26.6922 31.3654 25.2673 32.1513C25.0372 32.2783 24.975 32.5864 25.1549 32.778C25.436 33.0775 25.7298 33.3646 26.0357 33.6386C26.2361 33.8181 26.1895 34.0963 25.9207 34.0862C25.1661 34.0578 23.915 33.6284 23.0084 33.175C22.9185 33.13 22.8156 33.1187 22.7182 33.1434C20.7221 33.6496 18.6329 33.5816 16.6878 32.9609C16.2986 32.8367 15.9642 33.2626 16.2396 33.5643C19.2446 36.8555 27.0049 42.5249 34.044 36.4006C34.2571 36.2151 34.2069 35.8699 33.9566 35.7388C30.7719 34.0695 30.0597 31.8348 30.0154 30.2716Z'
          fill='#076BA0'
        />
        <path
          d='M8.80583 28.7518C7.71783 26.8798 4.96583 26.4558 2.64583 27.7918C0.32583 29.1278 -0.64217 31.7358 0.44583 33.5998C1.53383 35.4638 4.28583 35.9038 6.59783 34.5598C8.90983 33.2158 9.90983 30.6238 8.80583 28.7518Z'
          fill='#D3AE0E'
        />
        <path
          d='M9.10187 22.6C9.10187 22.3697 9.10187 22.1491 9.11409 21.9248C9.12903 21.6507 8.89199 21.4154 8.6233 21.4717C8.13145 21.575 7.6457 21.7054 7.16827 21.8623C6.91399 21.946 6.69477 21.7684 6.83426 21.54C7.30375 20.7711 8.62494 19.6725 9.51716 19.2039C9.61406 19.1531 9.68925 19.0692 9.72574 18.966C10.3302 17.257 11.3529 15.7256 12.7019 14.512C12.9718 14.2272 13.26 13.9607 13.5643 13.714C13.8352 13.4945 13.7455 13.1481 13.3993 13.1898C9.90668 13.6101 -1.21097 17.2842 0.820091 27.417C0.875596 27.6939 1.20046 27.8227 1.43834 27.6704C4.45607 25.7392 6.73874 26.231 8.11169 26.9693C9.22263 27.5667 9.92202 28.7274 9.97951 29.9875C10.0547 31.6354 9.48905 34.0437 6.17931 35.759C5.93887 35.8836 5.87418 36.2062 6.06522 36.3982C10.719 41.0742 16.1829 39.0683 18.5815 37.6221C18.833 37.4705 18.8165 37.1112 18.5693 36.9528C17.2645 36.1166 16.1282 35.041 15.2211 33.782C15.1197 33.6413 14.9416 33.5795 14.772 33.6155C14.6495 33.6416 14.5259 33.6614 14.4017 33.6751C14.0407 33.7146 13.9335 33.2856 14.2357 33.0841C14.4275 32.9563 14.4733 32.6922 14.3398 32.5043C14.1417 32.2254 13.8717 31.8349 13.5647 31.3779C13.539 31.3397 13.5068 31.3061 13.4699 31.2785C12.1288 30.2765 11.0368 28.9784 10.2791 27.485C9.51089 25.9708 9.10773 24.2979 9.10187 22.6Z'
          fill='#D3AE0E'
        />
      </g>
      <defs>
        <clipPath id='clip0_2400_45235'>
          <rect width={size} height={size} fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

import React from 'react'
import { useTranslation } from 'react-i18next'

import { AppStoreIcon } from '../../icons/AppStoreIcon'
import { GooglePlayIcon } from '../../icons/GooglePlayIcon'
import { NameDto } from '../../model/NameDto'
import { PlatformInfoDto, PlatformInfoPlatformLinkDto } from '../../model/PlatformInfoDto'
import {
  OperatingSystemEnum,
  detectAndroidOperatingSystem,
  detectIOSOperatingSystem,
  isAndroidOperatingSystem,
  isIOSOperatingSystem,
  isTMTPlatformType,
  isWebTraderOperatingSystem,
} from '../../model/PlatformTypeEnum'
import { useWindowResize } from '../../utils/domUtils'
import { DownloadAPKIcon } from '../images/TickmillTrader/Icons/DownloadAPKIcon'
import aboutDesktop from '../images/TickmillTrader/Images/aboutDesktop.webp'
import aboutMobile from '../images/TickmillTrader/Images/aboutMobile.webp'
import buysellDesktop from '../images/TickmillTrader/Images/buysellDesktop.webp'
import buysellMobile from '../images/TickmillTrader/Images/buysellMobile.webp'
import chartDesktop from '../images/TickmillTrader/Images/chartDesktop.webp'
import chartMobile from '../images/TickmillTrader/Images/chartMobile.webp'
import managementDesktop from '../images/TickmillTrader/Images/managementDesktop.webp'
import managementMobile from '../images/TickmillTrader/Images/managementMobile.webp'
import tmtQRCode from '../images/TickmillTrader/Images/tmtQRCode.webp'
import watchlistDesktop from '../images/TickmillTrader/Images/watchlistDesktop.webp'
import watchlistMobile from '../images/TickmillTrader/Images/watchlistMobile.webp'
import webtraderBuySellDesktop from '../images/TickmillTrader/Images/webtraderBuySellDesktop.webp'
import webtraderBuySellMobile from '../images/TickmillTrader/Images/webtraderBuySellMobile.webp'
import webtraderChartsDesktop from '../images/TickmillTrader/Images/webtraderChartsDesktop.webp'
import webtraderChartsMobile from '../images/TickmillTrader/Images/webtraderChartsMobile.webp'
import webtraderLayoutsWorkspacesDesktop from '../images/TickmillTrader/Images/webtraderLayoutsWorkspacesDesktop.webp'
import webtraderLayoutsWorkspacesMobile from '../images/TickmillTrader/Images/webtraderLayoutsWorkspacesMobile.webp'
import webtraderWatchlistsDesktop from '../images/TickmillTrader/Images/webtraderWatchlistsDesktop.webp'
import webtraderWatchlistsMobile from '../images/TickmillTrader/Images/webtraderWatchlistsMobile.webp'
import webtraderWebTraderOverviewDesktop from '../images/TickmillTrader/Images/webtraderWebTraderOverviewDesktop.webp'
import webtraderWebTraderOverviewMobile from '../images/TickmillTrader/Images/webtraderWebTraderOverviewMobile.webp'

import styles from './PlatformsLearnMore.module.scss'

export interface TickmillTraderVideoData {
  image: string
  title: string
  text: string
  link: string
}

export const useTickmillTraderWebtraderVideosData = (): TickmillTraderVideoData[] => {
  const isMobile = useWindowResize()

  return [
    {
      image: isMobile ? webtraderWebTraderOverviewMobile : webtraderWebTraderOverviewDesktop,
      title: 'ToolsPlatforms.WebTrader overview',
      text: 'ToolsPlatforms.Discover seamless trading manage layouts, watchlists, orders, and powerful chart tools',
      link: 'https://www.youtube.com/watch?v=3AqDHrwKDw8',
    },
    {
      image: isMobile ? webtraderLayoutsWorkspacesMobile : webtraderLayoutsWorkspacesDesktop,
      title: 'ToolsPlatforms.Layouts and Workspaces',
      text: 'ToolsPlatforms.Effortlessly manage layouts, switch accounts, and create custom dashboards',
      link: 'https://www.youtube.com/watch?v=Np7Ks8RejMI',
    },
    {
      image: isMobile ? webtraderChartsMobile : webtraderChartsDesktop,
      title: 'ToolsPlatforms.Charts',
      text: 'ToolsPlatforms.Explore charts manage indicators, change types, and use 90+ studies',
      link: 'https://www.youtube.com/watch?v=3cfPB9fORTI',
    },
    {
      image: isMobile ? webtraderBuySellMobile : webtraderBuySellDesktop,
      title: 'ToolsPlatforms.Buy and Sell',
      text: 'ToolsPlatforms.Trade smarter place orders, manage trades, and one-click execution',
      link: 'https://www.youtube.com/watch?v=0WR8tcIBzTc',
    },
    {
      image: isMobile ? webtraderWatchlistsMobile : webtraderWatchlistsDesktop,
      title: 'ToolsPlatforms.Watchlists',
      text: 'ToolsPlatforms.Customize watchlists, columns, trade maps, and manage instruments easily',
      link: 'https://www.youtube.com/watch?v=kNH1Rk2s-60',
    },
  ]
}

export const useTickmillTraderAndroidVideosData = (): TickmillTraderVideoData[] => {
  const isMobile = useWindowResize()

  return [
    {
      image: isMobile ? aboutMobile : aboutDesktop,
      title: 'ToolsPlatforms.About the app',
      text: 'ToolsPlatforms.Keep control of your trading with easy access all in one place',
      link: 'https://www.youtube.com/watch?v=UVlWq0CTSw0&embeds_referring_euri=https%3A%2F%2Fstaging.tickmill.com%2Ftrading-platforms%2Ftickmill-trader&source_ve_path=Mjg2NjY&feature=emb_logo',
    },
    {
      image: isMobile ? managementMobile : managementDesktop,
      title: 'ToolsPlatforms.Account Management',
      text: 'ToolsPlatforms.Get easy and secure login with face recognition and fingerprint',
      link: 'https://www.youtube.com/watch?v=az7CAsN25Sg',
    },
    {
      image: isMobile ? buysellMobile : buysellDesktop,
      title: 'ToolsPlatforms.Buy and Sell',
      text: 'ToolsPlatforms.Seamless trading with low spreads at your fingertips',
      link: 'https://www.youtube.com/watch?v=wrZIOsauEz8&embeds_referring_euri=https%3A%2F%2Fstaging.tickmill.com%2Ftrading-platforms%2Ftickmill-trader&source_ve_path=Mjg2NjY&feature=emb_logo',
    },
    {
      image: isMobile ? watchlistMobile : watchlistDesktop,
      title: 'ToolsPlatforms.Watchlist',
      text: 'ToolsPlatforms.Create a watchlist with your favourite assets',
      link: 'https://www.youtube.com/watch?v=02gt_VrE680&embeds_referring_euri=https%3A%2F%2Fstaging.tickmill.com%2Ftrading-platforms%2Ftickmill-trader&source_ve_path=Mjg2NjY&feature=emb_logo',
    },
    {
      image: isMobile ? chartMobile : chartDesktop,
      title: 'ToolsPlatforms.Chart Analysis',
      text: 'ToolsPlatforms.Navigate charts with a smooth pinch to zoom feature',
      link: 'https://www.youtube.com/watch?v=w-Mv0oA5oo0&embeds_referring_euri=https%3A%2F%2Fstaging.tickmill.com%2Ftrading-platforms%2Ftickmill-trader&source_ve_path=Mjg2NjY&feature=emb_logo',
    },
  ]
}

export const useTickmillTraderIOSVideosData = (): TickmillTraderVideoData[] => {
  const isMobile = useWindowResize()
  const { t } = useTranslation()

  return [
    {
      image: isMobile ? aboutMobile : aboutDesktop,
      title: t('ToolsPlatforms.About the app'),
      text: t('ToolsPlatforms.Keep control of your trading with easy access all in one place'),
      link: 'https://www.youtube.com/watch?v=sj8eNldEgZE',
    },
    {
      image: isMobile ? managementMobile : managementDesktop,
      title: t('ToolsPlatforms.Account Management'),
      text: t('ToolsPlatforms.Get easy and secure login with face recognition and fingerprint'),
      link: 'https://www.youtube.com/watch?v=GhSmUMHwaN4&embeds_referring_euri=https%3A%2F%2Fstaging.tickmill.com%2Ftrading-platforms%2Ftickmill-trader&source_ve_path=Mjg2NjY&feature=emb_logo',
    },
    {
      image: isMobile ? buysellMobile : buysellDesktop,
      title: t('ToolsPlatforms.Buy and Sell'),
      text: t('ToolsPlatforms.Seamless trading with low spreads at your fingertips'),
      link: 'https://www.youtube.com/watch?v=t3Bx7zkk5Vs&embeds_referring_euri=https%3A%2F%2Fstaging.tickmill.com%2Ftrading-platforms%2Ftickmill-trader&source_ve_path=Mjg2NjY&feature=emb_logo',
    },
    {
      image: isMobile ? watchlistMobile : watchlistDesktop,
      title: t('ToolsPlatforms.Watchlist'),
      text: t('ToolsPlatforms.Create a watchlist with your favourite assets'),
      link: 'https://www.youtube.com/watch?v=BcpVnqyQPJI&embeds_referring_euri=https%3A%2F%2Fstaging.tickmill.com%2Ftrading-platforms%2Ftickmill-trader&source_ve_path=Mjg2NjY&feature=emb_logo',
    },
    {
      image: isMobile ? chartMobile : chartDesktop,
      title: t('ToolsPlatforms.Chart Analysis'),
      text: t('ToolsPlatforms.Navigate charts with a smooth pinch to zoom feature'),
      link: 'https://www.youtube.com/watch?v=klYKgp6tsag&embeds_referring_euri=https%3A%2F%2Fstaging.tickmill.com%2Ftrading-platforms%2Ftickmill-trader&source_ve_path=Mjg2NjY&feature=emb_logo',
    },
  ]
}

interface UseComparePlatformsData {
  comparePlatformsData: (
    | {
        name: string
        webTrader: string
        mobileApp: string
      }
    | {
        name: string
        webTrader: boolean
        mobileApp: boolean
      }
  )[]
}

// TODO: check the weird type here

export const useComparePlatformsData = (): UseComparePlatformsData => {
  const { t } = useTranslation()

  const comparePlatformsData = [
    { name: t('ToolsPlatforms.Technical Indicators'), webTrader: '80+', mobileApp: '60+' },
    { name: t('ToolsPlatforms.Advanced Charting Package'), webTrader: true, mobileApp: false },
    {
      name: t('ToolsPlatforms.Trading Directly from the Charts'),
      webTrader: true,
      mobileApp: false,
    },
    { name: t('ToolsPlatforms.Market Depth'), webTrader: true, mobileApp: false },
    { name: t('ToolsPlatforms.Performance Analysis'), webTrader: true, mobileApp: false },
    { name: t('ToolsPlatforms.Trade Journaling'), webTrader: true, mobileApp: false },
    { name: t('ToolsPlatforms.Pending Orders'), webTrader: true, mobileApp: true },
    { name: t('ToolsPlatforms.Custom Watchlists'), webTrader: true, mobileApp: true },
  ]

  return { comparePlatformsData }
}

interface UsePlatformDownloadLinksData {
  webTraderLink: string | undefined
  platformDownloadLinksData: {
    icon: React.ReactNode
    link: string | undefined
  }[]
}

export const usePlatformDownloadLinksData = (
  platformInfo: PlatformInfoDto[]
): UsePlatformDownloadLinksData => {
  const isMobile = useWindowResize()

  const platformDownloadLinksData = [
    {
      os: OperatingSystemEnum.IOS,
      icon: <AppStoreIcon width={119} height={40} />,
      condition: detectIOSOperatingSystem,
    },
    {
      os: OperatingSystemEnum.ANDROID,
      icon: <GooglePlayIcon width={135} height={40} />,
      condition: detectAndroidOperatingSystem,
    },
    {
      os: OperatingSystemEnum.ANDROID_APK,
      icon: <DownloadAPKIcon />,
      condition: detectAndroidOperatingSystem,
    },
  ]

  const platformDownloadLinksDesktopExtras = [
    {
      icon: <img src={tmtQRCode} className={styles.qrCode} alt='' />,
      link: undefined,
    },
  ]

  const getWebTraderLink = () => {
    return getDownloadLinks().find((x) => isWebTraderOperatingSystem(x.operatingSystem.id))
      ?.productLink
  }

  const getDownloadLink = (os: OperatingSystemEnum): string | undefined => {
    return getDownloadLinks()?.find((link) => link.operatingSystem.id === os)?.productLink
  }

  const getDownloadLinks = (): PlatformInfoPlatformLinkDto[] => {
    return platformInfo.find((p) => isTMTPlatformType(p.platfromType.id))?.platformLinks || []
  }

  const platformDownloadLinksMobileData = platformDownloadLinksData
    .filter((x) => x.condition?.())
    .map((x) => ({
      icon: x.icon,
      link: getDownloadLink(x.os),
    }))

  const platformDownloadLinksDesktopData = [
    ...platformDownloadLinksDesktopExtras,
    ...platformDownloadLinksData.map((x) => ({
      icon: x.icon,
      link: getDownloadLink(x.os),
    })),
  ]

  return {
    webTraderLink: getWebTraderLink(),
    platformDownloadLinksData: isMobile
      ? platformDownloadLinksMobileData
      : platformDownloadLinksDesktopData,
  }
}

interface UseTabsData {
  tabs: NameDto<number>[]
}

export const useTabsData = (platformInfo: PlatformInfoDto[]): UseTabsData => {
  const isMobile = useWindowResize()

  const getFilteredOS = (filters: ((id: OperatingSystemEnum) => boolean)[]) => {
    return Array.from(
      new Set(
        platformInfo
          .flatMap((x) => x.platformLinks)
          .filter((x) => filters.some((fn) => fn(x.operatingSystem.id)))
          .map((x) => x.operatingSystem)
          .filter((os, index, self) => self.findIndex((o) => o.id === os.id) === index)
      )
    )
  }

  const tabsDesktop = getFilteredOS([
    isAndroidOperatingSystem,
    isIOSOperatingSystem,
    isWebTraderOperatingSystem,
  ])

  const tabsMobile = detectIOSOperatingSystem()
    ? getFilteredOS([isIOSOperatingSystem, isWebTraderOperatingSystem])
    : getFilteredOS([isAndroidOperatingSystem, isWebTraderOperatingSystem])

  return isMobile ? { tabs: tabsMobile } : { tabs: tabsDesktop }
}

import { IconProps, iconColor } from './IconProps'

export function StepArrowIcon(props: IconProps): JSX.Element {
  const { size = 24 } = props

  return (
    <svg
      width={size}
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17.531 12.5306L10.031 20.0306C9.96136 20.1003 9.87863 20.1556 9.78759 20.1933C9.69654 20.231 9.59896 20.2504 9.50042 20.2504C9.40187 20.2504 9.30429 20.231 9.21324 20.1933C9.1222 20.1556 9.03947 20.1003 8.96979 20.0306C8.90011 19.9609 8.84483 19.8782 8.80712 19.7872C8.76941 19.6961 8.75 19.5985 8.75 19.5C8.75 19.4014 8.76941 19.3039 8.80712 19.2128C8.84483 19.1218 8.90011 19.039 8.96979 18.9694L15.9401 12L8.96979 5.03061C8.82906 4.88988 8.75 4.69901 8.75 4.49999C8.75 4.30097 8.82906 4.1101 8.96979 3.96936C9.11052 3.82863 9.30139 3.74957 9.50042 3.74957C9.69944 3.74957 9.89031 3.82863 10.031 3.96936L17.531 11.4694C17.6008 11.539 17.6561 11.6217 17.6938 11.7128C17.7316 11.8038 17.751 11.9014 17.751 12C17.751 12.0986 17.7316 12.1961 17.6938 12.2872C17.6561 12.3782 17.6008 12.461 17.531 12.5306Z'
        fill={iconColor(props)}
      />
    </svg>
  )
}

import classNames from 'classnames'

import { IconProps, iconColor } from './IconProps'

import styles from './Icons.module.scss'

interface Props extends IconProps {
  direction?: 'left' | 'right'
  disabled?: boolean
  isReversed?: boolean
}

export function NavigationIcon(props: Props): JSX.Element {
  const { size = 24, direction = 'left', disabled, onClick, className, isReversed } = props

  const getDirection = () => {
    if (direction === 'left') {
      if (isReversed) {
        return 'M9 5L16 12L9 19'
      } else {
        return 'M15 19L8 12L15 5'
      }
    }
    if (isReversed) {
      return 'M15 5L8 12L15 19'
    } else {
      return 'M9 19L16 12L9 5'
    }
  }

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={disabled ? undefined : onClick}
      className={classNames(className, { [styles.btn]: !disabled && onClick })}
    >
      <g id='Navigation arrow'>
        <path
          id='Vector'
          d={getDirection()}
          stroke={iconColor({ color: disabled ? 'disabled' : 'text' })}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  )
}

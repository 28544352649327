import { SubscriptionBundleDto } from '../../model/SubscriptionBundleDto'
import { SubscriptionCartItem } from '../../model/SubscriptionCart'
import { SubscriptionRequestState } from '../../model/SubscriptionRequestState'
import { SubscriptionRequestTypeEnum } from '../../model/SubscriptionRequestTypeEnum'
import { isTransactionStatusInProcess } from '../../model/SubscriptionStatus'
import {
  Subscription,
  SubscriptionDetail,
  SubscriptionDetailTypeEnum,
  isPendingCancellation,
} from '../../model/SubscriptionTypes'
import { SubscriptionRequest, TradingPlatformsPackagesProps } from '../../model/TradingPlatformsDto'
import { SubscriptionTimeInForce } from '../../model/UpdateSubscriptionRequest'

export function getSubscribedBundles(
  subscriptionBundles: SubscriptionBundleDto[]
): SubscriptionBundleDto[] {
  return subscriptionBundles?.filter((bundle) =>
    bundle.subscriptions.some((subscription) => subscription.activeSubscriptionRequest)
  )
}

export function isLevelDowngrade(
  selectedSubscription: Subscription | undefined,
  subscriptionDetail: SubscriptionDetail | undefined
): boolean {
  if (!selectedSubscription || !subscriptionDetail) {
    return false
  }

  const { details, activeSubscriptionRequest, subscriptionRequest } = selectedSubscription
  const { amount } = subscriptionDetail

  return details.some((detail) => {
    const isActiveDetail = detail.id === activeSubscriptionRequest?.subscriptionDetailId
    const isPendingDetail =
      detail.id === subscriptionRequest?.subscriptionDetailId &&
      (detail.subscriptionRequest?.state.name === SubscriptionRequestState.Pending ||
        detail.subscriptionRequest?.state.name === SubscriptionRequestState.DeductingFunds ||
        detail.subscriptionRequest?.state.name === SubscriptionRequestState.InProcess)

    return (isActiveDetail || isPendingDetail) && detail.amount > amount
  })
}

export const hasPendingUnsubscriptionRequest = (
  subscription: Subscription | TradingPlatformsPackagesProps
): boolean => {
  const { subscriptionRequest } = subscription
  return (
    !!subscriptionRequest &&
    (subscriptionRequest?.state.name === SubscriptionRequestState.Pending ||
      subscriptionRequest?.state.name === SubscriptionRequestState.DeductingFunds ||
      subscriptionRequest?.state.name === SubscriptionRequestState.InProcess) &&
    subscriptionRequest.type.name === SubscriptionRequestTypeEnum.CancelExistingSubscription
  )
}

export const hasStartingMessage = (
  subscription: Subscription | TradingPlatformsPackagesProps
): boolean => {
  const { subscriptionRequest } = subscription

  return (
    !subscription?.activeSubscriptionRequest &&
    !isPendingCancellation(subscriptionRequest) &&
    !!subscriptionRequest &&
    subscriptionRequest?.subscriptionTimeInForce?.id !== SubscriptionTimeInForce.Immediate &&
    (subscriptionRequest.type.name === SubscriptionRequestTypeEnum.AddNewSubscription ||
      subscriptionRequest.type.name === SubscriptionRequestTypeEnum.CancelExistingSubscription)
  )
}

export const getSubscriptionDefaultLevel = (
  subscription?: Subscription
): SubscriptionDetail | undefined =>
  subscription?.details.find((detail) => detail.type.name === SubscriptionDetailTypeEnum.Level_1)

export const hasActivatingSubscription = (subscription: Subscription): boolean =>
  subscription.details.some(
    (detail) =>
      detail?.subscriptionRequest?.state.name === SubscriptionRequestState.InProcess ||
      detail?.subscriptionRequest?.state.name === SubscriptionRequestState.DeductingFunds
  )
export const hasLevelInCart = (
  subscription: Subscription | TradingPlatformsPackagesProps | undefined,
  nonActiveSubscriptions: SubscriptionCartItem[]
): boolean => {
  if (!subscription) {
    return false
  }
  return nonActiveSubscriptions.some((item) => item.subscription.id === subscription.id)
}

export const getActiveSubscriptionLevel = (
  subscription: Subscription | undefined
): SubscriptionDetail | undefined => {
  return subscription?.details.find((detail) => detail.activeSubscriptionRequest)
}

export const getLatestSubscriptionRequest = (
  bundle?: SubscriptionBundleDto
): SubscriptionDetail | undefined => {
  if (!bundle) {
    return
  }
  const [subscription] = bundle.subscriptions
  return subscription.details.find(
    (detail) => detail.id === bundle.subscriptionRequest?.subscriptionDetailId
  )
}

export const getBadgeSubscriptionLevel = (
  bundle?: SubscriptionBundleDto
): SubscriptionDetail | undefined => {
  if (!bundle) {
    return
  }
  const [subscription] = bundle.subscriptions
  return (
    getLatestSubscriptionRequest(bundle) ||
    getActiveSubscriptionLevel(subscription) ||
    getPendingCancelSubscriptionLevel(subscription) ||
    getPendingUpdateSubscriptionLevel(subscription) ||
    getPendingAddNewSubscriptionLevel(subscription) ||
    getSubscriptionDefaultLevel(subscription) ||
    subscription?.details[0]
  )
}

export const getNextSubLevel = (
  subscription: Subscription,
  bundleSub?: SubscriptionDetail
): SubscriptionDetail | undefined => {
  const currentLevel =
    getActiveSubscriptionLevel(subscription)?.type.id || getLevelFromBundle(bundleSub) || 0
  return subscription?.details.find((detail) => detail.type.id === currentLevel + 1)
}

export const getPreviousSubLevel = (
  subscription: Subscription,
  bundleSub?: SubscriptionDetail
): SubscriptionDetail | undefined => {
  const currentLevel =
    getActiveSubscriptionLevel(subscription)?.type.id || getLevelFromBundle(bundleSub) || 0
  return subscription?.details.find((detail) => detail.type.id === currentLevel - 1)
}

export const getLevelFromBundle = (bundleSub?: SubscriptionDetail): number | undefined => {
  if (bundleSub?.activeSubscriptionRequest || bundleSub?.activeViaBundleSubscriptionRequest) {
    return bundleSub?.type.id
  }
}

export const allowSubUpgrade = (
  subscription: Subscription,
  bundleSub?: SubscriptionDetail
): boolean | undefined => {
  const maxLevel = subscription?.details.reduce((prev, current) =>
    prev.type.id > current.type.id ? prev : current
  )
  const currentLevel =
    getActiveSubscriptionLevel(subscription)?.type.id || getLevelFromBundle(bundleSub)
  if (maxLevel && currentLevel) {
    return maxLevel.type.id > currentLevel
  }
  return false
}

export const allowSubDowngrade = (
  subscription: Subscription,
  bundleSub?: SubscriptionDetail
): boolean | undefined => {
  const minLevel = subscription?.details.reduce((prev, current) =>
    prev.type.id < current.type.id ? prev : current
  ).type.id
  const currentLevel =
    getActiveSubscriptionLevel(subscription)?.type.id || getLevelFromBundle(bundleSub)
  if (minLevel && currentLevel) {
    return minLevel < currentLevel
  }
  return false
}

export const hasPendingUpgrade = (
  subscription: Subscription,
  bundleSub?: SubscriptionDetail
): boolean => {
  const currentLevel =
    getActiveSubscriptionLevel(subscription)?.type.id || getLevelFromBundle(bundleSub)
  const pendingUpgrade = getPendingUpdateSubscriptionLevel(subscription)
  if (pendingUpgrade && currentLevel) {
    return pendingUpgrade.type.id > currentLevel
  } else {
    return false
  }
}

export const hasPendingDowngrade = (
  subscription: Subscription,
  bundleSub?: SubscriptionDetail
): boolean => {
  const currentLevel =
    getActiveSubscriptionLevel(subscription)?.type.id || getLevelFromBundle(bundleSub)
  const pendingDowngrade = getPendingUpdateSubscriptionLevel(subscription)
  if (pendingDowngrade && currentLevel) {
    return pendingDowngrade.type.id < currentLevel
  } else {
    return false
  }
}

export const getInProcessSubscriptionLevel = (
  subscription: Subscription | undefined
): SubscriptionDetail | undefined => {
  return subscription?.details.find((detail) =>
    isTransactionStatusInProcess(detail.subscriptionRequest?.state.id ?? 0)
  )
}

export const hasInProcessUpgrade = (
  subscription: Subscription,
  bundleSub?: SubscriptionDetail
): boolean => {
  const currentLevel =
    getActiveSubscriptionLevel(subscription)?.type.id || getLevelFromBundle(bundleSub)
  const inProcessUpgrade = getInProcessSubscriptionLevel(subscription)

  if (inProcessUpgrade && currentLevel) {
    return inProcessUpgrade.type.id > currentLevel
  } else {
    return false
  }
}

export const hasInProcessDowngrade = (
  subscription: Subscription,
  bundleSub?: SubscriptionDetail
): boolean => {
  const currentLevel =
    getActiveSubscriptionLevel(subscription)?.type.id || getLevelFromBundle(bundleSub)
  const inProcessDowngrade = getInProcessSubscriptionLevel(subscription)

  if (inProcessDowngrade && currentLevel) {
    return inProcessDowngrade.type.id < currentLevel
  } else {
    return false
  }
}

export const getPendingUpdateSubscriptionLevel = (
  subscription: Subscription | undefined
): SubscriptionDetail | undefined => {
  return subscription?.details.find(
    (detail) =>
      (detail.subscriptionRequest?.state.name === SubscriptionRequestState.Pending ||
        detail.subscriptionRequest?.state.name === SubscriptionRequestState.DeductingFunds ||
        detail.subscriptionRequest?.state.name === SubscriptionRequestState.InProcess) &&
      detail.subscriptionRequest?.type.name ===
        SubscriptionRequestTypeEnum.UpdateExistingSubscription
  )
}

export const getPendingAddNewSubscriptionLevel = (
  subscription: Subscription | undefined
): SubscriptionDetail | undefined => {
  return subscription?.details.find(
    (detail) =>
      (detail.subscriptionRequest?.state.name === SubscriptionRequestState.Pending ||
        detail.subscriptionRequest?.state.name === SubscriptionRequestState.DeductingFunds ||
        detail.subscriptionRequest?.state.name === SubscriptionRequestState.InProcess) &&
      detail.subscriptionRequest?.type.name === SubscriptionRequestTypeEnum.AddNewSubscription
  )
}

export const getPendingCancelSubscriptionLevel = (
  subscription: Subscription | undefined
): SubscriptionDetail | undefined => {
  return subscription?.details.find(
    (detail) =>
      (detail.subscriptionRequest?.state.name === SubscriptionRequestState.Pending ||
        detail.subscriptionRequest?.state.name === SubscriptionRequestState.DeductingFunds ||
        detail.subscriptionRequest?.state.name === SubscriptionRequestState.InProcess) &&
      detail.subscriptionRequest?.type.name ===
        SubscriptionRequestTypeEnum.CancelExistingSubscription
  )
}

export const isSubscriptionTimeInForceImmediate = (
  subscriptionRequest?: SubscriptionRequest
): boolean => {
  return subscriptionRequest?.subscriptionTimeInForce?.id === SubscriptionTimeInForce.Immediate
}

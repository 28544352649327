import React from 'react'
import { useTranslation } from 'react-i18next'

import { Grid } from '../../ui/Layouts/Grid/Grid'
import { TextSmall, TextStrong } from '../../ui/Typography/Typography'
import { PlatformDownloadButton } from './PlatformIcon'
import { TickmillTraderVideoData } from './platformsLearnMoreData'

import styles from './PlatformsLearnMore.module.scss'

interface VideoCardsProps {
  data: TickmillTraderVideoData[]
}

export const VideoCards: React.FC<VideoCardsProps> = (props) => {
  const { data } = props

  return (
    <Grid className={styles.imageContainer}>
      {data.map((item) => (
        <VideoCard item={item} key={item.title} />
      ))}
    </Grid>
  )
}

interface VideoCardProps {
  item: TickmillTraderVideoData
}

const VideoCard: React.FC<VideoCardProps> = (props) => {
  const { item } = props
  const { t } = useTranslation()

  return (
    <div className={styles.imageCard}>
      <PlatformDownloadButton to={item.link}>
        <img src={item.image} alt={item.title} />
      </PlatformDownloadButton>
      <div className={styles.imageText}>
        <TextStrong isParagraph>{t(item.title)}</TextStrong>
        <TextSmall isParagraph>{t(item.text)}</TextSmall>
      </div>
    </div>
  )
}

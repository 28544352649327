import React from 'react'

import { useSessionLanguage } from '../../global/context/SessionSettingsContext'
import { Tel3Client, Tel3ClientContext } from '../../utils/Tel3Client'
import { TrainingVideosPage } from './TrainingVideosPage'

export const TrainingPage = (): JSX.Element => {
  const locale = useSessionLanguage()
  const apiClient = new Tel3Client(locale)

  return (
    <Tel3ClientContext.Provider value={apiClient}>
      <TrainingVideosPage />
    </Tel3ClientContext.Provider>
  )
}

import React, { useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { throttle } from 'lodash'

import { Button } from '../../../../../global/button/Button'
import { useSignup } from '../../../../../global/context/SignupContext'
import { useSnackbar } from '../../../../../global/context/SnackbarContext'
import { AccountType } from '../../../../../model/AccountType'
import { Text, TextStrong } from '../../../../../ui/Typography/Typography'
import { ResponseError, instanceOfAxiosError, useApiClient } from '../../../../../utils/ApiClient'
import { ClientApiClient } from '../../../../../utils/clientApi'
import { NoteWrapper } from '../../../Components/NoteWrapper'
import { SignupFormWrapper } from '../../../Components/SignupFormWrapper'
import { SignupSections, SignupStep } from '../../../Components/SignupStep'
import { SignupError } from './SignupErrorPage'

export const LeadCreationPage: React.FC = () => {
  const { signupData } = useSignup()
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const accountTypeId = searchParams.get('accountTypeId') as AccountType | null
  const email = searchParams.get('email')
  const totalSteps = signupData.totalSteps.personalDetails
  const apiClient = useApiClient(ClientApiClient)
  const { addSnackbar } = useSnackbar()

  const handleResend = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()
      event.stopPropagation()

      if (signupData.values?.email ?? email) {
        try {
          await apiClient.resendCreateLeadEmailByEmail(signupData.values?.email ?? email)
        } catch (e: unknown) {
          if (instanceOfAxiosError((e as ResponseError)?.response)) {
            const errorResponse = (e as ResponseError).response.response?.data
            if (errorResponse?.code === SignupError.LeadVerificationErrorException) {
              // Ignore error: it means the lead is already verified
            } else if (errorResponse?.code) {
              throw e
            }
          }
        }
        addSnackbar.success({ message: t('Email was re-sent') })
      } else {
        addSnackbar.error({ message: t('Sign up.Something went wrong') })
      }
    },
    [signupData.values?.email, email, apiClient, addSnackbar, t]
  )

  const throttledHandleResend = useRef(
    throttle(handleResend, 1000, { leading: true, trailing: false })
  )

  return (
    <SuccessPage
      accountTypeId={Number(accountTypeId ?? signupData.values.typeId)}
      email={signupData.values.email ?? email}
      totalSteps={totalSteps}
      step={3}
      onResend={throttledHandleResend.current}
    />
  )
}

interface SuccessPageProps {
  step: number
  email?: string
  accountTypeId: AccountType
  totalSteps: number
  onResend(event: React.MouseEvent<HTMLButtonElement>): void
}

const SuccessPage: React.FC<SuccessPageProps> = (props) => {
  const { step, totalSteps, onResend, email, accountTypeId } = props

  const { t } = useTranslation()

  return (
    <SignupFormWrapper label={t('Sign up.Sign up')}>
      <SignupStep
        subStep={{
          current: step,
          total: totalSteps,
        }}
        label={t('Sign up.Confirm Registration')}
        section={SignupSections.PersonalDetails}
        accountTypeId={accountTypeId}
      />
      <NoteWrapper
        id='lead-created-page'
        title={`${t('Sign up.Verify your Email')}!`}
        text={
          <>
            <Text>
              {t('Sign up.Please check your inbox', { email })}
              {<TextStrong>{t('Sign up.click the verification link to continue')}</TextStrong>}{' '}
              {t('Sign up.Your verification link is active for the next x hours', {
                hours: 72,
              })}
              .
            </Text>
            <Text>
              {t(`Sign up.Didn't get the link`)}?{' '}
              {t('Sign up.Make sure to check your spam folder or')}{' '}
              <Button appearance='link' onClick={onResend}>
                {t('Sign up.Resend')}
              </Button>
            </Text>
          </>
        }
      />
    </SignupFormWrapper>
  )
}

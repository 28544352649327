import React from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation } from 'react-router-dom'
import classNames from 'classnames'

import { Tabs, defineTab } from '../global/tabs/Tabs'
import { useCurrentTab } from '../hooks/useCurrentTab'
import { useNavigate } from '../navigation/custom-react-router-dom'
import { isNoTabsPath } from '../utils/path'

import styles from './Tools.module.scss'

export const Tools: React.FC = () => {
  const { t } = useTranslation()

  const currentTab = useCurrentTab('tools')
  const navigate = useNavigate()
  const location = useLocation()
  function* getTabs() {
    yield {
      ...defineTab('platforms', t('Platforms')),
    }
    yield {
      ...defineTab('earnings-calendar', t('Earnings Calendar')),
    }
    yield {
      ...defineTab('economic-calendar', t('Economic Calendar')),
    }
    yield {
      ...defineTab('research-terminal', t('Research Terminal')),
    }
  }
  const TabsDefinition = [...getTabs()]

  return (
    <div>
      {!isNoTabsPath(location.pathname) && (
        <div className={classNames('section', styles.adminRoutes)}>
          <Tabs
            tabs={TabsDefinition}
            currentTab={currentTab}
            appearance='light'
            onTabChanged={(tab) => navigate(`/dashboard/tools/${tab}`)}
          />
        </div>
      )}
      <Outlet />
    </div>
  )
}

import React, { useMemo, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'

import './layout/site.scss'
import { NotificationsContextProvider } from './global/context/NotificationsContext'
import { initiateNotificationsContext } from './global/notification/notification'
import { Session } from './routing/Session/Session'
import { AccountContextProvider, initiateAccountContext } from './utils/AccountContextContext'
import { ApiClient, ApiClientContext } from './utils/ApiClient'
import { CategorySizesContextProvider } from './utils/CategorySizesContext'
import { IBCampaignsResultsContextProvider } from './utils/IBCampaignsResultsContext'
import { MaintenanceModeProvider } from './utils/MaintenanceModeContext'
import { SharedContext, StateSharedState, initialSharedContext } from './utils/SharedContext'

export const App: React.FC = () => {
  const apiClient = useMemo(() => new ApiClient(), [])

  const [sharedState, setSharedState] = useState<StateSharedState>(initialSharedContext)

  return (
    <BrowserRouter>
      <ApiClientContext.Provider value={apiClient}>
        <AccountContextProvider account={initiateAccountContext()}>
          <SharedContext.Provider value={[sharedState, setSharedState]}>
            <MaintenanceModeProvider>
              <IBCampaignsResultsContextProvider
                account={initiateAccountContext() || undefined}
                campaignsResults={[]}
              >
                <CategorySizesContextProvider categorySizes={[]}>
                  <NotificationsContextProvider initialSettings={initiateNotificationsContext()}>
                    <Session />
                  </NotificationsContextProvider>
                </CategorySizesContextProvider>
              </IBCampaignsResultsContextProvider>
            </MaintenanceModeProvider>
          </SharedContext.Provider>
        </AccountContextProvider>
      </ApiClientContext.Provider>
    </BrowserRouter>
  )
}

export default App

import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { useSessionEntity } from '../../../global/context/EntityContext'
import { ToastContext, errorToast } from '../../../global/toast/Toast'
import { CreateDemoTradingAccount } from '../../../model/CreateTradingAccount'
import { LeverageTypeDto, isLeverageTypeDynamic } from '../../../model/LeverageTypeDto'
import {
  PlatformTypeEnum,
  isTMTPlatformType,
  isTradingViewPlatformType,
} from '../../../model/PlatformTypeEnum'
import { WalletCurrency } from '../../../model/WalletDto'
import { useNavigate } from '../../../navigation/custom-react-router-dom'
import { useAccountReadContext } from '../../../utils/AccountContextContext'
import { useApiClient } from '../../../utils/ApiClient'
import { ClientApiClient } from '../../../utils/clientApi'
import { useScrollToTop } from '../../../utils/useScrollToTop'
import { CreateDemoTradingAccountForm } from './CreateDemoTradingAccountForm'

export const CreateDemoTradingAccountPage: React.FC = () => {
  useScrollToTop()

  const apiClient = useApiClient(ClientApiClient)
  const navigate = useNavigate()
  const { account } = useAccountReadContext()
  const setToast = useContext(ToastContext)
  const entity = useSessionEntity()

  const location = useLocation()
  const { selectedPlatformType } = location.state || {}

  const { t } = useTranslation()

  const [currencies, setCurrencies] = useState<WalletCurrency[]>([])
  const [leverageType, setLeverageType] = useState<LeverageTypeDto | undefined>()

  const handleSubmit = async (values: CreateDemoTradingAccount) => {
    try {
      const { publicName: serverName, dns: serverDns } = await apiClient.addDemoTradingAccount(
        values
      )
      const queryParams = new URLSearchParams({ serverName, serverDns }).toString()

      if (isTradingViewPlatformType(values.platformTypeId)) {
        navigate(`/dashboard/traders-room/trading-accounts/new/demo/success/tradingview`)
      } else if (values.platformTypeId === PlatformTypeEnum.MT4) {
        navigate(`/dashboard/traders-room/trading-accounts/new/demo/success/mt4?${queryParams}`)
      } else if (values.platformTypeId === PlatformTypeEnum.MT5) {
        navigate(`/dashboard/traders-room/trading-accounts/new/demo/success/mt5?${queryParams}`)
      } else if (values.platformTypeId === PlatformTypeEnum.TickmillTrader) {
        navigate(
          `/dashboard/traders-room/trading-accounts/new/demo/success/tickmilltrader?${queryParams}`
        )
      } else {
        navigate('/dashboard')
      }
    } catch (error: unknown) {
      navigate('/dashboard/traders-room/trading-accounts')
      if ((error as any).response.response.data.message) {
        return setToast(errorToast((error as any).response.response.data.message))
      } else {
        return setToast(errorToast(t('errors.Something went wrong! Please try again later')))
      }
    }
  }

  const handleChangePlatform = async (platformTypeId: number) => {
    const response = await apiClient.getDemoCurrencies({
      platformTypeId,
      tickmillCompanyId: entity,
    })
    setCurrencies(response)
  }

  const fetchLeverage = useCallback(async () => {
    if (
      isTradingViewPlatformType(selectedPlatformType?.id) ||
      isTMTPlatformType(selectedPlatformType?.id)
    ) {
      const leverage = (await apiClient.getLeverageTypes(selectedPlatformType?.id)).find(
        (leverage) => isLeverageTypeDynamic(leverage.id)
      )
      setLeverageType(leverage)
    }
  }, [selectedPlatformType?.id])

  useEffect(() => {
    if (selectedPlatformType) {
      handleChangePlatform(selectedPlatformType.id)
      fetchLeverage()
    }
  }, [selectedPlatformType?.id])

  return (
    <CreateDemoTradingAccountForm
      entity={entity}
      currencies={currencies}
      onChangePlatform={handleChangePlatform}
      account={account}
      onSubmit={handleSubmit}
      selectedPlatformType={selectedPlatformType}
      leverageType={leverageType}
    />
  )
}

import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { TwoFactorAuthForm } from '../../TwoFactorAuth/TwoFactorAuthForm'
import { TwoFactorAuthLocationState, useTwoFactorAuth } from '../../TwoFactorAuth/useTwoFactorAuth'
import { useTwoFactorAuthGoBackProps } from '../../TwoFactorAuth/useTwoFactorAuthGoBackProps'
import { useTwoFactorAuthText } from '../../TwoFactorAuth/useTwoFactorAuthText'
import IconButton from '../../global/iconButton/IconButton'
import { ArrowLeftIcon } from '../../icons/ArrowLeftIcon'
import { GoodIcon } from '../../icons/GoodIcon'
import { isGoogleAuthenticatorType } from '../../model/TwoFactorAuthentication'
import { useNavigate } from '../../navigation/custom-react-router-dom'
import { Text, TextH3 } from '../../ui/Typography/Typography'
import { useScrollToTop } from '../../utils/useScrollToTop'

import styles from './TwoFactorAuthConfirmPage.module.scss'

export const TwoFactorAuthConfirmPage: React.FC = () => {
  useScrollToTop()

  const navigate = useNavigate()
  const {
    twoFactor,
    resendCode,
    handleVerifyCode,
    error,
    status,
    disableResend,
    renderTrustModal,
    setRecoveryCodeModal,
    initialCountdown,
    resendError,
    renderRecoveryModal,
    handleTimer,
    stateChecker,
  } = useTwoFactorAuth()
  const { resendText, errorText } = useTwoFactorAuthText({
    twoFactor,
    initialCountdown,
    resendCode,
    disableResend,
    resendError,
  })

  const { t } = useTranslation()
  const location = useLocation()
  const locationState = location.state as TwoFactorAuthLocationState
  const hasTwoFactorMethods = (locationState?.twoFactorAuthData?.twoFactor?.length ?? 0) > 1
  const twoFactorMethods = locationState?.twoFactorAuthData?.twoFactor || []

  useEffect(() => {
    if (!locationState?.twoFactorAuthData?.twoFactor) {
      navigate('/login')
    }
  }, [locationState, navigate])

  const goBackProps = useTwoFactorAuthGoBackProps(
    locationState?.twoFactorAuthData?.twoFactorAuthEvent ?? '',
    locationState?.twoFactorAuthData?.withdrawalData?.withdrawalPayload?.fromWalletId
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => handleTimer().startResetTimer(), [resendError.hideResend])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => handleTimer().startInitialTimer(), [initialCountdown])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => stateChecker().effect(), stateChecker().dependants)

  return (
    <div className={styles.topWrapper}>
      <div className={styles.wrapper}>
        {renderTrustModal()}
        {renderRecoveryModal()}
        <div className={styles.inner}>
          <IconButton
            onClick={() =>
              navigate(goBackProps.path, goBackProps.state ? { state: goBackProps.state } : {})
            }
          >
            <div className={styles.backToLogin}>
              <ArrowLeftIcon color='text' />
              <Text>{goBackProps.text}</Text>
            </div>
          </IconButton>
          <div className={styles.title}>
            <TextH3>{t('TwoFactorAuth.Confirm it’s you')}</TextH3>
            {status === 'success' && <GoodIcon size={24} />}
          </div>
          <div>
            <Text className={styles.email}>
              {isGoogleAuthenticatorType(twoFactorMethods?.[0]?.provider?.id)
                ? t('TwoFactorAuth.Enter the verification code generated by your')
                : t('TwoFactorAuth.Enter the verification code sent to')}
            </Text>
            <br />
            {hasTwoFactorMethods ? (
              <Text>
                {twoFactorMethods.map((method, idx, array) => (
                  <React.Fragment key={idx}>
                    {idx > 0 && idx === array.length - 1 ? (
                      <Text> {t('and')} </Text>
                    ) : idx > 0 ? (
                      ', '
                    ) : (
                      ' '
                    )}
                    <Text className={styles.email}>{method.authMedium}</Text>
                  </React.Fragment>
                ))}
              </Text>
            ) : (
              <Text>{twoFactorMethods[0]?.authMedium ?? ''}</Text>
            )}
          </div>

          <TwoFactorAuthForm
            resendText={resendText}
            errorText={errorText}
            openRecoveryModal={() => setRecoveryCodeModal(true)}
            statusType={status}
            onSubmit={handleVerifyCode}
            validationError={error}
          />
        </div>
      </div>
    </div>
  )
}

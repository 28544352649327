import * as React from 'react'
import { useLocation } from 'react-router-dom'

import { Navigate } from '../../navigation/custom-react-router-dom'

export function RedirectionManager() {
  const location = useLocation()
  const redirectionPath = new URLSearchParams(location.search).get('redir')

  return redirectionPath ? <Navigate to={`/${redirectionPath}`} replace /> : null
}

import React, { Suspense, useCallback, useEffect, useRef } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import { CartDataFetcher } from '../Subscriptions/hooks/useCartItemsData'
import { Loading } from '../global/Loading/Loading'
import { SessionTimeout } from '../global/SessionTimeout/SessionTimeout'
import { StatusBanner } from '../global/StatusBanner/StatusBanner'
import { TickmillAppBanner } from '../global/TickmillAppBanner/TickmillAppBanner'
import { AppFooter } from '../global/footer/Footer'
import { MaintenanceMessage } from '../global/maintenanceMessage/MaintenanceMessage'
import { MobileTabs } from '../global/mobileTabs/MobileTabs'
import { Nav } from '../global/nav/Nav'
import { NotificationBlock } from '../global/notification/NotificationBlock'
import { PlatformNotification } from '../global/platformNotification/PlatformNotification'
import { Toast, ToastBanner } from '../global/toast/Toast'
import { useModalScrollHandler } from '../hooks/useModalScrollHandler'
import { SnackbarContainer } from '../ui/Popups/Snackbar/Snackbar'
import { PathHandler } from '../utils/PathHandler'
import { useLiveChatWidget } from '../utils/useLiveChatWidget'
import { GlobalModals } from './GlobalModals'

interface LayoutProps {
  toast?: Toast
  setCurrentPath: (path: string) => void
}

const useLayoutHeight = () => {
  const statusBannerRef = useRef<HTMLDivElement>(null)

  const updateLayoutHeight = useCallback(() => {
    const statusBannerHeight = statusBannerRef.current?.clientHeight || 0

    document.documentElement.style.setProperty('--status-banner-height', `${statusBannerHeight}px`)
  }, [])

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      updateLayoutHeight()
    })

    if (statusBannerRef.current) {
      resizeObserver.observe(statusBannerRef.current)
    }

    return () => {
      if (statusBannerRef.current) {
        resizeObserver.unobserve(statusBannerRef.current)
      }
    }
  }, [updateLayoutHeight])

  return { statusBannerRef }
}

const useBackgroundType = () => {
  const location = useLocation()

  const pathsSecondaryBackground = [
    '/login',
    '/forgot-password',
    '/tools/platforms',
    '/dashboard/traders-room/trading-accounts/new/platform-types',
  ]
  const secondaryBackground = pathsSecondaryBackground.some((path) =>
    location.pathname.includes(path)
  )

  const pathsNewBackground = ['/dashboard/introducing-broker']
  const newBackground = pathsNewBackground.some((path) => location.pathname.includes(path))

  let backgroundType = 'defaultBackground'
  if (secondaryBackground) {
    backgroundType = 'secondaryBackground'
  } else if (newBackground) {
    backgroundType = 'newBackground'
  }

  return { backgroundType }
}

export const Layout: React.FC<LayoutProps> = ({ toast, setCurrentPath }) => {
  useModalScrollHandler()
  const { statusBannerRef } = useLayoutHeight()
  const { backgroundType } = useBackgroundType()

  return (
    <div className={`expander ${backgroundType}`}>
      {useLiveChatWidget()}
      <TickmillAppBanner />
      <CartDataFetcher />
      <SnackbarContainer />
      <GlobalModals />
      <MaintenanceMessage />
      <PlatformNotification />
      <Nav />
      <main id='mainContainer' className='container'>
        <Suspense fallback={<Loading isLoading showLoadingIcon />}>
          <SessionTimeout />
          <NotificationBlock />
          <ToastBanner of={toast} />
          <div ref={statusBannerRef}>
            <StatusBanner />
          </div>
          <PathHandler setCurrentPath={setCurrentPath} />
          <MobileTabs />
          <Outlet />
        </Suspense>
      </main>
      <AppFooter />
    </div>
  )
}

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { useFormikContext } from 'formik'

import { Button } from '../../../../global/button/Button'
import { DocumentFileUploads } from '../../../../global/fileUpload/DocumentFileUploads'
import { FileData, isFileSizeValid } from '../../../../global/fileUpload/FileUpload'
import { CloseIcon } from '../../../../icons/CloseIcon'
import { DocumentCategorySizeDto, DocumentCategoryType } from '../../../../model/DocumentCategories'
import { AppropriatenessTestTakeTestFormValues } from '../../../../model/Test'
import { useCategorySizesReadContext } from '../../../../utils/CategorySizesContext'

import styles from './AppropriatenessTestUploadModal.module.scss'

export interface AppropriatenessTestUploadModalProps {
  questionId: string

  onCancel(): void
  onConfirm(): void
}

const MAX_MB_FILE = 8
const hasValidSizeFiles = (files: FileData[], categorySizes: DocumentCategorySizeDto[]) => {
  const maxFileSizeInMb =
    categorySizes.find((category) => category.id === DocumentCategoryType.AppropriatenessTest)
      ?.maxUploadFileSizeInMb ?? MAX_MB_FILE

  return files.every((file) => isFileSizeValid(file.fileSize, maxFileSizeInMb))
}

export const AppropriatenessTestUploadModal: React.FC<AppropriatenessTestUploadModalProps> = (
  props
) => {
  const { questionId, onCancel, onConfirm } = props
  const { categorySizes } = useCategorySizesReadContext()
  const { t } = useTranslation()

  const context = useFormikContext<AppropriatenessTestTakeTestFormValues>()

  const documents = context.values.documents
  const documentFiles = (documents?.[questionId] || []).filter((x) => !!x?.fileName)

  const [files, setFiles] = useState<FileData[]>(documentFiles)
  const [canUpload, setCanUpload] = useState(true)

  const handleUpload = () => {
    const canUpload = hasValidSizeFiles(files, categorySizes)

    if (files && canUpload) {
      context.setValues({
        ...context.values,
        documents: {
          ...documents,
          [questionId]: files,
        },
      })
    }
    onConfirm()
  }

  const handleDelete = (questionId: string, fileId: string | undefined) => {
    if (files) {
      const newFiles = files.filter((x) => x.id !== fileId).filter((x) => !!x?.fileName)
      setFiles(newFiles)
    }
  }

  return (
    <React.Fragment>
      <header className='modal-card-head'>
        <p className='modal-card-title'>{t('Sign up.Document Upload .JPG .JPEG, .PNG .PDF')}</p>
      </header>
      <div className={classNames('modal-card-body', styles.body)}>
        <span>
          {t(
            'Sign up.If available please upload any documentary evidence of trading history (e.g. trading statement)'
          )}
        </span>
        <DocumentFileUploads
          documentCategory={DocumentCategoryType.AppropriatenessTest}
          files={files}
          onChange={(data) => {
            const hasValidSizeFilesAdded = hasValidSizeFiles(data, categorySizes)
            setCanUpload(hasValidSizeFilesAdded)
            setFiles(data)
          }}
        />
        <br />
        <br />
        <ul className='content'>
          {files
            .filter((x) => !!x?.fileName)
            .map((x) => (
              <li className={styles.delete}>
                <span>{x.fileName}</span>
                <Button
                  className={styles.iconButton}
                  onClick={() => {
                    handleDelete(questionId, x.id)
                  }}
                  appearance='plain'
                  size='S'
                  type='button'
                >
                  <CloseIcon size={10} />
                </Button>
              </li>
            ))}
        </ul>
      </div>
      <footer className='modal-card-foot'>
        <React.Fragment>
          <button className='button' onClick={onCancel} type='button'>
            {t('Cancel')}
          </button>
          <button className='button' onClick={handleUpload} type='button' disabled={!canUpload}>
            {t('Sign up.Upload')}
          </button>
        </React.Fragment>
      </footer>
    </React.Fragment>
  )
}

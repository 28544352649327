import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useDocumentVerificationPath } from '../../hooks/useDocumentVerificationPath'
import { isVerificationExpedited } from '../../model/AccountDetailedDto'
import { isAccountActivatedStatus } from '../../model/AccountDetailedStatus'
import { useNavigate } from '../../navigation/custom-react-router-dom'
import { isDepositBeforeKYC, isSumsubNeeded } from '../../utils/AccountAccess/accountKYCStatuses'
import { useAccountWriteContext } from '../../utils/AccountContextContext'
import { useWalletsWriteContext } from '../../utils/UserWalletContext'
import { CommonPaths, useBasePath } from '../../utils/path'
import { useScrollToTop } from '../../utils/useScrollToTop'
import { Loading } from '../Loading/Loading'
import { useSessionLanguage } from '../context/SessionSettingsContext'
import { ToastContext, errorToast } from '../toast/Toast'
import { WalletSetupIndicator } from './WalletSetupIndicator'

import styles from './WalletSetupLoader.module.scss'

export const WalletSetupLoader: React.FC = () => {
  useScrollToTop()
  const setToast = useContext(ToastContext)
  const { refreshUserWallets } = useWalletsWriteContext()
  const navigate = useNavigate()
  const locale = useSessionLanguage()
  const { t } = useTranslation()
  const { getBasePath } = useBasePath(true)

  const { refreshAccount } = useAccountWriteContext()

  const [isWalletInitialized, setWalletInitialized] = useState(false)
  const [walletsFetching, setWalletsFetching] = useState(false)
  const docVerificationPath = useDocumentVerificationPath()

  useEffect(() => {
    const timer = setTimeout(() => setWalletInitialized(true), 2000)
    return () => clearTimeout(timer)
  }, [])

  const handleClickNext = async () => {
    try {
      setWalletsFetching(true)
      const wallets = await refreshUserWallets()
      const newAccount = await refreshAccount(locale)

      if (
        !isDepositBeforeKYC(newAccount) &&
        isVerificationExpedited(newAccount) &&
        !isAccountActivatedStatus(newAccount)
      ) {
        navigate(docVerificationPath)
        return
      }

      if (!isDepositBeforeKYC(newAccount) && isSumsubNeeded(newAccount)) {
        const path = await getBasePath(newAccount)
        console.log({ path })
        navigate(path)
      }

      if (isWalletInitialized && (!wallets || wallets.length === 0)) {
        setToast(errorToast(t('errors.Something went wrong')))
        return
      }

      const targetPath = isAccountActivatedStatus(newAccount)
        ? CommonPaths.FirstTimeDepositDashboard
        : CommonPaths.FirstTimeDeposit
      navigate(targetPath, { state: { wallets } })
    } finally {
      setWalletsFetching(false)
    }
  }

  return (
    <Loading
      wrapperClass={styles.loadingWrapper}
      disableIconAnimation={isWalletInitialized}
      showLoadingIcon
      renderText={() => (
        <WalletSetupIndicator
          onSetupComplete={handleClickNext}
          isButtonDisabled={!isWalletInitialized || walletsFetching}
        />
      )}
      isLoading
    />
  )
}

import React, { ChangeEvent, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { useDebouncedCallback } from 'use-debounce'

import { DropArrowDownIconOutline } from '../../icons/DropArrowDownIcon'
import { DropArrowUpIconOutline } from '../../icons/DropArrowUpIcon'
import { NavigationIcon } from '../../icons/NavigationIcon'
import { TextSmall } from '../../ui/Typography/Typography'
import { PageMetaData } from '../../utils/ApiClient'
import { useWindowResize } from '../../utils/domUtils'
import { isOne, isZero } from '../../utils/validations'
import { useArabicSessionLanguage } from '../context/SessionSettingsContext'
import { SelectField } from '../field/SelectField'

import styles from './Paging.module.scss'

const PageSizesAllOptions = [10, 20, 50, 100, 250, 500, 1000]

interface Props {
  pageData?: PageMetaData
  isLoading?: boolean
  maxPageSize?: number
  minPageSize?: number
  onPageChanged(newPage: number, pageSize?: number, eventType?: PagingEventType): void
  scrollToHeaderId?: string
}

export enum PagingEventType {
  PAGE_CHANGED = 'pageChanged',
  ENTRIES_CHANGED = 'entriesChanged',
}

export const Paging: React.FC<Props> = (props) => {
  const { pageData, onPageChanged, maxPageSize } = props

  const pageSizes = useMemo(() => {
    const totalResults = props.pageData?.totalResults || 0
    const allowedPageSizes = []

    for (let i = 0; i < PageSizesAllOptions.length; i++) {
      if (maxPageSize && allowedPageSizes[allowedPageSizes.length - 1] === maxPageSize) {
        break
      }
      const previousSize = i === 0 ? 0 : PageSizesAllOptions[i - 1]
      const threshold = previousSize + 1

      if (totalResults >= threshold) {
        allowedPageSizes.push(PageSizesAllOptions[i])
      } else {
        break
      }
    }

    return allowedPageSizes.reverse()
  }, [props.pageData?.totalResults, maxPageSize])

  if (!pageData) {
    return null
  }

  if (props.pageData?.totalResults && props.pageData?.totalResults < PageSizesAllOptions[0] + 1) {
    return null
  }

  return (
    <div className={classNames('level', styles.pagingSpace)}>
      {pageData.totalPages > 1 ? <PaginationPage {...props} /> : <div />}
      {pageSizes.length ? (
        <PaginationSize {...props} pageSizes={pageSizes} onChange={onPageChanged} />
      ) : (
        <div />
      )}
    </div>
  )
}

interface PaginationSizeProps extends Props {
  pageSizes: number[]
  scrollToHeaderId?: string | undefined
  onChange(newPage: number, pageSize?: number, eventType?: PagingEventType): void
}

const PaginationSize: React.FC<PaginationSizeProps> = (props) => {
  const { pageData, pageSizes, onChange } = props

  const { t } = useTranslation()

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const newPageSize = Number(event.target.value)

    if (newPageSize !== pageData?.pageSize) {
      if (newPageSize < (pageData?.pageSize ?? 10)) {
        onChange(1, newPageSize, PagingEventType.ENTRIES_CHANGED)
      } else {
        onChange(1, newPageSize)
      }
    }
  }

  return (
    <div className={styles.innerWrapperCount}>
      <TextSmall className={styles.show}>{t('Show')}</TextSmall>
      <SelectField
        className={styles.select}
        selectClassName={styles.selectInput}
        name='pageSize'
        value={pageData?.pageSize}
        options={pageSizes.map((size) => ({
          value: size,
          label: size,
        }))}
        appearance='secondary'
        mini
        onChange={handleChange}
        renderArrowUpIcon={<DropArrowUpIconOutline size={24} />}
        renderArrowDownIcon={<DropArrowDownIconOutline size={24} />}
      />
    </div>
  )
}

const PaginationPage: React.FC<Props> = (props) => {
  const { pageData, onPageChanged } = props
  const { pageIndex } = pageData ?? { pageIndex: 1 }
  const { t } = useTranslation()
  const [newPage, setNewPage] = React.useState<number | undefined>()
  const isMobile = useWindowResize()
  const isArabic = useArabicSessionLanguage()

  const debounced = useDebouncedCallback((index, pageSize) => onPageChanged(index, pageSize), 500)

  const handlePageChange = async (pageIndex?: number) => {
    if (pageIndex && pageIndex !== pageData?.pageIndex) {
      if (pageIndex < 1) {
        debounced(1, pageData?.pageSize)
      } else if (pageIndex > (pageData?.totalPages ?? 1)) {
        debounced(pageData?.totalPages, pageData?.pageSize)
      } else {
        debounced(pageIndex, pageData?.pageSize)
      }
    }
  }

  if (!pageData) {
    return null
  }

  const isActive = (page: number) => page === pageIndex

  const getPrePages = () => {
    if (pageIndex > 3) {
      return [1]
    }
    return Array.from({ length: Math.min(3, pageData.totalPages) }, (_, i) => i + 1)
  }

  const getMidPage = () => {
    if (pageIndex > 3 && pageIndex < totalPages - 2) {
      return [pageIndex]
    }
    return []
  }

  const getPostPages = () => {
    if (totalPages < 4) {
      return []
    }
    if (pageIndex > totalPages - 3 && totalPages > 3 && pageIndex > 3) {
      return [totalPages - 2, totalPages - 1, totalPages]
    }
    return [totalPages]
  }

  const { totalPages } = pageData

  const Page = ({ page }: { page: number }) => (
    <TextSmall
      key={page}
      className={classNames(styles.page, { [styles.active]: isActive(page) })}
      onClick={() => handlePageChange(page)}
    >
      {page}
    </TextSmall>
  )

  return (
    <div className={styles.innerWrapper}>
      <div className={styles.clickersWrapper}>
        <NavigationIcon
          isReversed={isArabic}
          className={styles.navigationIcon}
          disabled={isOne(pageIndex)}
          onClick={() => handlePageChange(pageIndex - 1)}
        />
        {!isMobile && (
          <>
            <div className={styles.pages}>
              {getPrePages().map((page) => (
                <Page key={page} page={page} />
              ))}
              {!!getMidPage().length && <TextSmall>...</TextSmall>}
              {getMidPage().map((page) => (
                <Page key={page} page={page} />
              ))}
              {!!getPostPages().length && <TextSmall>...</TextSmall>}
              {getPostPages().map((page) => (
                <Page key={page} page={page} />
              ))}
            </div>
            <NavigationIcon
              direction='right'
              isReversed={isArabic}
              className={styles.navigationIcon}
              onClick={() => handlePageChange(pageIndex + 1)}
              disabled={pageIndex === totalPages || isZero(totalPages)}
            />
          </>
        )}
      </div>
      <input
        className={classNames(styles.input, 'input')}
        onBlur={() => handlePageChange(newPage)}
        onChange={(e) => setNewPage(e.target.value ? Number(e.target.value) : undefined)}
        onKeyDown={async (e) => {
          if (e.key === 'Enter') {
            handlePageChange(newPage)
          }
        }}
        type='number'
        max={totalPages}
        placeholder={pageIndex.toString()}
        min={1}
        value={totalPages > 0 ? newPage : 0}
      />
      <TextSmall className={styles.of}>
        <span>{t('of')}</span>
        <span>{totalPages}</span>
      </TextSmall>
      {isMobile && (
        <NavigationIcon
          direction='right'
          isReversed={isArabic}
          className={styles.navigationIcon}
          onClick={() => handlePageChange(pageIndex + 1)}
          disabled={pageIndex === totalPages || isZero(totalPages)}
        />
      )}
    </div>
  )
}

import { useContext } from 'react'

import { useEntitySettings } from '../global/context/EntityContext'
import { useNotificationsContext } from '../global/context/NotificationsContext'
import { usePublicDomainsWriteContext } from '../global/context/PublicDomainsContext'
import { useSessionLanguage } from '../global/context/SessionSettingsContext'
import { AuthUser, LoginResponse } from '../model/User'
import { useNavigate } from '../navigation/custom-react-router-dom'
import { AuthSessionContext } from '../utils/AuthContext'
import { entityPerTickmillCompany, getTickmillCompanyByHostname } from '../utils/companyName.utils'
import { dispatchEntitySelected } from '../utils/cookie.utils'
import { loginToAuth } from '../utils/loginToAuth'
import { TokenStorage } from '../utils/tokenStorage'
import { useLocallyPersistedState } from '../utils/useStorage'

export const useSignInAuth = (): {
  handleSignInAuth: (
    authData: LoginResponse | AuthUser,
    isUsersAuthAlreadyValidated?: boolean
  ) => void
} => {
  const [, setNotificationSettings] = useNotificationsContext()
  const [, setLuckyDrawViewed] = useLocallyPersistedState('luckyDrawViewed', false)
  const [, setAuth] = useContext(AuthSessionContext)
  const { refreshDomains } = usePublicDomainsWriteContext()
  const [, setEntity] = useEntitySettings()
  const locale = useSessionLanguage()
  const navigate = useNavigate()

  const handleSignInAuth = async (
    authData: LoginResponse | AuthUser,
    isUsersAuthPreValidated?: boolean
  ) => {
    setLuckyDrawViewed(false)
    setNotificationSettings({
      hideGeneralNotifications: false,
      hidePromoNotifications: false,
      hidePlatformNotifications: false,
    })

    const auth = isUsersAuthPreValidated
      ? (authData as AuthUser)
      : loginToAuth(authData as LoginResponse)

    if (!auth) {
      return
    }

    setAuth(auth)
    refreshDomains()
    if (
      auth.tickmillCompany &&
      !window.env.APP_ENTITY.includes(entityPerTickmillCompany[auth.tickmillCompany.id]) &&
      process.env.NODE_ENV !== 'development'
    ) {
      navigate('/wrong-entity')
      return
    } else {
      localStorage.setItem('auth', JSON.stringify(auth))
      if (auth.accessToken) {
        TokenStorage.storeToken('access', auth.accessToken)
      }
      if (auth.refreshToken) {
        TokenStorage.storeToken('refresh', auth.refreshToken)
      }
      if (auth.sessionId) {
        TokenStorage.storeSession(auth.sessionId)
      }
      if (auth.tickmillCompany) {
        setEntity({ entity: auth.tickmillCompany.id })
      }
      if (auth.email && auth.firstName) {
        dispatchEntitySelected(
          auth?.tickmillCompany.id || getTickmillCompanyByHostname(),
          locale,
          auth.email,
          auth.firstName
        )
      }
    }
  }

  return { handleSignInAuth }
}

import { NameDto } from '../model/NameDto'

export enum TradingAccountRestrictions {
  TRANSFER_FROM = 14,
  TRANSFER_TO = 15,
  LEVERAGE_DOWN = 21,
  LEVERAGE_UP = 22,
  CHANGE_INVESTOR_PASSWORD = 23,
  CHANGE_MASTER_PASSWORD = 24,
  RESET_INVESTOR_PASSWORD = 25,
  RESET_MASTER_PASSWORD = 26,
}

const TradingAccountRestriction: Record<TradingAccountRestrictions, string> = {
  [TradingAccountRestrictions.TRANSFER_FROM]: 'Transfer From',
  [TradingAccountRestrictions.TRANSFER_TO]: 'Transfer To',
  [TradingAccountRestrictions.LEVERAGE_DOWN]: 'Leverage Down',
  [TradingAccountRestrictions.LEVERAGE_UP]: 'Leverage Up',
  [TradingAccountRestrictions.CHANGE_INVESTOR_PASSWORD]: 'Change Investor Password',
  [TradingAccountRestrictions.CHANGE_MASTER_PASSWORD]: 'Change Master Password',
  [TradingAccountRestrictions.RESET_INVESTOR_PASSWORD]: 'Reset Investor Password',
  [TradingAccountRestrictions.RESET_MASTER_PASSWORD]: 'Reset Master Password',
}

export function isRestricted(
  restrictionId: TradingAccountRestrictions,
  restrictions: NameDto[] = []
): boolean {
  return (
    !!TradingAccountRestriction[restrictionId] &&
    !!restrictions.find((x) => x.name === TradingAccountRestriction[restrictionId])
  )
}

export function isTradingAccountSuspended(restrictions?: NameDto[]): boolean {
  return (
    !!restrictions?.find((x) => x.id === TradingAccountRestrictions.TRANSFER_FROM) &&
    !!restrictions?.find((x) => x.id === TradingAccountRestrictions.TRANSFER_TO) &&
    !!restrictions?.find((x) => x.id === TradingAccountRestrictions.LEVERAGE_DOWN) &&
    !!restrictions?.find((x) => x.id === TradingAccountRestrictions.LEVERAGE_UP) &&
    !!restrictions?.find((x) => x.id === TradingAccountRestrictions.CHANGE_INVESTOR_PASSWORD) &&
    !!restrictions?.find((x) => x.id === TradingAccountRestrictions.CHANGE_MASTER_PASSWORD) &&
    !!restrictions?.find((x) => x.id === TradingAccountRestrictions.RESET_INVESTOR_PASSWORD) &&
    !!restrictions?.find((x) => x.id === TradingAccountRestrictions.RESET_MASTER_PASSWORD)
  )
}

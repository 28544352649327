import { useContext, useEffect, useState } from 'react'
import Cookies from 'js-cookie'

import { useProductReadContext } from '../global/context/ProductContext'
import { ClientGuideDto, GuideTypes } from '../model/ClientGuideDto'
import { useApiClient } from './ApiClient'
import { AuthSessionContext } from './AuthContext'
import { ClientApiClient } from './clientApi'

const firstTimeGuideSessionName = 'fti_guide_session'

interface GuideHookData {
  guide?: ClientGuideDto
  fetchGuide: () => Promise<void>
  markGuideAsRead: () => Promise<void>
  isLoading: boolean
}

export const useGuide = (): GuideHookData => {
  const [auth] = useContext(AuthSessionContext)
  const [isLoading, setIsLoading] = useState(false)
  const [guide, setGuide] = useState<ClientGuideDto | undefined>(undefined)
  const { product } = useProductReadContext()
  const authSessionId = auth?.sessionId
  const apiClient = useApiClient(ClientApiClient)

  const fetchGuide = async () => {
    const guide = await apiClient.getGuideByTypeId(GuideTypes.IntroductionGuide, {
      tickmillProductId: product,
    })
    setGuide(guide)
  }

  const markGuideAsRead = async () => {
    if (!authSessionId) {
      return
    }
    const cookieName = `${firstTimeGuideSessionName}_${product}`

    const guideSessionId = Cookies.get(cookieName)
    if (authSessionId === guideSessionId) {
      return
    }
    Cookies.set(cookieName, authSessionId)
    await apiClient.updateClientGuideRead(GuideTypes.IntroductionGuide, {
      tickmillProductId: product,
    })
  }

  const handleGuide = async () => {
    try {
      setIsLoading(true)
      if (!authSessionId) {
        return
      }
      await markGuideAsRead()
      await fetchGuide()
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    handleGuide()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authSessionId])

  return {
    guide,
    fetchGuide,
    markGuideAsRead,
    isLoading,
  }
}

import { UAParser } from 'ua-parser-js'

import { useLinksConfig } from '../configs/configs'
import { usePublicDomainsReadContext } from '../global/context/PublicDomainsContext'
import {
  overrideForZHToCNLanguage,
  useSessionLanguage,
} from '../global/context/SessionSettingsContext'
import {
  getPlatformTypeByVersion,
  getVersionByPlatformType,
  isMT4PlatformType,
  isMT5PlatformType,
  isTMTPlatformType,
} from '../model/PlatformTypeEnum'
import { DemoTradingAccount, TradingAccount } from '../model/TradingAccount'
import { TickmillCompaniesEnum, TickmillCompaniesSuffixEnum } from '../utils/companyName.utils'

const parser = new UAParser()

interface SearchParamsProps {
  homePageUrl?: string
  locale?: string
  languageId?: string
  server?: string
  version?: string
  login?: string
  companyId?: string
}

const useSearchParams = (): SearchParamsProps => {
  const url = new URL(window.location.href)
  const searchParams = url.searchParams

  const { domains } = usePublicDomainsReadContext()
  const locale = useSessionLanguage()
  const languageId = searchParams.get('languageId') ?? overrideForZHToCNLanguage(locale)

  const homePageUrl = domains?.homePageUrl

  const server = searchParams.get('server') || undefined
  const version = searchParams.get('version') || undefined
  const login = searchParams.get('login') || undefined
  const companyId = searchParams.get('companyId') || undefined

  return { homePageUrl, locale, languageId, server, version, login, companyId }
}

export const useTraderUrlByPlatform = (): ((
  tradingAccount?: TradingAccount | DemoTradingAccount
) => string | undefined) => {
  const locale = useSessionLanguage()
  const { links } = useLinksConfig()
  const searchParams = useSearchParams()

  const getTraderUrlByPlatform = (
    tradingAccount?: TradingAccount | DemoTradingAccount | undefined
  ) => {
    const { platformType: accountPlatformType, server, platformOverview } = tradingAccount ?? {}

    const languageId = overrideForZHToCNLanguage(locale)

    const osName = parser.getResult().os.name?.toLowerCase()

    const homePageUrl = searchParams?.homePageUrl
    const hasRequiredSearchParams =
      searchParams.login && searchParams.server && searchParams.version && searchParams.companyId
    const searchParamsPlatformType = getPlatformTypeByVersion(searchParams.version)

    if (accountPlatformType || searchParamsPlatformType) {
      if (osName === 'ios') {
        if (isTMTPlatformType(accountPlatformType?.id)) {
          return links.TradingLinks.IOS.TMT
        }
        if (isMT4PlatformType(accountPlatformType?.id ?? searchParamsPlatformType)) {
          return links.TradingLinks.IOS.MT4
        }
        if (isMT5PlatformType(accountPlatformType?.id ?? searchParamsPlatformType)) {
          return links.TradingLinks.IOS.MT5
        }
      } else if (osName === 'android') {
        if (isTMTPlatformType(accountPlatformType?.id)) {
          return links.TradingLinks.Android.TMT
        }

        if (isMT4PlatformType(accountPlatformType?.id ?? searchParamsPlatformType)) {
          return links.TradingLinks.Android.MT4
        }

        if (isMT5PlatformType(accountPlatformType?.id ?? searchParamsPlatformType)) {
          return links.TradingLinks.Android.MT5
        }
      } else {
        if (isTMTPlatformType(accountPlatformType?.id)) {
          return links.TradingLinks.Web.TMT
        }
      }
    }

    if (hasRequiredSearchParams) {
      return buildTradeUrlWithSearchParams(searchParams)
    }

    if (
      platformOverview &&
      server &&
      getVersionByPlatformType(accountPlatformType?.id) &&
      accountPlatformType
    ) {
      const queryParams = new URLSearchParams({
        login: platformOverview.login,
        trade_server: server.publicName,
        version: getVersionByPlatformType(accountPlatformType.id),
      }).toString()

      return buildTradeUrl({ homePageUrl, languageId }, queryParams)
    }

    return
  }

  return getTraderUrlByPlatform
}

const buildTradeUrlWithSearchParams = (searchParams: SearchParamsProps) => {
  const { login, server, version, companyId } = searchParams

  if (login && server && version && companyId) {
    const urlSearchParams = {
      login,
      trade_server: server,
      version,
      companyId,
    }

    const params = new URLSearchParams(urlSearchParams)
    const queryParams = params.toString()

    return buildTradeUrl(searchParams, queryParams)
  }

  return
}

const buildTradeUrl = (searchParams: SearchParamsProps, queryParams: string) => {
  const tickmillURL = 'https://www.tickmill.com'
  const companyId = searchParams?.companyId ?? ''
  const isCompanyIdEU = String(TickmillCompaniesEnum.TICKMILL_EU) === companyId
  const isCompanyIdUK = String(TickmillCompaniesEnum.TICKMILL_UK) === companyId
  const companyIdEntityPath = isCompanyIdEU
    ? `/${TickmillCompaniesSuffixEnum.TICKMILL_EU.toLowerCase()}`
    : isCompanyIdUK
    ? `/${TickmillCompaniesSuffixEnum.TICKMILL_UK.toLowerCase()}`
    : ''

  const searchQueryRequiredKeys = ['login', 'server', 'version', 'companyId']
  const hasRequiredSearchQueryParamKeys = searchQueryRequiredKeys.every(
    (key: string) => key in searchParams
  )

  const { languageId } = searchParams

  const homePageUrl = hasRequiredSearchQueryParamKeys
    ? `${tickmillURL}${companyIdEntityPath}`
    : searchParams.homePageUrl

  if (homePageUrl && languageId !== 'en' && queryParams) {
    return `${homePageUrl}/${languageId}/trade?${queryParams}`
  }

  if (homePageUrl && queryParams) {
    return `${homePageUrl}/trade?${queryParams}`
  }

  return undefined
}

import { IconProps } from './IconProps'

export const ChartLineUpIcon = (props: IconProps): JSX.Element => {
  const width = props.width || '40'
  const height = props.height || '40'
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='chart-line-up'>
        <path
          id='Vector'
          d='M36.248 32.5C36.248 32.8315 36.1164 33.1495 35.8819 33.3839C35.6475 33.6183 35.3296 33.75 34.998 33.75H4.99805C4.66653 33.75 4.34858 33.6183 4.11416 33.3839C3.87974 33.1495 3.74805 32.8315 3.74805 32.5V7.5C3.74805 7.16848 3.87974 6.85054 4.11416 6.61612C4.34858 6.3817 4.66653 6.25 4.99805 6.25C5.32957 6.25 5.64751 6.3817 5.88193 6.61612C6.11635 6.85054 6.24805 7.16848 6.24805 7.5V24.4828L14.1137 16.6156C14.2298 16.4994 14.3676 16.4072 14.5194 16.3443C14.6711 16.2814 14.8338 16.249 14.998 16.249C15.1623 16.249 15.325 16.2814 15.4767 16.3443C15.6285 16.4072 15.7663 16.4994 15.8824 16.6156L19.998 20.7328L28.2309 12.5H24.998C24.6665 12.5 24.3486 12.3683 24.1142 12.1339C23.8797 11.8995 23.748 11.5815 23.748 11.25C23.748 10.9185 23.8797 10.6005 24.1142 10.3661C24.3486 10.1317 24.6665 10 24.998 10H31.248C31.5796 10 31.8975 10.1317 32.1319 10.3661C32.3664 10.6005 32.498 10.9185 32.498 11.25V17.5C32.498 17.8315 32.3664 18.1495 32.1319 18.3839C31.8975 18.6183 31.5796 18.75 31.248 18.75C30.9165 18.75 30.5986 18.6183 30.3642 18.3839C30.1297 18.1495 29.998 17.8315 29.998 17.5V14.2672L20.8824 23.3844C20.7663 23.5006 20.6285 23.5928 20.4767 23.6557C20.325 23.7186 20.1623 23.751 19.998 23.751C19.8338 23.751 19.6711 23.7186 19.5194 23.6557C19.3676 23.5928 19.2298 23.5006 19.1137 23.3844L14.998 19.2672L6.24805 28.0172V31.25H34.998C35.3296 31.25 35.6475 31.3817 35.8819 31.6161C36.1164 31.8505 36.248 32.1685 36.248 32.5Z'
          fill='#CC0000'
        />
      </g>
    </svg>
  )
}

import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Button } from '../../../global/button/Button'

import styles from './CalendarFooter.module.scss'

interface CalendarFooterProps {
  className?: string
  isClearDisabled?: boolean
  isConfirmDisabled?: boolean
  onClear: () => void
  onConfirm: () => void
}

export const CalendarFooter: FC<CalendarFooterProps> = (props) => {
  const { className, isClearDisabled, isConfirmDisabled, onClear, onConfirm } = props

  const { t } = useTranslation()

  return (
    <div className={classNames(styles.calendarFooter, className)}>
      <div className={styles.buttons}>
        <Button.New
          className={styles.button}
          disabled={isClearDisabled}
          type='button'
          onClick={() => onClear()}
          appearance='secondary'
          size='S'
        >
          {t('Clear')}
        </Button.New>
        <Button
          disabled={isConfirmDisabled}
          className={styles.button}
          type='submit'
          appearance='primary'
          size='S'
          onClick={() => onConfirm()}
        >
          {t('Apply')}
        </Button>
      </div>
    </div>
  )
}

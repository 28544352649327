import React, { PropsWithChildren, useContext, useEffect, useState } from 'react'
import classNames from 'classnames'

import { useTraderRoomRoutesConditions } from '../../../Traders-Room/TradersRoomRoutes'
import { useLossPercentagesReadContext } from '../../../global/context/LossPercentagesContext'
import {
  useArabicSessionLanguage,
  useSessionLanguage,
} from '../../../global/context/SessionSettingsContext'
import IconButton from '../../../global/iconButton/IconButton'
import { CancelActionModal } from '../../../global/modal/CancleActionModal'
import { Modal } from '../../../global/modal/Modal'
import { BackIcon } from '../../../icons/BackIcon'
import { CloseIcon } from '../../../icons/CloseIcon'
import { ForwardIcon } from '../../../icons/ForwardIcon'
import { WarningIcon } from '../../../icons/WarningIcon'
import { useNavigate } from '../../../navigation/custom-react-router-dom'
import { TextH3 } from '../../../ui/Typography/Typography'
import { requireDepositBeforeKYC } from '../../../utils/AccountAccess/accountKYCStatuses'
import { useAccountReadContext, useAccountWriteContext } from '../../../utils/AccountContextContext'
import { AuthSessionContext } from '../../../utils/AuthContext'
import { CommonPaths } from '../../../utils/path'
import { RiskWarningModal } from '../Modals/RiskWarningModal'
import { useProducts } from '../hooks/useProducts'

import styles from './SignupFormWrapper.module.scss'

interface SignupFormWrapperProps {
  label: string
  shouldHideBackArrow?: boolean

  onBackToPreviousStep?(): void
  cancelPath?: string
}

export const SignupFormWrapper: React.FC<PropsWithChildren<SignupFormWrapperProps>> = ({
  label,
  children,
  ...props
}) => {
  const navigate = useNavigate()
  const [auth] = useContext(AuthSessionContext)
  const locale = useSessionLanguage()
  const { account } = useAccountReadContext()
  const { refreshAccount } = useAccountWriteContext()
  const isArabic = useArabicSessionLanguage()
  const { isTradersRoomVisible } = useTraderRoomRoutesConditions()

  const redirectLink = isTradersRoomVisible
    ? '/dashboard/traders-room/wallets'
    : '/dashboard/introducing-broker/wallets'

  const [isHighRiskModalVisible, setHighRiskModalVisible] = useState(false)
  const [confirmationModal, setConfirmationModal] = useState(false)
  const [closeIconVisible, setCloseIconVisible] = useState(false)
  const { selectedProductIds } = useProducts()

  const { percentages } = useLossPercentagesReadContext()

  // useEffect(() => {
  //   if (Array.isArray(props.selectedProductIds)) {
  //     setSelectedProductIds(props.selectedProductIds || [])
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props.selectedProductIds])

  useEffect(() => {
    if (!props.shouldHideBackArrow && props.onBackToPreviousStep) {
      setCloseIconVisible(false)
    } else {
      setCloseIconVisible(true)
    }
  }, [props.onBackToPreviousStep, props.shouldHideBackArrow])

  const handleCancelAction = () => {
    if (!auth) {
      navigate('/login')
    } else {
      refreshAccount(locale)
      if (requireDepositBeforeKYC(account)) {
        return navigate(props.cancelPath || CommonPaths.FirstTimeDeposit)
      }
      navigate(props.cancelPath || redirectLink)
    }
  }

  return (
    <React.Fragment>
      {isHighRiskModalVisible && (
        <Modal
          closeModal={() => setHighRiskModalVisible(false)}
          render={({ closeModal }) => (
            <RiskWarningModal
              onCancel={closeModal}
              selectedProductIds={selectedProductIds}
              risk={percentages?.percentage}
            />
          )}
        />
      )}
      {confirmationModal && (
        <Modal
          closeModal={() => setConfirmationModal(false)}
          render={({ closeModal }) => (
            <CancelActionModal onConfirm={handleCancelAction} onCancel={closeModal} />
          )}
        />
      )}
      <div className={styles.wrapper}>
        <div className={styles.innerWrapper}>
          {!props.shouldHideBackArrow && props.onBackToPreviousStep && (
            <button
              id='signup_backarrow'
              onClick={props.onBackToPreviousStep}
              className={classNames('button', styles.closeButton)}
            >
              {isArabic ? <ForwardIcon /> : <BackIcon />}
            </button>
          )}
          <button
            type='button'
            className={classNames('button', styles.closeButton, {
              'is-hidden': !closeIconVisible,
            })}
            onClick={() => setConfirmationModal(true)}
          >
            <CloseIcon size={14} />
          </button>
          <TextH3>{label}</TextH3>
          <IconButton onClick={() => setHighRiskModalVisible(true)}>
            <WarningIcon size={20} color={'warning'} />
          </IconButton>
        </div>
        {children}
      </div>
    </React.Fragment>
  )
}

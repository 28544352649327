import React from 'react'

import { WalletDepositTransactionCompletedPage } from '../../Traders-Room/Wallets/WalletDeposit/WalletDepositTransactionPage/WalletDepositTransactionCompletedPage'
import { useNavigate } from '../../navigation/custom-react-router-dom'

export const PaymentProviderWalletDepositTransactionCompletedPage: React.FC = () => {
  const navigate = useNavigate()

  const handleCancel = () => {
    navigate('/dashboard/payment-agent/transaction-history')
  }

  return <WalletDepositTransactionCompletedPage onCancel={handleCancel} />
}

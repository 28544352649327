import { FormikErrors } from 'formik'
import { TFunction } from 'i18next'

import { FilterQueryProps } from '../global/filter/FilterQueryModal'
import {
  EmailPattern,
  EmojiPattern,
  InputTextPattern,
  LowerCasePattern,
  RestrictedSpecialCharacters,
  SymbolPattern,
  UUIDExpressionPattern,
  UpperCasePattern,
  UrlHttpPattern,
  UrlPattern,
} from './formValidation'

export const hasNumber = (str: string): boolean => {
  return /\d/.test(str)
}

export const hasUpperCase = (str: string): boolean => {
  return UpperCasePattern.test(str)
}

export const hasLowercase = (str: string): boolean => {
  return LowerCasePattern.test(str)
}

export const isChinese = (text: string): boolean => {
  const rgx = /^[\u4e00-\u9fa5\u00B7]+$/
  return rgx.test(text)
}

export const hasSymbol = (str: string): boolean => {
  return SymbolPattern.test(str)
}

export function isUUID(str: string): boolean {
  return UUIDExpressionPattern.test(str)
}

export const isNumber = (amount: string): boolean => {
  return /^\d+(\.\d{0,2})?$/.test(amount)
}

export function removeRestrictedSpecialCharacters(v: string): string {
  return removeEmojis(v).replace(RestrictedSpecialCharacters, '')
}

export function removeEmojis(v: string | number): string {
  if (typeof v !== 'string' && typeof v !== 'number') {
    return v
  }
  return v.toString().replace(EmojiPattern, '')
}

export function minTwoDigits(n: unknown): string {
  if (typeof n !== 'number') {
    return String(n)
  }
  return (n < 10 ? '0' : '') + n
}

export function isEmailValid(email: string): boolean {
  if (!email) {
    return false
  }
  return EmailPattern.test(email)
}

export const isFullUrl = (url: string): boolean => {
  return UrlHttpPattern.test(url)
}

export const removeInputTextSpecialCharacters = (str: string): string => {
  return str.match(InputTextPattern)?.join('') || ''
}

export const validateUrl = (value: string): boolean => {
  return !UrlPattern.test(value)
}

export const trimToMaxLength = (value: string, maxLength: number): string => {
  const trimmed = value.trim()
  return trimmed.length > maxLength ? trimmed.slice(0, maxLength) : trimmed
}

export const isOne = (value?: string | number | null): boolean => value?.toString() === '1'
export const isZero = (value?: string | number | null): boolean => value?.toString() === '0'

export const minNumber = (value: string | number, min: number): boolean => Number(value) < min
export const maxNumber = (value: string | number, max: number): boolean => Number(value) > max

export const hasArabicCharacters = (text: string): boolean =>
  /[\u0600-\u06FF\u0750-\u077F]/.test(text)
export const hasOnlyArabicCharacters = (text: string): boolean => /^[\u0600-\u06FF]+$/.test(text)
export const hasArabicWithSpacesAndDashes = (text: string): boolean =>
  /^[\u0600-\u06FF\s-]+$/.test(text)

export const createAmountRangeValidator =
  (values: FilterQueryProps, errors: FormikErrors<FilterQueryProps>, t: TFunction) =>
  (fieldFrom: keyof FilterQueryProps, fieldTo: keyof FilterQueryProps): void => {
    if (
      values[fieldFrom] &&
      values[fieldTo] &&
      Number(values[fieldFrom]) > Number(values[fieldTo])
    ) {
      errors[fieldTo] = t('Validation.From amount cannot be greater than To amount')
    }
  }

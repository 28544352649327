import { IconProps } from './IconProps'

export function TickmillTraderLogo(props: IconProps): JSX.Element {
  const { size = 24 } = props

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask id='mask0_2400_45222' maskUnits='userSpaceOnUse' x='0' y='0' width='40' height='40'>
        <path
          d='M26.0543 0H13.9457C9.93 0 7.92233 0 5.761 0.683329C3.401 1.54234 1.54233 3.401 0.683334 5.761C0 7.92233 0 9.93033 0 13.9457V26.0543C0 30.07 0 32.0773 0.683334 34.239C1.54233 36.599 3.401 38.4577 5.761 39.3163C7.92233 40 9.93 40 13.9457 40H26.0543C30.07 40 32.0773 40 34.239 39.3163C36.599 38.4577 38.4577 36.599 39.3167 34.239C40 32.0773 40 30.07 40 26.0543V13.9457C40 9.93033 40 7.92233 39.3167 5.761C38.4577 3.401 36.599 1.54234 34.239 0.683329C32.0773 0 30.07 0 26.0543 0Z'
          fill='#C4C4C4'
        />
      </mask>
      <g mask='url(#mask0_2400_45222)'>
        <rect
          width={size}
          height={size}
          transform='translate(0.000976562 -0.00012207)'
          fill='#01C6D4'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M13.7932 16.4138C13.7084 16.561 13.6593 16.726 13.6498 16.8955V22.2711L7.16699 26V13.8855C7.18842 13.4609 7.30836 13.0471 7.51735 12.6767C7.72633 12.3063 8.01864 11.9896 8.37121 11.7514L18.7893 5.6224C19.1575 5.4324 19.5659 5.33325 19.9803 5.33325C20.3947 5.33325 20.8032 5.4324 21.1713 5.6224L31.5876 11.7532C31.6386 11.7837 31.6872 11.818 31.733 11.8558L25.1394 15.6519L20.467 12.9432C20.3159 12.8665 20.1489 12.8266 19.9794 12.8266C19.8099 12.8266 19.6429 12.8665 19.4918 12.9432L14.1378 16.0477C13.9959 16.1412 13.8779 16.2666 13.7932 16.4138ZM26.3101 23.1043V17.7323L32.7929 13.9988V26.1134C32.7715 26.538 32.6515 26.9518 32.4425 27.3221C32.2336 27.6925 31.9413 28.0093 31.5887 28.2475L21.1715 34.3774C20.8033 34.5674 20.3949 34.6665 19.9805 34.6665C19.5661 34.6665 19.1577 34.5674 18.7895 34.3774L8.3723 28.2475C8.32212 28.2158 8.27359 28.1815 8.22689 28.1449L14.816 24.3488L19.492 27.0574C19.6431 27.1341 19.8101 27.1741 19.9796 27.1741C20.149 27.1741 20.3161 27.1341 20.4672 27.0574L25.8221 23.953C25.9641 23.8594 26.0822 23.7338 26.1669 23.5865C26.2517 23.4391 26.3007 23.274 26.3101 23.1043Z'
          fill='white'
        />
      </g>
    </svg>
  )
}

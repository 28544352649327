import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../../global/button/Button'
import { useNavigate } from '../../navigation/custom-react-router-dom'
import { Text } from '../../ui/Typography/Typography'
import { useScrollToTop } from '../../utils/useScrollToTop'
import { NoteWrapper } from '../Signup/Components/NoteWrapper'

import styles from './Blocked.module.scss'

export const BlockedNote: React.FC = () => {
  useScrollToTop()
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <NoteWrapper
      title={t('Sign up.Your registration has been unsuccessful') + '!'}
      text={
        <Text>
          {t('Sign up.From the information you have provided, we do not think', {
            email: 'support@tickmill.co.uk',
          })}
        </Text>
      }
      button={
        <Button
          className={styles.button}
          appearance='primary'
          size='L'
          onClick={() => navigate('/')}
        >
          {t('Close')}
        </Button>
      }
    />
  )
}

import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

import { useNavigate } from '../navigation/custom-react-router-dom'
import { useSessionLanguage, useSessionSettings } from './context/SessionSettingsContext'
import { AllLocales, Locale } from './locale/Locale'

export const LocaleModule = (): null => {
  const locale = useSessionLanguage()
  const location = useLocation()
  const navigate = useNavigate()
  const [, setSettings] = useSessionSettings()
  const isFirstRender = useRef(true)

  useEffect(() => {
    const { pathname, search } = location
    const hasRedirQuery = new URLSearchParams(location.search).get('redir') !== null
    const hasRedirectQuery = hasRedirQuery && location.pathname.length > 1

    const pathSegments = pathname.split('/')
    const firstSegment = pathSegments[1]

    const hasLocale = AllLocales.includes(firstSegment as Locale)

    if (hasRedirectQuery) {
      const newPath = `/${location.search}`
      navigate(newPath, { replace: true })
    } else if (hasLocale && firstSegment !== locale) {
      if (isFirstRender.current) {
        setSettings({ locale: firstSegment as Locale })
      } else {
        const newPath = `/${locale}/${pathSegments.slice(2).join('/')}${search}`
        navigate(newPath, { replace: true })
      }
    } else if (!hasLocale) {
      const newPath = `/${locale}${pathname}${search}`
      navigate(newPath, { replace: true })
    }
    isFirstRender.current = false
  }, [locale, location, navigate, setSettings])

  return null
}

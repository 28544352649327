import React, { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { LeadDto } from '../../../../model/LeadDto'
import { Text } from '../../../../ui/Typography/Typography'
import { TickmillCompaniesEnum } from '../../../../utils/companyName.utils'
import { useScrollToTop } from '../../../../utils/useScrollToTop'
import { LeftSection } from '../../Components/LeftSection'
import { SignupFormWrapper } from '../../Components/SignupFormWrapper'
import { SignupSections, SignupStep } from '../../Components/SignupStep'
import { SignupWrapper } from '../../Components/SignupWrapper'

import styles from './CompanyDetailsTemplate.module.scss'

export interface CompanyDetailsTemplateProps {
  lead?: LeadDto
  step: number

  goBack?: () => void
  reversed?: boolean
  totalSteps: number
}

export const CompanyDetailsTemplate: React.FC<PropsWithChildren<CompanyDetailsTemplateProps>> = (
  props
) => {
  const { lead, step, children, totalSteps, goBack, reversed } = props
  useScrollToTop(step)

  const { t } = useTranslation()

  return (
    <SignupFormWrapper
      label={t('Sign up.Sign up')}
      shouldHideBackArrow={step === 4}
      onBackToPreviousStep={
        goBack
          ? () => {
              if (step > 4) {
                goBack()
              }
            }
          : undefined
      }
    >
      <SignupStep
        subStep={{
          current: step,
          total: totalSteps,
        }}
        section={SignupSections.CompanyDetails}
        accountTypeId={lead?.type?.id}
      />
      <SignupWrapper>
        <React.Fragment>
          <LeftSection
            title={t('Sign up.Company Details')}
            description={() => (
              <div>
                {lead?.tickmillCompany.id === TickmillCompaniesEnum.TICKMILL_UK ? (
                  <Text isParagraph>
                    {t(
                      "Sign up.Next up, we'd like to know a little bit more about your company UK"
                    )}
                  </Text>
                ) : (
                  <Text isParagraph>
                    {t("Sign up.Next up, we'd like to know a little bit more about your company")}
                  </Text>
                )}
                <br />
                <Text isParagraph>
                  {t('Sign up.Be prepared by having your company documents next to you')}
                </Text>
                <br />
              </div>
            )}
          />
          <section
            className={classNames(styles.formSection, styles.wrapper, {
              [styles.reversed]: reversed,
            })}
          >
            {children}
          </section>
        </React.Fragment>
      </SignupWrapper>
    </SignupFormWrapper>
  )
}

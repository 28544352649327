import { NameDto } from '../model/NameDto'
import { WalletDto } from '../model/WalletDto'

export enum WalletRestrictions {
  CREATE_WALLET = 2,
  ATTACH_TRADING_ACCOUNTS = 10,
  ADD_TRADING_ACCOUNT = 11,
  DEPOSIT = 12,
  WITHDRAW = 13,
  TRANSFER_FROM = 14,
  TRANSFER_TO = 15,
}

const WalletRestriction: Record<WalletRestrictions, string> = {
  [WalletRestrictions.ATTACH_TRADING_ACCOUNTS]: 'Attach Trading Account',
  [WalletRestrictions.ADD_TRADING_ACCOUNT]: 'Add Trading Account',
  [WalletRestrictions.DEPOSIT]: 'Deposit',
  [WalletRestrictions.WITHDRAW]: 'Withdraw',
  [WalletRestrictions.TRANSFER_FROM]: 'Transfer From',
  [WalletRestrictions.TRANSFER_TO]: 'Transfer To',
  [WalletRestrictions.CREATE_WALLET]: 'Create Wallet',
}

export function isRestricted(
  restrictionId: WalletRestrictions,
  restrictions: NameDto[] = []
): boolean {
  return (
    !!WalletRestriction[restrictionId] &&
    !!restrictions.find((x) => x.name === WalletRestriction[restrictionId])
  )
}

export function isDepositEnabledFromAction(action: WalletDto['depositAction']): boolean {
  if (action.canDeposit) {
    if (action.availableToDeposit) {
      return action.availableToDeposit > 0
    }
    return true
  }
  return false
}

export function isWalletSuspended(restrictions: NameDto[] = []): boolean {
  return (
    !!restrictions.find((x) => x.id === WalletRestrictions.DEPOSIT) &&
    !!restrictions.find((x) => x.id === WalletRestrictions.WITHDRAW) &&
    !!restrictions.find((x) => x.id === WalletRestrictions.TRANSFER_FROM) &&
    !!restrictions.find((x) => x.id === WalletRestrictions.TRANSFER_TO)
  )
}

import React, { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { BottomLeftCornerLineIcon } from '../../icons/BottomLeftCornerLineIcon'
import { DropArrowDownIcon } from '../../icons/DropArrowDownIcon'
import { DropArrowUpIcon } from '../../icons/DropArrowUpIcon'
import { VerticalLineIcon } from '../../icons/VerticalLineIcon'
import { useWindowResize } from '../../utils/domUtils'

import styles from './Table.module.scss'

type Spacing = 'consistent' | 'none'

export type TableProps = React.DetailedHTMLProps<
  React.TableHTMLAttributes<HTMLTableElement>,
  HTMLTableElement
> & {
  inactive?: boolean
  spacing?: Spacing
  showOnMobile?: boolean
  wrapperClassName?: string
  size?: 'normal' | 'small'
}

export const Table: React.FC<TableProps> = (props) => {
  const isMobile = useWindowResize()
  const {
    children,
    inactive,
    showOnMobile,
    spacing = 'consistent',
    className,
    size = 'normal',
    wrapperClassName,
  } = props

  if (!showOnMobile && isMobile) {
    return null
  }

  return (
    <div className={classNames(styles.tableWrapper, wrapperClassName, 'table-wrapper')}>
      <table
        className={classNames(styles.table, className, {
          [styles.inactive]: inactive,
          [styles.tableSeparate]: spacing === 'consistent',
          [styles.tableSmall]: size === 'small',
        })}
      >
        {children}
      </table>
    </div>
  )
}

export type TableHeadProps = React.DetailedHTMLProps<
  React.TableHTMLAttributes<HTMLTableHeaderCellElement>,
  HTMLTableHeaderCellElement
>

export const TableHead: React.FC<TableHeadProps> = (props) => {
  const { children } = props

  return <thead className={styles.thead}>{children}</thead>
}

export type TableHeadRowProps = React.DetailedHTMLProps<
  React.TableHTMLAttributes<HTMLTableRowElement>,
  HTMLTableRowElement
> & {
  selected?: boolean
  id?: string
}

export const TableHeadRow: React.FC<TableHeadRowProps> = ({ children, selected, id }) => {
  return (
    <tr
      className={classNames('section section-step', {
        [styles.selected]: selected,
      })}
      id={id}
    >
      {children}
    </tr>
  )
}

export const TableBody: React.FC<
  PropsWithChildren & { spacing?: Spacing; margined?: boolean; id?: string }
> = (props) => {
  const { children, spacing = 'consistent', id } = props

  return (
    <tbody
      id={id}
      className={classNames('section section-step', styles.tbody, {
        [styles.tableBodySeparate]: spacing === 'consistent',
        [styles.tableBodyMargined]: props.margined,
      })}
    >
      {children}
    </tbody>
  )
}

export type TableRowProps = React.DetailedHTMLProps<
  React.TableHTMLAttributes<HTMLTableRowElement>,
  HTMLTableRowElement
>

export const ExpandableRow: React.FC<
  TableRowProps & {
    id?: string
    rowId: string
    isExpanded: boolean
    setExpandedRow: (rowId?: string) => void
  }
> = (props) => {
  const { children, className, isExpanded, setExpandedRow, rowId, ...restProps } = props

  return (
    <>
      <tr
        className={classNames('section section-step', styles.expandableRow, styles.tr, className)}
        {...restProps}
      >
        {!isExpanded && (
          <td align='center' width={50} className={styles.expandableRowContent}>
            <DropArrowDownIcon
              className={styles.expandableRowIcon}
              onClick={() => setExpandedRow(rowId)}
            />
          </td>
        )}
        {isExpanded && (
          <td align='center' width={50} className={styles.expandableRowContent}>
            <DropArrowUpIcon
              className={styles.expandableRowIcon}
              onClick={() => setExpandedRow(undefined)}
            />
          </td>
        )}
        {children}
      </tr>
    </>
  )
}

export const SubTableRow: React.FC<TableRowProps & { isLastRow?: boolean }> = (props) => {
  const { children, className, isLastRow, ...restProps } = props

  return (
    <tr
      className={classNames('section section-step', styles.expandableRow, styles.tr, className)}
      {...restProps}
    >
      {!isLastRow && (
        <TableCell noPadding width={50} className={styles.subTableRowContent}>
          <VerticalLineIcon
            size={30}
            color='contrastMediumLow'
            className={styles.subTableRowIcon}
          />
        </TableCell>
      )}
      {isLastRow && (
        <td width={50} className={styles.subTableRowContent}>
          <BottomLeftCornerLineIcon
            color='contrastMediumLow'
            className={classNames(styles.subTableRowIcon, styles.subTableRowLastIcon)}
          />
        </td>
      )}
      {children}
    </tr>
  )
}

export const TableRow: React.FC<TableRowProps> = (props) => {
  const { children, className, ...restProps } = props

  return (
    <tr className={classNames('section section-step', styles.tr, className)} {...restProps}>
      {children}
    </tr>
  )
}

type TableCellClassname = {
  cellClassName?: string
  colSpan?: number
  expanded?: boolean
  spacing?: Spacing
}

type TableCellProps = React.DetailedHTMLProps<
  React.TableHTMLAttributes<HTMLTableDataCellElement>,
  HTMLTableDataCellElement
> &
  TableCellClassname & { noPadding?: boolean }

export const TableCell: React.FC<TableCellProps> = (props) => {
  const { children, cellClassName, noPadding, expanded, ...restProps } = props
  return (
    <td
      {...restProps}
      className={classNames(styles.td, cellClassName, {
        [styles.noPaddingCell]: noPadding,
        [styles.expanded]: expanded,
      })}
    >
      {children}
    </td>
  )
}

export const TableDivider: React.FC = () => {
  return <div className={styles.divider} />
}

export const TableNoResults: React.FC = () => {
  const { t } = useTranslation()

  return (
    <tr>
      <td align='center' colSpan={100} className={styles.noResult}>
        {t('No results')}
      </td>
    </tr>
  )
}

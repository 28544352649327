import React, { useEffect } from 'react';



import { useProductReadContext, useProductWriteContext } from '../global/context/ProductContext';
import { useNavigate } from '../navigation/custom-react-router-dom';
import { useAccountReadContext } from '../utils/AccountContextContext';
import { useBasePath } from '../utils/path';





export const ProductRedirect: React.FC = () => {
  const { account } = useAccountReadContext()
  const { changeProductContext } = useProductWriteContext()
  const { product } = useProductReadContext()
  const navigate = useNavigate()
  const { redirectionLink } = useBasePath()

  useEffect(() => {
    navigate(redirectionLink)
  }, [
    account?.preferredLandingPage?.id,
    account?.preferredLandingPage?.tickmillProductId,
		redirectionLink,
    changeProductContext,
    navigate,
    product,
  ])

  return null
}

import React, { PropsWithChildren, ReactElement, RefObject, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Button, ButtonProps } from '../../global/button/Button'
import { DemoTradingAccount, TradingAccount } from '../../model/TradingAccount'
import { TextSmallStrong, TextTiny } from '../../ui/Typography/Typography'

import styles from './TradingAccountsTable.module.scss'

export interface ButtonLinkWrapperProps {
  disabled: boolean | undefined
  tradingAccount: TradingAccount | DemoTradingAccount
  children: ReactElement
  getPlatformTradeUrl: (
    tradingAccount: TradingAccount | DemoTradingAccount | undefined
  ) => Promise<string | undefined>
}

interface TradeHandleArgs {
  tradingAccount: TradingAccount | DemoTradingAccount
  linkRef: RefObject<HTMLAnchorElement>
  setIsLoading: (isLoading: boolean) => void
  setURL: (URL: string | null) => void
  getPlatformTradeUrl: (
    tradingAccount: TradingAccount | DemoTradingAccount | undefined
  ) => Promise<string | undefined>
}

const handleTradeClick = async (args: TradeHandleArgs) => {
  const { tradingAccount, setIsLoading, setURL, linkRef, getPlatformTradeUrl } = args

  setIsLoading(true)

  try {
    const platformTradeUrl = await getPlatformTradeUrl(tradingAccount)
    setURL(platformTradeUrl || null)

    if (platformTradeUrl && linkRef.current) {
      linkRef.current.href = platformTradeUrl
      linkRef.current.click()
    }
  } finally {
    setIsLoading(false)
  }
}

export const TradeButtonLinkWrapper: React.FC<PropsWithChildren<ButtonLinkWrapperProps>> = ({
  disabled,
  tradingAccount,
  getPlatformTradeUrl,
  children,
}): JSX.Element => {
  const { t } = useTranslation()
  const [URL, setURL] = useState<string | null>('')
  const [isLoading, setIsLoading] = useState(false)
  const linkRef = useRef<HTMLAnchorElement>(null)

  const childrenWithProps = React.isValidElement(children)
    ? React.cloneElement(children as React.ReactElement<ButtonProps>, {
        disabled,
      })
    : children

  if (disabled) {
    return childrenWithProps
  }

  if (URL === null) {
    return <></>
  }

  const tradeClickArgs: TradeHandleArgs = {
    tradingAccount,
    linkRef,
    setIsLoading,
    setURL,
    getPlatformTradeUrl,
  }

  return !URL ? (
    <Button
      size='XS'
      appearance='primary'
      disabled={isLoading}
      loading={isLoading}
      onClick={() => handleTradeClick(tradeClickArgs)}
    >
      <TextTiny className={classNames({ [styles.visibilityHidden]: isLoading })}>
        {t('Trade')}
      </TextTiny>
      <a target='_blank' href={URL} rel='noreferrer' ref={linkRef} className='is-hidden' />
    </Button>
  ) : (
    <a className='is-link' target='_blank' href={URL} rel='noreferrer' ref={linkRef}>
      {children}
    </a>
  )
}

export const MobileTradeButtonLinkWrapper: React.FC<
  Omit<ButtonLinkWrapperProps, 'disabled' | 'children'>
> = ({ tradingAccount, getPlatformTradeUrl }): JSX.Element => {
  const { t } = useTranslation()
  const [URL, setURL] = useState<string | null>('')
  const [isLoading, setIsLoading] = useState(false)
  const linkRef = useRef<HTMLAnchorElement>(null)

  if (URL === null) {
    return <></>
  }

  const tradeClickArgs: TradeHandleArgs = {
    tradingAccount,
    linkRef,
    setIsLoading,
    setURL,
    getPlatformTradeUrl,
  }

  return !URL ? (
    <Button
      size='M'
      appearance='primary'
      disabled={isLoading}
      loading={isLoading}
      onClick={() => handleTradeClick(tradeClickArgs)}
      fullWidth
      className={'mb-4'}
    >
      <TextSmallStrong className={classNames({ [styles.visibilityHidden]: isLoading })}>
        {t('Trade')}
      </TextSmallStrong>
      <a className='is-hidden' target='_blank' href={URL} rel='noreferrer' ref={linkRef} />
    </Button>
  ) : (
    <a className='is-link' target='_blank' href={URL} rel='noreferrer'>
      <Button size='M' appearance='primary' fullWidth className={'mb-4'}>
        <TextSmallStrong>{t('Trade')}</TextSmallStrong>
      </Button>
    </a>
  )
}

import React, { FormEvent, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import classNames from 'classnames'

import { AppMarketLink } from '../../../../global/appMarketLink/mobile/AppMarketLink'
import { Button } from '../../../../global/button/Button'
import { useSignup } from '../../../../global/context/SignupContext'
import { AccountType } from '../../../../model/AccountType'
import { useNavigate } from '../../../../navigation/custom-react-router-dom'
import { Text, TextH3, TextStrong } from '../../../../ui/Typography/Typography'
import { getTickmillCompanyByHostname, isTickmillUKType } from '../../../../utils/companyName.utils'
import { useWindowResize } from '../../../../utils/domUtils'
import { LeftSection } from '../../Components/LeftSection'
import { SignupFormWrapper } from '../../Components/SignupFormWrapper'
import { SignupSections, SignupStep } from '../../Components/SignupStep'
import { SignupWrapper } from '../../Components/SignupWrapper'

import styles from './AccountType.module.scss'

export interface FormValuesStep0 {
  typeId: AccountType
}

export const AccountTypeStep: React.FC = () => {
  const { resetSignup, signupData, appendValues: onAccountChange } = useSignup()
  const isMobile = useWindowResize()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const entity = getTickmillCompanyByHostname()
  const location = useLocation()
  const searchParams = location.search
  const accountType = signupData.values.typeId

  useEffect(() => {
    if ((!signupData.lead?.id && signupData.leadResponse?.leadId) || location?.state?.keepValues) {
      return
    }
    resetSignup()
  }, [
    location?.state?.keepValues,
    resetSignup,
    signupData.lead?.id,
    signupData.leadResponse?.leadId,
  ])

  useEffect(() => {
    localStorage.removeItem('selectedProductIdsKey')
  }, [])

  const handleSubmitStep = (event: FormEvent) => {
    event.preventDefault()
    navigate(`personal-details${searchParams}`)
  }

  const hideMobileWrapper = !isTickmillUKType(entity) && accountType !== AccountType.Corporate

  return (
    <SignupFormWrapper label={t('Sign up.Sign up')}>
      <SignupStep
        section={SignupSections.AccountType}
        accountTypeId={signupData.values?.typeId || 1}
      />
      {isMobile && !hideMobileWrapper && (
        <SignupWrapper>
          <section className={styles.formSection}>
            <div className={styles.fewSteps}>
              <div>
                <TextH3>{t('Sign up.Few steps')}</TextH3>
                <Text isParagraph>
                  {isTickmillUKType(entity)
                    ? t('Sign up.Here is what you need to open a Tickmill CFD or Futures account:')
                    : t('Sign up.In order to open a Tickmill CFD account you will need:')}
                </Text>
              </div>
              <Text>
                <TextStrong isParagraph>{t('Sign up.Personal ID')}:</TextStrong>
                <span className='text-secondary'>{t(`Sign up.Passport`)}</span>
              </Text>
              <Text isParagraph>
                <TextStrong isParagraph>{t('Sign up.Proof of Address')}:</TextStrong>
                <span className='text-secondary'>{t('Sign up.Bill')}</span>
              </Text>
              {accountType === AccountType.Corporate && (
                <Text isParagraph>
                  <TextStrong isParagraph>{t('Sign up.Company ID')}:</TextStrong>
                  <span className='text-secondary'>{t('Sign up.Corporate Certificates')}</span>
                </Text>
              )}
            </div>
          </section>
        </SignupWrapper>
      )}

      <SignupWrapper>
        {!isMobile && (
          <LeftSection
            title={t('Sign up.Few steps')}
            description={() => (
              <React.Fragment>
                <Text isParagraph>
                  {isTickmillUKType(entity)
                    ? t('Sign up.Here is what you need to open a Tickmill CFD or Futures account:')
                    : t('Sign up.In order to open a Tickmill CFD account you will need:')}
                </Text>
                <Text isParagraph>
                  <TextStrong>{t('Sign up.Personal ID')}: </TextStrong>
                  <span className='text-secondary'>{t(`Sign up.Passport`)}</span>
                </Text>
                <Text isParagraph>
                  <TextStrong>{t('Sign up.Proof of Address')}: </TextStrong>
                  <span className='text-secondary'>{t('Sign up.Bill')}</span>
                </Text>
                {accountType === AccountType.Corporate && (
                  <Text isParagraph>
                    <TextStrong isParagraph>{t('Sign up.Company ID')}:</TextStrong>
                    <span className='text-secondary'>{t('Sign up.Corporate Certificates')}</span>
                  </Text>
                )}
              </React.Fragment>
            )}
            hasQRCodeSection
          />
        )}

        <section className={styles.formSection}>
          <form className={styles.form}>
            <TextH3>{t('Sign up.Select Account type')}</TextH3>
            {[
              {
                id: AccountType.Individual,
                name: t('Sign up.Individual'),
              },
              {
                id: AccountType.Corporate,
                name: t('Sign up.Corporate'),
              },
            ].map(({ id, name }) => (
              <div
                key={id}
                className='field has-cursor-pointer'
                onClick={() => onAccountChange({ typeId: id })}
              >
                <div
                  className={classNames(styles.field, {
                    [styles.isActive]: accountType === id,
                  })}
                >
                  <input
                    className={classNames('radio')}
                    type='radio'
                    checked={accountType === id}
                    onChange={() => onAccountChange({ typeId: id })}
                  />
                  <label>{name}</label>
                </div>
              </div>
            ))}
            <Button
              onClick={handleSubmitStep}
              className={styles.button}
              type='submit'
              appearance='primary'
              size='L'
            >
              {t('Sign up.Proceed')}
            </Button>
          </form>
        </section>
      </SignupWrapper>
      <AppMarketLink />
    </SignupFormWrapper>
  )
}

import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import classNames from 'classnames'
import { Form, FormikProps, withFormik } from 'formik'

import { Button } from '../global/button/Button'
import { createFormField } from '../global/formField/FormField'
import { Text } from '../ui/Typography/Typography'
import { TwoFactorAuthEvents, TwoFactorAuthLocationState } from './useTwoFactorAuth'

import styles from './TwoFactorAuthPage.module.scss'

export interface CodeFormValues {
  code1: string
  code2: string
  code3: string
  code4: string
  code5: string
  code6: string
}

const fieldKeys: (keyof CodeFormValues)[] = ['code1', 'code2', 'code3', 'code4', 'code5', 'code6']

const FormField = createFormField<CodeFormValues>()

const TwoFactorAuthFormUI: React.FunctionComponent<FormikProps<CodeFormValues> & OuterProps> = (
  props
) => {
  const { openRecoveryModal, resendText, errorText } = props
  const { handleSubmit, values, statusType, validationError, isSubmitting } = props
  const { t } = useTranslation()
  const location = useLocation()
  const locationState = location.state as TwoFactorAuthLocationState
  const isLoginAuthEvent =
    locationState?.twoFactorAuthData?.twoFactorAuthEvent === TwoFactorAuthEvents.LogIn ||
    location.pathname.includes('/login/2fa')

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    props.setFieldValue(name, value)
    let nextField: keyof CodeFormValues | undefined
    if (name === 'code1') {
      nextField = 'code2'
    } else if (name === 'code2') {
      nextField = 'code3'
    } else if (name === 'code3') {
      nextField = 'code4'
    } else if (name === 'code4') {
      nextField = 'code5'
    } else if (name === 'code5') {
      nextField = 'code6'
    }
    if (nextField) {
      const next = document.getElementById(nextField)
      if (next && !!value) {
        next.focus()
      }
    }
  }

  const handleStepBack = (
    e: {
      key: string
      preventDefault: () => void
    },
    name: keyof CodeFormValues,
    value: string
  ) => {
    e.preventDefault()
    let previousField: keyof CodeFormValues | undefined
    if (name === 'code2') {
      previousField = 'code1'
    } else if (name === 'code3') {
      previousField = 'code2'
    } else if (name === 'code4') {
      previousField = 'code3'
    } else if (name === 'code5') {
      previousField = 'code4'
    } else if (name === 'code6') {
      previousField = 'code5'
    }
    if (previousField) {
      const previous = document.getElementById(previousField)
      if (previous && !value) {
        props.setFieldValue(previousField, '')
        previous?.focus()
      }
    }
  }

  useEffect(() => {
    if (fieldKeys.every((fk) => !!values[fk]) && !isSubmitting) {
      handleSubmit()
    }
  }, [values]) // eslint-disable-line react-hooks/exhaustive-deps

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault()
    const pastedData = e.clipboardData.getData('text/plain')

    const pastedValues = pastedData.match(/\d+/g)?.join('').split('') || []
    if (pastedValues.length <= fieldKeys.length) {
      pastedValues.forEach((value, index) => {
        props.setFieldValue(fieldKeys[index], value)
      })
    }
  }

  return (
    <Form className={styles.form}>
      <div className='is-flex is-flex-direction-column is-align-items-flex-start'>
        <div className='is-inline-flex'>
          <div className={classNames('is-flex', styles.codeWrapper)}>
            {fieldKeys.map((fk: keyof CodeFormValues) => (
              <FormField
                type='tel'
                id={fk}
                key={fk}
                wrapperClassname={styles.verificationBoxWrapper}
                className={classNames(styles.verificationBox, {
                  [styles.invalid]: statusType === 'error',
                  [styles.valid]: statusType === 'success',
                })}
                onPaste={handlePaste}
                onChange={onChange}
                onKeyDown={(e) => {
                  if (e.key === 'Backspace' && !values[fk as keyof CodeFormValues]) {
                    handleStepBack(e, fk, values[fk as keyof CodeFormValues])
                  }
                  if (['e', 'E', '+', '-'].includes(e.key)) {
                    return e.preventDefault()
                  }
                }}
                name={fk}
                value={values[fk as keyof CodeFormValues] ? '*' : ''}
              />
            ))}
          </div>
        </div>
        {!!validationError && <div className={styles.validationError}>{validationError}</div>}
        {resendText}
        {errorText}
        {isLoginAuthEvent && (
          <Button
            className='p-0 mt-4'
            type='button'
            onClick={openRecoveryModal}
            appearance='plain'
            size='XS'
          >
            <Text>{t('Sign up.Enter recovery code')}</Text>
          </Button>
        )}
      </div>
    </Form>
  )
}

interface OuterProps {
  onSubmit(code: string): Promise<void>
  openRecoveryModal: () => void
  statusType: 'success' | 'error' | undefined
  validationError?: string
  resendText: JSX.Element
  errorText: JSX.Element
}

export const TwoFactorAuthForm = withFormik<OuterProps, CodeFormValues>({
  mapPropsToValues: () => {
    return {
      code1: '',
      code2: '',
      code3: '',
      code4: '',
      code5: '',
      code6: '',
    }
  },
  handleSubmit: async (values, { props, setSubmitting, setFieldValue }) => {
    try {
      const code =
        values.code1 + values.code2 + values.code3 + values.code4 + values.code5 + values.code6
      await props.onSubmit(code)
    } catch {
      fieldKeys.forEach((fk) => setFieldValue(fk, ''))
      document.getElementById(fieldKeys[0])?.focus()
    } finally {
      setSubmitting(false)
    }
  },
  enableReinitialize: true,
})(TwoFactorAuthFormUI)

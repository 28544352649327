import { IconProps } from './IconProps'

export function TradingViewLogo(props: IconProps): JSX.Element {
  const { size = 24 } = props

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_2400_45185)'>
        <mask id='mask0_2400_45185' maskUnits='userSpaceOnUse' x='-1' y='-1' width='41' height='41'>
          <path d='M39.998 -0.000366211H-0.00195312V39.9996H39.998V-0.000366211Z' fill='white' />
        </mask>
        <g mask='url(#mask0_2400_45185)'>
          <path
            d='M-0.00195312 7.49963C-0.00195312 3.3575 3.35591 -0.000366211 7.49805 -0.000366211H32.498C36.6402 -0.000366211 39.998 3.3575 39.998 7.49963V32.4996C39.998 36.6417 36.6402 39.9996 32.498 39.9996H7.49805C3.35591 39.9996 -0.00195312 36.6417 -0.00195312 32.4996V7.49963Z'
            fill='#131722'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M25.8574 15.5857C25.8574 17.4194 24.3709 18.906 22.5371 18.906C20.7034 18.906 19.2168 17.4194 19.2168 15.5857C19.2168 13.7519 20.7034 12.2654 22.5371 12.2654C24.3709 12.2654 25.8574 13.7519 25.8574 15.5857ZM17.5762 12.4998H4.37305V19.1403H10.9355V28.9841H17.5762V12.4998ZM29.2168 12.4998H36.873L29.959 28.9841H22.3027L29.2168 12.4998Z'
            fill='white'
          />
        </g>
      </g>
      <defs>
        <clipPath id='clip0_2400_45185'>
          <rect width={size} height={size} fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

import { isZero } from '../utils/validations'
import {
  MasterTransactionDto,
  PaymentProviderTransactionDto,
  PaymentProviderTransactionResponseDto,
  WalletTransactionDto,
} from './MasterTransactionDto'
import { isTransactionStatusSuccessful, isTransactionStatusUnSuccessful } from './TransactionStatus'

export interface MasterTransaction extends Omit<MasterTransactionDto, 'walletTransactions'> {
  walletTransactions: WalletTransaction[]
}

export interface WalletTransaction
  extends Omit<WalletTransactionDto, 'paymentProviderTransaction'> {
  paymentProviderTransaction: PaymentProviderTransaction
}

interface PaymentProviderTransaction extends Omit<PaymentProviderTransactionDto, 'response'> {
  response: PaymentProviderTransactionResponse
}

interface PaymentProviderTransactionResponse extends PaymentProviderTransactionResponseDto {
  redirectData: {
    type: string
    method: null
    url: null
    data: {
      [key: string]: string | undefined
    }
    prepared: null
  }
}

export const isTransactionHistory = (data: MasterTransaction): boolean => {
  const transactions = data.walletTransactions || []

  if (isTransactionSuccess(data)) {
    return isZero(transactions.length)
  }

  return false
}

export const isTransactionUrlRedirect = (data: MasterTransaction): boolean => {
  if (!data) {
    return false
  }

  const [transaction] = data.walletTransactions || []

  if (isTransactionSuccess(data)) {
    const type = transaction?.paymentProviderTransaction?.response?.redirect?.type
    return type === 'redirect_url'
  }

  return false
}

export const getTransactionRedirectUrl = (data: MasterTransaction): string | undefined => {
  const [transaction] = data.walletTransactions || []
  return transaction?.paymentProviderTransaction?.response?.redirect?.url || undefined
}

export const isTransactionFormRedirect = (data: MasterTransaction): boolean => {
  if (!data) {
    return false
  }

  const [transaction] = data.walletTransactions || []

  if (isTransactionSuccess(data)) {
    const type = transaction?.paymentProviderTransaction?.response?.redirect?.type
    return type === 'redirect_form'
  }

  return false
}

export const getTransactionRedirectForm = (data: MasterTransaction): string | undefined => {
  const [transaction] = data.walletTransactions || []
  return transaction?.paymentProviderTransaction?.response?.redirect.prepared || undefined
}

const isTransactionSuccess = (data: MasterTransaction) => {
  const [transaction] = data.walletTransactions || []
  return !!transaction?.id
}

export const isTransactionSuccessful = (data: MasterTransaction): boolean => {
  if (!data) {
    return false
  }

  const [transaction] = data.walletTransactions || []
  const redirect = transaction?.paymentProviderTransaction?.response?.redirect

  return !redirect && isTransactionStatusSuccessful(data.state.id)
}

export const isTransactionUnSuccessful = (data: MasterTransaction): boolean => {
  if (!data) {
    return false
  }

  const [transaction] = data.walletTransactions || []
  const redirect = transaction?.paymentProviderTransaction?.response?.redirect

  return !redirect && isTransactionStatusUnSuccessful(data.state.id)
}

export const normalizeMasterTransaction = (data: MasterTransactionDto): MasterTransaction => {
  const walletTransactions = (data?.walletTransactions || []).map(normalizeWallet) || []

  return {
    ...data,
    walletTransactions,
  }
}

const normalizeWallet = (x: WalletTransactionDto) => {
  return {
    ...x,
    paymentProviderTransaction: {
      ...x?.paymentProviderTransaction,
      response: {
        ...x?.paymentProviderTransaction?.response,
        redirect: x?.paymentProviderTransaction?.response?.redirect,
        redirectData: {
          ...x?.paymentProviderTransaction?.response?.redirect,
          data: {
            ...x?.paymentProviderTransaction?.response?.redirect?.data,
          },
        },
      },
    },
  }
}

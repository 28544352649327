import { useCallback } from 'react'

import { MultiLevelCommissionDetailDto } from '../../../model/MultiLevelCommission'
import { useAccountReadContext } from '../../../utils/AccountContextContext'
import { useApiClient } from '../../../utils/ApiClient'
import { ClientApiClient } from '../../../utils/clientApi'
import { useCallbackWithForceRefresh } from '../../../utils/useCallbackWithForceRefresh'
import { useFetchOne } from '../../../utils/useFetch'

interface MultiLevelCommission {
  data: MultiLevelCommissionDetailDto | null
  isLoading: boolean
  dismissNotification: () => Promise<void>
}

interface MultiLevelCommissionParams {
  isPaused?: boolean
}

export const useMultiLevelCommission = (
  params: MultiLevelCommissionParams = {}
): MultiLevelCommission => {
  const { isPaused } = params

  const apiClient = useApiClient(ClientApiClient)
  const { account } = useAccountReadContext()

  const { callback, forceRefresh } = useCallbackWithForceRefresh(async () => {
    if (!account?.clientIntroducingBroker?.id || isPaused) {
      return null
    }

    const multiLevelCommission = await apiClient.getIBMultiLevelCommission(
      account.clientIntroducingBroker.id
    )

    return multiLevelCommission
  }, [account, apiClient, isPaused])

  const dismissNotification = useCallback(async () => {
    if (!account?.clientIntroducingBroker?.id) {
      return
    }

    await apiClient.dismissIBMultilevelCommissionNotification(account.clientIntroducingBroker.id)

    forceRefresh(true)
  }, [apiClient, account, forceRefresh])

  const { data, isLoading } = useFetchOne(callback)

  return {
    data: data || null,
    isLoading: isLoading,
    dismissNotification: dismissNotification,
  }
}

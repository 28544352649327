import React, { PropsWithChildren, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Loading } from '../../global/Loading/Loading'
import { useProductReadContext } from '../../global/context/ProductContext'
import { useSessionLanguage } from '../../global/context/SessionSettingsContext'
import { NotificationSuccess, NotificationWarning } from '../../global/notification/notification'
import {
  ScrollToIds,
  useScrollAfterLoad,
  useScrollIntoViewOnPagingEntriesChange,
} from '../../hooks/useScrollToElementIds'
import { CircleWarningIcon } from '../../icons/CircleWarningIcon'
import { TickmillBackgroundIcon } from '../../icons/TickmillBackgroundIcon'
import { AccountDetailedDto, isAccountDetailedActivated } from '../../model/AccountDetailedDto'
import {
  AccountDetailedStatus,
  isAccountPendingDocumentVerificationStatusType,
} from '../../model/AccountDetailedStatus'
import { BankAccount } from '../../model/BankAccount'
import { DocumentCategoryType } from '../../model/DocumentCategories'
import { DocumentClientAreaDto } from '../../model/DocumentClientAreaDto'
import { TickmillProductType } from '../../model/TickmillProductType'
import { isOtherDocumentType } from '../../model/UploadDocumentTypeEnum'
import { PageHeader } from '../../ui/Table/Header/PageHeader'
import { TextH3, TextSmall } from '../../ui/Typography/Typography'
import { useAccountAccessStatuses } from '../../utils/AccountAccess/accountAccessStatuses'
import {
  getAccountDocumentExpirationDaysInterval,
  hasProductPendingDocumentVerificationStatus,
  isAccountDocumentAllLocked,
  isAccountDocumentAllowedUpload,
  isAccountDocumentManagement1MonthExpired,
  isAccountDocumentManagement2MonthExpired,
  isAccountDocumentManagementExpired,
  isAccountDocumentsApproved,
} from '../../utils/AccountAccess/accountDocumentStatuses'
import { useAccountReadContext } from '../../utils/AccountContextContext'
import { PageQuery, useApiClient } from '../../utils/ApiClient'
import { ClientApiClient } from '../../utils/clientApi'
import { useCallbackWithForceRefresh } from '../../utils/useCallbackWithForceRefresh'
import { IAppendableFetchResult, useFetchAppendablePage, useFetchOne } from '../../utils/useFetch'
import { useScrollToTop } from '../../utils/useScrollToTop'
import { useLocallyPersistedState } from '../../utils/useStorage'
import { ActivatedClientDocumentManagementPage } from './ActivatedClientDocumentManagementPage'
import { UploadDocuments } from './UploadDocuments'
import { UploadedDocuments, UploadedDocumentsCards } from './UploadedDocuments'

import styles from './DocumentManagementPage.module.scss'

interface DocumentsProps extends IAppendableFetchResult<DocumentClientAreaDto[]> {
  forceRefresh(): void
}

const useDocuments = (): DocumentsProps => {
  const apiClient = useApiClient(ClientApiClient)

  const locale = useSessionLanguage()

  const { callback, forceRefresh } = useCallbackWithForceRefresh(
    (query?: PageQuery) =>
      apiClient.getDocuments({
        ...query,
        search: {
          Category: [
            DocumentCategoryType.Personal,
            DocumentCategoryType.Address,
            DocumentCategoryType.Corporate,
            DocumentCategoryType.Additional,
            DocumentCategoryType.Payment,
          ].toString(),
        },
        languageId: locale,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locale]
  )
  const query = useFetchAppendablePage(callback)
  return { ...query, forceRefresh }
}

const useBankAccountsFetch = (areDocumentsLoading: boolean) => {
  const apiClient = useApiClient(ClientApiClient)
  const { callback } = useCallbackWithForceRefresh(() => apiClient.getBankAccounts(), [])
  const { data: bankAccounts = [], isLoading, meta } = useFetchOne(callback)

  return { bankAccounts, isLoading: areDocumentsLoading || isLoading, meta }
}

export const DocumentManagementPage: React.FC = () => {
  useScrollToTop()

  const { t } = useTranslation()
  const { account } = useAccountReadContext()
  const { product } = useProductReadContext()

  const documentsProps = useDocuments()
  const { isLoading: areDocumentsLoading, meta } = documentsProps
  const { bankAccounts, isLoading: areBankAccountsLoading } =
    useBankAccountsFetch(areDocumentsLoading)

  const [isPaginationEntrySelected, setIsPaginationEntrySelected] = useState(false)
  useScrollIntoViewOnPagingEntriesChange(
    ScrollToIds.UploadedDocumentsHeader,
    isPaginationEntrySelected,
    areBankAccountsLoading || areDocumentsLoading,
    setIsPaginationEntrySelected
  )
  useScrollAfterLoad(
    ScrollToIds.UploadedDocumentsHeader,
    areBankAccountsLoading || areDocumentsLoading,
    meta?.pageSize
  )

  return (
    <>
      <div>
        {isDocumentShowNotification(account, product) && (
          <NotificationSuccess subtitle={t('Profile.Submitted for review')} />
        )}
      </div>
      <Loading isLoading={areDocumentsLoading} showLoadingIcon>
        <DocumentManagementFactory
          documentsProps={documentsProps}
          bankAccounts={bankAccounts}
          isLoading={areBankAccountsLoading || areDocumentsLoading}
          setIsPaginationEntrySelected={setIsPaginationEntrySelected}
        />
      </Loading>
    </>
  )
}

interface DocumentManagementFactoryProps {
  documentsProps: DocumentsProps
  bankAccounts: BankAccount[]
  isLoading: boolean
  setIsPaginationEntrySelected: (isPaginationEntrySelected: boolean) => void
}

const DocumentManagementFactory: React.FC<DocumentManagementFactoryProps> = (props) => {
  const { documentsProps, bankAccounts, isLoading, setIsPaginationEntrySelected } = props
  const { hasInitialResults } = documentsProps

  const { account } = useAccountReadContext()
  const someDocumentsPending = isAccountPendingDocumentVerificationStatusType(
    account?.status.id || -1
  )

  const isEveryInfoLocked =
    account?.isAdditionalInfoLocked &&
    account?.isPersonalInfoLocked &&
    account?.isAddressInfoLocked &&
    account?.isPaymentInfoLocked

  if (
    account?.isVerificationExpedited &&
    !isAccountDetailedActivated(account) &&
    isEveryInfoLocked
  ) {
    return (
      <>
        <DocumentSideBackground />
        <ThankYouPage documentsProps={documentsProps} />
      </>
    )
  }

  if (someDocumentsPending) {
    return (
      <UploadDocumentsPage
        documents={documentsProps}
        bankAccounts={bankAccounts}
        isLoading={isLoading}
      />
    )
  }

  if (isAccountDetailedActivated(account)) {
    return (
      <ActivatedClientDocumentManagementPage
        {...documentsProps}
        setIsPaginationEntrySelected={setIsPaginationEntrySelected}
        bankAccounts={bankAccounts}
        isLoading={isLoading}
      />
    )
  }

  if (isAccountDocumentsApproved(account) && hasInitialResults) {
    return (
      <UploadedDocumentsPage
        {...documentsProps}
        bankAccounts={bankAccounts}
        isLoading={isLoading}
      />
    )
  }

  return (
    <UploadDocumentsPage
      documents={documentsProps}
      bankAccounts={bankAccounts}
      isLoading={isLoading}
    />
  )
}

interface UploadDocumentsProps {
  documents: DocumentsProps
  bankAccounts: BankAccount[]
  isLoading: boolean
}

const UploadDocumentsPage = (props: UploadDocumentsProps) => {
  const { documents, isLoading, bankAccounts } = props
  const { data, meta, setPageQuery, pageQuery, forceRefresh, hasInitialResults } = documents

  const { t } = useTranslation()

  return (
    <>
      {data.length > 0 && <DocumentSideBackground />}
      <DocumentBackground hasInitialResults={hasInitialResults}>
        <div className={styles.additional}>
          <UploadDocuments />
        </div>
        {data.length > 0 && (
          <UploadedDocumentsWrapper>
            <PageHeader title={t('Profile.Uploaded Documents')} />
            <UploadedDocumentsCards
              documents={data}
              bankAccounts={bankAccounts}
              setPageQuery={setPageQuery}
              pageQuery={pageQuery}
              meta={meta}
              isLoading={isLoading}
              forceRefresh={forceRefresh}
            />
          </UploadedDocumentsWrapper>
        )}
      </DocumentBackground>
    </>
  )
}

const UploadedDocumentsPage = (props: DocumentsProps & { bankAccounts: BankAccount[] }) => {
  const { data, meta, setPageQuery, pageQuery, isLoading, bankAccounts, forceRefresh } = props

  const { t } = useTranslation()

  return (
    <UploadedDocumentsWrapper>
      <PageHeader
        title={t('Profile.Uploaded Documents')}
        id={ScrollToIds.UploadedDocumentsHeader}
      />
      <UploadedDocuments
        documents={data}
        setPageQuery={setPageQuery}
        pageQuery={pageQuery}
        meta={meta}
        isLoading={isLoading}
        forceRefresh={forceRefresh}
        bankAccounts={bankAccounts}
      />
    </UploadedDocumentsWrapper>
  )
}

const ThankYouPage: React.FC<Pick<DocumentManagementFactoryProps, 'documentsProps'>> = (props) => {
  const { documentsProps } = props
  const { hasInitialResults } = documentsProps

  const { t } = useTranslation()

  return (
    <DocumentBackground hasInitialResults={hasInitialResults}>
      <div className={styles.notifyCard}>
        <div className={styles.notifyCardTitleBox}>
          <CircleWarningIcon />
          <TextH3>{t('Thank you for registering with Tickmill')}</TextH3>
        </div>
        <TextSmall className={styles.text}>
          {t('The verification process is in progress')}
        </TextSmall>
      </div>
    </DocumentBackground>
  )
}

const DocumentSideBackground: React.FC<PropsWithChildren> = () => {
  const { isAccountActivatedStatus } = useAccountAccessStatuses()

  return (
    <>
      <div
        className={classNames('is-hidden-mobile', styles.backgroundIcon, {
          [styles.activatedBackgroundIconLeft]: isAccountActivatedStatus,
          [styles.backgroundIconLeft]: !isAccountActivatedStatus,
        })}
      >
        <TickmillBackgroundIcon />
      </div>
      <div
        className={classNames('is-hidden-mobile', styles.backgroundIcon, {
          [styles.activatedBackgroundIconRight]: isAccountActivatedStatus,
          [styles.backgroundIconRight]: !isAccountActivatedStatus,
        })}
      >
        <TickmillBackgroundIcon />
      </div>
    </>
  )
}

interface DocumentBackgroundProps {
  hasInitialResults: boolean
}

const DocumentBackground: React.FC<PropsWithChildren<DocumentBackgroundProps>> = (props) => {
  const { hasInitialResults, children } = props

  const { account } = useAccountReadContext()

  const { isAccountActivatedStatus } = useAccountAccessStatuses()

  return (
    <div className={styles.wrapper}>
      <div
        className={classNames(styles.container, {
          [styles.isAccountDocumentAllowedUpload]:
            isAccountDocumentAllowedUpload(account) ||
            (!hasInitialResults && account?.isVerificationExpedited),
          [styles.activatedAccount]: !isAccountActivatedStatus && hasInitialResults,
        })}
      >
        {children}
      </div>
    </div>
  )
}

export const UploadedDocumentsWrapper: React.FC<PropsWithChildren<{}>> = (props) => {
  const { children } = props
  const { account } = useAccountReadContext()

  return (
    <div
      className={classNames(styles.documents, {
        ['mt-0']: !isAccountDocumentAllowedUpload(account),
      })}
    >
      {children}
    </div>
  )
}

export const DocumentManagementNotification: React.FC = () => {
  const { t } = useTranslation()
  const { account } = useAccountReadContext()
  const [isPersistentlyClosed, setPersistentlyClosed] = useLocallyPersistedState(
    'hideDocumentExpiration',
    false
  )
  const isOtherType = isOtherDocumentType(account?.documentType?.id)

  const getDocumentName = useMemo(() => {
    switch (true) {
      case account?.documentType === null:
        return t('Profile.Proof of Identity')
      default:
        return account?.documentType?.name
    }
  }, [account?.documentType, t])

  if (
    account?.status.id === AccountDetailedStatus.Closed ||
    account?.status.id === AccountDetailedStatus.Dormant
  ) {
    return null
  }

  if (isAccountDocumentManagementExpired(account)) {
    return (
      <NotificationWarning
        subtitle={
          isOtherType ? (
            <p>{t('Profile.Your identification document is expired')}</p>
          ) : (
            <p>
              {t('Profile.another valid type of identification document as instructed below', {
                name: getDocumentName,
              })}
            </p>
          )
        }
      />
    )
  }

  if (isAccountDocumentManagement1MonthExpired(account)) {
    return (
      <NotificationWarning
        subtitle={
          isOtherType ? (
            <p>
              {t('Profile.Your identification document will be expired in', {
                count: getAccountDocumentExpirationDaysInterval(account),
              })}
            </p>
          ) : (
            <p>
              {t(
                'Profile.another valid type of identification document before the expiration date as instructed below.',
                {
                  name: getDocumentName,
                  count: getAccountDocumentExpirationDaysInterval(account),
                }
              )}
            </p>
          )
        }
      />
    )
  }

  if (isAccountDocumentManagement2MonthExpired(account) && !isPersistentlyClosed) {
    return (
      <NotificationWarning
        onCancel={() => setPersistentlyClosed(true)}
        subtitle={
          isOtherType ? (
            <p>{t('Profile.Your identification document will be expired in 2 months')}</p>
          ) : (
            <p>
              {t('Profile.or another valid type of identification document', {
                name: getDocumentName,
              })}
            </p>
          )
        }
      />
    )
  }

  return null
}

const isDocumentShowNotification = (
  accountDetailed: AccountDetailedDto | undefined,
  product: TickmillProductType
) => {
  if (accountDetailed === undefined || accountDetailed === null) {
    return false
  }

  if (isAccountDocumentAllowedUpload(accountDetailed)) {
    return false
  }

  if (hasProductPendingDocumentVerificationStatus(accountDetailed, product)) {
    return false
  }

  if (isAccountDocumentAllLocked(accountDetailed)) {
    return false
  }

  return true
}

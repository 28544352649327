import { useTranslation } from 'react-i18next'

import { Button } from '../global/button/Button'
import { NotificationsNotFoundIcon } from '../icons/NotificationsNotFoundIcon'
import { TextLargeStrong } from '../ui/Typography/Typography'
import { usePathHistoryContext } from '../utils/PathHistoryContext'

import styles from './NotificationCenter.module.scss'

export const NotificationsNotFound = () => {
  const { t } = useTranslation()
  const { navigateToPreviousPath } = usePathHistoryContext()

  return (
    <div className={styles.notificationsNotFound}>
      <NotificationsNotFoundIcon />
      <TextLargeStrong className={styles.notificationsNotFoundTitle}>
        {t('You have no new notifications')}
      </TextLargeStrong>
      <Button
        size='S'
        appearance='primary'
        onClick={() => navigateToPreviousPath()}
        className={styles.goBackButton}
      >
        {t('Go Back')}
      </Button>
    </div>
  )
}

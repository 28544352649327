import React, { useEffect, useState } from 'react'

import { useProductReadContext } from '../../global/context/ProductContext'
import {
  FilterOptions,
  FilterQueryModal,
  FilterQueryProps,
  FilterQueryPropsValue,
} from '../../global/filter/FilterQueryModal'
import { FilterOptionsDto } from '../../model/FilterOptionsDto'
import { NameDto } from '../../model/NameDto'
import { isTickmillProductTypeCFD } from '../../model/TickmillProductType'
import { TradingAccount } from '../../model/TradingAccount'
import { SharedState } from '../../utils/SharedContext'
import { IDateFilter } from '../../utils/filter.utils'

interface Props {
  currentQuery?: FilterQueryProps
  sharedState: SharedState
  filterOptions?: FilterOptionsDto
  onConfirm({
    searchFilters,
    currentFilter,
  }: {
    searchFilters?: FilterQueryProps
    currentFilter?: IDateFilter
  }): void
  isLoading?: boolean
  onCancel(): void
  lastSelectedTAFilterId?: string
  setLastSelectedTAFilterId(platformTypeId: string): void
}

const isMetaTraderFilter = (
  platformTypeId: FilterQueryPropsValue,
  tradingAccount: TradingAccount
) => {
  return platformTypeId?.toString().split(',').includes(tradingAccount.platformType.id.toString())
}

const isTMTFilter = (platformTypeId: FilterQueryPropsValue, tradingAccount: TradingAccount) =>
  tradingAccount.platformType.id.toString() === platformTypeId

export const TransactionFilterModal: React.FC<Props> = ({
  onConfirm,
  currentQuery,
  sharedState,
  filterOptions,
  isLoading,
  lastSelectedTAFilterId,
  setLastSelectedTAFilterId,
}) => {
  const { product } = useProductReadContext()

  const [searchFilters, setSearchFilters] = useState<FilterQueryProps | undefined>(currentQuery)
  const [filteredAccounts, setFilteredAccounts] = useState<NameDto<string>[]>()

  useEffect(() => {
    const platformTypeId = searchFilters?.[FilterOptions.TradingAccountPlatformType]
    const caSearchTAIdExists = !!searchFilters?.[FilterOptions.TradingAccount]

    const resetTradingAccountFilterOptions = () => {
      setFilteredAccounts(filterOptions?.tradingAccounts ?? [])
      if (caSearchTAIdExists) {
        setSearchFilters({ ...searchFilters, [FilterOptions.TradingAccount]: undefined })
      }
      setLastSelectedTAFilterId(platformTypeId?.toString() ?? '')
    }

    const filterTradingAccountOptionsByPlatform = () => {
      const filteredFilterOptions = filterOptions?.tradingAccounts?.filter(
        (tradingAccount) =>
          isMetaTraderFilter(platformTypeId, tradingAccount) ||
          isTMTFilter(platformTypeId, tradingAccount)
      )

      if (lastSelectedTAFilterId !== platformTypeId && caSearchTAIdExists) {
        setSearchFilters({ ...searchFilters, [FilterOptions.TradingAccount]: undefined })
      }

      setFilteredAccounts(filteredFilterOptions ?? [])
      setLastSelectedTAFilterId(platformTypeId?.toString() ?? '')
    }

    if (!platformTypeId && !caSearchTAIdExists) {
      resetTradingAccountFilterOptions()
    } else if (!platformTypeId && caSearchTAIdExists) {
      setFilteredAccounts(filterOptions?.tradingAccounts ?? [])
      setLastSelectedTAFilterId('')
    } else {
      filterTradingAccountOptionsByPlatform()
    }
  }, [currentQuery, filterOptions?.tradingAccounts, searchFilters])

  const currentFilterOptions = [
    FilterOptions.Wallet,
    FilterOptions.AccountType,
    FilterOptions.TransactionType,
    FilterOptions.TransactionStatus,
    FilterOptions.Date,
    ...(isTickmillProductTypeCFD(product)
      ? [FilterOptions.TradingAccountPlatformType, FilterOptions.TradingAccount]
      : []),
    FilterOptions.ClientIdAmount,
  ]

  return (
    <FilterQueryModal
      searchFilters={searchFilters}
      setSearchFilters={setSearchFilters}
      onConfirm={onConfirm}
      wallets={filterOptions?.wallets}
      tradingAccounts={filteredAccounts}
      transactionTypes={filterOptions?.transactionTypes}
      transactionStates={sharedState?.transactionStates}
      currencyOptions={filterOptions?.walletCurrencies}
      isLoading={isLoading}
      customLabels={{
        [FilterOptions.TradingAccount]: '',
      }}
      currentFilterOptions={currentFilterOptions}
      allFilterOptions={filterOptions}
    />
  )
}

import React from 'react'

import { BalancesOptionsModal } from '../BalancesOptionsModal'
import { InitiateConversion, TradingAccountOption } from './TradingAccountBalances'
import { TradingAccountBalancesOptions } from './TradingAccountBalancesOptions'

interface Props {
  onCancel: () => void
  TAOptionsData: TradingAccountOption
  isTransferFromRestricted: boolean
  intiateConversion: ({ currency, balance }: InitiateConversion) => void
}

export const TradingAccountOptionsModal: React.FC<Props> = ({
  onCancel,
  TAOptionsData,
  isTransferFromRestricted,
  intiateConversion,
}) => {
  return (
    <BalancesOptionsModal
      closeModal={onCancel}
      renderOptions={() => (
        <TradingAccountBalancesOptions
          tradingAccount={TAOptionsData.tradingAccount}
          currency={TAOptionsData.currency}
          isTransferFromRestricted={isTransferFromRestricted}
          intiateConversion={intiateConversion}
        />
      )}
    />
  )
}

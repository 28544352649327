import { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { throttle } from 'lodash'

import { Button } from '../../../../../global/button/Button'
import { useSnackbar } from '../../../../../global/context/SnackbarContext'
import { Text, TextStrong } from '../../../../../ui/Typography/Typography'
import { useApiClient } from '../../../../../utils/ApiClient'
import { ClientApiClient } from '../../../../../utils/clientApi'
import { NoteWrapper } from '../../../Components/NoteWrapper'

export const ResendEmailPage: React.FC = () => {
  const [searchParams] = useSearchParams()
  const apiClient = useApiClient(ClientApiClient)
  const leadId = searchParams.get('leadId') ?? ''
  const email = searchParams.get('leadEmail') ?? ''
  const { addSnackbar } = useSnackbar()

  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)

  const handleResend = useCallback(async () => {
    try {
      setLoading(true)
      await apiClient.resendCreateLeadEmail(leadId)
      addSnackbar.success({ message: t('Email was re-sent') })
    } finally {
      setLoading(false)
    }
  }, [addSnackbar, apiClient, leadId, t])

  const throttledHandleResend = useRef(
    throttle(handleResend, 1000, { leading: true, trailing: false })
  )

  return (
    <NoteWrapper
      title={`${t('Sign up.Email sent')}!`}
      isLoading={loading}
      text={
        <>
          <Text>
            {t('Sign up.Please check your inbox', { email })}
            <TextStrong>{t('Sign up.click the verification link to continue')}</TextStrong>{' '}
            {t('Sign up.Your verification link is active for the next x hours', {
              hours: 72,
            })}
            .
          </Text>
          <Text>
            {t(`Sign up.Didn't get the link`)}?{' '}
            {t('Sign up.Make sure to check your spam folder or')}{' '}
            <Button appearance='link' disabled={loading} onClick={throttledHandleResend.current}>
              {t('Sign up.Resend')}
            </Button>
          </Text>
        </>
      }
    />
  )
}

import { CSSProperties, FC, PropsWithChildren, ReactNode } from 'react'
import classNames from 'classnames'

import {
  HorizontalScroll as ScrollProps,
  useHorizontalScroll,
} from '../../hooks/useHorizontalScroll'

import styles from './HorizontalScroll.module.scss'

interface HorizontalScrollProps {
  className?: string
  innerClassName?: string
  containerClassName?: string
  fadeWidth?: string
  renderAdditionalContent?: (scrollProps: ScrollProps) => ReactNode
}

export const HorizontalScroll: FC<PropsWithChildren<HorizontalScrollProps>> = (props) => {
  const {
    className,
    children,
    innerClassName,
    containerClassName,
    fadeWidth = '7rem',
    renderAdditionalContent,
  } = props

  const scrollProps = useHorizontalScroll<HTMLDivElement>()

  const {
    scrollContainerRef,
    scrollContainerInnerRef,
    hasScrolledToEnd,
    hasScrolledToStart,
    isScrollable,
  } = scrollProps

  return (
    <div
      className={classNames(styles.scrollContainerWrapper, className, {
        [styles.containerScrollable]: isScrollable,
      })}
    >
      <div
        className={classNames(styles.scrollContainer, containerClassName)}
        ref={scrollContainerRef}
      >
        <div
          className={classNames(styles.scrollContainerInner, innerClassName)}
          ref={scrollContainerInnerRef}
        >
          {children}
        </div>
      </div>
      <HorizontalScrollFade.Start
        active={isScrollable && !hasScrolledToStart}
        style={{ width: fadeWidth }}
      />
      <HorizontalScrollFade.End
        active={isScrollable && !hasScrolledToEnd}
        style={{ width: fadeWidth }}
      />

      {renderAdditionalContent?.(scrollProps)}
    </div>
  )
}

interface HorizontalScrollFadeProps {
  direction?: 'start' | 'end'
  style?: CSSProperties
  className?: string
  active?: boolean
}

export const HorizontalScrollFade: FC<HorizontalScrollFadeProps> & {
  Start: FC<Omit<HorizontalScrollFadeProps, 'direction'>>
  End: FC<Omit<HorizontalScrollFadeProps, 'direction'>>
} = (props) => {
  const { direction = 'start', style, className, active } = props

  return (
    <div
      style={style}
      className={classNames(styles.scrollContainerFade, styles[direction], className, {
        [styles.active]: active,
      })}
    />
  )
}

HorizontalScrollFade.Start = (props: Omit<HorizontalScrollFadeProps, 'direction'>) => (
  <HorizontalScrollFade {...props} direction='start' />
)

HorizontalScrollFade.End = (props: Omit<HorizontalScrollFadeProps, 'direction'>) => (
  <HorizontalScrollFade {...props} direction='end' />
)

import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import classNames from 'classnames'

import { Loading } from '../global/Loading/Loading'
import { BackButton } from '../global/backButton/BackButton'
import { useSessionLanguage } from '../global/context/SessionSettingsContext'
import { useNavigate } from '../navigation/custom-react-router-dom'
import { PageHeader } from '../ui/Table/Header/PageHeader'
import { PageQuery, useApiClient } from '../utils/ApiClient'
import { usePathHistoryContext } from '../utils/PathHistoryContext'
import { ClientApiClient } from '../utils/clientApi'
import { getDomainName } from '../utils/cookie.utils'
import { useWindowResize } from '../utils/domUtils'
import { useNotificationUnread } from '../utils/notifications'
import { useCallbackWithForceRefresh } from '../utils/useCallbackWithForceRefresh'
import { useFetchOne } from '../utils/useFetch'
import { scrollToTop } from '../utils/useScrollToTop'
import { NotificationPreview } from './NotificationPreview'

import styles from './NotificationCenter.module.scss'

export const NotificationDetailsPage = () => {
  const isMobile = useWindowResize()
  const { notificationId = '' } = useParams<{ notificationId: string }>()
  const { t } = useTranslation()
  const apiClient = useApiClient(ClientApiClient)
  const locale = useSessionLanguage()
  const { refreshAllNotifications } = useNotificationUnread()
  const { navigateToPreviousPath } = usePathHistoryContext()

  const navigate = useNavigate()
  const { callback } = useCallbackWithForceRefresh(
    (query?: PageQuery) =>
      apiClient
        .getNotification(notificationId, {
          ...query,
          languageId: locale,
          domain: getDomainName(true),
        })
        .then((response) => {
          scrollToTop()
          return response
        }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [notificationId, locale]
  )

  const removeNotification = async () => {
    await apiClient.deleteNotification(notificationId)
    await refreshAllNotifications()
    navigate('/notifications')
  }

  const readNotification = async (notificationId: string) => {
    await apiClient.readNotification(notificationId)
    await refreshAllNotifications()
  }

  const { data, isLoading } = useFetchOne(callback)
  const notification = data

  useEffect(() => {
    if (!notification && !isLoading) {
      navigate('/notifications')
    }
    if (notification && !notification.isRead) {
      readNotification(notification.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification])

  return (
    <div className={styles.notifications}>
      <div className={classNames(styles.backButton)}>
        <BackButton section={t('Notifications.Notification Centre')} className='is-uppercase' />
      </div>
      <PageHeader
        backButton={isMobile ? () => navigateToPreviousPath() : undefined}
        className={styles.header}
        title={t('Notifications.Notification')}
      />

      <Loading isLoading={isLoading}>
        {notification && (
          <NotificationPreview expanded remove={removeNotification} notification={notification} />
        )}
      </Loading>
    </div>
  )
}

import { AccountDetailedDto } from './AccountDetailedDto'
import { NameDto } from './NameDto'

export enum BankAccountStatus {
  None = 0,
  Activated = 4,
  AwaitingApproval = 10,
  Approved = 20,
  Rejected = 30,
  Expired = 40,
}

export interface BankAccountFormValues {
  id: string
  clientBankAccountDetailsId: string
  clientId: string
  bankName: string
  bankCode: string
  bankAddress: string
  accountName: string
  bankAccount: string
  documentId?: string
  codeSort: string
  residenceCountryId: string
  comment: string
  reference: string
  // documents: DocumentClientAreaDto[]
}

export interface BankAccountCreate extends Omit<BankAccountFormValues, 'documents'> {
  document?: {
    fileName: string
    file: string
  }
}

export interface BankAccount {
  id: string
  client: NameDto<string>
  document: NameDto<string>
  bankDetails: BankDetailsDto
  bankName: string
  bankCode: string
  bankCodeSort: string
  bankAddress: string
  bankResidenceCountry: NameDto<string>
  accountName: string
  bankAccount: string
  comment: string
  status: NameDto<BankAccountStatus>
}

export const isBankAccountAllowed = (bankAccount: BankAccount): boolean => {
  return isBankAccountApproved(bankAccount) || isBankAccountAwaitingApproval(bankAccount)
}

export const isBankAccountApproved = (bankAccount: BankAccount): boolean => {
  return BankAccountStatus.Approved === bankAccount?.status?.id
}

export const isBankAccountAwaitingApproval = (bankAccount: BankAccount): boolean => {
  return BankAccountStatus.AwaitingApproval === bankAccount?.status?.id
}

interface BankDetailsDto {
  id: string
  name: string
  code: string
  codeSort: string
  address: string
  residenceCountry: NameDto<string>
  status: NameDto
}

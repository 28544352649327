import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'

import { TextSmallStrong } from '../../../../ui/Typography/Typography'
import { Operator } from '../../../../utils/ApiClient'
import { isAllowedNumericKey } from '../../../../utils/input.utils'
import { Chip } from '../../../chip/Chip'
import { TextField } from '../../../field/TextField'
import { FilterQueryProps } from '../../FilterQueryModal'

import styles from './FilterModalFromToFilter.module.scss'

export type ChipVariantType = 'light' | 'dark'

interface QueryExtended extends FilterQueryProps {
  value: string | number
  operator: Operator
}

export interface FromToI<T> {
  from: T
  to: T
}

export enum FromToVariant {
  From = 'from',
  To = 'to',
}

export interface handleFromToChange {
  value: string | number
  type: FromToVariant
  key: string
}

export interface FilterModalFromToFilterProps {
  labels?: FromToI<string>
  name: string
  setState: (value: React.SetStateAction<FilterQueryProps | undefined>) => void
  keys: FromToI<string>
  state?: FilterQueryProps
  presets?: number[]
  isQueryParameter?: boolean
}

export const FilterModalFromToFilter: React.FC<FilterModalFromToFilterProps> = (props) => {
  const { t } = useTranslation()
  const {
    labels = { from: t('From'), to: t('To') },
    setState,
    state,
    keys,
    name,
    presets,
    isQueryParameter,
  } = props
  const [selectedPreset, setSelectedPreset] = useState<number | undefined>()
  const [extendOptions, setExtendOptions] = useState<boolean>()

  const clearFields = () => {
    setState({
      ...state,
      [keys.from]: '',
      [keys.to]: '',
    })
    setValues({ ...values, [keys.from]: '', [keys.to]: '' })
  }

  const toggleExtendOptions = () => {
    if (extendOptions) {
      clearFields()
    } else {
      setSelectedPreset(undefined)
    }
    setExtendOptions(!extendOptions)
  }

  const handleFromTo = ({ value, type, key }: handleFromToChange) => {
    setFieldValue(key, value)
    if (value === '') {
      return setState({ ...state, [key]: undefined })
    }

    setState({
      ...state,
      [key]: isQueryParameter
        ? value
        : {
            value,
            operator: type === FromToVariant.From ? Operator.MORE_OR_EQUAL : Operator.LESS_OR_EQUAL,
          },
    })
  }

  const handlePreset = (value: number) => {
    if (!presets?.length) {
      return
    }
    if (selectedPreset === value) {
      setSelectedPreset(undefined)
      return clearFields()
    }
    setSelectedPreset(value)
    setExtendOptions(false)
    if (!value || isNaN(value)) {
      return
    }
    setState({
      ...state,
      [keys.from]: isQueryParameter
        ? 0
        : {
            value: 0,
            operator: Operator.MORE_OR_EQUAL,
          },
      [keys.to]: isQueryParameter
        ? value
        : {
            value: value,
            operator: Operator.LESS_OR_EQUAL,
          },
    })
  }

  useEffect(() => {
    if (isQueryParameter) {
      setExtendOptions(!!state?.[keys.from] || !!state?.[keys.to])
      const from = state?.[keys.from] as number
      const to = state?.[keys.to] as number
      if (from === 0 && presets?.includes(to)) {
        handlePreset(to)
      }
    } else {
      setExtendOptions(
        !!(state?.[keys.from] as QueryExtended)?.value ||
          !!(state?.[keys.to] as QueryExtended)?.value
      )
      const from = (state?.[keys.from] as QueryExtended)?.value
      const to = (state?.[keys.to] as QueryExtended)?.value as number
      if (from === 0 && presets?.includes(to)) {
        handlePreset(to)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fromAmount = isQueryParameter
    ? (state?.[keys.from] as number)
    : (state?.[keys.from] as QueryExtended)?.value || ''

  const { setFieldValue, errors, values, setValues } = useFormikContext<FilterQueryProps>()

  return (
    <div className={styles.fromToFilter}>
      <TextSmallStrong>{name}</TextSmallStrong>
      <div className={styles.buttons}>
        {presets?.map((preset) => (
          <span className={styles.buttonItem}>
            <Chip
              text={`< ${preset}`}
              onClick={() => handlePreset(preset)}
              isActive={selectedPreset === preset}
              key={preset}
            />
          </span>
        ))}
        <span className={styles.buttonItem}>
          <Chip text={t('Custom')} onClick={() => toggleExtendOptions()} isActive={extendOptions} />
        </span>
      </div>
      {extendOptions && (
        <>
          <div className={styles.fromTo}>
            <TextField
              className={styles.input}
              label={labels.from}
              type='text'
              onChange={(value) =>
                handleFromTo({
                  value,
                  key: keys.from,
                  type: FromToVariant.From,
                })
              }
              value={fromAmount}
              onKeyDown={(e) => !isAllowedNumericKey(e) && e.preventDefault()}
            />
            <TextField
              className={styles.input}
              label={labels.to}
              type='text'
              onChange={(value) =>
                handleFromTo({
                  value,
                  key: keys.to,
                  type: FromToVariant.To,
                })
              }
              value={
                (isQueryParameter
                  ? (state?.[keys.to] as number)
                  : (state?.[keys.to] as QueryExtended)?.value) || ''
              }
              onKeyDown={(e) => !isAllowedNumericKey(e) && e.preventDefault()}
            />
          </div>
          {errors[keys.to] && <span className={styles.error}>{errors[keys.to]}</span>}
        </>
      )}
    </div>
  )
}

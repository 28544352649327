import { useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'

import { Loading } from '../../../global/Loading/Loading'
import { Modal } from '../../../global/modal/Modal'
import { AccountGroupType } from '../../../model/AccountGroupType'
import { MasterTransactionDto } from '../../../model/MasterTransactionDto'
import { PlatformTypeEnum } from '../../../model/PlatformTypeEnum'
import { TransferTypeEnum } from '../../../model/TransactionDto'
import { CurrencyType, WalletTypeEnum, isWalletETDType } from '../../../model/WalletDto'
import { useNavigate } from '../../../navigation/custom-react-router-dom'
import { Operator, useApiClient } from '../../../utils/ApiClient'
import { AuthSessionContext } from '../../../utils/AuthContext'
import { ClientApiClient } from '../../../utils/clientApi'
import { useFetchOne } from '../../../utils/useFetch'
import { TransferFormETD, TransferValues } from './TransferFormETD'
import { TransferSuccessModal } from './TransferSuccessModal'

import styles from './TransferForm.module.scss'

interface MatchProps {
  walletId?: string
  tradingAccountId?: string
}

export const WalletTransferPageETD = () => {
  const navigate = useNavigate()
  const { walletId, tradingAccountId }: MatchProps = useParams()
  const { state } = useLocation()
  const [currency] = useState<CurrencyType | undefined>(state?.currency)
  const apiClient = useApiClient(ClientApiClient)
  const [auth] = useContext(AuthSessionContext)

  const [masterTransaction, setMasterTransaction] = useState<MasterTransactionDto | undefined>()
  const [isSubmitting, setSubmitting] = useState(false)
  const [transferType, setTransferType] = useState<TransferTypeEnum>(
    TransferTypeEnum.WALLET_TO_WALLET
  )

  const callback = useCallback(async () => {
    return Promise.all([
      apiClient.getWallets({
        ClientWalletType: `${auth?.id}__${WalletTypeEnum.ETD}`,
      }),
      apiClient.getTradingAccounts({
        pageSize: 150,
        search: {
          AccountGroupType: {
            value: AccountGroupType.Live,
            operator: Operator.EQUAL,
          },
          Platform: {
            value: PlatformTypeEnum.CQGCAST, // it's for ETD
            operator: Operator.EQUAL,
          },
          Status: {
            value: 1,
            operator: Operator.EQUAL,
          },
        },
      }),
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletId, tradingAccountId])

  const { data = [], isLoading: fetchingAccounts } = useFetchOne(callback)
  const [wallets = [], tradingAccounts] = data

  const processTransfer = (values: TransferValues) => {
    const { transferFrom } = values

    return transferFrom?.displayType === 'wallet'
      ? walletToTradingAccountTransfer(values)
      : tradingAccountToWalletTransfer(values)
  }

  const walletToTradingAccountTransfer = ({
    transferFrom,
    transferTo,
    fromAmount,
  }: TransferValues) => {
    setTransferType(TransferTypeEnum.WALLET_TO_TA)
    return apiClient.transferWalletToTradingAccount({
      FromWalletId: transferFrom?.id || '',
      ToTaId: transferTo?.id || '',
      Amount: fromAmount ?? 0,
      clientIpAddress: '',
      referrer_url: '',
    })
  }

  const tradingAccountToWalletTransfer = ({
    transferFrom,
    transferTo,
    fromAmount,
  }: TransferValues) => {
    setTransferType(TransferTypeEnum.TA_TO_WALLET)
    return apiClient.transferTradingAccountToWallet({
      ToWalletId: transferTo?.id || '',
      FromTaId: transferFrom?.id || '',
      Amount: fromAmount ?? 0,
      clientIpAddress: '',
      referrer_url: '',
    })
  }

  const handleSubmit = async (values: TransferValues) => {
    try {
      setSubmitting(true)
      const masterTransaction = await processTransfer(values)
      setSubmitting(false)
      setMasterTransaction(masterTransaction)
    } catch (e: unknown) {
      console.error(e)
      // alert(error.response?.response?.data.code)
    } finally {
      setSubmitting(false)
    }
  }

  const handleCloseTransferSuccessModal = () => {
    navigate('/dashboard/traders-room/transaction-history')
  }

  const { t } = useTranslation()

  if (masterTransaction) {
    return (
      <Modal
        size='xsmall'
        cardClassName={styles.modalContainer}
        closeModal={handleCloseTransferSuccessModal}
        render={() => (
          <TransferSuccessModal
            masterTransaction={masterTransaction}
            onCancel={handleCloseTransferSuccessModal}
            transferType={transferType}
          />
        )}
      />
    )
  }

  return (
    <Loading text={t('Wallet.Transferring...')} isLoading={isSubmitting} showLoadingIcon>
      <TransferFormETD
        fetchingAccounts={fetchingAccounts}
        wallets={wallets.filter((x) => isWalletETDType(x.walletType.id))}
        tradingAccounts={tradingAccounts?.items || []}
        preselectedWallet={wallets.find((w) => w.id === walletId)}
        preselectedTradingAccount={tradingAccounts?.items.find((ta) => ta.id === tradingAccountId)}
        currency={currency}
        handleFormSubmit={handleSubmit}
      />
    </Loading>
  )
}

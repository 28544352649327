import { useCallback, useState } from 'react'

import { useProductReadContext } from '../../global/context/ProductContext'
import { useSessionLanguage } from '../../global/context/SessionSettingsContext'
import { GuideTypes } from '../../model/ClientGuideDto'
import { WalletTypeEnum } from '../../model/WalletDto'
import { useApiClient } from '../../utils/ApiClient'
import { ClientApiClient } from '../../utils/clientApi'
import { useFetchOne } from '../../utils/useFetch'
import { CarouselProvider } from '../Carousel/CarouselProvider'
import {
  CreateTradingAccountGuideCarouselChild,
  DownloadTradingCarouselChild,
  FundWalletGuideCarouselChild,
  GetMetaTraderGuideCarouselChild,
  TickmillMobileAppCarouselChild,
  TransferWalletToTradingAccountGuideCarouselChild,
} from './FirstTimeGuideCarouselChild'
import { GuideConfirmationModal } from './GuideConfirmationModal'

import styles from './FirstTimeGuide.module.scss'

interface FirstTimeGuideCarouselProps {
  onConfirmationModalOpen(): void
}

interface FirstTimeGuideCarouselCFDProps {
  isWalletsLoading: boolean
  reloadGuide(): void
  walletType?: WalletTypeEnum
  handleDeposit: () => void
  depositDisabled?: boolean
}

export const FirstTimeGuideCarouselCFD: React.FC<FirstTimeGuideCarouselCFDProps> = (props) => {
  const apiClient = useApiClient(ClientApiClient)
  const { product } = useProductReadContext()
  const locale = useSessionLanguage()
  const { handleDeposit, depositDisabled, isWalletsLoading } = props

  const walletsCallback = useCallback(
    () => apiClient.getWallets({ walletTypeId: props.walletType }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locale]
  )
  const { data: wallets = [] } = useFetchOne(walletsCallback)

  const defaultWallet = wallets.find((wallet) => wallet.isDefault) || wallets[0]

  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false)

  const handleGuideDismiss = async () => {
    await apiClient.updateClientGuideDismiss(GuideTypes.IntroductionGuide, {
      tickmillProductId: product,
    })
    props.reloadGuide()
    setConfirmationModalOpen(false)
  }

  const handleConfirmationModalOpen = () => {
    setConfirmationModalOpen(true)
  }

  const handleConfirmationModalClose = () => {
    setConfirmationModalOpen(false)
  }

  const carousels = [
    { component: <FundWalletGuideCarouselChild /> },
    {
      component: (
        <CreateTradingAccountGuideCarouselChild
          defaultWallet={defaultWallet}
          disabledAction={depositDisabled}
          isWalletsLoading={isWalletsLoading}
          onDeposit={handleDeposit}
        />
      ),
    },
    { component: <TransferWalletToTradingAccountGuideCarouselChild /> },
    {
      component: <GetMetaTraderGuideCarouselChild />,
    },
    {
      component: <DownloadTradingCarouselChild />,
    },
    { component: <TickmillMobileAppCarouselChild /> },
  ]

  const carouselSettings = {
    hideDots: true,
    interval: 5000,
    items: carousels,
    loop: false,
  }

  return (
    <div className={styles.wrapper}>
      {isConfirmationModalOpen && (
        <GuideConfirmationModal
          onGuideClose={handleGuideDismiss}
          onClose={handleConfirmationModalClose}
        />
      )}
      <CarouselProvider<FirstTimeGuideCarouselProps>
        {...carouselSettings}
        onConfirmationModalOpen={handleConfirmationModalOpen}
      />
    </div>
  )
}

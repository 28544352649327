import React from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import classNames from 'classnames'

import { Tabs, defineTab } from '../global/tabs/Tabs'
import { useCurrentTab } from '../hooks/useCurrentTab'
import { useNavigate } from '../navigation/custom-react-router-dom'

import styles from './Subscriptions.module.scss'

export const SubscriptionsETD: React.FC = () => {
  const { t } = useTranslation()
  const currentTab = useCurrentTab('subscriptions')
  const navigate = useNavigate()

  function* getTabs() {
    yield defineTab('trading-platforms', t('Subscriptions.Trading Platforms'))
    yield defineTab('market-data', t('Market Data.title'))
    yield defineTab('history', t('History'))
  }

  const TabsDefinition = [...getTabs()]

  return (
    <div>
      <div className={classNames('section', styles.adminRoutes)}>
        <Tabs
          tabs={TabsDefinition}
          currentTab={currentTab}
          appearance='light'
          onTabChanged={(tab) => navigate(`/dashboard/subscriptions/${tab}`)}
        />
      </div>
      <Outlet />
    </div>
  )
}

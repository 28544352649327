import React from 'react'

import { WalletWithdrawTransactionCompletedPage } from '../../Traders-Room/Wallets/WalletWithdraw/WalletWithdrawTransactionPage/WalletWithdrawTransactionCompletedPage'
import { useNavigate } from '../../navigation/custom-react-router-dom'

export const IBWalletWithdrawTransactionCompletedPage: React.FC = () => {
  const navigate = useNavigate()

  const handleCancel = () => {
    navigate('/dashboard/introducing-broker/transaction-history')
  }

  return <WalletWithdrawTransactionCompletedPage onCancel={handleCancel} />
}

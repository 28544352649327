import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../../../global/button/Button'
import { useSnackbar } from '../../../global/context/SnackbarContext'
import IconButton from '../../../global/iconButton/IconButton'
import { useFormatNumber } from '../../../hooks/useFormatNumber'
import { DotsIcon } from '../../../icons/DotsIcon'
import { DummyIcon } from '../../../icons/DummyIcon'
import { WarningIcon } from '../../../icons/WarningIcon'
import { WalletDto } from '../../../model/WalletDto'
import { Link } from '../../../navigation/custom-react-router-dom'
import { AlertIconWrapper } from '../../../ui/AlertIconWrapper/AlertIconWrapper'
import { TextSmall, TextSmallStrong } from '../../../ui/Typography/Typography'
import { WalletRestrictions, isRestricted, isWalletSuspended } from '../../../utils/wallet.utils'

import styles from './WalletBalancesCard.module.scss'

interface WalletBalancesCardProps {
  wallet: WalletDto
  onSetWalletOptionsModal(wallet: WalletDto): void

  onSetModalVisible(v: boolean): void
}

export const WalletBalancesCard: React.FC<WalletBalancesCardProps> = (props) => {
  const { wallet, onSetWalletOptionsModal, onSetModalVisible } = props

  const { t } = useTranslation()
  const { formatMoney } = useFormatNumber()

  const { addSnackbar } = useSnackbar()

  const showSnackbarError = () => addSnackbar.error({ message: t('This action is not available') })

  const hasAllRestrictions = isWalletSuspended(wallet.restrictions)
  const isDepositRestricted = isRestricted(WalletRestrictions.DEPOSIT, wallet.restrictions)
  const isTransferFromRestricted = isRestricted(
    WalletRestrictions.TRANSFER_FROM,
    wallet.restrictions
  )

  return (
    <div className={styles.card}>
      <div className={styles.titleWrapper}>
        {hasAllRestrictions ? (
          <IconButton onClick={() => onSetModalVisible(true)}>
            <WarningIcon size={20} color='error' />
          </IconButton>
        ) : (
          <DummyIcon />
        )}
        <TextSmall>
          {wallet.currency.id} {t('Wallet.Wallet')}
        </TextSmall>

        <IconButton onClick={() => onSetWalletOptionsModal(wallet)}>
          <DotsIcon />
        </IconButton>
      </div>
      <h2 className={styles.title}>{formatMoney(wallet.balance, wallet.currency.id)}</h2>
      <div>
        <TextSmall className={styles.subLabel}>{t('Wallet.Reserved')}</TextSmall>
        <TextSmallStrong>{formatMoney(wallet.reserved, wallet.currency.id)}</TextSmallStrong>
      </div>
      <div className='mt-4 mb-1'>
        {isDepositRestricted && (
          <AlertIconWrapper fullWidth spaced isActive={!hasAllRestrictions}>
            <Button
              appearance='primary'
              state='disabled'
              size='M'
              onClick={showSnackbarError}
              className={styles.button}
            >
              {t('Deposit')}
            </Button>
          </AlertIconWrapper>
        )}
        {!isDepositRestricted && (
          <Link to={`/dashboard/traders-room/wallets/${wallet.id}/deposit`}>
            <Button appearance='primary' size='M' className={styles.button}>
              {t('Deposit')}
            </Button>
          </Link>
        )}
      </div>
      <div className='mb-4'>
        {isTransferFromRestricted && (
          <AlertIconWrapper fullWidth spaced isActive={!hasAllRestrictions}>
            <Button
              appearance='secondary'
              state='disabled'
              size='M'
              onClick={showSnackbarError}
              className={styles.button}
            >
              {t('Transfer')}
            </Button>
          </AlertIconWrapper>
        )}
        {!isTransferFromRestricted && (
          <Link to={`/dashboard/traders-room/wallets/${wallet.id}/transfer`}>
            <Button appearance='secondary' size='M' className={styles.button}>
              {t('Transfer')}
            </Button>
          </Link>
        )}
      </div>
      <div>
        <Link
          className={styles.history}
          to='/dashboard/traders-room/transaction-history'
          state={{ walletId: wallet.id }}
        >
          {t('View Transaction History')}
        </Link>
      </div>
    </div>
  )
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { ConversionIcon } from '../../../icons/ConversionIcon'
import { TradingAccount } from '../../../model/TradingAccount'
import { CurrencyType } from '../../../model/WalletDto'
import { Text } from '../../../ui/Typography/Typography'
import { InitiateConversion } from './TradingAccountBalances'

import styles from '../BalancesOptions.module.scss'

interface TradingAccountBalancesOptionsProps {
  tradingAccount: TradingAccount
  currency: CurrencyType
  isTransferFromRestricted: boolean
  intiateConversion: ({ currency, balance }: InitiateConversion) => void
}

export const TradingAccountBalancesOptions: React.FC<TradingAccountBalancesOptionsProps> = ({
  tradingAccount,
  currency,
  isTransferFromRestricted,
  intiateConversion,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <ul className={styles.options}>
        <li
          className={classNames(styles.option, { [styles.isDisabled]: isTransferFromRestricted })}
          onClick={() =>
            intiateConversion({
              currency: currency,
              balance: tradingAccount.platformOverview.balances.find((b) => b.currency === currency)
                ?.balance,
            })
          }
        >
          <ConversionIcon
            color={isTransferFromRestricted ? 'disabled' : 'text'}
            strokeWidth={1.5}
            size={24}
          />
          <Text>{t('Wallet.Convert')}</Text>
        </li>
      </ul>
    </>
  )
}

import { NameDto } from './NameDto'

export enum TwoFAProviderEnum {
  Email = 1,
  SMS = 2,
  GoogleAuthenticator = 3,
}

export interface TwoFactorVerificationPayload {
  code: string
  token: string
  typeId: number
}

export interface Change2FAProvidersData {
  type: NameDto
  authMedium?: string
}

export interface Update2FAProvidersData {
  typeId: number
  authMedium?: string
  twoFactor?: TwoFactorVerificationPayload
}

export interface Update2FAProvidersActionsData {
  actionTypeId: number
  twoFactor?: TwoFactorVerificationPayload
}

export interface VerifyFirstTimeUseData {
  typeId?: number
  code: string
}

export interface TwoFAProviderItem {
  authMedium: string
  authActions: NameDto[]
  type: NameDto
}

export interface GoogleAuthenticator2FAProviderDataDto {
  data: {
    qrCode: string
    manualToken: string
  }
}

export interface TwoFactorAuthRecoveryCodeDto {
  recoveryCode: string | null
  isInvalid: boolean
}

export interface TwoFactorAuthRecoveryCodesDto {
  recoveryCodesDto: TwoFactorAuthRecoveryCodeDto[]
}

export const isGoogleAuthenticatorType = (typeId?: TwoFAProviderEnum): boolean =>
  typeId === TwoFAProviderEnum.GoogleAuthenticator

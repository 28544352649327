import { FC } from 'react'

import { useWindowResize } from '../../utils/domUtils'
import { useIBLink } from '../../utils/useIBLink'
import { useIBReferralCodes } from '../../utils/useIBReferralCodes'
import { useLoyaltyData } from '../IntroducingBroker'
import { useIntroducingBrokerById } from '../hooks/useIntroducingBrokerById'
import { useIntroducingBrokerLegalDocuments } from '../hooks/useIntroducingBrokerLegalDocuments'
import { LoyaltyProgram } from './LoyaltyProgram/LoyaltyProgram'
import { MultiLevel } from './MultiLevel/MultiLevel'
import { QuickPerformance } from './QuickPerformance/QuickPerformance'
import { ReferralLink } from './ReferralLink/ReferralLink'
import { TransactionHistory } from './TransactionHistory/TransactionHistory'
import { Wallets } from './Wallets/Wallets'
import { useConsumeLoyaltyReward } from './hooks/useConsumeLoyaltyReward'
import { useIBPerformance } from './hooks/useIBPerformance'
import { useMultiLevelCommission } from './hooks/useMultiLevelCommission'
import { useWalletsWithLatestTransactions } from './hooks/useWalletsWithLatestTransactions'

import styles from './IBDashboardPage.module.scss'

export const IBDashboardPage: FC = () => {
  const isMobile = useWindowResize()
  const { ibLoyaltyData, isLoyaltyDataLoading } = useLoyaltyData()

  const { walletsWithLatestTransactions, isLoading: isWalletsLoading } =
    useWalletsWithLatestTransactions()

  const {
    data: ibData,
    isLoading: isIbDataLoading,
    forceRefresh: forceIBRefresh,
  } = useIntroducingBrokerById()

  const {
    ibCode,
    mtCode,
    isLoading: isLoadingReferralCodes,
    legalDocumentsSchemes,
  } = useIBReferralCodes()

  const { data: legalDocuments, isLoading: isLegalDocumentsLoading } =
    useIntroducingBrokerLegalDocuments({
      legalDocumentsSchemes,
    })

  const { ibLink } = useIBLink(ibCode)

  const {
    data: ibPerformanceData,
    isLoading: ibPerformanceDataLoading,
    dateRange,
    setDateRange,
  } = useIBPerformance()

  const { consumeLoyaltyReward, isLoading: isConsumeLoyaltyRewardLoading } =
    useConsumeLoyaltyReward({
      onComplete: forceIBRefresh,
    })

  const {
    data: multiLevelData,
    isLoading: isMultiLevelDataLoading,
    dismissNotification,
  } = useMultiLevelCommission({
    isPaused: !ibData?.hasMultiLevelCommission,
  })

  const isMultiLevelLoading = isLegalDocumentsLoading || isIbDataLoading || isMultiLevelDataLoading
  const isLoyaltyProgramLoading = isLoyaltyDataLoading || isIbDataLoading

  return (
    <div className={styles.ibDashboardPage}>
      <div className={styles.col}>
        <Wallets
          walletsWithLatestTransactions={walletsWithLatestTransactions}
          isLoading={isWalletsLoading}
        />
        <TransactionHistory
          walletsWithLatestTransactions={walletsWithLatestTransactions}
          isLoading={isWalletsLoading}
        />
        {!isMobile && (
          <QuickPerformance
            isLoading={ibPerformanceDataLoading}
            ibPerformanceData={ibPerformanceData}
            className={styles.section}
            dateRange={dateRange}
            setDateRange={setDateRange}
          />
        )}
      </div>
      <div className={styles.col}>
        {(isMultiLevelLoading || ibData?.hasMultiLevelCommission) && (
          <MultiLevel
            isLoading={isMultiLevelLoading}
            legalDocuments={legalDocuments ?? []}
            multiLevelData={multiLevelData ?? undefined}
            dismissNotification={dismissNotification}
          />
        )}
        {(isLoyaltyProgramLoading || ibLoyaltyData?.loyaltyProgramEnabled) && (
          <LoyaltyProgram
            isLoading={isLoyaltyProgramLoading}
            loyaltyData={ibLoyaltyData ?? undefined}
            onClaimClick={consumeLoyaltyReward}
            ibData={ibData}
            isConsumeLoyaltyRewardLoading={isConsumeLoyaltyRewardLoading}
          />
        )}
        <ReferralLink
          isLoading={isLoadingReferralCodes}
          ibCode={ibCode}
          ibLink={ibLink}
          mtCode={mtCode}
        />
        {isMobile && (
          <QuickPerformance
            isLoading={ibPerformanceDataLoading}
            ibPerformanceData={ibPerformanceData}
            className={styles.section}
            dateRange={dateRange}
            setDateRange={setDateRange}
          />
        )}
      </div>
    </div>
  )
}

import React from 'react'

import { WalletDto } from '../../../model/WalletDto'
import { BalancesOptionsModal } from '../BalancesOptionsModal'
import { WalletBalancesOptions } from './WalletBalancesOptions'

interface Props {
  wallet: WalletDto
  onWithdrawal: (walletId: string) => void
  onWalletInformationModal(): void
  onSetWalletOptionsData: (wallet?: WalletDto) => void
  isLoading: boolean
}

export const WalletOptionsModal: React.FC<Props> = (props) => {
  const { wallet, onWithdrawal, onWalletInformationModal, onSetWalletOptionsData, isLoading } =
    props

  return (
    <BalancesOptionsModal
      closeModal={() => onSetWalletOptionsData(undefined)}
      renderOptions={() => (
        <WalletBalancesOptions
          wallet={wallet}
          onWithdrawal={onWithdrawal}
          onSetWalletInformationModalOpen={onWalletInformationModal}
          onSetWalletOptionsData={onSetWalletOptionsData}
          isLoading={isLoading}
        />
      )}
    />
  )
}

import React, { useContext, useEffect, useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'

import { Navigate } from '../navigation/custom-react-router-dom'

import '../layout/site.scss'
import { useDashboardRoutes } from '../Dashboard/DashboardRoutes'
import { ForgotPasswordPage } from '../ForgotPassword/ForgotPasswordPage'
import { ResetPasswordPage } from '../ForgotPassword/ResetPasswordPage'
import { JourneyPage } from '../Journey/JourneyPage'
import { LoginPage } from '../Login/LoginPage'
import { LogoutPage } from '../Logout/LogoutPage'
import MarketingPage from '../MarketingForm/MarketingPage'
import { NotificationCenter } from '../Notifications/NotificationCenter'
import { NotificationDetailsPage } from '../Notifications/NotificationDetailsPage'
import { TwoFactorAuthConfirmPage } from '../Profile/AccountSettings/TwoFactorAuthConfirmPage'
import { useProfileRoutes } from '../Profile/ProfileRoutes'
import { TwoFactorAuthErrorPage } from '../TwoFactorAuth/TwoFactorAuthErrorPage'
import { TwoFactorAuthPage } from '../TwoFactorAuth/TwoFactorAuthPage'
import { WrongEntityPage } from '../WrongEntity/WrongEntityPage'
import { Loading } from '../global/Loading/Loading'
import { RedirectionManager } from '../global/RedirectionManager/RedirectionManager'
import { WalletSetupLoader } from '../global/WalletSetupLoader/WalletSetupLoader'
import { useSessionEntity } from '../global/context/EntityContext'
import { useProductReadContext } from '../global/context/ProductContext'
import { useArabicSessionLanguage } from '../global/context/SessionSettingsContext'
import { Locale } from '../global/locale/Locale'
import { PageNotFound } from '../global/notFound/PageNotFound'
import { SumsubThankYouPage } from '../global/sumsub/SumsubThankYouPage'
import { Toast } from '../global/toast/Toast'
import { useDocumentVerificationPath } from '../hooks/useDocumentVerificationPath'
import i18n from '../i18n'
import { AccountDetailedDto, isVerificationExpedited } from '../model/AccountDetailedDto'
import { isAccountActivatedStatus } from '../model/AccountDetailedStatus'
import { BlockedNote } from '../pages/Blocked/Blocked'
import { FirstTimeDepositOrDocumentsResolver } from '../pages/Signup/Pages/FirstTimeDeposit/FirstTimeDepositOrDocumentsResolver'
import { VerifyEmailRedirect } from '../pages/Signup/Pages/PersonalDetails/PersonalDetailsStep4/VerifyEmailPage'
import { SignupPage } from '../pages/Signup/Signup.page'
import { useAccountAccessStatuses } from '../utils/AccountAccess/accountAccessStatuses'
import { isAccountDocumentManagementPermitted } from '../utils/AccountAccess/accountDocumentStatuses'
import {
  isAccountKycUpdateDetailsAllowed,
  isAccountKycUpdateDetailsNotAllowed,
  isSumsubNeeded,
  requireDepositBeforeKYC,
} from '../utils/AccountAccess/accountKYCStatuses'
import {
  hasSubmittedAppropriatenessTest,
  isProductAppropriatenessValid,
} from '../utils/AccountAccess/accountProductStatuses'
import { useAccountVisibilityStatuses } from '../utils/AccountAccess/accountVisibilityStatuses'
import { useAccountReadContext, useAccountWriteContext } from '../utils/AccountContextContext'
import { AuthSessionContext, MaybeAuthSession } from '../utils/AuthContext'
import { useFirstDepositContext } from '../utils/FirstDepositContext'
import { useMaintenance } from '../utils/MaintenanceModeContext'
import { isTickmillPartnerType, isTickmillUK } from '../utils/companyName.utils'
import { useNotificationFunctionality } from '../utils/notifications'
import { CommonPaths } from '../utils/path'
import { Layout } from './Layout'
import { MinimalLayout } from './MinimalLayout'
import { ProductRedirect } from './ProductRedirect'
import { useRenderRoutes } from './RenderRoutes'
import { TradingAccountTradeRedirect } from './TradingAccountTradeRedirect'

export const MainContainer: React.FC<{
  toast?: Toast
  auth: MaybeAuthSession
  account?: AccountDetailedDto
  urlLocale?: Locale
  setCurrentPath: (path: string) => void
}> = (props) => {
  const renderRoutes = useRenderRoutes()
  const { toast, setCurrentPath } = props
  const [auth] = useContext(AuthSessionContext)
  const { account } = useAccountReadContext()
  const { isMaintenanceMode } = useMaintenance()
  const [isActivated, setIsActivated] = useState(true)
  const DashboardRoutes = useDashboardRoutes()
  const ProfileRoutes = useProfileRoutes()
  const isArabic = useArabicSessionLanguage()
  const { clearAccount } = useAccountWriteContext()

  const rootDiv = document.getElementById('root')
  if (rootDiv) {
    rootDiv.setAttribute('dir', i18n.dir())
  }

  useEffect(() => {
    document.body.classList.toggle('localeArabic', isArabic)
  }, [isArabic])

  const location = useLocation()
  const { search } = location

  const { product } = useProductReadContext()
  const { hasFirstDeposit } = useFirstDepositContext()
  const { enableNotifications } = useNotificationFunctionality()
  const entity = useSessionEntity()
  const accountAccessStatuses = useAccountAccessStatuses()
  const accountVisibilityStatuses = useAccountVisibilityStatuses()
  const docVerificationPath = useDocumentVerificationPath()

  const hasRedirQuery = new URLSearchParams(location.search).get('redir') !== null

  useEffect(() => {
    if (!auth) {
      clearAccount()
    }
    if (!account) {
      return
    }
    setIsActivated(accountAccessStatuses.isAccountActivatedStatus)

    if (accountVisibilityStatuses.isAccountVisibilityLockUnlockStatus) {
      setIsActivated(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, product, auth])

  if (isMaintenanceMode) {
    return (
      <Routes>
        <Route element={<Layout setCurrentPath={setCurrentPath} toast={toast} />}>
          <Route path={'/login'} element={<LoginPage />} />
          <Route path={'/*'} element={<Navigate to={'/login'} />} />
        </Route>
      </Routes>
    )
  }

  if (auth && hasFirstDeposit === null) {
    return <Loading isLoading showLoadingIcon />
  }

  if (auth && account) {
    return (
      <Routes>
        <Route path='/trading-account-trade-redirect' element={<TradingAccountTradeRedirect />} />
        <Route path='/logout' element={<LogoutPage />} />
        <Route element={<Layout setCurrentPath={setCurrentPath} toast={toast} />}>
          {isActivated && hasRedirQuery && (
            <>
              <Route path='/' element={<RedirectionManager />} />
              <Route path='/login' element={<RedirectionManager />} />
            </>
          )}

          {isAccountDocumentManagementPermitted(product, account) &&
            !isAccountKycUpdateDetailsAllowed(account, product, true) &&
            ['/dashboard', '/profile/appropriateness-test', '/profile/kyc-update-details'].map(
              (path) => (
                <Route key={path} path={path} element={<Navigate to={docVerificationPath} />} />
              )
            )}
          {isProductAppropriatenessValid(product, account) &&
            (hasSubmittedAppropriatenessTest(account, product) || !isTickmillUK(account)) &&
            ['/profile/document-management', '/profile/verification'].map((path) => (
              <Route
                key={path}
                path={path}
                element={<Navigate to='/profile/appropriateness-test' />}
              />
            ))}
          {isProductAppropriatenessValid(product, account) &&
            ['/profile/document-management', 'profile/verification'].map((path) => (
              <Route key={path} path={path} element={<Navigate to='/dashboard/take-app-test' />} />
            ))}
          {isProductAppropriatenessValid(product, account) &&
            [
              '/dashboard/traders-room',
              '/dashboard/introducing-broker',
              '/dashboard/payment-agent',
              '/profile/kyc-update-details',
            ].map((path) => (
              <Route
                key={path}
                path={path}
                element={<Navigate to='/dashboard/product-registration/appropriateness-test' />}
              />
            ))}

          {(requireDepositBeforeKYC(account) || isVerificationExpedited(account)) && (
            <Route path={CommonPaths.FirstTimeDepositLoading} element={<WalletSetupLoader />} />
          )}

          {isSumsubNeeded(account) && (
            <>
              <Route path={CommonPaths.AccountLoading} element={<WalletSetupLoader />} />
              <Route path={CommonPaths.AccountPending} element={<SumsubThankYouPage />} />
            </>
          )}

          {account && isAccountKycUpdateDetailsNotAllowed(account, product) && (
            <Route
              path='/profile/kyc-update-details'
              element={<Navigate to='/profile/personal-info' />}
            />
          )}

          {renderRoutes(DashboardRoutes)}
          {renderRoutes(ProfileRoutes)}

          {enableNotifications && <Route path='notifications' element={<NotificationCenter />} />}
          {enableNotifications && (
            <Route path='notifications/:notificationId' element={<NotificationDetailsPage />} />
          )}
          {!isTickmillPartnerType(entity) && !isAccountActivatedStatus(account) && (
            <Route path='sign-up/*' element={<SignupPage />} />
          )}
          <Route
            path='/sign-up/first-time-deposit'
            element={<FirstTimeDepositOrDocumentsResolver />}
          />
          <Route path='/journey' element={<JourneyPage />} />
          <Route path='/wrong-entity' element={<WrongEntityPage />} />
          <Route path='/404' element={<PageNotFound />} />
          <Route path='*' element={<ProductRedirect />} />
        </Route>
        <Route element={<MinimalLayout setCurrentPath={setCurrentPath} toast={toast} />}>
          <Route path='/2fa/confirm' element={<TwoFactorAuthConfirmPage />} />
        </Route>
      </Routes>
    )
  }

  if (!auth) {
    return (
      <Routes>
        <Route path='/trading-account-trade-redirect' element={<TradingAccountTradeRedirect />} />
        <Route element={<Layout setCurrentPath={setCurrentPath} toast={toast} />}>
          <Route path='login' element={<LoginPage />} />
          <Route path='login/2fa' element={<TwoFactorAuthPage />} />
          <Route path='login/2fa/expired' element={<TwoFactorAuthErrorPage />} />
          <Route path='blocked' element={<BlockedNote />} />
          {!isTickmillPartnerType(entity) && <Route path='sign-up/*' element={<SignupPage />} />}
          <Route path='logout' element={<LogoutPage />} />
          <Route path='users'>
            <Route path='reset-password/:email/:token' element={<ResetPasswordPage />} />
            <Route path='login' element={<Navigate to={`/login${search}`} />} />
            <Route path='verify-email/:leadId' element={<VerifyEmailRedirect />} />
            <Route path='register' element={<Navigate to={`/sign-up${search}`} />} />
            <Route path='register2/:leadId' element={<VerifyEmailRedirect />} />
            <Route path='register2' element={<Navigate to={`/sign-up${search}`} />} />
          </Route>
          <Route path='forgot-password' element={<ForgotPasswordPage />} />
          <Route path='marketing-form' element={<MarketingPage />} />
          <Route path='*' element={<Navigate to={`/login${search}`} />} />
        </Route>
        <Route element={<MinimalLayout setCurrentPath={setCurrentPath} toast={toast} />}>
          <Route path='/2fa/confirm' element={<TwoFactorAuthConfirmPage />} />
        </Route>
      </Routes>
    )
  }

  return (
    <Routes>
      <Route path='/trading-account-trade-redirect' element={<TradingAccountTradeRedirect />} />
      <Route path='*' element={<Loading isLoading showLoadingIcon />} />
    </Routes>
  )
}

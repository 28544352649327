import React, { PropsWithChildren, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useMatch, useOutletContext, useParams } from 'react-router-dom'
import classNames from 'classnames'

import { Button } from '../../global/button/Button'
import { useSessionLanguage } from '../../global/context/SessionSettingsContext'
import { Table } from '../../global/table/Table'
import { ScrollToIds, useScrollToElementIds } from '../../hooks/useScrollToElementIds'
import { CheckIcon } from '../../icons/CheckIcon'
import { CloseIcon } from '../../icons/CloseIcon'
import { PlatformInfoDto } from '../../model/PlatformInfoDto'
import {
  OperatingSystemEnum,
  formatOSName,
  formatPlatformName,
  getOperatingSystemTypes,
  getPlatformTypes,
  isAndroidOperatingSystem,
  isIOSOperatingSystem,
  isWebTraderOperatingSystem,
} from '../../model/PlatformTypeEnum'
import { Carousel } from '../../ui/Carousel/Carousel'
import { CarouselChild } from '../../ui/Carousel/CarouselItem'
import { TabSwitcherLink, TabsSwitcher } from '../../ui/SwitcherTabs/SwitcherTabs'
import { Text, TextH2, TextH3, TextStrong } from '../../ui/Typography/Typography'
import { useWindowResize } from '../../utils/domUtils'
import { AndroidLogo } from '../images/TickmillTrader/Icons/AndroidLogo'
import { IOSLogo } from '../images/TickmillTrader/Icons/IOSLogo'
import { WebTraderLogo } from '../images/TickmillTrader/Icons/WebTraderLogo'
import tmtAppDesktop from '../images/TickmillTrader/Images/tmtAppDesktop.webp'
import { PlatformDownloadButton } from './PlatformIcon'
import { VideoCards } from './PlatformsLearnMoreVideo'
import {
  useComparePlatformsData,
  usePlatformDownloadLinksData,
  useTabsData,
  useTickmillTraderAndroidVideosData,
  useTickmillTraderIOSVideosData,
  useTickmillTraderWebtraderVideosData,
} from './platformsLearnMoreData'

import styles from './PlatformsLearnMore.module.scss'

interface TickmillTraderProps {
  platformInfo: PlatformInfoDto[]
}

const useScrollTo = () => {
  const { scrollIntoView } = useScrollToElementIds()
  const locale = useSessionLanguage()

  const match = useMatch(`/${locale}/dashboard/tools/platforms/:platformType/:deviceType`)
  const platformType = match?.params?.platformType || ''
  const deviceType = match?.params?.deviceType || ''

  const [isInitialLoad, setIsInitialLoad] = useState(true)

  useEffect(() => {
    const platformNames = getPlatformTypes().map((x) => x.shortName)
    if (platformNames.some((name) => platformType.includes(formatPlatformName(name)))) {
      scrollIntoView([ScrollToIds.ToolsPlatformsHeader])
      setIsInitialLoad(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [platformType])

  useEffect(() => {
    const osNames = getOperatingSystemTypes().map((x) => x.shortName)
    if (osNames.some((name) => deviceType.includes(formatPlatformName(name)))) {
      !isInitialLoad && scrollIntoView([ScrollToIds.ToolsPlatformsOSHeader])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceType])
}

export const PlatformsLearnMorePage: React.FC = () => {
  const { platformInfo = [] } = useOutletContext<TickmillTraderProps>()

  useScrollTo()

  const isMobile = useWindowResize()

  return isMobile ? (
    <TickmillTraderMobile platformInfo={platformInfo} />
  ) : (
    <TickmillTraderDesktop platformInfo={platformInfo} />
  )
}

const TickmillTraderMobile: React.FC<TickmillTraderProps> = (props) => {
  const { platformInfo } = props

  const { t } = useTranslation()

  return (
    <>
      <div className={styles.downloadSection}>
        <img src={tmtAppDesktop} className={styles.hasMarginBottom} alt='' />
        <TextH2 isParagraph className={styles.hasMarginBottom}>
          {t('ToolsPlatforms.Download Your Tickmill Trader')}
        </TextH2>
        <Text className={styles.textSecondary} isParagraph>
          {t('ToolsPlatforms.One of the most powerful trading platforms for mobile devices')}
        </Text>
        <Text isParagraph className={classNames(styles.textSecondary, styles.hasMarginTop)}>
          {t('ToolsPlatforms.Trade on the go with Tickmill Trader (for iOS and Android)')}
        </Text>
        <div className={styles.linksSectionAbout}>
          <div className={styles.linksSection}>
            <DownloadLinks platformInfo={platformInfo} />
          </div>
        </div>
      </div>
      <Title title={t('ToolsPlatforms.Or use the WebTrader and trade from anywhere, anytime')}>
        {t(
          'ToolsPlatforms.Mobile App or WebTrader your access to the global markets, anytime, anywhere'
        )}
      </Title>
      <div className={styles.sectionCompare}>
        <ComparePlatformsTableMobile />
        <div className='is-flex is-align-items-center is-justify-content-center has-text-centered pt-5'>
          <Text isParagraph className={styles.textSecondary}>
            {t(
              'ToolsPlatforms.Use the Tickmill Trader Account to access your Live and Demo account, all in one place!'
            )}
          </Text>
        </div>
        <div className={styles.linksSectionCompare}>
          <div className={styles.linksSection}>
            <DownloadLinks platformInfo={platformInfo} />
          </div>
        </div>
        <DownloadWebTraderLink platformInfo={platformInfo}>
          {t('ToolsPlatforms.Open WebTrader')}
        </DownloadWebTraderLink>
      </div>
      <Outlet context={{ platformInfo }} />
    </>
  )
}

const TickmillTraderDesktop: React.FC<TickmillTraderProps> = (props) => {
  const { platformInfo } = props

  const { t } = useTranslation()

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.downloadSection}>
          <div className={styles.textSection}>
            <TextH2 isParagraph>{t('ToolsPlatforms.Download Your Tickmill Trader')}</TextH2>
            <Text className={styles.textSecondary} isParagraph>
              {t('ToolsPlatforms.One of the most powerful trading platforms for mobile devices')}
            </Text>
            <Text className={styles.textSecondary} isParagraph>
              {t('ToolsPlatforms.Trade on the go with Tickmill Trader (for iOS and Android)')}
            </Text>
            <div className={styles.linksSection}>
              <DownloadLinks platformInfo={platformInfo} />
            </div>
          </div>
          <div className={styles.tmtImageContainer}>
            <img src={tmtAppDesktop} alt='' />
          </div>
        </div>
      </div>
      <Title title={t('ToolsPlatforms.Or use the WebTrader and trade from anywhere, anytime')}>
        {t('ToolsPlatforms.Seamless trading on the go with our proprietary platform')}
      </Title>
      <div className={styles.sectionCompare}>
        <ComparePlatformsTableDesktop />
        <div className={styles.linksSectionCompare}>
          <div className={styles.linksSection}>
            <DownloadLinks platformInfo={platformInfo} />
            <DownloadWebTraderLink platformInfo={platformInfo} className={styles.wtButton}>
              {t('ToolsPlatforms.Open WebTrader')}
            </DownloadWebTraderLink>
          </div>
        </div>
      </div>
      <Outlet context={{ platformInfo }} />
    </div>
  )
}

interface PlatformParams extends Record<string, string | undefined> {
  deviceType: string
}

export const TMTVideos: React.FC = () => {
  const { t } = useTranslation()
  const { deviceType } = useParams<PlatformParams>()
  const { platformInfo = [] } = useOutletContext<TickmillTraderProps>()

  const webVideos = useTickmillTraderWebtraderVideosData()
  const androidVideos = useTickmillTraderAndroidVideosData()
  const iosVideos = useTickmillTraderIOSVideosData()

  return (
    <>
      <Title title={t('ToolsPlatforms.Learn more about Tickmill Trader')}>
        {t('ToolsPlatforms.Discover the features and functionalities of the Tickmill Trader app')}
      </Title>
      <div id={ScrollToIds.ToolsPlatformsOSHeader} />
      <Tabs platformInfo={platformInfo} />
      <div className={styles.videosSection}>
        {isWebTraderOperatingSystem(deviceType) && <VideoCards data={webVideos} />}
        {isAndroidOperatingSystem(deviceType) && <VideoCards data={androidVideos} />}
        {isIOSOperatingSystem(deviceType) && <VideoCards data={iosVideos} />}
      </div>
    </>
  )
}

const ComparePlatformsTableMobile = () => {
  const { t } = useTranslation()

  const { comparePlatformsData } = useComparePlatformsData()

  const renderCellContent = (value: string | boolean) => {
    return value === true ? (
      <CheckIcon color='success' size={14} />
    ) : value === false ? (
      <CloseIcon color='error' size={14} />
    ) : (
      value
    )
  }

  return (
    <Carousel loop interval={10000}>
      <CarouselChild key={1} className={styles.carouselChild}>
        <div className={styles.table}>
          <div className={styles.columns}>
            <div className={styles.tableHead}>
              <TextH3>{t('ToolsPlatforms.WebTrader')}</TextH3>
            </div>
            {comparePlatformsData.map((x) => (
              <div className={styles.column}>
                <div className={styles.columnName}>
                  <Text>{x.name}</Text>
                </div>
                <div>{renderCellContent(x.webTrader)}</div>
              </div>
            ))}
          </div>
        </div>
      </CarouselChild>
      <CarouselChild key={2} className={styles.carouselChild}>
        <div className={styles.table}>
          <div className={styles.columns}>
            <div className={styles.tableHead}>
              <TextH3>{t('ToolsPlatforms.Mobile App')}</TextH3>
            </div>
            {comparePlatformsData.map((x) => (
              <div className={styles.column}>
                <div className={styles.columnName}>
                  <Text>{x.name}</Text>
                </div>
                <div>{renderCellContent(x.mobileApp)}</div>
              </div>
            ))}
          </div>
        </div>
      </CarouselChild>
    </Carousel>
  )
}

const ComparePlatformsTableDesktop = () => {
  const { t } = useTranslation()

  const { comparePlatformsData } = useComparePlatformsData()

  const renderCellContent = (value: string | boolean) => {
    return value === true ? (
      <CheckIcon color='success' size={14} />
    ) : value === false ? (
      <CloseIcon color='error' size={14} />
    ) : (
      value
    )
  }

  return (
    <>
      <div className='is-flex is-align-items-center is-justify-content-center'>
        <Table className={styles.table} wrapperClassName={styles.tableWrapper}>
          <thead>
            <tr>
              <th align='left' />
              <th align='center' className={styles.tableHeadColumn}>
                <TextH3 className={styles.tableHeader}>{t('ToolsPlatforms.WebTrader')}</TextH3>
              </th>
              <th align='center' className={styles.tableHeadColumn}>
                <TextH3 className={styles.tableHeader}>{t('ToolsPlatforms.Mobile App')}</TextH3>
              </th>
            </tr>
          </thead>
          <tbody>
            {comparePlatformsData.map((x) => (
              <tr key={x.name} className={styles.column}>
                <td align='left'>
                  <Text>{x.name}</Text>
                </td>
                <td align='center'>
                  <Text>{renderCellContent(x.webTrader)}</Text>
                </td>
                <td align='center'>
                  <Text>{renderCellContent(x.mobileApp)}</Text>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <div className='is-flex is-align-items-center is-justify-content-center'>
        <Text isParagraph className={styles.textSecondary}>
          {t(
            'ToolsPlatforms.Use the Tickmill Trader Account to access your Live and Demo account, all in one place!'
          )}
        </Text>
      </div>
    </>
  )
}

const DownloadLinks: React.FC<TickmillTraderProps> = (props) => {
  const { platformInfo = [] } = props

  const { platformDownloadLinksData } = usePlatformDownloadLinksData(platformInfo)

  return platformDownloadLinksData.map((x) => (
    <PlatformDownloadButton className={styles.hasMarginRight} to={x.link}>
      {x.icon}
    </PlatformDownloadButton>
  ))
}

const DownloadWebTraderLink: React.FC<
  TickmillTraderProps & React.ButtonHTMLAttributes<HTMLButtonElement>
> = (props) => {
  const { platformInfo = [], ...restProps } = props

  const { t } = useTranslation()

  const { webTraderLink } = usePlatformDownloadLinksData(platformInfo)

  const handleClick = () => {
    webTraderLink && window.open(webTraderLink, '_blank')
  }

  return (
    <Button {...restProps} appearance='primary' size='L' fullWidth onClick={handleClick}>
      {t('ToolsPlatforms.Open WebTrader')}
    </Button>
  )
}

interface TitleProps {
  id?: string
  title: string
}

const Title: React.FC<PropsWithChildren<TitleProps>> = (props) => {
  const { id, title, children } = props

  return (
    <div className={styles.aboutSection} id={id}>
      <div className={styles.title}>
        <TextH2 isParagraph>{title}</TextH2>
      </div>
      <Text isParagraph className={styles.textSecondary}>
        {children}
      </Text>
    </div>
  )
}

const Tabs: React.FC<TickmillTraderProps> = (props) => {
  const { platformInfo } = props

  const isMobile = useWindowResize()
  const { tabs } = useTabsData(platformInfo)

  return (
    <div className={styles.tabsWrapper}>
      <div className={styles.buttonWrapper}>
        <TabsSwitcher
          items={tabs}
          renderItem={(x) => (
            <TabSwitcherLink to={`../${formatOSName(x.name)}`} className={styles.tabLink}>
              <TabIconFactory name={x.id} />
              <span>{x.name}</span>
            </TabSwitcherLink>
          )}
          fullWidth={isMobile}
        />
      </div>
    </div>
  )
}

const TabIconFactory: React.FC<{ name: OperatingSystemEnum }> = ({ name }) => {
  switch (name) {
    case OperatingSystemEnum.ANDROID:
      return (
        <span className='pr-2'>
          <AndroidLogo />
        </span>
      )
    case OperatingSystemEnum.IOS:
      return (
        <span className='pr-2'>
          <IOSLogo />
        </span>
      )

    case OperatingSystemEnum.WEB_TRADER:
      return (
        <span className='pl-2 pr-2'>
          <WebTraderLogo />
        </span>
      )
    default:
      return null
  }
}

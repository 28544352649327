import {
  isCampaignBusinessRuleMaxDrawdownType,
  isCampaignBusinessRuleMinActiveTradingDaysPerCampaignType,
} from './CampaignBusinessRule'
import { NameDto } from './NameDto'

export interface ClientContestCampaignDetailDto {
  id: string
  name: string | null
  type: NameDto
  calculationType: NameDto
  ranking: number
  points: number
  tradingAccount: ClientCampaignDetailTradingAccountDto
  disqualified: boolean
  disqualificationRule: CampaignBusinessRuleDto
}

interface ClientCampaignDetailTradingAccountDto {
  id: string
  name: string | null
  currencyId: string | null
  platformType: NameDto
}

interface CampaignBusinessRuleDto {
  businessRule: BusinessRuleDto
  businessRuleValue: number | null
}

export const isCampaignBusinessRuleMaxDrawdown = (value?: CampaignBusinessRuleDto) => {
  return isCampaignBusinessRuleMaxDrawdownType(value?.businessRule?.id)
}

export const isCampaignBusinessRuleMinActiveTradingDaysPerCampaign = (
  value?: CampaignBusinessRuleDto
) => {
  return isCampaignBusinessRuleMinActiveTradingDaysPerCampaignType(value?.businessRule?.id)
}

interface BusinessRuleDto {
  id: number
  name: string | null
  businessRuleType: NameDto
  hasValue: boolean
}

export interface TradingDaysPerCampaignDto {
  minTradingDays?: number
  currentTradingDays?: number
  remainingTradingDays?: number
}

export interface DrawdownDto {
  maxDrawdownPercentage?: number
  maxDrawdown?: number
  currentDrawdownPercentage?: number
  currentDrawdown?: number
}

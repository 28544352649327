import { useMemo } from 'react'

import { DepositRedirectToProviderPage } from '../PaymentProviderRedirect/DepositRedirectToProviderPage/DepositRedirectToProviderPage'
import { useProductReadContext } from '../global/context/ProductContext'
import { WalletTypeEnum } from '../model/WalletDto'
import { Navigate } from '../navigation/custom-react-router-dom'
import { RouteConfig } from '../routing/RenderRoutes'
import { useAccountReadContext } from '../utils/AccountContextContext'
import { DateFilterContextProvider } from '../utils/DateFilterContext'
import { BalancesPage } from './Balances/BalancesPage'
import { CampaignResultsPage } from './Campaign-Results/CampaignResultsPage'
import { ConversionPage } from './Conversion/ConversionPage'
import { StockDividendsPage } from './Stock-Dividends/StockDividendsPage'
import { TradersRoom } from './TradersRoom'
import { ChangeTradingAccountPasswordPage } from './Trading-Accounts/ChangeTradingAccountPasswordForm'
import { CreateDemoTradingAccountPage } from './Trading-Accounts/CreateDemoTradingAccount/CreateDemoTradingAccountPage'
import { CreateDemoTradingAccountSuccessPage } from './Trading-Accounts/CreateDemoTradingAccount/CreateDemoTradingAccountSuccessPage'
import { CreateTradingAccountCampaignPage } from './Trading-Accounts/CreateTradingAccountCampaignPage'
import { CreateTradingAccountCampaignSuccessPage } from './Trading-Accounts/CreateTradingAccountCampaignSuccessPage'
import { PlatformTypePage } from './Trading-Accounts/PlatformTypePage'
import { DemoTradingAccountDepositPage } from './Trading-Accounts/TradingAccountDeposit/DemoTradingAccountDeposit/DemoTradingAccountDepositPage'
import { TradersRoomTradingAccountDepositPage } from './Trading-Accounts/TradingAccountDeposit/TradingAccountDepositPage/TradersRoomTradingAccountDepositPage'
import { TradersRoomTradingAccountDepositTransactionCompletedPage } from './Trading-Accounts/TradingAccountDeposit/TradingAccountDepositTransactionPage/TradersRoomTradingAccountDepositTransactionCompletedPage'
import { TradersRoomTradingAccountDepositTransactionFailedPage } from './Trading-Accounts/TradingAccountDeposit/TradingAccountDepositTransactionPage/TradersRoomTradingAccountDepositTransactionFailedPage'
import { TradersRoomTradingAccountDepositTransactionSuccessPage } from './Trading-Accounts/TradingAccountDeposit/TradingAccountDepositTransactionPage/TradersRoomTradingAccountDepositTransactionSuccessPage'
import { TradingAccountsPage } from './Trading-Accounts/TradingAccountsPage'
import { TransactionHistoryPage } from './Transaction-History/TransactionHistoryPage'
import { TransactionsPage } from './Transactions/TransactionsPage'
import { TradersRoomWalletDepositPage } from './Wallets/WalletDeposit/WalletDepositPage/TradersRoomWalletDepositPage'
import { TradersRoomWalletStartDepositPage } from './Wallets/WalletDeposit/WalletDepositPage/TradersRoomWalletStartDepositPage'
import { TradersRoomWalletDepositTransactionCompletedPage } from './Wallets/WalletDeposit/WalletDepositTransactionPage/TradersRoomWalletDepositTransactionCompletedPage'
import { TradersRoomWalletDepositTransactionFailedPage } from './Wallets/WalletDeposit/WalletDepositTransactionPage/TradersRoomWalletDepositTransactionFailedPage'
import { TradersRoomWalletDepositTransactionSuccessPage } from './Wallets/WalletDeposit/WalletDepositTransactionPage/TradersRoomWalletDepositTransactionSuccessPage'
import {
  CampaignWalletTransferPage,
  TradersRoomWalletStartTransferPage,
  TradersRoomWalletTransferPage,
} from './Wallets/WalletTransfer/WalletTransferPage'
import { WalletTransferPageETD } from './Wallets/WalletTransfer/WalletTransferPageETD'
import { TradersRoomWalletStartWithdrawPage } from './Wallets/WalletWithdraw/WalletWithdrawPage/TradersRoomWalletStartWithdrawPage'
import { TradersRoomWalletWithdrawPage } from './Wallets/WalletWithdraw/WalletWithdrawPage/TradersRoomWalletWithdrawPage'
import { TradersRoomWalletWithdrawTransactionCompletedPage } from './Wallets/WalletWithdraw/WalletWithdrawTransactionPage/TradersRoomWalletWithdrawTransactionCompletedPage'
import { TradersRoomWalletWithdrawTransactionFailedPage } from './Wallets/WalletWithdraw/WalletWithdrawTransactionPage/TradersRoomWalletWithdrawTransactionFailedPage'
import { TradersRoomWalletWithdrawTransactionSuccessPage } from './Wallets/WalletWithdraw/WalletWithdrawTransactionPage/TradersRoomWalletWithdrawTransactionSuccessPage'
import { WalletsPage } from './Wallets/WalletsPage'

export const useTraderRoomRoutes = (): RouteConfig[] => {
  const { account } = useAccountReadContext()
  const visible = useTraderRoomRoutesConditions()
  const { isDefaultCFDProductType } = useProductReadContext()

  const walletType = isDefaultCFDProductType() ? WalletTypeEnum.CFD : WalletTypeEnum.ETD
  const mainPath = isDefaultCFDProductType() ? 'wallets' : 'balances'

  return useMemo(
    (): RouteConfig[] => [
      {
        path: 'traders-room',
        outlet: <TradersRoom />,
        condition: visible.isTradersRoomVisible,
        element: <Navigate to={mainPath} />,
        children: [
          {
            path: 'wallets',
            element: <WalletsPage walletType={walletType} />,
            children: [
              {
                path: 'deposit',
                element: <TradersRoomWalletStartDepositPage walletType={walletType} />,
              },
              {
                path: 'withdrawal',
                element: <TradersRoomWalletStartWithdrawPage walletType={walletType} />,
                locked: account?.actionRestrictions.tradersRoomRestricted,
              },
              {
                path: 'transfer',
                condition: isDefaultCFDProductType(),
                element: <TradersRoomWalletStartTransferPage walletType={walletType} />,
                locked: account?.actionRestrictions.openAccountRestricted,
              },
              {
                path: 'transfer',
                condition: !isDefaultCFDProductType(),
                element: <WalletTransferPageETD />,
              },
              {
                path: ':walletId',
                children: [
                  {
                    path: 'deposit',
                    element: <TradersRoomWalletDepositPage walletType={walletType} />,
                    children: [
                      {
                        path: 'transaction',
                        children: [
                          {
                            path: 'succeed',
                            element: <TradersRoomWalletDepositTransactionSuccessPage />,
                          },
                          {
                            path: 'completed',
                            element: <TradersRoomWalletDepositTransactionCompletedPage />,
                          },
                          {
                            path: 'failed',
                            element: <TradersRoomWalletDepositTransactionFailedPage />,
                          },
                        ],
                      },
                      {
                        path: 'redirect',
                        element: <DepositRedirectToProviderPage />,
                      },
                    ],
                  },
                  {
                    path: 'withdrawal',
                    element: <TradersRoomWalletWithdrawPage walletType={walletType} />,
                    locked: account?.actionRestrictions.tradersRoomRestricted,
                    children: [
                      {
                        path: 'transaction',
                        children: [
                          {
                            path: 'succeed',
                            element: <TradersRoomWalletWithdrawTransactionSuccessPage />,
                          },
                          {
                            path: 'completed',
                            element: <TradersRoomWalletWithdrawTransactionCompletedPage />,
                          },
                          {
                            path: 'failed',
                            element: <TradersRoomWalletWithdrawTransactionFailedPage />,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: 'transfer',
                    element: <TradersRoomWalletStartTransferPage walletType={walletType} />,
                    condition: isDefaultCFDProductType(),
                    locked: account?.actionRestrictions.openAccountRestricted,
                  },
                  {
                    path: 'transfer',
                    element: <WalletTransferPageETD />,
                    condition: !isDefaultCFDProductType(),
                    locked: account?.actionRestrictions.openAccountRestricted,
                  },
                ],
              },
            ],
          },
          {
            path: 'balances',
            element: <BalancesPage walletType={walletType} />,
            condition: !isDefaultCFDProductType(),
            children: [
              {
                path: ':tradingAccountId',
                children: [
                  {
                    path: 'transfer',
                    element: <WalletTransferPageETD />,
                  },
                ],
              },
            ],
          },
          {
            path: 'trading-accounts',
            element: <TradingAccountsPage />,
            condition: isDefaultCFDProductType(),
            children: [
              {
                path: 'new',
                element: <CreateTradingAccountCampaignPage />,
                locked: account?.actionRestrictions.openAccountRestricted,
                children: [
                  {
                    path: 'platform-types',
                    element: <PlatformTypePage />,
                  },
                  {
                    path: 'success',
                    element: <CreateTradingAccountCampaignSuccessPage />,
                  },
                ],
              },
              {
                path: 'new/demo',
                element: <CreateDemoTradingAccountPage />,
                children: [
                  {
                    path: 'success',
                    children: [
                      {
                        path: ':platformType',
                        element: <CreateDemoTradingAccountSuccessPage />,
                      },
                    ],
                  },
                ],
              },
              {
                path: ':tradingAccountId',
                children: [
                  {
                    path: 'demo',
                    children: [
                      {
                        path: 'add-balance',
                        element: <DemoTradingAccountDepositPage />,
                      },
                    ],
                  },
                  {
                    path: 'transfer',
                    element: <TradersRoomWalletTransferPage walletType={walletType} />,
                  },
                  {
                    path: 'change-password',
                    element: <ChangeTradingAccountPasswordPage />,
                  },
                  {
                    path: 'transfer-from-campaign',
                    element: <CampaignWalletTransferPage />,
                  },
                  {
                    path: 'deposit-to-campaign',
                    element: <TradersRoomTradingAccountDepositPage />,
                  },
                  {
                    path: 'deposit',
                    children: [
                      {
                        path: 'transaction',
                        children: [
                          {
                            path: 'succeed',
                            element: <TradersRoomTradingAccountDepositTransactionSuccessPage />,
                          },
                          {
                            path: 'completed',
                            element: <TradersRoomTradingAccountDepositTransactionCompletedPage />,
                          },
                          {
                            path: 'failed',
                            element: <TradersRoomTradingAccountDepositTransactionFailedPage />,
                          },
                        ],
                      },
                      {
                        path: 'redirect',
                        element: <DepositRedirectToProviderPage />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: 'transactions',
            element: <TransactionsPage />,
            children: [
              {
                path: 'conversion',
                condition: !isDefaultCFDProductType(),
                element: <ConversionPage />,
              },
            ],
          },
          {
            path: 'campaign-results',
            condition: isDefaultCFDProductType(),
            element: (
              <DateFilterContextProvider>
                <CampaignResultsPage />
              </DateFilterContextProvider>
            ),
          },
          {
            path: 'transaction-history',
            element: (
              <DateFilterContextProvider>
                <TransactionHistoryPage walletType={walletType} />
              </DateFilterContextProvider>
            ),
          },
          {
            path: 'stock-dividends',
            condition: isDefaultCFDProductType(),
            element: (
              <DateFilterContextProvider>
                <StockDividendsPage />
              </DateFilterContextProvider>
            ),
          },
        ],
      },
    ],
    [
      account?.actionRestrictions.openAccountRestricted,
      account?.actionRestrictions.tradersRoomRestricted,
      isDefaultCFDProductType,
      mainPath,
      visible.isTradersRoomVisible,
      walletType,
    ]
  )
}

interface RoutesConditions {
  isTradersRoomVisible: boolean
}
export const useTraderRoomRoutesConditions = (): RoutesConditions => {
  const { account } = useAccountReadContext()
  const { isDefaultCFDProductType } = useProductReadContext()

  const isTradersRoomVisible = useMemo(
    () =>
      isDefaultCFDProductType()
        ? !!account?.visibilityConfiguration.hasTraderRoom
        : !!account?.visibilityConfiguration.hasEtdTraderRoom,
    [
      account?.visibilityConfiguration.hasEtdTraderRoom,
      account?.visibilityConfiguration.hasTraderRoom,
      isDefaultCFDProductType,
    ]
  )

  return {
    isTradersRoomVisible,
  }
}

import { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Loading } from '../../../global/Loading/Loading'
import { OldLegalDocumentDto } from '../../../model/LegalDocumentDto'
import { MultiLevelCommissionDetailDto } from '../../../model/MultiLevelCommission'
import { useGAEvents } from '../../../utils/callGAEvents'
import { SectionHeader, SeeMoreLinkProps } from '../components/SectionHeader/SectionHeader'
import { Levels } from './Levels'
import { NewLevelBanner } from './NewLevelBanner'
import { ProgressBars } from './ProgressBars'
import { SpreadShareLevelBanners } from './SpreadShareLevelBanners'

import styles from './MultiLevel.module.scss'

interface MultiLevelProps {
  isLoading: boolean
  legalDocuments: OldLegalDocumentDto[]
  multiLevelData?: MultiLevelCommissionDetailDto
  dismissNotification: () => Promise<void>
}

export const MultiLevel: FC<MultiLevelProps> = (props) => {
  const { isLoading, legalDocuments, multiLevelData, dismissNotification } = props

  const { t } = useTranslation()
  const [isBannerShown, setIsBannerShown] = useState(false)
  const { callGAEvents } = useGAEvents()

  const hasReachedMaxLevel = useMemo<boolean>(() => {
    return (
      !!multiLevelData?.levels.current &&
      !!multiLevelData?.levels.final &&
      multiLevelData?.levels.current?.id === multiLevelData?.levels.final?.id
    )
  }, [multiLevelData])

  const seeMoreLinkProps = useMemo<SeeMoreLinkProps | undefined>(() => {
    const agreementLink = legalDocuments.find(
      (doc) => doc.code === 'sc_introducing_broker_agreement'
    )?.url

    if (!agreementLink) {
      return undefined
    }

    return {
      onClick: () => callGAEvents().IBDashboard.MultiLevelCommission.seeMore(),
      to: agreementLink,
      target: '_blank',
    }
  }, [callGAEvents, legalDocuments])

  const handleHideClick = async () => {
    setIsBannerShown(false)
    await dismissNotification()
  }

  useEffect(() => {
    setIsBannerShown(!!multiLevelData?.showLevelNotification)
  }, [multiLevelData])

  return (
    <div className={styles.multiLevel}>
      <SectionHeader
        title={t('IB.Dashboard.Multi-level commission')}
        seeMoreLinkProps={seeMoreLinkProps}
      />

      <Loading isLoading={isLoading} showLoadingIcon>
        {multiLevelData && (
          <>
            {!hasReachedMaxLevel && <Levels levels={multiLevelData.levels} />}
            {isBannerShown && (
              <NewLevelBanner multiLevelData={multiLevelData} onHideClick={handleHideClick} />
            )}
            {!hasReachedMaxLevel && <SpreadShareLevelBanners multiLevelData={multiLevelData} />}
            <ProgressBars multiLevelData={multiLevelData} />
          </>
        )}
      </Loading>
    </div>
  )
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Loading } from '../../../global/Loading/Loading'
import { useSnackbar } from '../../../global/context/SnackbarContext'
import { ConversionIcon } from '../../../icons/ConversionIcon'
import { InfoIcon } from '../../../icons/InfoIcon'
import { WithdrawIcon } from '../../../icons/WithdrawIcon'
import { WalletDto } from '../../../model/WalletDto'
import { useNavigate } from '../../../navigation/custom-react-router-dom'
import { AlertIconWrapper } from '../../../ui/AlertIconWrapper/AlertIconWrapper'
import { Text } from '../../../ui/Typography/Typography'
import { WalletRestrictions, isRestricted, isWalletSuspended } from '../../../utils/wallet.utils'

import styles from '../BalancesOptions.module.scss'

interface WalletBalancesOptionsProps {
  wallet: WalletDto
  onWithdrawal: (walletId: string) => void
  onSetWalletInformationModalOpen: (value: boolean) => void
  onSetWalletOptionsData?: (wallet: WalletDto) => void
  isLoading: boolean
}

export const WalletBalancesOptions: React.FC<WalletBalancesOptionsProps> = (props) => {
  const {
    wallet,
    onWithdrawal,
    onSetWalletOptionsData,
    onSetWalletInformationModalOpen,
    isLoading,
  } = props
  const { addSnackbar } = useSnackbar()

  const navigate = useNavigate()
  const { t } = useTranslation()
  const hasAllRestrictions = isWalletSuspended(wallet.restrictions)
  const isWithdrawRestricted = isRestricted(WalletRestrictions.WITHDRAW, wallet.restrictions)
  const isConvertRestricted = isRestricted(WalletRestrictions.TRANSFER_FROM, wallet.restrictions)

  const handleConversion = () => {
    if (isConvertRestricted) {
      addSnackbar.error({ message: t('This action is not available') })
    } else {
      navigate(`/dashboard/traders-room/transactions/conversion`, {
        state: {
          walletId: wallet.id,
        },
      })
    }
  }

  return (
    <>
      <ul className={styles.options}>
        <li
          className={classNames(styles.option, { [styles.isDisabled]: isWithdrawRestricted })}
          onClick={() => onWithdrawal(wallet.id)}
        >
          <WithdrawIcon
            color={isWithdrawRestricted ? 'disabled' : 'text'}
            strokeWidth={1.5}
            size={24}
          />
          <AlertIconWrapper spaced isActive={isWithdrawRestricted && !hasAllRestrictions}>
            <Text>{isLoading ? t('Loading...') : t('Wallet.Withdraw')}</Text>
          </AlertIconWrapper>
        </li>
        <li
          className={classNames(styles.option, { [styles.isDisabled]: isConvertRestricted })}
          onClick={handleConversion}
        >
          <ConversionIcon
            color={isConvertRestricted ? 'disabled' : 'text'}
            strokeWidth={1.5}
            size={24}
          />
          <AlertIconWrapper spaced isActive={isConvertRestricted && !hasAllRestrictions}>
            <Text>{t('Wallet.Convert')}</Text>
          </AlertIconWrapper>
        </li>
        <li
          className={styles.option}
          onClick={() => {
            onSetWalletInformationModalOpen(true)
            onSetWalletOptionsData?.(wallet)
          }}
        >
          <InfoIcon color='text' strokeWidth={1.5} size={24} />
          <Text>{t('Wallet.Info')}</Text>
        </li>
      </ul>
    </>
  )
}

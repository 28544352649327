import React, { SyntheticEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, FormikErrors, FormikProps, withFormik } from 'formik'
import { t } from 'i18next'

import { Button } from '../global/button/Button'
import { createFormField } from '../global/formField/FormField'
import { useNavigate } from '../navigation/custom-react-router-dom'
import { TickmillCompaniesEnum, getTickmillPublicDomain } from '../utils/companyName.utils'
import { FormSubmitValues } from '../utils/formValidation'

import styles from './ForgotPasswordPage.module.scss'

export interface ForgotPasswordFormValues {
  email: string
  communicationLanguageId: string
  tickmillPublicDomain: string
}

const EmailField = createFormField<ForgotPasswordFormValues>()

const ForgotPasswordFormUI: React.FC<FormikProps<ForgotPasswordFormValues> & OuterProps> = (
  props
) => {
  const { handleSubmit, setSubmitting, isSubmitting, isValid } = props
  const navigate = useNavigate()
  const { t } = useTranslation()
  const handleSubmitForm = (event: SyntheticEvent) => {
    event.preventDefault()
    setSubmitting(true)
    handleSubmit()
  }

  return (
    <Form className={styles.form} onSubmit={handleSubmitForm}>
      <EmailField
        type='email'
        label={t('Email Address')}
        placeholder={t('Email Address')}
        name='email'
        wrapperClassname={styles.field}
      />
      <div className={styles.buttonBox}>
        <Button
          disabled={!isValid}
          type='submit'
          loading={isSubmitting}
          appearance='primary'
          size='M'
        >
          {t('Reset')}
        </Button>
        <Button type='button' onClick={() => navigate('/login')} appearance='secondary' size='M'>
          {t('Cancel')}
        </Button>
      </div>
    </Form>
  )
}

interface OuterProps {
  entity: TickmillCompaniesEnum
  email?: string
  onSubmit(values: FormSubmitValues<ForgotPasswordFormValues>): Promise<void>
}

export const ForgotPasswordForm = withFormik<OuterProps, ForgotPasswordFormValues>({
  mapPropsToValues: (props) => {
    return {
      email: props?.email || '',
      communicationLanguageId: 'en',
      tickmillPublicDomain: getTickmillPublicDomain(),
    }
  },

  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      await props.onSubmit(values)
    } finally {
      setSubmitting(false)
    }
  },
  validate: (values) => {
    const errors: FormikErrors<ForgotPasswordFormValues> = {}

    if (!values.email) {
      errors.email = t('Validation.Required')
    }
    return errors
  },
  enableReinitialize: true,
})(ForgotPasswordFormUI)

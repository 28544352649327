import React, { useState } from 'react'

import {
  FilterOptions,
  FilterQueryModal,
  FilterQueryProps,
} from '../../global/filter/FilterQueryModal'
import { FilterOptionsDto } from '../../model/FilterOptionsDto'
import { IDateFilter } from '../../utils/filter.utils'
import { IBIncomeQuery } from './IBIncomePage'

interface Props {
  filterOptions?: FilterOptionsDto
  currentQuery?: IBIncomeQuery
  onConfirm({
    searchFilters,
    currentFilter,
  }: {
    searchFilters?: FilterQueryProps
    currentFilter?: IDateFilter
  }): void
}

export const IBIncomeFilterModal: React.FC<Props> = ({
  onConfirm,
  currentQuery,
  filterOptions,
}) => {
  const [searchFilters, setSearchFilters] = useState(currentQuery)

  return (
    <FilterQueryModal
      searchFilters={searchFilters}
      setSearchFilters={setSearchFilters}
      currencyOptions={filterOptions?.walletCurrencies}
      tradingAccountTypes={filterOptions?.accountTypes}
      entities={filterOptions?.entities}
      currentFilterOptions={[
        FilterOptions.Volume,
        FilterOptions.Amount,
        FilterOptions.Currency,
        FilterOptions.AccountType,
        FilterOptions.Date,
        FilterOptions.Entity,
      ]}
      onConfirm={onConfirm}
    />
  )
}

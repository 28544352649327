import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import classNames from 'classnames'

import { StepCircle } from '../../../global/StepCircle/StepCircle'
import { Button } from '../../../global/button/Button'
import { FormTemplate } from '../../../global/templates/FormTemplate'
import { BackIcon } from '../../../icons/BackIcon'
import { HexagonCheckIcon } from '../../../icons/HexagonCheckIcon'
import { StepArrowIcon } from '../../../icons/StepArrowIcon'
import { Link, useNavigate } from '../../../navigation/custom-react-router-dom'
import {
  Text,
  TextH2,
  TextLargeStrong,
  TextSmall,
  TextSmallStrong,
  TextStrong,
  TextTiny,
} from '../../../ui/Typography/Typography'
import { useWindowResize } from '../../../utils/domUtils'

import styles from './CreateDemoTradingAccountSuccessPage.module.scss'

export const CreateDemoTradingAccountSuccessPage: React.FC = () => {
  const { platformType } = useParams()
  const navigate = useNavigate()

  if (platformType === 'mt4' || platformType === 'mt5') {
    return <MetaTraderSuccessPage />
  } else if (platformType === 'tickmilltrader') {
    return <TickmillTraderSuccessPage />
  } else if (platformType === 'tradingview') {
    return <TradingViewSuccessPage />
  } else {
    navigate('/dashboard')
    return null
  }
}

const MetaTraderSuccessPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <FormTemplate
      title={t('Trading Account.Demo Trading Account Created!')}
      subtitle={t(
        'Trading Account.You will receive a confirmation email with your demo trading account credentials'
      )}
      bottomText={t('Trading Account.Please note that your demo trading account credentials')}
      wrapped
      goBackIcon={<BackIcon />}
      goBack={() => navigate('/dashboard/traders-room/trading-accounts')}
      titleClassName={styles.title}
    >
      <MetaTraderSuccessBody />
    </FormTemplate>
  )
}

const MetaTraderSuccessBody = () => {
  const { t } = useTranslation()
  const { platformType } = useParams()
  const location = useLocation()
  const urlParams = new URLSearchParams(location.search)
  const serverName = urlParams.get('serverName')
  const serverDns = urlParams.get('serverDns')

  const getPlatformLink = () => {
    if (platformType === 'mt4') {
      return '/dashboard/tools/platforms/mt4'
    }
    if (platformType === 'mt5') {
      return '/dashboard/tools/platforms/mt5'
    }
    if (platformType === 'tickmilltrader') {
      return '/dashboard/tools/platforms/tickmilltrader'
    }
    return '/dashboard'
  }

  return (
    <div className={styles.contentWrapper}>
      <Text className='text-align-center'>
        {t('Trading Account.Please, follow the instructions below')}
      </Text>
      <div className={styles.contentText}>
        <div>
          <StepCircle isActive={false} section={1} isRed={true} isSmall={true} />
        </div>
        <TextSmall>
          <Link className={styles.link} to={getPlatformLink()}>
            {t('Trading Account.Download')}
          </Link>{' '}
          {t('and')} <TextSmallStrong>{t('Trading Account.install')}</TextSmallStrong>{' '}
          {t('Trading Account.the MetaTrader trading platform', {
            platform: platformType === 'mt4' ? '4' : '5',
          })}
        </TextSmall>
      </div>
      <div className={styles.contentText}>
        <div>
          <StepCircle isActive={false} section={2} isRed={true} isSmall={true} />
        </div>
        <TextSmall>
          <TextSmallStrong>{t('Trading Account.Login')}</TextSmallStrong>{' '}
          {t(
            'Trading Account.into your trading platform with your demo trading account credentials'
          )}
        </TextSmall>
      </div>
      <div className={styles.contentText}>
        <div>
          <StepCircle isActive={false} section={3} isRed={true} isSmall={true} />
        </div>
        <TextSmall>
          {t('Trading Account.During login, please choose')}{' '}
          <TextSmallStrong>{serverName ? serverName : 'Tickmill-Demo'}</TextSmallStrong>{' '}
          {t('Trading Account.from the servers list or type')}{' '}
          <TextSmallStrong>
            {serverDns ? serverDns : `demo01.mt${platformType}tickmill.com`}
          </TextSmallStrong>
        </TextSmall>
      </div>
      <div className={styles.contentText}>
        <StepCircle isActive={false} section={4} isRed={true} isSmall={true} />
        <TextSmall>
          <TextSmallStrong>{t('Trading Account.Practice trading')}</TextSmallStrong>{' '}
          {t('Trading Account.without any risks')}
        </TextSmall>
      </div>
    </div>
  )
}

const TickmillTraderSuccessPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <FormTemplate
      title={t('Trading Account.Tickmill Trader Account Created')}
      subtitle={t(
        'Trading Account.You will receive a confirmation email with your username and password tickmillTrader'
      )}
      goBackIcon={<BackIcon />}
      goBack={() => navigate('/dashboard/traders-room/trading-accounts')}
      wrapped
      titleClassName={styles.title}
    >
      <TickmillTraderSuccessBody />
    </FormTemplate>
  )
}

const TickmillTraderSuccessBody = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.contentWrapper}>
      <Text className='text-align-center'>
        {t('Trading Account.Please, follow the instructions below')}
      </Text>
      <div className={styles.contentText}>
        <div>
          <StepCircle isActive={false} section={1} isRed={true} isSmall={true} />
        </div>
        <TextSmall>
          <Link className={styles.link} to={'/dashboard/tools/platforms/tickmilltrader'}>
            {t('Trading Account.Download')}
          </Link>{' '}
          {t('and')} <TextSmallStrong>{t('Trading Account.install')}</TextSmallStrong>{' '}
          {t('Trading Account.the Tickmill Trader Mobile App')}
        </TextSmall>
      </div>
      <div className={styles.contentText}>
        <div>
          <StepCircle isActive={false} section={2} isRed={true} isSmall={true} />
        </div>
        <TextSmall>
          {t('Trading Account.Access the app with your login username and password')}
        </TextSmall>
      </div>
      <div className={styles.contentText}>
        <div>
          <StepCircle isActive={false} section={3} isRed={true} isSmall={true} />
        </div>
        <TextSmall>
          {t(
            'Trading Account.Click on “menu” on the bottom of the screen to switch between Live and Demo Accounts'
          )}
        </TextSmall>
      </div>
      <div className={styles.contentText}>
        <div>
          <StepCircle isActive={false} section={4} isRed={true} isSmall={true} />
        </div>
        <TextSmall>
          {t(
            'Trading Account.Trade without any risks on your Demo, or fund your account and start trading'
          )}
        </TextSmall>
      </div>
    </div>
  )
}

interface Step {
  name: string
  description: string

  isInteractive?: boolean
  isDisabled?: boolean
}

const TradingViewSuccessPage = () => {
  const { t } = useTranslation()

  const steps: Step[] = [
    {
      name: t('Trading Account.Step 1'),
      description: t('Trading Account.Check your email'),
      isDisabled: true,
    },
    {
      name: t('Trading Account.Step 2'),
      description: t('Trading Account.Get TradingView'),
      isInteractive: true,
      isDisabled: true,
    },
    {
      name: t('Trading Account.Step 3'),
      description: t('Trading Account.Log in'),
      isDisabled: true,
    },
    {
      name: t('Trading Account.Step 4'),
      description: t('Trading Account.Start trading'),
      isDisabled: true,
    },
  ]

  const tradingViewToolPath = '/dashboard/tools/platforms/tradingview'

  const isMobile = useWindowResize()

  return (
    <div className={styles.tradingView}>
      <HexagonCheckIcon color='alwaysWhite' />
      <TextH2 isParagraph>{t('Trading Account.Trading account created!')}</TextH2>

      <div className={styles.container}>
        <div className={styles.textCentered}>
          <TextStrong>
            {t('Trading Account.Your login credentials have been sent to your email.')}
          </TextStrong>{' '}
          <Text className={styles.textSecondary}>
            {t(
              'Trading Account.We created demo and live trading accounts, and you can use your credentials to access both.'
            )}
          </Text>
        </div>

        <span className={styles.textCentered}>
          <Text className={styles.textSecondary}>
            {t('Trading Account.Use these credentials to log in to')}
          </Text>
          <TextStrong>{t('TradingView')}</TextStrong>
          <Text className={styles.textSecondary}> {t('Trading Account.or')} </Text>
          <TextStrong>{t('Trading Account.Tickmill Trader platform')}</TextStrong>
          <Text className={styles.textSecondary}>, </Text>
          <Text className={styles.textSecondary}>
            {t('Trading Account.giving you the flexibility to choose the platform')}
          </Text>
        </span>

        <span className={styles.textCentered}>
          <Text className={styles.textSecondary}>
            {t(
              'Trading Account.Need help connecting to TradingView? Check out our step-by-step guide'
            )}
          </Text>{' '}
          <Link to={tradingViewToolPath}>
            <TextStrong className={styles.link}>{`${t('Trading Account.here')}.`}</TextStrong>
          </Link>
        </span>
        <div className={styles.nextSection}>
          <TextLargeStrong isParagraph>{t('Trading Account.What’s next?')}</TextLargeStrong>
          <div className={styles.steps}>
            {steps.map((step, i) => {
              const isArrowVisible = i !== steps.length - 1 && !isMobile

              return (
                <>
                  <div className={styles.step}>
                    <TextTiny
                      isParagraph
                      className={classNames(styles.textSecondary, {
                        [styles.isDisabled]: step.isDisabled,
                      })}
                    >
                      {step.name}:
                    </TextTiny>
                    {step?.isInteractive ? (
                      <Link to={tradingViewToolPath}>
                        <TextStrong className={styles.textInteractive} isParagraph>
                          {step.description}
                        </TextStrong>
                      </Link>
                    ) : (
                      <TextStrong isParagraph>{step.description}</TextStrong>
                    )}
                  </div>
                  {isArrowVisible && <StepArrowIcon size={25} color='iconDisabled' />}
                </>
              )
            })}
          </div>
        </div>
      </div>
      <Link to='/dashboard/traders-room/wallets' className={styles.buttonContainer}>
        <Button appearance='primary' size='L' className={styles.button}>
          {t('Trading Account.Return to Wallets')}
        </Button>
      </Link>
    </div>
  )
}

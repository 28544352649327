import { useCallback } from 'react'

import { AccountGroupType } from '../model/AccountGroupType'
import { TradingAccountStatusType } from '../model/TradingAccount'
import { Operator, useApiClient } from '../utils/ApiClient'
import { ClientApiClient } from '../utils/clientApi'
import { useFetchOne } from '../utils/useFetch'

export const useTradingAccountStatus = (): {
  hasNegativeBalance: boolean
  hasActiveTradingAccounts: boolean
  isLoading: boolean
} => {
  const apiClient = useApiClient(ClientApiClient)

  const fetchLiveTradingAccount = useCallback(async () => {
    const result = await apiClient.getTradingAccounts({
      search: {
        AccountGroupType: {
          value: AccountGroupType.Live,
          operator: Operator.EQUAL,
        },
      },
    })

    return {
      hasNegativeBalance: result.items.some((account) => account.platformOverview.equity < 0),
      hasActiveTradingAccounts: result.items.some(
        (item) => item.status.id === TradingAccountStatusType.Approved
      ),
    }
  }, [apiClient])

  const { data: tradingAccountStatus, isLoading } = useFetchOne(fetchLiveTradingAccount)

  return {
    hasNegativeBalance: tradingAccountStatus?.hasNegativeBalance ?? false,
    hasActiveTradingAccounts: tradingAccountStatus?.hasActiveTradingAccounts ?? false,
    isLoading,
  }
}

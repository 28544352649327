import { IconProps } from './IconProps'

export const Step1Icon = (props: IconProps): JSX.Element => {
  const width = props.width || '40'
  const height = props.height || '40'
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='40' height='40' rx='6' fill='#CC0000' />
      <path
        d='M21.44 28H19.34V18.52C19.34 18.1867 19.34 17.88 19.34 17.6C19.3533 17.3067 19.3667 17.0267 19.38 16.76C19.3933 16.48 19.4067 16.2133 19.42 15.96C19.2467 16.1333 19.0667 16.3 18.88 16.46C18.6933 16.62 18.48 16.8067 18.24 17.02L16.68 18.28L15.6 16.92L19.68 13.72H21.44V28Z'
        fill='white'
      />
    </svg>
  )
}

import { UAParser } from 'ua-parser-js'

import { NameDto } from './NameDto'
import { PlatformInfoDto } from './PlatformInfoDto'

export enum PlatformTypeEnum {
  MT4 = 1,
  MT5 = 2,
  CQGCAST = 3,
  TickmillTrader = 4,
  TradingView = 5,
  None = 0,
}

export enum NotificationPlatformEnum {
  ANDROID = 1,
  IOS = 2,
  WEB = 3,
}

export enum PlatformTypeShort {
  MT4 = 'mt4',
  MT5 = 'mt5',
  CQGCAST = 'cqgcast',
  TickmillTrader = 'tickmilltrader',
  TradingView = 'tradingview',
  None = 'none',
}

export const platformShortNames: PlatformTypeShort[] = [
  PlatformTypeShort.MT4,
  PlatformTypeShort.MT5,
  PlatformTypeShort.CQGCAST,
  PlatformTypeShort.TickmillTrader,
  PlatformTypeShort.TradingView,
]

type PlatformType = NameDto & { shortName: string }

export const getPlatformType = (platformType?: PlatformTypeEnum): PlatformType => {
  if (platformType === PlatformTypeEnum.MT4) {
    return { id: PlatformTypeEnum.MT4, name: 'MT4', shortName: PlatformTypeShort.MT4 }
  }

  if (platformType === PlatformTypeEnum.MT5) {
    return { id: PlatformTypeEnum.MT5, name: 'MT5', shortName: PlatformTypeShort.MT5 }
  }

  if (platformType === PlatformTypeEnum.TickmillTrader) {
    return {
      id: PlatformTypeEnum.TickmillTrader,
      name: 'Tickmill Trader',
      shortName: PlatformTypeShort.TickmillTrader,
    }
  }

  if (platformType === PlatformTypeEnum.TradingView) {
    return {
      id: PlatformTypeEnum.TradingView,
      name: 'TradingView',
      shortName: PlatformTypeShort.TradingView,
    }
  }

  if (platformType === PlatformTypeEnum.CQGCAST) {
    return { id: PlatformTypeEnum.CQGCAST, name: 'CQGCAST', shortName: PlatformTypeShort.CQGCAST }
  }

  return { id: PlatformTypeEnum.None, name: 'None', shortName: PlatformTypeShort.None }
}

export const sortPlatforms = (
  platforms: PlatformInfoDto[] = [],
  platformTypes: PlatformTypeEnum[] = []
): PlatformInfoDto[] => {
  return platformTypes
    .map((typeId) => platforms.find((platform) => platform.platfromType.id === typeId))
    .filter((x): x is PlatformInfoDto => !!x?.platfromType?.id)
    .filter(Boolean)
}

export const getLandingPlatform = (
  platforms: PlatformInfoDto[] = []
): PlatformInfoDto | undefined => {
  const tradingView = platforms.find((x) => x.platfromType.id === PlatformTypeEnum.TradingView)
  const MT5 = platforms.find((x) => x.platfromType.id === PlatformTypeEnum.MT5)
  const [firstPlatform] = platforms

  return tradingView || MT5 || firstPlatform
}

export const formatPlatformName = (name = ''): string => {
  return name.replace(/\s+/g, '').toLowerCase().trim()
}

export const getPlatformTypes = (...platformTypes: PlatformTypeEnum[]): PlatformType[] => {
  const data = [
    {
      id: PlatformTypeEnum.TickmillTrader,
      name: 'TickmillTrader',
      shortName: PlatformTypeShort.TickmillTrader,
    },
    { id: PlatformTypeEnum.MT4, name: 'MT4', shortName: PlatformTypeShort.MT4 },
    { id: PlatformTypeEnum.MT5, name: 'MT5', shortName: PlatformTypeShort.MT5 },
    { id: PlatformTypeEnum.CQGCAST, name: 'CQGCAST', shortName: PlatformTypeShort.CQGCAST },
    {
      id: PlatformTypeEnum.TradingView,
      name: 'TradingView',
      shortName: PlatformTypeShort.TradingView,
    },
  ]
  return platformTypes.length > 0 ? data.filter((x) => platformTypes?.includes(x.id)) : data
}

export const isMT4PlatformType = (platformType?: PlatformTypeEnum): boolean => {
  return platformType === PlatformTypeEnum.MT4
}

export const isMT5PlatformType = (platformType?: PlatformTypeEnum): boolean => {
  return platformType === PlatformTypeEnum.MT5
}

export const isTMTPlatformType = (platformType?: PlatformTypeEnum): boolean => {
  return platformType === PlatformTypeEnum.TickmillTrader
}

export const isTradingViewPlatformType = (platformType?: PlatformTypeEnum): boolean => {
  return platformType === PlatformTypeEnum.TradingView
}

export const isPlatformTypeCQGCAST = (platformType: PlatformTypeEnum): boolean => {
  return platformType === PlatformTypeEnum.CQGCAST
}

export const getVersionByPlatformType = (platformType: PlatformTypeEnum | undefined): string => {
  switch (platformType) {
    case PlatformTypeEnum.MT4:
      return '4'
    case PlatformTypeEnum.MT5:
      return '5'
    default:
      return ''
  }
}

export const getPlatformTypeByVersion = (
  version: string | undefined
): PlatformTypeEnum | undefined => {
  switch (version) {
    case '4':
      return PlatformTypeEnum.MT4
    case '5':
      return PlatformTypeEnum.MT5
  }
}

export enum OperatingSystemEnum {
  WINDOWS = 1,
  OSX = 2,

  WEB_TRADER = 3,

  ANDROID = 4,
  IOS = 5,
  ANDROID_APK = 6,

  DESKTOP = 7,

  None = 0,
}

export const getOperatingSystemTypes = (): PlatformType[] => {
  const data = [
    { id: OperatingSystemEnum.WINDOWS, name: 'WINDOWS', shortName: 'windows' },
    { id: OperatingSystemEnum.OSX, name: 'OSX', shortName: 'osx' },
    { id: OperatingSystemEnum.WEB_TRADER, name: 'WEB_TRADER', shortName: 'webtrader' },
    { id: OperatingSystemEnum.ANDROID, name: 'ANDROID', shortName: 'android' },
    { id: OperatingSystemEnum.ANDROID_APK, name: 'ANDROID', shortName: 'android' },
    { id: OperatingSystemEnum.IOS, name: 'IOS', shortName: 'ios' },
  ]
  return data
}

export const isWebTraderOperatingSystem = (osId?: OperatingSystemEnum | string): boolean => {
  return (
    osId === OperatingSystemEnum.WEB_TRADER ||
    (osId && getOperatingSystemIdByName(osId?.toString()) === OperatingSystemEnum.WEB_TRADER) ||
    false
  )
}

export const isAndroidOperatingSystem = (osId?: OperatingSystemEnum | string): boolean => {
  return (
    osId === OperatingSystemEnum.ANDROID ||
    (osId && getOperatingSystemIdByName(osId?.toString()) === OperatingSystemEnum.ANDROID) ||
    false
  )
}

export const isIOSOperatingSystem = (osId?: OperatingSystemEnum | string): boolean => {
  return (
    osId === OperatingSystemEnum.IOS ||
    (osId && getOperatingSystemIdByName(osId?.toString()) === OperatingSystemEnum.IOS) ||
    false
  )
}

export const getOperatingSystemName = (): string => {
  const os = detectOperatingSystem()

  if (isAndroidOperatingSystem(os)) {
    return 'android'
  }

  if (isIOSOperatingSystem(os)) {
    return 'ios'
  }

  return 'webtrader'
}

export const getOperatingSystemIdByName = (osName: string): OperatingSystemEnum => {
  if (!isNaN(Number.parseInt(osName))) {
    return OperatingSystemEnum.None
  }

  if (osName === 'android') {
    return OperatingSystemEnum.ANDROID
  }

  if (osName === 'ios') {
    return OperatingSystemEnum.IOS
  }

  if (osName === 'osx') {
    return OperatingSystemEnum.OSX
  }

  return OperatingSystemEnum.WEB_TRADER
}

const detectOperatingSystem = () => {
  const parser = new UAParser()
  const { os } = parser.getResult()
  const osName = os.name?.toLowerCase() || ''

  if (osName === 'android') {
    return OperatingSystemEnum.ANDROID
  }

  if (osName === 'ios') {
    return OperatingSystemEnum.IOS
  }

  return OperatingSystemEnum.WEB_TRADER
}

export const detectAndroidOperatingSystem = (): boolean => {
  const parser = new UAParser()
  const { os } = parser.getResult()
  const osName = os.name?.toLowerCase() || ''

  return osName.includes('android')
}

export const detectIOSOperatingSystem = (): boolean => {
  const parser = new UAParser()
  const { os } = parser.getResult()
  const osName = os.name?.toLowerCase() || ''

  return osName.includes('ios')
}

export const detectMacOSOperatingSystem = (): boolean => {
  const parser = new UAParser()
  const { os } = parser.getResult()
  const osName = os.name?.toLowerCase() || ''

  return osName.includes('mac os')
}

export const detectWindowsOperatingSystem = (): boolean => {
  const parser = new UAParser()
  const { os } = parser.getResult()
  const osName = os.name?.toLowerCase() || ''

  return osName.includes('windows')
}

export const detectDesktopOperatingSystem = (): boolean => {
  const parser = new UAParser()
  const { os, device } = parser.getResult()
  const osName = os.name?.toLowerCase() || ''
  return !device.type && !osName.includes('windows') && !osName.includes('mac os')
}

export const formatOSName = (name = ''): string => {
  return name.replace(/\s+/g, '').toLowerCase().trim()
}

export enum OperatingSystemNameEnum {
  iOS = 'iOS',
  Android = 'Android',
  Windows_Phone = 'Windows Phone',
}

import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { Form, FormikErrors, isString, useFormikContext, withFormik } from 'formik'
import { t } from 'i18next'

import { Loading } from '../../../../global/Loading/Loading'
import { useProductReadContext } from '../../../../global/context/ProductContext'
import { useSessionLanguage } from '../../../../global/context/SessionSettingsContext'
import { FileData } from '../../../../global/fileUpload/FileUpload'
import { initialPlaceholderFile } from '../../../../global/fileUpload/FileUploads'
import { createFormField } from '../../../../global/formField/FormField'
import { CancelActionModal } from '../../../../global/modal/CancleActionModal'
import { Modal } from '../../../../global/modal/Modal'
import { FormTemplate } from '../../../../global/templates/FormTemplate'
import { FormatMoney, useFormatNumber } from '../../../../hooks/useFormatNumber'
import { DropArrowDownIcon } from '../../../../icons/DropArrowDownIcon'
import { BankAccount, isBankAccountAllowed } from '../../../../model/BankAccount'
import { NameDto } from '../../../../model/NameDto'
import { PaymentProvider } from '../../../../model/PaymentProviderDto'
import {
  PaymentProviderType,
  isPaymentProviderBankType,
  isPaymentProviderCardProviderType,
  isPaymentProviderNoneType,
  isPaymentProviderPSPType,
  isPaymentProviderPaymentAgentType,
} from '../../../../model/PaymentProviderType'
import { TickmillProductType } from '../../../../model/TickmillProductType'
import { TransactionDto } from '../../../../model/TransactionDto'
import { CurrencyType, WalletDto, WalletTypeEnum } from '../../../../model/WalletDto'
import { WalletPaymentProvider } from '../../../../model/WalletPaymentProvider'
import { WalletPaymentProviderMethodCurrency } from '../../../../model/WalletPaymentProviderParameters'
import { useNavigate } from '../../../../navigation/custom-react-router-dom'
import { useApiClient } from '../../../../utils/ApiClient'
import { ClientApiClient } from '../../../../utils/clientApi'
import { useIsLangSelected } from '../../../../utils/language.utils'
import { isZero, trimToMaxLength } from '../../../../utils/validations'
import { usePaymentProviderFactory } from '../../WalletDeposit/usePaymentProviderFactory'
import { WalletWithdrawModal } from '../WalletWithdrawModal'
import {
  PaymentProviderOptionProps,
  WalletWithdrawPaymentProviderModal,
} from '../WalletWithdrawPaymentProviderModal'
import { WalletWithdrawTermsConditions } from '../WalletWithdrawTermsConditions'
import { WalletWithdrawBankFields, initialBankAccountState } from './WalletWithdrawBankFields'
import { WalletWithdrawPSPFields } from './WalletWithdrawPSPFields'
import { WalletWithdrawPaymentAgentFields } from './WalletWithdrawPaymentAgentFields'
import { WalletWithdrawProviderNoneFields } from './WalletWithdrawProviderNoneFields'

export interface WalletWithdrawFormValues {
  wallet: Wallet
  paymentProvider: PaymentProvider
  bankAccount: Partial<BankAccount>
  bankAccounts: BankAccount[]
  isBankAccountsList: boolean
  bankDepositProof: Omit<FileData, 'data'>
  walletType: WalletTypeEnum
  amount: number | undefined
  comment: string
  terms: {
    withdrawing: boolean
    paymentOperations: boolean
    powerOfAttorney?: boolean
  }
  fields:
    | { [key: string]: string | boolean }
    | {
        accountName: string
        externalAccount: string
        bankCode: string
        bankCodeSort: string
        saveClientAccount: boolean
      }
}

export interface Wallet {
  id?: string
  name?: string
  currency?: NameDto<CurrencyType>
  walletType: NameDto
  balance: number
  bonusReserved: number
}

export const getCurrencyLimits = (
  wallet: Wallet,
  paymentProvider: PaymentProvider
): WalletPaymentProviderMethodCurrency | undefined => {
  return paymentProvider?.parameters?.currenciesLimits
    .filter((x) => x.walletType.id === wallet?.walletType?.id)
    .find((x) => x.id === paymentProvider.currency.id)
}

const FormField = createFormField<WalletWithdrawFormValues>()

export const WalletWithdrawFormUI: React.FC<OuterProps> = (props) => {
  const { onCancel, title, wallet } = props
  const [isOpenTermsCondition, setIsOpenTermsCondition] = useState(false)

  const navigate = useNavigate()
  const { t } = useTranslation()
  const { isDefaultCFDProductType } = useProductReadContext()
  const { values, resetForm, setValues } = useFormikContext<WalletWithdrawFormValues>()
  const { wallet: walletFromValues, paymentProvider } = values
  const [walletPaymentProvider, setWalletPaymentProvider] = useState<
    WalletPaymentProvider | undefined
  >()

  const { createPaymentProvider } = usePaymentProviderFactory()

  const selectedWallet = walletFromValues || wallet

  const [isPageExitConfirmation, setPageExitConfirmation] = useState(false)

  const isProductTypeCFD = isDefaultCFDProductType()

  const handlePageExitConfirmation = () => {
    if (isProductTypeCFD) {
      onCancel()
    } else {
      navigate('/dashboard/traders-room/balances')
    }
  }

  const handleExitPageConfirmationModalOpen = () => {
    setPageExitConfirmation(true)
  }

  const handleExitPageConfirmationModalClose = () => {
    setPageExitConfirmation(false)
  }

  const getTitle = () => (title ? `${title} ${t('Wallet.Withdrawal')}` : t('Wallet.Withdrawal'))

  useEffect(() => {
    ;(async () => {
      if (walletPaymentProvider && wallet) {
        const paymentProvider = await createPaymentProvider(walletPaymentProvider, wallet)
        setValues({ ...values, paymentProvider })
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useIsLangSelected(() => {
    resetForm()
    setValues({ ...initialState({ wallet, walletType: values.walletType }) })
  })

  const onClickTermsConditions = () => setIsOpenTermsCondition(true)

  const handleSetWalletPaymentProvider = (
    walletPaymentProvider: WalletPaymentProvider | undefined
  ) => {
    setWalletPaymentProvider(walletPaymentProvider)
  }

  if (isOpenTermsCondition && selectedWallet && paymentProvider) {
    return (
      <WalletWithdrawTermsConditions
        wallet={selectedWallet}
        paymentProvider={paymentProvider}
        onCancel={() => setIsOpenTermsCondition(false)}
      />
    )
  }

  return (
    <FormTemplate title={getTitle()} goBack={handleExitPageConfirmationModalOpen}>
      {isPageExitConfirmation && (
        <Modal
          closeModal={handleExitPageConfirmationModalClose}
          render={() => (
            <CancelActionModal
              onConfirm={handlePageExitConfirmation}
              onCancel={handleExitPageConfirmationModalClose}
            />
          )}
        />
      )}
      <Form noValidate>
        <FormFieldsFactory
          onCancel={handleExitPageConfirmationModalOpen}
          onClickTermsConditions={onClickTermsConditions}
          onSetWalletPaymentProvider={handleSetWalletPaymentProvider}
        />
      </Form>
    </FormTemplate>
  )
}

interface FormFieldsFactoryProps {
  onCancel(): void
  transactions?: TransactionDto[]
  amountHint?: string
  onClickTermsConditions(): void
  onSetWalletPaymentProvider(walletPaymentProvider: WalletPaymentProvider | undefined): void
}

const FormFieldsFactory: React.FC<FormFieldsFactoryProps> = (props) => {
  const { onCancel, transactions, onClickTermsConditions, onSetWalletPaymentProvider, amountHint } =
    props
  const apiClient = useApiClient(ClientApiClient)

  const { t } = useTranslation()
  const location = useLocation()
  const { formatMoney } = useFormatNumber()

  const { values, setValues, setFieldValue, resetForm } =
    useFormikContext<WalletWithdrawFormValues>()
  const { paymentProvider } = values
  const [loading, setLoading] = useState(true)
  const locale = useSessionLanguage()

  const { product } = useProductReadContext()

  const showAmountPresets = useMemo(() => {
    if (product === TickmillProductType.CFD) {
      return true
    }
    if (!transactions?.length) {
      return false
    }
    return true
  }, [transactions, product])

  const [isWalletModalOpen, setWalletModalOpen] = useState(false)
  const [isPaymentProviderModalOpen, setPaymentProviderModalOpen] = useState(false)

  const providerCategoryId = values.paymentProvider.providerCategory.id

  const initiateBankFields = async () => {
    try {
      const bankAccounts = await apiClient.getBankAccounts()

      if (location.state.localeChanged) {
        const methods = await apiClient.getWalletWithdrawalMethods(
          locale,
          values.wallet.id || location.state.values.wallet.id || ''
        )
        const matchedProvider = methods.find(
          (x) => x.method.id === location.state.values.paymentProvider.id
        )
        if (!matchedProvider) {
          return
        }
      }
      setValues({ ...location.state.values, bankAccounts })

      const passedAccount = bankAccounts.find(
        (account) => account.id === location.state?.bankAccountId
      )
      if (passedAccount) {
        const { bankName, bankCode, bankCodeSort, bankAddress, accountName } = passedAccount
        const { id: clientBankAccountDetailsId, bankAccount: externalAccount } = passedAccount
        setFieldValue('bankAccount', passedAccount)
        setFieldValue('fields', {
          ...values.fields,
          clientBankAccountDetailsId,
          bankName,
          bankCode,
          bankCodeSort,
          bankAddress,
          accountName,
          externalAccount,
        })
      }

      if (location.state?.resetFields) {
        setFieldValue('bankAccount', undefined)
        setFieldValue('fields', {
          ...location.state?.values,
          ...initialBankAccountState,
        })
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const locationStateId = location.state?.values?.paymentProvider?.providerCategory?.id
    if (isPaymentProviderBankType(locationStateId || providerCategoryId)) {
      setLoading(true)
      initiateBankFields()
    } else {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleExitPageConfirmationModalClose = () => {
    onCancel()
  }

  const handleWallet = (wallet: WalletDto) => {
    resetForm()
    setValues({
      ...initialState({ wallet, walletType: values.walletType }),
    })
    setWalletModalOpen(false)
    onSetWalletPaymentProvider(undefined)
  }

  const handleWalletOpen = () => {
    setWalletModalOpen(true)
  }

  const handleWalletClose = () => {
    setWalletModalOpen(false)
  }

  const handlePaymentProvider = (option: PaymentProviderOptionProps) => {
    const isBankAccountsList = option.bankAccounts.filter(isBankAccountAllowed).length >= 1 || false
    resetForm()

    setValues({
      ...initialState({
        wallet: values.wallet,
        walletType: values.walletType,
      }),
      paymentProvider: option.paymentProviderOptions,
      bankAccounts: option.bankAccounts,
      isBankAccountsList,
    })
    setPaymentProviderModalOpen(false)
    onSetWalletPaymentProvider(option.walletPaymentProvider)
  }

  const handlePaymentProviderOpen = () => {
    setPaymentProviderModalOpen(true)
  }

  const handlePaymentProviderClose = () => {
    setPaymentProviderModalOpen(false)
  }

  const getWalletFullName = (values: WalletWithdrawFormValues) => {
    const walletCurrencyId = values?.wallet?.currency?.id
    const walletName = values?.wallet?.name || ''

    if (!values?.wallet?.id) {
      return undefined
    }

    if (location.pathname.includes('introducing-broker')) {
      t('Wallet.IB Wallet Currency Name', {
        walletCurrencyId,
        walletName,
      })
    }

    return t('Wallet.Wallet Currency Name', {
      walletCurrencyId,
      walletName,
    })
  }

  return (
    <Loading isLoading={loading} showLoadingIcon>
      {isWalletModalOpen && (
        <WalletWithdrawModal onSelectOption={handleWallet} onClose={handleWalletClose} />
      )}
      {isPaymentProviderModalOpen && (
        <WalletWithdrawPaymentProviderModal
          onSelectOption={handlePaymentProvider}
          onClose={handlePaymentProviderClose}
        />
      )}
      <FormField
        name='wallet.name'
        label={t('Wallet.From Wallet')}
        placeholder={t('Wallet.From Wallet')}
        value={getWalletFullName(values)}
        rightIcon={<DropArrowDownIcon />}
        hint={
          values.wallet?.currency &&
          `${t('Wallet.Balance')} ${formatMoney(values.wallet.balance, values.wallet.currency.id)}`
        }
        required
        readOnly
        onClick={handleWalletOpen}
      />
      <FormField
        name='paymentProvider.description'
        value={values.paymentProvider.description}
        label={t('Wallet.Payment Method')}
        placeholder={t('Wallet.Payment Method')}
        rightIcon={<DropArrowDownIcon />}
        disabled={!values.wallet?.id}
        required
        readOnly
        onClick={handlePaymentProviderOpen}
      />

      {paymentProvider.id && isPaymentProviderPSPType(providerCategoryId) && (
        <WalletWithdrawPSPFields
          amountHint={amountHint}
          showAmountPresets={showAmountPresets}
          onCancel={handleExitPageConfirmationModalClose}
          onClickTermsConditions={onClickTermsConditions}
        />
      )}
      {paymentProvider.id && isPaymentProviderCardProviderType(providerCategoryId) && (
        <WalletWithdrawPSPFields
          amountHint={amountHint}
          showAmountPresets={showAmountPresets}
          onCancel={handleExitPageConfirmationModalClose}
          onClickTermsConditions={onClickTermsConditions}
        />
      )}
      {paymentProvider.id && isPaymentProviderBankType(providerCategoryId) && (
        <WalletWithdrawBankFields
          amountHint={amountHint}
          showAmountPresets={showAmountPresets}
          onCancel={handleExitPageConfirmationModalClose}
          onClickTermsConditions={onClickTermsConditions}
        />
      )}
      {paymentProvider.id && isPaymentProviderPaymentAgentType(providerCategoryId) && (
        <WalletWithdrawPaymentAgentFields
          amountHint={amountHint}
          onCancel={handleExitPageConfirmationModalClose}
          onClickTermsConditions={onClickTermsConditions}
        />
      )}
      {paymentProvider.id && isPaymentProviderNoneType(providerCategoryId) && (
        <WalletWithdrawProviderNoneFields
          amountHint={amountHint}
          onCancel={handleExitPageConfirmationModalClose}
          onClickTermsConditions={onClickTermsConditions}
        />
      )}
    </Loading>
  )
}

interface OuterProps {
  wallet?: WalletDto
  walletType: WalletTypeEnum

  onSubmit(values: WalletWithdrawFormValues): void
  formatMoney: FormatMoney
  onCancel(): void

  title?: string
}

interface StateProps {
  wallet?: Wallet
  walletType: WalletTypeEnum
}

const initialState = (props: StateProps): WalletWithdrawFormValues => {
  const { wallet, walletType } = props

  return {
    wallet: {
      id: wallet?.id || undefined,
      name: wallet?.name || undefined,
      currency: wallet?.currency || undefined,
      walletType: {
        id: wallet?.walletType?.id || 0,
        name: wallet?.walletType?.name || '',
      },
      balance: wallet?.balance || 0,
      bonusReserved: wallet?.bonusReserved || 0,
    },
    paymentProvider: {
      id: undefined,
      name: undefined,
      description: '',
      providerCategory: { id: PaymentProviderType.None },
      currency: { id: '', name: '' },
      parameters: {
        currencies: [],
        currenciesLimits: [],
        messages: [{ title: [], list: [], top: [], bottom: [] }],
        termsConditions: [],
        fields: [],
        form: [],
      },
      method_name: '',
    },
    bankAccount: {},
    bankAccounts: [],
    isBankAccountsList: false,
    bankDepositProof: initialPlaceholderFile(),
    walletType: walletType,
    fields: {
      saveClientAccount: false,
    },
    amount: undefined,
    comment: '',
    terms: {
      withdrawing: false,
      paymentOperations: false,
    },
  }
}

export const enforceMaxLength = (values: {
  [key: string]: string | boolean
}): WalletWithdrawFormValues['fields'] => {
  return {
    ...values,
    accountName: isString(values.accountName)
      ? trimToMaxLength(values.accountName, 128)
      : values.accountName,
    bankName: isString(values.bankName) ? trimToMaxLength(values.bankName, 255) : values.bankName,
    bankAddress: isString(values.bankAddress)
      ? trimToMaxLength(values.bankAddress, 255)
      : values.bankAddress,
    bankCodeSort: isString(values.bankCodeSort)
      ? trimToMaxLength(values.bankCodeSort, 32)
      : values.bankCodeSort,
    bankCode: isString(values.bankCode) ? trimToMaxLength(values.bankCode, 32) : values.bankCode,
    externalAccount: isString(values.externalAccount)
      ? trimToMaxLength(values.externalAccount, 64)
      : values.externalAccount,
  }
}

export const WalletWithdrawForm = withFormik<OuterProps, WalletWithdrawFormValues>({
  mapPropsToValues: ({ wallet, walletType }) => {
    return initialState({ wallet, walletType })
  },
  handleSubmit: (values, { props }) => {
    const resultValues = {
      ...values,
      fields: enforceMaxLength(values.fields),
    }
    props.onSubmit(resultValues)
  },
  validate: (values, { formatMoney }) => {
    const errors: FormikErrors<WalletWithdrawFormValues> = {}
    const isPaymentAgentProviderType =
      values.paymentProvider.providerCategory.id === PaymentProviderType.PaymentAgent

    if (!values.wallet) {
      errors.wallet = {
        name: t('Validation.Required'),
      }
    }

    if (!values?.paymentProvider?.name) {
      errors.paymentProvider = {}
      errors.paymentProvider.name = t('Validation.Required')
    }

    if (isPaymentAgentProviderType) {
      if (!values.terms.powerOfAttorney) {
        errors.terms = {}
        errors.terms.powerOfAttorney = t('Validation.Required')
      }
    }

    const providerCategoryId = values.paymentProvider?.providerCategory?.id
    if (
      isPaymentProviderPSPType(providerCategoryId) ||
      isPaymentProviderCardProviderType(providerCategoryId) ||
      isPaymentProviderBankType(providerCategoryId)
    ) {
      const { minAmount, maxAmount } = getErrorAmount(values)

      if (isMinAmountError(values)) {
        errors.amount = `${t('Wallet.Minimum withdrawal')} ${formatMoney(
          minAmount,
          values.paymentProvider.currency.id
        )}`
      }

      if (isInsufficientFunds(values)) {
        errors.amount = t('Wallet.Insufficient Funds')
      }

      if (isMaxAmountError(values)) {
        errors.amount = `${t('Wallet.Maximum withdrawal')} ${formatMoney(
          maxAmount,
          values.paymentProvider.currency.id
        )}`
      }

      if (!values.terms.paymentOperations) {
        errors.terms = {}
        errors.terms.paymentOperations = t('Validation.Required')
      }
    }

    if (isPaymentProviderPaymentAgentType(providerCategoryId)) {
      const { minAmount, maxAmount } = getErrorAmount(values)

      if (isMinAmountError(values)) {
        errors.amount = `${t('Wallet.Minimum withdrawal')} ${formatMoney(
          minAmount,
          values.paymentProvider.currency.id
        )}`
      }

      if (isInsufficientFunds(values)) {
        errors.amount = t('Wallet.Insufficient Funds')
      }

      if (isMaxAmountError(values)) {
        errors.amount = `${t('Wallet.Maximum withdrawal')} ${formatMoney(
          maxAmount,
          values.paymentProvider.currency.id
        )}`
      }

      if (!values.terms.paymentOperations) {
        errors.terms = {}
        errors.terms.paymentOperations = t('Validation.Required')
      }
    }

    if (isPaymentProviderBankType(providerCategoryId)) {
      Object.entries(values.fields).forEach(([, fieldValue]) => {
        if (typeof fieldValue !== 'string') {
          return undefined
        }
      })

      if (!values.fields.accountName) {
        errors.fields = errors.fields || {}
        errors.fields.accountName = t('Validation.Required')
      }

      if (!values.isBankAccountsList && !values.bankDepositProof?.fileName) {
        errors.bankDepositProof = {}
        errors.bankDepositProof.error = t('Validation.Required')
      }
    }

    return errors
  },
})(WalletWithdrawFormUI)

const getErrorAmount = (values: WalletWithdrawFormValues) => {
  const currencyLimit = getCurrencyLimits(values.wallet, values.paymentProvider)
  const minAmount = currencyLimit?.minAmount || 0
  const maxAmount = currencyLimit?.maxAmount || 0

  return { minAmount, maxAmount }
}

const isMinAmountError = (values: WalletWithdrawFormValues): boolean => {
  const amount = values?.amount || 0
  const { minAmount } = getErrorAmount(values)

  return amount < minAmount || isZero(amount)
}

const isMaxAmountError = (values: WalletWithdrawFormValues): boolean => {
  const amount = values?.amount || 0
  const { maxAmount } = getErrorAmount(values)

  return amount > maxAmount
}

const isInsufficientFunds = (values: WalletWithdrawFormValues): boolean => {
  return Math.max(values.wallet.balance, 0) < (values.amount ?? 0)
}

import { IconProps } from './IconProps'

export function FireIcon(props: IconProps): JSX.Element {
  const size = props.size || 64

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.4931 9.58371C11.3635 10.3079 11.0151 10.975 10.4948 11.4951C9.9745 12.0153 9.30734 12.3636 8.58313 12.4931C8.55563 12.4975 8.52784 12.4998 8.5 12.5C8.37458 12.4999 8.25375 12.4528 8.16148 12.3678C8.06921 12.2829 8.01223 12.1663 8.00185 12.0414C7.99146 11.9164 8.02843 11.792 8.10542 11.693C8.18242 11.594 8.2938 11.5276 8.4175 11.5068C9.45312 11.3325 10.3319 10.4537 10.5075 9.41621C10.5297 9.28542 10.603 9.16882 10.7112 9.09205C10.8193 9.01527 10.9536 8.98462 11.0844 9.00683C11.2152 9.02905 11.3318 9.1023 11.4085 9.21049C11.4853 9.31867 11.5153 9.45292 11.4931 9.58371ZM13.5 8.99996C13.5 10.4586 12.9205 11.8576 11.8891 12.889C10.8576 13.9205 9.45869 14.5 8 14.5C6.54131 14.5 5.14236 13.9205 4.11091 12.889C3.07946 11.8576 2.5 10.4586 2.5 8.99996C2.5 7.25496 3.1875 5.47058 4.54125 3.69683C4.5841 3.64067 4.63837 3.59422 4.70047 3.56056C4.76258 3.52689 4.83112 3.50676 4.90157 3.50151C4.97201 3.49625 5.04278 3.50599 5.10919 3.53006C5.17561 3.55414 5.23616 3.59203 5.28687 3.64121L6.79437 5.10433L8.16938 1.32871C8.19685 1.25341 8.24202 1.18581 8.30108 1.13162C8.36015 1.07743 8.43138 1.03824 8.50877 1.01735C8.58615 0.996452 8.66743 0.99447 8.74574 1.01157C8.82406 1.02866 8.89711 1.06434 8.95875 1.11558C10.3256 2.24996 13.5 5.28433 13.5 8.99996ZM12.5 8.99996C12.5 6.11933 10.2631 3.62996 8.86187 2.35433L7.47 6.17121C7.44143 6.2496 7.3937 6.3196 7.33116 6.37483C7.26861 6.43005 7.19324 6.46875 7.11191 6.48739C7.03059 6.50603 6.94589 6.50402 6.86553 6.48154C6.78518 6.45906 6.71173 6.41684 6.65188 6.35871L5.00375 4.75996C4.00562 6.20058 3.5 7.62496 3.5 8.99996C3.5 10.1934 3.97411 11.338 4.81802 12.1819C5.66193 13.0259 6.80653 13.5 8 13.5C9.19347 13.5 10.3381 13.0259 11.182 12.1819C12.0259 11.338 12.5 10.1934 12.5 8.99996Z'
        fill='#EF0000'
      />
      <path
        d='M13 9.5C13 11.9853 10.7614 14 8 14C5.23858 14 3 11.9853 3 9.5C3 7.95711 3.18498 5.31074 4.5 4.50003C5.3032 4.00485 5.9529 6.00003 7 6.00003C7.7111 6.00003 8.38754 1.75916 9 2C10.7659 2.6944 13 7.65472 13 9.5Z'
        fill='#F7B054'
      />
      <path
        d='M11.4931 9.58377C11.3635 10.308 11.0151 10.975 10.4948 11.4952C9.9745 12.0154 9.30734 12.3636 8.58313 12.4931C8.55563 12.4975 8.52784 12.4998 8.5 12.5C8.37458 12.5 8.25375 12.4528 8.16148 12.3679C8.06921 12.2829 8.01223 12.1664 8.00185 12.0414C7.99146 11.9164 8.02843 11.7921 8.10542 11.6931C8.18242 11.5941 8.2938 11.5276 8.4175 11.5069C9.45312 11.3325 10.3319 10.4538 10.5075 9.41627C10.5297 9.28549 10.603 9.16888 10.7112 9.09211C10.8193 9.01534 10.9536 8.98468 11.0844 9.0069C11.2152 9.02911 11.3318 9.10236 11.4085 9.21055C11.4853 9.31873 11.5153 9.45298 11.4931 9.58377ZM13.5 9.00002C13.5 10.4587 12.9205 11.8577 11.8891 12.8891C10.8576 13.9206 9.45869 14.5 8 14.5C6.54131 14.5 5.14236 13.9206 4.11091 12.8891C3.07946 11.8577 2.5 10.4587 2.5 9.00002C2.5 7.25502 3.1875 5.47065 4.54125 3.6969C4.5841 3.64073 4.63837 3.59428 4.70047 3.56062C4.76258 3.52695 4.83112 3.50682 4.90157 3.50157C4.97201 3.49631 5.04278 3.50605 5.10919 3.53013C5.17561 3.55421 5.23616 3.59209 5.28687 3.64127L6.79437 5.1044L8.16938 1.32877C8.19685 1.25347 8.24202 1.18587 8.30108 1.13168C8.36015 1.07749 8.43138 1.0383 8.50877 1.01741C8.58615 0.996513 8.66743 0.994531 8.74574 1.01163C8.82406 1.02873 8.89711 1.0644 8.95875 1.11565C10.3256 2.25002 13.5 5.2844 13.5 9.00002ZM12.5 9.00002C12.5 6.1194 10.2631 3.63002 8.86187 2.3544L7.47 6.17127C7.44143 6.24966 7.3937 6.31966 7.33116 6.37489C7.26861 6.43011 7.19324 6.46881 7.11191 6.48745C7.03059 6.50609 6.94589 6.50408 6.86553 6.4816C6.78518 6.45913 6.71173 6.4169 6.65188 6.35877L5.00375 4.76002C4.00562 6.20065 3.5 7.62502 3.5 9.00002C3.5 10.1935 3.97411 11.3381 4.81802 12.182C5.66193 13.0259 6.80653 13.5 8 13.5C9.19347 13.5 10.3381 13.0259 11.182 12.182C12.0259 11.3381 12.5 10.1935 12.5 9.00002Z'
        fill='#CC0000'
      />
    </svg>
  )
}

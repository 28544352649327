import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { CheckBox } from '../../global/checkBox/CheckBox'
import {
  isPlatformExternalApplicationACUITY,
  isPlatformExternalApplicationFXBLUE,
} from '../../model/PlatformInfoDto'
import { OperatingSystemEnum } from '../../model/PlatformTypeEnum'
import { Text, TextSmall } from '../../ui/Typography/Typography'
import {
  MTDownloadIcon,
  ManualIconFactory,
  PlatformDownloadButton,
  PlatformIconFactory,
} from './PlatformIcon'
import { usePlatformInfo } from './Platforms'

import styles from './Platforms.module.scss'

enum ToolkitType {
  ACUITY = 'acuity',
  MT = 'mt',
}

export const PlatformsToolKitsSection: React.FC<ReturnType<typeof usePlatformInfo>> = (props) => {
  const { selectedPlatform } = props

  const { t } = useTranslation()

  const [isAcuitySelected, setIsAcuitySelected] = useState(false)
  const [isMtSelected, setIsMtSelected] = useState(false)

  const handleSetToolkitType = (
    event: React.ChangeEvent<HTMLInputElement>,
    toolkitType: ToolkitType
  ) => {
    const { checked } = event.target
    if (toolkitType === ToolkitType.MT) {
      setIsMtSelected(checked)
    } else {
      setIsAcuitySelected(checked)
    }
  }

  return (
    <div className={styles.toolKits}>
      {selectedPlatform?.externalPlatformApplications.map((application, applicationIndex) => {
        const isSelected = isPlatformExternalApplicationFXBLUE(application)
          ? isMtSelected
          : isAcuitySelected

        return (
          <div className={classNames(styles.toolkit)} key={applicationIndex}>
            <h2 className={styles.title}>
              {isPlatformExternalApplicationFXBLUE(application) && (
                <span>{t('MetaTrader.FxBlue')}</span>
              )}
              {isPlatformExternalApplicationACUITY(application) && (
                <span>{t('MetaTrader.Acuity')}</span>
              )}
            </h2>
            <Text isParagraph className={classNames(styles.text, styles.subtitle, styles.textGray)}>
              {isPlatformExternalApplicationFXBLUE(application) && (
                <span>{t('MetaTrader.FXBlueDesc')}</span>
              )}
              {isPlatformExternalApplicationACUITY(application) && (
                <span>{t('MetaTrader.AcuityDesc')}</span>
              )}
            </Text>

            <div className={classNames(styles.icons)}>
              {isSelected && (
                <div className={styles.icon}>
                  <PlatformDownloadButton to={application.productLink}>
                    <PlatformIconFactory os={OperatingSystemEnum.WINDOWS} />
                  </PlatformDownloadButton>
                </div>
              )}
              {!isSelected && (
                <div className={styles.icon}>
                  <PlatformDownloadButton disabled>
                    <PlatformIconFactory os={OperatingSystemEnum.WINDOWS} />
                  </PlatformDownloadButton>
                </div>
              )}

              {application.guides.map((guid, guideIndex) =>
                isSelected ? (
                  <div className={styles.icon} key={guideIndex}>
                    <PlatformDownloadButton to={guid.guideLink} disabled={!isSelected}>
                      <MTDownloadIcon
                        title={guid.name}
                        icon={<ManualIconFactory icon={guid.icon} />}
                      />
                    </PlatformDownloadButton>
                  </div>
                ) : (
                  <div className={styles.icon} key={guideIndex}>
                    <PlatformDownloadButton key={guideIndex} disabled>
                      <MTDownloadIcon
                        title={guid.name}
                        icon={<ManualIconFactory icon={guid.icon} />}
                      />
                    </PlatformDownloadButton>
                  </div>
                )
              )}
            </div>
            <TextSmall isParagraph className={classNames(styles.textGray)}>
              {t('MetaTrader.More')}{' '}
              <a className='is-link' href={application.infoLink} target='_blank' rel='noreferrer'>
                {t('here')}
              </a>
            </TextSmall>

            <div className={styles.consent}>
              <div className={styles.checkbox}>
                <CheckBox
                  value={
                    isPlatformExternalApplicationFXBLUE(application)
                      ? isMtSelected
                      : isAcuitySelected
                  }
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleSetToolkitType(
                      event,
                      isPlatformExternalApplicationFXBLUE(application)
                        ? ToolkitType.MT
                        : ToolkitType.ACUITY
                    )
                  }}
                />
              </div>
              <TextSmall isParagraph>
                {isPlatformExternalApplicationFXBLUE(application) && (
                  <span>{t('MetaTrader.FXBlueDownload')}</span>
                )}
                {isPlatformExternalApplicationACUITY(application) && (
                  <span>{t('MetaTrader.AcuityDownload')}</span>
                )}
              </TextSmall>
            </div>
          </div>
        )
      })}
    </div>
  )
}

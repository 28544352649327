export enum FilterFields {
  ClientAmountFrom = 'ca_search_AmountFrom',
  ClientAmountTo = 'ca_search_AmountTo',
  ClientClientAmountFrom = 'ca_search_ClientAmountFrom',
  ClientClientAmountTo = 'ca_search_ClientAmountTo',
  QueryAmountFrom = 'query_search_AmountFrom',
  QueryAmountTo = 'query_search_AmountTo',
  QueryVolumeFrom = 'query_search_VolumeFrom',
  QueryVolumeTo = 'query_search_VolumeTo',
  ClientVolumeFrom = 'ca_search_VolumeFrom',
  ClientVolumeTo = 'ca_search_VolumeTo',
  ClientPriceFrom = 'ca_search_PriceFrom',
  ClientPriceTo = 'ca_search_PriceTo',
  ClientDateFrom = 'ca_search_DateFrom',
  ClientDateTo = 'ca_search_DateTo',
  ClientRegistrationDateFrom = 'ca_search_RegistrationDateFrom',
  ClientRegistrationDateTo = 'ca_search_RegistrationDateTo',
  DateFrom = 'DateFrom',
  DateTo = 'DateTo',
  AmountFrom = 'ca_search_AmountFrom',
  AmountTo = 'ca_search_AmountTo',
  TradingAccountsCountFrom = 'ca_search_TradingAccountsCountFrom',
  TradingAccountsCountTo = 'ca_search_TradingAccountsCountTo',
  Name = 'ca_search_Name',
}

import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import classNames from 'classnames'

import { Loading } from '../../../../global/Loading/Loading'
import { Button } from '../../../../global/button/Button'
import { useSessionEntity } from '../../../../global/context/EntityContext'
import { useProductReadContext } from '../../../../global/context/ProductContext'
import {
  useArabicSessionLanguage,
  useSessionLanguage,
} from '../../../../global/context/SessionSettingsContext'
import { DocumentErrorModal } from '../../../../global/modal/DocumentErrorModal'
import { useLogout } from '../../../../hooks/useLogout'
import { BackIcon } from '../../../../icons/BackIcon'
import { ForwardIcon } from '../../../../icons/ForwardIcon'
import { isVerificationExpedited } from '../../../../model/AccountDetailedDto'
import { isAccountAppropriatenessTestSubmittedStatus } from '../../../../model/AccountDetailedStatus'
import { AccountType } from '../../../../model/AccountType'
import { TestAnswerOutcomeStatus, TestAnswersDto } from '../../../../model/ClientTestAnswerDto'
import { ClientTestDto } from '../../../../model/ClientTestDto'
import { CreateClientTest } from '../../../../model/CreateClientTest'
import { DocumentCategoryType } from '../../../../model/DocumentCategories'
import { DocumentCategoryOptionType } from '../../../../model/DocumentCategoryOptionType'
import { DocumentPropertyType } from '../../../../model/DocumentPropertyType'
import { AppropriatenessTestTakeTestFormValues } from '../../../../model/Test'
import { AppropriatenessTestSectionCode } from '../../../../model/TestSectionsDto'
import { Link, useNavigate } from '../../../../navigation/custom-react-router-dom'
import { Text, TextH1, TextStrong } from '../../../../ui/Typography/Typography'
import {
  isDepositBeforeKYC,
  isSumsubNeeded,
} from '../../../../utils/AccountAccess/accountKYCStatuses'
import {
  useAccountReadContext,
  useAccountWriteContext,
} from '../../../../utils/AccountContextContext'
import { useApiClient } from '../../../../utils/ApiClient'
import { ClientApiClient } from '../../../../utils/clientApi'
import {
  TickmillCompaniesEnum,
  isTickmillEU,
  isTickmillUK,
  requiresAppTestImprovement,
} from '../../../../utils/companyName.utils'
import { useWindowResize } from '../../../../utils/domUtils'
import { useFetchOne } from '../../../../utils/useFetch'
import { scrollToTop } from '../../../../utils/useScrollToTop'
import { isOne, isZero } from '../../../../utils/validations'
import { LeftSection } from '../../Components/LeftSection'
import { SignupFormWrapper } from '../../Components/SignupFormWrapper'
import { SignupSections, SignupStep } from '../../Components/SignupStep'
import { SignupWrapper } from '../../Components/SignupWrapper'
import { FinancialInfoForm } from './FinancialInfoStep1234Form'

import styles from './FinancialInfoPage.module.scss'

enum FailReason {
  Test = 'test',
  Signup = 'signup',
}

const useAppropriatenessTestMutate = (clientId?: string) => {
  const apiClient = useApiClient(ClientApiClient)
  const locale = useSessionLanguage()
  const { product } = useProductReadContext()

  const [isLoading, setLoading] = useState(false)
  const [isSuccess, setSuccess] = useState(false)
  const [isSignupFail, setSignupFail] = useState(false)
  const [isTestFail, setTestFail] = useState(false)
  const [accountApTestAnswers, setAccountApTestAnswers] = useState<TestAnswersDto | undefined>()
  const { refreshAccount } = useAccountWriteContext()

  const mutate = async (
    values: AppropriatenessTestTakeTestFormValues,
    setAlreadyUploadedModal: (message: string | undefined) => void
  ) => {
    try {
      setLoading(true)

      await apiClient.createClientTests(dataToSubmit(values))
      const accountApTestAnswers = await apiClient.getAccountAppTestAnswers(locale, product)

      const files = Object.entries(values.documents || {})
        .map(([questionId, filesData]) => filesData.map((x) => ({ ...x, questionId })).flat())
        .flat()
        .filter((x) => !!x.fileName)
      setAccountApTestAnswers(accountApTestAnswers)

      if (files.length > 0) {
        await apiClient.uploadDocuments({
          documents: files.map((file) => ({
            categoryId: DocumentCategoryType.AppropriatenessTest,
            typeId: DocumentCategoryOptionType.TradingStatement,
            file: file.base64Content,
            filename: file.fileName,
            properties: {
              [DocumentPropertyType.ClientTestId]: accountApTestAnswers.id,
              [DocumentPropertyType.TestQuestionId]: file.questionId,
            },
          })),
        })
      }
      if (accountApTestAnswers.isClientVulnerable) {
        setSignupFail(true)
      }
      if (accountApTestAnswers.outcome.outcome === TestAnswerOutcomeStatus.FAILED) {
        setTestFail(true)
      } else {
        setSuccess(true)
        await refreshAccount(locale)
      }
      scrollToTop()
    } catch (error: unknown) {
      setAlreadyUploadedModal((error as any).response?.response?.data?.code)
      console.log('error:', error)
    } finally {
      setLoading(false)
    }
  }

  const dataToSubmit = (values: AppropriatenessTestTakeTestFormValues): CreateClientTest => {
    const selectedAnswers = Object.entries(values.selectedAnswers).map(([, value]) => value)
    const freeAnswers = Object.entries(values.freeAnswers).map(([key, value]) => {
      return { testQuestionId: key, answer: value }
    })

    return {
      category: values?.category || '',
      clientId: clientId || '',
      testId: values?.testId || '',
      selectedAnswers,
      freeAnswers,
    }
  }

  return { mutate, isLoading, isSuccess, isSignupFail, isTestFail, accountApTestAnswers }
}

export const FinancialInfoPage: React.FC = () => {
  const { clientId } = useParams<{ clientId?: string }>()

  const childRef = useRef<{ goPrevStep(): void }>()

  const { t } = useTranslation()
  const apiClient = useApiClient(ClientApiClient)
  const entity = useSessionEntity()

  const isMobile = useWindowResize()

  const { account } = useAccountReadContext()
  const tickmillEntity = useSessionEntity()
  const { refreshAccount } = useAccountWriteContext()

  const locale = useSessionLanguage()
  const navigate = useNavigate()

  const [formValues, setFormValues] = useState<AppropriatenessTestTakeTestFormValues>()
  const [alreadyUploadedModal, setAlreadyUploadedModal] = useState<string | undefined>()
  const [step, setStep] = useState('')

  const { accountApTestAnswers, ...mutation } = useAppropriatenessTestMutate(clientId)

  const testsCallback = useCallback(async () => {
    return apiClient.getClientAppTests(locale)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])

  const { data, isLoading } = useFetchOne(testsCallback)

  const sections = data?.sections || []
  const stepIndex = sections?.findIndex((x) => x.code === step) || 0
  const subStep = stepIndex + 1
  const sectionSize = sections?.length || 0

  const [isFirstStep, setIsFirstStep] = useState(true)

  const entityRequiresAppTestImprovement = requiresAppTestImprovement(tickmillEntity)
  const isAppTestImprovementRequired = entityRequiresAppTestImprovement && isFirstStep

  const sectionStep = SignupSections.FinancialInfo

  useEffect(() => {
    window.onpopstate = () => refreshAccount(locale)
  }, [locale, refreshAccount])

  useEffect(() => {
    const [section] = data?.sections || []
    setStep(section?.code || '')
  }, [account, data?.sections])

  const handleSubmit = async (values: AppropriatenessTestTakeTestFormValues) => {
    await mutation.mutate(values, setAlreadyUploadedModal)
  }

  const handleNextStep = (values: AppropriatenessTestTakeTestFormValues) => {
    const sections = data?.sections || []
    const stepIndex = sections.findIndex((x) => x.code === step)
    const section = sections?.[stepIndex + 1]
    if (section?.code) {
      setStep(section.code)
      setFormValues(values)
      scrollToTop()
    }
  }

  const handlePrevStep = (values: AppropriatenessTestTakeTestFormValues) => {
    const sections = data?.sections || []
    const stepIndex = sections.findIndex((x) => x.code === step)
    const section = sections?.[stepIndex - 1]

    if (section?.code) {
      setStep(section.code)
      setFormValues(values)
      scrollToTop()
    }
  }

  if (mutation.isSuccess) {
    const isDocumentSkipStep = !!localStorage.getItem('hasAdditionalId')
    if (isDepositBeforeKYC(account) || isVerificationExpedited(account)) {
      navigate('/sign-up/first-time-deposit')
      return
    }

    if (isDocumentSkipStep) {
      navigate('/sign-up/confirm')
      return
    }

    return <SuccessPage section={sectionStep} step={step} data={data} />
  }

  const section = data?.sections?.find((x) => x.code === step)

  if (mutation.isSignupFail) {
    return <FailPage step={step} section={sectionStep} data={data} failReason={FailReason.Signup} />
  }
  if (mutation.isTestFail) {
    return <FailPage section={sectionStep} step={step} data={data} failReason={FailReason.Test} />
  }

  if (!isLoading && isAccountAppropriatenessTestSubmittedStatus(account?.status.id)) {
    return <SuccessPage section={sectionStep} step={step} data={data} />
  }

  return (
    <SignupFormWrapper
      label={t('Sign up.Sign Up')}
      onBackToPreviousStep={
        !isZero(stepIndex)
          ? () => {
              childRef?.current?.goPrevStep()
            }
          : undefined
      }
      cancelPath='/dashboard/take-app-test'
    >
      <DocumentErrorModal
        onClose={() => setAlreadyUploadedModal(undefined)}
        message={alreadyUploadedModal}
        isOpen={!!alreadyUploadedModal}
      />
      <SignupStep
        accountTypeId={account?.type.id}
        subStep={{
          current: stepIndex <= sectionSize ? subStep : sectionSize,
          total: sectionSize,
        }}
        section={sectionStep}
        isHidden={isAppTestImprovementRequired}
      />
      <Loading showLoadingIcon isLoading={isLoading}>
        <SignupWrapper noBackground={subStep === 1 && !isFirstStep}>
          {entityRequiresAppTestImprovement && !isFirstStep && (isOne(subStep) || !isMobile) && (
            <LeftSection
              hideInfo
              title={
                isTickmillEU(account) || isTickmillUK(account)
                  ? t('Sign up.Appropriateness Test')
                  : t('Sign up.Financial Info & Experience')
              }
              description={() => (
                <React.Fragment>
                  {account?.type.id === AccountType.Individual && (
                    <TextStrong isParagraph>
                      {t(
                        'Sign up.Please answer all questions with accurate information that reflects your current situation, knowledge, and experience'
                      )}
                    </TextStrong>
                  )}

                  {account?.type.id === AccountType.Corporate &&
                    entity !== TickmillCompaniesEnum.TICKMILL_UK && (
                      <TextStrong isParagraph>
                        {t(
                          'Sign up.Please fill these sections in accordance to the knowledge and experience of the person authorised to trade on behalf of the company.'
                        )}
                      </TextStrong>
                    )}
                  {isOne(subStep) && (
                    <Text isParagraph>
                      {t(
                        'Sign up.We will not contact any employer you have specified under any circumstances'
                      )}
                    </Text>
                  )}
                  {!isOne(subStep) && <AlignmentMessage sectionCode={section?.code || ''} />}
                </React.Fragment>
              )}
            />
          )}

          {!entityRequiresAppTestImprovement && (
            <LeftSection
              title={t('Sign up.Financial Info & Experience')}
              description={() => (
                <React.Fragment>
                  {account?.type.id === AccountType.Individual && (
                    <Text isParagraph>
                      {t(
                        'Sign up.Please fill these sections in accordance to your knowledge and experience'
                      )}
                    </Text>
                  )}
                  {account?.type.id === AccountType.Corporate && (
                    <Text isParagraph>
                      {t(
                        'Sign up.Please fill these sections in accordance to the knowledge and experience of the person authorised to trade on behalf of the company.'
                      )}
                    </Text>
                  )}
                </React.Fragment>
              )}
            />
          )}
          <section
            className={classNames(styles.testWrapper, {
              [styles.formSection]: !isAppTestImprovementRequired,
            })}
          >
            {isAppTestImprovementRequired && (
              <div
                className={classNames(styles.infoSection, {
                  [styles.testInfo]: isAppTestImprovementRequired,
                })}
              >
                <TextH1>{t('Sign up.Appropriateness Test')}</TextH1>

                <ul className={styles.list}>
                  {[
                    t('Sign up.Answer all questions with accurate information'),
                    t(
                      'Sign up.Completion and passing of the appropriateness test are prerequisites'
                    ),
                    t('Sign up.We will evaluate your trading knowledge, trading experience'),
                  ].map((text, index) => (
                    <li key={index} className='is-flex'>
                      <div className={styles.bullet} />
                      <div>
                        <Text>{text}</Text>
                      </div>
                    </li>
                  ))}
                </ul>
                <Button
                  appearance='primary'
                  size='L'
                  className={styles.button}
                  onClick={() => {
                    setIsFirstStep(false)
                    scrollToTop()
                  }}
                >
                  {t('Sign up.Start Test')}
                </Button>
              </div>
            )}

            {!isAppTestImprovementRequired && data && (
              <FinancialInfoForm
                ref={childRef as any}
                data={data}
                step={step}
                values={formValues}
                onSubmit={handleSubmit}
                goPrevStep={handlePrevStep}
                goNextStep={handleNextStep}
              />
            )}
          </section>
        </SignupWrapper>
      </Loading>
    </SignupFormWrapper>
  )
}

interface FailPageProps {
  data: ClientTestDto | undefined
  step: string
  failReason: FailReason
  section: number
}

const FailPage: React.FC<FailPageProps> = (props) => {
  const { data, step, failReason, section } = props
  const isArabic = useArabicSessionLanguage()
  const { account } = useAccountReadContext()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { logout } = useLogout()

  const sections = data?.sections || []
  const stepIndex = sections?.findIndex((x) => x.code === step) || 0
  const subStep = stepIndex + 1
  const sectionSize = sections?.length || 0

  const content: Record<FailReason, { title: string; description: string[] }> = {
    [FailReason.Test]: {
      title: t('Profile.Trading Experience Test Failed!'),
      description: [
        t(
          'Profile.You may fully use Client Area functions and open a live trading account after satisfying appropriateness test requirements. Please note that live trading account opening and Client Area functions will be frozen until you satisfy appropriateness test requirements.'
        ),
      ],
    },
    [FailReason.Signup]: {
      title: t('Sign up.Your registration has been unsuccessful') + '!',
      description: [
        t('Sign up.From the information you have provided, we do not think', {
          email: 'support@tickmill.co.uk',
        }),
      ],
    },
  }

  const clearSessionAndLogout = async () => {
    logout()
    navigate('/logout')
  }

  const navigateToTest = () => {
    navigate('/profile/appropriateness-test')
  }

  const renderButton = () => {
    switch (failReason) {
      case FailReason.Signup:
        return (
          <Button
            className={styles.button}
            appearance='primary'
            size='L'
            onClick={clearSessionAndLogout}
          >
            {t('Close')}
          </Button>
        )
      case FailReason.Test:
        return (
          <Button
            className={styles.button}
            type='submit'
            appearance='primary'
            size='L'
            onClick={navigateToTest}
            renderRightIcon={() => (
              <span className='is-flex is-align-items-center'>
                {isArabic ? <BackIcon inverse /> : <ForwardIcon inverse />}
              </span>
            )}
          >
            {t('Next')}
          </Button>
        )
    }
  }
  const failDescriptionLength = content[failReason].description.length

  return (
    <SignupFormWrapper label={t('Sign up.Sign up')}>
      <SignupStep
        accountTypeId={account?.type.id}
        subStep={{
          current: stepIndex <= sectionSize ? subStep : sectionSize,
          total: sectionSize,
        }}
        isFailed={true}
        section={section}
      />
      <SignupWrapper>
        <div className={styles.confirmationWrapper}>
          <div className={styles.content}>
            <TextH1>{content[failReason].title}</TextH1>
            <div className={styles.description}>
              <p>
                {' '}
                {content[failReason].description.map((line, index) => {
                  if (failDescriptionLength > 1 && index === failDescriptionLength - 1) {
                    return (
                      <div className='mb-3'>
                        <Text>
                          {line} <Link to='/dashboard/learning/tutorial-videos'>{t('here')}</Link>
                        </Text>
                      </div>
                    )
                  }
                  return (
                    <div className='mb-3'>
                      <Text>{line}</Text>
                    </div>
                  )
                })}
                {}
              </p>
            </div>
            {renderButton()}
          </div>
        </div>
      </SignupWrapper>
    </SignupFormWrapper>
  )
}

interface SuccessPageProps {
  data: ClientTestDto | undefined
  step: string
  section: number
}

const SuccessPage: React.FC<SuccessPageProps> = (props) => {
  const { data, step, section } = props

  const { t } = useTranslation()

  const isArabic = useArabicSessionLanguage()

  const { account } = useAccountReadContext()

  const sections = data?.sections || []
  const stepIndex = sections?.findIndex((x) => x.code === step) || 0
  const subStep = stepIndex + 1
  const sectionSize = sections?.length || 0

  return (
    <SignupFormWrapper label={t('Sign up.Sign up')}>
      <SignupStep
        accountTypeId={account?.type.id}
        subStep={{
          current: stepIndex <= sectionSize ? subStep : sectionSize,
          total: sectionSize,
        }}
        section={section}
        isCompleted
      />
      <SignupWrapper reversed>
        <div className={styles.confirmationWrapper}>
          <div className={styles.content}>
            <TextH1>{t('Sign up.Trading Experience Test Complete')}!</TextH1>
            <div className={styles.description}>
              <p>
                {t("Sign up.We've emailed all of the relevant information to")}{' '}
                <b>{account?.emails[0]?.address}</b>
              </p>
              <br />
              <p>
                {t(
                  "Sign up.Let's get on to the next step – uploading your identification documents."
                )}
              </p>
            </div>
            <Link to={'/sign-up/first-time-deposit'}>
              <Button
                className={styles.button}
                type='submit'
                appearance='primary'
                size='L'
                renderRightIcon={() => (
                  <span className='is-flex is-align-items-center'>
                    {isArabic ? <BackIcon inverse /> : <ForwardIcon inverse />}
                  </span>
                )}
              >
                {isSumsubNeeded(account) ? t('Sign up.Start Verification') : t('Next')}
              </Button>
            </Link>
          </div>
        </div>
      </SignupWrapper>
    </SignupFormWrapper>
  )
}

export const AlignmentMessage: FC<{ sectionCode?: string }> = ({ sectionCode }) => {
  const { t } = useTranslation()

  const AlignmentMessage: Record<string, string> = {
    [AppropriatenessTestSectionCode.FinancialBackground]: t(
      'Sign up.To align our products with your financial profile as part of our KYC process, we need details about your financial background'
    ),
    [AppropriatenessTestSectionCode.TradingKnowledge]: t(
      'Sign up.To align our products with your financial profile as part of our KYC process, we need details about your trading knowledge and experience'
    ),
    [AppropriatenessTestSectionCode.CFDInvestmentKnowledge]: t(
      'Sign up.To align our products with your financial profile as part of our KYC process, we need details about your Investment Knowledge'
    ),
    [AppropriatenessTestSectionCode.ETDInvestmentKnowledge]: t(
      'Sign up.To align our products with your financial profile as part of our KYC process, we need details about your Investment Knowledge of leverage products'
    ),
  }

  if (!sectionCode || !AlignmentMessage[sectionCode]) {
    return null
  }

  return <Text>{AlignmentMessage[sectionCode]}</Text>
}

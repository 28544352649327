import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

import { Button } from '../../global/button/Button'
import { SelectModalCardOption } from '../../global/field/SelectModelCardOption'
import { SelectableModal } from '../../global/field/SelectableModal'
import { Modal } from '../../global/modal/Modal'
import { MT4Logo } from '../../icons/MT4Logo'
import { MT5Logo } from '../../icons/MT5Logo'
import { TickmillTraderLogo } from '../../icons/TickmillTraderLogo'
import { TradingViewLogo } from '../../icons/TradingViewLogo'
import { NameDto } from '../../model/NameDto'
import { PlatformTypeEnum } from '../../model/PlatformTypeEnum'
import { TradingAccountInfo, TradingAccountInfoDetail } from '../../model/TradingAccountInfo'
import { Text } from '../../ui/Typography/Typography'

import styles from './TradingPlatformModal.module.scss'

interface WalletModalProps {
  tradingAccountsAccountInfo: TradingAccountInfo[]
  demo?: boolean
  onClose(): void
  onSelectOption(platform: NameDto): void
  onConfirm(): void
  tmpPlatformType?: NameDto | undefined
}

export const TradingPlatformModal: React.FC<WalletModalProps> = (props) => {
  const { tradingAccountsAccountInfo, demo, onSelectOption, onClose, onConfirm, tmpPlatformType } =
    props
  const { t } = useTranslation()

  const allowedPlatforms = useMemo(
    () =>
      demo
        ? tradingAccountsAccountInfo.filter((x) => x.allowToOpenDemoAccount)
        : tradingAccountsAccountInfo,
    [tradingAccountsAccountInfo, demo]
  )
  const addPlatformDetails = (
    accountInfo: TradingAccountInfo,
    t: TFunction
  ): TradingAccountInfoDetail => {
    switch (accountInfo.platform.id) {
      case PlatformTypeEnum.MT4:
        return {
          ...accountInfo,
          hint: t('Trading Account.mt4.hint'),
          description: t('Trading Account.mt4.description'),
          logo: <MT4Logo size={40} />,
        }
      case PlatformTypeEnum.MT5:
        return {
          ...accountInfo,

          hint: t('Trading Account.mt5.hint'),
          description: t('Trading Account.mt5.description'),
          logo: <MT5Logo size={40} />,
        }
      case PlatformTypeEnum.TradingView:
        return {
          ...accountInfo,
          hint: t('Trading Account.tradingView.hint'),
          description: t('Trading Account.tradingView.description'),
          logo: <TradingViewLogo size={40} />,
        }
      default:
        return {
          ...accountInfo,
          hint: t('Trading Account.tickmillTrader.hint'),
          description: t('Trading Account.tickmillTrader.description'),
          logo: <TickmillTraderLogo size={40} />,
        }
    }
  }
  const getPlatformTypeName = (platformTypeId: PlatformTypeEnum) => {
    switch (platformTypeId) {
      case PlatformTypeEnum.MT4:
        return t('Trading Account.mt4.name')
      case PlatformTypeEnum.MT5:
        return t('Trading Account.mt5.name')
      case PlatformTypeEnum.TradingView:
        return t('Trading Account.tradingView.name')
      default:
        return t('Trading Account.tickmillTrader.name')
    }
  }

  return (
    <Modal
      closeModal={onClose}
      cardClassName={styles.modalCard}
      render={({ closeModal }) => (
        <SelectableModal
          onCancel={closeModal}
          title={t('Trading Account.Platform Type')}
          renderOptions={() => (
            <div className={styles.platformTypes}>
              {allowedPlatforms.map((allowedPlatform) => {
                const { platform, hint, description, logo } = addPlatformDetails(allowedPlatform, t)
                return (
                  <SelectModalCardOption
                    label={getPlatformTypeName(platform.id)}
                    value={platform.id === tmpPlatformType?.id}
                    onClick={() => {
                      onSelectOption(platform)
                    }}
                    key={platform.id}
                    logo={logo}
                    hint={hint}
                    description={description}
                    isOnFire={platform.id === PlatformTypeEnum.TradingView}
                  />
                )
              })}
            </div>
          )}
          renderFooter={() => (
            <>
              <Button appearance='plain' onClick={closeModal}>
                <Text>{t('Cancel')}</Text>
              </Button>
              <Button appearance='plain' onClick={onConfirm}>
                <Text>{t('Confirm')}</Text>
              </Button>
            </>
          )}
        />
      )}
    />
  )
}

import React, { PropsWithChildren, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Loading } from '../../../../global/Loading/Loading'
import { useSignup } from '../../../../global/context/SignupContext'
import { AccountType } from '../../../../model/AccountType'
import { LeadDto } from '../../../../model/LeadDto'
import { Text } from '../../../../ui/Typography/Typography'
import { isTickmillUKType } from '../../../../utils/companyName.utils'
import { useWindowResize } from '../../../../utils/domUtils'
import { LeftSection } from '../../Components/LeftSection'
import { SignupFormWrapper } from '../../Components/SignupFormWrapper'
import { SignupSections, SignupStep } from '../../Components/SignupStep'
import { SignupWrapper } from '../../Components/SignupWrapper'

import styles from './PersonalDetailsTemplate.module.scss'

export interface PersonalDetailsTemplateProps {
  lead?: LeadDto
  step: number
  USStep?: boolean
  stepLabel?: string
  isLoading?: boolean
  goBack?: () => void
  reversed?: boolean
  totalSteps: number
}

export const PersonalDetailsTemplate: React.FC<PropsWithChildren<PersonalDetailsTemplateProps>> = (
  props
) => {
  const { lead, USStep, step, isLoading } = props
  const { children, totalSteps, stepLabel, goBack, reversed } = props

  const { signupData } = useSignup()
  const isMobile = useWindowResize()
  const { t } = useTranslation()

  const showAppMarketLink = useMemo(() => {
    if (USStep) {
      return true
    }
    if (step === 1 && isMobile) {
      return false
    }
    if (step < 4) {
      return true
    }
    return false
  }, [USStep, isMobile, step])

  const description = useMemo(() => {
    if (USStep) {
      return null
    }
    if (step < 4) {
      return t(
        'Sign up.Your personal information is required in the next step to complete the application process. Your ID documents are needed for this step.'
      )
    }
    if (isTickmillUKType(lead?.tickmillCompany?.id)) {
      return t(
        'Sign up.Next up, we would like to know a little bit more about you. Please complete the'
      )
    }
    if (lead?.type.id === AccountType.Corporate) {
      return t(
        "Sign up.Next up, we like to know a little bit more about you. It'll take you about 20"
      )
    }
    return t(
      'Sign up.Next up, we would like to know a little bit more about you. It will take you about 10'
    )
  }, [USStep, lead?.tickmillCompany?.id, lead?.type.id, step, t])

  return (
    <SignupFormWrapper label={t('Sign up.Sign up')} onBackToPreviousStep={goBack}>
      <SignupStep
        subStep={
          USStep
            ? undefined
            : {
                current: step,
                total: totalSteps,
              }
        }
        label={stepLabel}
        section={SignupSections.PersonalDetails}
        accountTypeId={lead?.type?.id || signupData?.values.typeId}
      />
      <SignupWrapper>
        <Loading isLoading={isLoading ?? false}>
          <React.Fragment>
            <LeftSection
              title={t('Sign up.Personal Details')}
              hideTitle={USStep}
              hasQRCodeSection={showAppMarketLink}
              description={() => <Text isParagraph>{description}</Text>}
            />
            <section
              className={classNames(styles.formSection, styles.wrapper, {
                [styles.reversed]: reversed,
              })}
            >
              {children}
            </section>
          </React.Fragment>
        </Loading>
      </SignupWrapper>
    </SignupFormWrapper>
  )
}

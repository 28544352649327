import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { FieldValidator, getIn, useFormikContext } from 'formik'

import { CheckBox } from '../../global/checkBox/CheckBox'
import { SelectField } from '../../global/field/SelectField'
import { createFormField } from '../../global/formField/FormField'
import { createFormRadioField } from '../../global/formField/RadioGroupField'
import { RadioButton } from '../../global/radioButton/RadioButton'
import { useFetchRegulators } from '../../hooks/useFetchRegulators'
import { DropArrowDownIcon } from '../../icons/DropArrowDownIcon'
import { InfoIcon } from '../../icons/InfoIcon'
import {
  QuestionDto,
  TestQuestionWidgetType,
  TestSectionDto,
  TestSectionQuestionDto,
  TestSectionWidgetDto,
  WidgetOptions,
} from '../../model/TestSectionsDto'
import { CountryOfResidenceModal } from '../../pages/Signup/Pages/PersonalDetails/PersonalDetailsStep2/PersonalDetailsModal'
import { InfoCard, InfoCardLinkProps } from '../Popups/InfoCard/InfoCard'
import { TooltipIcon } from '../Popups/Tooltip/TooltipIcon'
import { Text } from '../Typography/Typography'

import styles from './TestFields.module.scss'

interface QuestionFieldFactoryProps<T> {
  section?: TestSectionDto
}

const FormRadioGroupField = createFormRadioField()
const FormField = createFormField()

export const QuestionFieldFactory = <T,>(props: QuestionFieldFactoryProps<T>): JSX.Element => {
  const { section } = props
  const context = useFormikContext<T>()
  // TODO: add doc upload support

  if (!section) {
    return <></>
  }

  return (
    <div className={styles.testWidget}>
      {section?.questions
        .filter(({ widget }) =>
          showConditionalQuestions(
            section?.questions,
            (context.values as { selectedAnswers: any })['selectedAnswers'],
            widget
          )
        )
        .map((question) => (
          <QuestionMapper<T> {...question} section={section} key={question.question.id} />
        ))}
    </div>
  )
}

interface QuestionMapperProps extends TestSectionQuestionDto {
  section: TestSectionDto
}

const QuestionMapper = <T,>(props: QuestionMapperProps) => {
  const { question } = props

  switch (question.widgetType) {
    case TestQuestionWidgetType.Select:
      return <SelectWidget<T> {...props} />
    case TestQuestionWidgetType.Radio:
      return <RadioWidget<T> {...props} />
    case TestQuestionWidgetType.Country:
      return <CountryWidget<T> {...props} />
    case TestQuestionWidgetType.Multiselect:
      return <CheckboxWidget<T> {...props} />
    default:
      return <TextWidget {...props} />
  }
}

const SelectWidget = <T,>(props: TestSectionQuestionDto & { section: TestSectionDto }) => {
  const { question, answers, widget } = props
  const name = `selectedAnswers.${widget.name}`

  const { t } = useTranslation()
  const context = useFormikContext<T>()

  const errorMessage = question?.errorMessage || t('Validation.Required')
  const options = widget.widgetOptions

  useEffect(() => {
    const value = getIn(context.values, name)
    const isMandatory = options?.isMandatory ?? props.isMandatory
    if (isMandatory && !value) {
      context.setFieldError(name, errorMessage)
    } else {
      context.setFieldError(name, undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, question, context.values, context.errors, context.touched])

  if (!widget?.name || !question?.name) {
    return null
  }

  const isMandatory = options?.isMandatory ?? props.isMandatory
  const value = getIn(context.values, name)

  return (
    <React.Fragment>
      <div className={styles.questionAnswerField}>
        <div>
          <QuestionName question={props.question} isMandatory={isMandatory} />
          <SelectField
            name={name}
            error={getIn(context, `errors.${widget.name}`)}
            options={answers.map((x) => ({ value: x.id, label: x.name }))}
            onChange={(v) => {
              context.setFieldValue(name, v.target.value)
              context.setFieldTouched(name, true)
            }}
            value={value}
            required={isMandatory}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

const RadioWidget = <T,>(props: TestSectionQuestionDto & { section: TestSectionDto }) => {
  const { question, answers, widget } = props

  const context = useFormikContext<T>()

  const { t } = useTranslation()
  const options = widget.widgetOptions

  if (widget?.name && question?.name) {
    const name = `selectedAnswers.${widget.name}`

    const isMandatory = options?.isMandatory ?? props.isMandatory
    const error = getIn(context.errors, name)
    const touched = getIn(context.touched, name)
    const value = getIn(context.values, name)

    return (
      <React.Fragment>
        <div
          className={classNames(styles.questionAnswerField, {
            [styles.radioError]: touched && error,
          })}
        >
          <QuestionName question={props.question} isMandatory={isMandatory} />
          <FormRadioGroupField
            name={name}
            value={value}
            required
            validate={(value: FieldValidator) => {
              if (!value) {
                return question?.errorMessage || t('Validation.Required')
              }
              return undefined
            }}
          >
            <div>
              {answers.map((x) => (
                <RadioButton
                  name={name}
                  label={x.name}
                  textSize='normal'
                  value={x.id}
                  checked={x.id === value}
                  key={x.id}
                  className={styles.marginBottom}
                />
              ))}
            </div>
          </FormRadioGroupField>
        </div>
      </React.Fragment>
    )
  }

  return null
}

const CheckboxWidget = <T,>(props: TestSectionQuestionDto & { section: TestSectionDto }) => {
  const { question, answers, widget } = props

  const context = useFormikContext<T>()
  const options = widget.widgetOptions

  if (widget?.name && question?.name) {
    const name = `selectedAnswers.${widget.name}`

    const isMandatory = options?.isMandatory ?? props.isMandatory
    const error = getIn(context.errors, name)
    const touched = getIn(context.touched, name)
    const values = context.values as { selectedAnswers: any; [key: string]: any }

    const handleMultiSelect = (answerId: string) => {
      if (Object.keys(values.selectedAnswers).includes(widget.name)) {
        if (values.selectedAnswers[widget.name].includes(answerId)) {
          context.setFieldValue('selectedAnswers', {
            ...values.selectedAnswers,
            [widget.name]: values.selectedAnswers[widget.name].filter(
              (x: string) => x !== answerId
            ),
          })
        } else {
          context.setFieldValue('selectedAnswers', {
            ...values.selectedAnswers,
            [widget.name]: [...values.selectedAnswers[widget.name], answerId],
          })
        }
      } else {
        context.setFieldValue('selectedAnswers', {
          ...values.selectedAnswers,
          [widget.name]: [answerId],
        })
      }
    }

    return (
      <React.Fragment>
        <div
          className={classNames(styles.questionAnswerField, {
            [styles.radioError]: touched && error,
          })}
        >
          <QuestionName question={props.question} isMandatory={isMandatory} />
          {answers.map((answer) => (
            <div className={styles.selectorBox}>
              <CheckBox
                error={getIn(context, `errors.${widget.name}`)}
                verticallyCentered
                onClick={() => handleMultiSelect(answer.id)}
                value={values.selectedAnswers[widget.name]?.includes(answer.id)}
              >
                <span>{answer.name}</span>
              </CheckBox>
            </div>
          ))}
        </div>
      </React.Fragment>
    )
  }

  return null
}

const TextWidget = <T,>(props: TestSectionQuestionDto & { section: TestSectionDto }) => {
  const { question, widget } = props
  const context = useFormikContext<T>()
  const options = getOptions(widget?.options)

  if (widget?.name && question?.name) {
    const name = `freeAnswers.${question.id}`
    const isMandatory = options.isMandatory ?? props.isMandatory

    return (
      <React.Fragment>
        <div className={styles.questionAnswerField}>
          <div>
            <QuestionName question={props.question} isMandatory={isMandatory} />
            <FormField
              name={name}
              required={isMandatory}
              error={getIn(context, `errors.${widget.name}`)}
            />
          </div>
        </div>
      </React.Fragment>
    )
  }

  return null
}

const CountryWidget = <T,>(props: TestSectionQuestionDto & { section: TestSectionDto }) => {
  const [isCountryModalOpen, setCountryModalOpen] = useState<boolean>()
  const context = useFormikContext<T>()
  const name = `freeAnswers.${props.question.id}`
  const value = getIn(context.values, name)
  const regulators = useFetchRegulators()
  const options = getOptions(props.widget.options)

  const isMandatory = options.isMandatory ?? props.isMandatory

  return (
    <React.Fragment>
      <div className={styles.questionAnswerField}>
        <div>
          <QuestionName question={props.question} isMandatory={isMandatory} />
          <FormField
            name={name}
            value={
              regulators.data
                .map((regulator) => regulator.country)
                .find((country) => country.id === value)?.name
            }
            rightIcon={<DropArrowDownIcon />}
            required={props.isMandatory}
            onChange={(event: React.ChangeEvent) => event.preventDefault()}
            onClick={() => setCountryModalOpen(true)}
          />
          {isCountryModalOpen && (
            <CountryOfResidenceModal
              title={props.question.name}
              regulators={regulators?.data}
              selectedCountry={value}
              onChangeOption={(option) => {
                context.setFieldValue(name, option.id)
                setCountryModalOpen(false)
              }}
              closeModal={() => setCountryModalOpen(false)}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

interface QuestionNameProps {
  question: QuestionDto
  isMandatory: boolean
}

const QuestionName: React.FC<QuestionNameProps> = (props) => {
  const { question, isMandatory } = props
  const { tooltip, infoCard } = question

  return (
    <div className={styles.textWrapper}>
      <Text className={styles.questionName}>
        {props.question.name}
        {isMandatory ? <span>*</span> : null}
        {tooltip && !infoCard && <TooltipIcon title={tooltip} />}
        {infoCard && (
          <InfoCard {...infoCard}>
            <InfoIcon />
          </InfoCard>
        )}
      </Text>
      {question.text !== question.name && <Text>{question.text}</Text>}
    </div>
  )
}

export const getOptions = (options?: string | null): WidgetOptions => {
  if (!options?.length) {
    return {}
  }
  try {
    return JSON.parse(options)
  } catch (e: unknown) {
    return {}
  }
}

export const showConditionalQuestions = (
  questions: TestSectionQuestionDto[],
  selectedAnswers: string[],
  widget?: TestSectionWidgetDto
): boolean => {
  const parsedAnswers = Object.values(selectedAnswers ?? {}).map((value) => value)

  const dependsOn = questions.find(
    (question) => question.widget.name === getOptions(widget?.options)?.depend
  )
  if (!dependsOn) {
    return true
  }

  const dependsOnValueWidgetData = getOptions(dependsOn.widget.options)
  const answersNeededToShow: string[] = []
  if (dependsOnValueWidgetData?.onchange) {
    dependsOnValueWidgetData.onchange
      .filter((change) => change.name === widget?.name)
      ?.forEach((onchange: { values?: string[] }) => {
        if (onchange.values) {
          answersNeededToShow.push(...onchange.values)
        }
      })
  }

  return parsedAnswers?.some((answer) => answersNeededToShow?.includes(answer))
}

export const getCleanedAnswers = (
  values: { [key: string]: string },
  sections: readonly TestSectionDto[]
): {
  [key: string]: string
} => {
  const cleanValues = { ...values }
  Object.keys(values).forEach((key) => {
    const section = sections.find((x) => x.questions.find((y) => y.question.widgetName === key))
    const question = section?.questions.find((x) => x.question.widgetName === key)
    const options = getOptions(question?.widget?.options)

    if (options?.hide && options?.depend) {
      if (options?.depend) {
        const parentQuestion = section?.questions.find(
          (x) => x.question.widgetName === options.depend
        )
        const parentQuestionOptions = getOptions(parentQuestion?.widget?.options)
        const matchingRule = parentQuestionOptions?.onchange?.find(
          (change) => change.name === question?.question.widgetName
        )
        if (!Object.values(values).some((val) => matchingRule?.values?.includes(val))) {
          delete cleanValues[key]
        }
      }
    }
  })

  return cleanValues
}

import { useCallback, useEffect } from 'react'

export const usePersistentWindow = (condition: () => boolean, callback?: () => void): void => {
  const handleBeforeUnload = useCallback(
    (event: BeforeUnloadEvent) => {
      if (condition()) {
        event.preventDefault()
        callback?.()
      }
    },
    [condition, callback]
  )

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [handleBeforeUnload])
}

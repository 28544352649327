import { JSXElementConstructor, ReactElement } from 'react'
import { Route } from 'react-router-dom'

import { useDocumentVerificationPath } from '../hooks/useDocumentVerificationPath'
import { Navigate } from '../navigation/custom-react-router-dom'

export interface RouteConfig {
  path?: string
  element?: JSX.Element
  condition?: boolean
  outlet?: JSX.Element
  locked?: boolean
  children?: RouteConfig[]
}

export const useRenderRoutes = (): ((
  routes: RouteConfig[]
) => ReactElement<any, string | JSXElementConstructor<any>>) => {
  const docVerificationPath = useDocumentVerificationPath()

  const renderRoutes = (routes: RouteConfig[]) => (
    <>
      {routes
        .filter((route) => route.condition !== false)
        .map(({ children, path, element, locked, outlet }) => {
          if (locked) {
            return <Route key={path} path={path} element={<Navigate to={docVerificationPath} />} />
          }
          if (children) {
            return (
              <Route key={path} path={path} element={outlet}>
                {renderRoutes(children)}
                <Route index element={element} />
              </Route>
            )
          }
          return <Route key={path} path={path} element={element} />
        })}
    </>
  )

  return renderRoutes
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { useLearningRoutesConditions } from '../Learning/LearningRoutes'
import { useSubscriptionRoutesConditions } from '../Subscriptions/SubscriptionsRoutes'
import { useToolsRoutesConditions } from '../Tools/ToolsRoutes'
import { useTraderRoomRoutesConditions } from '../Traders-Room/TradersRoomRoutes'
import { useProductReadContext } from '../global/context/ProductContext'
import { Tabs, defineTab } from '../global/tabs/Tabs'
import { useCurrentTab } from '../hooks/useCurrentTab'
import { useNavigate } from '../navigation/custom-react-router-dom'
import { useAccountAccessStatuses } from '../utils/AccountAccess/accountAccessStatuses'
import { isNoTabsPath } from '../utils/path'

export const DashboardTabsETD: React.FC = () => {
  const navigate = useNavigate()
  const currentTab = useCurrentTab('dashboard')

  const location = useLocation()
  const { t } = useTranslation()
  const { isTradersRoomVisible } = useTraderRoomRoutesConditions()
  const { isDefaultCFDProductType } = useProductReadContext()
  const { isAccountActivatedStatus } = useAccountAccessStatuses()
  const { isToolsVisible } = useToolsRoutesConditions()
  const { isLearningVisible } = useLearningRoutesConditions()
  const { isSubscriptionsVisible } = useSubscriptionRoutesConditions()

  function* getTabs() {
    if (isAccountActivatedStatus) {
      if (isTradersRoomVisible) {
        yield defineTab('traders-room', t('Tabs.Traders room'), 'traders-room/balances')
      }
      if (isSubscriptionsVisible) {
        yield defineTab('subscriptions', t('Tabs.Subscriptions'), 'subscriptions/trading-platforms')
      }
      if (isTradersRoomVisible) {
        yield defineTab('statements', t('Tabs.Statements'), 'statements/daily')
      }
      if (isToolsVisible) {
        yield defineTab('tools', t('Tabs.Tools'), 'tools/platform-vendors')
      }
      if (isLearningVisible) {
        yield defineTab('learning', t('Tabs.Learning'), 'learning/getting-started')
      }
    }
  }

  const tabs = [...getTabs()]

  if (tabs.length < 2 || isNoTabsPath(location.pathname) || isDefaultCFDProductType()) {
    return null
  }

  return (
    <Tabs
      tabs={tabs}
      currentTab={currentTab}
      onTabChanged={(tab) => {
        const path = tabs.find((x) => x.id === tab)
        if (path) {
          return navigate(`/dashboard/${path.path}`)
        }
        return navigate(`/dashboard/${tab}`)
      }}
    />
  )
}

import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { useArabicSessionLanguage } from '../../../global/context/SessionSettingsContext'
import { LevelFiresIcon } from '../../../icons/LevelFiresIcon'
import { MultiLevelCommissionDetailDto } from '../../../model/MultiLevelCommission'
import { Text, TextH3 } from '../../../ui/Typography/Typography'

import styles from './SpreadShareLevelBanners.module.scss'

interface SpreadShareLevelBannersProps {
  multiLevelData: MultiLevelCommissionDetailDto
}

export const SpreadShareLevelBanners: FC<SpreadShareLevelBannersProps> = (props) => {
  const { multiLevelData } = props
  const currentLevel = multiLevelData.levels.current.spreadShare
  const maxLevel = multiLevelData.levels.final.spreadShare

  const { t } = useTranslation()
  const isArabic = useArabicSessionLanguage()

  return (
    <div className={styles.spreadShareLevelBanners}>
      <div className={classNames(styles.box, styles.current)}>
        <TextH3 className={styles.percentage}>
          <span dir={isArabic ? 'rtl' : ''}>{currentLevel}</span>
          <span>%</span>
        </TextH3>
        <Text isParagraph>{t('IB.Dashboard.Current spread Share level')}</Text>
      </div>
      <div className={classNames(styles.box, styles.max)}>
        <LevelFiresIcon width={40} height={45} className={styles.maxIcon} />
        <TextH3 className={styles.percentage}>
          <span dir={isArabic ? 'rtl' : ''}>{maxLevel}</span>
          <span>%</span>
        </TextH3>
        <Text isParagraph>{t('IB.Dashboard.MAX spread Share level')}</Text>
      </div>
    </div>
  )
}

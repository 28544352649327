import { NameDto } from './NameDto'
import { PlatformTypeEnum } from './PlatformTypeEnum'

export interface PlatformInfoDto {
  platfromType: NameDto<PlatformTypeEnum>
  platformLinks: PlatformInfoPlatformLinkDto[]
  externalPlatformApplications: ExternalPlatformApplication[]
}

export interface PlatformInfoPlatformLinkDto {
  operatingSystem: NameDto
  order: number
  productLink: string
}

interface ExternalPlatformApplication {
  order: number
  name: string
  externalApplication: string
  icon: string
  productLink: string
  infoLink: string
  guides: {
    order: number
    icon: string
    guideLink: string
    name: string
  }[]
}

export enum PlatformExternalApplication {
  FXBLUE = 'FXBLUE',
  ACUITY = 'ACUITY',
}

export const isPlatformExternalApplicationFXBLUE = (
  platform: ExternalPlatformApplication
): boolean => platform.externalApplication === PlatformExternalApplication.FXBLUE

export const isPlatformExternalApplicationACUITY = (
  platform: ExternalPlatformApplication
): boolean => platform.externalApplication === PlatformExternalApplication.ACUITY

import React, { useCallback } from 'react'
import {
  LinkProps,
  NavigateFunction,
  NavigateOptions,
  NavigateProps,
  // eslint-disable-next-line
  Link as OriginalLink,
  // eslint-disable-next-line
  Navigate as OriginalNavigate,
  To,
  // eslint-disable-next-line
  useNavigate as originalUseNavigate,
  useLocation,
} from 'react-router-dom'

import { useSessionLanguage } from '../global/context/SessionSettingsContext'

const getToLink = (to: To, locale: string, currentPath: string): To => {
  const isExternal = (to: To): boolean => {
    if (typeof to === 'string') {
      return to.startsWith('http')
    } else if (typeof to === 'object' && to.pathname) {
      return to.pathname.startsWith('http')
    }

    return false
  }

  if (isExternal(to)) {
    return to
  } else if (typeof to === 'string') {
    if (to.startsWith(`/${locale}/`) || to === `/${locale}`) {
      return to
    } else if (to.startsWith('/')) {
      return `/${locale}${to}`
    } else {
      const basePath = currentPath.replace(/\/+$/, '')
      return `${basePath}/${to}`
    }
  } else if (typeof to === 'object' && to.pathname) {
    const { pathname } = to
    if (pathname.startsWith(`/${locale}/`) || pathname === `/${locale}`) {
      return to
    } else if (pathname.startsWith('/')) {
      return { ...to, pathname: `/${locale}${pathname}` }
    } else {
      const basePath = currentPath.replace(/\/+$/, '')
      return { ...to, pathname: `${basePath}/${pathname}` }
    }
  }

  return to
}

export const Link: React.FC<LinkProps> = ({ to, ...rest }) => {
  const locale = useSessionLanguage()
  const location = useLocation()

  return <OriginalLink to={getToLink(to, locale, location.pathname)} {...rest} />
}

export const Navigate: React.FC<NavigateProps> = ({ to, ...rest }) => {
  const locale = useSessionLanguage()
  const location = useLocation()

  return <OriginalNavigate to={getToLink(to, locale, location.pathname)} {...rest} />
}

export const useNavigate = (): NavigateFunction => {
  const navigate = originalUseNavigate()
  const locale = useSessionLanguage()
  const location = useLocation()

  const customNavigate = useCallback<NavigateFunction>(
    (to: To | number, options?: NavigateOptions) => {
      if (typeof to === 'number') {
        ;(navigate as (delta: number) => void)(to)
      } else {
        const newPath = getToLink(to, locale, location.pathname)
        navigate(newPath, options)
      }
    },
    [navigate, locale, location.pathname]
  )

  return customNavigate
}

import React, { PropsWithChildren, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'

import { NameDto } from '../../model/NameDto'
import { useWindowResize } from '../../utils/domUtils'
import { TextSmall } from '../Typography/Typography'

import styles from './SwitcherTabs.module.scss'

interface SwitcherTabsProps {
  tabs?: NameDto<string | number>[]
  handleChangeTab?: (tab: string | number) => void
  initialTab?: string | number | null
  allowEmpty?: boolean
}

export const SwitcherTabs: React.FC<SwitcherTabsProps> = (props) => {
  const { tabs = [], initialTab, allowEmpty, handleChangeTab } = props

  const { t } = useTranslation()
  const isMobile = useWindowResize()

  const [firstTab] = tabs
  const [activeTab, setActiveTab] = useState<string | number | null>(
    initialTab !== undefined ? initialTab : firstTab?.id
  )

  useEffect(() => {
    if (initialTab) {
      setActiveTab(initialTab)
    }
  }, [initialTab])

  const getTabName = (name: string, isMobile: boolean) => {
    if (isMobile) {
      switch (name) {
        case 'TickmillTrader':
          return t('Sign up.Tickmill Trader')
        default:
          return t(`${name}`)
      }
    } else {
      switch (name) {
        case 'MT4':
          return t('MetaTrader 4')
        case 'MT5':
          return t('MetaTrader 5')
        case 'TickmillTrader':
          return t('Sign up.Tickmill Trader')
        default:
          return t(`${name}`)
      }
    }
  }

  return (
    <div className={styles.switcherTabs}>
      {tabs?.map((tab) => {
        const tabName = getTabName(tab.name, isMobile)
        return (
          <div
            key={tab.id}
            onClick={() => {
              setActiveTab(tab.id)
              if (allowEmpty) {
                if (tab.id === activeTab) {
                  setActiveTab(null)
                }
              }
              if (handleChangeTab) {
                handleChangeTab(tab.id)
              }
            }}
            className={classNames(styles.tab, {
              [styles.active]: activeTab === tab.id,
            })}
          >
            <TextSmall>{tabName}</TextSmall>
          </div>
        )
      })}
    </div>
  )
}

interface TabsSwitcherProps<T> {
  items?: T[]
  renderItem: (item: T) => React.ReactNode
  fullWidth?: boolean
}

export const TabsSwitcher = <T,>(props: TabsSwitcherProps<T>) => {
  const { items = [], renderItem, fullWidth = false } = props

  return (
    <div
      className={classNames(styles.switcherTabs, {
        [styles.fullWidth]: fullWidth,
      })}
    >
      {items?.map((x) => renderItem(x))}
    </div>
  )
}

interface TabSwitcherProps {
  isActive: boolean
  onClick<T>(item: T): void
}

export const TabSwitcher: React.FC<PropsWithChildren<TabSwitcherProps>> = (props) => {
  const { isActive, children, onClick } = props

  return (
    <div
      onClick={onClick}
      className={classNames(styles.tab, {
        [styles.active]: isActive,
      })}
    >
      <TextSmall>{children}</TextSmall>
    </div>
  )
}

interface TabSwitcherLinkProps {
  to: string
  className: string
}

export const TabSwitcherLink: React.FC<PropsWithChildren<TabSwitcherLinkProps>> = (props) => {
  const { to, children, className } = props

  return (
    <NavLink to={to} className={className}>
      {({ isActive }) => (
        <div
          className={classNames(styles.tab, {
            [styles.active]: isActive,
          })}
        >
          <TextSmall className='is-flex'>{children}</TextSmall>
        </div>
      )}
    </NavLink>
  )
}

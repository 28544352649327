import { IconProps, iconColor } from './IconProps'

export function NavigationArrowRightDiagonalIcon(props: IconProps) {
  const size = props.size || 32

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.66675 16C2.66675 8.63619 8.63629 2.66666 16.0001 2.66666C23.3639 2.66666 29.3334 8.6362 29.3334 16C29.3334 23.3638 23.3639 29.3333 16.0001 29.3333C8.63628 29.3333 2.66675 23.3638 2.66675 16ZM13.4401 10.6667C12.851 10.6667 12.3734 11.1442 12.3734 11.7333C12.3734 12.3224 12.851 12.8 13.4401 12.8H17.6916L10.9792 19.5124C10.5626 19.929 10.5626 20.6043 10.9792 21.0209C11.3957 21.4375 12.0711 21.4375 12.4877 21.0209L19.2001 14.3085V18.56C19.2001 19.1491 19.6776 19.6267 20.2667 19.6267C20.8559 19.6267 21.3334 19.1491 21.3334 18.56V11.7333C21.3334 11.1442 20.8559 10.6667 20.2667 10.6667H13.4401Z'
        fill={iconColor(props)}
      />
    </svg>
  )
}

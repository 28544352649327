import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { AxiosError } from 'axios'

import { Loading } from '../../../../global/Loading/Loading'
import { useSessionEntity } from '../../../../global/context/EntityContext'
import { useProductReadContext } from '../../../../global/context/ProductContext'
import { useSessionLanguage } from '../../../../global/context/SessionSettingsContext'
import { useFormatNumber } from '../../../../hooks/useFormatNumber'
import {
  CreatePspToWalletDepositTransaction,
  denormalizeCreatePspToWalletDepositTransaction,
} from '../../../../model/CreatePspToWalletDepositTransaction'
import {
  MasterTransaction,
  isTransactionFormRedirect,
  isTransactionHistory,
  isTransactionSuccessful,
  isTransactionUnSuccessful,
  isTransactionUrlRedirect,
} from '../../../../model/MasterTransaction'
import { PaymentProvider } from '../../../../model/PaymentProviderDto'
import { isPaymentProviderBankType } from '../../../../model/PaymentProviderType'
import { TransactionStatus } from '../../../../model/TransactionStatus'
import { WalletDto, WalletTypeEnum } from '../../../../model/WalletDto'
import { useAccountReadContext } from '../../../../utils/AccountContextContext'
import { useApiClient } from '../../../../utils/ApiClient'
import { ClientApiClient } from '../../../../utils/clientApi'
import { prefixFilter } from '../../../../utils/filter.utils'
import { TransactionType } from '../../../../utils/transaction.utils'
import { useFetchOne } from '../../../../utils/useFetch'
import { useMutation } from '../../../../utils/useMutation'
import { useScrollToTop } from '../../../../utils/useScrollToTop'
import { WalletRestrictions, isRestricted } from '../../../../utils/wallet.utils'
import { WalletDepositForm, WalletDepositFormValues } from '../WalletDepositForm/WalletDepositForm'
import { useGetDepositCurrencyLimits } from '../WalletDepositForm/hooks/useGetDepositCurrencyLimits'

interface WalletDepositFetchProps {
  onCancel(): void
}

interface UseWalletDepositForm {
  data: WalletDto | undefined
  isLoading: boolean
}

export const useWalletDepositFetch = (props: WalletDepositFetchProps): UseWalletDepositForm => {
  const { walletId } = useParams<{ walletId?: string }>()

  const apiClient = useApiClient(ClientApiClient)

  const callback = useCallback(async () => {
    if (walletId) {
      return apiClient.getWalletById(walletId)
    }
  }, [walletId])

  const { data, isLoading } = useFetchOne(callback)

  useEffect(() => {
    if (isRestricted(WalletRestrictions.DEPOSIT, data?.restrictions)) {
      props.onCancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.restrictions])

  return { data, isLoading }
}

interface WalletDepositMutateProps {
  onSubmit(data: MasterTransaction, values: WalletDepositFormValues): void
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useWalletDepositMutate = (props: WalletDepositMutateProps) => {
  const { onSubmit } = props

  const locale = useSessionLanguage()

  const apiClient = useApiClient(ClientApiClient)

  const handleSuccess = async (data: MasterTransaction, values: WalletDepositFormValues) => {
    if (isTransactionHistory(data)) {
      onSubmit(data, values)
    }

    if (isPaymentProviderBankType(values.paymentProvider.providerCategory.id, data)) {
      onSubmit(data, values)
    }

    if (isTransactionUrlRedirect(data)) {
      onSubmit(data, values)
    }

    if (isTransactionFormRedirect(data)) {
      onSubmit(data, values)
    }

    if (isTransactionSuccessful(data)) {
      onSubmit(data, values)
    }

    if (isTransactionUnSuccessful(data)) {
      onSubmit(data, values)
    }
  }

  const handleError = (error: AxiosError<{ message: string }>) => {
    // TODO: add error notification
  }

  return useMutation(
    async (values: WalletDepositFormValues) => {
      return apiClient.transactionDeposit(
        denormalizeCreatePspToWalletDepositTransaction(
          createPspToWalletDepositTransaction(values),
          locale
        )
      )
    },
    {
      onSuccess: handleSuccess,
      onError: handleError,
    }
  )
}

interface WalletDepositPageProps {
  wallet?: WalletDto
  walletType: WalletTypeEnum

  onSubmit(data: MasterTransaction, values: WalletDepositFormValues): void

  onCancel(): void

  title?: string

  paymentProvider?: PaymentProvider
}

export const WalletDepositPage: React.FC<WalletDepositPageProps> = (props) => {
  const { wallet, walletType, onSubmit, onCancel, title, paymentProvider } = props
  const apiClient = useApiClient(ClientApiClient)
  const { account } = useAccountReadContext()
  const { isDefaultCFDProductType } = useProductReadContext()
  const isCFDProduct = isDefaultCFDProductType()
  const [conversionRate, setConversionRate] = useState(1)

  const { getDepositCurrencyLimits } = useGetDepositCurrencyLimits({ conversionRate })

  useScrollToTop()
  const { formatMoney } = useFormatNumber()

  const mutation = useWalletDepositMutate({ onSubmit })

  const handleSubmit = async (values: WalletDepositFormValues) => {
    await mutation.mutate(values)
  }

  const callback = useCallback(
    async () => {
      if (isCFDProduct) {
        return Promise.resolve({ items: [] })
      }

      return await apiClient.getTransactions({
        caller: 'ca',
        pageSize: 1,
        ca_search_ClientWalletType: prefixFilter({
          prefix: account?.id,
          value: WalletTypeEnum.ETD,
        }),
        ca_search_TransactionTypeId: TransactionType.WalletDeposit,
        ca_search_TransactionStateId: TransactionStatus.Completed,
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isCFDProduct]
  )

  const { data: transactions } = useFetchOne(callback)

  const handlePageExitConfirmation = () => {
    onCancel()
  }
  const { t } = useTranslation()
  const entity = useSessionEntity()

  const handleSetConversionRate = (rate: number) => {
    setConversionRate(rate)
  }

  return (
    <React.Fragment>
      {!mutation.isLoading && (
        <WalletDepositForm
          isETDFirstTimeDeposit={!!transactions?.items?.length}
          formatMoney={formatMoney}
          getDepositCurrencyLimits={getDepositCurrencyLimits}
          entity={entity}
          wallet={wallet}
          walletType={walletType}
          onSubmit={handleSubmit}
          onCancel={handlePageExitConfirmation}
          title={title}
          paymentProvider={paymentProvider}
          onSetConversionRate={handleSetConversionRate}
        />
      )}
      {mutation.isLoading && (
        <Loading isLoading={mutation.isLoading} showLoadingIcon text={t('Wallet.Depositing ...')} />
      )}
    </React.Fragment>
  )
}

export const createPspToWalletDepositTransaction = (
  values: WalletDepositFormValues
): CreatePspToWalletDepositTransaction => {
  return {
    fromPaymentProvider: {
      id: values.paymentProvider.id,
      currency: {
        id: values.paymentProvider.currency.id,
      },
    },
    toWallet: {
      id: values.wallet.id,
      currency: {
        id: values.wallet.currency.id,
      },
    },
    amount: values.amount,
    comment: values.comment,
    terms: values.terms.paymentOperations,
    form: values.paymentProvider.parameters.form,
    fields: values.fields,
    bonusCampaignId: values.bonusCampaignId,
  }
}

import React from 'react'
import { useTranslation } from 'react-i18next'

import { Sumsub } from '../../../../global/sumsub/Sumsub'
import { useAccountReadContext } from '../../../../utils/AccountContextContext'
import { SignupFormWrapper } from '../../Components/SignupFormWrapper'
import { SignupSections, SignupStep } from '../../Components/SignupStep'
import { SignupWrapper } from '../../Components/SignupWrapper'

import styles from './SumsubPage.module.scss'

export const SumsubSignupPage: React.FC = () => {
  const { account } = useAccountReadContext()

  const { t } = useTranslation()

  return (
    <SignupFormWrapper label={t('Sign up.Sign up')} cancelPath='/profile/verification'>
      <SignupStep accountTypeId={account?.type?.id} section={SignupSections.Sumsub} />
      <SignupWrapper>
        <React.Fragment>
          <section className={styles.sumsubBox}>
            <Sumsub />
          </section>
        </React.Fragment>
      </SignupWrapper>
    </SignupFormWrapper>
  )
}

import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IBSignUpPage } from '../../IntroducingBroker/SignUp/IBSignUpPage'
import { useIntroducingBrokerById } from '../../IntroducingBroker/hooks/useIntroducingBrokerById'
import { Loading } from '../../global/Loading/Loading'
import { SelectField } from '../../global/field/SelectField'
import { Modal } from '../../global/modal/Modal'
import { ScrollToIds } from '../../hooks/useScrollToElementIds'
import { ChevronRightIcon } from '../../icons/ChevronRightIcon'
import { useNavigate } from '../../navigation/custom-react-router-dom'
import { PageHeader } from '../../ui/Table/Header/PageHeader'
import { TextTiny } from '../../ui/Typography/Typography'
import { useWindowResize } from '../../utils/domUtils'
import { useIBPrograms } from '../../utils/useIBPrograms'
import { useIBReferralCodes } from '../../utils/useIBReferralCodes'
import { useScrollToTop } from '../../utils/useScrollToTop'
import { HowIBWorksModal } from './HowIBWorksModal'
import { HowMultiTierWorksModal } from './HowMultiTierWorksModal'

import styles from './IntroducingBroker.module.scss'

export const IntroducingBrokerPage = (): JSX.Element => {
  useScrollToTop()
  const [howIBWorks, setHowIBWorks] = useState(false)
  const [howMIBWorks, setHowMIBWorks] = useState(false)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { data: introducingBroker } = useIntroducingBrokerById()
  const { defaultProgram, multiTierProgram } = useIBPrograms()
  const { referralCodes, ibCode, mtCode, isLoading } = useIBReferralCodes()
  const [selectedCode, setSelectedCode] = useState(ibCode || mtCode)
  const isMobile = useWindowResize()
  const [showThankYouPage, setShowThankYouPage] = useState(false)

  useEffect(() => setSelectedCode(ibCode || mtCode), [ibCode, mtCode])
  const canRegister = useMemo(
    () => ((multiTierProgram && !mtCode) || (defaultProgram && !ibCode)) ?? true,
    [ibCode, mtCode, multiTierProgram, defaultProgram]
  )

  const ibSignupFormSubmitted = localStorage.getItem('ibSignupFormSubmitted') === 'true'
  const isInviteSectionVisible =
    !!referralCodes.length && !ibSignupFormSubmitted && !showThankYouPage

  return (
    <>
      {howIBWorks && (
        <Modal
          closeModal={() => setHowIBWorks(false)}
          render={({ closeModal }) => <HowIBWorksModal onCancel={closeModal} ibCode={ibCode} />}
        />
      )}
      {howMIBWorks && (
        <Modal
          closeModal={() => setHowMIBWorks(false)}
          render={({ closeModal }) => (
            <HowMultiTierWorksModal onCancel={closeModal} ibCode={mtCode} />
          )}
        />
      )}
      <Loading showLoadingIcon isLoading={isLoading}>
        {isInviteSectionVisible && (
          <>
            {!isMobile && (
              <PageHeader
                title={t('IB.Introducing Broker Program')}
                id={ScrollToIds.IBProfileHeader}
              />
            )}
            <div className={styles.container}>
              <section className={styles.inviteSection}>
                <span>
                  {t(
                    'IB.Invite traders to join Tickmill by using your personal link and get a permanent commission on every trade they make.'
                  )}
                </span>
                <SelectField
                  options={referralCodes.map(({ code }) => ({
                    value: code,
                    label: code,
                  }))}
                  allowCopy
                  className={styles.selector}
                  onChange={(v) => setSelectedCode(v.target.value)}
                  value={selectedCode}
                  disabled={referralCodes.length < 2}
                  label={t('IB.Your Referral Code')}
                />

                {selectedCode === ibCode && (
                  <div className={styles.link} onClick={() => setHowIBWorks(true)}>
                    {t('IB.How IB works?')}
                  </div>
                )}
                {selectedCode === mtCode && (
                  <div className={styles.link} onClick={() => setHowMIBWorks(true)}>
                    {t('IB.MultiTier.How Multi-tier works')}?
                  </div>
                )}
              </section>
              <section className={styles.linkSection}>
                <div onClick={() => navigate('/dashboard/introducing-broker/legal-documents')}>
                  <p className={styles.documents}>
                    {t('Profile.Legal Documents')}{' '}
                    <span className='icon is-right has-cursor-pointer'>
                      <ChevronRightIcon />
                    </span>
                  </p>
                  <TextTiny isParagraph>{t('Profile.All the legal documents')}</TextTiny>
                </div>
                {introducingBroker?.loyaltyProgramEnabled && (
                  <div onClick={() => navigate('/dashboard/introducing-broker/loyalty-program')}>
                    <p className={styles.documents}>
                      {t('Profile.Loyalty Program')}{' '}
                      <span className='icon is-right has-cursor-pointer'>
                        <ChevronRightIcon />
                      </span>
                    </p>
                    <TextTiny isParagraph>
                      {t('Level')}&nbsp;{introducingBroker?.loyaltyDetails.currentTier?.name}&nbsp;(
                      {introducingBroker?.loyaltyDetails.loyaltyLots}&nbsp;{t('Profile.points')})
                    </TextTiny>
                  </div>
                )}
                <div onClick={() => navigate('/dashboard/introducing-broker/referral-materials')}>
                  <p className={styles.documents}>
                    {t('Profile.Promo Materials')}{' '}
                    <span className='icon is-right has-cursor-pointer'>
                      <ChevronRightIcon />
                    </span>
                  </p>
                  <TextTiny isParagraph>{t('Profile.Banners, logos')}</TextTiny>
                </div>
              </section>
            </div>
          </>
        )}
        {(canRegister || ibSignupFormSubmitted || showThankYouPage) && (
          <IBSignUpPage
            ibSignupFormSubmitted={ibSignupFormSubmitted}
            onSetShowThanYouPage={setShowThankYouPage}
            hideHeader={isInviteSectionVisible}
          />
        )}
      </Loading>
    </>
  )
}

import React, { ButtonHTMLAttributes, FC, PropsWithChildren } from 'react'
import classNames from 'classnames'

import styles from './IconButton.module.scss'

enum ButtonAppearance {
  Selectable = 'selectable',
  Hoverable = 'hoverable',
  Secondary = 'secondary',
  Ghost = 'ghost',
}

export interface IconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: 'S' | 'M' | 'L'
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  fullWidth?: boolean
  className?: string
  appearance?: 'selectable' | 'hoverable' | 'secondary' | 'ghost'
  isNew?: boolean
}

const IconButton: FC<PropsWithChildren<IconButtonProps>> & {
  New: FC<PropsWithChildren<IconButtonProps>>
} = ({ children, fullWidth, className, appearance, isNew, size = 'M', ...props }) => {
  return (
    <button
      {...props}
      type='button'
      className={classNames(
        'button',
        styles.btn,
        {
          [styles.selectable]: appearance === ButtonAppearance.Selectable,
          [styles.hoverable]: appearance === ButtonAppearance.Hoverable,
          [styles.secondary]: appearance === ButtonAppearance.Secondary,
          [styles.ghost]: appearance === ButtonAppearance.Ghost,
          [styles.disabled]: props.disabled,
          [styles.new]: isNew,
          [styles[`size-${size}`]]: size,
          [styles.fullWidth]: fullWidth,
        },
        className
      )}
    >
      {children}
    </button>
  )
}

IconButton.New = (props) => {
  return <IconButton {...props} isNew />
}

export default IconButton

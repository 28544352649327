import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { TwoFactorAuthWithdrawalWarningModal } from '../../TwoFactorAuth/TwoFactorAuthWithdrawalWarningModal'
import { Loading } from '../../global/Loading/Loading'
import { useProductReadContext } from '../../global/context/ProductContext'
import { useFormatNumber } from '../../hooks/useFormatNumber'
import { useTradingAccountStatus } from '../../hooks/useTradingAccountStatus'
import { useWithdrawalPageNavigation } from '../../hooks/useWithdrawalPageNavigation'
import { ConversionIcon } from '../../icons/ConversionIcon'
import { DepositIcon } from '../../icons/DepositIcon'
import { TransferIcon } from '../../icons/TransferIcon'
import { WithdrawIcon } from '../../icons/WithdrawIcon'
import { TotalWalletBalanceDto } from '../../model/TotalWalletBalanceDto'
import { WalletTypeEnum } from '../../model/WalletDto'
import { useNavigate } from '../../navigation/custom-react-router-dom'
import { FirstTimeGuideCarousel } from '../../ui/FirstTimeGuideCarousel/FirstTimeGuideCarousel'
import { Grid } from '../../ui/Layouts/Grid/Grid'
import { PageHeader } from '../../ui/Table/Header/PageHeader'
import { useAccountActionAccessCheck } from '../../utils/AccountAccess/accountAccessCheck'
import { useAccountReadContext } from '../../utils/AccountContextContext'
import { useApiClient } from '../../utils/ApiClient'
import { useWalletsReadContext } from '../../utils/UserWalletContext'
import { ClientApiClient } from '../../utils/clientApi'
import { useFetchOne } from '../../utils/useFetch'
import { useScrollToTop } from '../../utils/useScrollToTop'
import {
  WalletRestrictions,
  isDepositEnabledFromAction,
  isRestricted,
} from '../../utils/wallet.utils'
import { Card } from './Card'

import styles from './TransactionsPage.module.scss'

interface TransactionCardsProps {
  areWalletsLoading: boolean
  walletWithdrawLoadingId: string
  canDeposit: boolean
  handleWithdrawalNavigation: () => Promise<void>
}

const useTransactionsFetch = () => {
  const { account } = useAccountReadContext()
  const apiClient = useApiClient(ClientApiClient)

  const callback = useCallback(async () => {
    if (account?.displayCurrency?.id) {
      return await apiClient.getTotalBalanceByCurrency(account.displayCurrency.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const { data, isLoading } = useFetchOne(callback)

  return { data, isLoading }
}

const useTransactionsData = (data?: TotalWalletBalanceDto) => {
  const { t } = useTranslation()
  const { isDefaultCFDProductType } = useProductReadContext()
  const { formatMoney } = useFormatNumber()

  const balance = data?.currency?.id ? `≈${formatMoney(data.balance, data.currency.id)}` : ''

  const description = !isDefaultCFDProductType()
    ? t('Transactions.Wallets balances', {
        balance,
      })
    : undefined

  return {
    balance,
    description,
  }
}

export const TransactionsPage: React.FC = () => {
  useScrollToTop()
  const { t } = useTranslation()
  const {
    handleWithdrawalNavigation,
    twoFactorWarningModalState,
    onDismissModal,
    getWithdrawalPath,
    walletWithdrawLoadingId,
  } = useWithdrawalPageNavigation()
  const { isDefaultCFDProductType } = useProductReadContext()
  const apiClient = useApiClient(ClientApiClient)
  const walletType = isDefaultCFDProductType() ? WalletTypeEnum.CFD : WalletTypeEnum.ETD

  const walletsCallback = useCallback(async () => {
    return await apiClient.getWallets({ walletType })
  }, [apiClient, walletType])

  const { data: wallets = [], isLoading: areWalletsLoading } = useFetchOne(walletsCallback)

  const hasCanDepositWallet = wallets.some((wallet) => {
    return isDepositEnabledFromAction(wallet.depositAction)
  })
  const hasUnrestrictedWallet = wallets.some(
    (wallet) => !isRestricted(WalletRestrictions.DEPOSIT, wallet.restrictions)
  )
  const canDeposit = hasCanDepositWallet || hasUnrestrictedWallet

  const transactionCardProps = {
    handleWithdrawalNavigation,
    walletWithdrawLoadingId,
    areWalletsLoading,
    canDeposit,
  }

  return (
    <>
      {twoFactorWarningModalState.visible && (
        <TwoFactorAuthWithdrawalWarningModal
          closeModal={onDismissModal}
          getWithdrawalPath={getWithdrawalPath}
        />
      )}
      <FirstTimeGuideCarousel />
      <PageHeader title={t('Transactions.Transactions')} />
      {isDefaultCFDProductType() && <TransactionsCardsCFD {...transactionCardProps} />}
      {!isDefaultCFDProductType() && <TransactionsCardsETD {...transactionCardProps} />}
    </>
  )
}

const TransactionsCardsCFD = ({
  areWalletsLoading,
  canDeposit,
  handleWithdrawalNavigation,
  walletWithdrawLoadingId,
}: TransactionCardsProps) => {
  const { t } = useTranslation()
  const {
    hasNegativeBalance,
    isLoading: negativeBalanceLoading,
    hasActiveTradingAccounts,
  } = useTradingAccountStatus()

  const { data, isLoading } = useTransactionsFetch()
  const { description } = useTransactionsData(data)
  const navigate = useNavigate()
  const { checkAccountTradersRoomAccess, checkAccountOpenAccountAccess } =
    useAccountActionAccessCheck()

  const { wallets = [] } = useWalletsReadContext()

  const handleWithdrawalClick = () => {
    checkAccountTradersRoomAccess(() => {
      handleWithdrawalNavigation()
    })
  }

  const handleTransferClick = () => {
    if (!hasActiveTradingAccounts && !!wallets.length) {
      return navigate(`/dashboard/traders-room/trading-accounts/new`)
    }
    checkAccountOpenAccountAccess(() => {
      navigate('/dashboard/traders-room/wallets/transfer')
    })
  }

  return (
    <Loading showLoadingIcon isLoading={isLoading || areWalletsLoading}>
      <Grid maxCardWidth={300} mobileSameSize>
        <Card
          title={t('Transactions.Deposit')}
          renderDescription={() => (
            <p className={styles.descriptionCFD}>{t('Transactions.Move Funds')}</p>
          )}
          buttonText={t('Transactions.Start Deposit')}
          appearance='primary'
          lockAction={!canDeposit}
          to='/dashboard/traders-room/wallets/deposit'
          additionalDescription={description}
          icon={<DepositIcon size={80} />}
          buttonInfoText={t('Temporarily unavailable')}
        />
        <Card
          title={t('Transactions.Withdraw')}
          renderDescription={() => (
            <p className={styles.descriptionCFD}>{t('Transactions.Move funds wallet bank')}</p>
          )}
          buttonText={t('Transactions.Start Withdrawal')}
          onClick={handleWithdrawalClick}
          additionalDescription={description}
          buttonInfoText={t(
            'Wallet.Withdrawal is currently not available because your trading account'
          )}
          lockAction={hasNegativeBalance || negativeBalanceLoading}
          icon={<WithdrawIcon size={80} />}
          isLoading={!!walletWithdrawLoadingId}
        />
        <Card
          title={t('Transactions.Transfer Funds')}
          renderDescription={() => (
            <p className={styles.descriptionCFD}>{t('Transactions.Move funds between')}</p>
          )}
          buttonText={t('Transactions.Start Transfer')}
          onClick={handleTransferClick}
          icon={<TransferIcon size={80} />}
        />
      </Grid>
    </Loading>
  )
}

const TransactionsCardsETD = ({
  areWalletsLoading,
  canDeposit,
  handleWithdrawalNavigation,
}: TransactionCardsProps) => {
  const { t } = useTranslation()

  const { data, isLoading } = useTransactionsFetch()
  const { description } = useTransactionsData(data)

  return (
    <Loading showLoadingIcon isLoading={isLoading || areWalletsLoading}>
      <Grid mobileSameSize>
        <Card
          bottomSpaced
          title={t('Transactions.Deposit')}
          renderDescription={() => (
            <p className={styles.descriptionETD}>{t('Transactions.Move Funds')}</p>
          )}
          buttonText={t('Transactions.Start Deposit')}
          appearance='primary'
          to='/dashboard/traders-room/wallets/deposit'
          additionalDescription={description}
          icon={<DepositIcon size={80} />}
          lockAction={!canDeposit}
          buttonInfoText={t('Temporarily unavailable')}
        />
        <Card
          bottomSpaced
          title={t('Transactions.Withdraw')}
          renderDescription={() => (
            <p className={styles.descriptionETD}>{t('Transactions.Move funds wallet bank')}</p>
          )}
          buttonText={t('Transactions.Start Withdrawal')}
          onClick={handleWithdrawalNavigation}
          additionalDescription={description}
          icon={<WithdrawIcon size={80} />}
        />
        <Card
          bottomSpaced
          title={t('Transactions.Transfer Funds')}
          renderDescription={() => (
            <p className={styles.descriptionETD}>{t('Transactions.Move funds between')}</p>
          )}
          buttonText={t('Transactions.Start Transfer')}
          to='/dashboard/traders-room/wallets/transfer'
          icon={<TransferIcon size={80} />}
        />
        <Card
          bottomSpaced
          title={t('Transactions.Conversion')}
          renderDescription={() => (
            <p className={styles.descriptionETD}>{t('Transactions.Convert currencies')}</p>
          )}
          buttonText={t('Transactions.Start Conversion')}
          to='/dashboard/traders-room/transactions/conversion'
          icon={<ConversionIcon size={80} />}
        />
      </Grid>
    </Loading>
  )
}

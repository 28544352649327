import { useTranslation } from 'react-i18next'

import { BackIcon } from '../../icons/BackIcon'
import { ForwardIcon } from '../../icons/ForwardIcon'
import { Text } from '../../ui/Typography/Typography'
import { Button } from '../button/Button'
import { useArabicSessionLanguage } from '../context/SessionSettingsContext'

import styles from './WalletSetupIndicator.module.scss'

export interface WalletSetupIndicatorProps {
  isButtonDisabled: boolean
  onSetupComplete: () => void
}

export function WalletSetupIndicator(props: WalletSetupIndicatorProps): JSX.Element {
  const { t } = useTranslation()
  const { onSetupComplete, isButtonDisabled } = props
  const isArabic = useArabicSessionLanguage()

  return (
    <div className={styles.container}>
      <div className={styles.setupStatusText}>
        <Text isParagraph>{t('Setting up your TICKMILL account')}</Text>
        <Text>{t('Please wait a moment')}</Text>
      </div>

      <Button
        className={styles.nextButton}
        size='M'
        appearance='primary'
        disabled={isButtonDisabled}
        renderRightIcon={() => (
          <span className='is-flex is-align-items-center'>
            {isArabic ? <BackIcon inverse /> : <ForwardIcon inverse />}
          </span>
        )}
        onClick={onSetupComplete}
      >
        {t('Next')}
      </Button>
    </div>
  )
}

import { IconProps } from './IconProps'

export const Step2Icon = (props: IconProps): JSX.Element => {
  const width = props.width || '40'
  const height = props.height || '40'
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='40' height='40' rx='6' fill='#CC0000' />
      <path
        d='M24.52 28H14.92V26.36L18.6 22.62C19.32 21.8867 19.92 21.2533 20.4 20.72C20.88 20.1733 21.24 19.6533 21.48 19.16C21.72 18.6533 21.84 18.1067 21.84 17.52C21.84 16.7867 21.6267 16.24 21.2 15.88C20.7733 15.5067 20.22 15.32 19.54 15.32C18.8867 15.32 18.3 15.4467 17.78 15.7C17.26 15.94 16.72 16.28 16.16 16.72L15.02 15.3C15.4067 14.9667 15.82 14.6667 16.26 14.4C16.7133 14.1333 17.2133 13.92 17.76 13.76C18.32 13.6 18.9333 13.52 19.6 13.52C20.5067 13.52 21.2867 13.68 21.94 14C22.5933 14.3067 23.0933 14.7467 23.44 15.32C23.8 15.8933 23.98 16.5667 23.98 17.34C23.98 17.9133 23.8933 18.4533 23.72 18.96C23.5467 19.4533 23.2933 19.94 22.96 20.42C22.64 20.9 22.2467 21.3867 21.78 21.88C21.3133 22.3733 20.8 22.8933 20.24 23.44L17.58 26.02V26.12H24.52V28Z'
        fill='white'
      />
    </svg>
  )
}

import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import {
  endOfDay,
  endOfMonth,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfYear,
  subDays,
  subMonths,
  subYears,
} from 'date-fns'

import { TabProps, Tabs } from '../../../global/tabs/Tabs'

import styles from './RangeOptions.module.scss'

export interface RangeOption {
  label: string
  id: string
  value: [Date, Date]
}

interface RangeOptionsProps {
  onRangeSelect: (range: [Date, Date]) => void
  startDate?: Date
  endDate?: Date
  className?: string
  options?: RangeOption[]
}

export const RangeOptions: FC<RangeOptionsProps> = (props) => {
  const { onRangeSelect, startDate, endDate, className } = props

  const { t } = useTranslation()

  const options: RangeOption[] = props.options ?? [
    {
      label: t('Calendar.Today'),
      id: 'today',
      value: [startOfDay(new Date()), endOfDay(new Date())],
    },
    {
      label: t('Calendar.Week'),
      id: 'week',
      value: [startOfDay(subDays(new Date(), 7)), endOfDay(new Date())],
    },
    {
      label: t('Calendar.Last 30 Days'),
      id: 'last-30-days',
      value: [startOfDay(subDays(new Date(), 30)), endOfDay(new Date())],
    },
    {
      label: t('Calendar.This Month'),
      id: 'this-month',
      value: [startOfMonth(new Date()), endOfMonth(new Date())],
    },
    {
      label: t('Calendar.Last Month'),
      id: 'last-month',
      value: [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))],
    },
    {
      label: t('Calendar.Last Year'),
      id: 'last-year',
      value: [startOfYear(subYears(new Date(), 1)), endOfYear(subYears(new Date(), 1))],
    },
  ]

  const currentTab = useMemo<string | undefined>(() => {
    const selectedOption = options.find((option) => {
      return (
        option.value[0].getTime() === startDate?.getTime() &&
        option.value[1].getTime() === endDate?.getTime()
      )
    })
    return selectedOption?.id
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate])

  const handleTabChanged = (id: string) => {
    const option = options.find((option) => option.id === id)

    if (option) {
      onRangeSelect(option.value)
    }
  }

  return (
    <div className={classNames(styles.rangeOptions, className)}>
      <Tabs
        wrapperClassName={styles.tabsWrapper}
        tabs={options.map<TabProps>(({ id, label: name }) => ({ id, name, className: styles.tab }))}
        currentTab={currentTab}
        appearance='label-large'
        onTabChanged={handleTabChanged}
      />
    </div>
  )
}

import React from 'react'

import IconButton from '../../global/iconButton/IconButton'
import { Modal } from '../../global/modal/Modal'
import { CloseIcon } from '../../icons/CloseIcon'

import styles from './SecuritySettingsInfoModal.module.scss'

interface SecuritySettingsInfoModalProps {
  isOpen: boolean
  onClose: () => void
  text: string
}

export const SecuritySettingsInfoModal: React.FC<SecuritySettingsInfoModalProps> = ({
  isOpen,
  onClose,
  text,
}) => {
  if (!isOpen) {
    return <></>
  }

  return (
    <Modal
      pullDown
      closeModal={onClose}
      render={() => (
        <div className={styles.modalBody}>
          {text}
          <IconButton onClick={onClose} className={styles.closeButton}>
            <CloseIcon />
          </IconButton>
        </div>
      )}
    />
  )
}

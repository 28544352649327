import React, { PropsWithChildren, useRef, useState } from 'react'
import classNames from 'classnames'
import { motion } from 'framer-motion'

import { debounce } from '../../../utils/debounce'
import { Direction } from '../../common/types'

import styles from './Bubble.module.scss'

const variants = {
  open: {
    opacity: 1,
    scale: 1,
    transition: {
      type: 'spring',
      bounce: 0,
      duration: 0.5,
    },
  },
  closed: {
    opacity: 0,
    scale: 0.95,
    transition: {
      type: 'spring',
      bounce: 0,
      duration: 0.25,
    },
  },
}

export interface BubbleProps extends PropsWithChildren {
  component?: React.ReactNode
  direction?: Direction
  disabled?: boolean
  isOpened?: boolean
  className?: string
  fullWidth?: boolean
}

interface HoverableDivElement extends HTMLDivElement {
  hovering: boolean
}

export const Bubble: React.FC<BubbleProps> = (props) => {
  const { component, isOpened, direction = 'top', fullWidth, children, disabled, className } = props
  const ref = useRef<HoverableDivElement>(null)
  const [active, setActive] = useState(false)

  const showTip = () => {
    if (ref.current) {
      ref.current.hovering = true
    }
    if (!disabled) {
      setActive(true)
    }
  }

  const debouncedHideTip = debounce(() => {
    if (!disabled && ref.current && !ref.current.hovering) {
      setActive(false)
    }
  }, 300)

  const hideTip = () => {
    if (ref.current) {
      ref.current.hovering = false
    }
    debouncedHideTip()
  }

  return (
    <div
      ref={ref}
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
      className={classNames(styles.bubbleWrapper, className, {
        [styles.fullWidth]: fullWidth,
      })}
    >
      {component}
      <motion.div
        animate={isOpened ?? active ? 'open' : 'closed'}
        initial='closed'
        variants={variants}
        className={classNames(styles.bubble, styles[direction], {
          [styles.active]: isOpened ?? active,
        })}
      >
        {children}
      </motion.div>
    </div>
  )
}

import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { useDocumentVerificationPath } from '../../hooks/useDocumentVerificationPath'
import { IDdocsIcon } from '../../icons/IDdocsIcon'
import { UploadIcon } from '../../icons/UploadIcon'
import { AccountDetailedStatus } from '../../model/AccountDetailedStatus'
import { useNavigate } from '../../navigation/custom-react-router-dom'
import { Alert } from '../../ui/Alert/Alert'
import { isDepositBeforeKYC } from '../../utils/AccountAccess/accountKYCStatuses'
import { useAccountReadContext } from '../../utils/AccountContextContext'
import { useWindowResize } from '../../utils/domUtils'
import { isSignUpPath } from '../../utils/path'
import { useLocallyPersistedState } from '../../utils/useStorage'
import { Button } from '../button/Button'

import styles from './StatusBanner.module.scss'

const useBannerVisibility = () => {
  const location = useLocation()

  const [bannerOpen, setBannerOpen] = useLocallyPersistedState('statusBannerOpen', true)
  const { account } = useAccountReadContext()

  const isSignUpRoute = isSignUpPath(location.pathname)
  const isRedirectPath = location.pathname.includes('redirect')
  const isEligibleAccountStatus =
    (account &&
      [
        AccountDetailedStatus.AppropriatenessTestSubmitted,
        AccountDetailedStatus.PendingDocumentVerification,
      ].includes(account.status.id)) ??
    false

  const isBannerVisible = useMemo(
    () =>
      bannerOpen &&
      !isSignUpRoute &&
      !isRedirectPath &&
      isEligibleAccountStatus &&
      isDepositBeforeKYC(account),
    [bannerOpen, isSignUpRoute, isRedirectPath, isEligibleAccountStatus, account]
  )

  return { isBannerVisible, setBannerOpen }
}

export const StatusBanner: React.FC = () => {
  const isMobile = useWindowResize()
  const navigate = useNavigate()
  const { isBannerVisible, setBannerOpen } = useBannerVisibility()
  const docVerificationPath = useDocumentVerificationPath()

  const { t } = useTranslation()

  const handleNavigate = () => {
    navigate(docVerificationPath)
  }

  const handleClose = () => {
    setBannerOpen(false)
  }

  if (!isBannerVisible) {
    return null
  }

  return (
    <div className={styles.statusBanner}>
      <StatusBannerUpload
        text={
          isMobile
            ? t('Verification required')
            : t('To gain full access to your Client Area, please upload your documents')
        }
        onClick={handleNavigate}
        onClose={handleClose}
      />
    </div>
  )
}

interface WarningNoteProps {
  text: string
  onClick(): void
  onClose(): void
}

const StatusBannerUpload: React.FC<WarningNoteProps> = (props) => {
  const { text, onClick, onClose } = props

  const { t } = useTranslation()

  return (
    <Alert
      text={text}
      renderLeft={<IDdocsIcon color='warning' />}
      renderRight={
        <Button
          size='XS'
          appearance='primary'
          renderRightIcon={() => <UploadIcon color='alwaysWhite' />}
          onClick={onClick}
        >
          {t('Profile.Upload')}
        </Button>
      }
      onClose={onClose}
    />
  )
}

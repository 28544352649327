import React, { FC, useMemo } from 'react'
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { format, getMonth } from 'date-fns'

import { NavigationArrowLeftIcon } from '../../../icons/NavigationArrowLeftIcon'
import { NavigationArrowRightIcon } from '../../../icons/NavigationArrowRightIcon'
import { Text } from '../../../ui/Typography/Typography'
import { getMonthOptions } from '../../../utils/date.utils'
import { YEAR_ITEM_NUMBER } from './Calendar'

import styles from './CalendarHeader.module.scss'

export interface CalendarHeaderProps extends ReactDatePickerCustomHeaderProps {
  showMonthYearPicker?: boolean
  showYearPicker?: boolean
}

export const CalendarHeader: FC<CalendarHeaderProps> = (props) => {
  const {
    decreaseMonth,
    increaseMonth,
    decreaseYear,
    increaseYear,
    changeYear,
    showMonthYearPicker,
    showYearPicker,
    monthDate,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
    prevYearButtonDisabled,
    nextYearButtonDisabled,
  } = props

  const isPrevDisabled = prevMonthButtonDisabled && prevYearButtonDisabled
  const isNextDisabled = nextMonthButtonDisabled && nextYearButtonDisabled

  const { t } = useTranslation()

  const [startYear, endYear] = useMemo<number[]>(() => {
    const dateYear = monthDate.getFullYear()
    let rangeStart = dateYear
    let count = 0
    while (!Number.isInteger((dateYear - count) / YEAR_ITEM_NUMBER)) {
      count++
    }
    rangeStart = dateYear - count + 1
    return [rangeStart, rangeStart + YEAR_ITEM_NUMBER - 1]
  }, [monthDate])

  const label = useMemo(() => {
    if (showMonthYearPicker) {
      return format(monthDate, 'yyyy')
    }

    if (showYearPicker) {
      return `${startYear} - ${endYear}`
    }

    const monthIndex = getMonth(monthDate)
    const monthLabel = getMonthOptions(t).find((month) => month.id === monthIndex + 1)?.label
    const year = monthDate.getFullYear()

    return `${monthLabel} ${year}`
  }, [monthDate, showMonthYearPicker, showYearPicker, startYear, endYear, t])

  const onPrevClick = () => {
    if (isPrevDisabled) {
      return
    }

    if (showMonthYearPicker) {
      decreaseYear()
    }

    if (showYearPicker) {
      changeYear(startYear - YEAR_ITEM_NUMBER)
    }

    decreaseMonth()
  }

  const onNextClick = () => {
    if (isNextDisabled) {
      return
    }

    if (showMonthYearPicker) {
      increaseYear()
    }

    if (showYearPicker) {
      changeYear(endYear + 1)
    }

    increaseMonth()
  }

  return (
    <div className={styles.calendarHeader}>
      <button
        className={classNames(styles.calendarNavigation, {
          [styles.disabled]: isPrevDisabled,
        })}
        onClick={onPrevClick}
      >
        <NavigationArrowLeftIcon />
      </button>
      <Text>{label}</Text>
      <button
        className={classNames(styles.calendarNavigation, {
          [styles.disabled]: nextMonthButtonDisabled,
        })}
        onClick={onNextClick}
      >
        <NavigationArrowRightIcon />
      </button>
    </div>
  )
}

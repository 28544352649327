import React, { PropsWithChildren, ReactNode } from 'react'
import classNames from 'classnames'

import { FireIcon } from '../../icons/FireIcon'
import { RadioButtonOffIcon } from '../../icons/RadioButtonOffIcon'
import { RadioButtonOnIcon } from '../../icons/RadioButtonOnIcon'
import { Text, TextMicro, TextSmall, TextStrong, TextTiny } from '../../ui/Typography/Typography'
import { useArabicSessionLanguage } from '../context/SessionSettingsContext'

import styles from './RadioButton.module.scss'

export interface RadioBoxProps {
  name?: string
  label?: string | React.ReactNode
  value?: string | number | boolean
  checked?: boolean
  disabled?: boolean
  required?: boolean
  hint?: string
  noContentWrapper?: boolean
  onChange?(value: React.ChangeEvent<HTMLInputElement>): void
  onClick?(value: React.MouseEvent<HTMLInputElement>): void
  className?: string
  textSize?: 'small' | 'normal'
  isUnselectedGray?: boolean
}

export const RadioButton: React.FunctionComponent<PropsWithChildren<RadioBoxProps>> = (props) => {
  const {
    name,
    label,
    value = undefined,
    checked = undefined,
    disabled = false,
    required = false,
    noContentWrapper,
    hint,
    onChange = () => {},
    onClick,
    children,
    className,
    textSize = 'small',
  } = props

  const hasTitle = label || hint || children

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(event)
  }

  const handleClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    event.stopPropagation()
    onClick?.(event)
  }

  return (
    <React.Fragment>
      <div className={classNames(styles.wrapper, 'is-flex is-align-items-center', className)}>
        <span className={styles.radioButton}>
          <input
            name={name}
            type='radio'
            value={getValue(value)}
            checked={checked}
            disabled={disabled}
            required={required}
            onChange={handleChange}
            onClick={handleClick}
            className={classNames(styles.input, {
              [styles.disabled]: disabled,
            })}
          />
          <span
            className={classNames(styles.icon, {
              [styles.active]: !!value,
              [styles.disabled]: disabled,
            })}
          >
            <span className={styles.radioOn}>
              <RadioButtonOnIcon />
            </span>
            <span className={styles.radioOff}>
              <RadioButtonOffIcon />
            </span>
          </span>
        </span>

        {hasTitle && (
          <span
            className={classNames({
              [styles.disabled]: disabled,
              [styles.contentWrapper]: !noContentWrapper,
            })}
          >
            {label && (
              <Label textSize={textSize} className={styles.label}>
                {label}
              </Label>
            )}
            {hint && (
              <Label textSize={textSize} className={styles.hint}>
                {hint}
              </Label>
            )}
            {children && (
              <Label textSize={textSize} className={styles.label}>
                {children}
              </Label>
            )}
          </span>
        )}
      </div>
    </React.Fragment>
  )
}

interface LabelProps {
  className?: string
  textSize?: 'small' | 'normal'
}

const Label: React.FC<PropsWithChildren<LabelProps>> = (props) => {
  const { className, textSize, children } = props

  if (textSize === 'small') {
    return <TextSmall className={className}>{children}</TextSmall>
  } else {
    return <Text className={className}>{children}</Text>
  }
}

const getValue = (value: string | number | boolean | undefined) => {
  if (value === undefined || value === null) {
    return undefined
  }

  return value?.toString() || undefined
}

export interface RadioCardProps extends RadioBoxProps {
  logo?: ReactNode
  description?: string

  isOnFire?: boolean
}

export const RadioCard: React.FunctionComponent<PropsWithChildren<RadioCardProps>> = (props) => {
  const {
    name,
    label,
    value = undefined,
    checked = undefined,
    disabled = false,
    required = false,
    hint,
    onChange = () => {},
    onClick,
    className,
    logo,
    description,
    isOnFire,
    isUnselectedGray = false,
  } = props
  const isArabic = useArabicSessionLanguage()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(event)
  }

  const handleClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    event.stopPropagation()
    onClick?.(event)
  }

  return (
    <React.Fragment>
      <label
        className={classNames(
          styles.confirmWrapper,
          {
            [styles.selected]: checked,
            [styles.disabled]: disabled,
          },
          className
        )}
        style={{ cursor: disabled ? 'default' : 'pointer' }}
      >
        <div className={styles.logo}>{logo}</div>
        <div className={styles.contentWrapper}>
          <div className={styles.titleWrapper}>
            <span className={classNames(styles.title, { [styles.disabled]: disabled })}>
              {isOnFire && <FireIcon size={16} />}
              <TextStrong>{label}</TextStrong>
            </span>
            <span
              className={classNames(styles.radioButton, styles.radioCardButton)}
              dir={isArabic ? 'rtl' : ''}
            >
              <input
                name={name}
                type='radio'
                value={getValue(value)}
                checked={checked}
                disabled={disabled}
                required={required}
                onChange={handleChange}
                onClick={handleClick}
                className={classNames(styles.input, {
                  [styles.disabled]: disabled,
                })}
              />
              <span className={classNames(styles.icon, { [styles.disabled]: disabled })}>
                {checked ? (
                  <RadioButtonOnIcon color='primary' />
                ) : isUnselectedGray ? (
                  <RadioButtonOffIcon color='contrastMediumLow' />
                ) : (
                  <RadioButtonOffIcon />
                )}
              </span>
            </span>
          </div>
          <TextMicro isParagraph className={styles.textInteractive}>
            {hint}
          </TextMicro>
          <TextTiny isParagraph className={styles.textSecondary}>
            {description}
          </TextTiny>
        </div>
      </label>
    </React.Fragment>
  )
}

import React, { SyntheticEvent, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { AxiosError } from 'axios'
import { Form, FormikErrors, FormikProps, withFormik } from 'formik'
import { t } from 'i18next'

import { Button } from '../../global/button/Button'
import { useSessionLanguage } from '../../global/context/SessionSettingsContext'
import { createFormPasswordField } from '../../global/formField/FormPasswordField'
import { InfoModal } from '../../global/modal/InfoModal'
import { Modal } from '../../global/modal/Modal'
import { errorOptions } from '../../routing/RouterToaster'
import { usePasswordValidation } from '../../ui/PasswordValidation/PasswordValidation'
import { Text } from '../../ui/Typography/Typography'
import { ResponseError, instanceOfAxiosError } from '../../utils/ApiClient'
import { FormSubmitValues } from '../../utils/formValidation'

import styles from './AccountSettingsPage.module.scss'

export interface ChangePasswordFormValues {
  currentPassword: string
  newPassword: string
}

const FormField = createFormPasswordField<ChangePasswordFormValues>()

const ChangePasswordFormUI: React.FC<FormikProps<ChangePasswordFormValues> & OuterProps> = (
  props
) => {
  const { handleSubmit, setSubmitting, dirty, values, isValid, isSubmitting } = props
  const { isPasswordValid, validationComponent } = usePasswordValidation({
    password: values.newPassword,
    showOneValidation: true,
  })
  const { t } = useTranslation()
  const locale = useSessionLanguage()

  const handleSubmitForm = (event: SyntheticEvent) => {
    event.preventDefault()
    setSubmitting(true)
    handleSubmit()
  }

  useEffect(() => {
    props.validateForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])

  return (
    <>
      <Text className={styles.changePasswordDescription}>{t('Profile.Change password')}</Text>
      <Form className={styles.changePasswordForm} onSubmit={handleSubmitForm}>
        <FormField
          name='currentPassword'
          type={'password'}
          placeholder={t('Old Password')}
          className={styles.changePasswordFormField}
        />
        <FormField name='newPassword' type={'password'} placeholder={t('New Password')} />

        {validationComponent}

        <div className={styles.changePasswordSubmitWrapper}>
          <Button
            disabled={!(isPasswordValid && isValid && dirty && !isSubmitting)}
            type='submit'
            appearance='secondary'
            size='M'
            className={styles.submitButton}
            loading={isSubmitting}
          >
            {t('Save changes')}
          </Button>
        </div>
      </Form>
    </>
  )
}

interface OuterProps {
  onSubmit(values: FormSubmitValues<ChangePasswordFormValues>): Promise<void>
}

export const ChangePasswordForm = withFormik<OuterProps, ChangePasswordFormValues>({
  mapPropsToValues: () => {
    return {
      currentPassword: '',
      newPassword: '',
    }
  },
  handleSubmit: async (values, { props, setSubmitting, setErrors }) => {
    try {
      await props.onSubmit(values)
    } catch (error: unknown) {
      if (
        (instanceOfAxiosError(error as Error) &&
          (error as AxiosError).response?.data.code === errorOptions.invalid_credentials) ||
        (error instanceof ResponseError &&
          error.response.response?.data.code === errorOptions.invalid_credentials)
      ) {
        setErrors({
          currentPassword: t('errors.Password does not match'),
        })
      }
    } finally {
      setSubmitting(false)
    }
  },
  validate: (values) => {
    const errors: FormikErrors<ChangePasswordFormValues> = {}
    if (!values.currentPassword) {
      errors.currentPassword = t('Validation.Required')
    }

    return errors
  },
  enableReinitialize: true,
  isInitialValid: false,
})(ChangePasswordFormUI)

interface InfoModalProps {
  close: () => void
  isOpen?: boolean
}

export const PasswordInfoModal: React.FC<InfoModalProps> = ({ close, isOpen }) => {
  if (!isOpen) {
    return null
  }
  return (
    <Modal
      closeModal={close}
      render={() => (
        <InfoModal
          onCancel={close}
          title={t('Profile.Password Changed')}
          renderBody={() => (
            <section className='modal-card-body'>
              <p
                dangerouslySetInnerHTML={{
                  __html: t('Profile.Change success'),
                }}
              />
            </section>
          )}
          onConfirm={close}
        />
      )}
    />
  )
}

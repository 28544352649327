import { IconProps } from './IconProps'

export function NotificationsNotFoundIcon(props: IconProps): JSX.Element {
  const size = props.size || 120

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 121 120'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={props.className}
      onClick={props.onClick}
    >
      <circle cx='60.5' cy='60' r='60' fill='url(#paint0_linear_16120_111610)' />
      <path
        d='M35.7253 21.7357C36.2808 20.669 37.3836 20 38.5863 20H82.7368C83.9394 20 85.0422 20.669 85.5978 21.7357L100.823 50.9677H20.5002L35.7253 21.7357Z'
        fill='#515154'
        fillOpacity='0.3'
      />
      <path
        d='M35.8683 21.8102C36.3961 20.7969 37.4438 20.1613 38.5863 20.1613H82.7368C83.8793 20.1613 84.927 20.7969 85.4548 21.8102L100.557 50.8064H20.7661L35.8683 21.8102Z'
        stroke='#5C5C5D'
        strokeOpacity='0.2'
        strokeWidth='0.322581'
      />
      <path
        d='M73.5645 34.1936C73.5645 35.8861 72.1925 37.2581 70.5 37.2581L50.5 37.2581C48.8075 37.2581 47.4355 35.8861 47.4355 34.1936L47.4355 20.3227C47.4355 18.6302 48.8075 17.2581 50.5 17.2581L70.5 17.2581C72.1925 17.2581 73.5645 18.6302 73.5645 20.3227L73.5645 34.1936Z'
        fill='#E0E0E0'
        stroke='#AFAFB1'
        strokeWidth='0.322581'
      />
      <path
        d='M81.3065 41.6128C81.3065 43.3053 79.9345 44.6773 78.242 44.6773L42.7581 44.6773C41.0656 44.6773 39.6936 43.3053 39.6936 41.6128L39.6936 27.7418C39.6936 26.0494 41.0656 24.6773 42.7581 24.6773L78.242 24.6773C79.9345 24.6773 81.3065 26.0494 81.3065 27.7419L81.3065 41.6128Z'
        fill='#E6E6E6'
        stroke='#AFAFB1'
        strokeWidth='0.322581'
      />
      <path
        d='M85.8226 50C85.8226 51.6925 84.4506 53.0645 82.7581 53.0645L38.242 53.0645C36.5495 53.0645 35.1774 51.6925 35.1774 50L35.1774 36.1291C35.1774 34.4366 36.5495 33.0645 38.242 33.0645L82.7581 33.0645C84.4506 33.0645 85.8226 34.4366 85.8226 36.1291L85.8226 50Z'
        fill='#EDEDED'
        stroke='#AFAFB1'
        strokeWidth='0.322581'
      />
      <path
        d='M91.8387 64.2742C91.8387 65.9667 90.4667 67.3387 88.7742 67.3387L32.2258 67.3387C30.5333 67.3387 29.1613 65.9667 29.1613 64.2742L29.1613 45.2258C29.1613 43.5333 30.5333 42.1613 32.2258 42.1613L88.7742 42.1613C90.4667 42.1613 91.8387 43.5333 91.8387 45.2258L91.8387 64.2742Z'
        fill='#F9F9F9'
        stroke='#AFAFB1'
        strokeWidth='0.322581'
      />
      <rect
        x='50'
        y='49.7905'
        width='17.76'
        height='2.58065'
        rx='1.29032'
        fill='#B0B0B0'
        fillOpacity='0.5'
      />
      <rect
        x='50'
        y='54.3064'
        width='22.965'
        height='2.58065'
        rx='1.29032'
        fill='#B0B0B0'
        fillOpacity='0.5'
      />
      <rect
        x='50'
        y='58.8228'
        width='7.965'
        height='2.58065'
        rx='1.29032'
        fill='#B0B0B0'
        fillOpacity='0.5'
      />
      <rect
        x='59'
        y='58.8228'
        width='3.465'
        height='2.58065'
        rx='1.29032'
        fill='#B0B0B0'
        fillOpacity='0.5'
      />
      <path
        d='M20.5 50.9678H40.5806C43.4611 50.9678 45.903 53.0861 46.3098 55.9376L46.3229 56.0295C46.5424 57.5679 47.205 59.0092 48.2298 60.1774C49.7496 61.9099 51.9424 62.9033 54.247 62.9033H55.6411H60.6613H65.6814H67.0756C69.3802 62.9033 71.573 61.9099 73.0928 60.1774C74.1176 59.0092 74.7802 57.5679 74.9997 56.0295L75.0128 55.9376C75.4196 53.0861 77.8615 50.9678 80.7419 50.9678H100.823V86.1291C100.823 91.4738 96.4899 95.8065 91.1452 95.8065H30.1774C24.8327 95.8065 20.5 91.4738 20.5 86.1291V50.9678Z'
        fill='#EFEFEF'
      />
      <path
        d='M20.6613 51.1291H40.5806C43.3808 51.1291 45.7547 53.1883 46.1501 55.9604L46.1632 56.0522C46.3872 57.6217 47.0631 59.092 48.1085 60.2838C49.659 62.0512 51.8959 63.0645 54.247 63.0645H55.6411H60.6613H65.6814H67.0756C69.4267 63.0645 71.6636 62.0512 73.214 60.2838C74.2595 59.092 74.9354 57.6217 75.1593 56.0522L75.1724 55.9604C75.5679 53.1883 77.9418 51.1291 80.7419 51.1291H100.661V86.1291C100.661 91.3847 96.4008 95.6452 91.1452 95.6452H30.1774C24.9218 95.6452 20.6613 91.3847 20.6613 86.1291V51.1291Z'
        stroke='#5C5C5D'
        strokeOpacity='0.2'
        strokeWidth='0.322581'
      />
      <rect
        x='27.2742'
        y='82.5806'
        width='22.2581'
        height='2.58065'
        rx='1.29032'
        fill='#626265'
        fillOpacity='0.4'
      />
      <rect
        x='27.2744'
        y='87.0967'
        width='16.129'
        height='2.58065'
        rx='1.29032'
        fill='#626265'
        fillOpacity='0.4'
      />
      <rect
        x='44'
        y='87.0967'
        width='3'
        height='2.58065'
        rx='1.29032'
        fill='#626265'
        fillOpacity='0.4'
      />
      <rect
        x='27.2742'
        y='78.0647'
        width='6.45161'
        height='2.58065'
        rx='1.29032'
        fill='#626265'
        fillOpacity='0.4'
      />
      <circle cx='104.081' cy='16.5806' r='12.5806' fill='#FB3E68' />
      <path
        d='M107.959 16.5097C107.959 17.4671 107.887 18.3199 107.744 19.0682C107.601 19.8165 107.365 20.4548 107.034 20.983C106.715 21.5002 106.297 21.8964 105.78 22.1715C105.274 22.4466 104.652 22.5842 103.915 22.5842C102.99 22.5842 102.231 22.3421 101.637 21.8579C101.042 21.3737 100.602 20.6804 100.316 19.778C100.03 18.8646 99.887 17.7752 99.887 16.5097C99.887 15.2441 100.019 14.1602 100.283 13.2578C100.547 12.3554 100.971 11.6622 101.554 11.178C102.148 10.6938 102.935 10.4517 103.915 10.4517C104.839 10.4517 105.598 10.6938 106.193 11.178C106.787 11.6512 107.227 12.3444 107.513 13.2578C107.81 14.1602 107.959 15.2441 107.959 16.5097ZM101.983 16.5262C101.983 17.4836 102.044 18.2869 102.165 18.9362C102.286 19.5744 102.484 20.0586 102.759 20.3888C103.045 20.7079 103.43 20.8675 103.915 20.8675C104.399 20.8675 104.778 20.7079 105.054 20.3888C105.34 20.0696 105.543 19.5854 105.664 18.9362C105.796 18.2869 105.862 17.4836 105.862 16.5262C105.862 15.5578 105.796 14.7544 105.664 14.1162C105.543 13.4669 105.34 12.9827 105.054 12.6636C104.778 12.3334 104.399 12.1684 103.915 12.1684C103.43 12.1684 103.045 12.3334 102.759 12.6636C102.484 12.9827 102.286 13.4614 102.165 14.0997C102.044 14.7379 101.983 15.5468 101.983 16.5262Z'
        fill='white'
      />
      <defs>
        <linearGradient
          id='paint0_linear_16120_111610'
          x1='60.5'
          y1='-65.5'
          x2='60.5'
          y2='133'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#DEDEE3' />
          <stop offset='1' stopColor='#FCFCFC' />
        </linearGradient>
      </defs>
    </svg>
  )
}

import { differenceInMinutes } from 'date-fns'

import { useProductReadContext } from '../../global/context/ProductContext'
import { AccountDetailedDto, KYCVerificationProvider } from '../../model/AccountDetailedDto'
import { isAccountActivatedStatus } from '../../model/AccountDetailedStatus'
import { TickmillProductType } from '../../model/TickmillProductType'
import { OnboardingFlowTypeEnum } from '../../pages/Signup/Components/SignupStep'
import { useAccountReadContext } from '../AccountContextContext'
import { determineProductState, hasProductDormantStatus } from './accountProductStatuses'

interface AccountKYCStatus {
  isKYCUpdateNotAllowed: boolean
  isKYCUpdateAllowed: boolean
  canUpdateKYCOrDormant: boolean
  isKYCUpdateDue: boolean
  requiresDepositBeforeKYC: boolean
  mustDepositBeforeKYC: boolean
}

export const useAccountKYCStatus = (): AccountKYCStatus => {
  const { account } = useAccountReadContext()
  const { product } = useProductReadContext()

	const isKYCUpdateNotAllowed = isAccountKycUpdateDetailsNotAllowed(account, product)
	const isKYCUpdateAllowed = isAccountKycUpdateDetailsAllowed(account, product)
	const canUpdateKYCOrDormant = allowKYCUpdate(account)
	const kYCUpdateDue = isKYCUpdateDue(account)
	const requiresDepositBeforeKYC = isDepositBeforeKYC(account)
	const mustDepositBeforeKYC = requireDepositBeforeKYC(account)

	return {
		isKYCUpdateNotAllowed,
		isKYCUpdateAllowed,
		canUpdateKYCOrDormant,
		isKYCUpdateDue: kYCUpdateDue,
		requiresDepositBeforeKYC,
		mustDepositBeforeKYC,
	}
}

export const isAccountKycUpdateDetailsNotAllowed = (
	accountDetailed: AccountDetailedDto | undefined,
	product: TickmillProductType | undefined
): boolean => {
	return !isAccountKycUpdateDetailsAllowed(accountDetailed, product, true)
}

export const isAccountKycUpdateDetailsAllowed = (
	accountDetailed: AccountDetailedDto | undefined,
	product: TickmillProductType | undefined,
	ignoreDateDifferenceInMinutes = false
): boolean => {
	if (!accountDetailed) {
		return false
	}

	if (ignoreDateDifferenceInMinutes) {
		return allowKYCUpdate(accountDetailed)
	}

	const dateDifference = differenceInMinutes(
		new Date(),
		new Date(accountDetailed.detailsUpdateAvailableUntil ?? '')
	)

	const canUpdateKYC = accountDetailed.isKYCUpdateNeeded && dateDifference >= 0
	const canUpdateDormant =
		accountDetailed.isDormantUpdateNeeded &&
		dateDifference >= 0 &&
		!hasProductDormantStatus(accountDetailed, product)

	return canUpdateKYC || canUpdateDormant
}

export const allowKYCUpdate = (account: AccountDetailedDto | undefined): boolean => {
	if (!account) {
		return false
	}
	return !!account.isKYCUpdateNeeded || !!account.isDormantUpdateNeeded
}

export const isKYCUpdateDue = (account: AccountDetailedDto | undefined): boolean => {
	if (!account || !allowKYCUpdate(account)) {
		return false
	}

	const currentDate = new Date()
	const kycUpdateDate = new Date(account.kycUpdateAvailableUntil ?? currentDate)
	const detailsUpdateDate = new Date(account.detailsUpdateAvailableUntil ?? currentDate)

	return kycUpdateDate < currentDate || detailsUpdateDate < currentDate
}

export const isDepositBeforeKYC = (account: AccountDetailedDto | undefined): boolean => {
	return (
		account?.visibilityConfiguration?.onboardingFlowType?.id ===
		OnboardingFlowTypeEnum.DepositBeforeKYC
	)
}

export const isSumsubNeeded = (account: AccountDetailedDto | undefined): boolean =>
  account?.visibilityConfiguration?.kycVerificationProvider === KYCVerificationProvider.Sumsub &&
  !isAccountActivatedStatus(account)

export const isKycVerified = (
  account: AccountDetailedDto | undefined,
  product: TickmillProductType | undefined
): boolean => determineProductState(account, product)?.isKycVerified ?? false

export const isDepositPlaced = (account: AccountDetailedDto | undefined): boolean =>
  account?.visibilityConfiguration.hasPlacedDeposit ?? false

export const requireDepositBeforeKYC = (account: AccountDetailedDto | undefined): boolean => {
  return (
    !isAccountActivatedStatus(account) && isDepositBeforeKYC(account) && !isDepositPlaced(account)
  )
}
